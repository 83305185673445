<template>
  <div class="my_order">
    <div class="my_order_header">
      <div class="item">
        <span class="title">时间范围</span>
        <el-date-picker class="my_order_date" v-model="beginTime" type="date" value-format="yyyy-MM-dd"
                        placeholder="开始时间">
        </el-date-picker>
        <span class="to">-</span>
        <el-date-picker class="my_order_date" v-model="endTime" type="date" value-format="yyyy-MM-dd"
                        placeholder="结束时间">
        </el-date-picker>
      </div>
      <div class="item">
        <el-button class="my_order_query_btn" @click="queryEvent">查询</el-button>
      </div>
    </div>
    <div class="my_order_main">
      <el-table  :data="tableData" style="width: 100%">
        <el-table-column prop="orderNum" label="订单编号" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="userRemark" label="会员类型" align="center">
        </el-table-column>
        <el-table-column label="支付金额" align="center">
            <template slot-scope="scope">
              <span style="color: #eb5657;">{{ scope.row.price / 100  }} </span>元
            </template>
        </el-table-column>
        <el-table-column  label="有效期" align="center">
          <template slot-scope="scope">
            <span >{{ scope.row.cardStartTime }} - {{ scope.row.cardEndTime }}</span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="categoryName" label="状态" align="center">-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="paymentMethod" label="支付方式" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <span>{{scope.row.paymentMethod == 'PAY_VOUCHER' ? '对公支付' : scope.row.paymentMethod}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="订单金额" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <span style="color: #eb5657;">{{ scope.row.amount }}</span>元-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column  label="状态" align="center">
          <template slot-scope="scope">
            <span >{{ scope.row.status == 0 ? '支付成功' : ''}}</span>
          </template>
        </el-table-column>
<!--        <el-table-column label="操作" align="center" width="240">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button class="btn btn_green" style="background-color: #dec397" @click="detailEvent(scope.row.id)">查看</el-button>-->
<!--            <el-button class="btn pay" style="background-color: #ffffff;color: #dec397 " v-show="scope.row.status == 10" type="primary"-->
<!--                       @click="payEvent(scope.row.id,scope.row.amount,scope.row.orderType,scope.row.userRemark,scope.row.quantity,scope.row,scope.row.orderNum)">支付</el-button>-->
<!--            &lt;!&ndash; <i class="order_detail" v-show="scope.row.isPaid == 'n'" @click="delEvent(scope.row.id)">-->
<!--              <img src="../../../images/del.png" alt="">-->
<!--            </i> &ndash;&gt;-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <div class="el_pagination_wrap">
        <el-pagination v-if="tableData.length>0" @current-change="handleCurrentChange" @size-change="handleSizeChange"
                       :current-page="current" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <div class="no_table_data" v-show="tableData.length == 0">
        <i>
          <img src="@/assets/images/common/orderN.png" alt="">
        </i>
        <p>暂无订单，去<span @click="buyEvent">购买</span></p>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" width="860px" custom-class="detail_dialog"
               :close-on-click-modal="false">
      <div class="report_con">
        <div class="report_con_item">
          <h6>订单信息</h6>
          <div class="report_list_item">
            <span>订单号:</span>
            <p>{{detailData.orderNum}}</p>
          </div>
          <div class="report_list_item">
            <span>创建时间:</span>
            <p>{{detailData.createTime}}</p>
          </div>
          <div class="report_list_item">
            <span>订单状态:</span>
            <p>
              <!-- 对公支付  12是待审核 ，23是订单完成，24是审核拒绝 -->
              <span v-if="detailData.status == 12 || detailData.status == 23 || detailData.status == 24">{{detailData.status == 12 ? '待审核' : detailData.status == 23 ? '订单完成' : detailData.status == 24 ? '审核拒绝' : ''}}</span>

              <!-- 微信、支付宝  10是待支付 ，11是待审核 ，20是支付成功，21是免支付，22是审核拒绝，30是订单完成 -->
              <span v-else>{{detailData.status == 10 ? '待支付' : detailData.status == 11 ? '待审核' : detailData.status == 20 ? '支付成功' :
                    detailData.status == 21 ? '免支付' : detailData.status == 22 ? '审核拒绝' : detailData.status == 30 ? '订单完成' :''}}</span>
            </p>
          </div>
          <div class="report_list_item">
            <span>订单金额:</span>
            <p>{{detailData.amount+"元"}}</p>
          </div>
        </div>
        <div class="report_con_item">
          <h6>产品信息</h6>
          <el-table :data="detailTableData" style="width: 100%">

            <el-table-column prop="serviceName" label="产品名称" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.serviceName}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="type == 1 ? '总个数' : modeType == '4' ? '总次数': modeType == '3' ? '总字数' : '总数'" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.totalLimit == 0 ? '--' : scope.row.totalLimit}}</span>{{scope.row.type == 1 ? '个' : scope.row.modeType == '4' && scope.row.totalLimit != 0? '次' :scope.row.modeType == '3' ? '字': scope.row.modeType == '1' ? '个' : '' }}
              </template>
            </el-table-column>
            <el-table-column v-if="publishType == 4 || modeType != 1"  prop="dailyLimit" label="每日限制" align="center">
              <template slot-scope="scope">
                <span style="color: #2f56de;" v-if="scope.row.dailyLimit != 0">{{scope.row.dailyLimit}}</span>{{scope.row.dailyLimit != 0 && scope.row.modeType == '4' ? '次': scope.row.dailyLimit != 0 && scope.row.modeType == '3' ? '字': '--'}}
              </template>
            </el-table-column>
            <el-table-column v-if="publishType == 4 || modeType != 1"  prop="timeLimit" label="时间限制" align="center">
              <template slot-scope="scope">
                <span style="color: #2f56de;">{{quantity == 1 ? '一周' : quantity == 2 ? '一个月' : quantity == 3 ? '三个月' : quantity == 4 ? '半年' : quantity == 5 ? '一年' :  quantity == 0 ? scope.row.timeLimit + '天' : ''}}</span>
              </template>
            </el-table-column>
            <!--            <el-table-column prop="amount" label="总金额" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                <span style="color: #2f56de;">{{scope.row.amount}}</span>元-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
        </div>
        <div class="report_con_item">
          <h6>支付信息</h6>
          <div class="report_list_item">
            <span>支付时间:</span>
            <p>{{detailData.paymentTime == null ? '--' : detailData.paymentTime}}</p>
          </div>
          <div class="report_list_item">
            <span>支付方式:</span>
            <p>{{detailData.paymentMethod == null ? '--' : detailData.paymentMethod == 'PAY_VOUCHER' ? '对公支付' : detailData.paymentMethod}}</p>
          </div>
          <div class="report_list_item">
            <span>支付金额:</span>
            <p>
              {{(detailData.isPaid == 'n' && detailData.payAmount == 0) || detailData.payAmount == null  ? '--' : detailData.payAmount+"元"}}
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  // 亚洲
  const cityOptions = [
    {
      city:'中国大陆',
      code:'CN',
      ingSrc:'/images/national/zgdl.jpg'
    },
    {
      city:'中国香港',
      code:'HK',
      ingSrc:'/images/national/zgxg.jpg'
    },
    {
      city:'印度尼西亚',
      code:'ID',
      ingSrc:'/images/national/ydnxy.jpg'
    },
    {
      city:'印度',
      code:'IN',
      ingSrc:'/images/national/yd.jpg'
    },
    {
      city:'日本',
      code:'JP',
      ingSrc:'/images/national/rb.jpg'
    },
    {
      city:'土耳其',
      code:'TR',
      ingSrc:'/images/national/trq.jpg'
    },
    {
      city:'韩国',
      code:'KR',
      ingSrc:'/images/national/hg.jpg'
    },
    {
      city:'中国澳门',
      code:'MO',
      ingSrc:'/images/national/am.jpg'
    },
    {
      city:'马来西亚',
      code:'MY',
      ingSrc:'/images/national/mlxy.jpg'
    },
    {
      city:'菲律宾',
      code:'PH',
      ingSrc:'/images/national/flb.jpg'
    },
    {
      city:'新加坡',
      code:'SG',
      ingSrc:'/images/national/xjp.jpg'
    },
    {
      city:'中国台湾',
      code:'TW',
      ingSrc:'/images/national/zgtw.png'
    },
    {
      city:'泰国',
      code:'TH',
      ingSrc:'/images/national/tgs.jpg'
    },
    {
      city:'阿拉伯联合酋长国',
      code:'AE',
      ingSrc:'/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city:'奥地利',
      code:'AT',
      ingSrc:'/images/national/adl.JPG'
    },
    {
      city:'瑞士',
      code:'CH',
      ingSrc:'/images/national/rs.jpg'
    },
    {
      city:'德国',
      code:'DE',
      ingSrc:'/images/national/dg.jpg'
    },
    {
      city:'丹麦',
      code:'DK',
      ingSrc:'/images/national/dm.jpg'
    },
    {
      city:'挪威',
      code: 'NO',
      ingSrc:'/images/national/nw.jpg'
    },
    {
      city:'西班牙',
      code:'ES',
      ingSrc:'/images/national/xby.jpg'
    },
    {
      city:'欧盟',
      code:'EU',
      ingSrc:'/images/national/om.jpg'
    },
    {
      city:'芬兰',
      code:'FI',
      ingSrc:'/images/national/fl.jpg'
    },
    {
      city:'法国',
      code:'FR',
      ingSrc:'/images/national/fg.jpg'
    },
    {
      city:'英国',
      code:'GB',
      ingSrc:'/images/national/yg.jpg'
    },
    {
      city:'葡萄牙',
      code:'PT',
      ingSrc:'/images/national/pty.jpg'
    },
    {
      city:'希腊',
      code:'GR',
      ingSrc:'/images/national/xl.jpg'
    },
    {
      city:'匈牙利',
      code:'HU',
      ingSrc:'/images/national/xyl.jpg'
    },
    {
      city:'爱尔兰',
      code:'IE',
      ingSrc:'/images/national/ael.jpg'
    },
    {
      city:'冰岛',
      code:'IS',
      ingSrc:'/images/national/bd.jpg'
    },
    {
      city:'意大利',
      code:'IT',
      ingSrc:'/images/national/ydl.jpg'
    },
    {
      city:'瑞典',
      code:'SE',
      ingSrc:'/images/national/rd.jpg'
    },
    {
      city:'俄罗斯',
      code:'RU',
      ingSrc:'/images/national/elss.jpg'
    },
    {
      city:'比荷卢经济联盟',
      code:'BX',
      ingSrc:'/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city:'加拿大',
      code:'CA',
      ingSrc:'/images/national/jnd.jpg'
    },
    {
      city:'墨西哥',
      code:'MX',
      ingSrc:'/images/national/mxg.jpg'
    },
    {
      city:'美国',
      code:'US',
      ingSrc:'/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city:'阿根廷',
      code:'AR',
      ingSrc:'/images/national/agt.jpg'
    },
    {
      city:'巴西',
      code:'BR',
      ingSrc:'/images/national/bx.jpg'
    },
    {
      city:'智利',
      code:'CL',
      ingSrc:'/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city:'南非',
      code:'ZA',
      ingSrc:'/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city:'澳大利亚',
      code:'AU',
      ingSrc:'/images/national/adly.jpg'
    },
    {
      city:'新西兰',
      code:'NZ',
      ingSrc:'/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city:'世界知识产权组织国际局',
      code:'WO',
      ingSrc:'/images/national/zscq.jpg'
    }
  ]
  export default {
    data () {
      return {
        quantity:1,
        publishType:'4',
        //总和数据库
        allDatabase:[],
        beginTime: "",
        endTime: "",
        tableData: [],
        detailTableData: [],
        dialogTableVisible: false,
        current: 1,
        size: 10,
        total: 0,
        detailData: {},
        modeType: '4',
        servicePeriod:['','一周','一个月','三个月','半年','一年'],
        type: 0
      }
    },
    mounted () {
      //中文数据库总和
      this.allDatabase = this.allDatabase.concat(cityOptions,city,cityOptionsd,cityOptionsds,cityOpts,cityds,propertyOrganization)
      this.queryAjax();
    },
    methods: {
      //数据库code 和 中文 转换
      conversion(stateChange){
        let data = []
        stateChange.split(';').map(item => {
          this.allDatabase.map(items => {
            if(items.code == item){
              data.push(items.city)
            }
          })
        })
        localStorage.setItem('allDatabase',data)
      },
      //   购买
      buyEvent () {
        this.$router.push({
          path: "/services"
        })
      },
      // 查询
      queryEvent () {
        this.queryAjax();
      },
      getVipType(item) {
        switch (item) {
          case 'vip_pt': return '专利'; break;
          case 'vip_tm': return '商标'; break;
          case 'vip_plus': return '联合'; break;
        }
      },
      async queryAjax () {
        let data = await this.$axios.get("/admin/member/user/record/page", {        params: {
            startTime: this.beginTime ? this.beginTime + " 00:00:00" : '',
            endTime: this.endTime ? this.endTime + " 23:59:59" : '',
            size: this.size,
            current: this.current
          }      });
        if (data.data.code == 0) {
          this.tableData = data.data.data.records.map(item => {
            item.userRemark = this.getVipType(item.cardType) + '-' + item.cardName
            return item
          });
          this.total = data.data.data.total;
        }
      },
      detailEvent (id) {
        this.detailTableData = [];
        this.detailAjax(id)
        this.dialogTableVisible = true;
      },
      // 详情
      async detailAjax (id) {
        let data = await this.$axios.get(`/order/order/getLanternOrderDetail/${id}`);
        if (data.data.code == 0) {
          this.detailData = data.data.data;
          this.detailTableData = data.data.data.orderServiceManageList;
          this.detailTableData = data.data.data.orderServiceManageList.map(item => {
            if(item.productName.indexOf('商标监控') != - 1){
              item.type = 1
            }else {
              item.type = 0
            }
            return item;
          })
          this.modeType = this.detailTableData[0].modeType;
          this.publishType = this.detailTableData[0].publishType;
          this.quantity = this.detailData.quantity
          this.type = this.detailTableData[0].productName.indexOf('商标监控') != - 1 ? 1 : 0;
        }
      },
      // 支付
      payEvent (dataId, amount, orderType,userRemark,quantity,orderServiceManageList,orderNum) {

        if(orderType == 'lantern'){
          //lantern的翻译
          localStorage.setItem('orderId', dataId)
          this.$router.push({
            path: "/pay/orderInfos",
            query: {
              id: orderServiceManageList.id,
              tariff: amount,
              orderNum:orderNum
            }
          })
        }else if(orderType == 'tm_query'){
          //订制的商标查询
          this.conversion(userRemark);
          localStorage.setItem('price', JSON.stringify(amount))
          // localStorage.setItem('servicePeriod', this.servicePeriod[quantity])
          localStorage.setItem('orderId', dataId)
          this.$router.push({
            path: "/trademarkApply",
            query: {
              trademark:true,
              servicePeriod: quantity,
              orderNum:orderNum
            }
          })
        }else if(orderType == 'pt_translate') {
          //订制的专利翻译
          localStorage.setItem('price', JSON.stringify(amount))
          localStorage.setItem('language', '中英')
          // localStorage.setItem('servicePeriod', this.servicePeriod[quantity])
          localStorage.setItem('orderId', dataId)
          this.$router.push({
            path: "/trademarkApply",
            query: {
              orderId: dataId,
              price: amount,
              trademark:false,
              servicePeriod: quantity,
              orderNum:orderNum
            }
          })
        }
      },
      // 删除订单
      delEvent () {

      },
      toTop () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      },
      handleCurrentChange (val) {
        this.current = val;
        this.toTop();
        this.queryAjax();
      },
      handleSizeChange (val) {
        this.size = val;
        this.toTop();
        this.queryAjax();
      },
    }
  }
</script>
<style scoped lang="less">
  .my_order_header {
    height: 85px;
    line-height: 85px;
    padding: 0 25px;
    background: #fff;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    margin-bottom: 8px;
    font-size: 14px;
    .item {
      display: inline-block;
      .title {
        margin-right: 20px;
      }
      .to {
        color: #c2c6d0;
      }
    }
  }
  .my_order_main {
    padding: 32px 10px;
    background: #fff;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    min-height: calc(100vh - 355px);
    .order_upload,
    .order_detail {
      display: inline-block;
      width: 20x;
      vertical-align: middle;
      cursor: pointer;
      img {
        width: 100%;
        display: block;
      }
    }
    .order_detail {
      width: 20px;
      margin-left: 16px;
      vertical-align: -4px;
    }
    .el_pagination_wrap {
      text-align: center;
      margin-top: 10px;
    }
    .no_table_data {
      margin-top: 60px;
      text-align: center;
      i {
        display: block;
        width: 80px;
        height: 80px;
        margin: 0 auto 40px;
        img {
          display: block;
          width: 100%;
        }
      }
      p {
        font-size: 15px;
        color: #949aa9;
        span {
          cursor: pointer;
          color: @blue;
        }
      }
    }
    .btn {
      width: 76px;
      height: 32px;
      color: #ffffff;
      background-color: #dec397;
      border-radius: 5px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      padding: 0;
      border: none;
      &.pay {
        background-color: #dec397;
        border: solid 1px #dec397;
        color: #53dee3;
      }
      &:hover {
        box-shadow: none;
      }
    }
  }
  .detail_dialog .el-dialog__body {
    .report_con {
      padding: 0 60px 30px;
      color: #454545;
      .report_con_item {
        margin-bottom: 50px;
        h6 {
          color: #595a5e;
          font-size: 16px;
          position: relative;
          margin-bottom: 12px;
          &::before {
            content: "";
            width: 4px;
            height: 18px;
            background-color: @blue;
            position: absolute;
            top: 50%;
            margin-top: -9px;
            left: -11px;
          }
        }
        .report_list_item {
          line-height: 28px;
          span {
            display: inline-block;
            width: 74px;
            font-size: 14px;
            color: #888888;
          }
          p {
            display: inline-block;
            width: 600px;
            vertical-align: top;
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .my_order_date.el-input {
    width: 160px;
  }
  .my_order_date .el-input__inner {
    width: 160px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #c2c6d0;
    border-radius: 5px;
    color: #666666;
  }
  .my_order_query_btn {
    width: 78px;
    height: 34px;
    line-height: 34px;
    background-color: @blue;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    padding: 0;
    margin-left: 20px;
    border: none;
    &:focus,
    &:hover {
      border: none;
      color: #fff;
      background-color: @blue;
    }
    &:hover {
      box-shadow: @boxShadow;
    }
  }
  .my_order_main .el-table__empty-block {
    display: none;
  }
</style>

<template>
  <div class="oaApplication">
    <div class="home_top"/>
    <textarea id="copy_content" value="" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>

    <div class="oaApplication_cen">
      <div class="oaApplication_o">
        <!--右侧打开历史记录按钮-->
        <el-tooltip class="item" effect="light" content="历史记录" placement="top">
          <img @click="open" class="history" src="../../assets/images/oaApplication/his.png" alt="">
        </el-tooltip>

        <!--主页面-->
        <div class="oaApplication_content">
          <!--左上切换页签-->
          <ul class="tabSwitch">
            <li :class="[{'active': generateType == (index + 1)}]" @click="handleGenerateTypeSwitch(item.value)" :key="index" v-for="(item,index) in tabList">
              {{item.label}}
            </li>
          </ul>

          <!--交底书解读-->
          <div v-loading="chatLoading1" v-if="generateType == 1" class="result">
            <div class="result_cen" style="height: calc(100vh - 340px)">
              <div class="titles">主要技术方案</div>
              <mavon-editor
                @change="(e) => contentChange(e, chatResult1[0], 1)"
                :placeholder="' '"
                style="min-height: 150px;box-shadow: none;z-index: 500"
                :config="editorConfig"
                :externalLink="externalLink"
                defaultOpen="edit"
                :toolbarsFlag="false"
                :default-preview="false"
                :toolbars="markdownOption"
                class="mavonEditor_1"
                language="zh-CN"
                ref="md"
                v-model="chatResult1[0].content"
              />

              <div class="titles">非必要技术特征</div>
              <mavon-editor
                @change="(e) => contentChange(e, chatResult1[1], 1)"
                :placeholder="' '"
                style="min-height: 150px;box-shadow: none;z-index: 500"
                :config="editorConfig"
                :externalLink="externalLink"
                defaultOpen="edit"
                :toolbarsFlag="false"
                :default-preview="false"
                :toolbars="markdownOption"
                class="mavonEditor_1"
                language="zh-CN"
                ref="md"
                v-model="chatResult1[1].content"
              />

              <div class="titles">不清楚之处</div>
              <mavon-editor
                @change="(e) => contentChange(e, chatResult1[2], 1)"
                :placeholder="' '"
                style="min-height: 150px;box-shadow: none;z-index: 500"
                :config="editorConfig"
                :externalLink="externalLink"
                defaultOpen="edit"
                :toolbarsFlag="false"
                :default-preview="false"
                :toolbars="markdownOption"
                class="mavonEditor_1"
                language="zh-CN"
                ref="md"
                v-model="chatResult1[2].content"
              />

              <div class="titles">问题清单</div>
              <mavon-editor
                @change="(e) => contentChange(e, chatResult1[3], 1)"
                :placeholder="' '"
                style="min-height: 150px;box-shadow: none;z-index: 500"
                :config="editorConfig"
                :externalLink="externalLink"
                defaultOpen="edit"
                :toolbarsFlag="false"
                :default-preview="false"
                :toolbars="markdownOption"
                class="mavonEditor_1"
                language="zh-CN"
                ref="md"
                v-model="chatResult1[3].content"
              />
            </div>

            <div style="display: flex; align-items: center; justify-content: center; margin-top: 20px; min-width: 441px">
              <div @click="handleStopStep1" style="margin-right: 10px" class="btn">终止</div>
              <div @click="regenerate" style="margin-right: 10px" :style="{cursor: disableRegenerate1 ? 'not-allowed' : 'pointer'}" class="btn">重新生成</div>
              <div @click="download" :style="{cursor: disableNextStep1 ? 'not-allowed' : 'pointer'}" class="btn">下载问题清单</div>
            </div>
          </div>

          <!--检索报告-->
          <div v-loading="chatLoading2" v-if="generateType == 2"  class="result">
            <el-tabs v-model="searchReportTab">
              <el-tab-pane class="result_cen" :disabled="!searchReportTabSet.has('技术方案')" label="技术方案" name="技术方案">
                <div class="titles">主要技术方案</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult2[0], 2)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult2[0].content"
                />

                <div class="titles">次要技术方案</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult2[1], 2)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult2[1].content"
                />

                <div class="titles">交底书内容概述</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult2[2], 2)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult2[2].content"
                />
              </el-tab-pane>

              <el-tab-pane class="result_cen" :disabled="!searchReportTabSet.has('对比文件分析')" label="对比文件分析" name="对比文件分析">
                <el-collapse class="oa_collapse" v-model="activeName"  accordion>
                  <el-collapse-item v-for="(item,index) in comparativeFiles" :class="[{active:  activeName == item.fileId}]" :key="item.fileId" :name="item.fileId">
                    <template slot="title">
                      <div class="titleHeader active">
                        <p class="title">{{item.title}}</p>

                        <div style="display: flex;align-items: center">
                          <el-tooltip class="item" effect="light" content="删除" placement="top-start">
                            <i @click.stop="del(item)" style="margin-right: 10px;font-size: 16px;font-weight: bold" class="header-icon el-icon-delete"></i>
                          </el-tooltip>

                          <el-tooltip class="item" effect="light" content="预览" placement="top-start">
                            <i @click.stop="view(item)" style="margin-right: 10px;font-size: 18px;font-weight: bold" class="header-icon el-icon-view"></i>
                          </el-tooltip>

                          <el-tooltip class="item" effect="light" content="查看对比文件分析" placement="top-start">
                            <i @click.stop="openCollapse(item)" style="font-size: 20px;font-weight: bold" :class="['header-icon', {'el-icon-arrow-down': activeName != item.fileId }, {'el-icon-arrow-up': activeName == item.fileId}]"/>
                          </el-tooltip>
                        </div>
                      </div>

                      <ul class="titleHeader">
                        <li style="margin-bottom: 8px">
                          <span style="font-weight: bold">申请号:</span>{{item.applyNoAll}}
                        </li>

                        <li style="margin-bottom: 8px">
                          <span style="font-weight: bold">公开号:</span>{{item.pubNoAll}}
                        </li>
                      </ul>

                      <ul v-if="activeName != item.fileId" class="titleHeader">
                        <li style="margin-bottom: 8px">
                          <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                        </li>
                      </ul>
                    </template>

                    <ul>
                      <li style="margin-bottom: 8px">
                        <span style="font-weight: bold">摘要:</span>{{item.abstractInfo}}
                      </li>

                      <li style="margin-bottom: 8px">
                        <span style="font-weight: bold">对比分析:</span>
                        <br/>
                        <mavon-editor
                          @change="(e) => contentChange(e, item, 2)"
                          :placeholder="' '"
                          style="min-height: 150px;box-shadow: none;z-index: 500;margin-top: 10px"
                          :config="editorConfig"
                          :externalLink="externalLink"
                          defaultOpen="edit"
                          :toolbarsFlag="false"
                          :default-preview="false"
                          :toolbars="markdownOption"
                          class="mavonEditor_1"
                          language="zh-CN"
                          ref="md"
                          v-model="item.differenceContent"
                        />
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>

              <el-tab-pane class="result_cen" :disabled="!searchReportTabSet.has('关键词/检索式')" label="关键词/检索式" name="关键词/检索式">
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult2[3], 2)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult2[3].content"
                />
              </el-tab-pane>

              <el-tab-pane class="result_cen" :disabled="!searchReportTabSet.has('IPC分类号')" label="IPC分类号" name="IPC分类号">
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult2[4], 2)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult2[4].content"
                />
              </el-tab-pane>
            </el-tabs>

            <div style="display: flex; align-items: center; justify-content: center; margin-top: 20px; min-width: 441px">
              <div @click="handleStopStep2" style="margin-right: 10px" class="btn">终止</div>
              <div @click="regenerate" style="margin-right: 10px" :style="{cursor: disableRegenerate2 ? 'not-allowed' : 'pointer'}"  class="btn">重新生成</div>

              <div @click="compareFileAnalysis" v-if="searchReportTab == '技术方案'" :style="{cursor: disableNextStep2 ? 'not-allowed' : 'pointer'}" class="btn">对比文件分析</div>
              <div @click="handleNextStep" v-if="searchReportTab == '对比文件分析'" :style="{cursor: disableNextStep2 ? 'not-allowed' : 'pointer'}" class="btn">关键词/检索式</div>
              <div @click="handleNextStep" v-if="searchReportTab == '关键词/检索式'" :style="{cursor: disableNextStep2 ? 'not-allowed' : 'pointer'}" class="btn">IPC分类</div>
              <div @click="download" v-if="searchReportTab == 'IPC分类号'" :style="{cursor: disableNextStep2 ? 'not-allowed' : 'pointer'}" class="btn">下载检索报告</div>
            </div>
          </div>

          <!--新申请-->
          <div v-loading="chatLoading3" v-if="generateType == 3" class="result">
            <el-tabs v-model="patentApplyTab">
              <el-tab-pane class="result_cen" :disabled="!patentApplyTabSet.has('技术方案')" label="技术方案" name="技术方案">
                <div class="titles">主要技术方案</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[0], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[0].content"
                />

                <div class="titles">次要技术方案</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[1], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[1].content"
                />
              </el-tab-pane>
              <el-tab-pane class="result_cen" :disabled="!patentApplyTabSet.has('权利要求书')" label="权利要求书" name="权利要求书">
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[2], 4)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[2].content"
                />
              </el-tab-pane>
              <el-tab-pane class="result_cen" :disabled="!patentApplyTabSet.has('摘要/名称/领域/背景')" label="摘要/名称/领域/背景" name="摘要/名称/领域/背景">
                <div class="titles">说明书摘要</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[3], 5)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[3].content"
                />
                <p style="text-align: right; margin-top: 5px;">
                  <span :style="patentApplyAbstractOfDescriptionNumber > 300 ? 'color:red; font-weight:bold' : ''">{{ patentApplyAbstractOfDescriptionNumber }}</span> / 300字
                </p>

                <div class="titles">发明名称</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[4], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[4].content"
                />

                <div class="titles">技术领域</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[5], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[5].content"
                />

                <div class="titles">背景技术</div>
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[6], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[6].content"
                />
              </el-tab-pane>
              <el-tab-pane class="result_cen" :disabled="!patentApplyTabSet.has('发明内容')" label="发明内容" name="发明内容">
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[7], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[7].content"
                />
              </el-tab-pane>
              <el-tab-pane class="result_cen" :disabled="!patentApplyTabSet.has('具体实施方式')" label="具体实施方式" name="具体实施方式">
                <mavon-editor
                  @change="(e) => contentChange(e, chatResult3[8], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[8].content"
                />

                <mavon-editor
                  v-if="chatResult3[9].content != ''"
                  @change="(e) => contentChange(e, chatResult3[9], 3)"
                  :placeholder="' '"
                  style="min-height: 150px;box-shadow: none;z-index: 500"
                  :config="editorConfig"
                  :externalLink="externalLink"
                  defaultOpen="edit"
                  :toolbarsFlag="false"
                  :default-preview="false"
                  :toolbars="markdownOption"
                  class="mavonEditor_1"
                  language="zh-CN"
                  ref="md"
                  v-model="chatResult3[9].content"
                />
              </el-tab-pane>
            </el-tabs>

            <div v-if="chatNumber3 == ''" style="display: flex;align-items: center;justify-content: center;margin-top: 20px">
              <div @click="generate2"  style="margin-right: 10px" class="btn">开始生成</div>
            </div>
            <div v-else style="display: flex;align-items: center;justify-content: center;margin-top: 20px">
              <div v-if="!(patentApplyTab == '权利要求书' && this.patentApplyType == 1)" @click="handleStopStep3"  style="margin-right: 10px" class="btn">终止</div>
              <div v-if="!(patentApplyTab == '权利要求书' && this.patentApplyType == 1)" @click="regenerate" :style="{cursor: disableRegenerate3 ? 'not-allowed' : 'pointer'}" style="margin-right: 10px" class="btn">重新生成</div>

              <div @click="handleNextStep" v-if="patentApplyTab == '技术方案'" :style="{cursor: disableNextStep3 ? 'not-allowed' : 'pointer'}" class="btn">生成权利要求书</div>

              <div @click="handleNextStep" v-if="patentApplyTab == '权利要求书'" :style="{cursor: disableNextStep3 ? 'not-allowed' : 'pointer'}" class="btn">下一步</div>

              <div @click="handleNextStep" v-if="patentApplyTab == '摘要/名称/领域/背景'" :style="{cursor: disableNextStep3 ? 'not-allowed' : 'pointer'}" class="btn">下一步</div>

              <div @click="uploadFiles = true" v-if="patentApplyTab == '发明内容'" :style="{cursor: disableNextStep3 ? 'not-allowed' : 'pointer'}" class="btn">生成具体实施方式</div>

              <div @click="download" v-if="patentApplyTab == '具体实施方式'" :style="{cursor: disableNextStep3 ? 'not-allowed' : 'pointer'}" class="btn">下载申请文件</div>
            </div>
          </div>

          <div class="chat">
            <!--右上上传文件-->
            <div class="upload">
              <div class="upload_item_1" style="display: flex">
                <div style="display: flex;align-items: center">

                  <div style="margin-right: 15px;width:80px;font-size: 14px">技术交底书</div>
                  <el-upload
                    :on-error="errorEvent"
                    :headers="headers"
                    :show-file-list="false"
                    class="upload-demo"
                    action="/aigc/app/file/uploadToMoonshot?fileType=4"
                    :on-success="uploadFn"
                    name="file"
                  >
                    <div v-if="generateType == 1">
                      <div v-if="fileArr1.fileName == ''" class="uploadFile">
                        <i style="font-size: 18px" class="el-icon-upload2"/> 技术交底书上传
                      </div>

                      <div v-else class="uploadFiled">
                        <i style="font-size: 18px" class="el-icon-document"/>
                        <p>{{fileArr1.fileName}}</p>
                        <i class="el-icon-close"/>
                      </div>
                    </div>
                    <div v-if="generateType == 2">
                      <div v-if="fileArr2.fileName == ''" class="uploadFile">
                        <i style="font-size: 18px" class="el-icon-upload2"/> 技术交底书上传
                      </div>

                      <div v-else class="uploadFiled">
                        <i style="font-size: 18px" class="el-icon-document"/>
                        <p>{{fileArr2.fileName}}</p>
                        <i class="el-icon-close"/>
                      </div>
                    </div>
                    <div v-if="generateType == 3">
                      <div v-if="fileArr3.fileName == ''" class="uploadFile">
                        <i style="font-size: 18px" class="el-icon-upload2"/> 技术交底书上传
                      </div>

                      <div v-else class="uploadFiled">
                        <i style="font-size: 18px" class="el-icon-document"/>
                        <p>{{fileArr3.fileName}}</p>
                        <i class="el-icon-close"/>
                      </div>
                    </div>
                  </el-upload>
                </div>

                <div @click="generate" class="btn">立即生成</div>
              </div>

              <div v-if="rotation" class="upload_item_2">
                <div style="display: flex;align-items: center" v-if="generateType == 2">
                  <div style="margin-right: 15px;width: 80px;text-align: left;font-size: 14px">对比文件</div>
                  <el-upload
                    style="width: 65%;"
                    :on-error="errorEvent"
                    :on-exceed="handleExceed"
                    :headers="headers"
                    class="upload-demo"
                    :file-list="fileLists"
                    action="/aigc/app/file/uploadToMoonshot?fileType=5"
                    :on-remove="handleRemoves"
                    :on-success="uploadFns"
                    :limit="5"
                  >
                    <div class="uploadFile">
                      <i style="font-size: 18px" class="el-icon-upload2"></i> 上传对比文件
                    </div>
                  </el-upload>
                </div>

                <div style="display: flex;align-items: center" v-if="generateType == 3">
                  <div style="margin-right: 15px;width: 80px;text-align: left;font-size: 14px">专利领域</div>

                  <el-select class="select" v-model="patentField" placeholder="请选择专利领域">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>

              <div v-if="generateType != 1" class="rotation">
                <i @click="rotation = !rotation" style="font-size: 18px" :class="[{'el-icon-arrow-down': !rotation},{'el-icon-arrow-up': rotation}]"></i>
              </div>
            </div>

            <!--右中对话列表-->
            <div class="chat_content">
              <div id="chatBox" class="chatBox">
                <el-row v-for="(item, index) in (generateType == 1 ? messageData01 : generateType == 2 ? messageData02 : messageData03)" :class="item.type == 'user' ? 'userDialog' : 'assistantDialog'">
                  <!--AI回答样式-->
                  <img v-if="item.type=='assistant'" class="dialogIconAssistant" src="../../assets/images/patentOAAi/fishIcon.png"/>
                  <div style="position: relative; display: inline-block; max-width: 70%">
                    <div v-if="item.type=='assistant'" class="dialogContentAssistant" v-html="item.content"/>
                    <svg
                      v-if="item.type=='assistant' && item.content != ''"
                      @click="handleDelete(item, index)"
                      class="AIDialogueCopyIcon2"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" width="1em"
                      height="18px"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none">
                        <path d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5zM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25z" fill="currentColor"/>
                      </g>
                    </svg>
                    <svg
                      v-if="item.type=='assistant' && item.content != ''"
                      @click="handleCopy(item)"
                      class="AIDialogueCopyIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" width="1em"
                      height="18px"
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1 1 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1zM5.002 8L5 20h10V8zM9 6h8v10h2V4H9zm-2 5h6v2H7zm0 4h6v2H7z"/>
                    </svg>
                    <svg
                      v-show="item.type=='assistant' && item.content != ''"
                      @click="handleRegenerate(item)"
                      class="AIDialogueRegenerateIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" width="1em"
                      height="18px"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" fill="currentColor"/>
                    </svg>
                  </div>

                  <!--用户提问样式-->
                  <div v-if="item.type=='user'" class="dialogContentUser" v-html="item.content"/>
                  <img v-if="item.type=='user'" class="dialogIconUser" src="../../assets/images/patentOAAi/userIcon.png"/>
                  <svg
                    v-if="item.type=='user' && item.content != ''"
                    @click="addPrompt(item.content, '')"
                    class="AIDialogueCopyIcon4"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    height="18px"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" fill="currentColor"/>
                  </svg>
                  <svg
                    v-if="item.type=='user' && item.content != ''"
                    @click="handleDelete(item, index)"
                    class="AIDialogueCopyIcon3"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true" role="img" width="1em"
                    height="18px"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5zM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25z" fill="currentColor"/>
                    </g>
                  </svg>
                </el-row>

                <el-row v-if="generateType == 1 ? ifChatLoading01 : generateType == 2 ? ifChatLoading02 : ifChatLoading03" style="text-align: center">
                  <el-button class="myChatStopGenerate" @click="handleStopStep0(generateType)">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class=" iconify iconify--ri" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16M9 9h6v6H9z"/>
                    </svg>
                    <span>终止生成</span>
                  </el-button>
                </el-row>
              </div>

              <!--右下对话框-->
              <div class="enter">
                <div class="input_">
                  <el-input
                    @keyup.enter.native="send"
                    placeholder="请输入内容"
                    v-model="chatInput"
                  />

                  <img @click="send" src="../../assets/images/oaApplication/feiji.png">

                  <el-popover
                    placement="top-end"
                    width="570"
                    trigger="click"
                  >
                    <div class="promptBoxApply">
                      <div v-for="item in promptList">
                        <el-tooltip v-if="item.prompt.length > 25" placement="top-start">
                          <div slot="content" style="max-width: 300px">{{item.prompt}}</div>
                          <div class="promptContentBox">
                            <p @click="handleClickPrompt(item)">{{item.prompt}}</p>
                            <!--删除-->
                            <svg
                              v-if="item.id != ''"
                              @click.prevent="deletePrompt(item)"
                              class="promptButton"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              width="18px"
                              height="18px"
                              viewBox="0 0 24 24"
                            >
                              <g fill="none">
                                <path d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5zM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25z" fill="currentColor"/>
                              </g>
                            </svg>
                            <!--编辑-->
                            <svg
                              @click.prevent="handleEditPrompt(item)"
                              class="promptButton"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 24 24"
                              width="18px"
                              height="18px"
                            >
                              <g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M9 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-3"/>
                                <path d="M9 15h3l8.5-8.5a1.5 1.5 0 0 0-3-3L9 12v3"/>
                                <path d="M16 5l3 3"/>
                              </g>
                            </svg>
                          </div>
                        </el-tooltip>

                        <div v-else class="promptContentBox">
                          <p @click="handleClickPrompt(item)">{{item.prompt}}</p>
                          <!--删除-->
                          <svg
                            v-if="item.id != ''"
                            @click.prevent="deletePrompt(item)"
                            class="promptButton"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            aria-hidden="true"
                            role="img"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none">
                              <path d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5zM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25z" fill="currentColor"/>
                            </g>
                          </svg>
                          <!--编辑-->
                          <svg
                            @click.prevent="handleEditPrompt(item)"
                            class="promptButton"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24"
                            width="18px"
                            height="18px"
                          >
                            <g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M9 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-3"/>
                              <path d="M9 15h3l8.5-8.5a1.5 1.5 0 0 0-3-3L9 12v3"/>
                              <path d="M16 5l3 3"/>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="AIDialogPromptButton" slot="reference">常用提问</div>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--历史记录-->
    <el-drawer
      class="oa_drawer"
      size="400px"
      :show-close="false"
      :modal="false"
      title=""
      :visible.sync="drawer"
      direction="rtl"
    >
      <div slot="title">
        <div class="header">
          <div class="title">
            <img src="../../assets/images/oaApplication/historyList.png"> 历史记录
          </div>

          <img @click="drawer = false" style="cursor: pointer" src="../../assets/images/oaApplication/close.png">
        </div>
      </div>

      <div>
        <el-table
          :data="historyArr"
          style="width: 100%"
          height="620"
        >
          <el-table-column
            fixed
            show-overflow-tooltip
            label="文件名称"
          >
            <template slot-scope="scope">
              {{scope.row.title}}_申请文件
            </template>
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            prop="createTime"
            label="生成时间"
          />

          <el-table-column
            fixed="right"
            label="操作"
            width="90"
          >
            <template slot-scope="scope">
              <el-button v-if="ifShowHistoryDownload(scope.row)" @click.native.prevent="historyDownload(scope.row)" type="text" size="small">下载</el-button>
              <el-button @click.native.prevent="backview(scope.row)" type="text" size="small">回看</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="pageConfig.pageSize"
            layout="total, prev, pager, next"
            :total="total"
          />
        </div>
      </div>
    </el-drawer>

    <!--上传对比文件-->
    <el-dialog
      custom-class="comparativeFilesDialog"
      center :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
      title="对比文件上传"
      :visible.sync="comparativeFilesDialog"
    >
      <el-upload
        :on-error="errorEvent"
        :on-exceed="handleExceed"
        :headers="headers"
        class="upload-demo"
        :file-list="fileList"
        drag
        action="/aigc/app/file/uploadToMoonshot?fileType=5"
        :on-remove="handleRemoves"
        :on-success="uploadFns"
        :limit="5"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </el-dialog>

    <!--上传附图或实施例-->
    <el-dialog
      custom-class="uploadFiles"
      center :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
      title="上传文件"
      :visible.sync="uploadFiles"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="附图文件">
          <el-upload
            :headers="headers"
            :on-error="errorEvent"
            class="upload-demo"
            :file-list="fufileList"
            :on-success="fuuploadFn"
            drag
            :data="{fileType: 6,chatNumber: chatNumber3}"
            action="/aigc/app/file/uploadForNewApp"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-tab-pane>

        <el-tab-pane label="实施例文件">
          <el-upload
            :headers="headers"
            :on-error="errorEvent"
            class="upload-demo"
            :file-list="shifileList"
            :on-success="shiuploadFn"
            drag
            :data="{fileType: 7,chatNumber: chatNumber3}"
            action="/aigc/app/file/uploadForNewApp"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleNextStep" type="primary">继续</el-button>
      </span>
    </el-dialog>

    <!--文件预览-->
    <el-dialog custom-class="dialogviewFileVisible" width="800px" center title="文件预览" :visible.sync="dialogviewFileVisible">
      <mavon-editor
        @change="(e) => contentChange(e, viewFile, 0)"
        :placeholder="' '"
        style="max-height: 500px;box-shadow: none;z-index: 500;background: #ffffff"
        :config="editorConfig"
        :externalLink="externalLink"
        defaultOpen="edit"
        :toolbarsFlag="false"
        :default-preview="false"
        :toolbars="markdownOption"
        class="mavonEditor_2"
        language="zh-CN"
        ref="md"
        v-model="viewFileContent"
      />

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogviewFileVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!--新增或编辑提示词-->
    <el-dialog
      custom-class="myApplyPromptEditDialog"
      :close-on-click-modal="false"
      title="编辑提示词"
      :visible.sync="ifShowEditPrompt"
      width="30%"
      center
    >
      <el-form>
        <el-form-item label="提示词">
          <el-input autosize type="textarea" v-model="addOrEditPromptConfig.prompt"/>
        </el-form-item>
        <el-form-item label="提示词类型">
          <el-select v-model="addOrEditPromptConfig.promptType">
            <el-option
              v-for="item in promptTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="EditPrompt">确 定</el-button>
        <el-button @click="ifShowEditPrompt = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  const axios = require('axios');
  axios.defaults.timeout = 60 * 1000 * 5;
  import hljsCss from "mavon-editor/src/lib/core/hljs/lang.hljs.css";

  export default {
    name: "index",
    data() {
      return {
        timer:null,
        tabList: [
          { label: '交底书解读', value: 1 },
          { label: '检索报告', value: 2 },
          { label: '新申请撰写', value: 3 }
        ],
        generateType: 1,
        rotation: true,
        chatInput:"",

        promptList:[],
        promptUniversalMap:new Map(),
        promptPersonalMap:new Map(),
        ifShowEditPrompt:false,
        addOrEditPromptConfig:{
          id:null,
          prompt:"",
          promptType:null,
        },
        promptTypeOption:[
          { label:"技术方案", value:"10" },
          { label:"对比文件分析", value:"20" },
          { label:"关键词检索式", value:"30" },
          { label:"分类号", value:"35" },
          { label:"权利要求书", value:"40" },
          { label:"摘要/名称/领域/背景", value:"50" },
          { label:"发明内容", value:"60" },
          { label:"具体实施方式", value:"70" },
        ],

        //历史记录
        pageConfig: {
          pageNum: 1,
          pageSize: 10
        },
        total: 0,
        drawer: false,
        historyArr: [],
        ifShowPrompt:false,

        //交底书解读
        chatLoading1: false,
        caseNumber1: '',
        chatNumber1: '',
        fileArr1: { fileName: '', fileId: ''},
        stopSignal1: axios.CancelToken.source(),
        disableRegenerate1:true,
        disableNextStep1:true,
        chatResult1:[{content:''},{content:''},{content:''},{content:''}],
        ifChatLoading01:false,
        stopSignal01: axios.CancelToken.source(),
        messageData01: [],

        //检索报告
        //显示展开的对比文件
        chatLoading2: false,
        caseNumber2: '',
        chatNumber2: '',
        fileArr2: { fileName: '', fileId: ''},
        fileList: [],
        comparativeFilesDialog: false,
        stopSignal2: axios.CancelToken.source(),
        disableRegenerate2:true,
        disableNextStep2:true,
        searchReportTabSet: new Set(),
        searchReportTab:'技术方案',
        chatResult2:[{content:''},{content:''},{content:''},{content:''},{content:''}],
        comparativeFiles:[],
        //对比文件
        activeName: 0,
        viewFile: '',
        viewFileContent:'',
        dialogviewFileVisible: false,
        ifChatLoading02:false,
        stopSignal02: axios.CancelToken.source(),
        messageData02: [],

        //新申请
        //专利领域
        chatLoading3: false,
        fileArr3: { fileName: '', fileId: ''},
        caseNumber3: '',
        chatNumber3: '',
        patentApplyType:0,
        stopSignal3: axios.CancelToken.source(),
        disableRegenerate3:true,
        disableNextStep3:true,
        patentField: '',
        patentApplyTabSet: new Set(),
        patentApplyTab:'权利要求书',
        options: [
          { value: 1, label: '化学' },
          { value: 2, label: '机械' },
          { value: 3, label: '电学' }
        ],
        //附图文件列表
        fufileList: [],
        //实施例文件列表
        shifileList: [],
        //是否展示上传附图、实施例文件浮框
        uploadFiles: false,
        chatResult3:[{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''}],
        patentApplyAbstractOfDescriptionNumber:0,
        ifChatLoading03:false,
        stopSignal03: axios.CancelToken.source(),
        messageData03: [],

        markdownOption: { //自定义文本标签
          bold: true, // 粗体
          italic: true,// 斜体
          strikethrough: true, // 中划线
          underline: true, // 下划线
        },
        externalLink: {
          markdown_css: function () {
            return '';
          },
          hljs_js: function () {
            return '';
          },
          hljs_lang: function (lang) {
            return '';
          },
          hljs_css: function (css) {
            if (hljsCss[css]) {
              return '';
            }
            return '';
          },
          katex_js: function () {
            return '';
          },
          katex_css: function () {
            return '';
          }
        },
        editorConfig: {
          toolbar: {
            githubLink: false, // 禁用 GitHub 链接
          },
        },
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
      }
    },
    computed: {
      fileLists(){
        let list = JSON.parse(JSON.stringify(this.fileList))
        return  list.map((item,index) => {
          item.name = `对比文件${index +1}:  ${item.fileName}`
          return item
        })
      },
    },
    watch: {
      generateType:{
        handler(){
          this.mySetPrompt()
        }
      },
      searchReportTab:{
        handler(){
          this.mySetPrompt()
        }
      },
      patentApplyTab:{
        handler(){
          this.mySetPrompt()
        }
      },
      fileList: {
        handler(newVal, oldVal) {
          this.$nextTick(() => {
            // 这个回调将在 DOM 更新完成后执行
            this.jisuan()
          });
        },
        deep: true,  // 深度监听
        immediate: true
      },
      rotation() {
        this.$nextTick(() => {
          // 这个回调将在 DOM 更新完成后执行
          this.jisuan()
        });
      }
    },
    mounted() {
      this.searchReportTabSet.add("技术方案")
      this.patentApplyTabSet.add("权利要求书")
      this.history()
      this.$nextTick(() => {
        // 这个回调将在 DOM 更新完成后执行
        this.jisuan()
      })

      setTimeout(() => {
        this.$nextTick(() => {
          try {
            const elements = document.querySelector('.result');
            elements?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          }
          catch (e) {}
          try {
            const element = document.querySelector('.result_cen');
            element?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          }
          catch (e) {}
      })
    })

      this.queryPrompt()
  },
    methods: {
      queryPrompt(){
        // 10-技术方案 20-对比文件分析 30-关键词检索式 35-分类号
        // 40-权利要求书 50-摘要/名称/领域/背景 60-发明内容 70-具体实施方式
        let chatTypeList = ['10','20','30', '35','40','50','60','70']
        let sendNumber = 0

        chatTypeList.forEach(item=>{
          // this.$axios.get("/aigc/app/new/prompt/list", { params:{ promptType:item }})
          //   .then(response=>{
          //     this.promptUniversalMap.set(item, response.data.data)
          //     sendNumber++
          //
          //     if(sendNumber == chatTypeList.length * 2){
          //       this.mySetPrompt()
          //     }
          //   })

          this.$axios.get("/aigc/app/new/prompt/owner", { params:{ promptType:item }})
            .then(response=>{
              this.promptPersonalMap.set(item, response.data.data)
              sendNumber++

              if(sendNumber == chatTypeList.length){
                this.mySetPrompt()
              }
            })
        })
      },
      mySetPrompt(){
        // 10-技术方案 20-对比文件分析 30-关键词检索式 35-分类号
        // 40-权利要求书 50-摘要/名称/领域/背景 60-发明内容 70-具体实施方式

        this.promptList = []
        let promptListPersonal = []
        // let promptListUniversal = []
        switch (this.generateType){
          case 1:
            // promptListUniversal = this.promptUniversalMap.get("10")
            promptListPersonal = this.promptPersonalMap.get("10")
            break
          case 2:
            // switch (this.searchReportTab){
            //   case "技术方案":
            //     promptListUniversal = this.promptUniversalMap.get("10")
            //     promptListPersonal = this.promptPersonalMap.get("10")
            //     break
            //   case "对比文件分析":
            //     promptListUniversal = this.promptUniversalMap.get("20")
            //     promptListPersonal = this.promptPersonalMap.get("20")
            //     break
            //   case "关键词/检索式":
            //     promptListUniversal = this.promptUniversalMap.get("30")
            //     promptListPersonal = this.promptPersonalMap.get("30")
            //     break
            //   case "IPC分类号":
            //     promptListUniversal = this.promptUniversalMap.get("35")
            //     promptListPersonal = this.promptPersonalMap.get("35")
            //     break
            // }
            if(this.promptPersonalMap.has("10")){
              this.promptPersonalMap.get("10").forEach(item=>{
                promptListPersonal.push(item)
              })
            }
            if(this.promptPersonalMap.has("20")){
              this.promptPersonalMap.get("20").forEach(item=>{
                promptListPersonal.push(item)
              })
            }
            if(this.promptPersonalMap.has("30")){
              this.promptPersonalMap.get("30").forEach(item=>{
                promptListPersonal.push(item)
              })
            }
            if(this.promptPersonalMap.has("35")){
              this.promptPersonalMap.get("35").forEach(item=>{
                promptListPersonal.push(item)
              })
            }
            break
          case 3:
            switch (this.patentApplyTab){
              case "技术方案":
                // promptListUniversal = this.promptUniversalMap.get("10")
                promptListPersonal = this.promptPersonalMap.get("10")
                break
              case "权利要求书":
                // promptListUniversal = this.promptUniversalMap.get("40")
                promptListPersonal = this.promptPersonalMap.get("40")
                break
              case "摘要/名称/领域/背景":
                // promptListUniversal = this.promptUniversalMap.get("50")
                promptListPersonal = this.promptPersonalMap.get("50")
                break
              case "发明内容":
                // promptListUniversal = this.promptUniversalMap.get("60")
                promptListPersonal = this.promptPersonalMap.get("60")
                break
              case "具体实施方式":
                // promptListUniversal = this.promptUniversalMap.get("70")
                promptListPersonal = this.promptPersonalMap.get("70")
                break
            }
            break
        }
        promptListPersonal.forEach(item=>{
          this.promptList.push(item)
        })
        this.promptList.push({
          id:"",
          prompt:"请结合交底书，以下核心方案为基础，结合交底书对每个步骤进行详细扩展",
          title:"请结合交底书，以下核心方案为基础，结合交底书对每个步骤进行详细扩展"
        })
        // promptListUniversal.forEach(item=>{
        //   item.type = 'universe'
        //   this.promptList.push(item)
        // })
      },

      addPrompt(content, type){
        let promptType = null

        switch (this.generateType){
          case 1:
            promptType = "10"
            break
          case 2:
            switch (this.searchReportTab){
              case "技术方案":
                promptType = "10"
                break
              case "对比文件分析":
                promptType = "20"
                break
              case "关键词/检索式":
                promptType = "30"
                break
              case "IPC分类号":
                promptType = "35"
                break
            }
            break
          case 3:
            switch (this.patentApplyTab){
              case "技术方案":
                promptType = "10"
                break
              case "权利要求书":
                promptType = "40"
                break
              case "摘要/名称/领域/背景":
                promptType = "50"
                break
              case "发明内容":
                promptType = "60"
                break
              case "具体实施方式":
                promptType = "70"
                break
            }
            break
        }

        if (type != ''){
          promptType = type
        }

        let param = {
          promptType:promptType,
          title:content,
          prompt:content,
          sort:0
        }

        this.$axios.post("/aigc/app/new/prompt", param)
          .then(()=>{
            this.$message.success("已添加到常用提示词中")
            this.queryPrompt()
          })
      },
      EditPrompt(){
        if(this.addOrEditPromptConfig.prompt == ""){
          this.$message.error("请输入提示词")
          return
        }
        if(this.addOrEditPromptConfig.promptType == null){
          this.$message.error("请选择提示词类型")
          return
        }

        if(this.addOrEditPromptConfig.id == ''){
          this.addPrompt(this.addOrEditPromptConfig.prompt, this.addOrEditPromptConfig.promptType)
          this.ifShowEditPrompt = false
          return
        }

        let param = {
          id:this.addOrEditPromptConfig.id,
          promptType:this.addOrEditPromptConfig.promptType,
          title:this.addOrEditPromptConfig.prompt,
          prompt:this.addOrEditPromptConfig.prompt,
          sort: 0
        }
        this.$axios.put("/aigc/app/new/prompt", param)
          .then(()=>{
            this.queryPrompt()
            this.ifShowEditPrompt = false
          })
      },
      handleEditPrompt(item){
        this.ifShowEditPrompt = true
        this.addOrEditPromptConfig = {
          id:item.id,
          prompt:item.prompt,
          promptType: item.promptType,
        }
      },
      deletePrompt(item){
        this.$axios.delete("/aigc/app/new/prompt/"+item.id)
          .then(()=>{
            this.queryPrompt()
          })
      },

    //立即生成
    async generate() {
      let param = null
      let response = null
      let chatTypeList = []
      switch (this.generateType) {
        case 1:
          if(this.fileArr1.fileId == ''){
            this.$message.error("请先上传生成所需的文件")
            return
          }

          this.caseNumber1 = Math.floor(10000 + Math.random() * 90000)
          param = {
            "caseNumber": this.caseNumber1,
            "type": 1,
            "discloseFileIds": this.fileArr1.fileId,
          }
          response = await this.$axios.post(`/aigc/app/new/create`, param)
          if(response.data.code != 0){
            this.$message(response.data.msg)
            return
          }

          this.chatNumber1 = response.data.data.chatNumber

          this.disableRegenerate1 = true
          this.disableNextStep1 = true
          this.chatResult1 = [{content:''},{content:''},{content:''},{content:''}]
          this.ifChatLoading01 = false
          this.messageData01 = []

          chatTypeList = ['10', '20', '30', '40']
          this.chatLoading1 = true
          this.sendMessageList(chatTypeList, this.generateType)
          break
        case 2:
          if(this.fileArr2.fileId == '' || this.fileList.length < 1){
            this.$message.error("请先上传生成所需的文件")
            return
          }

          this.caseNumber2 = Math.floor(10000 + Math.random() * 90000)
          param = {
            "caseNumber": this.caseNumber2,
            "type": 2,
            "discloseFileIds": this.fileArr2.fileId,
            "similarFileIds": this.fileList.map(item => item.fileId).join(',')
          }
          response = await this.$axios.post(`/aigc/app/new/create`, param)
          if(response.data.code != 0){
            this.$message(response.data.msg)
            return
          }
          this.chatNumber2 = response.data.data.chatNumber

          this.rotation = false
          this.disableRegenerate2 = true
          this.disableNextStep2 = true
          this.searchReportTab = '技术方案'
          this.chatResult2 = [{content:''},{content:''},{content:''},{content:''},{content:''}]
          this.ifChatLoading02 = false
          this.messageData02 = []

          chatTypeList = ['10', '20', '50']
          this.chatLoading2 = true
          this.sendMessageList(chatTypeList, this.generateType)
          break
        case 3:
          if(this.fileArr3.fileId == ''){
            this.$message.error("请先上传生成所需的文件")
            return
          }
          if(this.patentField == ''){
            this.$message.error("请先选择专利领域")
            return
          }

          this.caseNumber3 = Math.floor(10000 + Math.random() * 90000)
          param = {
            "caseNumber": this.caseNumber3,
            "type": 3,
            "discloseFileIds": this.fileArr3.fileId,
            "patentField": this.patentField,
          }
          response = await this.$axios.post(`/aigc/app/new/create`, param)
          if(response.data.code != 0){
            this.$message(response.data.msg)
            return
          }
          this.chatNumber3 = response.data.data.chatNumber

          this.rotation = false
          this.disableRegenerate3 = true
          this.disableNextStep3 = true
          this.patentApplyTabSet = new Set()
          this.patentApplyTabSet.add('技术方案')
          this.$nextTick(()=>{
            this.patentApplyTab = '技术方案'
          })
          this.chatResult3 = [{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''}]
          this.ifChatLoading03 = false
          this.messageData03 = []

          this.chatLoading3 = true

          let response1 = await this.$axios.post(`/aigc/app/new/message`, {
            "chatNumber": this.chatNumber3,
            "caseNumber": this.caseNumber3,
            "chatType": '10',
            "prompt": ""
          })
          let response2 = await this.$axios.post(`/aigc/app/new/message`, {
            "chatNumber": this.chatNumber3,
            "caseNumber": this.caseNumber3,
            "chatType": '20',
            "prompt": ""
          })

          let list = []
          list.push(
            this.$axios.get("/aigc/app/new/completions?conversationId="+response1.data.data)
          )
          list.push(
            this.$axios.get("/aigc/app/new/completions?conversationId="+response2.data.data)
          )

          Promise.all(list)
            .then(()=>{
              chatTypeList = ['100', '110']
              this.sendMessageList(chatTypeList, this.generateType)
            })
            .catch(err => {
              this.$message.error(err.msg);
              return
            })

          break
      }
    },

    async generate2(){
      if(this.fileArr3.fileId == ''){
        this.$message.error("请先上传生成所需的文件")
        return
      }
      if(this.patentField == ''){
        this.$message.error("请先选择专利领域")
        return
      }
      if(this.chatResult3[2].content == ''){
        this.$message.error("请输入权利要求书")
        return
      }

      this.patentApplyType = 1

      this.caseNumber3 = Math.floor(10000 + Math.random() * 90000)
      let claimInfo = this.chatResult3[2].content
      let param = {
        "caseNumber": this.caseNumber3,
        "type": 3,
        "discloseFileIds": this.fileArr3.fileId,
        "patentField": this.patentField,
        claimInfo: claimInfo
      }
      let response = await this.$axios.post(`/aigc/app/new/create`, param)
      if(response.data.code != 0){
        this.$message(response.data.msg)
        return
      }
      this.chatNumber3 = response.data.data.chatNumber

      this.rotation = false
      this.disableRegenerate3 = true
      this.disableNextStep3 = true

      this.patentApplyTabSet.add('摘要/名称/领域/背景')
      this.$nextTick(()=>{
        this.patentApplyTab = '摘要/名称/领域/背景'
      })

      this.chatResult3 = [{content:''},{content:''},{content:claimInfo},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''}]
      this.ifChatLoading03 = false
      this.messageData03 = []

      let chatTypeList = ['125','130','140','150']
      this.sendMessageList(chatTypeList ,3)
    },

    handleNextStep(){
      let chatTypeList = []
      let saveList = []
      let data = null
      switch (this.generateType){
        case 2:
          if(this.disableNextStep2) return
          this.chatLoading2 = true
          switch (this.searchReportTab){
            case '对比文件分析':
              this.searchReportTabSet.add('关键词/检索式')
              this.$nextTick(()=>{
                this.searchReportTab = '关键词/检索式'
              })

              chatTypeList = ['70']
              this.comparativeFiles.forEach(item=>{
                data = {
                  parentMessageId:item.parentMessageId,
                  messageId: item.messageId,
                  content:item.differenceContent,
                }

                saveList.push(this.$axios.put("/aigc/app/new/message", data))
              })
              break
            case '关键词/检索式':
              this.searchReportTabSet.add('IPC分类号')
              this.$nextTick(()=>{
                this.searchReportTab = 'IPC分类号'
              })

              chatTypeList = ['80']
              data = {
                parentMessageId:this.chatResult2[3].parentMessageId,
                messageId: this.chatResult2[3].messageId || this.chatResult2[3].id,
                content:this.chatResult2[3].content,
              }
              saveList.push(this.$axios.put("/aigc/app/new/message", data))
              break
          }

          this.disableNextStep2 = true
          this.disableRegenerate2 = true

          Promise.all(saveList)
            .then(()=>{
              this.sendMessageList(chatTypeList ,2)
            })
          break
        case 3:
          if(this.disableNextStep3) return
          this.chatLoading3 = true
          switch (this.patentApplyTab){
            case '技术方案':
              this.patentApplyTabSet.add('权利要求书')
              this.$nextTick(()=>{
                this.patentApplyTab = '权利要求书'
              })

              if(this.patentField == 3) chatTypeList = ['120']
              else chatTypeList = ['120', '121']

              for(let i = 0; i < 2; i++){
                data = {
                  parentMessageId:this.chatResult3[i].parentMessageId,
                  messageId: this.chatResult3[i].messageId || this.chatResult3[i].id,
                  content:this.chatResult3[i].content,
                }
                saveList.push(this.$axios.put("/aigc/app/new/message", data))
              }
              break
            case '权利要求书':
              this.patentApplyTabSet.add('摘要/名称/领域/背景')
              this.$nextTick(()=>{
                this.patentApplyTab = '摘要/名称/领域/背景'
              })

              chatTypeList = ['125','130','140','150']

              data = {
                parentMessageId:this.chatResult3[2].parentMessageId,
                messageId: this.chatResult3[2].messageId || this.chatResult3[2].id,
                content:this.chatResult3[2].content,
              }
              saveList.push(this.$axios.put("/aigc/app/new/message", data))
              break
            case '摘要/名称/领域/背景':
              this.patentApplyTabSet.add('发明内容')
              this.$nextTick(()=>{
                this.patentApplyTab = '发明内容'
              })

              chatTypeList = ['160']

              for(let i = 3; i < 6; i++){
                data = {
                  parentMessageId:this.chatResult3[i].parentMessageId,
                  messageId: this.chatResult3[i].messageId || this.chatResult3[i].id,
                  content:this.chatResult3[i].content,
                }
                saveList.push(this.$axios.put("/aigc/app/new/message", data))
              }
              break
            case '发明内容':
              this.patentApplyTabSet.add('具体实施方式')
              this.$nextTick(()=>{
                this.patentApplyTab = '具体实施方式'
              })

              switch (this.patentField){
                case 1:
                  chatTypeList = ['170', '180']
                  break
                case 2:
                  chatTypeList = ['210', '220', '215']
                  break
                case 3:
                  if (this.shifileList.length == 0) chatTypeList = ['190']
                  else chatTypeList = ['190', '200']
                  break
              }

              data = {
                parentMessageId:this.chatResult3[7].parentMessageId,
                messageId: this.chatResult3[7].messageId || this.chatResult3[7].id,
                content:this.chatResult3[7].content
              }
              saveList.push(this.$axios.put("/aigc/app/new/message", data))
              this.uploadFiles = false
              break
          }

          this.disableNextStep3 = true
          this.disableRegenerate3 = true
          Promise.all(saveList)
            .then(()=>{
              this.sendMessageList(chatTypeList ,3)
            })
          break
      }
    },

    regenerate(){
      let chatTypeList = []
      switch (this.generateType){
        case 1:
          this.chatLoading1 = true
          this.chatResult1 = [{content:''},{content:''},{content:''},{content:''}]
          chatTypeList = ['10','20','30','40']

          this.disableNextStep1 = true
          this.disableRegenerate1 = true

          this.sendMessageList(chatTypeList ,1)
          break
        case 2:
          this.chatLoading2 = true
          switch (this.searchReportTab){
            case '技术方案':
              this.chatResult2[0] = {content:''}
              this.chatResult2[1] = {content:''}
              this.chatResult2[2] = {content:''}
              chatTypeList = ['10','20','50']
              break
            case '对比文件分析':
              this.comparativeFiles = []
              this.$axios.get('/aigc/app/new/getSimilarPatents/' + this.caseNumber2 + '?chatNumber=' + this.chatNumber2).then(({data}) => {
                this.comparativeFiles = data.data
                let chatTypeList = ['60']
                this.comparativeFiles.forEach(item=>{
                  if(item.difference != '60'){
                    chatTypeList.push(item.difference)
                  }
                })

                this.disableNextStep2 = true
                this.disableRegenerate2 = true

                this.sendMessageList(chatTypeList ,2)
              })
              return
              break
            case '关键词/检索式':
              this.chatResult2[3] = {content:''}
              chatTypeList = ['70']
              break
            case 'IPC分类号':
              this.chatResult2[4] = {content:''}
              chatTypeList = ['80']
              break
          }

          this.disableNextStep2 = true
          this.disableRegenerate2 = true

          this.sendMessageList(chatTypeList ,2)
          break
        case 3:
          this.chatLoading3 = true
          switch (this.patentApplyTab){
            case '技术方案':
              this.chatResult3[0] = {content:''}
              this.chatResult3[1] = {content:''}

              chatTypeList = ['100', '110']
              break
            case '权利要求书':
              this.chatResult3[2] = {content:''}

              if(this.patentField == 3) chatTypeList = ['120']
              else chatTypeList = ['120', '121']
              break
            case '摘要/名称/领域/背景':
              this.chatResult3[3] = {content:''}
              this.chatResult3[4] = {content:''}
              this.chatResult3[5] = {content:''}
              this.chatResult3[6] = {content:''}

              chatTypeList = ['125','130','140','150']
              break
            case '发明内容':
              this.chatResult3[7] = {content:''}

              chatTypeList = ['160']
              break
            case '具体实施方式':
              this.chatResult3[8] = {content:''}
              this.chatResult3[9] = {content:''}

              switch (this.patentField){
                case 3:
                  if (this.shifileList.length == 0) chatTypeList = ['190']
                  else chatTypeList = ['190', '200']
                  break
                case 1:
                  chatTypeList = ['170', '180']
                  break
                case 2:
                  chatTypeList = ['210', '220', '215']
                  break
              }
              break
          }

          this.disableNextStep3 = true
          this.disableRegenerate3 = true

          if(this.patentApplyTab == '技术方案'){
            let deleteList = []
            deleteList.push(this.$axios.delete('/aigc/app/new/message/'+ this.chatNumber3 +'/' + 10))
            deleteList.push(this.$axios.delete('/aigc/app/new/message/'+ this.chatNumber3 +'/' + 20))

            Promise.all(deleteList)
              .then(async () => {
                let response1 = await this.$axios.post(`/aigc/app/new/message`, {
                  "chatNumber": this.chatNumber3,
                  "caseNumber": this.caseNumber3,
                  "chatType": '10',
                  "prompt": ""
                })
                let response2 = await this.$axios.post(`/aigc/app/new/message`, {
                  "chatNumber": this.chatNumber3,
                  "caseNumber": this.caseNumber3,
                  "chatType": '20',
                  "prompt": ""
                })

                let list = []

                list.push(
                  this.$axios.get("/aigc/app/new/completions?conversationId=" + response1.data.data)
                )
                list.push(
                  this.$axios.get("/aigc/app/new/completions?conversationId=" + response2.data.data)
                )

                Promise.all(list)
                  .then(() => {
                    this.sendMessageList(chatTypeList, 3)
                  })
              })
          }
          else {
            this.sendMessageList(chatTypeList ,3)
          }
          break
      }
    },

    sendMessageList(chatTypeList, generateType){
      if(chatTypeList.length > 0){
        let chatType = chatTypeList[0]
        let fileIndex = 0
        if(Number(chatType) >= 60 && Number(chatType) < 65){
          this.comparativeFiles.forEach((item, index)=>{
            if (chatType == item.difference){
              fileIndex = index
              this.activeName = item.fileId
            }
          })
        }

        let chatNumber = generateType == 1 ? this.chatNumber1 : generateType == 2 ? this.chatNumber2 : this.chatNumber3
        this.$axios.delete('/aigc/app/new/message/'+ chatNumber +'/' + chatType)
          .then(()=>{
            this.$axios.post(`/aigc/app/new/message`, {
              "chatNumber": generateType == 1 ? this.chatNumber1 : generateType == 2 ? this.chatNumber2 : this.chatNumber3,
              "caseNumber": generateType == 1 ? this.caseNumber1 : generateType == 2 ? this.caseNumber2 : this.caseNumber3,
              "chatType": chatType,
              "prompt": ""
            })
              .then(({data}) => {
                if (data.code == 0) {
                  this.$axios({
                    method: 'GET',
                    responseType: 'stream',
                    cancelToken: generateType == 1 ? this.stopSignal1.token : generateType == 2 ? this.stopSignal2.token : this.stopSignal3.token,
                    url: "/aigc/app/new/completions?conversationId=" + data.data,
                    onDownloadProgress: ({target}) => {
                      if((chatType != '210' && chatType != '120') || (chatType == '120' && this.patentField == 3)) {
                        switch (generateType){
                          case 1:
                            this.chatLoading1 = false
                            break
                          case 2:
                            this.chatLoading2 = false
                            break
                          case 3:
                            this.chatLoading3 = false
                            break
                        }
                      }

                      let response = target.response
                      let itemList = ""
                      let item = {content:''}
                      if (!response.endsWith("}")) {
                        itemList = response.split("{")
                        item = "{" + itemList[itemList.length - 2]
                      }
                      else {
                        itemList = response.split("{")
                        item = "{" + itemList[itemList.length - 1]
                      }
                      item = JSON.parse(item)
                      if(item && item.content != ''){
                        item.content = item.content.replaceAll("#", "").replaceAll("*", "")
                      }

                      switch (chatType+"-"+generateType){
                        case "10-1":
                          this.$set(this.chatResult1, 0, item)
                          break
                        case "20-1":
                          this.$set(this.chatResult1, 1, item)
                          break
                        case "30-1":
                          this.$set(this.chatResult1, 2, item)
                          break
                        case "40-1":
                          this.$set(this.chatResult1, 3, item)
                          break
                        case "10-2":
                          this.$set(this.chatResult2, 0, item)
                          break
                        case "20-2":
                          this.$set(this.chatResult2, 1, item)
                          break
                        case "50-2":
                          this.$set(this.chatResult2, 2, item)
                          break
                        case "60-2":
                        case "61-2":
                        case "62-2":
                        case "63-2":
                        case "64-2":
                          this.$set(this.comparativeFiles, fileIndex, {
                            ...this.comparativeFiles[fileIndex],
                            differenceContent: item.content
                          })
                          this.$set(this.comparativeFiles, fileIndex, {
                            ...this.comparativeFiles[fileIndex],
                            messageId: item.id
                          })
                          this.$set(this.comparativeFiles, fileIndex, {
                            ...this.comparativeFiles[fileIndex],
                            parentMessageId: item.parentMessageId
                          })
                          break
                        case "70-2":
                          this.$set(this.chatResult2, 3, item)
                          break
                        case "80-2":
                          this.$set(this.chatResult2, 4, item)
                          break
                        case "100-3":
                          this.$set(this.chatResult3, 0, item)
                          break
                        case "110-3":
                          this.$set(this.chatResult3, 1, item)
                          break
                        case "120-3":
                          if(this.patentField == 3){
                            this.$set(this.chatResult3, 2, item)
                          }
                          break
                        case "121-3":
                          this.$set(this.chatResult3, 2, item)
                          break
                        case "125-3":
                          this.$set(this.chatResult3, 3, item)
                          break
                        case "130-3":
                          this.$set(this.chatResult3, 4, item)
                          break
                        case "140-3":
                          this.$set(this.chatResult3, 5, item)
                          break
                        case "150-3":
                          this.$set(this.chatResult3, 6, item)
                          break
                        case "160-3":
                          this.$set(this.chatResult3, 7, item)
                          break
                        case "170-3":
                          this.$set(this.chatResult3, 8, item)
                          break
                        case "180-3":
                          this.$set(this.chatResult3, 9, item)
                          break
                        case "190-3":
                          this.$set(this.chatResult3, 8, item)
                          break
                        case "200-3":
                          this.$set(this.chatResult3, 9, item)
                          break
                        case "220-3":
                          this.$set(this.chatResult3, 8, item)
                          break
                        case "215-3":
                          this.$set(this.chatResult3, 9, item)
                          break
                      }
                    }
                  })
                    .then(()=>{
                      chatTypeList.splice(0, 1)
                      this.sendMessageList(chatTypeList, generateType)
                      let saveList = []
                      let data = null

                      switch (chatType+"-"+generateType){
                        case "40-1":
                          this.chatResult1.forEach(item=>{
                            let data = {
                              parentMessageId:item.parentMessageId,
                              messageId: item.messageId || item.id,
                              content:item.differenceContent || item.content,
                            }

                            saveList.push(this.$axios.put("/aigc/app/new/message", data))
                          })

                          this.disableNextStep1 = false
                          this.disableRegenerate1 = false
                          break
                        case "50-2":
                          for(let i = 0; i < 3; i++){
                            let data = this.chatResult2[i]
                            if(!data.messageId){
                              data.messageId = data.id
                            }
                            saveList.push(this.$axios.put("/aigc/app/new/message", data))
                          }

                          this.disableNextStep2 = false
                          this.disableRegenerate2 = false
                          break
                        case "60-2":
                        case "61-2":
                        case "62-2":
                        case "63-2":
                        case "64-2":
                          let flag = 0
                          this.comparativeFiles.forEach(item=>{
                            if (item.differenceContent != ''){
                              flag++
                            }
                          })
                          if (flag == this.comparativeFiles.length){
                            this.comparativeFiles.forEach(item=>{
                              let data = {
                                parentMessageId:item.parentMessageId,
                                messageId: item.messageId,
                                content:item.differenceContent,
                              }

                              saveList.push(this.$axios.put("/aigc/app/new/message", data))
                            })

                            this.disableNextStep2 = false
                            this.disableRegenerate2 = false
                          }
                          break
                        case "70-2":
                          data = {
                            parentMessageId:this.chatResult2[3].parentMessageId,
                            messageId: this.chatResult2[3].messageId || this.chatResult2[3].id,
                            content:this.chatResult2[3].content,
                          }
                          saveList.push(this.$axios.put("/aigc/app/new/message", data))

                          this.disableNextStep2 = false
                          this.disableRegenerate2 = false
                          break
                        case "80-2":
                          data = {
                            parentMessageId:this.chatResult2[4].parentMessageId,
                            messageId: this.chatResult2[4].messageId || this.chatResult2[4].id,
                            content:this.chatResult2[4].content,
                          }
                          saveList.push(this.$axios.put("/aigc/app/new/message", data))

                          this.disableNextStep2 = false
                          this.disableRegenerate2 = false
                          break
                        case "110-3":
                          for(let i = 0; i < 2; i++){
                            data = {
                              parentMessageId:this.chatResult3[i].parentMessageId,
                              messageId: this.chatResult3[i].messageId || this.chatResult3[i].id,
                              content:this.chatResult3[i].content,
                            }
                            saveList.push(this.$axios.put("/aigc/app/new/message", data))
                          }

                          this.disableNextStep3 = false
                          this.disableRegenerate3 = false
                          break
                        case "120-3":
                          if(this.patentField != 3){
                            break
                          }
                        case "121-3":
                          data = {
                            parentMessageId:this.chatResult3[2].parentMessageId,
                            messageId: this.chatResult3[2].messageId || this.chatResult3[2].id,
                            content:this.chatResult3[2].content,
                          }
                          saveList.push(this.$axios.put("/aigc/app/new/message", data))

                          this.disableNextStep3 = false
                          this.disableRegenerate3 = false
                          break
                        case "150-3":
                          for(let i = 3; i < 7; i++){
                            data = {
                              parentMessageId:this.chatResult3[i].parentMessageId,
                              messageId: this.chatResult3[i].messageId || this.chatResult3[i].id,
                              content:this.chatResult3[i].content,
                            }
                            saveList.push(this.$axios.put("/aigc/app/new/message", data))
                          }

                          this.disableNextStep3 = false
                          this.disableRegenerate3 = false
                          break
                        case "160-3":
                          data = {
                            parentMessageId:this.chatResult3[7].parentMessageId,
                            messageId: this.chatResult3[7].messageId || this.chatResult3[7].id,
                            content:this.chatResult3[7].content,
                          }
                          saveList.push(this.$axios.put("/aigc/app/new/message", data))

                          this.disableNextStep3 = false
                          this.disableRegenerate3 = false
                          break
                        case "180-3":
                        case "200-3":
                        case "215-3":
                          for(let i = 8; i < 10; i++){
                            data = {
                              parentMessageId:this.chatResult3[i].parentMessageId,
                              messageId: this.chatResult3[i].messageId || this.chatResult3[i].id,
                              content:this.chatResult3[i].content,
                            }
                            saveList.push(this.$axios.put("/aigc/app/new/message", data))
                          }

                          this.disableNextStep3 = false
                          this.disableRegenerate3 = false
                          break
                        case "190-3":
                          if(this.shifileList.length == 0){
                            data = {
                              parentMessageId:this.chatResult3[8].parentMessageId,
                              messageId: this.chatResult3[8].messageId || this.chatResult3[8].id,
                              content:this.chatResult3[8].content,
                            }
                            saveList.push(this.$axios.put("/aigc/app/new/message", data))

                            this.disableNextStep3 = false
                            this.disableRegenerate3 = false
                          }
                      }
                      Promise.all(saveList)
                    })
                }
              })
              .catch(err => {
                this.$message.error('服务器繁忙，请稍后重试');
              })
          })
      }
    },

    download(fileName){
      let saveList = []
      let title = this.generateType == 1 ? this.fileArr1.fileName : this.generateType == 2 ? this.fileArr2.fileName : this.fileArr3.fileName
      switch (this.generateType){
        case 1:
          if(this.disableNextStep1) return
          saveList = []
          this.chatResult1.forEach(item=>{
            let data = {
              parentMessageId:item.parentMessageId,
              messageId: item.messageId || item.id,
              content:item.differenceContent || item.content,
            }

            saveList.push(this.$axios.put("/aigc/app/new/message", data))
          })

          break
        case 2:
          if(this.disableNextStep2) return
          saveList = []
          this.chatResult2.forEach(item=>{
            let data = {
              parentMessageId:item.parentMessageId,
              messageId: item.messageId || item.id,
              content:item.differenceContent || item.content,
            }

            saveList.push(this.$axios.put("/aigc/app/new/message", data))
          })
          this.comparativeFiles.forEach(item=>{
            let data = {
              parentMessageId:item.parentMessageId,
              messageId: item.messageId || item.id,
              content:item.differenceContent || item.content,
            }

            saveList.push(this.$axios.put("/aigc/app/new/message", data))
          })
          break
        case 3:
          if(this.disableNextStep3) return
          saveList = []
          let i = this.patentApplyType == 0 ? 0 : 3
          let j = (this.patentField == 3 && this.shifileList.length == 0) ? this.chatResult3.length - 1 : this.chatResult3.length
          for(; i < j; i++){
            let data = {
              parentMessageId:this.chatResult3[i].parentMessageId,
              messageId: this.chatResult3[i].messageId || this.chatResult3[i].id,
              content:this.chatResult3[i].differenceContent || this.chatResult3[i].content,
            }

            saveList.push(this.$axios.put("/aigc/app/new/message", data))
          }
          break
      }

      Promise.all(saveList)
        .then(()=>{
          this.$axios.get(`/aigc/app/new/download/`+(this.generateType == 1 ? this.caseNumber1 : this.generateType == 2 ? this.caseNumber2 : this.caseNumber3), {
            params:{
              "type": this.generateType,
              "chatNumber": this.generateType == 1 ? this.chatNumber1 : this.generateType == 2 ? this.chatNumber2 : this.chatNumber3,
            },responseType: "arraybuffer"})
            .then(({ data }) => {
              const content = data
              const blob = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8"
              })

              let typeName = this.generateType == 1 ? '_问题清单' : this.generateType == 2 ? '_检索报告' : '_新申请'
              const fileName = this.removeExtension(title) + typeName + '.docx'
              if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
              }
              else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
              }
              this.history()
            })
        })
    },

    removeExtension(filename) {
      return filename.substring(0, filename.lastIndexOf('.')) || filename;
    },

    compareFileAnalysis(){
      if(this.disableNextStep2) return

      let saveList = []
      for(let i = 0; i < 3; i++){
        let data = this.chatResult2[i]
        if(!data.messageId){
          data.messageId = data.id
        }
        saveList.push(this.$axios.put("/aigc/app/new/message", data))
      }

      this.searchReportTabSet.add('对比文件分析')
      Promise.all(saveList)
        .then(()=>{
          this.searchReportTab = '对比文件分析'
          this.$axios.get('/aigc/app/new/getSimilarPatents/' + this.caseNumber2 + '?chatNumber=' + this.chatNumber2).then(({data}) => {
            this.comparativeFiles = data.data
            let list = ['60']
            this.comparativeFiles.forEach(item=>{
              if(item.difference != '60'){
                list.push(item.difference)
              }
            })

            this.disableNextStep2 = true
            this.disableRegenerate2 = true
            this.sendMessageList(list, 2)
          })
        })
    },

    //右下对话
    send(){
      switch (this.generateType){
        case 1:
          if(this.chatNumber1 == '') {
            this.$message("请先开始生成内容")
            return
          }
          break
        case 2:
          if(this.chatNumber2 == '') {
            this.$message("请先开始生成内容")
            return
          }
          break
        case 3:
          if(this.chatNumber3 == '') {
            this.$message("请先开始生成内容")
            return
          }
          break
      }

      let inputString = this.chatInput

      this.$axios.post(`/aigc/app/new/message`, {
        "chatNumber": this.generateType == 1 ? this.chatNumber1 : this.generateType == 2 ? this.chatNumber2 : this.chatNumber3,
        "caseNumber": this.generateType == 1 ? this.caseNumber1 : this.generateType == 2 ? this.caseNumber2 : this.caseNumber3,
        "chatType": 0,
        "prompt": inputString
      })
        .then(response=>{
          let myConversationId = response.data.data
          switch (this.generateType){
            case 1:
              this.messageData01.push({
                deleteId:myConversationId,
                type:'user',
                conversationId:myConversationId,
                content:inputString
              })
              this.messageData01.push({
                deleteId:myConversationId,
                type:'assistant',
                conversationId:myConversationId,
                content:""
              })

              this.ifChatLoading01 = true
              break
            case 2:
              this.messageData02.push({
                deleteId:myConversationId,
                type:'user',
                conversationId:myConversationId,
                content:this.chatInput
              })
              this.messageData02.push({
                deleteId:myConversationId,
                type:'assistant',
                conversationId:myConversationId,
                content:""
              })

              this.ifChatLoading02 = true
              break
            case 3:
              this.messageData03.push({
                deleteId:myConversationId,
                type:'user',
                conversationId:myConversationId,
                content:this.chatInput
              })
              this.messageData03.push({
                deleteId:myConversationId,
                type:'assistant',
                conversationId:myConversationId,
                content:""
              })

              this.ifChatLoading03 = true
              break
          }

          this.$nextTick(()=>{
            let dom = document.getElementById("chatBox")
            dom.scrollTop = dom.scrollHeight - dom.clientHeight
          })

          this.getChatMessage(myConversationId, this.generateType)
        })
        .finally(()=>{
          this.chatInput = ""
        })
    },

      handleClickPrompt(item){
        this.chatInput = item.prompt
      },

    getChatMessage(myConversationId, generateType){
      this.$axios({
        method: 'GET',
        responseType: 'stream',
        cancelToken: generateType == 1 ? this.stopSignal01.token : generateType == 2 ? this.stopSignal02.token : this.stopSignal03.token,
        url: "/aigc/app/new/completions?conversationId=" + myConversationId,
        onDownloadProgress:(data)=>{
          let response = data.target.response
          let itemList = ""
          let item = ""
          if (!response.endsWith("}")) {
            itemList = response.split("{")
            item = "{" + itemList[itemList.length - 2]
          }
          else {
            itemList = response.split("{")
            item = "{" + itemList[itemList.length - 1]
          }

          let messageList = generateType == 1 ? this.messageData01 : generateType == 2 ? this.messageData02 : this.messageData03

          messageList.forEach(myItem=>{
            if (myItem.conversationId == myConversationId && myItem.type == 'assistant'){
              let text = JSON.parse(item).content.replaceAll(/\n+/g, '\n\n').replaceAll('\n\n', '<br/>')
              text = text.replaceAll("#", "")
              text = text.replaceAll("**", "%%%")

              let pattern = /%%%.*?%%%/g
              let arr = text.match(pattern)

              if(arr){
                arr.forEach(item=>{
                  let targetText = "<b>"+item.replaceAll("%%%", "")+"</b>"
                  text = text.replace(item, targetText)
                })
              }

              myItem.content = text
              myItem.deleteId = JSON.parse(item).id
            }
          })

          this.$nextTick(()=>{
            let dom = document.getElementById("chatBox")
            dom.scrollTop = dom.scrollHeight - dom.clientHeight
          })
        }
      })
        .then(()=>{
          switch (generateType){
            case 1:
              this.ifChatLoading01 = false
              break
            case 2:
              this.ifChatLoading02 = false
              break
            case 3:
              this.ifChatLoading03 = false
              break
          }
        })
    },

    handleDelete(item, index){
      switch (this.generateType){
        case 1:
          this.messageData01.splice(index,1)
          break
        case 2:
          this.messageData02.splice(index,1)
          break
        case 3:
          this.messageData03.splice(index,1)
          break
      }

      this.$axios.delete("/aigc/app/new/message/"+item.deleteId)
    },
    //重新生成对话
    handleRegenerate(item){
      let messageList = null
      switch (this.generateType){
        case 1:
          messageList = this.messageData01
          break
        case 2:
          messageList = this.messageData02
          break
        case 3:
          messageList = this.messageData03
          break
      }

      messageList.forEach(myItem=>{
        if (myItem.conversationId == item.conversationId && myItem.type == 'assistant'){
          myItem.content = ""
        }
      })

      switch (this.generateType){
        case 1:
          this.ifChatLoading01 = true
          break
        case 2:
          this.ifChatLoading02 = true
          break
        case 3:
          this.ifChatLoading03 = true
          break
      }

      this.getChatMessage(item.conversationId, this.generateType)
    },
    //复制对话内容
    handleCopy(item){
      let stringContent = item.content
      stringContent = stringContent.replaceAll('<br/>', '\n\n')
      stringContent = stringContent.replaceAll(/<.*?>/g, '')
      //获取要赋值的input的元素
      var inputElement =  document.getElementById("copy_content");
      //给input框赋值
      inputElement.value = stringContent
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //提示已复制
      this.$message('已复制');
    },

      ifShowHistoryDownload(row){
        switch (row.type){
          case "1":
            return row.status == "40"
            break
          case "2":
            return row.status == "80"
            break
          case "3":
            return (row.status == "180" || row.status == "200" || row.status == "215" || (row.figureFileIds != null && row.status == '190'))
            break
        }
      },
    historyDownload(item){
      this.$axios.get(`/aigc/app/new/content/download/` + item.id, { responseType: "arraybuffer" })
        .then(({ data }) => {
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          })
          const fileName = item.title + '.docx'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }
          else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
    },

    //停止请求
    handleStopStep0(type){
      switch (type || this.generateType){
        case 1:
          this.stopSignal01.cancel("请求已被取消")
          this.stopSignal01 = axios.CancelToken.source()
          break
        case 2:
          this.stopSignal02.cancel("请求已被取消")
          this.stopSignal02 = axios.CancelToken.source()
          break
        case 3:
          this.stopSignal03.cancel("请求已被取消")
          this.stopSignal03 = axios.CancelToken.source()
          break
      }
    },
    handleStopStep1(){
      this.stopSignal1.cancel("请求已被取消")
      this.stopSignal1 = axios.CancelToken.source()
      this.disableRegenerate1 = false
    },
    handleStopStep2(){
      this.stopSignal2.cancel("请求已被取消")
      this.stopSignal2 = axios.CancelToken.source()
      this.disableRegenerate2 = false
    },
    handleStopStep3(){
      this.stopSignal3.cancel("请求已被取消")
      this.stopSignal3 = axios.CancelToken.source()
      this.disableRegenerate3 = false
    },

    //上传文件错误触发
    errorEvent(err) {
      this.$message.error(err);
    },

    //展开对比文件详情
    openCollapse(item) {
      if(this.activeName == item.fileId) {
        this.activeName = 0
      }
      else {
        this.activeName = item.fileId
      }
    },

    //调整右侧布局、容器大小
    jisuan(){
      const element = document.querySelector('.upload')
      if(element){
        const height = element.scrollHeight + 20

        const elements = document.querySelector('.chat_content')
        elements.style.height = `calc(100% - ${height}px)`  // 设置高度为视口高度减去50px
      }
    },

    //删除对比文件
    del(item){
      this.$axios.post('/aigc/app/new/unbindFiles',{
        caseNumber: this.caseNumber2,
        chatNumber: this.chatNumber2,
        fileIds: item.fileId,
        fileType: item.fileType,
      })
        .then(({data}) => {
          if (data.code == 0) {
            this.unbindFiles(item)
          }
        })
    },

    //删除对比文件后删除相应对话
    unbindFiles(items){
      this.$axios.delete('/aigc/app/new/message/'+ this.chatNumber2 +'/' + items.difference)
        .then(({data}) => {
          if (data.code == 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })

            this.comparativeFiles.forEach((item,index)=>{
              if (item.difference == items.difference){
                this.comparativeFiles.splice(index, 1)
              }
            })
          }
        })
    },

    //预览对比文件
    view(item){
      this.$axios.get('/aigc/app/file/' + item.fileId).then(({data}) => {
        if (data.code == 0) {
          this.dialogviewFileVisible = true
          this.viewFileContent = JSON.parse(data.data.fileContent).content
          this.viewFile = data.data
        }
      })
    },

    //查询对比文件 由于准确度问题暂时不使用
    searchSimilarPatents() {
      this.$axios.get('/aigc/app/new/searchSimilarPatents/' + this.caseNumber + '?chatNumber=' + this.chatNumber2).then(({data}) => {
        if (data.code == 0) {
          this.getSimilarPatents()
        }
      })
    },
    //获取对比文件
    getSimilarPatents() {
      this.$axios.get('/aigc/app/new/getSimilarPatents/' + this.caseNumber + '?chatNumber=' + this.chatNumber2)
    },

    //左上切换页签
    handleGenerateTypeSwitch(val) {
      setTimeout(() => {
        this.$nextTick(() => {
          try {
            const elements = document.querySelector('.result');
            elements?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          }
          catch (e) {}

          try {
            const element = document.querySelector('.result_cen');
            element?.scrollTo(0, 0);  // 将指定元素的滚动条置顶
          }
          catch (e) {}
        })
      })
      this.jisuan()
      this.generateType = val
    },

    //上传附图成功调用
    fuuploadFn(file, fileList) {
      if (file.code == 0) {
        this.fufileList = [fileList]
        this.$message({
          message: '文件上传成功',
          type: 'success'
        })
      }
      else {
        this.fufileList = []
        this.$message.error(file.msg)
      }
    },
    //上传实施例成功调用
    shiuploadFn(file, fileList) {
      if (file.code == 0) {
        this.shifileList = [fileList]
        this.$message({
          message: '文件上传成功',
          type: 'success'
        })
      }
      else {
        this.shifileList = []
        this.$message.error(file.msg);
      }
    },
    //移除对比文件
    handleRemoves(file, fileList) {
      this.fileList = fileList
    },

    //输入框内容变化调用
    contentChange(e, item, type) {
      if(type == 0){
        let fileContent = JSON.parse(this.viewFile.fileContent)
        fileContent.content = this.viewFileContent

        if(this.timer){
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(()=>{
          let param = {
            fileContent:fileContent,
            id:this.viewFile.id,
            identifier:this.viewFile.identifier
          }

          this.$axios.put("/aigc/app/file", param)
        }, 1000)
        return
      }

      //专门判断权利要求书输入内容
      if(type == 4 && this.patentApplyType == 1){

        if(this.timer){
          clearTimeout(this.timer)
        }

        this.timer = setTimeout(()=>{
          let param = {
            caseNumber:this.caseNumber3,
            chatNumber:this.chatNumber3,
            claimInfo:item.content
          }
          this.$axios.put('/aigc/app/new/claimInfo', param)
        }, 1000)
        return
      }

      switch (type){
        case 1:
          if(this.chatNumber1 == '' || this.disableNextStep1) return
          break;
        case 2:
          if(this.chatNumber2 == '' || this.disableNextStep2) return
          break;
        case 3:
        case 4:
          if(this.chatNumber3 == '' || this.disableNextStep3) return
          break
        case 5:
          this.patentApplyAbstractOfDescriptionNumber = item.content.length
          if(this.chatNumber3 == '' || this.disableNextStep3) return
          break;
      }

      if(this.timer){
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(()=>{
        let data = {
          parentMessageId:item.parentMessageId,
          messageId: item.messageId || item.id,
          content:item.differenceContent || item.content,
        }
        this.$axios.put("/aigc/app/new/message", data)
      }, 1000)
    },

    //打开历史记录浮窗
    open() {
      this.drawer = true
      this.history()
    },

    //历史页面页号变化
    handleCurrentChange(e) {
      this.pageConfig.pageNum = e
      this.history()
    },

    //格式化创建时间
    geshi(item) {
      const isoString = item;
      // 创建 Date 对象
      const date = new Date(isoString);
      // 获取 UTC 年、月、日、时、分、秒
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');  // 月份从 0 开始，需要加 1
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      // 拼接为 "YYYY-MM-DD HH:mm:ss" 格式
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },

    //获取历史记录
    history() {
      this.$axios.get('/aigc/app/new',{
        params: {
          current: this.pageConfig.pageNum,
          size: this.pageConfig.pageSize
        }
      })
        .then(({data}) => {
          if (data.code == 0) {
            this.historyArr = data.data.records.map(item => {
              item.createTime = this.geshi(item.createTime)
              return item
            })
            this.total = data.data.total
          }
        })
    },

    backview(item){
      this.$axios.get("/aigc/app/new/"+item.id)
        .then(fileListResponse=>{
          this.$axios.get("/aigc/app/new/message",{ params:{chatNumber: item.chatNumber}})
            .then(response=>{
              let fileList = fileListResponse.data.data
              let chatList = response.data.data
              chatList.reverse()

              switch (item.type){
                case "1":
                  this.generateType = 1
                  this.handleStopStep1()
                  this.handleStopStep0(1)
                  this.caseNumber1 = item.caseNumber
                  this.chatNumber1 = item.chatNumber
                  this.fileArr1 = fileList.discloseFileRecords[0]
                  this.disableRegenerate1 = false
                  this.disableNextStep1 = false
                  this.chatResult1 = [{content:''},{content:''},{content:''},{content:''}]
                  this.ifChatLoading01 = false
                  this.messageData01 = []

                  chatList.forEach(item=>{
                    if(item.chatType == 0){
                      item.deleteId = item.id
                      this.messageData01.push({
                        deleteId:item.messageId,
                        type:item.role,
                        conversationId:item.conversationId,
                        content:item.content
                      })
                    }
                    else if(item.role == 'assistant'){
                      switch (item.chatType){
                        case '10':
                          this.chatResult1[0] = item
                          break
                        case '20':
                          this.chatResult1[1] = item
                          break
                        case '30':
                          this.chatResult1[2] = item
                          break
                        case '40':
                          this.chatResult1[3] = item
                          break
                      }
                    }
                  })
                  break
                case "2":
                  this.generateType = 2
                  this.handleStopStep2()
                  this.handleStopStep0(2)
                  this.caseNumber2 = item.caseNumber
                  this.chatNumber2 = item.chatNumber
                  this.fileArr2 = fileList.discloseFileRecords[0]
                  this.fileList = fileList.similarFileRecords
                  this.disableRegenerate2 = false
                  this.disableNextStep2 = false
                  this.searchReportTabSet = new Set()
                  this.searchReportTab = '技术方案'
                  this.chatResult2 = [{content:''},{content:''},{content:''},{content:''},{content:''}]
                  this.comparativeFiles = []
                  this.fileList.forEach(item=>{
                    let fileContentJson = JSON.parse(item.fileContent)
                    fileContentJson.fileId = item.id
                    this.comparativeFiles.push(fileContentJson)
                  })
                  this.activeName = 0
                  this.viewFile = ''
                  this.viewFileContent = ''
                  this.dialogviewFileVisible = false
                  this.ifChatLoading02 = false
                  this.messageData02 = []

                  chatList.forEach(item=>{
                    if(item.chatType == 0){
                      item.deleteId = item.id
                      this.messageData02.push({
                        deleteId:item.messageId,
                        type:item.role,
                        conversationId:item.conversationId,
                        content:item.content
                      })
                    }
                    else if(item.role == 'assistant'){
                      switch (item.chatType){
                        case '10':
                          this.searchReportTabSet.add('技术方案')
                          this.chatResult2[0] = item
                          break
                        case '20':
                          this.searchReportTabSet.add('技术方案')
                          this.chatResult2[1] = item
                          break
                        case '50':
                          this.searchReportTabSet.add('技术方案')
                          this.chatResult2[2] = item
                          break
                        case '70':
                          this.searchReportTabSet.add('关键词/检索式')
                          this.chatResult2[3] = item
                          break
                        case '80':
                          this.searchReportTabSet.add('IPC分类号')
                          this.chatResult2[4] = item
                          break
                      }
                      if(Number(item.chatType) >= 60 && Number(item.chatType) <= 65){
                        this.searchReportTabSet.add('对比文件分析')
                        this.comparativeFiles.forEach(file=>{
                          if (file.difference == item.chatType){
                            file.messageId = item.messageId
                            file.parentMessageId = item.parentMessageId
                            file.differenceContent = item.content
                          }
                        })
                      }
                    }
                  })

                  break
                case "3":
                  this.generateType = 3
                  this.handleStopStep3()
                  this.handleStopStep0(3)
                  this.caseNumber3 = item.caseNumber
                  this.chatNumber3 = item.chatNumber
                  this.fileArr3 = fileList.discloseFileRecords[0]
                  this.patentField = Number(item.patentField)
                  this.patentApplyType = 0
                  this.disableRegenerate3 = false
                  this.disableNextStep3 = false
                  this.patentApplyTabSet = new Set()
                  this.patentApplyTab = (item.claimInfo && item.claimInfo != '') ? '权利要求书' : '技术方案'
                  this.fufileList = fileList.figureFileRecords
                  this.shifileList = fileList.implementFileRecords
                  this.uploadFiles = false
                  this.chatResult3 = [{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''},{content:''}]
                  this.ifChatLoading03 = false
                  this.messageData03 = []

                  if(item.claimInfo && item.claimInfo != ''){
                    this.patentApplyType = 1
                    this.patentApplyTabSet.add('权利要求书')
                    this.chatResult3[2].content = item.claimInfo
                  }

                  chatList.forEach(item=>{
                    if(item.chatType == 0){
                      item.deleteId = item.id
                      this.messageData03.push({
                        deleteId:item.messageId,
                        type:item.role,
                        conversationId:item.conversationId,
                        content:item.content
                      })
                    }
                    else if(item.role == 'assistant'){
                      switch (item.chatType){
                        case '100':
                          this.patentApplyTabSet.add('技术方案')
                          this.chatResult3[0] = item
                          break
                        case '110':
                          this.patentApplyTabSet.add('技术方案')
                          this.chatResult3[1] = item
                          break
                        case '120':
                        case '121':
                          this.patentApplyTabSet.add('权利要求书')
                          this.chatResult3[2] = item
                          break
                        case '125':
                          this.patentApplyTabSet.add('摘要/名称/领域/背景')
                          this.chatResult3[3] = item
                          break
                        case '130':
                          this.patentApplyTabSet.add('摘要/名称/领域/背景')
                          this.chatResult3[4] = item
                          break
                        case '140':
                          this.patentApplyTabSet.add('摘要/名称/领域/背景')
                          this.chatResult3[5] = item
                          break
                        case '150':
                          this.patentApplyTabSet.add('摘要/名称/领域/背景')
                          this.chatResult3[6] = item
                          break
                        case '160':
                          this.patentApplyTabSet.add('发明内容')
                          this.chatResult3[7] = item
                          break
                        case '170':
                        case '190':
                        case '220':
                          this.patentApplyTabSet.add('具体实施方式')
                          this.chatResult3[8] = item
                          break
                        case '180':
                        case '200':
                        case '215':
                          this.patentApplyTabSet.add('具体实施方式')
                          this.chatResult3[9] = item
                          break
                      }
                    }
                  })
                  break
              }

              this.$nextTick(()=>{
                this.jisuan()
              })
            })
        })
    },

    fenge(text) {
      const content = text;

      const splitPointRegex = /(.*检索式.*)/; // 匹配包含“关键词”和“检索式”的句子
      const match = content.match(splitPointRegex);

      if (match) {
        const splitPoint = match[0]; // 找到的匹配句子
        const [firstPart, secondPart] = content.split(splitPoint);
        return {
          up: firstPart.trim(),
          down: splitPoint.trim() + "\n" + secondPart.trim()
        }
      }
    },

    fenglei(text){
      const regex = /(?<=\d+\..*|\-\s)[A-Z]+\d+[\w/]+\s?[\w/]+/g;

      // 提取所有匹配的分类号
      const matches = text.match(regex);

      // 用中文顿号连接并输出结果
      const result = matches ? matches.join('、') : '';
      return result
    },

    keyEvent(text){
      const regex = /(\S+ - [^、\n]+|(\S+（[^）]+）))/g;
      const matches = [...text.matchAll(regex)];
      const output = matches.map(match => match[0]).join(' 、');
      return output
    },

    jiansuo(text){
      // 匹配检索式的正则表达式
      const regex = /[a-zA-Z]+=\([^=]+\)/g;

      // 提取所有匹配的检索式
      const matches = text.match(regex);

      // 用中文顿号连接并输出结果
      const result = matches ? matches.join('、') : '';
      return result
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

    uploadFns(file, fileList) {
      if (file.code == 0) {
        file.data.name = file.data.fileName
        this.fileList.push(file.data)
        this.$message({
          message: '文件上传成功',
          type: 'success'
        })
      }
    },

    uploadFn(file, fileList) {
      if (file.code == 0) {
        switch (this.generateType){
          case 1:
            this.fileArr1 = file.data
            break
          case 2:
            this.fileArr2 = file.data
            break
          case 3:
            this.fileArr3 = file.data
            break
        }
        this.$message({
          message: '文件上传成功',
          type: 'success'
        })
      }
    },
  }
}
</script>

<style lang="less">
.myApplyPromptEditDialog{
  .el-dialog__body{
    padding: 10px 30px;
  }
  .el-textarea__inner{
    padding: 5px;
    border: 1px solid #DCDFE6;
  }
}
.promptBoxApply{
  display: inline-block;
  width: 570px;
  height: 150px;
  overflow-y: scroll;
  scrollbar-width: none;

  .promptContentBox{
    position: relative;

    .promptButton{
      cursor: pointer;
      position: absolute;
      display: none;
      top: calc( 50% - 9px );
    }
    .promptButton:nth-of-type(1){
      right: 10px;
    }
    .promptButton:nth-of-type(2){
      right: 32px
    }
    p{
      transition: all .2s ease-in-out;
      margin: 5px;
      padding: 5px;
      padding-right: 50px;
      white-space: nowrap;         /* 禁止换行 */
      overflow: hidden;            /* 隐藏溢出部分 */
      text-overflow: ellipsis;     /* 使用省略号 */
      cursor: pointer;
    }
    p:hover{
      color: #0d84ff;
      box-shadow: #bbbbbb 0 0 10px 1px;
    }
  }
  .promptContentBox:hover{
    .promptButton{
      display: inline-block;
    }
  }
}
.dialogviewFileVisible {
  .v-note-wrapper {
    textarea {
      text-align: justify!important;
      width: 95% !important;
    }
  }
}
.oa_drawer {
  top: 120px;
  height: calc(100% - 140px);  /* 50%视口高度加100像素 */
  // 滚动条的宽度
  &::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }
  // 滚动条的滑块
  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, .1);
    border-radius: 10px
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  .el-drawer__header {
    margin-bottom: 10px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    height: 50px;

    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #555555;

      img {
        margin-right: 8px;
        display: block;
      }

      .clickableTitle{
        cursor:pointer;
      }
      .clickableTitle:hover{
        color: #2f56de;
      }
    }
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 2px solid #C3CFEF;
  }
  .el-drawer.rtl {
    padding: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08), -5px 0px 7px 0px rgba(0,0,0,0.08);
    border-radius: 15px 15px 15px 15px;
    .pagination {
      display: flex;
      justify-content: flex-end;
    }
  }

  .promptList{
    margin: 10px 20px 5px;
    height: calc( 63vh + 38px );
    overflow: scroll;
    scrollbar-width: none;
  }
}

.comparativeFilesDialog,
.uploadFiles {
  .upload-demo {
    text-align: center;
  }
}
.el-upload-list{
  min-width:70%;
}
.enter_hover {
  padding: 0 !important;
}
.oaApplication {
  textarea {
    padding: 0;
    margin: 0;
    line-height: normal; /* 确保行高不会导致额外的空白 */
  }
  /* 修改编辑区域和预览区域的底部空白 */
  .markdown-editor .editor-content,
  .markdown-editor .preview {
    padding-bottom: 0;  /* 去掉不必要的 padding */
    margin-bottom: 0;   /* 去掉不必要的 margin */
  }

  /* 调整整个编辑器的布局以减少空白 */
  .markdown-editor {
    height: auto;  /* 根据内容自动调整高度 */
  }
  .markdown-editor .editor-wrapper,
  .markdown-editor .editor-preview-wrapper {
    padding-bottom: 0;  /* 删除底部的 padding */
    margin-bottom: 0;   /* 删除底部的 margin */
  }

  //手风琴
  .oa_collapse {
    color: #333639;
    border: none;
    .el-collapse-item {
      border: 1px solid #e5e7eb;
      margin: 18px 0;
      padding: 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
      &:hover {
        box-shadow: #ddd 0 0 10px;

      }
      .el-collapse-item__header {
        height: 115px;
        &.is-active {
          height: 73px;
        }
        display: block;
        border: none;
        .el-collapse-item__arrow {
          display: none;
        }
        .titleHeader {
          display: flex;
          width: 100%;
          align-items: center;
          line-height: 1;
          &:nth-of-type(1) {
            height: 40px;
          }
          height: 20px;
          &.active {
            justify-content: space-between;
          }
          .title {
            line-height: 1;
            font-size: 14px;
            font-weight: 700;
          }
          li {
            color: #333639;
            margin-right: 40px;
            font-size: 14px;
            white-space: nowrap;         /* 禁止换行 */
            overflow: hidden;            /* 隐藏溢出部分 */
            text-overflow: ellipsis;     /* 使用省略号 */
            span {
              font-weight: bold;
              font-size: 14px;
              margin-right: 15px;
            }
          }
        }
      }
      .el-collapse-item__wrap {
        border: none;
        .el-collapse-item__content {
          color: #333639;
          font-size: 14px;
          padding-bottom: 15px;
          span {
            font-weight: bold;
            font-size: 14px;
            margin-right: 15px;
          }
        }
      }
    }
  }

}
</style>
<style lang="less" scoped>
.enter_hover {
  ul {
    padding: 18px 0 13px 0;
    box-sizing: border-box;
    width: 143px;
    /*height: 240px;*/
    background: rgba(255, 255, 255, 0.58);
    border-radius: 12px 12px 12px 12px;
    border: 2px solid;
    border-image: linear-gradient(123deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)) 2 2;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 22px 0 14px;
      box-sizing: border-box;
      text-align: right;
      font-weight: 500;
      font-size: 14px;
      color: #4E6B97;
      width: 100%;
      height: 28px;
      cursor: not-allowed;
      &.disabled {
        cursor: pointer;
      }

      &:hover {
        background: #C5C1EC;
        border-radius: 0px 0px 0px 0px;
        color: #345B93;
      }
    }
  }
}

.oaApplication {
  overflow: hidden;
  .home_top {
    height: 90px;
  }

  .mavonEditor_1 {
    background: #F1F4FC;
    /deep/.v-note-panel {
      padding: 15px;
    }
    /deep/textarea {
      background: #F1F4FC;
    }
    /deep/.content-input-wrapper {
      padding: 0!important;
    }
  }

  .historyList {
    padding: 10px 15px 0 25px;
    box-sizing: border-box;
    width: 320px;
    height: calc(100vh - 93px);
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(7, 13, 67, 0.18);
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    /*overflow-y: auto;*/

    .tabs {
      /deep/ .el-tab-pane {
        height: calc(100vh - 280px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 10px; // 横向滚动条
          height: 10px; // 纵向滚动条 必写
        }

        // 滚动条的滑块
        &::-webkit-scrollbar-thumb {
          background: #485687;
          border-radius: 10px
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }
      }
    }

    /*滚动条样式*/
    /*&::-webkit-scrollbar {*/
    /*  width: 4px;*/
    /*}*/
    /*&::-webkit-scrollbar-thumb {*/
    /*  border-radius: 10px;*/
    /*  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*  background: rgba(0,0,0,0.2);*/
    /*}*/
    /*&::-webkit-scrollbar-track {*/
    /*  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*  border-radius: 0;*/
    /*  background: rgba(0,0,0,0.1);*/

    /*}*/

    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #555555;
      padding: 0.5rem 0.7rem;
      box-sizing: border-box;

      img {
        width: 41px;
        height: auto;
        margin-right: 14px;
      }
    }

    .line {
      width: 260px;
      height: 2px;
      margin-top: 10px;
      margin-bottom: 33px;
      border: 1px solid #DBE4F5;
    }

    .item {
      line-height: 1;
      margin-bottom: 21px;

      p:nth-of-type(1) {
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        cursor: pointer;
        line-height: 14px;
        margin-bottom: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p:nth-of-type(2) {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
      }
    }
  }

  .oaApplication_cen {
    padding: 30px 30px 0 30px;
    box-sizing: border-box;

    .oaApplication_o {
      position: relative;

      .history {
        cursor: pointer;
        right: 0;
        top: 61px;
        width: 73px;
        height: auto;
        position: absolute;
      }

      padding-top: 36px;
      box-sizing: border-box;
      background: url("../../assets/images/oaApplication/bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;

      .oaApplication_content {
        position: relative;
        .tabSwitch {
          z-index: 2;
          position: absolute;
          width: calc(100% - 200px);;
          max-width: 1500px;
          display: flex;
          top: 23px;
          left: 50px;
          li {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            width: 110px;
            height: 35px;
            background: #E0E4EB;
            font-weight: bold;
            font-size: 14px;
            color: #666666;
            border-radius: 10px 10px 0 0;
            &.active {
              color: #172A6B;
              background: #B0C2F2;
            }
          }
        }
        padding: 60px 30px 24px 30px;
        box-sizing: border-box;
        width: calc(100% - 200px);;
        max-width: 1500px;
        height: calc(100vh - 170px); /* 高度为视口高度减去 100px */
        background: rgba(255,255,255,0.4);
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        display: flex;
        overflow: hidden;
        /*flex-direction: column;*/
        justify-content: space-between;
        .result {
          // 滚动条的宽度
          &::-webkit-scrollbar {
            width: 10px; // 横向滚动条
            height: 10px; // 纵向滚动条 必写
          }
          // 滚动条的滑块
          &::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, .1);
            border-radius: 10px
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          .scroll_bar /deep/ .el-scrollbar__wrap {
            overflow-x: hidden !important;
          }
          padding: 20px;
          box-sizing: border-box;
          width: calc(100% - 690px);
          height: calc(100vh - 245px); /* 高度为视口高度减去 100px */
          overflow-y: auto;
          background: #FFFFFF;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
          border-radius: 12px 12px 12px 12px;
          position: relative;
          .titles {
            margin: 15px 0;
            font-size: 12px;
          }
          .load {
            cursor: pointer;
            position: absolute;
            right: 25px;
            top: 0px;
            width: 31px;
            height: auto;
            z-index: 999;
          }
          .result_cen {
            // 滚动条的宽度
            &::-webkit-scrollbar {
              width: 10px; // 横向滚动条
              height: 10px; // 纵向滚动条 必写
            }
            // 滚动条的滑块
            &::-webkit-scrollbar-thumb {
              background: hsla(0, 0%, 53%, .1);
              border-radius: 10px
            }
            &::-webkit-scrollbar-track {
              background: transparent;
            }

            height: calc(100vh - 394px); /* 高度为视口高度减去 100px */
            overflow-y: auto;
          }
          .btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            height: 38px;
            letter-spacing: .5px;
            background: linear-gradient(252deg, #2E72D0 0%, #1B427A 100%);
            border-radius: 8px 8px 8px 8px;
            font-size: 14px;
            color: #FFFFFF;
            &:nth-of-type(1) {
              background: #e2e9fe;
              border: 1px solid #4178c2;
              color: #555555;
            }
          }
        }
        .chat {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 636px;
          height: calc(100vh - 240px); /* 高度为视口高度减去 100px */
          /*background: #FFFFFF;*/
          /*box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);*/
          /*border-radius: 12px 12px 12px 12px;*/

          .upload {
            .rotation {
              width: 100%;
              display: flex;
              justify-content: center;
              height: 20px;
              cursor: pointer;
            }
            .upload_item_1 {
              width: 100%;
              height: 75px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .upload_item_2 {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 15px;
            }
            padding: 0 35px 20px 33px;
            box-sizing: border-box;
            width: 100%;
            height: auto;
            background: rgba(255, 255, 255, 0.45);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
            border-radius: 12px 12px 12px 12px;
            /*border: 2px solid;*/
            border-image: linear-gradient(123deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)) 2 2;
            overflow: hidden;

            .uploadFile {
              width: 233px;
              height: 40px;
              background: #E2E9FE;
              border-radius: 8px 8px 8px 8px;
              border: 2px dashed #4178C2;
              padding-left: 42px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 500;
              font-size: 12px;
              color: #555555;

              i {
                margin-right: 5px;
              }
            }

            .uploadFiled {
              padding-left: 12px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 316px;
              height: 38px;
              background: #D1DBF3;
              border-radius: 5px 5px 5px 5px;
              font-weight: 400;
              font-size: 14px;
              color: #2E4172;

              p {
                text-align: left;
                white-space: nowrap; /* 不换行 */
                overflow: hidden; /* 隐藏溢出的内容 */
                text-overflow: ellipsis; /* 显示省略号 */
                width: 260px; /* 设定容器宽度 */
              }

              i:nth-of-type(1) {
                font-size: 16px;
                margin-right: 4px;
              }

              i:nth-of-type(2) {
                font-size: 18px;
                margin-right: 2px;
              }
            }

            .select {
              /deep/ input {
                width: 103px;
                height: 38px;
                background: #E5ECFF;
                border-radius: 8px 8px 8px 8px;
                border: 1px solid #6C80B3;
                font-weight: 500;
                font-size: 14px;
                color: #172A6B;
              }
            }

            .btn {
              letter-spacing: .5px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 120px;
              height: 38px;
              background: linear-gradient(252deg, #2E72D0 0%, #1B427A 100%);
              border-radius: 8px 8px 8px 8px;
              font-size: 14px;
              color: #FFFFFF;
            }
          }

          .chat_content {
            padding: 13px;
            box-sizing: border-box;
            height: calc(100% - 120px); /* 高度为视口高度减去 100px */

            .chatBox {
              padding: 30px 10px;
              box-sizing: border-box;
              //result-ico
              // 滚动条的宽度
              &::-webkit-scrollbar {
                width: 10px; // 横向滚动条
                height: 10px; // 纵向滚动条 必写
              }

              // 滚动条的滑块
              &::-webkit-scrollbar-thumb {
                background: #485687;
                border-radius: 10px
              }

              &::-webkit-scrollbar-track {
                background: transparent;
              }

              .scroll_bar /deep/ .el-scrollbar__wrap {
                overflow-x: hidden !important;
              }

              height: calc(100% - 60px); /* 高度为视口高度减去 100px */
              overflow-y: auto;


              .assistantDialog{
                text-align: left;
                padding: 25px 0;
                position: relative;

                .dialogIconAssistant{
                  display: inline-block;
                  vertical-align: bottom;
                  height: 34px;
                  width: 34px;
                }
                .dialogContentAssistant{
                  display: inline-block;
                  vertical-align: bottom;
                  padding: 10px;
                  margin-left: 10px;
                  background-color: #FFFFFF;
                  border-radius: 8px;
                  min-height: 18px;

                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                }

                .AIDialogueCopyIcon{
                  display: none;
                  position: absolute;
                  left: 10px;
                  bottom: -22px;
                }
                .AIDialogueCopyIcon2{
                  display: none;
                  position: absolute;
                  left: 50px;
                  bottom: -22px;
                }
                .AIDialogueRegenerateIcon{
                  display: none;
                  position: absolute;
                  left: 30px;
                  bottom: -22px;
                }
              }
              .assistantDialog:hover{
                .AIDialogueCopyIcon{
                  color: #555555;
                  vertical-align: bottom;
                  display: inline-block;
                  cursor: pointer;
                }
                .AIDialogueCopyIcon2{
                  color: #555555;
                  vertical-align: bottom;
                  display: inline-block;
                  cursor: pointer;
                }
                .AIDialogueRegenerateIcon{
                  color: #555555;
                  vertical-align: bottom;
                  display: inline-block;
                  cursor: pointer;
                }
              }
              .userDialog{
                text-align: right;
                padding: 20px 0;

                .dialogContentUser{
                  display: inline-block;
                  vertical-align: bottom;
                  margin-right: 10px;
                  padding: 10px;
                  text-align: left;
                  max-width: 70%;
                  background-color: #A2C1FF;
                  border-radius: 8px;

                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                }
                .dialogIconUser{
                  display: inline-block;
                  vertical-align: bottom;
                  height: 34px;
                  width: 34px;
                }
                .AIDialogueCopyIcon3{
                  display: none;
                  position: absolute;
                  right: 40px;
                  bottom: 0px
                }
                .AIDialogueCopyIcon4{
                  display: none;
                  position: absolute;
                  right: 60px;
                  bottom: 0px
                }
              }
              .userDialog:hover{
                .AIDialogueCopyIcon3{
                  color: #555555;
                  vertical-align: bottom;
                  display: inline-block;
                  cursor: pointer;
                }
                .AIDialogueCopyIcon4{
                  color: #555555;
                  vertical-align: bottom;
                  display: inline-block;
                  cursor: pointer;
                }
              }

              .myChatStopGenerate{
                font-weight: 500;
                font-size: 14px;
                display: inline-block;
                padding: 8px 15px;
                border-radius: 8px 8px 8px 8px;
                span{
                  margin-left: 5px;
                  vertical-align: middle;
                }
                svg{
                  vertical-align: middle;
                }
              }
            }

            .enter {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .generated {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 82px;
                height: 47px;
                background: url("../../assets/images/oaApplication/sends.png") no-repeat;
                background-size: 100% 100%;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                margin-left: 20px;
              }

              .input_ {
                padding: 0 9px 0 12px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 48px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                border: 1px solid #8DA8CE;

                img {
                  cursor: pointer;
                  width: auto;
                  height: 40px;
                }

                /deep/ input {
                  border: none;
                  outline: none;
                }
              }

              .AIDialogPromptButton{
                text-align: center;
                line-height: 40px;
                border-radius: 10px;
                font-size: 14px;
                display: inline-block;
                vertical-align: middle;
                height: 40px;
                width: 80px;
                margin: 10px 0 10px 10px;
                cursor: pointer;
                background-color: #2F73D1;
                color: #ffffff;
              }
            }

            background: url("../../assets/images/oaApplication/miao.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>

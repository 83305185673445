import {get, post,request,put } from './http'
//创建定制化订单接口
export const customizedOrder = data => post('/order/order/createCustomOrder', data)
//获取价格表
export const priceList = data => get('/product/productpriceconfig/list', data)
//支付凭证付款接口
export const voucherPay = data => post('/order/payment/voucherPay', data)
//支付凭证付款接口
export const voucherPayTradmark = data => post('/custody/renewal/order/upload/order/image/url', data)
//支付凭证付款接口
export const voucherVipPayTradmark = data => post('/order/member/order/voucherPay', data)
//获取购买服务列表接口
export const getProductServiceList = data => get('/product/productservicemanage/getProductServiceList', data)
//开启服务接口
export const customStart = data => post('/product/servicemanage/customStart/' + data)
//支付宝付款接口
export const payZfb = data => post('/pay/order/submit', data)
//续展---支付宝接口
export const renewalPayZfb = data => post('/pay/order/submit', data)
//获取发票抬头列表接口
export const nvoiceList= data => get('/order/invoicetitle/list', data)
//获取发票历史列表接口
export const nvoicordereList= data => get('/order/invoiceorder/list', data)
//获取发票历史列表接口
export const nvoicorceinfoList= data => get('/order/invoiceinfo/list', data)
//添加发票抬头接口
export const addinvoicetitle = data => post('/order/invoicetitle', data)
//修改默认发票抬头接口
export const putinvoicetitle = data => put('/order/invoicetitle', data)
//获取发票抬头详情接口
export const invoiceId = data => get('/order/invoicetitle/'+data)
//申请开发票接口
export const appplyinvoicetitle = data => post('/order/invoiceinfo', data)
//修改发票历史接口
export const putinvoiceinfo = data => put('/order/invoiceinfo', data)
//获取发票历史详情接口
export const getinvoiceHistory = data => get('/order/invoiceinfo/'+data)
//获取发票抬头全部接口
export const nvoiceListAll= data => get('/order/invoicetitle/all', data)

<template>
  <div class="server_wrap">
    <div class="server_list"  >
      <ul class="product_server_tab">
        <li v-for="(item,index) in tabList" :key="index" :class="{'active':item.isActive}" @click="tabEvent(item)">
          {{item.text}}</li>
      </ul>

      <div class="my_order_main" v-if="tableData.length>0">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="serviceCode" label="服务编号" align="center">
          </el-table-column>
          <el-table-column prop="serviceName" label="服务名称" align="center">
          </el-table-column>

          <el-table-column prop="startTime" label="开通时间" align="center">
          </el-table-column>
          <el-table-column prop="endTime" label="到期时间" align="center">
          </el-table-column>
          <el-table-column prop="usageDays" label="使用情况(使用/总量)" align="center">

            <template slot-scope="scope">
              <span>  {{scope.row.usageTimes}}/{{scope.row.totalTimes == 0 ? "--" :scope.row.totalTimes}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="服务状态" align="center">
            <template slot-scope="scope">
              <!-- 10是待支付 ，11是待审核 ，20是支付成功，21是免支付，22是审核拒绝，30是订单完成 -->
              <span v-if="scope.row.status == 1 || scope.row.status == 2">{{scope.row.status == 1 ? '服务中' : '已失效'}}</span>
              <el-button v-else @click="open(scope.row.id)" size="mini" type="primary">开启</el-button>
            </template>
          </el-table-column>
<!--          <el-table-column prop="" label="数据库" align="center">-->
<!--          </el-table-column>-->
<!--          <el-table-column label="操作" align="center" width="240">-->
<!--            <template slot-scope="scope">-->
<!--              <el-button class="btn btn_green" type="primary" @click="detailEvent(scope.row.id)">查看</el-button>-->
<!--              <el-button class="btn pay" v-show="scope.row.orderStatus == 10" type="primary"-->
<!--                         @click="payEvent(scope.row.id,scope.row.amount)">支付</el-button>-->
<!--              &lt;!&ndash; <i class="order_detail" v-show="scope.row.isPaid == 'n'" @click="delEvent(scope.row.id)">-->
<!--                <img src="../../../images/del.png" alt="">-->
<!--              </i> &ndash;&gt;-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
        <div class="el_pagination_wrap">
          <el-pagination v-if="tableData.length>0 && isShow" @current-change="handleCurrentChange" @size-change="handleSizeChange"
                         :current-page="flag ? purchaseTrial[0].current : purchaseTrial[1].current" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>

      </div>
    </div>
    <div class="no_table_data" v-show="tableData.length == 0">
      <i>
        <img src="@/assets/images/common/orderN.png" alt="">
      </i>
      <p>暂无订单，去<span @click="buyEvent">购买</span></p>
    </div>
  </div>
</template>
<script>
  import { customStart } from "@/request/trademarkPayment";
export default {
  name: "myServer",
  data () {
    return {
      //重新加载分页组件
      isShow: true,
      //默认购买
      flag: true,
      //购买---试用
      purchaseTrial:[
        {
          current: 1,
        },
        {
          current: 1,
        }
      ],
      tabList: [
        {
          text: "购买",
          isActive: true,
          typeId: 27,
          code:1
        },
        {
          text: "试用",
          isActive: false,
          typeId: 28,
          code:2
        }],
      current: 1,
      size: 10,
      total: 0,
      tabPosition: 'top',
      tableData: []
    }
  },
  mounted () {

    if (!this.tool.getCookie("auth")) {
      this.$router.push({ path: "/index" })
      return;
    }
    if(this.$route.query.try){
      this.tabList.forEach((item, index) => {
        if(item.typeId == this.$route.query.try) item.isActive = true;
        else item.isActive = false;
      })
      if(this.$route.query.try == 27){
        this.flag = true
      }
      else {
        this.flag = false
      }
      this.ServiceManagesAjax();
    }

    // if(this.$route.query.try){
    //
    //   this.tabEvent({typeId:this.$route.query.try,isActive:false})
    // }



    if(this.$route.query.open == true || this.$route.query.open == 'true'){
      this.ServiceOpen()
    }else {
      this.ServiceManagesAjax(1)
    }
  },
  methods: {
    //开启服务
    open(id){
      customStart(id).then(res => {
        this.ServiceManagesAjax();
      })
    },
    //   产品服务切换
    tabEvent (data) {
      if (data.isActive) {
        return;
      }
      this.isShow = false
      this.tabList.forEach((item, index) => {
        item.isActive = false;
      })
      data.isActive = !data.isActive;
      if(data.typeId == 27){
        this.flag = true
      }
      else {
        this.flag = false
      }
      this.$nextTick(() => {
          this.isShow = true
      })

      // this.current= 1

      this.ServiceManagesAjax();
    },
    //  服务列表
    async ServiceManagesAjax () {

      let data = await this.$axios.get("/product/servicemanage/getUserServiceManages", {        params: {
          operType: this.flag ? 1 : 2,
          size: this.size,
          current: this.flag ? this.purchaseTrial[0].current : this.purchaseTrial[1].current
        }      });

      if (data.data.code == 0) {
        let ids = ''
        this.tableData = data.data.data.records;
        this.total = data.data.data.total;
        //开启服务
        // if(this.$route.query.open == true || this.$route.query.open == 'true'){
        //   this.tableData.map((item) => {
        //     if(this.$route.query.td.split(';').includes(item.id.toString())){
        //       if(item.status == 0){
        //         this.open(item.id)
        //       }
        //     }
        //   })
        // }
      }
    },
    //开启全部服务
    async ServiceOpen () {
      let tableData = []
      let data = await this.$axios.get("/product/servicemanage/getUserServiceManages", {        params: {
          operType: this.flag ? 1 : 2,
          size: 1000,
          current: this.flag ? this.purchaseTrial[0].current : this.purchaseTrial[1].current
        }      });

      if (data.data.code == 0) {
        let ids = ''
        tableData = data.data.data.records;
        // this.total = data.data.data.total;

        //开启服务
          tableData.map((item) => {
            if(this.$route.query.td.split(';').includes(item.id.toString())){
              if(item.status == 0){
                this.open(item.id)
              }
            }
          })
        this.ServiceManagesAjax()
      }
    },
    //   购买
    buyEvent () {
      this.$router.push({
        path: "/services"
      })
    },
    toTop () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    handleCurrentChange (val) {

      if(this.flag){
        this.purchaseTrial[0].current = val;
      }else {
        this.purchaseTrial[1].current = val;
      }

      this.toTop();
      this.ServiceManagesAjax();
    },
    handleSizeChange (val) {
      this.size = val;
      this.toTop();
      this.ServiceManagesAjax();
    },
    }
}
</script>
<style lang="less" scoped>
.server_wrap {
  .server_list {
    .product_server_tab {
      display: flex;
      margin-bottom: 30px;
      li {
        width: 100px;
        height: 36px;
        background-color: #f5f7f9;
        border-radius: 3px;
        font-size: 16px;
        color: @blue;
        text-align: center;
        line-height: 36px;
        margin-right: 1px;
        cursor: pointer;
        &.active {
          background: @blue;
          color: #fff;
        }
      }
    }

    .radio_group{
      /deep/.el-radio-button__inner{
        width: 117px;
        height: 45px;
          font-size: 18px;
          color: rgb( 46, 84, 217 );
      }

      /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
        color: #ffffff!important;
      }
    }

    /deep/.tableHeader{
      text-align: center;
      font-size: 16px;
      color: rgb( 51, 51, 51 );
    }

    /deep/.tableCell{
      text-align: center;
      font-size: 14px;
      color: rgb( 51, 51, 51 );
    }



    background-color: #ffffff;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    padding: 40px 30px;
    margin-bottom: 10px;
    .server_list_item {
      display: flex;
      li {
        position: relative;
        padding: 0 20px;
        &.item1 {
          flex-basis: 340px;
          h5 {
            font-size: 18px;
            color: #454545;
            i {
              display: inline-block;
              width: 20px;
              margin-right: 10px;
              vertical-align: -3px;
              img {
                width: 100%;
              }
            }
          }
          p {
            color: #888888;
            font-size: 14px;
            &.date {
              margin: 40px 0 20px;
            }
            span {
              font-size: 18px;
              color: @blue;
              &.over {
                color: #b3b9c1;
              }
              &.open {
                color: #ffd145;
              }
            }
          }
        }
        &.item2 {
          flex: 1;
          display: flex;
          align-items: center;
          dl {
            flex: 1;
            font-size: 14px;
            color: #717171;
            text-align: center;
            dt {
              margin-bottom: 40px;
              span {
                font-size: 18px;
                color: @blue;
              }
            }
          }
        }
        &.item3 {
          flex: none;
          flex-basis: 200px;
          dl {
            dt {
              span {
                color: #ffd145;
              }
            }
            &:last-child {
              margin-left: 20px;
            }
          }
        }
        &::after {
          position: absolute;
          top: 50%;
          margin-top: -70px;
          right: 0;
          content: "";
          width: 1px;
          height: 140px;
          background: #dbdcdd;
        }
        &:last-child {
          &::after {
            background: transparent;
          }
        }
      }
    }
  }
  .my_order_main {
    padding: 32px 10px;
    background: #fff;
    /*box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);*/
    min-height: calc(100vh - 355px);
    .order_upload,
    .order_detail {
      display: inline-block;
      width: 20x;
      vertical-align: middle;
      cursor: pointer;
      img {
        width: 100%;
        display: block;
      }
    }
    .order_detail {
      width: 20px;
      margin-left: 16px;
      vertical-align: -4px;
    }
    .el_pagination_wrap {
      text-align: center;
      margin-top: 10px;
    }
    .no_table_data {
      margin-top: 60px;
      text-align: center;
      i {
        display: block;
        width: 80px;
        height: 80px;
        margin: 0 auto 40px;
        img {
          display: block;
          width: 100%;
        }
      }
      p {
        font-size: 15px;
        color: #949aa9;
        span {
          cursor: pointer;
          color: @blue;
        }
      }
    }
    .btn {
      width: 76px;
      height: 32px;
      background-color: #53dee3;
      border-radius: 5px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      padding: 0;
      border: none;
      &.pay {
        background-color: #fff;
        border: solid 1px #53dee3;
        color: #53dee3;
      }
      &:hover {
        box-shadow: none;
      }
    }
  }

  .el_pagination_wrap {
    text-align: center;
    margin-top: 10px;
  }
  .no_table_data {
    text-align: center;
    background: #fff;
    padding-top:100px;
    min-height: calc(100vh - 208px);
    box-sizing: border-box;
    i {
      display: block;
      width: 80px;
      height: 80px;
      margin: 0 auto 40px;
      img {
        display: block;
        width: 100%;
      }
    }
    p {
      font-size: 15px;
      color: #949aa9;
      span {
        cursor: pointer;
        color: @blue;
      }
    }
  }
}
</style>

<template>
  <div class="trademarkObjection">

    <div v-show="ifShowConfig" class="trademarkObjectionAi">
      <div class="danTitle">
        <span @click="skip('/')">首页</span>
        >
        <span @click="skip('/PQ')">商标查询</span>
        >
        <span style="color: #506bce;">异议智写</span>
      </div>

      <div class="trademarkObjection">

        <el-row class="trademarkConfigTitle">
          <div class="switchTab">
            <span  @click="skip('/trademarkRejectionAI')">驳通智写</span>
            <span class="active">异议智写</span>
          </div>
        </el-row>

        <el-row style="padding: 0 10px 0 40px">
          <el-col :span="16" class="trademarkConfig">
            <div class="trademarkConfigContent">
              <div class="trademarkConfigContentBox">
                <el-row class="trademarkConfigItem">
                  <p>被异议商标号</p>
                  <el-input  placeholder="请输入被异议商标号" v-model="trademarkObjectionParam.opposedTrademark"/>
                </el-row>
                <el-row class="trademarkConfigItem">
                  <p>被异议商标类别</p>
                  <el-input placeholder="请输入被异议商标类别" v-model="trademarkObjectionParam.opposedNiceCode"/>
                </el-row>
                <el-row class="trademarkConfigItem">
                  <p>被异议商标公告期号</p>
                  <el-input placeholder="请输入被异议商标公告期号" v-model="trademarkObjectionParam.opposedTrialnum"/>
                </el-row>
                <el-row class="trademarkConfigItem">
                  <p>异议人名称</p>
                  <el-input placeholder="请输入异议人名称" v-model="trademarkObjectionParam.opposedName"/>
                </el-row>
                <el-row class="trademarkConfigItem">
                  <p>异议委托代理人名称</p>
                  <el-input placeholder="请输入异议委托人名称" v-model="trademarkObjectionParam.opposedAgentName"/>
                </el-row>
                <el-row class="trademarkConfigItem">
                  <p>被异议商标阐释</p>
                  <el-input placeholder="请输入被异议商标阐释" v-model="trademarkObjectionParam.opposedExplain"/>
                </el-row>
                <el-row class="trademarkConfigItem item1">
                  <p>异议情形及法条选择</p>
                  <el-cascader

                    placeholder="请选择异议情形及法条"
                    :options="objectionLawOption"
                    :props="{ multiple: true }"
                    clearable
                    v-model="trademarkObjectionLawSelect"
                  >
                    <template slot-scope="{ node, data}">
                      <el-tooltip effect="dark" :content="data.tooltips" placement="top-start">
                        <span>{{ data.label }}</span>
                      </el-tooltip>
                    </template>
                  </el-cascader>
                </el-row>
                <el-row class="trademarkConfigItem">
                  <p style="width: 70px">引证商标</p>
                  <el-input style="width: 310px" placeholder="请输入引证商标号（多个使用英文分号;分隔）" v-model="trademarkObjectionParam.citedTrademark"/>
                </el-row>
                <el-row style="margin-top: 20px">
                  <el-button class="trademarkButtonItem" @click="generate()">开始生成</el-button>
                </el-row>
              </div>
            </div>
          </el-col>

          <el-col :span="7" class="trademarkHistory">
            <h2>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="&#229;&#142;&#134;&#229;&#143;&#178;&#232;&#174;&#176;&#229;&#189;&#149; 1" clip-path="url(#clip0_45_65)">
                  <path id="Vector" d="M17.4375 9.51317C17.4639 14.1556 13.7426 17.9331 9.12129 17.9981C4.41563 18.0649 0.580083 14.2997 0.562505 9.59228C0.560747 9.33739 0.571294 9.08427 0.594146 8.83466C0.667974 7.97333 1.38516 7.31063 2.24825 7.31063C2.43633 7.31063 2.56993 7.49169 2.51719 7.67274C2.33965 8.28271 2.25001 8.91552 2.25001 9.56239C2.25001 10.4747 2.42754 11.3589 2.77911 12.1886C3.11836 12.9919 3.60528 13.7143 4.22579 14.3349C4.84629 14.9554 5.56875 15.4423 6.37207 15.7815C7.20352 16.1331 8.0877 16.3106 8.99825 16.3106C9.90879 16.3106 10.7947 16.1331 11.6244 15.7815C12.4277 15.4423 13.1502 14.9554 13.7707 14.3349C14.3912 13.7143 14.8781 12.9919 15.2174 12.1886C15.5689 11.3571 15.7465 10.4729 15.7465 9.56239C15.7465 8.65185 15.5689 7.76591 15.2174 6.93622C14.8781 6.1329 14.3912 5.41044 13.7707 4.78993C13.1502 4.16942 12.4277 3.68251 11.6244 3.34325C10.793 2.99169 9.90879 2.81415 8.99825 2.81415C8.0877 2.81415 7.20176 2.99169 6.37207 3.34325C6.05743 3.47685 5.75684 3.63153 5.46856 3.80907C5.40879 3.84599 5.43516 3.93915 5.50547 3.93915H6.61641C6.69903 3.93915 6.77989 3.97606 6.83262 4.0411L8.05782 5.51239C8.09649 5.5581 8.06309 5.62841 8.00332 5.62841H4.5C3.25723 5.62841 2.25001 4.62118 2.25001 3.37841V0.170401C2.25001 0.10712 2.32559 0.0772374 2.36954 0.121183L3.85489 1.60653C3.90762 1.65927 3.9375 1.73134 3.9375 1.80517V2.67353C3.9375 2.73153 4.00254 2.76493 4.04825 2.72978C5.44219 1.71903 7.15606 1.12314 9.00879 1.12489C13.6564 1.12841 17.4111 4.86552 17.4375 9.51317Z" fill="#062C71"/>
                  <path id="Vector_2" d="M11.25 6.25781V10.125C11.25 11.0566 10.4941 11.8125 9.5625 11.8125H5.41406C5.37539 11.8125 5.34375 11.7809 5.34375 11.7422V10.1953C5.34375 10.1566 5.37539 10.125 5.41406 10.125H9C9.31113 10.125 9.5625 9.87363 9.5625 9.5625V6.25781C9.5625 6.21914 9.59414 6.1875 9.63281 6.1875H11.1797C11.2184 6.1875 11.25 6.21914 11.25 6.25781Z" fill="#062C71"/>
                </g>
                <defs>
                  <clipPath id="clip0_45_65">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <span>历史记录</span>
            </h2>

            <div v-show="trademarkHistoryList.length > 0">
              <div class="trademarkHistoryList">
                <div v-for="(item, index) in trademarkHistoryList" class="trademarkHistoryItem">
                  <h3>{{item.title}}</h3>
                  <p>{{getFormattedTime(item.createTime)}}</p>

                  <el-tooltip placement="top">
                    <div slot="content">下载</div>
                    <svg @click="historyDownload(item)" class="rightHistoryDownloadIcon" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="&#228;&#184;&#139;&#232;&#189;&#189; (3) 1" clip-path="url(#clip0_172_1465)">
                        <rect id="Rectangle 1308" width="22" height="20" rx="5" fill="#ECF0FB"/>
                        <path id="Vector" d="M11.4996 4C11.8267 4 12.0918 4.25996 12.0918 4.58065L12.0915 12.2448L14.9891 9.40399C15.2203 9.17718 15.5953 9.17718 15.8265 9.40399C16.0578 9.63069 16.0578 9.99843 15.8265 10.2251L12.8956 13.0992C12.5445 13.4435 12.0941 13.631 11.6348 13.6616C11.5917 13.6722 11.5463 13.6774 11.4996 13.6774C11.4528 13.6774 11.4072 13.6721 11.3636 13.6621C10.905 13.6306 10.4551 13.4431 10.1043 13.0992L7.17348 10.2251C6.94217 9.99831 6.94217 9.63069 7.17348 9.40399C7.3901 9.19157 7.73292 9.17827 7.96546 9.36419L8.01061 9.40423L10.9077 12.2455L10.9075 4.58065C10.9075 4.25996 11.1726 4 11.4996 4ZM14.4603 14.8387C14.7873 14.8387 15.0524 15.0987 15.0524 15.4194C15.0524 15.74 14.7873 16 14.4603 16H8.53896C8.21193 16 7.94683 15.74 7.94683 15.4194C7.94683 15.0987 8.21193 14.8387 8.53896 14.8387H14.4603Z" fill="#3567E6"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_172_1465">
                          <rect width="22" height="20" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </el-tooltip>

                  <el-tooltip placement="top">
                    <div slot="content">历史回看</div>
                    <svg @click="backReview(item)" class="rightHistoryBackReviewIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
                      <path d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68zM16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25z" fill="currentColor"/>
                      <path d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6zm0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4z" fill="currentColor"/>
                    </svg>
                  </el-tooltip>
                </div>
              </div>

              <el-row style="text-align: center; margin-top: 20px">
                <el-pagination
                  small
                  background
                  layout="total, sizes, prev, jumper, next"
                  :page-sizes="[5, 10, 20, 50]"
                  :total = trademarkHistoryTotal
                  :current-page = trademarkHistoryQueryParam.pageNum
                  :page-size= trademarkHistoryQueryParam.pageSize
                  @size-change = handleTrademarkHistorySizeChange
                  @current-change = handleTrademarkHistoryCurrentChange
                />
              </el-row>
            </div>

            <div class="nonHostory" v-show="trademarkHistoryList.length == 0">
              <p>暂无历史</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div v-show="!ifShowConfig" class="trademarkObjectionResult">
      <el-row>
        <el-col class="mainContentMenuBlock" :span="5">
          <div class="mainContentMenuBlockBox">
            <h1>目录</h1>
            <div class="mainContentMenuBlockContentBox">
              <div class="mainContentMenuTopTitle" @click="handleDomScrollToTop2('0')">异议理由书</div>
              <div class="mainContentMenuTopTitle" @click="handleDomScrollToTop2('0-1')">法律基础</div>
              <div class="mainContentMenuTopTitle" @click="handleDomScrollToTop2('0-2')">理由分析</div>
              <el-tree
                :expand-on-click-node="false"
                :data="nowShowMenuData"
                :props="myEltreeDefaultProps"
                @node-click="handleDomScrollToTop"
                default-expand-all
              />
            </div>
          </div>
        </el-col>
        <el-col class="trademarkObjectionGenerateMainBlock" :span="19">
          <el-row style="text-align: left;margin-bottom: 30px">
            <div class="danTitle">
              <span @click="skip('/')">首页</span>
              >
              <span @click="skip('/PQ')">商标查询</span>
              >
              <span @click="skip()">异议智写</span>
              >
              <span style="color: #506bce;">生成页</span>
            </div>
          </el-row>

          <el-row style="padding-right: 40px">
            <el-col class="mainContentBlock" :span="middleBlockSpan">
              <div style="height: 100%;">
                <div class="mainContentBlockTitle">AI生成</div>

                <div class="AIGenerateContent">
                  <div id="myAiGenerateContent" class="myAiGenerateContent">
                    <div id="myResultContent" class="resultContent">
                      <h1 style="text-align: center; font-size: 23px" id="0">异议理由书</h1>
                      <p style="text-indent:0">国家知识产权局：</p>
                      <p>我公司，{{trademarkObjectionParam.opposedAgentName}}，受{{trademarkObjectionParam.opposedName}}（以下简称“异议人”）委托，现依据《中华人民共和国商标法》（《商标法》）第三十三条和《商标法实施条例》（《实施条例》）第二十四条的规定，针对由被{{trademarkInfo == null ? '' : trademarkInfo.applicantPersonName}}（以下简称“被异议人”）在{{trademarkObjectionParam.opposedNiceCode}}类“{{trademarkInfo == null ? '' : trademarkInfo.niceCodeDetail}}”商品项目上申请注册的“<img style="height: 40px; width: 40px" :src="trademarkInfo == null ? '' : 'https://tmimage.lanternfish.cn'+trademarkInfo.trademarkImageUrl"/>”商标（以下简称“被异议商标”）提出异议，被异议商标刊登在{{ trademarkInfo == null ? '' : trademarkInfo.trialDate }}第{{ trademarkObjectionParam.opposedTrialnum }}期《商标公告》上，初步审定号为{{ trademarkObjectionParam.opposedTrademark }}。请贵局予以审理。</p>

                      <h1 style="text-align: center; font-size: 20px" id="0-1">法律基础</h1>
                      <div style="text-indent:0; margin: 5px 0" v-for="item in trademarkObjectionLawSelectString" v-html="item"/>

                      <h1 style="text-align: center; font-size: 20px" id="0-2">理由分析</h1>

                      <div v-for="item in nowShowMenuData">
                        <h1 :id="item.id">{{item.label}}</h1>

                        <div v-if="item.id == '4'">
                          <p>为方便贵局审查，现将被异议商标与引证商标比较如下：</p>
                          <table class="resultContentTable table0" v-if="trademarkInfo !=null">
                            <tr>
                              <th></th>
                              <th>商标</th>
                              <th>申请人</th>
                              <th>申请注册号</th>
                              <th>申请日</th>
                              <th>注册日</th>
                              <th>类别</th>
                              <th>商品</th>
                            </tr>
                            <tr>
                              <td>被异议商标</td>
                              <td>
                                <img v-if="trademarkInfo.trademarkImageUrl!=null" :src="'https://tmimage.lanternfish.cn'+trademarkInfo.trademarkImageUrl">
                                <span v-else>{{trademarkInfo.trademarkName}}</span>
                              </td>
                              <td>{{trademarkInfo.applicantPersonName}}</td>
                              <td>{{trademarkInfo.applyNo}}</td>
                              <td>{{trademarkInfo.applyDate}}</td>
                              <td>{{trademarkInfo.regDate}}</td>
                              <td>{{trademarkInfo.niceCode}}</td>
                              <td>
                                <el-tooltip placement="top" :content="trademarkInfo.niceCodeDetail">
                                  <span>{{trademarkInfo.niceCodeDetail}}</span>
                                </el-tooltip>
                              </td>
                            </tr>
                            <tr v-for="item in quoteTrademarkInfoList">
                              <td>{{"引证商标"+item.rowIndex}}</td>
                              <td><img :src="'https://tmimage.lanternfish.cn'+item.trademarkImageUrl"></td>
                              <td>
                                <el-tooltip placement="top" :content="item.applicantPersonName">
                                  <span>{{item.applicantPersonName}}</span>
                                </el-tooltip>
                              </td>
                              <td>{{item.applyNo}}</td>
                              <td>{{item.applyDate}}</td>
                              <td>{{item.regDate}}</td>
                              <td>{{item.niceCode}}</td>
                              <td>
                                <el-tooltip placement="top" :content="item.niceCodeDetail">
                                  <span>{{item.niceCodeDetail}}</span>
                                </el-tooltip>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div v-for="item1 in item.children">
                          <h1 :id="item1.id">{{item1.label}}</h1>

                          <div v-if="item1.pre!=null" v-for="it in item1.pre">
                            <el-skeleton v-if="it.content == ''" :rows="3" animated />
                            <v-md-editor
                              v-else
                              left-toolbar=""
                              right-toolbar=""
                              :mode="it.messageId ? 'edit' : 'preview'"
                              v-model="it.content"
                              @blur.capture.native = "handleEditSave(it)"
                            />
                          </div>

                          <div v-if="generateType == 1 && item1.id == '1-4'">
                            <table class="resultContentTable" v-if="objectorTrademarkList !=null">
                              <tr>
                                <th>申请号</th>
                                <th>国际分类</th>
                                <th>商标</th>
                                <th>被抢注商标简介</th>
                              </tr>
                              <tr v-for="item in objectorTrademarkList">
                                <td>{{item.applyNo}}</td>
                                <td>{{item.niceCode}}</td>
                                <td>
                                  <img v-if="item.trademarkImageUrl != null" :src="'https://tmimage.lanternfish.cn'+item.trademarkImageUrl">
                                  <span v-else>{{item.trademarkName}}</span>
                                </td>
                                <td>{{item.trademarkImageDescription}}</td>
                              </tr>
                            </table>
                            <el-skeleton v-else :rows="3" animated />
                          </div>

                          <v-md-editor
                            class="myHugeVMDEditor"
                            v-if="item1.id == '3-1'"
                            left-toolbar=""
                            right-toolbar=""
                            mode="edit"
                            :placeholder="item1.placeholder"
                            v-model="item1.content"
                            @blur.capture.native = "handleEditSave2(item1)"
                          />

                          <div v-if="item1.post!=null" v-for="it in item1.post">
                            <v-md-editor
                              left-toolbar=""
                              right-toolbar=""
                              mode="preview"
                              v-model="it.content"
                            />
                          </div>

                          <div v-for="item2 in item1.children">
                            <h2 :id="item2.id">{{item2.label}}</h2>

                            <div v-if="item2.pre!=null" v-for="it in item2.pre">
                              <el-skeleton v-if="it.content == ''" :rows="3" animated />
                              <v-md-editor
                                v-else
                                left-toolbar=""
                                right-toolbar=""
                                :mode="it.messageId ? 'edit' : 'preview'"
                                v-model="it.content"
                                @blur.capture.native = "handleEditSave(it)"
                              />
                            </div>

                            <v-md-editor
                              v-if="item2.id == '3-2-1'"
                              left-toolbar=""
                              right-toolbar=""
                              mode="edit"
                              :placeholder="item2.placeholder"
                              v-model="item2.content"
                              @blur.capture.native = "handleEditSave2(item2)"
                            />

                            <div v-if="item2.post.length > 0" v-for="it in item2.post">
                              <v-md-editor
                                left-toolbar=""
                                right-toolbar=""
                                mode="preview"
                                v-model="it.content"
                              />
                            </div>

                            <div v-if="item2.children.length > 0" v-for="item3 in item2.children">
                              <h3 :id="item3.id">{{item3.label}}</h3>

                              <el-skeleton v-if="item3.content == ''" :rows="3" animated />
                              <v-md-editor
                                v-else
                                left-toolbar=""
                                right-toolbar=""
                                :mode="item3.messageId ? 'edit' : 'preview'"
                                v-model="item3.content"
                                @blur.capture.native = "handleEditSave(item3)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="AIGenerateBottomButton">
                  <el-button @click="handleObjectionStop" class="AIGenerateButton2">终止</el-button>
                  <el-button :disabled="regenerateDisabled" @click="handleObjectionRegenerate" class="AIGenerateButton2">重新生成</el-button>
                  <el-button :disabled="buttonDisabled || ifObjectorTrademarkQueryNotComplate" @click="handleDownloadGenerate(trademarkObjectionParam.caseNumber, chatNumber, null)" class="AIGenerateButton">下载</el-button>
                  <el-button @click="skip()" class="AIGenerateButton">再来一件</el-button>
                </div>
              </div>

              <!--左侧收缩按钮 1收缩 2展开-->
              <img class="rightButton1" v-show="ifShowRightBlock" @click="handleRetractButton('right1')"  src="../../assets/images/patentOAAi/rightRetract.png">
              <div class="rightButton2" v-show="!ifShowRightBlock" @click="handleRetractButton('right2')">AI</div>
            </el-col>

            <el-col class="mainContentBlock coloredBG" :span="rightBlockSpan">
              <div class="AIDialogTitle">智能AI</div>

              <div id="AIDialogueContent" class="AIDialogueContent">
                <el-row v-for="(item, index) in dialogList" :class="item.type == 'user' ? 'userDialog' : 'assistantDialog'">
                  <!--AI回答样式-->
                  <img v-if="item.type=='assistant'" class="dialogIconAssistant" src="../../assets/images/patentOAAi/fishIcon.png"/>
                  <div style="position: relative; display: inline-block; max-width: 70%">
                    <div v-if="item.type=='assistant'" class="dialogContentAssistant" v-html="item.content"/>
                    <svg
                      v-if="item.type=='assistant' && item.content != '' && item.content !='欢迎使用蓝灯鱼驳通智写AI对话'"
                      @click="handleDelete(item, index)"
                      class="AIDialogueCopyIcon2"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" width="1em"
                      height="18px"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none">
                        <path d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5zM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25z" fill="currentColor"/>
                      </g>
                    </svg>
                    <svg
                      v-if="item.type=='assistant' && item.content != ''"
                      @click="handleCopy(item)"
                      class="AIDialogueCopyIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" width="1em"
                      height="18px"
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1 1 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1zM5.002 8L5 20h10V8zM9 6h8v10h2V4H9zm-2 5h6v2H7zm0 4h6v2H7z"/>
                    </svg>
                    <svg
                      v-show="item.type=='assistant' && item.content != '' && item.content !='欢迎使用蓝灯鱼驳通智写AI对话'"
                      @click="handleRegenerate(item)"
                      class="AIDialogueRegenerateIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" width="1em"
                      height="18px"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" fill="currentColor"/>
                    </svg>
                  </div>

                  <!--用户提问样式-->
                  <div v-if="item.type=='user'" class="dialogContentUser" v-html="item.content"/>
                  <img v-if="item.type=='user'" class="dialogIconUser" src="../../assets/images/patentOAAi/userIcon.png"/>
                  <svg
                    v-if="item.type=='user' && item.content != ''"
                    @click="handleDelete(item, index)"
                    class="AIDialogueCopyIcon3"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true" role="img" width="1em"
                    height="18px"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5zM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25z" fill="currentColor"/>
                    </g>
                  </svg>
                </el-row>
                <el-row v-if="chatLoading" style="text-align: center">
                  <el-button class="myChatStopGenerate" @click="handleStopMessage">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class=" iconify iconify--ri" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16M9 9h6v6H9z"/>
                    </svg>
                    <span>终止生成</span>
                  </el-button>
                </el-row>
              </div>

              <div class="AIDialogueInput">
                <el-input
                  @keyup.enter.native="handleSendMessage"
                  v-model="myAIDialogInput"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 3}"
                  class="AIDialogInputBlock"
                />
                <img @click="handleSendMessage" class="AIDialogInputButton" src="../../assets/images/patentOAAi/inputIcon.png"/>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";

export default {
  name: "trademarkObjection",
  data:()=>{
    return {
      ifShowConfig:true,

      objectionLawOption:[
        {
          value:"1",
          label:"不以使用为目的，恶意注册",
          tooltips:"不以使用为目的，恶意注册",
          children: [
            {
              value:"1-1",
              label:"第四条，自然人、法人或者...",
              tooltips:"第四条，自然人、法人或者其他组织在生产经营活动中，对其商品或者服务需要取得商标专用权的，应当向商标局申请商标注册。不以使用为目的的恶意商标申请，应当予以驳回。",
            },
            {
              value:"1-2",
              label:"第七条，申请注册和使用商...",
              tooltips:"第七条，申请注册和使用商标，应当遵循诚实信用原则。",
            },
            {
              value:"1-3",
              label:"第四十四条，已经注册的商...",
              tooltips:"第四十四条，已经注册的商标，违反本法第十条、第十一条、第十二条规定的，或者是以欺骗手段或者其他不正当手段取得注册的，由商标局宣告该注册商标无效；其他单位或者个人可以请求商标评审委会宣告该注册商标无效。",
            },
          ]
        },
        {
          value:"2",
          label:"与他人未注册的在先使用商标相同或近似",
          tooltips:"与他人未注册的在先使用商标相同或近似",
          children: [
            {
              value: "2-1",
              label:"第十五条第一款，未经授权,...",
              tooltips:"第十五条第一款，未经授权，代理人以自己的名义将被代理人的商标进行注册，被代理人提出异议的，不予注册并禁止使用。",
            },
            {
              value: "2-2",
              label:"第十五条第二款，被异议人与...",
              tooltips:"第十五条第二款，被异议人与异议人层就合作有过联系。",
            },
          ]
        },
        {
          value: "3",
          label:"与他人已注册的在先商标相同或近似",
          tooltips:"与他人已注册的在先商标相同或近似",
          children: [
            {
              value: "3-1",
              label:"第三十条，申请注册的商标...",
              tooltips:"第三十条，申请注册的商标，凡不符合本法有关规定或者同他人在同一种商品或者类似商品上已经注册的或者初步审定的商标相同或者近似的，由商标局驳回申请，不予公告。",
            },
            {
              value: "3-2",
              label: "第三十一条，两个或者两个...",
              tooltips: "第三十一条，两个或者两个以上的商标注册申请人，在同一种商品或者类似商品上，以相同或者近似的商标申请注册的，初步审定并公告申请在先的商标；同一天申请的，初步审定并公告使用在先的商标，驳回其他人的申请，不予公告。",
            }
          ]
        },
      ],
      trademarkObjectionLawSelect:[],
      // trademarkObjectionParam:{
      //   //案卷编号
      //   caseNumber:null,
      //   //被异议商标
      //   opposedTrademark:"37402842",
      //   //被异议商标尼斯分类
      //   opposedNiceCode:"16",
      //   //被异议商标初审公告期
      //   opposedTrialnum:"1911",
      //   //异议人名称
      //   opposedName:"小米科技有限责任公司",
      //   //被异议委托人名称
      //   opposedAgentName:"蓝灯鱼",
      //   //被异议商标解释
      //   opposedExplain:"redmi",
      //   //引证商标（多个使用分号;分隔）
      //   citedTrademark:"40028088A;56996773",
      //   //商标类型
      //   trademarkCategory:null,
      //   //异议配置
      //   objectionConfig:[],
      // },
      trademarkObjectionParam:{
        //案卷编号
        caseNumber:null,
        //被异议商标
        opposedTrademark:null,
        //被异议商标尼斯分类
        opposedNiceCode:null,
        //被异议商标初审公告期
        opposedTrialnum:null,
        //异议人名称
        opposedName:null,
        //被异议委托人名称
        opposedAgentName:null,
        //被异议商标解释
        opposedExplain:null,
        //引证商标（多个使用分号;分隔）
        citedTrademark:null,
        //商标类型
        trademarkCategory:null,
        //异议配置
        // {
        //   //异议情形及法条选择,第一级菜单
        //   opposedType:null,
        //   //异议情形及法条选择,第二级菜单
        //   lawTerm:null,
        //   //异议情形及法条选择,第三级菜单
        //   groundLaw:null,
        //   //该异议情形下涉及的种类
        //   situation:null
        // }
        objectionConfig:[],
      },
      trademarkObjectionLawSelectString:[],

      //历史列表
      trademarkHistoryList:[],
      trademarkHistoryQueryParam:{
        pageNum:1,
        pageSize:10
      },
      trademarkHistoryTotal:9999,

      stopSignal: axios.CancelToken.source(),
      //generateType:0-其他1-包含法令第4条
      generateType:0,
      myEltreeDefaultProps:{
        children: 'children',
        label: 'label'
      },
      nowShowMenuData:[],
      //data1-第四条 data2-第十五条第一款 data3-第十五条第二款 data3-第三十条
      menuData1:[
        {
          id:"1-1",
          label:"(一)被异议商标的注册申请并非出于生产经营活动的需要",
          pre:null,
          post:[
            {content:"经异议人对被异议人的调查，没有发现被异议人实际使用被异议商标的证据。可见，被异议人并不是出于意图使用的目的申请注册被异议商标。"},
            {content:"根据《商标法》第四条的规定，自然人、法人或者其他组织在生产经营活动中，对其商品或者服务需要取得商标专用权的，应当向商标局申请商标注册。不以使用为目的的恶意商标注册申请，应当予以驳回。这很明显的反映出申请商标注册应该是基于“生产经营活动”的需要，脱离了生产经营活动的商标申请，可以被认为是以谋取不正当利益为目的的商标抢注行为。因此，异议人有理由怀疑其注册被异议商标是出于恶意的意图，企图无偿占有他人劳动果实的行为。"}
          ]
        },
        {
          id:"1-2",
          label:"(二)被异议商标明显抄袭引证商标",
          pre:null,
          post: [
            {content:"被异议商标与引证商标构成近似商标。引证商标被异议人作为公司商号及品牌名称使用很久，在世界范围内的消费者中被广泛知晓。在这种情况下，如果说被异议商标是被异议人独创的品牌，非常难以让人信服。被异议人显然是抄袭了引证商标，企图浑水摸鱼，让消费者在购买其提供的商品时，误以为与异议人存在关联从而对其商品产生好感以达到获得不法利益的目的。"},
            {content:"企业的品牌应该凝结企业的经营理念与追求，只有这样品牌才会拥有充沛的生命力和竞争力。而被异议人显然忽略的这一点，而仅仅想通过抄袭他人知名品牌来经营自己生意，这种抄袭摹仿的行为明显违背了诚实信用原则，而抄袭摹仿的结果可能短期内会获得满意的收益，但是从长久来看，会失去消费者的信任，使企业难以成长并最终被淘汰。"}
          ]
        },
        {
          id:"1-3",
          label:"(三)被异议人明知异议人及引证商标的存在",
          pre:null,
          post:[
            {content:"在互联网如此发达的今天，及网络媒体传播速度快、影响范围广的独特特点下，可以认定被异议人在提交被异议商标注册申请前，完全知晓引证商标的存在。"},
            {content:"可见，被异议人在明知应知异议人及引证商标的情况下，还申请注册被异议商标，其主观恶意十分明显，其目的是利用异议人及引证商标极高的知名度和美誉度，来混淆相关消费者的视听，从而销售被异议商标的指定商品以获得暴利。"},
            {content:"根据《最高人民法院关于审理商标授权确权行政案件若干问题的意见》第一条，人民法院在审理商标授权确权行政案件时，对于尚未大量投入使用的诉争商标，在审查判断商标近似和商品类似等授权确权条件及处理与在先商业标志冲突上，可依法适当从严掌握商标授权确权的标准，充分考虑消费者和同业经营者的利益，有效遏制不正当注册行为，注重对于他人具有较高知名度和较强显著性的在先商标、企业名称等商业标志权益的保护，尽可能消除商业标志混淆的可能性。"},
            {content:"从最高院给出的意见可以明确，应当给予他人较高知名度和较强显著性的在先商标更强的保护力度，防止他人傍名牌、搭便车，违反诚实信用的商标注册行为，因为如果这种行为不及时制止，会损害已经建立并完善的市场格局，也会产生破窗效应，即更多的企业在利益的驱使下加入到这种通过投机钻营、傍名牌、搭便车的行为获取暴利的行列中，同时，也会严重打击其他遵纪守法、诚实信用经营者的积极性。而这种恶性循环会严重损害国家良好的经济秩序，破坏诚实信用的社会风气，造成资源的浪费，不利于社会主义经济建设。"},
            {content:"在先类似案例："},
            {content:"在《关于第12754993号“BVLGARI”商标无效宣告请求裁定书》中，商评委认定该案的被申请人申请注册的“BVLGARI”商标违反了《商标法》第四十四条第一款的规定，最终予以无效宣告。在该裁定中明确载明：“宝格丽”、“BVLGARI”确为申请人在“珠宝”等商品上具有较长使用历史、在市场上享有较高知名度的商标，普通消费者对此接触也较多，被申请人对该项事实亦应知晓。且鉴于“BVLGARI”并非现有固定搭配的词汇，因此，除非被申请人可以合理解释争议商标的渊源，否则争议商标与申请人商标构成巧合的可能性很小。在被申请人并未对争议商标的商标设计来源进行答辩并予以举证的情况下，我委合理认为，被申请人以申请人商标特有表现形式申请注册于别类商品，具有不正当利用申请人商标以盈利的目的。该类抢注行为不仅会导致相关公众对商品来源产生误认，更扰乱了正常的商标注册管理秩序，并有损于公平竞争的市场秩序，违反了诚实信用原则，不应鼓励和支持。因此，争议商标的申请注册构成了《商标法》第四十四条第一款规定的情形。"},
            {content:"本案与上述案例非常类似，引证商标是异议人的商号，具有很强的显著性，而“宝格丽/BVLGARI”品牌已在中国拥有很高的知名度，被异议商标所有人应当知晓异议人及其品牌。被异议人将异议人“宝格丽/BVLGARI”品牌极为近似的商标申请注册为商标，具有不正当利用异议人商标以盈利的目的。该类抢注行为不仅会导致相关公众对商品来源产生误认，更扰乱了正常的商标注册管理秩序，并有损于公平竞争的市场秩序，违反了诚实信用原则，不应鼓励和支持。"},
            {content:"由此可见，被异议人明显在明知应知异议人及引证商标的情况下，还将与引证商标高度近似的商标注册和使用在其指定商品上，其傍名牌的恶意十分明显，违反诚实信用原则。其目的是利用异议人及引证商标极高的知名度和美誉度，来混淆相关消费者的视听，从而销售被异议商标的指定商品/服务以获得暴利，因此属于“傍名牌”、“搭便车”的不正当行为，违反诚实信用原则，如予注册，会容易产生不良的社会影响，异议人恳请贵局依据《商标法》第七条、第十条第一款第八项以及第四十四条第一款规定，不予核准被异议商标的注册。"}
          ]
        },
        {
          id:"1-4",
          label:"(四)被异议人还抢注其他知名商标，进一步证实了被异议人的恶意",
          pre:[
            {
              id:"4-0",
              content:"经查询，被异议人名下共有 枚商标，其中部分商标还抢注了其他知名商标，异议人将被异议人名下部分商标列表如下供审查员参考："
            },
          ],
          post:[
            {content:"可见，被异议人在抢注商标时一般做法即是在品牌前面增加几个汉字或字母，并将品牌中部分汉字更换为读音相同的其他汉字或并将品牌中部分字母更换为相似的其他字母。既能让消费者联想到被抢注的品牌，利用被抢注品牌的知名度，又能通过国家知识产权局的初步审查。被异议人抢注其他知名商标的行为进一步证实了被异议人的恶意。"},
            {content:"综上，被异议商标的注册申请并非出于生产经营的需要，违反诚实信用原则，会损害异议人、社会公众的利益，浪费国家的行政审查资源，也会扰乱正常的经济秩序。因此，根据《商标法》第四条、第七条、第十条第一款八项和第四十四条的规定，被异议商标应不予核准注册。"}
          ]
        }
      ],
      menuData2:[
        {
          id: "2-1",
          label: "(一)被异议人是异议人的代理人或者代表人",
          pre:null,
          post: [
            {content:"在被异议商标申请日之前，被异议人就已经是异议人集团公司在中国的特许经营人之一。双方长期存在特许经营关系，属于代理关系范畴。"},
            {content:"如下XXX中关于异议人集团相关的介绍中提到了被异议人加入异议人集团的关系。XXX中相关信息通常是由当事人发布，即本案的被异议人主动发布了说明双方关系的介绍。"},
            {content:"异议人将在补充阶段补充提交其他证明双方关系的相关证据。"}
          ]
        },
        {
          id:"2-2",
          label:"(二)被异议商标指定的商品/服务与异议人商标使用的商品/服务相同或类似",
          pre:null,
          post:[
            {content:"《商标法》第十五条中，对被代理人商标的保护范围不限于与该商标所使用的商品相同的商品，也及于类似的商品；同时，代理人不得申请注册的商标，不限于与被代理人商标相同的商标，也包括与被代理人商标近似的商标。"}
          ]
        },
        {
          id:"2-3",
          label:"(三)被异议商标与异议人的商标相同或者近似",
          pre:null,
          post:null,
        },
        {
          id:"2-4",
          label:"(四)被异议人未经异议人授权，以其自己名义将异议人的商标进行注册，违反了《商标法》第十五条的规定，应不予核准注册",
          pre:null,
          post: [
            {content:"根据《商标审查及审理标准》的规定，代理人不仅包括《中华人民共和国民法通则》、《中华人民共和国合同法》中规定的代理人，也包括基于商事业务往来而可以知悉被代理人商标的经销商。"},
            {content:"同时，根据最高人民法院关于审理商标授权确权行政案件若干问题的意见第十二条：商标代理人、代表人或者经销、代理等销售代理关系意义上的代理人、代表人未经授权，以自己的名义将被代理人或者被代表人商标进行注册的，人民法院应当认定属于代理人、代表人抢注被代理人、被代表人商标的行为。"},
            {content:"被异议人仅为异议人引证商标在中国的被许可使用人之一，被异议人在中国可以特许经营引证商标品牌从事相关业务。但异议人从未授权被异议人以其名义在中国申请注册被异议商标。"},
            {content:"被异议人在未经异议人的授权，申请了与异议人商标相同的被异议商标，其恶意非常明显，属于《商标法》第十五条规定的不予注册的情形。"}
          ]
        }
      ],
      menuData3:[
        {
          id: "3-1",
          label: "(一)异议人的${quoteTrademarkNameListString}商标在被异议商标申请之前已经在先使用",
          pre:null,
          content:"",
          placeholder:"此处请填写具体的在先使用证据。\n示例：2007年12月1日，异议人与XXX有限公司签订了代理经销合同，由其代为销售异议人产品，异议人产品正式进入中国。异议人还与XX有限公司合作，促进异议人商品在中国的宣传和发展、并通过其进一步许可授权他人使用“蓝灯鱼”、“ Lanternfish”等商标及进行品牌管理。2007年-2019年，异议人的产品销往天津、上海、香港、青岛、浙江义乌、北京等地。因此，在被异议商标申请之前，异议人的“蓝灯鱼”、“ Lanternfish”商标已经在相关商品上在先使用。",
          post: null
        },
        {
          id:"3-2",
          label:"(二)被异议人明知异议人${quoteTrademarkNameListString}商标的存在",
          pre:null,
          post:null,
          children:[
            {
              id:"3-2-1",
              pre:[
                {content:"异议人曾与${opposedName}联系过如下事宜："}
              ],
              label:"1.${opposedName}曾联系异议人",
              content:"",
              placeholder:"此处请填写被异议人与异议人的具体联系情况及后续的合同来往情况",
              post:null
            }
          ]
        },
        {
          id:"3-3",
          label:"(三)被异议商标指定的商品或服务与异议人在先使用商标的商品或服务相同或类似",
          pre:null,
          post:[
            {content:"《商标法》第十五条中，对被代理人商标的保护范围不限于与该商标所使用的商品相同的商品，也及于类似的商品；同时，代理人不得申请注册的商标，不限于与被代理人商标相同的商标，也包括与被代理人商标近似的商标。"}
          ]
        },
        {
          id:"3-4",
          label:"(四)被异议商标与异议人的商标相同或者近似",
          pre:null,
          post:[
            {content:"综上所述，被异议人在明知异议人${quoteTrademarkNameListString}商标的情况下，未经异议人的授权，申请了与其高度近似的被异议商标，其恶意非常明显，被异议商标已构成《商标法》第十五条第二款所指的情形。因此，被异议商标违反《商标法》第十五条第二款的规定，应不予核准注册。"}
          ]
        }
      ],
      menuData4:[
        {
          id:"4-1",
          label:"(一)被异议商标与引证商标构成近似商标",
          pre:null,
          post:null,
          children:[
            {
              id:"4-1-1",
              label:"1.被异议商标与引证商标整体外观构成相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}整体外观构成相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-1-2",
              label:"2.被异议商标与引证商标的显著部分近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}显著部分构成相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-1-3",
              label:"3.被异议商标与引证商标呼叫构成相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}呼叫构成相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-1-4",
              label:"4.被异议商标与引证商标含义相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}含义相同/近似",
              pre:null,
              children:[]
            },
          ]
        },
        {
          id:"4-2",
          label:"(二)被异议商标与引证商标的指定商品构成类似",
          pre:null,
          children:[
            {
              id:"4-2-1",
              label:"1.被异议商标与引证商标指定商品、服务功能、用途相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务功能、用途相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-2-2",
              label:"2.被异议商标与引证商标指定商品/服务生产部门相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务生产部门相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-2-3",
              label:"3.被异议商标与引证商标指定商品/服务销售渠道相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务销售渠道相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-2-4",
              label:"4.被异议商标与引证商标指定商品/服务消费对象相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务消费对象相同/近似",
              pre:null,
              children:[]
            },
          ]
        },
        {
          id:"4-3",
          label:"(三)引证商标具有较高的显著性和知名度，被异议商标容易被认为是引证商标的系列商标",
          pre:null,
          post:null,
          children:[
            {
              id:"4-3-1",
              pre:null,
              label:"1.引证商标具有较高的显著性",
              post:[
                {content:"根据《最高人民法院关于审理商标授权确权行政案件若干问题的意见》，认定商标是否近似，要考虑相关商标的显著性。引证商标均具有很强的固有显著性，在判断被异议商标与引证商标近似性和混淆可能性时应当充分考虑这一事实。"}
              ]
            },
            {
              id:"4-3-2",
              pre:null,
              label:"2.引证商标具有较高的知名度",
              post:[
                {content:"根据《最高人民法院关于审理商标授权确权行政案件若干问题的意见》，认定商标是否近似，要考虑请求被保护商标的知名度。"},
                {content:"异议人的引证商标在包括中国在内的世界范围内长期、持续、广泛的使用和宣传，已经在被异议商标申请日之前在中国具有了极高的知名度和影响力，故，被异议商标与引证商标在市场上的共存，更易引起相关公众对商品来源的混淆和误认。"}
              ]
            },
          ]
        },
      ],
      trademarkInfo:null,
      quoteTrademarkInfoList:null,
      ifObjectorTrademarkQueryNotComplate:false,
      objectorTrademarkList:null,

      regenerateDisabled:true,
      buttonDisabled:true,
      ifShowRightBlock:true,
      middleBlockSpan:16,
      rightBlockSpan:8,

      chatNumber:"",
      dialogList:[{
        type:"assistant",
        conversationId:0,
        content:"欢迎使用蓝灯鱼驳通智写AI对话"
      }],
      myAIDialogInput:"",
      chatLoading:false,
      stopSignal2: axios.CancelToken.source(),
    }
  },
  mounted() {
    if(this.$route.query.applyNumber != null){
      this.trademarkObjectionParam.opposedTrademark = this.$route.query.applyNumber
      this.trademarkObjectionParam.opposedNiceCode = this.$route.query.niceCodeString
      this.trademarkObjectionParam.opposedTrialnum = this.$route.query.trialNumber
    }

    this.queryHistory()
  },
  watch:{
    trademarkObjectionLawSelect(newVal, oldVal){
      let mySet = new Set()
      let mySet2 = new Set()
      newVal.forEach(item=>{
        mySet.add(item[0])
        mySet.add(item[1])
      })
      oldVal.forEach(item=>{
        mySet2.add(item[0])
        mySet2.add(item[1])
      })

      let mylist = []
      if(mySet.size > mySet2.size){
        //说明新增选项
        if(mySet.has("1")){
          mylist.push(["1","1-1"])
          mylist.push(["1","1-2"])
          mylist.push(["1","1-3"])
        }
        if (mySet.has("2-1")){
          mylist.push(["2","2-1"])
        }
        if (mySet.has("2-2")){
          mylist.push(["2","2-2"])
        }
        if (mySet.has("3")){
          mylist.push(["3","3-1"])
          mylist.push(["3","3-2"])
        }
      }
      else if(mySet.size < mySet2.size){
        //说明取消选项
        if(mySet.has("1-1") && mySet.has("1-2") && mySet.has("1-3")){
          mylist.push(["1","1-1"])
          mylist.push(["1","1-2"])
          mylist.push(["1","1-3"])
        }
        if(mySet.has("2-1")){
          mylist.push(["2","2-1"])
        }
        if (mySet.has("2-2")){
          mylist.push(["2","2-2"])
        }
        if (mySet.has("3-1") && mySet.has("3-2")){
          mylist.push(["3","3-1"])
          mylist.push(["3","3-2"])
        }
      }
      else {
        return
      }
      this.trademarkObjectionLawSelect = mylist
    }
  },
  methods:{
    //根据长度生成随机字符串
    generateRandomString(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    //页面跳转
    skip(item) {
      if(item) {
        this.$router.push({path: item})
      }
      else {
        location.reload();
      }
    },
    backReview(item){
      store.commit("loadingBool", true)

      this.trademarkObjectionParam = {
        caseNumber:item.caseNumber,
        opposedTrademark:item.opposedTrademark,
        opposedNiceCode:item.opposedNiceCode,
        opposedTrialnum:item.opposedTrialnum,
        opposedName:item.opposedName,
        opposedAgentName:item.opposedAgentName,
        opposedExplain:item.opposedExplain,
        citedTrademark:item.citedTrademark,
        trademarkCategory:item.trademarkCategory,
        objectionConfig:[]
      }
      this.chatNumber = item.chatNumber

      this.trademarkObjectionLawSelectString = []
      this.generateType = 0
      let lawOptionSet = new Set()

      item.objectionConfig.forEach(item=>{
        switch (item.opposedType){
          case "1":
            lawOptionSet.add("1-1")
            this.trademarkObjectionParam.objectionConfig.push(["1","1-1"])
            this.trademarkObjectionParam.objectionConfig.push(["1","1-2"])
            this.trademarkObjectionParam.objectionConfig.push(["1","1-3"])
            this.trademarkObjectionLawSelectString.push("<b>第四条</b>，自然人、法人或者其他组织在生产经营活动中，对其商品或者服务需要取得商标专用权的，应当向商标局申请商标注册。不以使用为目的的恶意商标申请，应当予以驳回。")
            this.trademarkObjectionLawSelectString.push("<b>第七条</b>，申请注册和使用商标，应当遵循诚实信用原则。")
            this.trademarkObjectionLawSelectString.push("<b>第四十四条</b>，已经注册的商标，违反本法第十条、第十一条、第十二条规定的，或者是以欺骗手段或者其他不正当手段取得注册的，由商标局宣告该注册商标无效；其他单位或者个人可以请求商标评审委会宣告该注册商标无效。")
            this.generateType = 1
            break
          case "2":
            if(item.lawTerm == "1"){
              lawOptionSet.add("2-1")
              this.trademarkObjectionParam.objectionConfig.push(["2","2-1"])
              this.trademarkObjectionLawSelectString.push("<b>第十五条第一款</b>，未经授权,代理人或者代表人以自己的名义将被代理人或者被代表人的商标进行注册,被代理人或者被代表人提出异议的,不予注册并禁止使用。")
            }
            else if(item.lawTerm == "2"){
              lawOptionSet.add("2-2")
              this.trademarkObjectionParam.objectionConfig.push(["2","2-2"])
              this.trademarkObjectionLawSelectString.push("<b>第十五条第二款</b>，就同一种商品或者类似商品申请注册的商标与他人在先使用的未注册商标相同或者近似,申请人与该他人具有前款规定以外的合同、业务往来关系或者其他关系而明知该他人商标存在, 该他人提出异议的, 不予注册。")
            }
            break
          case "3":
            lawOptionSet.add("3-1")
            this.trademarkObjectionParam.objectionConfig.push(["3","3-1"])
            this.trademarkObjectionParam.objectionConfig.push(["3","3-2"])
            this.trademarkObjectionLawSelectString.push("<b>第三十条</b>，申请注册的商标，凡不符合本法有关规定或者同他人在同一种商品或者类似商品上已经注册的或者初步审定的商标相同或者近似的，由商标局驳回申请，不予公告。")
            this.trademarkObjectionLawSelectString.push("<b>第三十一条</b>，两个或者两个以上的商标注册申请人，在同一种商品或者类似商品上，以相同或者近似的商标申请注册的，初步审定并公告申请在先的商标；同一天申请的，初步审定并公告使用在先的商标，驳回其他人的申请，不予公告。")
            break
        }
      })
      this.trademarkObjectionLawSelectString.push("<b>《最高人民法院关于审理商标授权确权行政案件若干问题的规定》第二十四条规定</b>：以欺骗手段以外的其他方式扰乱商标注册秩序、损害公共利益、不正当占用公共资源或者谋取不正当利益的，人民法院可以认定其属于商标法第四十四条第一款规定的“其他不正当手段”。")
      this.trademarkObjectionLawSelect = this.trademarkObjectionParam.objectionConfig

      this.buttonDisabled = false
      this.regenerateDisabled = false

      this.$axios.get("/aigc/app/objection/detail/"+this.chatNumber)
        .then(response=>{
          this.trademarkInfo = response.data.data.trademarkInfo
          this.quoteTrademarkInfoList = this.trademarkInfo.quoteTrademarkInfoList

          let queryList = new Map()
          if(lawOptionSet.has("1-1")){
            this.nowShowMenuData.push({
              id:"1",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议商标的注册申请并非出于生产经营活动的需要，被异议人系以不正当手段申请注册商标，其注册和使用违反诚实信用原则，会产生不良影响",
              children: this.menuData1
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            nowEditMenu[2].pre = []

            let dom = {
              id: "1-3-0",
              content: "",
              messageId: "",
              parentMessageId: ""
            }
            nowEditMenu[2].pre.push(dom)

            queryList.set("150", {
              domMessage: dom,
              chatType: 150
            })

            this.ifObjectorTrademarkQueryNotComplate = true
            this.$axios.get("/aigc/app/objection/searchTrademarks/"+this.trademarkObjectionParam.caseNumber, {params:{chatNumber:this.chatNumber, cancelToken:this.stopSignal.token}})
              .then(response=>{
                this.objectorTrademarkList = response.data.data.records
                this.ifObjectorTrademarkQueryNotComplate = false
                this.nowShowMenuData[0].children[3].pre[0].content = "经查询，被异议人名下共有"+response.data.data.total+"枚商标，其中部分商标还抢注了其他知名商标，异议人将被异议人名下部分商标列表如下供审查员参考："
              })
          }
          if(lawOptionSet.has("2-1")){
            this.nowShowMenuData.push({
              id:"2",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议商标与异议人存在代理关系，被异议商标系被异议人在明知异议人及其引证商标的情况下，未经异议人授权申请注册的。根据《商标法》第十五条的规定，被异议商标理应不予核准",
              children: this.menuData2
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            nowEditMenu[1].pre = []
            nowEditMenu[2].pre = []
            nowEditMenu[1].pre.push({
              id: "2-2-0",
              content: "异议人主营的商业咨询等服务涉及的领域包括被异议商标指定的商品领域，商品和服务在所面向的群体等方面存在明显交叉，关联性极强，构成类似商品和服务。",
            })
            this.quoteTrademarkInfoList.forEach(child=> {
              let dom = {
                id: "2-2-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[1].pre.push(dom)
              queryList.set("100"+'-'+dom.applyNo, {
                domMessage: dom,
                chatType: 100
              })
            })
            this.quoteTrademarkInfoList.forEach(child=> {
              let dom1 = {
                id: "3-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[2].pre.push(dom1)
              queryList.set("110"+'-'+dom1.applyNo, {
                domMessage: dom1,
                chatType: 110
              })
            })
          }
          if(lawOptionSet.has("2-2")){
            this.nowShowMenuData.push({
              id:"3",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议人与异议人存在商业关系，被异议人在明知异议人及其商标存在的情况下申请注册了被异议商标，根据《商标法》第十五条的规定，被异议商标理应不予注册",
              children: this.menuData3
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            let quoteTrademarkNameListString = ""
            nowEditMenu[2].pre = []
            nowEditMenu[3].pre = []

            nowEditMenu[2].pre.push({
              id: "3-3-0",
              content:"异议人主营的商业咨询等服务涉及的领域包括被异议商标指定的商品领域，商品和服务在所面向的群体等方面存在明显交叉，关联性极强，构成类似商品和服务。"
            })
            this.quoteTrademarkInfoList.forEach(child=> {
              quoteTrademarkNameListString = quoteTrademarkNameListString + '"'+child.trademarkName+'",'

              let dom = {
                id: "3-3-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[2].pre.push(dom)
              queryList.set("120"+'-'+dom.applyNo, {
                domMessage: dom,
                chatType: 120
              })
            })

            nowEditMenu[0].label = nowEditMenu[0].label.replaceAll("${quoteTrademarkNameListString}", quoteTrademarkNameListString.slice(0, quoteTrademarkNameListString.length-1))
            nowEditMenu[1].label = nowEditMenu[1].label.replaceAll("${quoteTrademarkNameListString}", quoteTrademarkNameListString.slice(0, quoteTrademarkNameListString.length-1))
            nowEditMenu[3].post[0].content = nowEditMenu[3].post[0].content.replaceAll("${quoteTrademarkNameListString}", quoteTrademarkNameListString.slice(0, quoteTrademarkNameListString.length-1))

            nowEditMenu[1].children[0].label = nowEditMenu[1].children[0].label.replaceAll("${opposedName}", this.trademarkObjectionParam.opposedName == null ? "" : this.trademarkObjectionParam.opposedName)
            nowEditMenu[1].children[0].pre[0].content = nowEditMenu[1].children[0].pre[0].content.replaceAll("${opposedName}", this.trademarkObjectionParam.opposedName == null ? "" : this.trademarkObjectionParam.opposedName)

            this.quoteTrademarkInfoList.forEach(child=> {
              let dom1 = {
                id: "3-4-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[3].pre.push(dom1)
              queryList.set("130"+'-'+dom1.applyNo, {
                domMessage: dom1,
                chatType: 130
              })
            })
          }
          if(lawOptionSet.has("3-1")){
            this.nowShowMenuData.push({
              id:"4",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议商标与异议人的引证商标构成类似商品上的近似商标，被异议商标的注册申请违反了《商标法》第三十条的规定, 理应不予核准注册",
              children: this.menuData4
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            //根据印证商标补充目录
            nowEditMenu.forEach(item1=>{
              item1.children.forEach(item2=>{
                if(item2.children){
                  this.quoteTrademarkInfoList.forEach(child=>{
                    let dom1 = {
                      id:item2.id+"-"+child.rowIndex,
                      label:item2.childrenLabelPattern ? item2.childrenLabelPattern.replaceAll("${Number}", child.rowIndex) : null,
                      content:"",
                      messageId:"",
                      parentMessageId:"",
                      applyNo:child.applyNo
                    }
                    item2.children.push(dom1)

                    queryList.set(this.getChatTypeOfMenu0(dom1.id)+'-'+dom1.applyNo, {
                      domMessage:dom1,
                      chatType:this.getChatTypeOfMenu0(dom1.id)
                    })
                  })
                }

                if(item2.id == "4-3-1"){
                  item2.pre = []
                  this.quoteTrademarkInfoList.forEach(child=>{
                    let dom2 = {
                      id:item2.id+"-"+child.rowIndex,
                      content:"",
                      messageId:"",
                      parentMessageId:"",
                      applyNo:child.applyNo
                    }
                    item2.pre.push(dom2)

                    queryList.set(this.getChatTypeOfMenu0(dom2.id)+'-'+dom2.applyNo, {
                      domMessage:dom2,
                      chatType:this.getChatTypeOfMenu0(dom2.id)
                    })
                  })
                }
              })
            })
          }

          this.$axios.get('/aigc/app/objection/message',{params:{chatNumber:this.chatNumber}})
            .then(response=>{
              let chatList = response.data.data
              chatList.reverse()
              let chatMap = new Map()
              chatList.forEach(item=>{
                if (item.chatType == "150"){
                  chatMap.set("150", item)
                }
                else if(item.chatType != "0"){
                  chatMap.set(item.chatType+'-'+item.citedTrademark, item)
                }
                else {
                  if(item.role == "user"){
                    this.dialogList.push({
                      deleteId:item.messageId,
                      type:"user",
                      conversationId:item.messageId,
                      content:item.content
                    })
                    this.dialogList.push({
                      deleteId:"",
                      parentMessageId:item.messageId,
                      type:"assistant",
                      conversationId:"",
                      content:""
                    })
                  }
                  else {
                    this.dialogList.forEach(it=>{
                      if (item.parentMessageId == it.parentMessageId){
                        it.content = item.content
                        it.conversationId = item.conversationId
                        it.deleteId = item.messageId
                      }
                    })
                  }
                }
              })

              queryList.keys().forEach(item=>{
                if(chatMap.has(item)){
                  let dom = queryList.get(item).domMessage
                  let chat = chatMap.get(item)
                  dom.content = chat.content
                  dom.messageId = chat.messageId
                  dom.parentMessageId = chat.parentMessageId
                }
              })

              this.ifShowConfig = false
              store.commit("loadingBool", false)
            })
        })

    },
    historyDownload(item){
      this.handleDownloadGenerate(item.caseNumber, item.chatNumber, item.title)
    },
    handleTrademarkHistorySizeChange(val){
      this.trademarkHistoryQueryParam.pageSize = val
      this.queryHistory()
    },
    handleTrademarkHistoryCurrentChange(val){
      this.trademarkHistoryQueryParam.pageNum = val
      this.queryHistory()
    },
    //查询商标驳回历史数据
    queryHistory(){
      this.$axios.get("/aigc/app/objection", this.trademarkHistoryQueryParam)
        .then(response=>{
          this.trademarkHistoryTotal = response.data.data.total
          this.trademarkHistoryList = response.data.data.records
        })
    },
    getFormattedTime(time){
      let date = new Date(time)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let h =date.getHours()
      h = h < 10 ? ('0' + h) : h
      let M =date.getMinutes()
      M = M < 10 ? ('0' + M) : M
      let s =date.getSeconds()
      s = s < 10 ? ('0' + s) : s
      let dateTime= y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;

      return dateTime
    },
    getChinese(num){
      switch (num){
        case 1:
          return "一"
        case 2:
          return "二"
        case 3:
          return "三"
        case 4:
          return "四"
      }
    },
    handleObjectionStop(){
      this.stopSignal.cancel()
      this.stopSignal = axios.CancelToken.source()
      this.regenerateDisabled = false
    },
    handleObjectionRegenerate(){
      store.commit("loadingBool", true)
      //清理参数
      this.nowShowMenuData = []
      this.menuData1 = [
        {
          id:"1-1",
          label:"(一)被异议商标的注册申请并非出于生产经营活动的需要",
          pre:null,
          post:[
            {content:"经异议人对被异议人的调查，没有发现被异议人实际使用被异议商标的证据。可见，被异议人并不是出于意图使用的目的申请注册被异议商标。"},
            {content:"根据《商标法》第四条的规定，自然人、法人或者其他组织在生产经营活动中，对其商品或者服务需要取得商标专用权的，应当向商标局申请商标注册。不以使用为目的的恶意商标注册申请，应当予以驳回。这很明显的反映出申请商标注册应该是基于“生产经营活动”的需要，脱离了生产经营活动的商标申请，可以被认为是以谋取不正当利益为目的的商标抢注行为。因此，异议人有理由怀疑其注册被异议商标是出于恶意的意图，企图无偿占有他人劳动果实的行为。"}
          ]
        },
        {
          id:"1-2",
          label:"(二)被异议商标明显抄袭引证商标",
          pre:null,
          post: [
            {content:"被异议商标与引证商标构成近似商标。引证商标被异议人作为公司商号及品牌名称使用很久，在世界范围内的消费者中被广泛知晓。在这种情况下，如果说被异议商标是被异议人独创的品牌，非常难以让人信服。被异议人显然是抄袭了引证商标，企图浑水摸鱼，让消费者在购买其提供的商品时，误以为与异议人存在关联从而对其商品产生好感以达到获得不法利益的目的。"},
            {content:"企业的品牌应该凝结企业的经营理念与追求，只有这样品牌才会拥有充沛的生命力和竞争力。而被异议人显然忽略的这一点，而仅仅想通过抄袭他人知名品牌来经营自己生意，这种抄袭摹仿的行为明显违背了诚实信用原则，而抄袭摹仿的结果可能短期内会获得满意的收益，但是从长久来看，会失去消费者的信任，使企业难以成长并最终被淘汰。"}
          ]
        },
        {
          id:"1-3",
          label:"(三)被异议人明知异议人及引证商标的存在",
          pre:null,
          post:[
            {content:"在互联网如此发达的今天，及网络媒体传播速度快、影响范围广的独特特点下，可以认定被异议人在提交被异议商标注册申请前，完全知晓引证商标的存在。"},
            {content:"可见，被异议人在明知应知异议人及引证商标的情况下，还申请注册被异议商标，其主观恶意十分明显，其目的是利用异议人及引证商标极高的知名度和美誉度，来混淆相关消费者的视听，从而销售被异议商标的指定商品以获得暴利。"},
            {content:"根据《最高人民法院关于审理商标授权确权行政案件若干问题的意见》第一条，人民法院在审理商标授权确权行政案件时，对于尚未大量投入使用的诉争商标，在审查判断商标近似和商品类似等授权确权条件及处理与在先商业标志冲突上，可依法适当从严掌握商标授权确权的标准，充分考虑消费者和同业经营者的利益，有效遏制不正当注册行为，注重对于他人具有较高知名度和较强显著性的在先商标、企业名称等商业标志权益的保护，尽可能消除商业标志混淆的可能性。"},
            {content:"从最高院给出的意见可以明确，应当给予他人较高知名度和较强显著性的在先商标更强的保护力度，防止他人傍名牌、搭便车，违反诚实信用的商标注册行为，因为如果这种行为不及时制止，会损害已经建立并完善的市场格局，也会产生破窗效应，即更多的企业在利益的驱使下加入到这种通过投机钻营、傍名牌、搭便车的行为获取暴利的行列中，同时，也会严重打击其他遵纪守法、诚实信用经营者的积极性。而这种恶性循环会严重损害国家良好的经济秩序，破坏诚实信用的社会风气，造成资源的浪费，不利于社会主义经济建设。"},
            {content:"在先类似案例："},
            {content:"在《关于第12754993号“BVLGARI”商标无效宣告请求裁定书》中，商评委认定该案的被申请人申请注册的“BVLGARI”商标违反了《商标法》第四十四条第一款的规定，最终予以无效宣告。在该裁定中明确载明：“宝格丽”、“BVLGARI”确为申请人在“珠宝”等商品上具有较长使用历史、在市场上享有较高知名度的商标，普通消费者对此接触也较多，被申请人对该项事实亦应知晓。且鉴于“BVLGARI”并非现有固定搭配的词汇，因此，除非被申请人可以合理解释争议商标的渊源，否则争议商标与申请人商标构成巧合的可能性很小。在被申请人并未对争议商标的商标设计来源进行答辩并予以举证的情况下，我委合理认为，被申请人以申请人商标特有表现形式申请注册于别类商品，具有不正当利用申请人商标以盈利的目的。该类抢注行为不仅会导致相关公众对商品来源产生误认，更扰乱了正常的商标注册管理秩序，并有损于公平竞争的市场秩序，违反了诚实信用原则，不应鼓励和支持。因此，争议商标的申请注册构成了《商标法》第四十四条第一款规定的情形。"},
            {content:"本案与上述案例非常类似，引证商标是异议人的商号，具有很强的显著性，而“宝格丽/BVLGARI”品牌已在中国拥有很高的知名度，被异议商标所有人应当知晓异议人及其品牌。被异议人将异议人“宝格丽/BVLGARI”品牌极为近似的商标申请注册为商标，具有不正当利用异议人商标以盈利的目的。该类抢注行为不仅会导致相关公众对商品来源产生误认，更扰乱了正常的商标注册管理秩序，并有损于公平竞争的市场秩序，违反了诚实信用原则，不应鼓励和支持。"},
            {content:"由此可见，被异议人明显在明知应知异议人及引证商标的情况下，还将与引证商标高度近似的商标注册和使用在其指定商品上，其傍名牌的恶意十分明显，违反诚实信用原则。其目的是利用异议人及引证商标极高的知名度和美誉度，来混淆相关消费者的视听，从而销售被异议商标的指定商品/服务以获得暴利，因此属于“傍名牌”、“搭便车”的不正当行为，违反诚实信用原则，如予注册，会容易产生不良的社会影响，异议人恳请贵局依据《商标法》第七条、第十条第一款第八项以及第四十四条第一款规定，不予核准被异议商标的注册。"}
          ]
        },
        {
          id:"1-4",
          label:"(四)被异议人还抢注其他知名商标，进一步证实了被异议人的恶意",
          pre:[
            {
              id:"4-0",
              content:"经查询，被异议人名下共有 枚商标，其中部分商标还抢注了其他知名商标，异议人将被异议人名下部分商标列表如下供审查员参考："
            },
          ],
          post:[
            {content:"可见，被异议人在抢注商标时一般做法即是在品牌前面增加几个汉字或字母，并将品牌中部分汉字更换为读音相同的其他汉字或并将品牌中部分字母更换为相似的其他字母。既能让消费者联想到被抢注的品牌，利用被抢注品牌的知名度，又能通过国家知识产权局的初步审查。被异议人抢注其他知名商标的行为进一步证实了被异议人的恶意。"},
            {content:"综上，被异议商标的注册申请并非出于生产经营的需要，违反诚实信用原则，会损害异议人、社会公众的利益，浪费国家的行政审查资源，也会扰乱正常的经济秩序。因此，根据《商标法》第四条、第七条、第十条第一款八项和第四十四条的规定，被异议商标应不予核准注册。"}
          ]
        }
      ]
      this.menuData2 = [
        {
          id: "2-1",
          label: "(一)被异议人是异议人的代理人或者代表人",
          pre:null,
          post: [
            {content:"在被异议商标申请日之前，被异议人就已经是异议人集团公司在中国的特许经营人之一。双方长期存在特许经营关系，属于代理关系范畴。"},
            {content:"如下XXX中关于异议人集团相关的介绍中提到了被异议人加入异议人集团的关系。XXX中相关信息通常是由当事人发布，即本案的被异议人主动发布了说明双方关系的介绍。"},
            {content:"异议人将在补充阶段补充提交其他证明双方关系的相关证据。"}
          ]
        },
        {
          id:"2-2",
          label:"(二)被异议商标指定的商品/服务与异议人商标使用的商品/服务相同或类似",
          pre:null,
          post:[
            {content:"《商标法》第十五条中，对被代理人商标的保护范围不限于与该商标所使用的商品相同的商品，也及于类似的商品；同时，代理人不得申请注册的商标，不限于与被代理人商标相同的商标，也包括与被代理人商标近似的商标。"}
          ]
        },
        {
          id:"2-3",
          label:"(三)被异议商标与异议人的商标相同或者近似",
          pre:null,
          post:null,
        },
        {
          id:"2-4",
          label:"(四)被异议人未经异议人授权，以其自己名义将异议人的商标进行注册，违反了《商标法》第十五条的规定，应不予核准注册",
          pre:null,
          post: [
            {content:"根据《商标审查及审理标准》的规定，代理人不仅包括《中华人民共和国民法通则》、《中华人民共和国合同法》中规定的代理人，也包括基于商事业务往来而可以知悉被代理人商标的经销商。"},
            {content:"同时，根据最高人民法院关于审理商标授权确权行政案件若干问题的意见第十二条：商标代理人、代表人或者经销、代理等销售代理关系意义上的代理人、代表人未经授权，以自己的名义将被代理人或者被代表人商标进行注册的，人民法院应当认定属于代理人、代表人抢注被代理人、被代表人商标的行为。"},
            {content:"被异议人仅为异议人引证商标在中国的被许可使用人之一，被异议人在中国可以特许经营引证商标品牌从事相关业务。但异议人从未授权被异议人以其名义在中国申请注册被异议商标。"},
            {content:"被异议人在未经异议人的授权，申请了与异议人商标相同的被异议商标，其恶意非常明显，属于《商标法》第十五条规定的不予注册的情形。"}
          ]
        }
      ]
      this.menuData3 = [
        {
          id: "3-1",
          label: "(一)异议人的${quoteTrademarkNameListString}商标在被异议商标申请之前已经在先使用",
          pre:null,
          content:"",
          placeholder:"此处请填写具体的在先使用证据。\n示例：2007年12月1日，异议人与XXX有限公司签订了代理经销合同，由其代为销售异议人产品，异议人产品正式进入中国。异议人还与XX有限公司合作，促进异议人商品在中国的宣传和发展、并通过其进一步许可授权他人使用“蓝灯鱼”、“ Lanternfish”等商标及进行品牌管理。2007年-2019年，异议人的产品销往天津、上海、香港、青岛、浙江义乌、北京等地。因此，在被异议商标申请之前，异议人的“蓝灯鱼”、“ Lanternfish”商标已经在相关商品上在先使用。",
          post: null
        },
        {
          id:"3-2",
          label:"(二)被异议人明知异议人${quoteTrademarkNameListString}商标的存在",
          pre:null,
          post:null,
          children:[
            {
              id:"3-2-1",
              pre:[
                {content:"异议人曾与${opposedName}联系过如下事宜："}
              ],
              label:"1.${opposedName}曾联系异议人",
              content:"",
              placeholder:"此处请填写被异议人与异议人的具体联系情况及后续的合同来往情况",
              post:null
            }
          ]
        },
        {
          id:"3-3",
          label:"(三)被异议商标指定的商品或服务与异议人在先使用商标的商品或服务相同或类似",
          pre:null,
          post:[
            {content:"《商标法》第十五条中，对被代理人商标的保护范围不限于与该商标所使用的商品相同的商品，也及于类似的商品；同时，代理人不得申请注册的商标，不限于与被代理人商标相同的商标，也包括与被代理人商标近似的商标。"}
          ]
        },
        {
          id:"3-4",
          label:"(四)被异议商标与异议人的商标相同或者近似",
          pre:null,
          post:[
            {content:"综上所述，被异议人在明知异议人${quoteTrademarkNameListString}商标的情况下，未经异议人的授权，申请了与其高度近似的被异议商标，其恶意非常明显，被异议商标已构成《商标法》第十五条第二款所指的情形。因此，被异议商标违反《商标法》第十五条第二款的规定，应不予核准注册。"}
          ]
        }
      ]
      this.menuData4 = [
        {
          id:"4-1",
          label:"(一)被异议商标与引证商标构成近似商标",
          pre:null,
          post:null,
          children:[
            {
              id:"4-1-1",
              label:"1.被异议商标与引证商标整体外观构成相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}整体外观构成相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-1-2",
              label:"2.被异议商标与引证商标的显著部分近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}显著部分构成相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-1-3",
              label:"3.被异议商标与引证商标呼叫构成相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}呼叫构成相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-1-4",
              label:"4.被异议商标与引证商标含义相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}含义相同/近似",
              pre:null,
              children:[]
            },
          ]
        },
        {
          id:"4-2",
          label:"(二)被异议商标与引证商标的指定商品构成类似",
          pre:null,
          children:[
            {
              id:"4-2-1",
              label:"1.被异议商标与引证商标指定商品、服务功能、用途相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务功能、用途相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-2-2",
              label:"2.被异议商标与引证商标指定商品/服务生产部门相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务生产部门相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-2-3",
              label:"3.被异议商标与引证商标指定商品/服务销售渠道相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务销售渠道相同/近似",
              pre:null,
              children:[]
            },
            {
              id:"4-2-4",
              label:"4.被异议商标与引证商标指定商品/服务消费对象相同/近似",
              childrenLabelPattern:"（${Number}）被异议商标与引证商标${Number}指定商品/服务消费对象相同/近似",
              pre:null,
              children:[]
            },
          ]
        },
        {
          id:"4-3",
          label:"(三)引证商标具有较高的显著性和知名度，被异议商标容易被认为是引证商标的系列商标",
          pre:null,
          post:null,
          children:[
            {
              id:"4-3-1",
              pre:null,
              label:"1.引证商标具有较高的显著性",
              post:[
                {content:"根据《最高人民法院关于审理商标授权确权行政案件若干问题的意见》，认定商标是否近似，要考虑相关商标的显著性。引证商标均具有很强的固有显著性，在判断被异议商标与引证商标近似性和混淆可能性时应当充分考虑这一事实。"}
              ]
            },
            {
              id:"4-3-2",
              pre:null,
              label:"2.引证商标具有较高的知名度",
              post:[
                {content:"根据《最高人民法院关于审理商标授权确权行政案件若干问题的意见》，认定商标是否近似，要考虑请求被保护商标的知名度。"},
                {content:"异议人的引证商标在包括中国在内的世界范围内长期、持续、广泛的使用和宣传，已经在被异议商标申请日之前在中国具有了极高的知名度和影响力，故，被异议商标与引证商标在市场上的共存，更易引起相关公众对商品来源的混淆和误认。"}
              ]
            },
          ]
        },
      ]
      this.trademarkInfo = null
      this.quoteTrademarkInfoList = null
      this.ifObjectorTrademarkQueryNotComplate = false
      this.objectorTrademarkList = null
      this.buttonDisabled = true
      this.regenerateDisabled = true

      //获取法条
      let lawOptionSet = new Set()
      this.trademarkObjectionLawSelect.forEach(item=>{
        lawOptionSet.add(item[1])
      })

      this.handleGenerate(lawOptionSet)
    },
    generate(){
      if(this.trademarkObjectionParam.opposedTrademark == null){
        this.$message("请填写被异议商标申请号")
        return
      }
      if(this.trademarkObjectionParam.opposedNiceCode == null){
        this.$message("请填写被异议商标尼斯分类")
        return
      }
      if(this.trademarkObjectionParam.opposedTrialnum == null){
        this.$message("请填写被异议商标初审公告期号")
        return
      }
      if(this.trademarkObjectionParam.opposedName == null){
        this.$message("请填异议人名称")
        return
      }
      if(this.trademarkObjectionParam.opposedAgentName == null){
        this.$message("请填写被异议委托人名称")
        return
      }
      if(this.trademarkObjectionParam.opposedExplain == null){
        this.$message("请填写被异议商标阐释")
        return
      }
      if(this.trademarkObjectionParam.citedTrademark == null){
        this.$message("请填写引证商标申请号")
        return
      }
      if(this.trademarkObjectionLawSelect.length <1){
        this.$message("请选择异议法条")
        return
      }

      let param = this.trademarkObjectionParam
      param.caseNumber = this.generateRandomString(10)
      param.objectionConfig = []
      this.trademarkObjectionLawSelectString = []

      let lawOptionSet = new Set()
      this.trademarkObjectionLawSelect.forEach(item=>{
        lawOptionSet.add(item[1])
      })
      //获取法条
      this.generateType = 0
      if(lawOptionSet.has("1-1")){
        param.objectionConfig.push({opposedType:"1"})
        this.trademarkObjectionLawSelectString.push("<b>第四条</b>，自然人、法人或者其他组织在生产经营活动中，对其商品或者服务需要取得商标专用权的，应当向商标局申请商标注册。不以使用为目的的恶意商标申请，应当予以驳回。")
        this.trademarkObjectionLawSelectString.push("<b>第七条</b>，申请注册和使用商标，应当遵循诚实信用原则。")
        this.trademarkObjectionLawSelectString.push("<b>第四十四条</b>，已经注册的商标，违反本法第十条、第十一条、第十二条规定的，或者是以欺骗手段或者其他不正当手段取得注册的，由商标局宣告该注册商标无效；其他单位或者个人可以请求商标评审委会宣告该注册商标无效。")
        this.generateType = 1
      }
      if(lawOptionSet.has("2-1")){
        param.objectionConfig.push({opposedType:"2", lawTerm:"1"})
        this.trademarkObjectionLawSelectString.push("<b>第十五条第一款</b>，未经授权,代理人或者代表人以自己的名义将被代理人或者被代表人的商标进行注册,被代理人或者被代表人提出异议的,不予注册并禁止使用。")
      }
      if(lawOptionSet.has("2-2")){
        param.objectionConfig.push({opposedType:"2", lawTerm:"2"})
        this.trademarkObjectionLawSelectString.push("<b>第十五条第二款</b>，就同一种商品或者类似商品申请注册的商标与他人在先使用的未注册商标相同或者近似,申请人与该他人具有前款规定以外的合同、业务往来关系或者其他关系而明知该他人商标存在, 该他人提出异议的, 不予注册。")
      }
      if(lawOptionSet.has("3-1")){
        param.objectionConfig.push({opposedType:"3"})
        this.trademarkObjectionLawSelectString.push("<b>第三十条</b>，申请注册的商标，凡不符合本法有关规定或者同他人在同一种商品或者类似商品上已经注册的或者初步审定的商标相同或者近似的，由商标局驳回申请，不予公告。")
        this.trademarkObjectionLawSelectString.push("<b>第三十一条</b>，两个或者两个以上的商标注册申请人，在同一种商品或者类似商品上，以相同或者近似的商标申请注册的，初步审定并公告申请在先的商标；同一天申请的，初步审定并公告使用在先的商标，驳回其他人的申请，不予公告。")
      }
      this.trademarkObjectionLawSelectString.push("<b>《最高人民法院关于审理商标授权确权行政案件若干问题的规定》第二十四条规定</b>：以欺骗手段以外的其他方式扰乱商标注册秩序、损害公共利益、不正当占用公共资源或者谋取不正当利益的，人民法院可以认定其属于商标法第四十四条第一款规定的“其他不正当手段”。")

      this.buttonDisabled = true
      this.regenerateDisabled = true

      this.$axios.post("/aigc/app/objection/create", param)
        .then(response=>{
          store.commit("loadingBool", true)
          this.chatNumber = response.data.data.chatNumber
          this.handleGenerate(lawOptionSet)
        })
    },
    handleGenerate(lawOptionSet){
      this.$axios.get("/aigc/app/objection/detail/"+this.chatNumber)
        .then(response=>{
          this.trademarkInfo = response.data.data.trademarkInfo
          this.quoteTrademarkInfoList = this.trademarkInfo.quoteTrademarkInfoList

          let queryList = []
          if(lawOptionSet.has("1-1")){
            this.nowShowMenuData.push({
              id:"1",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议商标的注册申请并非出于生产经营活动的需要，被异议人系以不正当手段申请注册商标，其注册和使用违反诚实信用原则，会产生不良影响",
              children: this.menuData1
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            nowEditMenu[2].pre = []

            let dom = {
              id: "1-3-0",
              content: "",
              messageId: "",
              parentMessageId: ""
            }
            nowEditMenu[2].pre.push(dom)

            queryList.push({
              domMessage: dom,
              chatType: 150
            })

            this.ifObjectorTrademarkQueryNotComplate = true
            this.$axios.get("/aigc/app/objection/searchTrademarks/"+this.trademarkObjectionParam.caseNumber, {params:{chatNumber:this.chatNumber, cancelToken:this.stopSignal.token}})
              .then(response=>{
                this.objectorTrademarkList = response.data.data.records
                this.ifObjectorTrademarkQueryNotComplate = false
                this.nowShowMenuData[0].children[3].pre[0].content = "经查询，被异议人名下共有"+response.data.data.total+"枚商标，其中部分商标还抢注了其他知名商标，异议人将被异议人名下部分商标列表如下供审查员参考："
              })
          }
          if(lawOptionSet.has("2-1")){
            this.nowShowMenuData.push({
              id:"2",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议商标与异议人存在代理关系，被异议商标系被异议人在明知异议人及其引证商标的情况下，未经异议人授权申请注册的。根据《商标法》第十五条的规定，被异议商标理应不予核准",
              children: this.menuData2
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            nowEditMenu[1].pre = []
            nowEditMenu[2].pre = []
            nowEditMenu[1].pre.push({
              id: "2-2-0",
              content: "异议人主营的商业咨询等服务涉及的领域包括被异议商标指定的商品领域，商品和服务在所面向的群体等方面存在明显交叉，关联性极强，构成类似商品和服务。",
            })
            this.quoteTrademarkInfoList.forEach(child=> {
              let dom = {
                id: "2-2-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[1].pre.push(dom)
              queryList.push({
                domMessage: dom,
                chatType: 100
              })
            })
            this.quoteTrademarkInfoList.forEach(child=> {
              let dom1 = {
                id: "3-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[2].pre.push(dom1)
              queryList.push({
                domMessage: dom1,
                chatType: 110
              })
            })
          }
          if(lawOptionSet.has("2-2")){
            this.nowShowMenuData.push({
              id:"3",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议人与异议人存在商业关系，被异议人在明知异议人及其商标存在的情况下申请注册了被异议商标，根据《商标法》第十五条的规定，被异议商标理应不予注册",
              children: this.menuData3
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            let quoteTrademarkNameListString = ""
            nowEditMenu[2].pre = []
            nowEditMenu[3].pre = []

            nowEditMenu[2].pre.push({
              id: "3-3-0",
              content:"异议人主营的商业咨询等服务涉及的领域包括被异议商标指定的商品领域，商品和服务在所面向的群体等方面存在明显交叉，关联性极强，构成类似商品和服务。"
            })
            this.quoteTrademarkInfoList.forEach(child=> {
              quoteTrademarkNameListString = quoteTrademarkNameListString + '"'+child.trademarkName+'",'

              let dom = {
                id: "3-3-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[2].pre.push(dom)
              queryList.push({
                domMessage: dom,
                chatType: 120
              })
            })

            nowEditMenu[0].label = nowEditMenu[0].label.replaceAll("${quoteTrademarkNameListString}", quoteTrademarkNameListString.slice(0, quoteTrademarkNameListString.length-1))
            nowEditMenu[1].label = nowEditMenu[1].label.replaceAll("${quoteTrademarkNameListString}", quoteTrademarkNameListString.slice(0, quoteTrademarkNameListString.length-1))
            nowEditMenu[3].post[0].content = nowEditMenu[3].post[0].content.replaceAll("${quoteTrademarkNameListString}", quoteTrademarkNameListString.slice(0, quoteTrademarkNameListString.length-1))

            nowEditMenu[1].children[0].label = nowEditMenu[1].children[0].label.replaceAll("${opposedName}", this.trademarkObjectionParam.opposedName == null ? "" : this.trademarkObjectionParam.opposedName)
            nowEditMenu[1].children[0].pre[0].content = nowEditMenu[1].children[0].pre[0].content.replaceAll("${opposedName}", this.trademarkObjectionParam.opposedName == null ? "" : this.trademarkObjectionParam.opposedName)

            this.quoteTrademarkInfoList.forEach(child=> {
              let dom1 = {
                id: "3-4-" + child.rowIndex,
                content: "",
                messageId: "",
                parentMessageId: "",
                applyNo: child.applyNo
              }
              nowEditMenu[3].pre.push(dom1)
              queryList.push({
                domMessage: dom1,
                chatType: 130
              })
            })
          }
          if(lawOptionSet.has("3-1")){
            this.nowShowMenuData.push({
              id:"4",
              label:this.getChinese(this.nowShowMenuData.length+1)+"、被异议商标与异议人的引证商标构成类似商品上的近似商标，被异议商标的注册申请违反了《商标法》第三十条的规定, 理应不予核准注册",
              children: this.menuData4
            })

            let nowEditMenu = this.nowShowMenuData[this.nowShowMenuData.length-1].children
            //根据印证商标补充目录
            nowEditMenu.forEach(item1=>{
              item1.children.forEach(item2=>{
                if(item2.children){
                  this.quoteTrademarkInfoList.forEach(child=>{
                    let dom1 = {
                      id:item2.id+"-"+child.rowIndex,
                      label:item2.childrenLabelPattern ? item2.childrenLabelPattern.replaceAll("${Number}", child.rowIndex) : null,
                      content:"",
                      messageId:"",
                      parentMessageId:"",
                      applyNo:child.applyNo
                    }
                    item2.children.push(dom1)

                    queryList.push({
                      domMessage:dom1,
                      chatType:this.getChatTypeOfMenu0(dom1.id)
                    })
                  })
                }

                if(item2.id == "4-3-1"){
                  item2.pre = []
                  this.quoteTrademarkInfoList.forEach(child=>{
                    let dom2 = {
                      id:item2.id+"-"+child.rowIndex,
                      content:"",
                      messageId:"",
                      parentMessageId:"",
                      applyNo:child.applyNo
                    }
                    item2.pre.push(dom2)

                    queryList.push({
                      domMessage:dom2,
                      chatType:this.getChatTypeOfMenu0(dom2.id)
                    })
                  })
                }
              })
            })
          }

          this.ifShowConfig = false

          this.startChat(queryList)
        })
    },
    //查询menu0的chatType
    getChatTypeOfMenu0(id){
      let idList = id.split("-")
      let fatherId = idList[0]+"-"+idList[1]+"-"+idList[2]
      switch (fatherId){
        case "4-1-1":
          return 10
        case "4-1-2":
          return 20
        case "4-1-3":
          return 30
        case "4-1-4":
          return 40
        case "4-2-1":
          return 50
        case "4-2-2":
          return 60
        case "4-2-3":
          return 70
        case "4-2-4":
          return 80
        case "4-3-1":
          return 90
      }
    },
    //开始进行对话
    startChat(queryList){
      if(queryList.length > 0){
        let myItem = queryList[0]
        let param = {
          caseNumber:this.trademarkObjectionParam.caseNumber,
          chatNumber:this.chatNumber,
          chatType:myItem.chatType,
          citedTrademark:myItem.domMessage.applyNo
        }
        this.$axios.post("/aigc/app/objection/message", param)
          .then(response=>{
            this.$axios.get(
              "/aigc/app/objection/completions",
              {
                cancelToken:this.stopSignal.token,
                params:{ conversationId: response.data.data },
                onDownloadProgress:(data)=>{
                  store.commit("loadingBool", false)

                  let response = data.target.response
                  let itemList = ""
                  let item = ""
                  if (!response.endsWith("}")) {
                    itemList = response.split("{")
                    item = "{" + itemList[itemList.length - 2]
                  }
                  else {
                    itemList = response.split("{")
                    item = "{" + itemList[itemList.length - 1]
                  }

                  let myJson = JSON.parse(item)
                  myItem.domMessage.messageId = myJson.id
                  myItem.domMessage.parentMessageId = myJson.parentMessageId
                  myItem.domMessage.content = myJson.content.replaceAll("*", "").replaceAll("#", "").replaceAll(/\n+/g, '\n\n').trim()
                }
              }
            )
              .then(()=>{
                this.handleEditSave(myItem.domMessage)
                let queryList2 = queryList.slice(1, queryList.length)
                this.startChat(queryList2)
              })
          })
      }
      else {
        this.buttonDisabled = false
        this.regenerateDisabled = false
      }
    },
    handleDomScrollToTop(data){
      const contentDom = document.getElementById("myAiGenerateContent")
      const fatherDom = document.getElementById(data.id).parentElement
      contentDom.scrollTop = fatherDom.offsetTop - 20
    },
    handleDomScrollToTop2(data){
      const contentDom = document.getElementById("myAiGenerateContent")
      const dom = document.getElementById(data)
      contentDom.scrollTop = dom.offsetTop - 20
    },
    handleEditSave(item){
      let myParam = {
        parentMessageId:item.parentMessageId,
        messageId:item.messageId,
        content:item.content,
      }
      this.$axios.put("/aigc/app/objection/message", myParam)
    },
    handleEditSave2(item){
      let myParam = {
        caseNumber:this.trademarkObjectionParam.caseNumber,
        chatNumber:this.chatNumber
      }
      switch (item.id){
        case "3-1":
          myParam.useCertificate = item.content
          break
        case "3-2-1":
          myParam.contractinformation = item.content
          break
      }

      this.$axios.post("/aigc/app/objection/templateContent", myParam)
    },

    handleDownloadGenerate(caseNumber, chatNumber, title){
      store.commit("loadingBool", true)
      this.$axios.get("/aigc/app/objection/download/"+caseNumber,{ responseType:'blob', params:{ chatNumber:chatNumber} })
        .then(response=>{
          const content = response.data
          const blob = new Blob([content])
          let fileName = title
          if(fileName == null){
            fileName = this.trademarkInfo.trademarkName+"_"+this.trademarkInfo.applyNo + '_异议理由书.docx';
          }
          else {
            fileName += ".docx"
          }

          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
            store.commit("loadingBool", false)
          }
        })
    },

    //放缩左右窗口
    handleRetractButton(type){
      switch (type){
        case 'right1':
          this.ifShowRightBlock = false
          break;
        case 'right2':
          this.ifShowRightBlock = true
          break;
        default:
          break
      }

      if (this.ifShowRightBlock){
        this.middleBlockSpan = 16
        this.rightBlockSpan = 8
      }
      else{
        this.middleBlockSpan = 24
        this.rightBlockSpan = 0
      }
    },

    //发送对话信息
    handleSendMessage(){
      let param = {
        caseNumber: this.trademarkObjectionParam.caseNumber,
        chatNumber: this.chatNumber,
        chatType: 0,
        prompt: this.myAIDialogInput,
      }
      this.$axios.post("/aigc/app/objection/message", param)
        .then(response=>{
          let myConversationId = response.data.data

          this.dialogList.push({
            deleteId:myConversationId,
            type:'user',
            conversationId:myConversationId,
            content:this.myAIDialogInput
          })
          this.dialogList.push({
            deleteId:myConversationId,
            type:'assistant',
            conversationId:myConversationId,
            content:""
          })

          this.myAIDialogInput = ""
          this.handleScrollToButtom('AIDialogueContent')
          this.handleGetResponseMessage(myConversationId)
        })
    },
    //重新生成对话
    handleRegenerate(item){
      this.dialogList.forEach(myItem=>{
        if (myItem.conversationId == item.conversationId && myItem.type == 'assistant'){
          myItem.content = ""
        }
      })
      this.handleGetResponseMessage(item.conversationId)
    },
    handleStopMessage(){
      this.stopSignal2.cancel()
      this.stopSignal2 = axios.CancelToken.source()
      this.chatLoading = false
    },
    //获取对话内容
    handleGetResponseMessage(conversationId){
      this.chatLoading = true
      this.$axios.get(
        "/aigc/app/objection/completions",
        {
          cancelToken:this.stopSignal2.token,
          timeout: 5*60*1000,
          params:{ conversationId: conversationId },
          onDownloadProgress:(data)=>{
            let response = data.target.response
            let itemList = ""
            let item = ""
            if (!response.endsWith("}")) {
              itemList = response.split("{")
              item = "{" + itemList[itemList.length - 2]
            }
            else {
              itemList = response.split("{")
              item = "{" + itemList[itemList.length - 1]
            }

            this.dialogList.forEach(myItem=>{
              if (myItem.conversationId == conversationId && myItem.type == 'assistant'){
                let text = JSON.parse(item).content.replaceAll(/\n+/g, '\n\n').replaceAll('\n\n', '<br/>')
                text = text.replaceAll("**", "%%%")

                let pattern = /%%%.*?%%%/g
                let arr = text.match(pattern)

                if(arr){
                  arr.forEach(item=>{
                    let targetText = "<b>"+item.replaceAll("%%%", "")+"</b>"
                    text = text.replace(item, targetText)
                  })
                }

                myItem.content = text
                myItem.deleteId = JSON.parse(item).id
              }
            })

            this.handleScrollToButtom('AIDialogueContent')
          }
        }
      )
        .then(()=>{
          this.chatLoading = false
        })
    },
    //滑至dom底部
    handleScrollToButtom(domId){
      this.$nextTick(()=>{
        let dom = document.getElementById(domId)
        dom.scrollTop = dom.scrollHeight - dom.clientHeight
      })
    },
    handleDelete(item, index){
      this.dialogList.splice(index,1)
      this.$axios.delete("/aigc/app/objection/message/"+item.deleteId)
    },
    //复制对话内容
    handleCopy(item){
      let stringContent = item.content;
      //获取要赋值的input的元素
      var inputElement =  document.getElementById("copy_content");
      //给input框赋值
      inputElement.value = stringContent
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //提示已复制
      this.$message('已复制');
    },
  },
}
</script>

<style lang="scss" scoped>
.trademarkObjection {

  #copy_content{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }

  .danTitle {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #999999;
    font-weight: 600;
    position: relative;
    margin-left: 30px;

    &:before {
      position: absolute;
      content: '';
      width: 3px;
      height: 16px;
      top: 8px;
      background: #1a3dbf;
    }

    span {
      margin: 0 9px;

      &:nth-of-type(1) {
        margin-left: 18px;
      }
    }

    span:hover {
      cursor: pointer;
      color: #506bce;
    }
  }

  .trademarkObjectionAi{
    margin-top: 95px;

    .trademarkObjection{
      margin: 10px 20px;
      color: #000000;
      height: 83vh;
      border: 1px solid rgba(7, 13, 67, .18);
      border-radius: 15px;
      background-color: #ffffff;
      box-shadow: 0 0 4px 0 rgba(7,13,67,0.18);

      .trademarkConfigTitle{
        margin: 10px 0 0 40px;
        .switchTab {
          margin-top: 12px;
          display: flex;
          padding-left: 11px;
          box-sizing: border-box;
          span {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 35px;
            font-size: 14px;
            background: #E0E4EB;
            color: #666666;
            border-radius: 10px 10px 0 0;
            &:nth-of-type(1){
              margin-right: 4px;
            }
            &.active {
              color: #1B427A;
              background: #B0C2F2;
            }
          }
        }
      }

      .trademarkConfig{
        margin-right: 40px;

        .trademarkConfigContent{
          padding: 10px;
          background: #EDF2FD;
          height: 73vh;
          overflow: hidden;
          border-radius: 10px;

          .trademarkConfigContentBox{
            padding: 0px 0 0 20px;
            .trademarkConfigItem{
              display: inline-block;
              width: 40%;
              margin: 10px;

              p{
                font-size: 14px;
                display: inline-block;
                width: 135px;
              }
              .el-input{
                width: 250px;
              }
              .el-cascader{
                width: 400px;
              }
            }
            .item1{
              width: 100%;
              margin-bottom: 10px;
              p{
                margin: 10px 0;
                display: block;
              }
              .el-cascader{
                width: 450px;
              }
            }

            .trademarkButtonItem{
              margin: 20px 0 0 70px;
              width: 220px;
              height: 50px;
              color: #FFFFFF;
              font-size: 13px;
              letter-spacing: .3px;
              background: linear-gradient( 252deg, #2E72D0 0%, #1B427A 100%);
              border-radius: 8px 8px 8px 8px;
            }
          }
        }
      }

      .trademarkHistory{
        background: #EDF2FD;
        height: 75vh;
        border-radius: 10px;

        h2{
          vertical-align: middle;
          margin: 20px 20px 10px;
          padding-bottom: 15px;
          color: #555555;
          font-size: 14px;
          font-weight: bolder;
          border-bottom: 1px solid #CFD5EB;

          svg{
            margin-left: 20px;
            vertical-align: middle;
          }
          span{
            margin-left: 10px;
          }
        }

        .trademarkHistoryList{
          height: 60vh;
          overflow-y: scroll;
          scrollbar-width: none;

          .trademarkHistoryItem{
            margin: 4px 20px;
            padding: 5px 50px 5px 20px;
            position: relative;

            h3{
              font-weight: normal;
              font-size: 16px;
              margin: 5px 0;
            }
            p{
              font-size: 12px;
            }
            .rightHistoryDownloadIcon{
              position: absolute;
              width:22px;
              height:20px;
              top: calc( 50% - 10px );
              right: 0px;
              cursor: pointer;
              color: #3567E6;
              background-color: rgb(236, 240, 251);
            }
            .rightHistoryBackReviewIcon{
              position: absolute;
              width:22px;
              height:20px;
              top: calc( 50% - 10px );
              right: 25px;
              cursor: pointer;
              color: #3567E6;
              background-color: rgb(236, 240, 251);
            }
          }
        }

        .nonHostory{
          margin: 0 20px;
          text-align: center;
          line-height: 400px;
        }
      }
    }

    .el-pagination__jump{
      margin-left: 0;
    }
  }

  .trademarkObjectionResult{
    margin: 90px auto 0;
    background-image:-webkit-linear-gradient( 42deg, #E1E7F8 0%, #D4DFFC 54%, #97BAE7 100%);
    height: calc( 100vh - 90px);

    .danTitle{
      margin-left: 0;
    }

    .mainContentMenuBlock{
      padding-right: 50px;
      background-color: #D5E0FC;

      .mainContentMenuBlockBox{
        height: calc( 100vh - 130px);
        border-right: 2px solid #ffffff;
        background-color: rgba(255, 255, 255, .4);
        padding: 20px 20px 20px 40px;

        h1{
          font-family: Roboto, Roboto;
          font-weight: bold;
          font-size: 24px;
          color: #172A6B;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          text-transform: none;

          letter-spacing: 2px;
          padding-bottom: 10px;
          border-bottom: #B4C3E8 1px solid ;
          margin-bottom: 10px;
        }

        .mainContentMenuTopTitle{
          padding: 0 5px;
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          line-height: 26px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          letter-spacing: .5px;
        }
        .mainContentMenuTopTitle:hover{
          cursor: pointer;
          background-color: rgba(190,204,241,0.8);

        }

        .mainContentMenuBlockContentBox{
          height: 95%;
          overflow-y: scroll;
          scrollbar-width: none;
        }
      }
    }
    .trademarkObjectionGenerateMainBlock{
      vertical-align: top;
      display: inline-block;
      height: calc( 100vh - 90px);

      .mainContentMenuBlock{
        .mainContentMenuTopTitle{
          width: 100%;
          padding: 3px;
          color: rgb(96, 98, 102);
          font-weight: bolder;
          cursor: pointer;
        }
        .mainContentMenuTopTitle:hover{
          background-color: #ffffff;
        }

        .mainContentMenuBlockOutBox{
          position: relative;
          padding: 0 10px 0 0;
          text-align: left;
          height: calc( 80vh + 5px );
          overflow-y: scroll;
          scrollbar-width:none;
        }

        .mainContentBlockTitle{
          position: absolute;
          top: -26px;
          left: 0px;

          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 18px;
          color: #172A6B;
          line-height: 21px;
        }
      }

      .mainContentBlock{
        position: relative;
        height: calc( 100vh - 150px);
        background: rgba(255,255,255,0.4);
        border-radius: 15px 15px 15px 15px;
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);

        .mainContentBlockTitle{
          position: absolute;
          top: -30px;
          left: 30px;

          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 18px;
          color: #172A6B;
          line-height: 21px;
        }

        .AIGenerateMenuContent{
          height: 75vh;
          padding: 20px;
          text-align: left;

          .myAiGenerateMenu{
            overflow-y: scroll;
            scrollbar-width:none;
            height: 100%;
          }
        }

        .AIGenerateContent{
          height: calc( 75vh - 40px );
          padding: 20px;
          text-align: left;

          .myAiGenerateContent{
            overflow-y: scroll;
            scrollbar-width:none;
            height: 100%;
            padding-right: 1px;

            .resultContent{
              h1{
                padding: 5px;
                font-size: 19px;
              }
              h2{
                padding: 5px;
                font-size: 18px;
              }
              h3{
                padding: 5px;
                font-size: 17px;
              }
              p{
                padding: 5px;
                font-size: 16px;
                text-indent: 20px;
              }

              .resultContentTable{
                margin: 0 auto;
                text-align: center;
                border-collapse: collapse;
                img{
                  height: 70px;
                  width: 70px;
                }
                th{
                  padding: 5px;
                  border: #bbbbbb 1px solid;
                }
                td{
                  padding: 5px;
                  border: #bbbbbb 1px solid;
                }
              }
              .table0{
                td:nth-of-type(3){
                  span{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                td:nth-of-type(8){
                  max-width: 200px;
                  span{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }

              background-size: cover;
              background-repeat: no-repeat;
              background-position: right bottom;
              margin-top: 10px;
            }
          }
        }
        .AIGenerateBottomButton{
          text-align: center;
          padding-top: 20px;
          height: calc( 8vh - 18px );
          background: rgba(255,255,255,0.4);
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;

          .AIGenerateButton{
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            display: inline-block;
            padding: 10px 60px;
            background-image:-webkit-linear-gradient( 252deg, #2E72D0 0%, #1B427A 100%);
            border-radius: 8px 8px 8px 8px;
          }
          .AIGenerateButton2{
            font-weight: 500;
            font-size: 14px;
            display: inline-block;
            padding: 10px;
            border-radius: 8px 8px 8px 8px;
          }
        }

        .AIDialogTitle{
          position: absolute;
          top: -30px;
          left: 30px;

          -webkit-background-clip:text;
          -webkit-text-fill-color:transparent;
          background-image:-webkit-linear-gradient(358.02892004842deg, #0A2A64 0%, #1C5FBC 100%);
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
        }
        .AIDialogueContent{
          margin: 20px 20px 0;
          width: calc( 100% - 40px );
          height: calc( 100% - 140px );

          overflow-y: scroll;
          scrollbar-width: none;

          .assistantDialog{
            text-align: left;
            padding: 25px 0;
            position: relative;

            .dialogIconAssistant{
              display: inline-block;
              vertical-align: bottom;
              height: 34px;
              width: 34px;
            }
            .dialogContentAssistant{
              display: inline-block;
              vertical-align: bottom;
              padding: 10px;
              margin-left: 10px;
              background-color: #FFFFFF;
              border-radius: 8px;
              min-height: 18px;

              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }

            .AIDialogueCopyIcon{
              display: none;
              position: absolute;
              left: 10px;
              bottom: -22px;
            }
            .AIDialogueCopyIcon2{
              display: none;
              position: absolute;
              left: 50px;
              bottom: -22px;
            }
            .AIDialogueRegenerateIcon{
              display: none;
              position: absolute;
              left: 30px;
              bottom: -22px;
            }
          }
          .assistantDialog:hover{
            .AIDialogueCopyIcon{
              color: #555555;
              vertical-align: bottom;
              display: inline-block;
              cursor: pointer;
            }
            .AIDialogueCopyIcon2{
              color: #555555;
              vertical-align: bottom;
              display: inline-block;
              cursor: pointer;
            }
            .AIDialogueRegenerateIcon{
              color: #555555;
              vertical-align: bottom;
              display: inline-block;
              cursor: pointer;
            }
          }
          .userDialog{
            text-align: right;
            padding: 20px 0;

            .dialogContentUser{
              display: inline-block;
              vertical-align: bottom;
              margin-right: 10px;
              padding: 10px;
              text-align: left;
              max-width: 70%;
              background-color: #A2C1FF;
              border-radius: 8px;

              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
            .dialogIconUser{
              display: inline-block;
              vertical-align: bottom;
              height: 34px;
              width: 34px;
            }
            .AIDialogueCopyIcon3{
              display: none;
              position: absolute;
              right: 40px;
              bottom: 0px
            }
          }
          .userDialog:hover{
            .AIDialogueCopyIcon3{
              color: #555555;
              vertical-align: bottom;
              display: inline-block;
              cursor: pointer;
            }
          }

          .myChatStopGenerate{
            font-weight: 500;
            font-size: 14px;
            display: inline-block;
            padding: 8px 15px;
            border-radius: 8px 8px 8px 8px;
            span{
              margin-left: 5px;
              vertical-align: middle;
            }
            svg{
              vertical-align: middle;
            }
          }
        }
        .AIDialogueInput{
          height: 80px;
          padding: 20px 20px;

          .AIDialogInputBlock{
            width: calc( 100% - 70px );
            vertical-align: middle;
            background-color: #ffffff;
          }

          .AIDialogInputBlock ::v-deep .el-textarea__inner{
            scrollbar-width: none;
          }

          .AIDialogInputButton{
            display: inline-block;
            vertical-align: middle;
            height: 40px;
            width: 40px;
            margin: 10px 0 10px 20px;
            cursor: pointer;
          }
        }

        .leftButton1{
          position: absolute;
          height: 36px;
          width: 36px;
          left: -18px;
          top: calc( 50% - 18px );
          z-index: 5;
          cursor: pointer;
        }
        .leftButton2{
          position: absolute;
          left: -26px;
          top: calc( 50% - 19px );
          height: 38px;
          width: 24px;
          background-color: #6C80B3;
          color: #ffffff;
          line-height: 38px;
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 12px;
          border-radius: 8px 0 0 8px;
          cursor: pointer;
        }

        .rightButton1{
          position: absolute;
          height: 36px;
          width: 36px;
          right: -18px;
          top: calc( 50% - 18px );
          z-index: 5;
          cursor: pointer;
        }
        .rightButton2{
          position: absolute;
          right: -26px;
          top: calc( 50% - 19px );
          height: 38px;
          width: 19px;
          background-color: #6C80B3;
          color: #ffffff;
          line-height: 38px;
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 12px;
          padding-left: 5px;
          border-radius: 0 8px 8px 0;
          cursor: pointer;
        }
      }

      .coloredBG{
        background-image: url("../../assets/images/patentOAAi/coloredBG.png");
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 100%, 100%;
      }
    }
  }

  .myHugeVMDEditor{
    min-height: 200px;

    ::v-deep textarea{
      min-height: 200px;
    }
  }

  ::v-deep div.v-md-editor__main > div.v-md-editor__preview-wrapper > div > div.scrollbar__wrap > div > div > div{
    padding: 0;
    cursor: default;
    text-indent: 20px;
    font-size: 14px;
  }
  ::v-deep .vuepress-markdown-body{
    background-color: transparent;
  }
  ::v-deep .v-md-editor--preview{
    background-color: transparent;
  }

  ::v-deep div.v-md-editor__right-area > div.v-md-editor__main > div.v-md-editor__preview-wrapper > div > div.scrollbar__bar.is-horizontal{
    height: 0;
  }

  .v-md-editor{
    margin: 10px 0;
  }
  ::v-deep .v-md-textarea-editor pre{
    padding: 10px;
  }
  ::v-deep textarea{
    background-color: transparent!important;
    padding: 10px;
  }
  ::v-deep .v-md-editor__toolbar{
    display: none!important;
  }
  ::v-deep .el-textarea__inner{
    scrollbar-width: thin;
  }
}
</style>
<style lang="scss">
.trademarkObjection{
  .el-tree{
    background-color: transparent;
    .el-tree-node{
      white-space: normal;
      width: 100%!important;

      .el-tree-node__content{
        font-weight: bolder;
        height: 100%;
        align-items: start;
        border-radius: 4px;
        font-size: 14px;
        color: #333333;

        .el-tree-node__label{
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 5px;
        }
      }
      .el-tree-node__content:hover{
        background-color: rgba(190,204,241,0.8);
      }
      .el-tree-node__children{
        .el-tree-node__label{
          display: inline-block;
          white-space: nowrap;      /* 保持文本在一行，不换行 */
          overflow: hidden;         /* 隐藏超出容器的内容 */
          text-overflow: ellipsis;  /* 超出部分显示为省略号 */
        }
      }
    }
  }
}
.el-tooltip__popper{
  max-width: 300px;
}
</style>

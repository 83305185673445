import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import enUS from 'vxe-table/lib/locale/lang/en-US'

const en = {
  "translationText": 'Patent translation',
  "searchText": 'Patent search',
  "queryText": 'Trademark search',
  "headers": {

    "navList": [
      {
        "text": "Trademark solutions",
        "path": "/solutions",
        "position": 15,
        'showBool': true,
        children: [{
          "text": "Online applications",
          "subTilte": true,
          'showBool': true
        }, {
          "text": "Trademark search",
          "subTilte": false,
          "path": "/trademarkSearch",
          'showBool': true
        }, {
          "text": "Trademark monitoring",
          "subTilte": false,
          "path": "/SC",
          'showBool': true
        }, {
          "text": "Brand protection",
          "subTilte": false,
          "path": "/BP",
          'showBool': true
        }, {
          "text": "API",
          "subTilte": true,
          'showBool': true
        }, {
          "text": "Trademark search API",
          "subTilte": false,
          "path": "/TI",
          'showBool': true
        }, {
          "text": "Process management",
          "subTilte": true,
          'showBool': true
        }, {
          "text": "Trademark management system",
          "subTilte": false,
          "path": "/TMS",
          'showBool': true
        }
          // {
          //   "text": "效率工具",
          //   "subTilte":true,
          //   'showBool': true
          // },{
          //   "text": "商标自动化提交官申",
          //   "subTilte":false,
          //   // "path": "/AS",
          //   'showBool': true
          // }
        ]
      },
      {
        "text": "Patent solutions",
        "path": "/patentSolutions",
        "position": 15,
        'showBool': true,
        children: [{
          "text": "Online applications",
          "subTilte": true,
          'showBool': true
        }, {
          "text": "Patent translation",
          "subTilte": false,
          "path": "/patentTranslation",
          'showBool': true
        }, {
          "text": "Patent search",
          "subTilte": false,
          "path": "/searchTntroduction",
          'showBool': true
        }, {
          "text": "API",
          "subTilte": true,
          'showBool': true
        }, {
          "text": "Patent translation API",
          "subTilte": false,
          "path": "/psApi",
          'showBool': true
        }, {
          "text": "Process management",
          "subTilte": true,
          'showBool': true
        }, {
          "text": "Patent management system",
          "subTilte": false,
          "path": "/PMS",
          'showBool': true
        }, {
          "text": "Annual patent fee management system",
          "subTilte": false,
          "path": "/PAMS",
          'showBool': true
        },
          {
            "text": "Efficiency tools",
            "subTilte": true,
            'showBool': true
          }, {
            "text": "Computer-aided translation Trados plug-in",
            "subTilte": false,
            "path": "/Trados",
            'showBool': true
          }, {
            "text": "Computer-aided translation Microsoft Word plug-in",
            "subTilte": false,
            "path": "/TradosWord",
            'showBool': true
          }, {
            "text": "Computer-aided translation Excel plug-in",
            "subTilte": false,
            "path": "/Excel",
            'showBool': true
          }, {
            "text": "Patent five-chapter automatic split",
            "subTilte": false,
            "path": "/SPB",
            'showBool': true
          }, {
            "text": " Patent CPC one-click attachment compression",
            "subTilte": false,
            "path": "/patentCPC",
            'showBool': true
          }
        ]
      },
      {
        "text": "Data collaboration platform",
        "path": "/collaborationPlatform",
        "position": 15,
        'showBool': true,
        children: [
          // {
          //       "text": "智慧园区",
          //       "subTilte": false,
          //       "path": "/smartPark",
          //       'showBool': true
          //   },
          {
            "text": "Customized service for enterprise network disk",
            "subTilte": false,
            "path": "/enterpriseNetwork",
            'showBool': true
          }
          // {
          //   "text": "数字化平台",
          //     "subTilte":false,
          //   // "path": "/digitalPlatform",
          //   'showBool': true
          // }

          // ,{
          //   "text": "EHR管理系统订制(事务所版)",
          //     "subTilte":false,
          //   // "path": "/EHR",
          //   'showBool': true
          // }
        ]
      },
      // {
      //     "position": 15,
      //     "text": "Trademark big bata",
      //     "path": "/brand",
      //     "showBool": true
      // },
      // {
      //     "position": 15,
      //     "text": "News",
      //     "path": "/TD",
      //     "showBool": true
      // },
      {
        "position": 15,
        "text": "About us",
        "path": "/AU",
        "showBool": true
      }
    ],
    "login": "Login / Register",
    "vxLogin": "Binding",
    "dropDownMenu": [
      // {
      //       "text": "Personal center",
      //       "path": "/personalCenter"
      //   },
      {
        "text": "Login out",
        "path": ""
      }
    ],
    'noData': 'No notification message'
  },
  "logins": {
    "fild": ['Enter account', 'Input password', 'Register now', 'Forget password', 'Quick login', 'Please set a password of more than 6 digits', 'Register', 'Next step', 'Enter phone number'],
    "fild_sub": ['Enter the password again', 'Submit', 'Sign in', 'Login with user name and password', 'Registration complete', 'Modified successfully', 'Return to login', 'Existing account'],
    "form": {
      "phone": {
        "placeholder": "Enter account",
        "errorMessage": "Account error"
      },
      "code": {
        "placeholder": "Enter verification code",
        "errorMessage": "Verification code error"
      },
      "sendCodeText": "Get verification code",
      "resendText": "Resend"
    },
    "AgreementBtn": "Read and accept《Lanternfish User agreement》",
    "errorAgreeMessage": "Please confirm the user agreement",
    "wechatDesc": "The account needs to be bound to the mobile phone",
    "tips": "Please use wechat to scan QR code to log in",
    "confirmTips": "Tip: this mobile phone number is bound to other wechat. Please unbind it first",
    "confirmTipsText": 'Tips',
    "confirmButtonText": "Go login",
    "cancelButtonText": 'cancel'
  },
  "xfooter": {
    'title1': 'Follow us',
    'title2': 'Contact us',
    'content': [
      [{
        img: '/images/index/cx.jpg',
        subTitle: 'Trademark solutions',
        path: '/solutions'
      },
        {
          img: '/images/index/cx.jpg',
          subTitle: 'Trademark search',
          path: '/trademarkSearch'
        },
        {
          img: '/images/index/jk.jpg',
          subTitle: 'Trademark monitoring',
          path: '/SC'
        },
        {
          img: '/images/index/bh.jpg',
          subTitle: 'Brand protection',
          path: '/BP'
        },
        {
          img: '/images/index/api.jpg',
          subTitle: 'Trademark search API',
          path: '/TI'
        },
        {
          img: '/images/index/gl.jpg',
          subTitle: 'Trademark management system',
          path: '/TMS'
        }
      ],
      [{
        img: '/images/index/zhuanli1.png',
        subTitle: 'Patent business solutions',
        path: '/patentSolutions'
      },
        {
          img: '/images/index/zhuanli1.png',
          subTitle: 'Patent translation',
          path: '/patentTranslation'
        },
        {
          img: '/images/index/zhuanli1.png',
          subTitle: 'Patent search',
          path: '/searchTntroduction'
        },
        {
          img: '/images/index/zhuanli2.png',
          subTitle: 'Patent translation API',
          path: '/psApi'
        },
        {
          img: '/images/index/zhuanli3.png',
          subTitle: 'Patent management system',
          path: '/PMS'
        },
        {
          img: '/images/index/zhuanli4.png',
          subTitle: 'Annual patent fee management system',
          path: '/PAMS'
        }, {
        subTitle: 'Computer-aided translation Trados plug-in',
        path: "/Trados"
      }, {
        subTitle: 'Computer-aided translation Microsoft Word plug-in',
        path: "/TradosWord"
      }, {
        subTitle: 'Computer-aided translation Excel plug-in',
        path: "/Excel"
      }, {
        subTitle: 'Patent five-chapter automatic split',
        path: "/SPB"
      }, {
        subTitle: 'Patent CPC one-click attachment compression',
        path: "/patentCPC"
      }
      ],
      [{
        img: '/images/index/shuju1.png',
        subTitle: 'Data collaboration platform',
        path: '/collaborationPlatform'
      },
        // {
        //     img: '/images/index/shuju1.png',
        //     subTitle: '智慧园区',
        //     path: '/smartPark'
        // },
        {
          img: '/images/index/shuju3.png',
          subTitle: 'Customized service for enterprise network disk',
          path: '/enterpriseNetwork'
        }
      ]
    ],
  },
  "index": {
    'bannerTitle1': 'Using data to empower and develop innovation in IP',
    'describe': "Focusing on IP to provide sound business  solutions",
    "more": 'Learn more',
    'type': [{
      mold: 'Limited time trial',
      describe: 'Patent translation',
      // path: '/patentTranslation'
      path: '/PT'
    },
      {
        mold: 'Popular products',
        describe: 'Trademark search',
        // path: '/trademarkSearch'
        path: '/nPQ'
      },
      {
        mold: 'Free trial',
        describe: 'Patent search',
        // path: '/searchTntroduction'
        path: '/PS'
      },
      {
        mold: 'View now',
        describe: 'Schedule of fees',
        path: '/services'
      }
    ],
    'product_title': 'Products and services',
    'product': [{
      name: 'Trademark',
      isShow: true,
      left: 26
    },
      {
        name: 'Patent Data',
        isShow: false,
        left: 26
      },
      {
        name: 'collaboration',
        isShow: false,
        left: 52
      }
    ],
    'content': [
      [{
        img: '/images/cancat/trademark1.jpg',
        subTitle: 'Trademark search',
        path: '/trademarkSearch'
      },
        {
          img: '/images/cancat/trademark2.jpg',
          subTitle: 'Trademark monitoring',
          path: '/SC'
        },
        {
          img: '/images/cancat/trademark3.jpg',
          subTitle: 'Brand protection',
          path: '/BP'
        },
        {
          img: '/images/cancat/trademark4.jpg',
          subTitle: 'Trademark search API',
          path: '/TI'
        },
        {
          img: '/images/cancat/trademark5.jpg',
          subTitle: 'Trademark management system',
          path: '/TMS'
        },
        // {
        //   img:'/images/index/tj.jpg',
        //   subTitle:'商标自动提交审核',
        // }
      ],
      [{
        img: '/images/cancat/patent6.jpg',
        subTitle: 'Patent translation',
        path: '/patentTranslation'
      },
        {
          img: '/images/cancat/patent7.jpg',
          subTitle: 'Patent search',
          path: '/searchTntroduction'
        },
        {
          img: '/images/cancat/patent1.jpg',
          subTitle: 'Patent translation API',
          path: '/psApi'
        },
        {
          img: '/images/cancat/patent2.jpg',
          subTitle: 'Patent management system',
          path: '/PMS'
        },
        {
          img: '/images/cancat/patent3.jpg',
          subTitle: 'Annual patent fee management system',
          path: '/PAMS'
        },
        {
          img: '/images/index/split.png',
          subTitle: 'Computer-aided translation Trados plug-in',
          path: '/Trados'
        },
        {
          img: '/images/index/split.png',
          subTitle: 'Computer-aided translation Microsoft Word plug-in',
          path: '/TradosWord'
        },
        {
          img: '/images/index/split.png',
          subTitle: 'Computer-aided translation Excel plug-in',
          path: '/Excel'
        },
        {
          img: '/images/index/split.png',
          subTitle: 'Patent five-chapter automatic split',
          path: '/SPB'
        },
        {
          img: '/images/index/compress.png',
          subTitle: 'Patent CPC one-click attachment compression',
          path: '/patentCPC'
        }
      ],
      [
        // {
        //       img: '/images/cancat/data1.jpg',
        //       subTitle: '智慧园区',
        //       path: '/smartPark'
        //   },
        // {
        //   img:'/images/index/shuju2.png',
        //   subTitle:'数字化平台',
        //   // path: '/digitalPlatform'
        // },
        {
          img: '/images/cancat/data3.jpg',
          subTitle: 'Customized service for enterprise network disk',
          path: '/enterpriseNetwork'
        }
        // {
        //   img:'/images/index/shuju4.png',
        //   subTitle:'EHR管理系统订制(事务所版)',
        // }
      ]
    ],
    'drive': {
      title: 'Technology expanding boundaries',
      technology: [{
        title: 'Deep industry knowledge',
        img: require('@/assets/images/index/hy.jpg')
      },
        {
          title: 'Diversified experience',
          img: require('@/assets/images/index/dy.jpg')
        },
        {
          title: 'AI technology implementation',
          img: require('@/assets/images/index/ai.jpg')
        },
        {
          title: 'Exploration of innovation possibilities',
          img: require('@/assets/images/index/js.jpg')
        }
      ]
    },
    'industry': {
      title: 'Focusing on IP to provide sound business  solutions',
      patent: [{
        title: 'Trademark',
        path: '/solutions'
      },
        {
          title: 'Patent',
          path: '/patentSolutions'
        },
        {
          title: 'Data collaboration',
          path: '/collaborationPlatform'
        }
      ]
    },
    'patent': [{
      title: 'Trademark',
      path: '/solutions'
    },
      {
        title: 'Patent',
        path: '/patentSolutions'
      },
      {
        title: 'Data collaboration',
        path: '/collaborationPlatform'
      }
    ],
    'contact': {
      title: 'Contact us',
      describe: '',
      btn: 'Contact us'
    }
  },
  "solutions": {
    "bannerTitle1": "Trademark solutions",
    'describe': "Innovative solutions with in-depth trademark knowledge",
    'introduction': {
      "title": "Trademark solutions",
      'describe': "Lanternfish is a provider of trademark business solutions, and has developed several trademark products in-house., To protect businesses’ rights and interests as well as ensure its future development, we offer tools like trademark search, trademark monitoring, and brand protection services. Services such as our trademark management system and other automation tools improve business management and efficiency",
    },
    tabs: {
      title1: 'Online applications',
      title2: 'API',
      title3: 'Process management',
      content: [
        [{
          path: '/trademarkSearch',
          img: require('@/assets/images/cancat/trademark1.jpg'),
          title: 'Trademark search'
        },
          {
            path: '/SC',
            img: require('@/assets/images/cancat/trademark2.jpg'),
            title: 'Trademark monitoring'
          },
          {
            path: '/BP',
            img: require('@/assets/images/cancat/trademark3.jpg'),
            title: 'Brand protection'
          }
        ],
        [{
          path: '/TI',
          img: require('@/assets/images/cancat/trademark4.jpg'),
          title: 'Trademark search API'
        }],
        [{
          path: '/TMS',
          img: require('@/assets/images/cancat/trademark5.jpg'),
          title: 'Trademark management system'
        }]
      ]
    }
  },
  "trademarkSearch": {
    "bannerTitle1": "Trademark search",
    'describe': "",
    'button': "Use Now",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our trademark search product is based on global trademark data of 43 countries/regions, which can cover more than 99% of the case countries required for foreign and domestic trademark registration, and supports both image and text search',
      'describe2': 'Our image search technology is based on global trademark image databases, using machine technology to extract image features according to the Vienna code classification, accurately searching for similar images. During the machine learning process ,we add the standard rules of manual review, summarize the meaning of images and graphic structure, optimize the algorithm of feature extraction, and continuously optimize the iterative training model.',
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        crux: "43 ",
        value: 'jurisdictions'
      },
        {
          crux: "AI intelligent ",
          value: 'engine'
        },
        {
          crux: "Registrability ",
          value: 'Analysis'
        },
        {
          crux: "Flexible access ",
          value: 'services'
        }
      ]
    },
    'examples': {
      title: "Product Examples",
      lun: [{
        imgSrc: '/images/index/query_index1.png',
        name: 'Animation retrieval'
      }, {
        imgSrc: '/images/index/query_index2.png',
        name: 'Animal image retrieval'
      }, {
        imgSrc: '/images/index/query_index3.png',
        name: 'Graphic retrieval'
      }]
    },
    'price': {
      title: 'Product pricing',
      listData: [{
        categoryCode: "shangbiaoyewu",
        categoryName: "商标业务",
        currency: "CNY",
        existApply: false,
        imageUrl: "trademark_query.png",
        modeType: "4",
        price: '全球数据 周期自选',
        productName: "Trademark search",
        publishType: "4",
        serviceDesc: "Online application",
        button: true,
        btnName: "purchasing",
        serviceName: "Trademark search"
      },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "trademark_query.png",
          modeType: "4",
          btnName: "Trial",
          price: '全球数据3天，30次',
          productName: "Trademark search",
          publishType: "4",
          serviceDesc: "Online application",
          button: true,
          serviceName: "Trademark search"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number1.png",
          modeType: "4",
          price: '联系我们',
          productName: "Localized Deployment",
          publishType: "4",
          serviceDesc: "",
          button: false,
          serviceName: "Trademark search"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number2.png",
          modeType: "4",
          price: '联系我们',
          productName: "Engine Customization",
          publishType: "4",
          serviceDesc: "Matching different usage scenarios",
          button: false,
          serviceName: "Trademark search"
        },
      ],
    },
    'cancat': 'Contact us',
    iptPlaceholder: 'Please input trademark name/applicantion no./applicant',
    combineSearch: 'Advanced search',
    pleaseSelect: 'Please Select',
    uploadExplain: 'Please upload a trademark specimen <5MB JPG/PNG/GIF/TIFF格式',
    yes: 'Yes',
    no: 'No',
    valid: 'Valid',
    invalid: 'Invalid',
    add: 'Add',
    reminder: '温馨提示:&nbsp;&nbsp;可选多个',
    confirm: 'Confirm',
    close: 'Close',
    class: 'Class',
    selecetdConditions: 'Selected condition',
    country: 'Country',
    more: 'More',
    reset: 'Reset',
    return: 'Return',
    selectAll: 'Select All',
    selectNone: 'Select None',
    Asia: 'Asia',
    Europ: 'Europ',
    NorthAmerica: 'NorthAmerica',
    SouthAmerica: 'SouthAmerica',
    Africa: 'Africa',
    Oceania: 'Oceania',
    Organization: 'Organization',
    purchase: 'Purchase',
    applicantName: 'Applicant Name',
    select: 'Select',
    clear: 'Clear',
    applicantionDate: 'Applicantion Date',
    registrationDate: 'Registration Date',
    isValid: 'isValid',
    status: 'Status',
    agent: 'Agent',
    similarity: 'Degree of Similarity',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    address: 'Address',
    Chinese: 'Chinese',
    applicantionNo: 'Applicantion No.',
    applicant: 'Appliacnat',
    Enghish: 'English',
    services: 'Goods/Services',
    analysis: '可注册分析报告',
    renewal: 'Renewal',
    seeBigImg: '大图预览',
    noData: 'No results',
    table: 'table',
    tableName: 'the Search Supported Country/Region',
    statementPre: 'For the scope of trademark application, the latest update time, please refer to',
    statementafter: 'Disclaimer: Due to the time difference between data updates, the search results are for reference only and have no legal effect.',
    uploadImg: 'Please upload image',
    inputText: 'Please input text',
    textOrImg: 'Please input text or upload image',
    loading: 'Loading...',
    loadingComplte: 'Loading Complte',
    goPurchase: 'Go Purchase',
    runOut: '您的查询次数已用完',
    failedReport: '生成可注册报告失败',
    sendMessage: '您已经提交申请，审核通过后，会给您发送通知',
    otherResult: '结果较少，以下为其他类别结果供参考',
    searchResult: 'Search Result.xls'
  },
  "SC": {
    "bannerTitle1": "Trademark monitoring",
    'describe': "Big data search and monitoring technology combined with AI image recognition technology",
    'introduction': {
      "title": "Product Description",
      'describe1': 'Lanterfish trademark monitoring product uses big data search and monitoring technology and AI image recognition  to search for and compare similar trademarks with weekly published trademark  based on text, graphics, trademark class, and oher critera to effectively monitor trademarks and prevent malicious infringement.',
    },
    'basicProcess': {
      title: 'Basic process',
      img: require('@/assets/images/patent/basicprocess.jpg'),
      basicProcess_cen: [{
        value_1: '01',
        value_2: 'Set conditions',
        value_3: 'Set conditions, Latin characters, Chinese characters, Japanese characters, Korean characters or graphic marks, class range',
      },
        {
          value_1: '02',
          value_2: 'Continuous monitoring',
          value_3: 'The AI intelligent algorithm continuously monitors the trademark office\'s announcement information in each issue to accurately identify identical and similar trademarks',
        },
        {
          value_1: '03',
          value_2: 'Automatic reporting',
          value_3: 'Monitoring results are delivered as reports',
        }
      ]
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction2.png'),
      'advantage_content': [{
        crux: "AI algorithm for image search",
        value: 'Advanced AI identification technology, image-by-image examination'
      },
        {
          crux: "Trademark big data",
          value: 'Trademark data for 43 countries/regions is continuously updated, covering the main trademark jurisdictions.'
        },
        {
          crux: "Model iteration,official standard",
          value: 'The AI trademark image similarity search model was trained on real cases of refusal citations, thus ensuring that the monitoring results will be similar to official rulings. '
        }
      ]
    },
    'scenarios': {
      title: 'Usage scenarios',
      scenarios_cen: [{
        url: require('@/assets/images/patent/part1.jpg'),
        content_1: "Infringement monitoring",
        content_2: "Users can discover identical or similar trademarks as early as possible, and file objections during the preliminary announcement period, so as to \"intercept\" similar trademarks in time and eliminate the potential harm that identical or similar trademarks may cause to trademark owners in advance.",
      },
        {
          url: require('@/assets/images/patent/part2.jpg'),
          content_1: "Prevention of trademark infringement",
          content_2: "Users can learn of infringement to their trademark, and quickly take countermeasures to avoid losses to their business.",
        }
      ]
    },
    'cancat': 'Contact us'
  },
  "BP": {
    "bannerTitle1": "Brand protection",
    'describe': "E-commerce monitoring, Domain monitoring, Image monitoring and Web monitoring.",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Lanternfish brand protection products target the Internet as the carrier of  products or logos, searching across   140+ mainstream e-commerce platforms and 1119 types of domain name suffixes . Monitoring of e-commerce platforms, domain names, and webpages is constant  over 24 hours helping clients automatically protect their brand and collect evidence of infringement.',
    },
    'basicProcess': {
      title: 'Basic process',
      img: require('@/assets/images/patent/basicprocess.jpg'),
      basicProcess_cen: [{
        value_1: '01',
        value_2: 'Set conditions',
        value_3: 'Fill in  monitoring information',
      },
        {
          value_1: '02',
          value_2: 'Continuous monitoring',
          value_3: 'Monitoring of suspected infringing goods, Evidence preservation of counterfeit goods',
        },
        {
          value_1: '03',
          value_2: 'Automatic reporting',
          value_3: 'Monitoring results are delivered as reports',
        }
      ]
    },
    "title": 'Product type',
    "type": [{
      title: 'Electronic business monitoring',
      content: 'Online sales through e-commerce platforms is the main business model of many brands. However, counterfeit and substandard products are increasingly available globally, and unscrupulous businessmen take the opportunity to make huge profits while destroying reputable brand image and damaging business interests. Lanternfish provides clients with e-commerce monitoring services, which monitor 140+ mainstream e-commerce platforms searching for suspected infringing goods 24/7.The service also includes evidence preservation of counterfeit information, feedback to customers in the form of reports, one-click submission of complaints to e-commerce platforms, and seamless connection to  experienced IP lawyers to take action on behalf of clients.',
      isShow: true,
      imgSrc: '/images/patent/type1.png'
    },
      {
        title: 'Domain name monitoring',
        content: 'Domain names are as important as trademarks, both being key symbols of a brand that customers use to identify your business. Lanternfish domain name monitoring products cover 1119 types of domain name suffixes, using brand keywords to find the same or similar domain names.  The client receives a complete monitoring report, which can be used as evidence in future measures of rights protection.',
        isShow: false,
        imgSrc: '/static/assets/images/patent/type1.png'
      },
      {
        title: 'Image monitoring',
        content: 'Infringers use stolen logo images or other advertising and product images, store photos, etc. to damage the interests of brand owners. Lanternfish technology searches across the web to find the same orsimilar images to form a complete monitoring report and preserve evidence, giving clients  all the information needed tocarry out further legal action to protect their brand.',
        isShow: false,
        imgSrc: '/static/assets/images/patent/type1.png'
      },
      {
        title: 'Web monitoring',
        content: 'We conduct24/7 intelligent monitoring of web pages using the client\'s brand, so as not to leave any opportunity for counterfeiters to damage your reputation. Monitoring results will be sent as a report, and can act as evidence , containing detailed information of the suspected infringing website: URL, website screenshot, infringing link, IP address, risk level, etc.',
        isShow: false,
        imgSrc: '/static/assets/images/patent/type1.png'
      }
    ],
    'cancat': 'Contact us'
  },
  "TI": {
    "bannerTitle1": "Trademark search API",
    'describe': "Based on global trademark data, Continued iteration of an AI algorithm based on global trademark data",
    'button': "View Documents",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our trademark search product is based on global trademark data of 43 countries/regions, which can cover more than 99% of the case countries required for foreign and domestic trademark registration, and supports both image and text search',
      'describe2': 'Our image search technology is based on global trademark image databases, using machine technology to extract image features according to the Vienna code classification, accurately searching for similar images. During the machine learning process ,we add the standard rules of manual review, summarize the meaning of images and graphic structure, optimize the algorithm of feature extraction, and continuously optimize the iterative training model.',
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction.jpg'),
      'advantage_content': [{
        crux: "43",
        value: 'jurisdictions'
      },
        {
          crux: "AI intelligent",
          value: 'engine'
        },
        {
          crux: "Professional",
          value: 'partners'
        },
        {
          crux: "Fast access to",
          value: 'services'
        }
      ]
    },
    'examples': {
      title: "Product Examples",
      lun: [{
        imgSrc: '/images/index/query_index1.png',
        name: 'Animation retrieval'
      }, {
        imgSrc: '/images/index/query_index2.png',
        name: 'Animal image retrieval'
      }, {
        imgSrc: '/images/index/query_index3.png',
        name: 'Graphic retrieval'
      }]
    },
    'process': {
      url: require("@/assets/images/patent/process.jpg"),
      title: "Access Process",
      content: ['Login', 'Login now', 'Contact us', 'Set up account，Create  service', 'Get API key', 'Open personal center, Account information', 'Develop and debug', 'View API documents', 'Access successful']
    },
    'cancat': 'Contact us'
  },
  "TMS": {
    "bannerTitle1": "Trademark management system",
    'describe': "Entire case life cycle management for trademark agencies",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our trademark management system is a process management system for trademark agencies to provide all types of trademark services',
      'describe2': 'System features: flexible and secure trademark case file and process data management, automatic reminder of case deadline, automatic generation of reports and letter templates, automatic official application submission, convenient configuration of letter and document templates, flexible configuration'
    },
    'advantage': {
      'title': 'Product advantages',
      'advantage_content': ['The Entire case life cycle management', 'professional', 'intelligent', 'standardized'],
      'url': require('@/assets/images/patent/introduction1.png')
    },
    'price': {
      title: 'Product pricing',
      listData: [{
        categoryCode: "shangbiaoyewu",
        categoryName: "商标业务",
        currency: "CNY",
        existApply: false,
        imageUrl: "patent_mgr_system.png",
        modeType: "4",
        price: '全球数据 周期自选',
        productName: "Patent translation",
        publishType: "4",
        serviceDesc: "Process management",
        button: true,
        btnName: "purchasing",
        serviceName: "Trademark management system"
      }],
    },
    'cancat': 'Contact us'
  },
  "patentSolutions": {
    "bannerTitle1": "Patent business solutions",
    'describe': "Providing innovative solutions in the patent field",
    'introduction': {
      "title": "Patent Business Solutions",
      'describe': "We provide patent business solutions, which have now been developed into several products, patent translation, patent search, patent management system, and others., AI-enabled technology provides standardized, intelligent, highly secure  patent management process.",
    },
    tabs: {
      title1: 'Online applications',
      title2: 'API',
      title3: 'Process management',
      title4: 'Efficiency tools',
      content: [
        [{
          path: '/patentTranslation',
          img: require('@/assets/images/cancat/patent6.jpg'),
          title: 'Patent translation'
        },
          {
            path: '/searchTntroduction',
            img: require('@/assets/images/cancat/patent7.jpg'),
            title: 'Patent search'
          }
        ],
        [{
          path: '/psApi',
          img: require('@/assets/images/cancat/patent1.jpg'),
          title: 'Patent translation API'
        }],
        [{
          path: '/PMS',
          img: require('@/assets/images/cancat/patent2.jpg'),
          title: 'Patent management system'
        },
          {
            path: '/PAMS',
            img: require('@/assets/images/cancat/patent3.jpg'),
            title: 'Patent annuity management system'
          }
        ],
        [{
          path: '/Trados',
          img: require('@/assets/images/cancat/split.png'),
          title: 'CAT Trados plug-in'
        },
          {
            path: '/TradosWord',
            img: require('@/assets/images/cancat/split.png'),
            title: 'CAT Word plug-in'
          },
          {
            path: '/Excel',
            img: require('@/assets/images/cancat/split.png'),
            title: 'CAT Excel plug-in'
          },
          {
            path: '/SPB',
            img: require('@/assets/images/cancat/split.png'),
            title: 'Automatic splitting of patent documents'
          },
          {
            path: '/patentCPC',
            img: require('@/assets/images/cancat/compress.png'),
            title: 'Automatic compression of the patent attachment'
          }
        ]
      ]
    }
  },
  "patentTranslation": {
    "bottom_hover": 'txt/word/pdf (pure text files only,word count less than 20,000 words)',
    "source_language": 'source language：',
    "downloadFn": 'download Rresults',
    "choose_tips": "Please select the format to download",
    "confirmDownType": 'confirm',
    "bannerTitle1": "Patent translation",
    'describe': "High-quality translation is derived from the professional cleaning of 120 million global patent data and the extraction of 50 million+ training corpus.",
    'button': "Use Now",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our patent translation products are based on 120 million global patent database and patent agency industry patent translation database for more than 20 years, more than 50 million corpus data extraction, through neural network machine learning algorithm, training AI translation engine applicable to patent application documents, OA responses, comparison documents, invalidation documents and patent references and other patent fields, the result sentence style is more in line with the patent industry characteristics.\n' + 'Our patent translation products are based on 120 million entries in global patent databases and patent agency industry patent translation databases of more than 20 years, with more than 50 million corpus data extracted. Through neural network machine learning algorithms, training of the AI engine is specified to the patent field, using patent application documents, OA responses, comparison documents, invalidation documents, and patent references as training materials. The resulting sentence patterns are specific to the patent industry.',
      'describe2': 'Currently, our tool supports four language pairs: Chinese-English, English-Chinese, Chinese-Japanese, and Japanese-Chinese.',
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction2.png'),
      'advantage_content': [{
        crux: "Ten million",
        value: 'training corpus'
      },
        {
          crux: "20 years",
          value: 'of industry experience'
        },
        {
          crux: "Flexible and diverse",
          value: 'access services'
        }
      ]
    },
    'examples': {
      title: "Product Examples",
      lun: [{
        imgSrc: '/images/index/search_en.png',
        name: 'Sample Patent Translation'
      }]
    },
    'price': {
      title: 'Product pricing',
      listData: [{
        categoryCode: "shangbiaoyewu",
        categoryName: "商标业务",
        currency: "CNY",
        existApply: false,
        imageUrl: "patent_translate.png",
        modeType: "4",
        price: '全球数据 周期自选',
        productName: "Patent translation",
        publishType: "4",
        serviceDesc: "Online application/API",
        button: true,
        btnName: "purchasing",
        serviceName: "Patent translation"
      },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number1.png",
          modeType: "4",
          price: '联系我们',
          productName: "Localized Deployment",
          publishType: "4",
          serviceDesc: "",
          button: false,
          serviceName: "Localized Deployment"
        },
        {
          categoryCode: "shangbiaoyewu",
          categoryName: "商标业务",
          currency: "CNY",
          existApply: false,
          imageUrl: "number3.png",
          modeType: "4",
          price: '联系我们',
          productName: "Customized training",
          publishType: "4",
          serviceDesc: "",
          button: false,
          serviceName: "Customized training"
        },
      ],
    },
    'cancat': 'Contact us',
    "leftLanguage": [
      {
        "text": "Chinese",
        "languageDirection": "zh-cn",
        "isActive": true
      },
      {
        "text": "English",
        "languageDirection": "en-us",
        "isActive": false
      },
      {
        "text": "Japanese",
        "languageDirection": "ja-jp",
        "isActive": false
      }
    ],
    "translateBtn": require('@/assets/images/patent/rightcrow.png'),
    "rightLanguage": [
      {
        "text": "Chinese",
        "languageDirection": "zh-cn",
        "isActive": false,
        "isDisable": true
      },
      {
        "text": "English",
        "languageDirection": "en-us",
        "isActive": true,
        "isDisable": false
      },
      {
        "text": "Japanese",
        "languageDirection": "ja-jp",
        "isActive": false,
        "isDisable": false
      }
    ],
    "discernLan": [
      {
        "text": "Chinese",
        "languageDirection": "zh-cn",
        "isHow": false
      },
      {
        "text": "English",
        "languageDirection": "en-us",
        "isHow": false
      },
      {
        "text": "Japanese",
        "languageDirection": "ja-jp",
        "isHow": false
      }
    ],
    "languageArr": [
      {
        "leftText": "中",
        "rightText": "英",
        "languageDirection": "zh-cn_en-us",
        "hoverBool": false
      },
      {
        "leftText": "英",
        "rightText": "中",
        "languageDirection": "en-us_zh-cn",
        "hoverBool": false
      },
      {
        "leftText": "中",
        "rightText": "日",
        "languageDirection": "zh-cn_ja-jp",
        "hoverBool": false
      },
      {
        "leftText": "日",
        "rightText": "中",
        "languageDirection": "ja-jp_zh-cn",
        "hoverBool": false
      }
    ],
    "placeholder": "Free translation of up to 3000 words per day, single input of up to3000 words",
    "title": "Original Text",
    "errorTips": "您输入的字数超过了上限3000字，请删减",
    "origin": "Translated Text",
    "uploadBtn": "上传文档",
    "uploadNewBtn": "上传新文档",
    "cancelBtn": "取消",
    "inTranslation": "正在翻译中...",
    "confirmTitle1": "今日累计翻译已达到10次，可明日再来或者申请试用?",
    "confirmTitle2": "今日累计翻译已达到10次，可明日再来或者登录申请试用?",
    "confirmTitle3": "试用期已结束",
    "errMessage": "暂不支持该类型文件",
    "apply": "申请试用",
    "goLogin": "去登录",
    "tips": "提示",
    "tipsText": "您的翻译字数已用完",
    "goBuy": "去购买"
  },
  "searchTntroduction": {
    "bannerTitle1": "Patent search",
    'describe': "Based on 120 million global patent data, the training semantic model supports multiple retrieval types",
    'button': "Use Now",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Lanternfish\'s patent semantic retrieval product is based on 120 million global patent database, combined with artificial intelligence natural language technology, positioning and disassembly technical features, and expands the upper and lower synonyms through the knowledge map, so as to finally extract accurate retrieval keywords.\n' +
        'Through the optimization of machine learning algorithm, the recall and precision of patent search results are improved.',
      'describe2': 'The patent semantic retrieval product of lanternfish supports keyword retrieval, field retrieval, batch application number (publication number) and other retrieval methods.',
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        crux: "120 million",
        value: 'global patent data'
      },
        {
          crux: "Permanently free",
          value: ''
        },
        {
          crux: "Intelligent semantic",
          value: 'retrieval'
        },
        {
          crux: "Interactive",
          value: 'ease of use'
        }
      ]
    },
    'examples': {
      title: "Product Examples",
      lun: [{
        imgSrc: '/images/index/searchEasy.png',
        name: 'Patent search example'
      }]
    },
    'cancat': 'Contact us',
  },
  //商标查询
  'PQ': {
    'searchInput': ['Please enter Trademark names/Application Number/Applicant', 'Combined query', 'Please select', '请上传商标图样，<5MB JPG/PNG/GIF/TIFF格式'],
    'fild': ['是', '否', '有效', '无效', 'Add', '温馨提示', '可选多个', 'Confirm', '关闭', 'Selected Condition：', 'Class：', 'Country：'],
    'queryModes': ['More', 'Confirm', 'Reset', 'Return', 'Select all', 'Reset', 'Database', 'Select all', 'cancel all'],
    'country': ['Asia', 'Europe', 'North America', 'South America', 'Africa', 'Oceania', 'Intellectual Property Organization', '购买'],
    "accordion": ['Applicant Name', 'screen', 'clear', 'Class', 'Application date', 'Registration Date', 'Validity', '无效', '有效', 'Status', 'Agencies', 'Registration Analysis Report', '', '', 'Approximation'],
    "details_item": ['State', 'Application date', 'Registration Date', 'Applicant Address', 'Chinese', 'Application Number', 'Applicant', 'English', 'Class', 'Goods/Services'],
    "right": ['Applicant', 'Applicant Address', 'Application Number', 'Class', 'State', 'Application date', 'Registration Date', '去续展', '大图预览'],
    "regno": ['Application Number', 'Applicant', 'No results'],
    "statement": ['商标申请范围、最新更新时间，详见', '《支持查询的国家/地区》', '信息表', '免责声明：由于数据更新存在时间差，查询结果仅供参考，不具有法律效力。'],
    "firstList": [
      {text: "申请人精确", value: 0, param: 2, queryWay: "term", classControl: "", show: false},
      {text: "申请号精确", value: 1, param: 3, queryWay: "term", classControl: "", show: false},
      {text: "Name approximation", value: 2, param: 1, queryWay: "", classControl: "", show: true},
      {text: "Applicant", value: 3, param: 2, queryWay: "", classControl: "", show: true},
      {text: "图形近似", value: 4, param: "", queryWay: "", classControl: "", show: false},
      {text: "Application Number", value: 5, param: 3, queryWay: "", classControl: "", show: true},
      {text: "Agencies", value: 6, param: 4, queryWay: "", classControl: "", show: true},
    ],//param,queryWay爲後臺需要,classControl爲類名控制需要;
    "dataType": [
      {
        type: 1,
        name: "Industrial chemicals",
        title: '用于工业、科学、摄影、农业、园艺和林业的化学品;未加工人造合成树脂,未加工塑料物质;肥料;灭火用合成物;淬火和焊接用制剂;保存食品用化学品;鞣料;工业用粘合剂'
      },
      {
        type: 2,
        name: "Pigments, paints, resin",
        title: '颜料,清漆,漆;防锈剂和木材防腐剂;着色剂;媒染剂;未加工的天然树脂;绘画、装饰、印刷和艺术用金属箔及金属粉'
      },
      {
        type: 3,
        name: "Cosmetics, cleaning products",
        title: '洗衣用漂白剂及其他物料;清洁、擦亮、去渍及研磨用制剂;不含药物的肥皂;香料,香精油,不含药物的化妆品,不含药物的润发乳;不含药物的牙膏'
      },
      {
        type: 4,
        name: "Industrial oils and fuels",
        title: '工业用油和油脂;润滑剂;吸收、润湿和粘结灰尘用合成物;燃料（包括马达用燃料）和照明材料;照明用蜡烛和灯芯'
      },
      {
        type: 5,
        name: "Pharamaceuticals",
        title: '药品,医用和兽医用制剂;医用卫生制剂;医用或兽医用营养食物和物质,婴儿食品;人用和动物用膳食补充剂;膏药,绷敷材料;填塞牙孔用料,牙科用蜡;消毒剂;消灭有害动物制剂;杀真菌剂,除莠剂'
      },
      {
        type: 6,
        name: "Metals",
        title: '普通金属及其合金,金属矿石;金属建筑材料;可移动金属建筑物;普通金属制非电气用缆线;金属小五金具;存储和运输用金属容器;保险箱'
      },
      {
        type: 7,
        name: "Machines and machine tools",
        title: '机器和机床;马达和引擎（陆地车辆用的除外）机器联结器和传动机件（陆地车辆用的除外）非手动农业器具;孵化器;自动售货机'
      },
      {
        type: 8,
        name: "Cutlery, hand tools",
        title: '手工具和器具（手动的）刀、叉和勺餐具;随身武器;剃刀'
      },
      {
        type: 9,
        name: "Scientific, electronic devices",
        title: '科学、航海、测量、摄影、电影、光学、衡具、量具、信号、检验（监督）、救护（营救）和教学用装置及仪器;处理、开关、转换、积累、调节或控制电的装置和仪器;录制、传送、重放声音或影像的装置;磁性数据载体,录音盘;光盘,DVD 盘和其他数字存储媒介;投币启动装置的机械结构;收银机,计算机器,数据处理装置,计算机;计算机软件;灭火设备'
      },
      {
        type: 10,
        name: "Medical and nursing apparatus",
        title: '外科、医疗、牙科和兽医用仪器及器械;假肢,假眼和假牙;矫形用物品;缝合用材料;残疾人专用治疗装置;按摩器械;婴儿护理用器械、器具及用品;性生活用器械、器具及用品'
      },
      {
        type: 11,
        name: "Lights, AC, heating, santiaries",
        title: '照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水以及卫生用装置'
      },
      {
        type: 12,
        name: "Vehicles",
        title: '运载工具;陆、空、海用运载装置'
      },
      {
        type: 13,
        name: "Firearms, fireworks",
        title: '火器;军火及弹药;爆炸物;烟火'
      },
      {
        type: 14,
        name: "Jewelry, precious stones, watches",
        title: '贵重金属及其合金;首饰,宝石和半宝石;钟表和计时仪器'
      },
      {
        type: 15,
        name: "Musical instruments",
        title: '乐器'
      },
      {
        type: 16,
        name: "Office supplies, teaching materials",
        title: '纸和纸板;印刷品;书籍装订材料;照片;文具和办公用品（家具除外）文具用或家庭用粘合剂;艺术家用或绘画用材料;画笔;教育或教学用品;包装和打包用塑料纸、塑料膜和塑料袋;印刷铅字,印版'
      },
      {
        type: 17,
        name: "Plastic and rubber products",
        title: '未加工和半加工的橡胶、古塔胶、树胶、石棉、云母及这些材料的代用品;生产用成型塑料和树脂制品;包装、填充和绝缘用材料;非金属软管和非金属柔性管'
      },
      {
        type: 18,
        name: "Leather products ",
        title: '皮革和人造皮革;毛皮;行李箱和背包;雨伞和阳伞;手杖;鞭、马具和鞍具;动物用项圈、皮带和衣服'
      },
      {
        type: 19,
        name: "Non-metallic building materials",
        title: '非金属的建筑材料;建筑用非金属刚性管;柏油,沥青;可移动非金属建筑物;非金属碑'
      },
      {
        type: 20,
        name: "Furnitures, containers, bone and horn",
        title: '家具,镜子,相框;存储或运输用非金属容器;未加工或半加工的骨、角、鲸骨或珍珠母;贝壳;海泡石;黄琥珀'
      },
      {
        type: 21,
        name: "Household and kitchen utensils",
        title: '家用或厨房用器具和容器;梳子和海绵;刷子（画笔除外）制刷材料;清洁用具;未加工或半加工玻璃（建筑用玻璃除外）玻璃器皿、瓷器和陶器'
      },
      {
        type: 22,
        name: "Ropes, nets, tents, canvas",
        title: '缆和绳;网;帐篷和防水遮布;纺织品或合成纤维材料制遮篷;帆;运输和贮存散装物用麻袋;衬垫和填充材料（纸或纸板、橡胶、塑料制除外）纺织用纤维原料及其替代品'
      },
      {
        type: 23,
        name: "Yarns and threads for textile use",
        title: '纺织用纱和线'
      },
      {
        type: 24,
        name: "Textile for daily use",
        title: '织物及其替代品;家庭日用纺织品;纺织品制或塑料制帘'
      },
      {
        type: 25,
        name: "Clothing, footwear, headgear",
        title: '服装,鞋,帽'
      },
      {
        type: 26,
        name: "Lace, embroidery, false hair",
        title: '花边和刺绣品,饰带和编带;纽扣,领钩扣,饰针和缝针;假花;发饰;假发'
      },
      {
        type: 27,
        name: "Carpet, mats, wallpaper",
        title: '地毯,地席,席类,油毡及其他铺地板材料;非纺织品制墙帷'
      },
      {
        type: 28,
        name: "Games and toys",
        title: '游戏器具和玩具;视频游戏装置;体育和运动用品;圣诞树用装饰品'
      },
      {
        type: 29,
        name: "Cooked vegetables/fruits, meat/egg/cream",
        title: '肉,鱼,家禽和野味;肉汁;腌渍、冷冻、干制及煮熟的水果和蔬菜;果冻,果酱,蜜饯;蛋;奶和奶制品;食用油和油脂'
      },
      {
        type: 30,
        name: "Coffee, pastry, condiments",
        title: '咖啡,茶,可可和咖啡代用品;米;食用淀粉和西米;面粉和谷类制品;面包、糕点和甜食;食用冰;糖,蜂蜜,糖浆;鲜酵母,发酵粉;食盐;芥末;醋,沙司（调味品）辛香料;冰'
      },
      {
        type: 31,
        name: "Fresh fruit, vegetables, flowers",
        title: '未加工的农业、水产养殖业、园艺、林业产品;未加工的谷物和种子;新鲜水果和蔬菜,新鲜芳香草本植物;草木和花卉;种植用球茎、幼苗和种子;活动物;动物的饮食;麦芽'
      },
      {
        type: 32,
        name: "Beer, beverages, syrups",
        title: '啤酒;矿泉水和汽水以及其他不含酒精的饮料;水果饮料及果汁;糖浆及其他制饮料用的制剂'
      },
      {
        type: 33,
        name: "Alcohol",
        title: '含酒精的饮料（啤酒除外）'
      },
      {
        type: 34,
        name: "Tobacco, smoking articles",
        title: '烟草;烟具;火柴'
      },
      {
        type: 35,
        name: "Advertising, office affairs",
        title: '广告;商业经营;商业管理;办公事务'
      },
      {
        type: 36,
        name: "Insurance, financial affairs",
        title: '保险;金融事务;货币事务;不动产事务'
      },
      {
        type: 37,
        name: "Construction, repair, installation",
        title: '房屋建筑;修理;安装服务'
      },
      {
        type: 38,
        name: "Telecommunications",
        title: '电信'
      },
      {
        type: 39,
        name: "Transport, storage",
        title: '运输;商品包装和贮藏;旅行安排'
      },
      {
        type: 40,
        name: "Material treatment",
        title: '材料处理'
      },
      {
        type: 41,
        name: "Education, entertainment",
        title: '教育;提供培训;娱乐;文体活动'
      },
      {
        type: 42,
        name: "Design, consulting, development",
        title: '科学技术服务和与之相关的研究与设计服务;工业分析与研究;计算机硬件与软件的设计与开发'
      },
      {
        type: 43,
        name: "Food, accommodation, nursing",
        title: '提供食物和饮料服务;临时住宿'
      },
      {
        type: 44,
        name: "Medical, beauty, horticulture services",
        title: '医疗服务;兽医服务;人或动物的卫生和美容服务;农业、园艺和林业服务'
      },
      {
        type: 45,
        name: "Legal, security services",
        title: '法律服务;为有形财产和个人提供实体保护的安全服务;由他人提供的为满足个人需要的私人和社会服务'
      }
    ],
    // 亚洲
    "cityOptions": [
      {
        city: 'Mainland China',
        code: 'CN',
        ingSrc: '/images/national/zgdl.jpg'
      },
      {
        city: 'Indonesia',
        code: 'ID',
        ingSrc: '/images/national/ydnxy.jpg'
      },
      {
        city: 'India',
        code: 'IN',
        ingSrc: '/images/national/yd.jpg'
      },
      {
        city: 'Japan',
        code: 'JP',
        ingSrc: '/images/national/rb.jpg'
      },
      {
        city: 'Turkey',
        code: 'TR',
        ingSrc: '/images/national/trq.jpg'
      },
      {
        city: 'Hong Kong,China',
        code: 'HK',
        ingSrc: '/images/national/zgxg.jpg'
      },
      {
        city: 'Korea',
        code: 'KR',
        ingSrc: '/images/national/hg.jpg'
      },
      {
        city: 'Macao,China',
        code: 'MO',
        ingSrc: '/images/national/am.jpg'
      },
      {
        city: 'Malaysia',
        code: 'MY',
        ingSrc: '/images/national/mlxy.jpg'
      },
      {
        city: 'Philippines',
        code: 'PH',
        ingSrc: '/images/national/flb.jpg'
      },
      {
        city: 'Singapore',
        code: 'SG',
        ingSrc: '/images/national/xjp.jpg'
      },
      {
        city: 'Taiwan Province of China',
        code: 'TW',
        ingSrc: '/images/national/zgtw.png'
      },
      {
        city: 'Thailand',
        code: 'TH',
        ingSrc: '/images/national/tgs.jpg'
      },
      {
        city: 'United Arab Emirates',
        code: 'AE',
        ingSrc: '/images/national/alq.jpg'
      }
    ],
    // 欧洲
    "city": [
      {
        city: 'Austria',
        code: 'AT',
        ingSrc: '/images/national/adl.JPG'
      },
      {
        city: 'Switzerland',
        code: 'CH',
        ingSrc: '/images/national/rs.jpg'
      },
      {
        city: 'Germany',
        code: 'DE',
        ingSrc: '/images/national/dg.jpg'
      },
      {
        city: 'Denmark',
        code: 'DK',
        ingSrc: '/images/national/dm.jpg'
      },
      {
        city: 'Norway',
        code: 'NO',
        ingSrc: '/images/national/nw.jpg'
      },
      {
        city: 'Spain',
        code: 'ES',
        ingSrc: '/images/national/xby.jpg'
      },
      {
        city: 'EUIPO',
        code: 'EU',
        ingSrc: '/images/national/om.jpg'
      },
      {
        city: 'Finland',
        code: 'FI',
        ingSrc: '/images/national/fl.jpg'
      },
      {
        city: 'France',
        code: 'FR',
        ingSrc: '/images/national/fg.jpg'
      },
      {
        city: 'Grate Britain',
        code: 'GB',
        ingSrc: '/images/national/yg.jpg'
      },
      {
        city: 'Portugal',
        code: 'PT',
        ingSrc: '/images/national/pty.jpg'
      },
      {
        city: 'Greece',
        code: 'GR',
        ingSrc: '/images/national/xl.jpg'
      },
      {
        city: 'Hungary',
        code: 'HU',
        ingSrc: '/images/national/xyl.jpg'
      },
      {
        city: 'Ireland',
        code: 'IE',
        ingSrc: '/images/national/ael.jpg'
      },
      {
        city: 'Iceland',
        code: 'IS',
        ingSrc: '/images/national/bd.jpg'
      },
      {
        city: 'Italy',
        code: 'IT',
        ingSrc: '/images/national/ydl.jpg'
      },
      {
        city: 'Sweden',
        code: 'SE',
        ingSrc: '/images/national/rd.jpg'
      },
      {
        city: 'Russia',
        code: 'RU',
        ingSrc: '/images/national/elss.jpg'
      },
      {
        city: 'Benelux',
        code: 'BX',
        ingSrc: '/images/national/bhl.png'
      }
    ],
    // 北美洲
    "cityOptionsd": [
      {
        city: 'Canada',
        code: 'CA',
        ingSrc: '/images/national/jnd.jpg'
      },
      {
        city: 'Mexico',
        code: 'MX',
        ingSrc: '/images/national/mxg.jpg'
      },
      {
        city: 'USPTO',
        code: 'US',
        ingSrc: '/images/national/mg.jpg'
      },
    ],
    // 南美洲
    "cityOptionsds": [
      {
        city: 'Argentina',
        code: 'AR',
        ingSrc: '/images/national/agt.jpg'
      },
      {
        city: 'Brazil',
        code: 'BR',
        ingSrc: '/images/national/bx.jpg'
      },
      {
        city: 'Chile',
        code: 'CL',
        ingSrc: '/images/national/zl.jpg'
      },
    ],
    // 非洲
    "cityOpts": [
      {
        city: 'South Africa',
        code: 'ZA',
        ingSrc: '/images/national/nf.jpg'
      }
    ],
    // 大洋洲
    "cityds": [
      {
        city: 'Australia',
        code: 'AU',
        ingSrc: '/images/national/adly.jpg'
      },
      {
        city: 'New Zealand',
        code: 'NZ',
        ingSrc: '/images/national/xxl.jpg'
      }
    ],
    //知识产权组织
    "propertyOrganization": [
      {
        city: 'WIPO',
        code: 'WO',
        ingSrc: '/images/national/zscq.jpg'
      }
    ],
    "query_Items": [
      //动态新增 查询下拉列表
      {
        tmParams: "",
        input: "",
        options: [
          //   {
          //   value: "SBMC",
          //   label: "Trademark text",
          //   disabled: false
          // },
          //   {
          //     value: "SBTP",
          //     label: "商标图片",
          //     disabled: false
          //   },
          {
            value: "SQRMC",
            label: "Applicant Name",
            disabled: false
          },
          {
            value: "SQRDZ",
            label: "Applicant Address",
            disabled: false
          },
          {
            value: "SQH",
            label: "Application Number",
            disabled: false
          },
          // {
          //   value: "LB",
          //   label: "类别",
          //   disabled: false
          // },
          // {
          //     value: "TXYSBM",
          //     label: "Vienna code",
          //     disabled: false
          // },
          {
            value: "SQR",
            label: "Application Date",
            disabled: false
          },
          {
            value: "ZCR",
            label: "Registration Date",
            disabled: false
          },
          {
            value: "CSGGR",
            label: "Preliminary Announcement Date",
            disabled: false
          },
          //{
          //   value: "ZCGGR",
          //  label: "注册公告日",
          //  disabled: false
          //},
          {
            value: "ZYQDQR",
            label: "Expiration date",
            disabled: false
          },
          {
            value: "YXQR",
            label: "Priority date",
            disabled: false
          },
          // {
          //  value: "DLJG",
          //  label: "Agencies",
          //   disabled: false
          // },
          {
            value: 'ZCH',
            label: 'Registration number',
            disabled: false
          },
          {
            value: "SBZT",
            label: "Status",
            disabled: false
          },
          {
            value: "SFGYSB",
            label: "Jointly owned mark",
            disabled: false
          },
          {
            value: "SFYX",
            label: "Isvalid",
            disabled: false
          }
        ]
      }
    ],
    //提示
    Tips: ['Please upload pictures', 'Please enter text', 'Please enter text or upload pictures']
  },
  //商标查询详情——中国大陆
  "tra_detail_cn": {
    'btnPdf': 'Pdf Export',
    'leftLi': ['Graphic', 'Application Date', 'International Registration Date', 'Post-designated Date', 'Priority Date', 'Preliminary Announcement Date', 'Registration Dates', 'Duration of exclusivity'],
    'classTable': ['Goods/Services Class', 'Similar Groups', 'Goods/Services', 'Business Name', 'Business Node Name', '', '']
  },
  //商标查询详情——国外
  "tra_detail_en": {
    'btnPdf': 'Pdf Export',
    'leftLi': ['Graphic', 'Application Number', 'Registration Number', 'Preliminary announcement period number', 'Application Date', 'International Registration Date', 'Post-designated date', 'Priority Date', 'Preliminary Announcement Date', 'Registration Date', 'Duration of exclusivity'],
    'classTable': ['Applicant Information', '办案国/地区', '指定国', 'Contact information', 'Contact Name', 'Contact address', 'Goods/Services Class', 'Goods/Services', '查看更多', 'Status'],
    'seniority_box': ['先前权', '国家', 'Application Number', 'Registration Number', 'Priority Date', 'Application Date', 'Registration Date', '国际代码', 'Status', 'Process', '', ''],
    'fild': ['Countries/Regions', "Trademark names", "Trademark Type", 'Expiration date', 'Registration Dates', 'Applicant\'s Nationality', 'Applicant Name', 'Applicant Address'],
    'fild_1': ['指定国', 'Contact Nationality']
  },
  //专利翻译API
  "psApi": {
    "bannerTitle1": "Patent translation API",
    'describe': "High-quality translation is derived from the professional cleaning of 120 million global patent data and the extraction of 50 million+ training corpus.",
    'button': "View Documents",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our patent translation products are based on 120 million global patent database and patent agency industry patent translation database for more than 20 years, more than 50 million corpus data extraction, through neural network machine learning algorithm, training AI translation engine applicable to patent application documents, OA responses, comparison documents, invalidation documents and patent references and other patent fields, the result sentence style is more in line with the patent industry characteristics.\n' +
        'Our patent translation products are based on 120 million entries in global patent databases and patent agency industry patent translation databases of more than 20 years, with more than 50 million corpus data extracted. Through neural network machine learning algorithms, training of the AI engine is specified to the patent field, using patent application documents, OA responses, comparison documents, invalidation documents, and patent references as training materials. The resulting sentence patterns are specific to the patent industry.\n' +
        'Currently, our tool supports two language pairs: Chinese-English, English-Chinese, Chinese-Japanese, and Japanese-Chinese.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: '',
        crux: " Ten million ",
        value_2: 'training corpus'
      },
        {
          value_1: '',
          crux: " 20 years of ",
          value_2: 'industry experience'
        },
        {
          value_1: 'Flexible and',
          crux: " diverse access ",
          value_2: 'services'
        },
        {
          value_1: 'Continuous',
          crux: " updates ",
          value_2: 'and iterations'
        }
      ]
    },
    'examples': {
      title: "Product Examples",
      lun: [{
        imgSrc: '/images/index/search_en.png',
        name: 'Sample Patent Translation'
      }]
    },
    'process': {
      url: require("@/assets/images/patent/process.jpg"),
      title: "Access Process",
      content: ['Login', 'Login now', 'Contact us', 'Set up account，Create  service', 'Get API key', ' Open personal center, Account information', 'Develop and debug', 'View API documents', 'Access successful']
    },
    'price': {
      title: "Product pricing",
      listData: [{
        categoryCode: "shangbiaoyewu",
        categoryName: "商标业务",
        currency: "CNY",
        existApply: false,
        imageUrl: "patent_translate.png",
        modeType: "4",
        price: '全球数据 周期自选',
        productName: "Patent translation",
        publishType: "4",
        serviceDesc: "Online application/API",
        button: true,
        btnName: "purchasing",
        serviceName: "Patent translation"
      }],
    },
    'cancat': 'Contact us'
  },
  //专利管理系统
  "PMS": {
    "bannerTitle1": "Patent management system",
    'describe': "For the patent agency,   The entire case life cycle management process",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our patent management system is a patent case business management system for agencies at different stages of development.',
      'describe2': 'Features: customizable standard process management, Seamless docking to the official system, Docking to CPC automatic receipt of documents, Intelligent task flow triggering, Internal case audit information; Flexible configuration, Efficient management of various types of patent cases, Automatic deadline monitoring, Flexible letter template configuration, Multi-user management, Multi-dimensional comprehensive search, Detailed custom search.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'Entire life cycle management'
      },
        {
          value_1: 'Flexible configuration'
        },
        {
          value_1: 'Automation'
        },
        {
          value_1: 'Standardization'
        }
      ]
    },
    'price': {
      title: "Product pricing",
      listData: [{
        categoryCode: "shangbiaoyewu",
        categoryName: "商标业务",
        currency: "CNY",
        existApply: false,
        imageUrl: "patent_mgr_system.png",
        modeType: "4",
        price: '全球数据 周期自选',
        productName: "Patent translation",
        publishType: "4",
        serviceDesc: "Process management",
        button: true,
        btnName: "purchasing",
        serviceName: "patent management system"
      }],
    },
    'cancat': 'Contact us'
  },
  //专利年费管理系统
  "PAMS": {
    "bannerTitle1": "Patent annuity management system",
    'describe': "Entire life cycle of patent annuity payment and payment deadline management",
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Our annuity management system is a system that can be used alone or with a patent management system to complete the management and monitoring of domestic and foreign patent annuities.',
      'describe2': 'Features: automatic annuity deadline monitoring and reminders, automatic annuity calculation, automatic generation of fee payment report, foreign annuity custom configuration, annuity data batch import and export.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'Custom domestic and foreign annuity configuration'
      },
        {
          value_1: 'automatic monitoring of payment reminders'
        },
        {
          value_1: 'automatic generation of annuity reports'
        },
        {
          value_1: 'batch import and export of annuity data'
        }
      ]
    },
    'price': {
      title: "Product pricing",
      listData: [{
        categoryCode: "shangbiaoyewu",
        categoryName: "商标业务",
        currency: "CNY",
        existApply: false,
        imageUrl: "annual_fee_mgr_system.png",
        modeType: "4",
        price: '全球数据 周期自选',
        productName: "Patent translation",
        publishType: "4",
        serviceDesc: "Process management",
        button: true,
        btnName: "purchasing",
        serviceName: "Patent annuity management system"
      }],
    },
    'cancat': 'Contact us'
  },
  //蓝灯鱼机翻Trados（2019版）插件
  "Trados": {
    "bannerTitle1": "Lanternfish’s CAT Trados (2019 version) plug-in",
    'describe': "Lanternfish’s CAT plug-in based on SDL Trados Studio, which enables Trados software to directly access Lanternfish's CAT translation engine",
    'button': 'Download the installation package',
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Lanternfish\'s CAT Trados Plugin is a CAT plug-in based on SDL Trados Studio, which enables Trados software to directly access the CAT translation engine. The plug-in enables low-cost and fast access to machine translation results in the process of translating patent documents and enables batch translation in multiple languages.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'Improved translation efficiency'
      },
        {
          value_1: 'Reduced translation cost'
        },
        {
          value_1: 'Compatibility'
        },
        {
          value_1: 'Free delivery of 100,000 words'
        }
      ]
    },
    'productAcquisition': ['Purchase', 'Lanternfish’s CAT Trados (2019 version) plug-in', 'Download the installation package'],
    'cancat': 'Contact us'
  },
  //蓝灯鱼机翻word插件
  "TradosWord": {
    "bannerTitle1": "Lanternfish CAT Word plug-in",
    'describe': "Based on Microsoft Office Word CAT plug-in, the plug-in allows Word to directly access Lanternfish's CAT translation engine",
    'button': 'Download the installation package',
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Lanternfish\'s CAT Word plug-in is a CAT plug-in based on Microsoft Office Word whichallows Word to directly access Lanternfish\'s CAT translation engine. The plug-in can quickly obtain machine translation results in the process of translating patent documents at low cost, and realize batch translation of multiple languages.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'Improved translation efficiency'
      },
        {
          value_1: 'Reduced translation cost'
        },
        {
          value_1: 'Compatibility'
        },
        {
          value_1: 'Free delivery of 100,000 words'
        }
      ]
    },
    'productAcquisition': ['Purchase', 'Computer-aided translation Microsoft Word plug-in', 'Download the installation package'],
    'cancat': 'Contact us'
  },
  //蓝灯鱼机翻Excel插件
  "Excel": {
    "bannerTitle1": "CAT Excel plug-in",
    'describe': "Based on Microsoft Office Excel CAT plug-in the plug-in allows Word to directly access Lanternfish's CAT translation engine.",
    'button': 'Download the installation package',
    'introduction': {
      "title": "Product introduction",
      'describe1': 'Lanternfish\'s CAT Excel plug-in is a CAT plug-in based on Microsoft Office Excel, allows Excel to directly access Lanternfish\'s CAT translation engine. This plug-in can quickly obtain machine translation results in the process of translating patent documents at low cost, and realize batch translation of multiple languages.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'Improved translation efficiency'
      },
        {
          value_1: 'Reduced translation cost'
        },
        {
          value_1: 'Compatibility'
        },
        {
          value_1: 'Free delivery of 100,000 words'
        }
      ]
    },
    'productAcquisition': ['Purchase', 'CAT Excel plug-in', 'Download the installation package'],
    'cancat': 'Contact us'
  },
  //专利五书拆分
  "SPB": {
    "bannerTitle1": "Automatic splitting of patent documents",
    'describe': "Automatic splitting of patent application documents to meet the submission requirements of CNIPA's CPC system",
    'button': 'Download the installation package',
    'introduction': {
      "title": "Product introduction",
      'describe1': 'This is an efficiency tool that supports the automatic splitting of the Word files of patent application documents into 5 PDF files that meet the submission requirements of the CPC system.',
      'describe2': 'This tool can be used by patent agencies as well as businesses, universities and research institutions which file patent applications and submit applications on their own.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'One-click splitting'
      },
        {
          value_1: 'Conversion to PDF format'
        },
        {
          value_1: 'Automatic naming'
        },
        {
          value_1: 'Efficient and convenient'
        }
      ]
    },
    'productAcquisition': ['Purchase', '', 'Automatic splitting of patent application documents to meet the submission requirements of CNIPA\'s CPC system', 'Download the installation package'],
    'cancat': 'Contact us'
  },
  //专利CPC附图一键压缩
  "patentCPC": {
    "bannerTitle1": "Patent CPC one-click attachment compression",
    'describe': "Automatic compression of the patent attachment",
    'button': 'Download the installation package',
    'introduction': {
      "title": "Product introduction",
      'describe1': 'This is an efficiency tool for automatically compressing and generating qualified pictures when the attached pictures in a patent application do not meet the requirements of CNIPA\'s CPC system submission.',
      'describe2': 'This tool is suitable for patent agencies, businesses, universities and scientific research institutions to deal with the size of the accompanying drawings in patent application documents.'
    },
    'advantage': {
      title: 'Product advantages',
      img: require('@/assets/images/patent/introduction1.png'),
      'advantage_content': [{
        value_1: 'Selecting compression requirements by patent type'
      },
        {
          value_1: 'Pixels can be scaled to size'
        },
        {
          value_1: 'Automatic PDF conversion'
        },
        {
          value_1: 'Easy to use'
        }
      ]
    },
    'productAcquisition': ['Purchase', '', 'Automatic compression of the patent attachment', 'Download the installation package'],
    'cancat': 'Contact us'
  },
  //数据协作平台
  "collaborationPlatform": {
    "bannerTitle1": "Data collaboration platform",
    'describe': "Intelligent IP, data connectivity",
    'introduction': {
      "title": "Data collaboration platform",
      'describe': "We support customized platforms and cloud disks for enterprises based on global trademark data and 120 million patent data.",
    },
    type: [
      // {
      //       path: "/smartPark",
      //       url: require('@/assets/images/cancat/data1.jpg'),
      //       name: '智慧园区'
      //   },
      {
        path: "/enterpriseNetwork",
        url: require('@/assets/images/cancat/data3.jpg'),
        name: 'Customized service for enterprise network disk'
      }
    ]
  },
  //智慧园区
  "smartPark": {
    "bannerTitle1": "智慧园区",
    'describe': "实时监控园区企业知识产权动态",
    'button': '立即了解',
    'introduction': {
      "title": "Product introduction",
      'describe': "蓝灯鱼智慧园区管理平台是一款实时监控园区企业知识产权动态，园区知识产权保护态势，可用于通过分析园区企业知识产权情况，为园区知识产权服务提供管理工具。",
    },
    advantage: [
      'Product advantages',
      '专注知识产权管理和服务',
      '基于大数据和AI技术',
      '全面整合供需方业务需求',
      '平台交互、方便快捷',
      '知识产权的产出管理、服务对接和实时统计监控。'
    ],
    cancat: 'Contact us'
  },
  //企业网盘
  "enterpriseNetwork": {
    "bannerTitle1": "Enterprise network drive",
    'describe': "Cloud sharing, secure collaboration",
    'introduction': {
      "title": "Product introduction",
      'describe1': "Our enterprise network drive provides document cloud management subscription service. Through safe and convenient storage, access and sharing, it helps businesses achieve comprehensive cloud management of files.",
      'describe2': "Our business network drive can integrate and empower the file capability to the existing information systems, such as email, OA, ERP, etc., to become the businesses’ file portal, and precipitate daily office files in each system. For different tasks, metadata can be configured according to different attributes of existing business systems, so that different folders have different business attributes and realize the automatic docking and information storage of uploaded files between the existing business system and the network disk.",
    },
    advantage: [
      'Product advantages',
      'Share and collaborate with flexible permissions',
      'Efficient management of file preview/upload/download/deletion and other permissions',
      'Real-time synchronization and sharing of information, improve the efficiency of data and information flow, efficient team collaboration.',
      'Strong search, fast positioning',
      'Support keyword or folder name search, quickly locate the target file',
      'Storage encryption, data security',
      'Transferring full-link encryption, file security',
      'Login address restriction, access security',
      'Businesssubscription, Comprehensive record',
      'Customized organizational structure management',
      'Comprehensive record of information, collaborators can view the operation log'
    ],
    cancat: 'Contact us'
  },
  //企业动态
  "TD": {
    "bannerTitle1": "News"
  },
  //关于我们
  'AU': {
    "bannerTitle1": "Lanternfish",
    "content": {
      title: 'Firm Introduction',
      describe: "Beijing Lanternfish Intelligent Technology Co., Ltd. (Hereinafter referred to as \"Lanternfish\"), Founded in 2018, Committed to empowering the intellectual property industry with the power of digital technology through the exploration of artificial intelligence and big data.\n" +
        "Lanternfish's products and services to owns and use intellectual property users worldwide, based on global patents, trademark data, based on large data mining, artificial intelligence deep learning algorithm and user industry characteristics, easy-to-use convenient Intellectual property value added and efficiency products.\n" +
        "Lanternfish® (www.lanternfish.cn), providing products and services include:Trademark AI graphic query products, Patent AI translation products, Patented retrieval, Patent management system, Trademark management system, Patent annual fee management system, Trademark official Automatic submission tool, Patent CPC drawings one-button compression tool, Patent five book split tool, Smart park management platform and other intellectual property series software products."
    },
    cancat: {
      title: "Contact us",
      cancat_cen: [{
        url: require('@/assets/images/cancat/name.jpg'),
        key: "Corporate Name：",
        value: 'Beijing Lanternfish Intelligent Technology Co., Ltd.'
      },
        {
          url: require('@/assets/images/cancat/address.jpg'),
          key: "Company Address：",
          value: '17C, 14th Floor, Unit 3, Building 3, No. 48, Zhichun Road, Haidian District, Beijing China'
        },
        {
          url: require('@/assets/images/cancat/iphone.jpg'),
          key: "Contact Number：",
          value: '(8610)58457888'
        },
        {
          url: require('@/assets/images/cancat/email.jpg'),
          key: "Customer Service Email：",
          value: 'service@lanternfish.cn'
        },
        {
          url: require('@/assets/images/cancat/scanning.jpg'),
          key: "",
          value: 'Add our enterprise wechat and contact us'
        }
      ],
      join: ['Join Us', 'Recruitment Information']
    }
  },
  //商标检索
  "PS": {
    "batch_search_con": ['', '', 'Download'],
    "batchFile": ['Please enter the text', 'Please enter key words, a paragraph, application number, etc.', 'Please enter the number, not exceeding 2000 items'],
    "advanced_search_wrap": ['Application number', '', 'Application date', 'Start date', 'End date', 'Publication number', 'Publication date'],
    "advancedTitle": ['Title/Summary', '', 'Legal status', 'Please select', 'Applicant', '', 'Inventor', '', 'Agency', '', 'Agent', '', 'IPC classification', '', 'Template download', 'Supported formats', '', ''],
    "batchSearchType": ['Application number', 'Publication number', "Please separate the numbers with a new line, comma or space", 'clear', 'Retrieval', 'Please select', '', 'Add a new field'],
    'advanced_recored_con': ['Number', 'Expression name', 'Retrieve database', 'Number of hits', 'Retrieval time', 'Operation', 'Delete', 'Retrieve records'],
    "searchTypeArr": [
      {
        name: 'Semantic search',
        currentTypeBool: true,
        type: 1
      },
      {
        name: 'Simple search',
        currentTypeBool: false,
        type: 2
      },
      {
        name: 'Advanced search',
        currentTypeBool: false,
        type: 3
      }, {
        name: 'Batch search',
        currentTypeBool: false,
        type: 4
      }
    ],
    "literatureArr": [
      {
        name: "Keyword",
        type: "input_keywords",
        list: [
          {
            name: "Title/Summary",
            value: "not_cut_word"
          }
        ]
      },
      {
        name: "Number",
        type: "input",
        list: [
          {
            name: "Application number",
            value: "apply_no_all"
          },
          {
            name: "Publication number",
            value: "pub_no_all"
          }
        ]
      },
      {
        name: "Date",
        type: "date",
        list: [
          {
            name: "Application date",
            value: "apply_date"
          },
          {
            name: "Publication date",
            value: "pub_date"
          }
        ]
      }
    ],
    "advancedLegalArr": ["全部", "有效", "无效", "审中", "未确认"]
  },
  //商标检索列表页
  "PS_list": {
    'input_con': ['请输入一段话…', '请输入关键词或一段话…'],
    "none": '暂无',
    'add_keywords_select': '请选择',
    'add_keywords_title': ['例如：手机 通讯', '例如：CN123456 CN234567', '开始日期', '结束日期', '新增栏位', '关键词', '添加关键词', '全部', '更多', '筛选'],
    'list_con_middle_fixed': ['有效', '审核中', '无效', '未知', '相关度'],
    'des_info': ['公开号', '申请号', '发明人', '申请人', '申请日期', '专利度', '独权度', '方法度', '特征度'],
    'list_table': ['序号', '公开号', '标题', '公开日', '申请号', '申请日', '法律状态', '自救中，暂无结果'],
    'list_right_tab': ['公开号', '公开/公告日', '申请号', '申请日期', '申请人', '发明人', '专利类型', 'IPC分类号', '优先权国家', '优先权日', '摘要', '权利要求', '说明书'],
    'literatureArr': [
      {
        name: "关键词",
        type: "input_keywords",
        list: [
          {
            name: "标题/摘要",
            value: "not_cut_word"
          }
        ]
      },
      {
        name: "号码",
        type: "input",
        list: [
          {
            name: "申请号",
            value: "apply_no_all"
          },
          {
            name: "公开号",
            value: "pub_no_all"
          }
        ]
      },
      {
        name: "日期",
        type: "date",
        list: [
          {
            name: "申请日",
            value: "apply_date"
          },
          {
            name: "公开日",
            value: "pub_date"
          }
        ]
      }
    ],
    'detailTab': [
      {
        name: "著录项目"
      },
      {
        name: "权利要求"
      },
      {
        name: "说明书"
      },
      {
        name: "附图"
      }
    ]
  },
  //商标检索详情页
  'PS_detail': {
    'list_rigth_title': ['有效', '审核中', '无效', '未知'],
    'list_right_tab_con': ['公开号', '公开/公告日', '申请号', '申请日期', '申请人', '发明人', '专利类型', 'IPC分类号', '优先权国家', '优先权日', '摘要', '全文', '权利要求', '说明书', '附图'],
    'detailTab': [
      {
        name: "著录项目"
      },
      {
        name: "权利要求"
      },
      {
        name: "说明书"
      },
      {
        name: "附图"
      }
    ]
  },
  //支持国家
  'supportCountry': ['Search Supported Country/Region', 'Country/Region', 'Please select', 'Search', 'Reset', 'Recently Update Date', 'Filling Date Begin', 'Filling Date End'],
  //可注册性分析报告
  "analysisReport": {
    top_title: ['Registration Analysis Report', 'This report is machine analysis and is for reference only'],
    part1_title: ['Overview of registrability', 'Query trademark'],
    result_title: ['High registration rate', 'Active registration', 'Medium registration rate', 'Try to register', 'Low registration rate', 'Careful registration'],
    proposal_title: ['Suggestions on registrability', 'Class', 'abbreviation', 'title', 'Registration success rate'],
    dataType: [
      {
        type: 1,
        name: "Industrial chemicals",
        title: '用于工业、科学、摄影、农业、园艺和林业的化学品;未加工人造合成树脂,未加工塑料物质;肥料;灭火用合成物;淬火和焊接用制剂;保存食品用化学品;鞣料;工业用粘合剂'
      },
      {
        type: 2,
        name: "Pigments, paints, resin",
        title: '颜料,清漆,漆;防锈剂和木材防腐剂;着色剂;媒染剂;未加工的天然树脂;绘画、装饰、印刷和艺术用金属箔及金属粉'
      },
      {
        type: 3,
        name: "Cosmetics, cleaning products",
        title: '洗衣用漂白剂及其他物料;清洁、擦亮、去渍及研磨用制剂;不含药物的肥皂;香料,香精油,不含药物的化妆品,不含药物的润发乳;不含药物的牙膏'
      },
      {
        type: 4,
        name: "Industrial oils and fuels",
        title: '工业用油和油脂;润滑剂;吸收、润湿和粘结灰尘用合成物;燃料（包括马达用燃料）和照明材料;照明用蜡烛和灯芯'
      },
      {
        type: 5,
        name: "Pharamaceuticals",
        title: '药品,医用和兽医用制剂;医用卫生制剂;医用或兽医用营养食物和物质,婴儿食品;人用和动物用膳食补充剂;膏药,绷敷材料;填塞牙孔用料,牙科用蜡;消毒剂;消灭有害动物制剂;杀真菌剂,除莠剂'
      },
      {
        type: 6,
        name: "Metals",
        title: '普通金属及其合金,金属矿石;金属建筑材料;可移动金属建筑物;普通金属制非电气用缆线;金属小五金具;存储和运输用金属容器;保险箱'
      },
      {
        type: 7,
        name: "Machines and machine tools",
        title: '机器和机床;马达和引擎（陆地车辆用的除外）机器联结器和传动机件（陆地车辆用的除外）非手动农业器具;孵化器;自动售货机'
      },
      {
        type: 8,
        name: "Cutlery, hand tools",
        title: '手工具和器具（手动的）刀、叉和勺餐具;随身武器;剃刀'
      },
      {
        type: 9,
        name: "Scientific, electronic devices",
        title: '科学、航海、测量、摄影、电影、光学、衡具、量具、信号、检验（监督）、救护（营救）和教学用装置及仪器;处理、开关、转换、积累、调节或控制电的装置和仪器;录制、传送、重放声音或影像的装置;磁性数据载体,录音盘;光盘,DVD 盘和其他数字存储媒介;投币启动装置的机械结构;收银机,计算机器,数据处理装置,计算机;计算机软件;灭火设备'
      },
      {
        type: 10,
        name: "Medical and nursing apparatus",
        title: '外科、医疗、牙科和兽医用仪器及器械;假肢,假眼和假牙;矫形用物品;缝合用材料;残疾人专用治疗装置;按摩器械;婴儿护理用器械、器具及用品;性生活用器械、器具及用品'
      },
      {
        type: 11,
        name: "Lights, AC, heating, santiaries",
        title: '照明、加热、蒸汽发生、烹饪、冷藏、干燥、通风、供水以及卫生用装置'
      },
      {
        type: 12,
        name: "Vehicles",
        title: '运载工具;陆、空、海用运载装置'
      },
      {
        type: 13,
        name: "Firearms, fireworks",
        title: '火器;军火及弹药;爆炸物;烟火'
      },
      {
        type: 14,
        name: "Jewelry, precious stones, watches",
        title: '贵重金属及其合金;首饰,宝石和半宝石;钟表和计时仪器'
      },
      {
        type: 15,
        name: "Musical instruments",
        title: '乐器'
      },
      {
        type: 16,
        name: "Office supplies, teaching materials",
        title: '纸和纸板;印刷品;书籍装订材料;照片;文具和办公用品（家具除外）文具用或家庭用粘合剂;艺术家用或绘画用材料;画笔;教育或教学用品;包装和打包用塑料纸、塑料膜和塑料袋;印刷铅字,印版'
      },
      {
        type: 17,
        name: "Plastic and rubber products",
        title: '未加工和半加工的橡胶、古塔胶、树胶、石棉、云母及这些材料的代用品;生产用成型塑料和树脂制品;包装、填充和绝缘用材料;非金属软管和非金属柔性管'
      },
      {
        type: 18,
        name: "Leather products ",
        title: '皮革和人造皮革;毛皮;行李箱和背包;雨伞和阳伞;手杖;鞭、马具和鞍具;动物用项圈、皮带和衣服'
      },
      {
        type: 19,
        name: "Non-metallic building materials",
        title: '非金属的建筑材料;建筑用非金属刚性管;柏油,沥青;可移动非金属建筑物;非金属碑'
      },
      {
        type: 20,
        name: "Furnitures, containers, bone and horn",
        title: '家具,镜子,相框;存储或运输用非金属容器;未加工或半加工的骨、角、鲸骨或珍珠母;贝壳;海泡石;黄琥珀'
      },
      {
        type: 21,
        name: "Household and kitchen utensils",
        title: '家用或厨房用器具和容器;梳子和海绵;刷子（画笔除外）制刷材料;清洁用具;未加工或半加工玻璃（建筑用玻璃除外）玻璃器皿、瓷器和陶器'
      },
      {
        type: 22,
        name: "Ropes, nets, tents, canvas",
        title: '缆和绳;网;帐篷和防水遮布;纺织品或合成纤维材料制遮篷;帆;运输和贮存散装物用麻袋;衬垫和填充材料（纸或纸板、橡胶、塑料制除外）纺织用纤维原料及其替代品'
      },
      {
        type: 23,
        name: "Yarns and threads for textile use",
        title: '纺织用纱和线'
      },
      {
        type: 24,
        name: "Textile for daily use",
        title: '织物及其替代品;家庭日用纺织品;纺织品制或塑料制帘'
      },
      {
        type: 25,
        name: "Clothing, footwear, headgear",
        title: '服装,鞋,帽'
      },
      {
        type: 26,
        name: "Lace, embroidery, false hair",
        title: '花边和刺绣品,饰带和编带;纽扣,领钩扣,饰针和缝针;假花;发饰;假发'
      },
      {
        type: 27,
        name: "Carpet, mats, wallpaper",
        title: '地毯,地席,席类,油毡及其他铺地板材料;非纺织品制墙帷'
      },
      {
        type: 28,
        name: "Games and toys",
        title: '游戏器具和玩具;视频游戏装置;体育和运动用品;圣诞树用装饰品'
      },
      {
        type: 29,
        name: "Cooked vegetables/fruits, meat/egg/cream",
        title: '肉,鱼,家禽和野味;肉汁;腌渍、冷冻、干制及煮熟的水果和蔬菜;果冻,果酱,蜜饯;蛋;奶和奶制品;食用油和油脂'
      },
      {
        type: 30,
        name: "Coffee, pastry, condiments",
        title: '咖啡,茶,可可和咖啡代用品;米;食用淀粉和西米;面粉和谷类制品;面包、糕点和甜食;食用冰;糖,蜂蜜,糖浆;鲜酵母,发酵粉;食盐;芥末;醋,沙司（调味品）辛香料;冰'
      },
      {
        type: 31,
        name: "Fresh fruit, vegetables, flowers",
        title: '未加工的农业、水产养殖业、园艺、林业产品;未加工的谷物和种子;新鲜水果和蔬菜,新鲜芳香草本植物;草木和花卉;种植用球茎、幼苗和种子;活动物;动物的饮食;麦芽'
      },
      {
        type: 32,
        name: "Beer, beverages, syrups",
        title: '啤酒;矿泉水和汽水以及其他不含酒精的饮料;水果饮料及果汁;糖浆及其他制饮料用的制剂'
      },
      {
        type: 33,
        name: "Alcohol",
        title: '含酒精的饮料（啤酒除外）'
      },
      {
        type: 34,
        name: "Tobacco, smoking articles",
        title: '烟草;烟具;火柴'
      },
      {
        type: 35,
        name: "Advertising, office affairs",
        title: '广告;商业经营;商业管理;办公事务'
      },
      {
        type: 36,
        name: "Insurance, financial affairs",
        title: '保险;金融事务;货币事务;不动产事务'
      },
      {
        type: 37,
        name: "Construction, repair, installation",
        title: '房屋建筑;修理;安装服务'
      },
      {
        type: 38,
        name: "Telecommunications",
        title: '电信'
      },
      {
        type: 39,
        name: "Transport, storage",
        title: '运输;商品包装和贮藏;旅行安排'
      },
      {
        type: 40,
        name: "Material treatment",
        title: '材料处理'
      },
      {
        type: 41,
        name: "Education, entertainment",
        title: '教育;提供培训;娱乐;文体活动'
      },
      {
        type: 42,
        name: "Design, consulting, development",
        title: '科学技术服务和与之相关的研究与设计服务;工业分析与研究;计算机硬件与软件的设计与开发'
      },
      {
        type: 43,
        name: "Food, accommodation, nursing",
        title: '提供食物和饮料服务;临时住宿'
      },
      {
        type: 44,
        name: "Medical, beauty, horticulture services",
        title: '医疗服务;兽医服务;人或动物的卫生和美容服务;农业、园艺和林业服务'
      },
      {
        type: 45,
        name: "Legal, security services",
        title: '法律服务;为有形财产和个人提供实体保护的安全服务;由他人提供的为满足个人需要的私人和社会服务'
      }
    ],
  },
  "personalCenter": {
    "slideBar": [
      {
        "text": "Account information",
        "path": "/personalCenter/account"
      },
      {
        "text": "Purchase services",
        "path": "/services"
      },
      {
        "text": "My services",
        "path": "/personalCenter/myServer"
      },
      {
        "text": "My orders",
        "path": "/personalCenter/myOrder"
      },
      {
        "text": "My invoices",
        "path": "/personalCenter/myInvoice"
      },
      {
        "text": "Trademark log",
        "path": "/personalCenter/trademarkLog"
      },
      {
        "text": "Translation log",
        "path": "/personalCenter/tLog"
      }
      // {
      //     "text": "专利日志",
      //     "path": "/personalCenter/pLog"
      // }
    ],
    "account": {
      "setPassword": "Set password",
      "getKeyEvent": "获取API密钥",
      "headImgBtn": "更换图片",
      "contactTitle": "Account information",
      "contactRightTitle": "申请服务",
      "contactAddBtn": "添加",
      "contactEditBtn": "编辑",
      "writeTitle": "填写",
      "account_key": ['商户ID', '请求密钥'],
      "product_title": 'Product service',
      "WElogin": "请使用微信扫描二维码登录",
      "form": {
        "userName": {
          "label": "Name",
          "placeholder": "请填写您的真实姓名",
          "errorMessage": "请填写您的真实姓名"
        },
        "companyName": {
          "label": "Full name of company",
          "placeholder": "为方便认证，请与营业执照全称一致",
          "errorMessage": "请填写您的公司/企业全称"
        },
        "industry": {
          "label": "所属行业",
          "placeholder": "请输入所属行业",
          "errorMessage": "请输入所属行业"
        },
        "email": {
          "label": "E-mail",
          "placeholder": "请填写您的常用邮箱",
          "errorMessage": "邮箱错误"
        },
        "phone": {
          "label": "Telephone number",
          "placeholder": "请填写常用手机号，便于我们联系到您",
          "errorMessage": "手机号错误"
        },
        "saveBtn": "Save",
        "submitBtn": "Submit"
      },
      "success": "试用申请提交成功，审核后会通知您!",
      "successBtn": "返回我的服务",
      "Product_service": [
        [
          {
            operation: '立即了解',
            subTitle: 'Trademark search',
            path: '/trademarkSearch'
          },
          {
            operation: '立即了解',
            subTitle: 'Trademark monitoring',
            path: '/SC'
          },
          {
            operation: '立即了解',
            subTitle: 'Brand protection',
            path: '/BP'
          },
          {
            operation: '立即了解',
            subTitle: 'Trademark search API',
            path: '/TI'
          },
          {
            operation: '立即了解',
            subTitle: 'Trademark management system',
            path: '/TMS'
          }
        ],
        [
          {
            operation: '立即了解',
            subTitle: 'Patent translation',
            path: '/patentTranslation'
          },
          {
            operation: '立即了解',
            subTitle: 'Patent search',
            path: '/searchTntroduction'
          },
          {
            operation: '立即了解',
            subTitle: 'Patent translation API',
            path: '/psApi'
          },
          {
            operation: '立即了解',
            subTitle: 'Patent management system',
            path: '/PMS'
          },
          {
            operation: '立即了解',
            subTitle: 'Patent annuity management system',
            path: '/PAMS'
          },
          {
            operation: '立即了解',
            subTitle: 'CAT Trados plug-in',
            path: "/Trados"
          },
          {
            operation: '立即了解',
            subTitle: 'CAT Word plug-in',
            path: "/TradosWord"
          },
          {
            operation: '立即了解',
            subTitle: 'CAT Excel plug-in',
            path: "/Excel"
          },
          {
            operation: '立即了解',
            subTitle: 'Automatic splitting of patent documents',
            path: "/SPB"
          },
          {
            operation: '立即了解',
            subTitle: 'Automatic compression of the patent attachment',
            path: "/patentCPC"
          }
        ],
        [
          // {
          //   operation:'立即了解',
          //   subTitle:'智慧园区',
          //   path: '/smartPark'
          // },
          {
            operation: '立即了解',
            subTitle: 'Customized service for enterprise network disk',
            path: '/enterpriseNetwork'
          }
        ]
      ],
      "stepsPhoneTitle": [
        "安全验证", "设置新手机号", "完成"
      ],
      "stepsWechatTitle": [
        "安全验证", "解除授权", "完成"
      ],
      "bindingWechatTitle": [
        "安全验证", "绑定微信", "完成"
      ],
      "successRevision": "修改成功",
      "successRelease": "解除成功",
      "backAccount": "返回个人中心",
      "submit": "Submit",
      "submitValida": "提交验证",
      "wechatTip1": "请使用微信扫描二维码解除授权",
      "wechatTip2": "注：解除授权后，将不能使用微信登录",
      "wechatTip3": "请使用微信扫描二维码绑定账户",
      "wechatTip4": "注：绑定账户后，将可以使用微信扫码登陆",
      "wechatLogin": '微信授权登录'
    },
    "server": {
      "tabList": [
        {
          text: "Trademark",
          isActive: true,
          typeId: 27
        },
        {
          text: "Patent",
          isActive: false,
          typeId: 28
        },
        {
          text: "Free trial",
          isActive: false,
          typeId: 26
        }
      ],
      field: ['起', '折', '元', '购买', '已申请', '申请', 'Trademark search']
    },
    "myServer": {
      tabList: [
        {
          text: "购买",
          isActive: true,
          typeId: 27,
          code: 1
        },
        {
          text: "Trial",
          isActive: false,
          typeId: 28,
          code: 2
        }],
      field: ['Service number', 'Service name', 'Start', 'Expiration', 'Usage (total)', 'Service status', 'Services in progress', 'Expired', '开启', '暂无订单，去', '购买']
    },
    "myOrder": {
      'my_order_header': 'Time range',
      'my_order_date': ['开始时间', '结束时间', 'Search'],
      'my_order_main': ['Order number', 'Created', 'Payment method', '对公支付', 'Order amount', '元', 'Status', '待审核', '订单完成', '审核拒绝'],
      'orderStatus': ['待支付', '待审核', '支付成功', '免支付', '审核拒绝', '订单完成', 'Options', '查看', '支付'],
      'no_table_data': ['暂无订单，去', '购买'],
      'report_con': ['订单信息', '订单号', 'Created', '订单状态', 'Order amount', '产品信息', 'Product name', '总次数', '总字数', '总数'],
      'report_con_item': ['次', '字', '个', '每日限制', '次', '字', '时间限制', '一周', '一个月', '三个月', '半年', '一年', '天'],
      'report_list_item': ['支付信息', '支付时间', 'Payment method', '支付金额']
    },
    "myInvoice": {
      "tabList": [
        {
          text: "Order record",
          isActive: true,
          typeId: 27,
          code: 1
        },
        {
          text: "Invoicing history",
          isActive: false,
          typeId: 28,
          code: 2
        },
        {
          text: "Invoice payable",
          isActive: false,
          typeId: 29,
          code: 3
        }],
      'my_order_main': ['申请开票', '订单类型', 'Purchase services', '支付时间', '支付金额', '元', '开票状态'],
      'addInvoice_table': ['未开票', '开票失败', '已申请', '开票中', '已邮寄', '开票失败', 'Options', '修改申请', '备注', '开票信息'],
      'tableDatas': ['抬头', '纳税人识别号', '注册地址', '注册电话', '开户银行', '银行帐户', 'Options', '暂无数据'],
      'tableceinfoData': ['Invoice payable', '提交时间', '开票金额', '元', '开票状态', '已申请', '开票中', '已邮寄', '开票失败', 'Options', '修改申请', '备注', '开票信息'],
      'forms': ['申请开票', '开票金额', '元', '抬头', '请输入账号', '提交', '重新申请', '修改信息', '默认', '暂无数据', '开票信息'],
      'form': [
        {
          label: '抬头',
          placeholder: '请输入抬头'
        },
        {
          label: '纳税人识别号',
          placeholder: '请输入纳税人识别号'
        },
        {
          label: '注册地址',
          placeholder: '请输入注册地址'
        },
        {
          label: '注册电话',
          placeholder: '请输入注册电话'
        },
        {
          label: '开户银行',
          placeholder: '请输入开户银行'
        },
        {
          label: '银行帐户',
          placeholder: '请输入银行帐户'
        }
      ],
      'message': ['请选择要申请开票的订单', '开票信息修改失败', '开票信息添加失败', '开票信息修改成功', '开票信息添加成功', '修改申请成功', '修改申请失败', '申请开票成功', '申请开票失败', '开票信息填写不全']
    },
    "trademarkLog": {
      'title': 'Time range',
      'startTime': '开始时间',
      'endTime': '结束时间',
      'query': 'Search',
      'Query_content': ['Search content', 'Time', 'Product name', 'Initiated', '网站', '接口']
    },
    "tLog": {
      'title': 'Time range',
      'startTime': '开始时间',
      'endTime': '结束时间',
      'query': 'Search',
      'Query_content': ['Translation direction', 'Translation time', 'Content type', 'Translation word count', 'Product name', 'Initiated', '网站', '接口']
    }
  },
  "legal": {
    "h1": "法律条款",
    "label": "法律",
    "links": {
      "term": "用户协议",
      "privacy": "隐私政策"
    }
  },
  "trademarkData": {
    'countryGroup': [{
      'titleText': '快速通道',
      "activeBool": true,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_China.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '中国大陆',
        'isChecked': true,
        'country': 'China'
      },
        {
          'conImg': '../../../images/trademarkData/icon_Japan.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '日本',
          'isChecked': false,
          'country': 'Japan'
        },
        {
          'conImg': '../../../images/trademarkData/icon_Britain.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '英国',
          'isChecked': false,
          'country': 'Britain'
        },
        {
          'conImg': '../../../images/trademarkData/icon_America.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '美国',
          'isChecked': false,
          'country': 'America'
        }
      ]
    }, {
      'titleText': '亚洲',
      "activeBool": false,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_China.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '中国大陆',
        'isChecked': false,
        'country': 'China'
      },
        {
          'conImg': '../../../images/trademarkData/icon_HK.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '中国香港',
          'isChecked': false,
          'country': 'HK'
        },
        {
          'conImg': '../../../images/trademarkData/icon_Taiwan.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '中国台湾',
          'isChecked': false,
          'country': 'Taiwan'
        },
        {
          'conImg': '../../../images/trademarkData/icon_Japan.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '日本',
          'isChecked': false,
          'country': 'Japan'
        }
      ]
    }, {
      'titleText': '美洲',
      "activeBool": false,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_America.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '美国',
        'isChecked': false,
        'country': 'America'
      },
        {
          'conImg': '../../../images/trademarkData/icon_Canada.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '加拿大',
          'isChecked': false,
          'country': 'Canada'
        },

      ]
    }, {
      'titleText': '欧洲',
      "activeBool": false,
      'conList': [{
        'conImg': '../../../images/trademarkData/icon_France.png',
        'checkedImg': '../../../images/trademarkData/icon_checked.png',
        'desc': '法国',
        'isChecked': false,
        'country': 'France'
      },
        {
          'conImg': '../../../images/trademarkData/icon_Germany.png',
          'checkedImg': '../../../images/trademarkData/icon_checked.png',
          'desc': '德国',
          'isChecked': false,
          'country': 'Germany'
        },

      ]
    }],
    'typeGroup': [{
      'titleText': '大数据',
      "titleImg": '../../../images/trademarkData/icon_data.png',
      'conList': [{
        'conText': '总览图',
        'conActive': false
      },
        {
          'conText': '各省10年总览图',
          'conActive': true
        },
        {
          'conText': '各省详细柱状图',
          'conActive': false
        }
      ]
    }, {
      'titleText': '查企业',
      "titleImg": '../../../images/trademarkData/icon_enterprise.png',
      'conList': [{
        'conText': '企业分析图',
        'conActive': false
      },
        {
          'conText': '企业对比图',
          'conActive': false
        },
        {
          'conText': '45类前十名企业',
          'conActive': false
        }
      ]
    }, {
      'titleText': '查代理所',
      "titleImg": '../../../images/trademarkData/icon_agency.png',
      'conList': [{
        'conText': '代理所排名图',
        'conActive': false
      },
        {
          'conText': '马德里商标代理图',
          'conActive': false
        },
        {
          'conText': '代理所对比图',
          'conActive': false
        }
      ]
    }],
  },
  "query_lang": {
    /* 后续补充 */
    queryShowTable: '表格',
    queryShowIcon: '图标',
    Disclaimer: '免责声明：由于数据更新存在时间差，查询结果仅供参考，不具有法律效力。', // 免责声明
    Powered_by_Lanternfish: '由蓝灯鱼提供技术支持', // 技术支持
    more: '更多', // 更多
    packup: '收起', // 收起
    /* 主界面/快速查询 */ /// *主界面/快速查询*/
    Similar_mark: '近似商标', // 近似商标,
    Similar_mark_tips: '',
    Reg_No: 'Application number', // 申请号,
    Trademark_name: 'Applicant', // 申请人,
    area: '中国大陆',
    Class: '类别', // 分类,
    // search:"search",//查询,
    exactQuery: '组合查询', // 高级查询,
    fastQuery: '快速查询', // 快速查询,
    xxx: '中国大陆', // 中国大陆,
    /* 高级查询 */ /// *高级查询*/
    Literal_elements_of_trademark: '商标文字', // 商标文字,
    Identical_search: '相同查询', // 精确查询,
    Contained_search: '包含查询', // 包含查询,
    Similar_search: '近似查询', // 近似查询,
    Trademark_specimen: 'Trademark specimen', // 商标图形,
    Click_to_upload_the_specimen: '拖拽图片或点击上传', // 拖拽图片或点击上传,
    chooseDate: '选择日期',
    Design_Code_Search: 'Vienna code', // 图形要素编码,
    App_Reg_No: 'Application Number', // 申请号/注册号,
    Applicant_name_Chinese: 'Applicant Name（cn）', // 申请人名称（中文）,
    Applicant_name_English: 'Applicant Name（en）', // 申请人名称（英文）,
    Applicant_address_Chinese: 'Applicant Address（cn）', // 申请人地址（中文） ,
    Applicant_address_English: 'Applicant Address（en）', // 申请人地址（英文） ,
    Publication_date: '初审公告日', // 初审公告日,
    app_Date: 'Application date', // 申请日,
    Registration_Date: 'Registration date', // 注册日,
    Expiration_Date: '专用权到期日', // 专用权到期日,
    Domestic_Representative_or_Attorney_of_Record: 'Agency', // 代理机构,
    return_: '返回', // 返回,
    search: '查询', // 查询,
    reset: '重置',
    to: '到',
    /* 查询结果 */ /// *查询结果*/
    Selected_conditions: '查询条件', // 查询条件,
    Degree_of_similarity: '近似度', // 近似度,
    high: 'high', // 高,
    middle: '中', // 中,
    low: '低', // 低,
    trademarkStatus: 'Status', // 商标状态,
    Registered: '已注册', // 已注册,
    Pending_application: '申请中', // 申请中,
    Invalid: '无效', // 无效,
    Published_for_opposition: '公告中', // 公告中,
    Pending_opposition: '异议中', // 异议中,
    Pending_review_of_refusal: '驳回复审中', // 驳回复审中,
    Applicant_name: 'Applicant Name', // 申请人名称,
    Applicant_address: 'Applicant Address', // 申请人地址,
    App_No: 'Application number', // 申请号,
    Goods_Services_: '商品/服务', // 商品/服务,
    Filing_Date: 'Application date', // 申请日,
    Reg_Date: 'Registration date', // 注册日,
    Status: '状态', // 状态,
    Degree_of_satisfaction: '满意度', // 满意度,
    Completed_File: '完整档案', // 完整档案,
    Analysis_of_availability_of_registration: '查询报告', // xxx,
    Download_search_report: '导出到excel', // xxx,
    please_enter: '请输入',
    please_choose: '请选择',
    checkAll: '全选',
    cancel: '取消',
    confirm: '确定',
    /* 上传图片 */
    Kind_reminder: '温馨提示',
    uploadTips1: '请上传商标图样，JPG/PNG/GIF/TIFF格式',
    uploadTips2: '2.如已经上传文字，则无法同时上传商标',
    uploadTips_success: '图片上传成功,请继续选择条件,注意选择类别呦!',
    noData: '暂无数据',
    /* 通用 */
    Image_format_error: '文件格式错误', // 图片格式错误
    Search_successful: 'Success',
    The_file_is_too_large: '文件过大', // 文件过大
    /* 商标档案for丹丹 */
    Print: '打印', // 打印
    Report_wrong_information: '错误信息反馈', // 错误信息反馈
    Goods_Services: '商品/服务',
    Subclass: '类似群组',
    GazetteNoofRegistration: 'Registration Notice Issue No.',
    InternationalRegistrationDate: '国际注册日',
    PostdesignationDate: '后期指定日期',
    jsjmStatus: 'Status',
    ddProcedure: 'Trademark Process',
    ddPriority: 'Community Marks',
    ddSteps: '流程',
    dddate: 'Date',
    ddyouxianquan: '优先权日期',
    ddPublication: 'Preliminary announcement period number',
    // 可注册分析
    CommerceAndMarketAnalysis: '查询报告',
    TrademarkToBeSearched: '查询对象',
    TrademarkToBeScope: '查询范围',
    ClassEs: '选择类别',
    PresentationOfBelief: '您支付费用后1个工作日内会收到分析报告:',
    MobilePhone: '手机号',
    EmailAddress: '电子邮件',
    Total: '费用总和:',
    PayNow: '支付费用',
    qian: '元',
    fusm: '服务说明',
    fusmx: '我们会在收到订单后1个工作日内安排经验丰富的商标代理人为您出具详细的《Trademark search报告》，包括对商标显著性、在先权利冲突等影响商标注册的潜在因素进行分析和建议，发到您的注册邮箱。',
    ContactInformation: '联系信息',
    CompleteNow: '立即填写',
    Incomplete: '未填写',
    PleaseUpload: '请上传商标图样',
    pleaseChoose: '请选择类别',
    At_least_one: '至少保留一个' // 至少保留一个
  },
  "follow_up_FE_lang": {
    A_larger_preview: '大图预览',
    please0: '如果原始文件不是英文，请上传英文翻译。',
    please1: '1) 请在右侧框中下载模板；',
    please2: '2) 请参照示例签字；',
    please3: '3) 请扫描已签名的，并上传至左侧框',
    complete_: '添加',
    edit: '修改',
    continue_: '继续',
    delete_: '删除',
    priority_: '优先权证明文件',
    scanned_copy: '扫描件',
    download_the_drafted_POA: '下载',
    Duplicate_of_applicant: '申请人重复', // 申请人重复
    trademark_at_least_one: 'Application number/注册号,Applicant Name,商标名称至少输入一个', // 申请号/注册号,申请人名称,商标至少输入一个
    Priority_category: '优先权类别', // 优先权类别
    operation: '操作', // operation
    Cannot_contain_special_characters: '不能包含特殊字符', // 不能包含特殊字符
    Analysis_of_trademark: '查询报告', // 可注册性分析
    Schedule_renewal: '预定', // 预定续展
    Renewal: '正常', // 正常续展
    Extension_renewal: '宽展期续展', // 宽展期续展
    Unable_to_renew: '不可续展', // 不可续展
    Amount_paid: '实收金额', // 实收金额
    reset: 'Reset', // 重置
    Please_note: '温馨提示', // 温馨提示
    Please_note2: '温馨提示:  最多可勾选5个', // 温馨提示:  最多可勾选5个
    applicantNumLimit: '申请人共同申请人加起来不能超过10个',
    confirm: '确认', // 确认
    close: '关闭', // 关闭
    enter_error: '输入错误', // 输入错误
    enter_fomat_nice: '请输入,如03;09;38;', //
    enter_fomat_vienna: '请输入,如4.1;6.3;最多输入5个;', // 最多输入5个位自己翻译;
    enter_fomat_no: '请输入申请号,如000001;000002;...国际商标请在申请号前增加字母G', // 请输入申请号,如No.1;No.2
    enter_fomat_reg_no: '请输入注册号,如000001;000002;...', // 请输入申请号,如No.1;No.2
    xuzhan1: '部分商标已经提交续展申请,请勿重复提交.', // 部分商标已经提交续展申请,请勿重复提交;
    Price_error: '价格错误',
    next: '下一步', // 下一步
    isRenewal2: '此商标已经提交续展申请,请勿重复提交;',
    isRenewal: '部分商标已经提交续展申请,请勿重复提交;',
    xuzhan2: '说明：根据国家知识产权局规定，非标准优先权需要上传/邮寄优先权原件', // 说明：根据中国大陆商标局规定，非标准优先权需要邮寄优先权原件
    both_words_and_marks: '有关键词则不能上传图片', // 有关键词则不能上传图片
    /*  appNameCn:"",//申请人中文
      appname:"",//申请人英文
      appAdressCn:"",//申请人地址中文
      appAdressEn:"",//申请人地址英文 */
    Mainland_China: '中国大陆',
    Hong_Kong: '中国香港',
    Trademark_Application: '中国大陆商标注册',
    Hong_Kong_Applications: '中国香港商标注册',
    Analysis__Availability: '查询报告',
    Trademark_Renewal: '商标续展',
    to: '至',
    Pending_application: '申请中',
    Preliminary_published: '初审公告中',
    Pending_opposition: '异议中',
    Registered: '已注册',
    Invalid: '无效',
    Other: '其他情形'

  },
  "reg_query_lang": {
    Upload_again: '重选',
    Rotate: '旋转',
    Flip: '镜像',
    Confirm: '确定',
    Creation_Date: '创建日期',
    Service_fees: '服务时长',
    Total_Due: '费用',
    Expiration_Date: '截止日期',
    Payment: '付费方式',
    havePriority: '(优先权)',
    please_upload: '请上传',
    de: '的',
    business_license: '营业执照',
    priority: '优先权证明',
    notarial_documents: '公正文件',
    power_of_attorney: '委托书', // 委托书The power of attorney
    Successfully_added: '添加成功', // 添加成功
    Abnormal_communication: '通讯异常', // 通讯异常
    xxx_s_business_license: '请上传xxx的营业执照', // 请上传xxx的营业执照
    upload_the_priority_: '请上传优先权证明', // 请上传优先权证明
    pload_the_notarial_documents: '请上传公证文件', // 请上传公证文件
    mailing_company: '请填写邮寄公司', // 请填写邮寄公司
    mailing_list: '请填写邮寄单号', // 请填写邮寄单号
    Submitted_successfully: '提交成功', // 提交成功
    Submitted_failure: '提交失败', // 提交失败
    name_of_trademark: '请输入商标名称', // 请输入商标名称
    trademark_image: '请上传或生成商标图片', // 请上传或生成商标图片
    trademark_images_black_and_white: '请上传或生成黑白商标图片', // 请上传或生成黑白商标图片
    color_of_the_trademark_contains: '请填写商标包含的颜色', // 请填写商标包含的颜色
    Description_of_the_mark: '请填写商标说明', // 请填写商标说明
    contain_non_Latin: '是否包含拉丁字符', // 是否包含拉丁
    country_Territory_Area: '请选择优先权国家', // 请选择优先权国家
    priority_date: '请选择优先权日', // 请选择优先权日
    Application_No: '请输入优先权申请号', // 请输入优先权申请号
    priority_category_list: '请将优先权类别列表填写完整', // 请将优先权类别列表填写完整
    priority_category_list_HK: '请填写优先权类别', // 请填写优先权类别
    the_product_category: '请选择商品类目', // 请选择商品类目
    tips_keywords: 'Please enter key words', // 请输入关键词
    Belongs_to_field_and_niche: '请选择所属领域和细分领域', // 请选择所属领域和细分领域
    At_least_one: '至少保留一个', // 至少保留一个
    name_of_applicant: '请输入申请人名称', // 请输入申请人名称
    address_in_detail: '请输入详细地址', // 请输入详细地址
    Social_unified_coding: '请输入正确的统一社会信用代码', // 请输入正确的社会统一编码
    id_number: '请输入证件号码', // 请输入证件号码
    Certificate_number_format_error: '证件号码格式错误', // 证件号码格式错误
    enter_the_country: '请输入国家', // 请输入国家
    plicant_name_en: '请输入申请人名称(English)', // 请输入申请人名称(英文)
    address_en: '请输入申请人地址英文', // 请输入申请人地址英文
    address_cn: '请输入申请人地址英文', // 请输入申请人地址中文
    plicant_name_cn: '请输入申请人名称中文', // 请输入申请人名称中文
    contact_person: '请输入联系人', // 请输入联系人
    phone_number: '请输入联系电话', // 请输入联系电话
    Save_Success: '保存成功', // 保存成功
    name_of_the_recipient: '请输入收件人名称', // 请输入收件人名称
    Save_failed: '保存失败', // 保存失败
    right_range_of_time: '请选择正确的时间段', // 请选择正确的时间段
    tips_which: '商标文字/Trademark specimen/图样要素编码/Application number/注册号/Applicant Name/Applicant Address/Agency,有且只能选一个', // 商标文字/商标图形/图样要素编码/申请号/注册号/申请人名称/申请人地址/代理机构,有且只能选一个
    tips_which2: '商标文字/Trademark specimen/图样要素编码/Application number/注册号/Applicant Name/Applicant Address/Agency,至少选一个',
    full_class_query: '没有选择类别,默认全类查询', // 没有选择分类,默认全类查询
    Data_Exception: '数据异常', // 数据异常
    Empty_Data: '查询为空', // 查询为空
    result_and_then_filter: '请确认查询到结果后,再进行筛选;', // 请确认查询到结果后,再进行筛选;
    tips_1: '没有满足当前近似度的数据', // 没有满足当前近似度的数据
    classification_first: '请先选择类别', // 请先选择分类
    CountriesAndRegions_first: '请先选择国家/地区', // 请先选择国家
    upload_your_query: '请上传查询图片', // 请上传查询图片
    upload_pictures: '请上传图片', // 请上传图片
    query_is_successful: '查询成功', // 查询成功
    regno_is_repeat: '基础信息申请号重复', // 基础信息申请号重复
    tmapplicant_is_repeat: '申请人与基础信息申请不一致', // 申请人与基础信息申请不一致
    SubmitSuccess: '提交成功', // 意见反馈提交成功
    SubmitFail: '提交失败' // 意见反馈提交失败
  },
  "personal_lang2": {
    debitmonitor: 'Trademark monitoring',
    monitor: '商标监控库',
    globalApplication: '海外商标注册',
    Dateofexpiry: '到期日',
    myOrders: '我的订单',
    DiscountCoupon: '我的感恩券',
    returnHome: '回到主页',
    returnKx: '返回康信',
    trademarkColors: '商标颜色',
    etc: '(多个)',
    OfficialCommunication: '官方来文:',
    SubmitOfficial: '提交官方:',
    // music: '网易云音乐3',//网易云音乐
    noAccount: '您的公司还没有企业账号，',
    NoEnterprise: '创建企业账号',
    myorderWelcome: '欢迎您,',
    myorderAll: '全部订单',
    myorderSelectAll: '全选',
    myorderSeviceName: '服务名称',
    OrderAmount: '订单总额',
    ServiceFees: '服务费',
    myorderStatus: '全部状态',
    Operations: '操作',
    orderMoney: '￥',
    ddOfficialdocuments: '官方文件',
    // 官文管理页面
    serach: '搜索',
    trademarkName: 'Trademark text',
    appliNo: 'Application number',
    GuanwenClass: '类别',
    Documentname: '官文名称',
    Guanwenapplicnt: 'Applicant',
    OrderNo1: '订单号',
    Trademark: '商标',
    Appliacnat: 'Applicant',
    PleaseChoose: '请选择',
    fflakf: '申请退款后则无法撤销，您是否确认退款？请您输入退款原因',
    ddprompt: '提示',
    refunsWhy: '退款原因',
    typesOfSevice: '案件类型',
    OurRefNo: '康信卷号',
    YourRefNo: '客户卷号',
    Progresss: '进度',

    NoteDD: '提示',
    Download: '下载',
    DocumentName: '官文名称',
    DateReceived: '收录时间',
    Deadline: '绝限时间',

    // Huawei:'华为',
    // huaweiCom:'华为通信科技有限公司',
    // 订单详情页
    TrackingInfo: '邮寄信息',
    OrderIformation: '订单信息',
    caseformation: '案件信息',
    Receipt: '发票',
    printOrder: '打印订单',
    TrademarkInformation: '商标信息',
    PatentInformation: '专利信息',
    //  安全设置页面
    corpname: '公司名称',
    lianxiren: '联系人',
    SecurityLevel: '安全级别',
    LoginPassword: '登录密码',
    longLoginPassword: '安全性高的密码可以使您的账号更安全，建议您定期的更改密码',
    LoginPasswordmodify: '修改',
    EmailVerification: '账号邮箱',
    EmailVerificationBind: '建议您绑定邮箱，以便于接收给您发送的重要通知',
    binding: '绑定',
    securityPhone: '安全手机',
    jajeueaosj: '您验证的手机',
    securiwodnsls: '若丢失或停用，请立即更换，避免账户被盗',
    ServicePromotion: '安全服务提示',
    jejeneoettta: '1. 确认您登录的是康信网址https://service.kangxin.com/，注意防范进入钓鱼网站，不要轻信各种即时通讯工具发送的服务或支付链接，谨防网络诈骗。',
    jianuie: '2. 建议您安装杀毒软件，并定期更新操作系统等软件补丁，确保账户及交易安全。',
    enterPassword: '请输入不少于八位的数字字母组合密码',
    incosistern: '两次输入密码不一致',
    emptyNewPassword: '新密码不能为空',
    tuxing: '图形验证码不能为空',
    getYanzheng: '验证码不能为空',

    //  消息页面

    //  邮寄地址页面
    CountryArea: '所在地区',
    Address: '详细地址',
    PostCode: '邮政编码',
    ContactPerson: '收货人',
    Telephone: '电话',
    Operation: '操作',
    add: '添加',
    delete: '删除',
    AddressDefault: '设为默认',
    defaultAddress: '默认地址',
    //    邮寄地址页面的提示
    ContactPersonEmpty: '收货人不能为空',
    TelephoneeMPTY: '电话不能为空',
    TelephoneSuccess: '请输入正确的电话',
    //  企业认证页面
    companyName: '企业名称',
    MailingAddress: '邮寄地址',
    OfficeTelephone: '办公电话',
    EnterContactPerson: '指定联系人',
    enterMobilePhone: '指定手机号',
    enterEmailAddress: '电子邮件',
    enterBusiness: '营业执照',
    companyNamesEmpty: '企业名称不能为空',
    emailEmpty: '邮政编码不能为空',
    emailUnSuccess: '请输入正确的邮政编码',
    addressEmpty: '详细地址不能为空',
    officePhoneEmpty: '办公电话不能为空',
    officePhoneUnSuess: '请输入正确的办公电话',
    emailssEmpty: '电子邮件不能为空',
    emailssUnSuccess: '请输入正确的电子邮件',
    enterpriseYijng: '您的认证正在审核中',
    SuccessfullySubmit: '提交成功',
    SuccessFulluSuek: '已经提交成功，我们将在2个工作日内完成审核。',
    shenhetongguo: '您的审核已通过',
    enterFirishs: '恭喜,您的企业信息认证已完成',
    enejkie: '请上传图片格式营业执照，需<10MB仅限JPG、PNG、',
    fkofafk: 'GIF、TIFF格式',
    jsjsn: '提交',
    rejected: '您的认证被驳回',
    Reasons: '驳回理由',
    //  账号信息页面
    addressz: '地址',
    city: '城市',
    youbian: '邮编',
    county: '国家',
    accountLevel: '安全级别',
    accountEmail: '邮箱',
    accountName: '名称',
    accountMobilePhone: '手机号',
    Gender: '性别',
    male: '男',
    Female: '女',
    currency: '显示币种',
    Ipfrim: '您是',
    enterprises: '企业',
    Agency: '代理所',
    Indiv: '个人',
    ModifyInformation: '修改信息',
    modifyChangePass: '修改登录密码',
    ddsuccess: '成功',
    //  修改密码页面
    VerifyIdentity: '身份验证',
    setNewPassword: '设置新密码',
    authenComplete: '完成',
    authenticaRetri: '密码找回途径',
    VerificationCode: '验证码',
    Captchas: '图形验证码',
    //  修改手机号
    phoneModify: '绑定新手机',
    newMobile: '新手机号',
    //  修改邮箱
    emailModify: '绑定新邮箱',
    newEmail: '新邮箱',
    jjskdpff: '恭喜，修改成功!',
    fanhuizhuye: '返回主页',
    duanxin: '短信验证码',

    noData: '暂无数据',
    deleteScues: '删除成功',
    Successful: '操作成功',
    Unsuccessful: '操作失败',
    getVercitive: '获取验证码',
    null: '无',
    reqiureDocu: '委托材料',
    businessLincese: '营业执照',
    IDcardMerge: '身份证复印件',
    IDcard: '身份证',
    NotarizedDocument: '公证文件',
    PriorityDocument: '优先权证明',
    POA: '委托书',
    Literatrademark: 'Trademark text',
    Havelikeness: '肖像权',
    DescriptionMark: '商标说明',
    BusinessSector: '所属领域',
    Prioritys: '优先权',
    PrioritysCountry: '优先权国家',
    PriorityDate: '优先权日',
    ApplicationNo: '优先权申请号',
    RefNo: '康信卷号',
    Customized: '自定义商品',
    Applicantname: 'Applicant',
    Latestupdates: '最新进度',
    // 账单
    OrderNo: '订单号',
    DNNo: '账单号',
    DNdate: '账单日',
    PaymentStatus: '支付状态',
    PaymentDate: '支付日期',
    CasesType: '案件类型',
    DebitTotal: '金额（人民币）',
    Unpaid: '未付款',
    Paid: '已付款',
    To: '致',
    Date: 'Date',
    DNNos: '制单编号',
    Re: '案由',
    Trademarks: '商标',
    Classss: '类别',
    DESCRIPTION: '收费项目',
    AMOUNT: '收费金额',
    SERVICESFEES: '代理费',
    Subtotal: '小计',
    OFFICIALFEES: '官方费用',
    TOTALDUE: '总计',
    OrderNos: '订单号',
    ChinaMainland: '中国大陆商标注册',
    HongKongs: '香港商标注册',
    Searchservice: '查询服务',
    Designs: '图形',
    DEBITNOTE: '账单',
    InvoiceStatus: '发票状态',
    // 发票状态
    Unbilled: '未开票',
    Auditing: '申请审核中',
    Audited: '审核通过',
    UnAudited: '审核未通过',
    Invoiced: '已开票',
    Mailed: '已邮寄',
    //  未完成案件
    TypeofCase: '案件类型',
    deadline: '官方绝限',
    Country: '国家',
    Mark: '商标',
    MainlandChina: '中国大陆',
    HongKong: '中国香港',
    TrademarkRenewal: '商标续展',
    TrademarkApplication: '商标注册',
    AnalysisAvail: '查询报告',
    Continue: '填写',
    deleteSure: '确定删除这条信息吗',
    TradematkImg: '商标',
    SelectedGoodsServices: '商品/服务项目',

    //  注册首页马丹丹
    dd1TrademarkRenewal: '商标续展',
    ddServiceIntroduction: '服务简介 :',
    ddprocedures: '商标有效期满后续继续使用的，应及时办理商标续展手续。续展可办理的时间期限为商标期满前12个月内，如该期限内未及时办理的可在商标到期日后6个月内（即“宽展期”）办理',
    ddApplicationProcess: '申请流程 :',
    ddSubmitInfor: '提交申请---形式审查---实质审查---核准'

  },
  update_lang: {
    btnRenewal: '续展',
    ccc: '',
    /* 主页面 */
    Select_trademark_to_renew: '选择续展商标',
    Confirmation: '支付费用',
    Upload_Documents: '提供文件',
    Submit: '确认通知方式',
    search: '查询',
    placeholder_1: '申请号/注册号,如No.1;No.2',
    placeholder_2: '申请人名称',
    placeholder_3: '商标名称',
    placeholder_4: '类别',
    placeholder_5: '续展状态',
    placeholder_6: '代理机构',
    placeholder_7: '专用权到期日',
    placeholder_8: '专用权到期日',
    placeholder_9: '注册日',
    placeholder_10: '注册日',
    label1: '全部',
    label2: '可续展',
    label3: '不可续展',
    table_checkAll: '全选',
    table_pictorial_trade_mark: '商标图案', // 商标图案
    table_Applicant_name: '申请人', // 申请人
    table_App_No: '申请号', // 申请号
    table_Class: '类别', // 类别
    table_Status: '状态', // 状态
    table_Date_of_expiry: '有效期截止日', // 有效期截止日
    table_Renewal_limitations: '宽展期', // 续展绝限
    table_Operating_state: '操作状态', // 操作状态
    table_Operating_1: '操作', // 操作
    btn_Confirm_renewal: '商标续展', // 确认续展
    btn_Confirm_monitoring: '商标监控', // 确认监控
    btn_Confirm_review: '驳回复审', // 确认驳回复审
    btn_Confirm_alteration: '商标变更', // 确认变更
    btn_Confirm_assignment: '商标转让', // 确认转让
    btn_cancel: '取消', // 取消
    btn_delete: '删除', // 删除
    have_selected: '已选择续展商标', // 已选择续展商标
    Unified_social_credit_code: '统一社会信用代码', // 统一社会信用代码
    btn_Save: '保存',
    btn_Return: '返回',
    btn_submit: '提交',
    total: '费用总和',
    shuoming: 'The mark is not open for renewal now, but we will monitor the status and file the renewal when available.'
  },
  /* 注册 */
  "register_lang": {
    hasPortaryal: '', // 肖像权提示
    discount_coupon: '感恩券',
    auto_fill: '确认', // 自动填充
    Selected_GoodsServices: '已选商品服务',
    /* 主页面 */
    Order_Form: '填写信息',
    Select_Trademarks: '选择商标',
    Confirmation: '订单确认',
    Upload_Documents: '提供文件',
    Submit: '确认通知方式',
    /* 商标信息 */
    Trademark_Information: '商标信息',
    Type_of_trademark: 'Trademark Type',
    Type_of_trademark2: '商标构成',
    Character: '文字',
    Design: '图形',
    Character_and_Design: '文字及图形',
    Name_of_trademark: 'Trademark text',
    Name_of_trademark_ploceholder: '请输入商标名称',
    Sample_of_trademark: '商标图样',
    // Manually_generated: "手动上传",
    Upload_your_own: '手动上传',
    Automatically_generated: '自动生成',
    uploadReminder1: '请上传商标图样，JPG/PNG格式，文件大小须<2MB',
    uploadReminder2: '像素要求不低于400x400（px）',
    seeExample: '查看示例',
    SampleReminder1: '如上传图样为黑白，取得注册后可以更换商标颜色使用；',
    SampleReminder2: '如上传图样为彩色，取得注册后只能按照该彩色图样使用。',
    Portrait: '上传图样是否包含肖像',
    PortraitYes: '是',
    PortraitNo: '否',
    yes: '是',
    no: '否',
    language: '语言',
    Series_of_marks: '是否是系列商标', // 是否是系列商标
    Number_of_marks_in_the_series: '系列号', // 序列号
    Description_of_the_mark: '商标说明',
    DescriptionReminder: '中文无',
    Not_the_Latin_alphabet_or_Chinese_characters: '中文无,非拉丁字母或非中文字符',
    Language: '中文无',
    Transliteration: '中文无',
    Phonetic_Pronunciation: '中文无',
    Business_Sector1: '所属领域',
    Business_Sector2: '细分领域',
    Priority: '优先权',
    PriorityYes: '有',
    PriorityNo: '无',
    Suggested_class: '推荐注册',
    All_class_registrations: '全类注册',
    Clear: '清除',

    plaseChoose: '请选择',
    Please_enter: '请输入',
    Please_select_the_same_applicant: '请选择同一申请人',
    /* 自动生成图片 */
    create: '点击自动生成', // 点击自动生成
    regenerate: '重新生成', // 重新生成

    /* 上传商标 */
    Trademark_Specimen: '提交商标的颜色',
    Color: '彩色',
    Black_and_White: '黑白',
    colorTips1: '如上传图样为黑白，取得注册后可以更换商标颜色使用；',
    colorTips2: '如上传图样为彩色，取得注册后只能按照该彩色图样使用。',
    reupload: '重新上传',
    containColor: '请说明都包含哪些颜色',
    /* 有优先权 */
    Country_Territory_Area: '优先权国家',
    Priority_Date: '优先权日',
    Priority_Date_WARN: '',
    Application_No: '优先权申请号',
    Class_: '类别',
    Goods_Services_English: '商品/服务项目',
    Goods_Services_Chinese: '商品/服务项目(Chinese)',
    /* 无优先权 */
    Class_and_Goods_Services: '类别及商品／服务项目：',
    Class: '类别',
    Selected_Goods_Services: '已选商品／服务项目',
    one_upload_one: '提交方式说明：为方便使用，按照一标一类提交至官方',
    Search_by_keywords: '关键词搜索',
    add: '添加',
    delete_: '删除',
    /* 结束语 */
    total: '费用总和 :',
    rmb: '元',
    save: '保存',
    // 注册-确认提交方式
    subconfirm: '您的订单已提交!',
    lookOrder: '查看列表订单',
    againorder: '再来一单',
    confirm2: '温馨提示:为及时通知案件进展,请您完善联系信息',
    confirm3: '手机号',
    email: '电子邮件',
    notApplicable: '未填写',
    updatenow: '立即填写',
    address: '邮寄地址',
    confirm5: '邮编',
    confirm5_two: '收件人',
    confirm5_three: '其他电话',
    confirm6: '保存',
    confirm7: '确定',
    noData1: '暂无数据',
    /* 申请人信息 */
    Applicant_Information: '申请人信息',
    Type_of_applicant: '申请人类型',
    Organization: '企业',
    Individual: '自然人',
    Type_of_applicant_ID: '申请人证件类型',
    Photo_ID: '身份证',
    Passport: '护照',
    Other: '其他',
    Belongs_to_The_country: '所属国家/地区',
    Find_Chinese_name_and_address: '',
    Find_Chinese_name_and_address_tips: '',
    Applicant_name_En: 'Applicant Name（en）',
    Applicant_name_En_tips: '',
    Applicant_name_En_tips2: '',
    Applicant_address_En: 'Applicant Address（en）',
    Applicant_address_En_tips: '',
    Add_joint_applicant: '添加共同申请人',
    Preview: '预览',
    Save_and_proceed: '下一步',
    // 注册-预览页面
    tradeInfo: '商标信息',
    tradeType: '商标类型',
    tradeName: 'Trademark text',
    TheArea: '所属领域',
    PriorityDa: '优先权日',
    applicntMsg: '申请人信息',
    applicnt: 'Applicant',
    applicntType: '申请人类型',
    appcotry: 'Countries/Regions',
    appEname: 'Applicant Name（en）',
    category: '类别及商品/服务项目',
    appEaddress: 'Applicant Address（en）',
    Applicant_name_Chinese: 'Applicant Name（cn）', // 申请人名称（中文）,
    Applicant_address_Chinese: 'Applicant Address（cn）', // 申请人地址（中文） ,
    applicantID: '申请人证件类型',
    PostCoder: '邮政编码',
    Contperson: '联系人',
    Tphone: '联系电话',
    appliCant: 'Applicant',
    Joinapp: '共同申请人信息',
    sure: '确定',
    // area1:"中国大陆",
    class1: '类',
    noClass: '第',
    heibai: '商标黑白图样',
    tishi: '提交成功',
    /* 提供材料 */
    submit_btn: '确认并提交',
    appname: 'Applicant Name',
    SpecimenOfTrademark: '商标原图样',
    changyong: '已保存至常用地址',
    noChinese: '',
    Copy_of_Business_Certificate: '营业执照复印件',
    id_or_passport: '/身份证/护照',
    English_Translation: '英文翻译',
    Priority_document: '优先权证明',
    Notarized_and_legalized_document: '公证书证明',
    Power_of_Attorney: '委托书',
    Mailing_company: '邮寄公司:',
    Tracking_number: '邮寄单号:',
    ReUpload: '上传',
    Preview_: '预览',
    who_Power_of_Attorney: '的委托书',
    Mainland_China_Application: '中国大陆商标注册',
    Hong_Kong_Application: '中国香港商标注册',
    Mainland_China_Trademark_Renewal: '中国大陆商标续展',

    Priority_DateDan: '优先权日期',
    //  注册首页 马丹丹
    ddmonths: '个月',
    ddyears: '年',
    dApplicationTimeline: '申请周期',
    Protectionperiod: '保护年限',
    Elementswhich: '可作为商标注册的要素',
    dApplicationprinciple: '申请原则',
    ddinclude: '为申请人提供在全球提交商标注册申请的服务，服务包括：',
    ddseverses: '商标申报 官方文件通知 案件进展跟进 时限监控 商标',
    ddzhengsh: '注册证书邮寄',
    Countryselection: '选择办理国家',
    Commonlycountry: '热门国家/地区',
    Countriesselected: '已选国家',
    ddFee: '费用',
    Applynow: '立即办理',
    zeroRMB: '元起',
    seeMore: '查看更多',
    dFAQAnswers: '常见问题解答',
    Please_enter_correct: '请输入正确' // 请输入正确

  },
  // "trademarkSearch": {
  //     iptPlaceholder: 'Please input trademark name/applicantion no./applicant',
  //     combineSearch: 'Advanced search',
  //     pleaseSelect: 'Please Select',
  //     uploadExplain: 'Please upload a trademark specimen <5MB JPG/PNG/GIF/TIFF格式',
  //     yes: 'Yes',
  //     no: 'No',
  //     valid: 'Valid',
  //     invalid: 'Invalid',
  //     add: 'Add',
  //     reminder: '温馨提示:&nbsp;&nbsp;可选多个',
  //     confirm: 'Confirm',
  //     close: 'Close',
  //     class: 'Class',
  //     selecetdConditions: 'Selected condition',
  //     country: 'Country',
  //     more: 'More',
  //     reset: 'Reset',
  //     return: 'Return',
  //     selectAll: 'Select All',
  //     selectNone: 'Select None',
  //     Asia: 'Asia',
  //     Europ: 'Europ',
  //     NorthAmerica: 'NorthAmerica',
  //     SouthAmerica: 'SouthAmerica',
  //     Africa: 'Africa',
  //     Oceania: 'Oceania',
  //     Organization: 'Organization',
  //     purchase: 'Purchase',
  //     applicantName: 'Applicant Name',
  //     select: 'Select',
  //     clear: 'Clear',
  //     applicantionDate: 'Applicantion Date',
  //     registrationDate: 'Registration Date',
  //     isValid: 'isValid',
  //     status: 'Status',
  //     agent: 'Agent',
  //     similarity: 'Degree of Similarity',
  //     low: 'Low',
  //     medium: 'Medium',
  //     high: 'High',
  //     address: 'Address',
  //     Chinese: 'Chinese',
  //     applicantionNo: 'Applicantion No.',
  //     applicant: 'Appliacnat',
  //     Enghish: 'English',
  //     services: 'Goods/Services',
  //     analysis: '可注册分析报告',
  //     renewal: 'Renewal',
  //     seeBigImg: '大图预览',
  //     noData: '自救中，暂无结果',
  //     tableName: 'the Search Supported Country/Region',
  //     table: 'table',
  //     statementPre: 'For the scope of trademark application, the latest update time, please refer to',
  //     statementafter: 'Disclaimer: Due to the time difference between data updates, the search results are for reference only and have no legal effect.',
  //     uploadImg: 'Please upload image',
  //     inputText: 'Please input text',
  //     textOrImg: 'Please input text or upload image',
  //     loading: 'Loading...',
  //     loadingComplte: 'Loading Complte',
  //     goPurchase: 'Go Purchase',
  //     runOut: '您的查询次数已用完',
  //     failedReport: '生成可注册报告失败',
  //     sendMessage: '您已经提交申请，审核通过后，会给您发送通知',
  //     otherResult: '结果较少，以下为其他类别结果供参考',
  //     searchResult: 'Search Result.xls',
  // },
  ...enLocale,
  ...enUS
}

export default en

<template>
  <div>
    <div :class="[{overlay: !isModeTable && !flagyang}]"></div>
    <div :class="['query']">
      <h1 class="h_none">商标查询</h1>
      <h2 class="h_none">蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索</h2>
      <img src="/images/result_show_logo.png" alt="" class="h_none">
      <input id="copy_content" type="text" value="" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>

      <el-card :style="{width: show ? '100%' : '55%', position: 'relative', zIndex: '5'}" shadow="always" class="box-card">
        <!--      商标类型选择-->
        <ul class="tab">
          <li :class="[{'active': item.show}]" @click.stop="switchType(item.id)" v-for="item in tab" :key="item.id">
            {{item.label}}
          </li>
        </ul>

        <!--      功能页面-->
        <div class="content">
          <!--          搜索-->
          <div :style="{width: show ? '50%' : '100%'}" :class="['content_item_1',{'active': show == false}]">
            <!--      tabType-->
            <ul v-if="wSize" :class="[{active:show == false},{'tabType': wSize}]">
              <li @click.stop="tabTypeSwitch(item.id)" v-for="item in tabType" :key="item.id">
                <img :src="item.icon" alt="">
                <img :src="item.icon1" alt="">
                <span>{{item.label}}</span>
              </li>
            </ul>

            <ul v-else :class="[{active:show == false},{'tabTypes': !wSize}]">
              <li @click.stop="tabTypeSwitch(item.id)" v-for="item in tabType" :key="item.id">
                <el-tooltip class="item" effect="light" :content="item.label" placement="bottom">
                  <img :src="item.icon" alt="">
                </el-tooltip>

                <el-tooltip class="item" effect="light" :content="item.label" placement="bottom">
                  <img :src="item.icon1" alt="">
                </el-tooltip>
              </li>
            </ul>

            <section class="query-box">
              <el-radio-group @change="changeValue"  v-if="switchTypeId == 1" :class="{radio: queryModes}" v-model="value">
                <el-radio style="margin-bottom: 10px;" @click="" v-if="item.show" :label="item.value" v-for="(item, index) in firstList" :key="index">
                  {{item.text}}
                </el-radio>
              </el-radio-group>

              <el-radio-group @change="radioGroupChange" style="height: 20px" v-if="switchTypeId == 6" :class="{radio: queryModes}" v-model="leadsRadio">
                <el-radio :label="1">代理机构推荐</el-radio>
                <el-radio :label="2">申请人线索</el-radio>
                <el-radio :label="3">公告线索</el-radio>
                <el-radio :label="4">续展线索</el-radio>
              </el-radio-group>

              <el-radio-group @change="changeValue"  v-if="switchTypeId == 5" :class="{radio: queryModes}" v-model="value">
                <el-radio @click="" style="margin-bottom: 10px" v-if="item.show && (item.value == 3 || item.value == 5)" :label="item.value" v-for="(item, index) in firstList" :key="index">
                  {{item.text}}
                </el-radio>
              </el-radio-group>

              <div class="input_search_item" v-if="switchTypeId == 6">
                <div class="line"></div>
                <el-form v-if="leadsRadio == 3" :inline="true"  class="demo-form-inline">
                  <el-form-item label="期号">
                    <el-select style="margin-right: 26px" v-model="formInline.num" placeholder="请选择">
                      <el-option
                        v-for="item in queryTmTrialNumArr"
                        :key="item.trialNum"
                        :label="item.trialNum"
                        :value="item.trialNum"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="类型">
                    <el-select @change="queryNotivceArrEvent" v-model="formInline.type" placeholder="请选择">
                      <el-option
                        v-for="item in announcementTypeArr"
                        :key="item.lable"
                        :label="item.lable"
                        :value="item.lable"
                      />
                    </el-select>
                  </el-form-item>
                </el-form>

                <el-form  v-else :inline="true"  class="demo-form-inline">
                  <el-form-item label="省/市">
                    <el-select style="margin-right: 26px"   @change="selectChange_1" v-model="formInline.city" placeholder="请选择">
                      <el-option
                        v-for="item in regionList"
                        :key="item.lable"
                        :label="item.lable"
                        :value="item.lable"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="市/区">
                    <el-select @change="selectChange_2" v-model="formInline.region" placeholder="请选择">
                      <el-option
                        v-for="item in regionArr"
                        :key="item.cnCounty || item.cnCity"
                        :label="item.cnCounty || item.cnCity"
                        :value="item.cnCounty || item.cnCity"
                      />
                    </el-select>
                  </el-form-item>
                </el-form>

                <el-form label-position="left" label-width="100px" v-if="leadsRadio == 4"  class="demo-form-inline">
                  <el-form-item label="注册公告日">
                    <el-date-picker
                      v-model="ggDate_"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="续展截止日期">
                    <el-date-picker
                      v-model="validDate_"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-form>
              </div>

              <div class="input_search" v-else style="display: flex">
                <div :style="{width: '100%'}" :class="['input-single',{'borderBlue':inputFocus}]">
                  <div class="input_box">
                    <el-input
                      v-if="!ifSearchInputAutoComplate"
                      style="height: 100%;border: none"
                      class="input-con"
                      ref="searchInput"
                      :placeholder="returnTip"
                      v-model="searchText"
                      @keyup.enter.native="isBigDatas();searchCross()"
                      @input="inputFn"
                      @focus="focus"
                      @blur="inputFocus=false"
                    >
                      <template v-if="switchTypeId != 4" class="prepend_input" slot="prepend">
                        <div v-if="(switchTypeId == 1 && value != 8) && switchTypeId != 5" @click.stop="rotate">
                          <img v-if="stateChange && stateChange.length" style="vertical-align: middle;width: 32px;" :src="nationalFlag" alt="">
                          <i :class="{rotate:isRotate == true}" style="color: #ffffff;margin-left: 5px" class="el-icon-caret-bottom"></i>
                        </div>

                        <div style="text-align: center" v-else>
                          <img v-if="stateChange && stateChange.length" style="vertical-align: middle;width: 32px;" :src="nationalFlag" alt="">
                        </div>

                        <el-tooltip effect="light" v-if="base64Url" placement="bottom">
                          <div style="width: 300px;height: auto" slot="content">
                            <img style="width: 100%;height: auto" :src="base64Url" alt="">
                          </div>

                          <el-tag @close="close" effect="plain" closable class="base64Url">
                            <img :src="base64Url" alt="">
                          </el-tag>
                        </el-tooltip>
                      </template>

                      <template v-else class="prepend_input notice" slot="prepend">
                        <div class="notice_btn">
                          <span @click="Announcement(0)" :class="[{'active': announcementFlag == 0 }]">公告期号</span>
                          <span @click="Announcement(1)" :class="[{'active': announcementFlag == 1 }]">申请号</span>
                        </div>
                      </template>
                    </el-input>
                    <el-autocomplete
                      v-if="ifSearchInputAutoComplate"
                      style="height: 100%;border: none"
                      class="input-con"
                      ref="searchInput"
                      :placeholder="returnTip"
                      v-model="searchText"
                      @keyup.enter.native="isBigDatas();searchCross()"
                      @input="inputFn"
                      @focus="focus"
                      @blur="inputFocus=false"
                      :fetch-suggestions="querySearch"
                      :trigger-on-focus="false"
                    >
                      <template v-if="switchTypeId != 4" class="prepend_input" slot="prepend">
                        <div v-if="(switchTypeId == 1 && value != 8) && switchTypeId != 5" @click.stop="rotate">
                          <img v-if="stateChange && stateChange.length" style="vertical-align: middle;width: 32px;" :src="nationalFlag" alt="">
                          <i :class="{rotate:isRotate == true}" style="color: #ffffff;margin-left: 5px" class="el-icon-caret-bottom"></i>
                        </div>

                        <div style="text-align: center" v-else>
                          <img v-if="stateChange && stateChange.length" style="vertical-align: middle;width: 32px;" :src="nationalFlag" alt="">
                        </div>

                        <el-tooltip effect="light" v-if="base64Url" placement="bottom">
                          <div style="width: 300px;height: auto" slot="content">
                            <img style="width: 100%;height: auto" :src="base64Url" alt="">
                          </div>

                          <el-tag @close="close" effect="plain" closable class="base64Url">
                            <img :src="base64Url" alt="">
                          </el-tag>
                        </el-tooltip>
                      </template>

                      <template v-else class="prepend_input notice" slot="prepend">
                        <div class="notice_btn">
                          <span @click="Announcement(0)" :class="[{'active': announcementFlag == 0 }]">公告期号</span>
                          <span @click="Announcement(1)" :class="[{'active': announcementFlag == 1 }]">申请号</span>
                        </div>
                      </template>
                    </el-autocomplete>
                  </div>

                  <el-tooltip v-if="switchTypeId == 1 && value != 7 && value != 8" class="item" effect="light" content="点击上传商标图片，即可以图查图" placement="top">
                    <div class="camera-box">
                      <img @click="UloaddialogTableVisible = true" src="@/assets/images/query/camera1_2.png" class="camera-btn camera-btn1"/>
                      <img @click="UloaddialogTableVisible = true" src="@/assets/images/query/camera2.png" class="camera-btn camera-btn2"/>
                    </div>
                  </el-tooltip>

                  <p class="query-btn" @click="isBigDatas();searchCross();isColumn = true"></p>
                </div>

                <div v-if="switchTypeId == 1 && value == 2" class="screen_btn">
                  <span @click="wholebtn('全')" :class="[{'active': wholeText == '全' }]">全</span>
                  <span @click="wholebtn('准')" :class="[{'active': wholeText == '准' }]">准</span>
                </div>

                <div v-if="switchTypeId == 4" class="screen_btn">
                  <span @click="gaoji()"  class="Advanced">高级检索</span>
                </div>
              </div>

              <p v-if="switchTypeId == 1 && value != 8" style="font-size: 12px; margin-top: 5px; color: #666666">注：多国查询时，选择多国消耗多次(例:选择5国，则消耗5次)</p>
            </section>

            <div v-if="switchTypeId == 6" class="btn" type="primary" @click="handleOnSubmitButtonClick">查询</div>
          </div>

          <div v-show="show" class="content_item_2">
            <div class="title">
              <div style="display: flex">
                <span class="sub_title">类别</span>
                <div class="Retrieval">
                  <div class="defineSwitcher">
                    <el-switch :disabled="switchTypeId != 1 || (value != 2 && value != 4)" v-model="isRetrieval" :width="80"></el-switch>
                  </div>
                </div>
              </div>

              <div>
                <el-button @click="resetSizer(false)" class="el_button" size="mini" round>重置</el-button>
                <el-button @click="resetSizer(true)" class="el_button" size="mini" round>全选</el-button>
              </div>
            </div>

            <ul v-if="niceList">
              <el-tooltip popper-class="tooltip_query" v-for="(item,index) in niceList" :key="index" effect="light" :content="item.typeName" placement="top">
                <li
                  :style="{marginLeft: '10px', cursor: 'pointer'} "
                  :class="['li-category',{isCheck:item.isCheck},{isSimilar:item.isSimilar}]"
                  @click="categoryType(item)"
                >
                  <span>{{item.name}}</span>
                </li>
              </el-tooltip>
            </ul>

            <div class="notes">
              注: <span style="color: rgba(243,183,20,1)">橙色背景</span>类别是您所选中类别的交叉群组
            </div>
          </div>

          <div v-show="show" class="content_item_3">
            <p class="title_1">{{switchTypeId == 1 && value == 2 && this.stateChange == 'CN'? '' : '以下是商品交叉信息'}}</p>

            <el-table
              v-show="switchTypeId == 1 && value == 2 && this.stateChange == 'CN'"
              key="123"
              height="250px"
              class="multipleTable"
              style="width: 100%"
              ref="multipleTable"
              default-expand-all
              :data="sameMessage"
              tooltip-effect="light"
              header-align="center"
              :header-row-class-name="tableHeaderClassName"
              :row-class-name="tableRowClassName"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                align="left"
                show-overflow-tooltip
                type="selection"
                width="30"
              />
              <el-table-column
                align="left"
                show-overflow-tooltip
                label="群组"
                width="55"
                prop="item3"
              />
              <el-table-column
                align="left"
                show-overflow-tooltip
                label="群组信息"
                prop="item2"
              />
              <el-table-column
                align="left"
                show-overflow-tooltip
                prop="item1"
                label="交叉群组"
                v-if="isCompound != 4 && isCompound != 1"
                width="80"
              />
              <el-table-column
                align="left"
                show-overflow-tooltip
                prop="address"
                label="交叉信息"
                width="150"
                v-if="isCompound != 4 && isCompound != 1"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                <span v-if="scope.row.item1 && scope.row.color != 2" style="width: 50%;text-align: center">
                  <i v-if="scope.row.crossVersion">({{scope.row.crossVersion}})</i>
                  与{{scope.row.item1}}类<i v-if="scope.row.crossParagraph">第{{scope.row.crossParagraph}}段</i>{{scope.row.item2}}交叉检索
                </span>
                  <span v-else></span>
                </template>
              </el-table-column>
            </el-table>

            <template v-show="switchTypeId != 1 || value != 2 || this.stateChange != 'CN'">
              <ul style="display: flex;margin-top: 9px" class="groupInformation active">
                <li style="width: 100%;margin-bottom: 3px">
                  <span style="width: 17%;text-align: center">群组</span>
                  <span style="width: 27%;text-align: center">交叉群组</span>
                  <span style="width: 50%;text-align: center">交叉信息</span>
                </li>
              </ul>

              <ul style="height: 190px;width: 100%;overflow-y: auto" :class="['groupInformation']">
                <li style="height: 20px;line-height: 20px;width: 100%;" v-for="(item,index) in sameMessages" :key="index">
                  <span style="width: 17%;text-align: center">{{item.similargroup}}</span>
                  <span style="width: 27%;text-align: center">{{item.crossSimilargroup}}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                    <span style="width: 50%;text-align: center">
                      <i v-if="item.crossVersion">({{item.crossVersion}})</i>
                      与{{item.crossSimilargroup}}类<i v-if="item.crossParagraph">第{{item.crossParagraph}}段</i>{{item.crossSimilargroupName}}交叉检索
                    </span>
                    </div>
                    <span style="width: 50%;text-align: center">
                    <i v-if="item.crossVersion">({{item.crossVersion}})</i>
                    与{{item.crossSimilargroup}}类<i v-if="item.crossParagraph">第{{item.crossParagraph}}段</i>{{item.crossSimilargroupName}}交叉检索
                  </span>
                  </el-tooltip>
                </li>
              </ul>
            </template>
          </div>

          <i @click="isInformation" v-if="!show && !trademarkFlag" class="el-icon-d-arrow-right arrow"></i>
          <i @click="isInformation" v-if="show && !trademarkFlag" class="el-icon-d-arrow-left arrow"></i>
        </div>
      </el-card>

      <el-card shadow="always" class="chart_box-card" v-if="!(switchTypeId == 4 && announcement.data.length > 0) && !trademarkFlag ">
        <transition name="el-zoom-in-center">
          <div v-if="!isCharts">
            <div class="loggingTitle">
              <span>历史记录</span>
              <span @click="historyJump()" style="cursor: pointer">全部历史</span>
            </div>
            <el-table class="history_table" height="230" :data="loggingData" style="width: 100%">
              <el-table-column width="100px" prop="orderNum" label="查询内容" align="center">
                <template slot-scope="scope">
                  <i style="display: block; height: 40px;" class="order_detail" v-if="scope.row.searchType == '图片检索'">
                    <img style="display: inline-block;height: 100%;" :src="`data:image/png;base64,${scope.row.searchContents}`" alt="">
                  </i>
                  <span v-if="scope.row.searchType == '文字检索'">{{scope.row.searchContents}}</span>
                  <span v-if="scope.row.searchType == '申请号查询'">{{scope.row.searchContents}}</span>
                  <span v-if="scope.row.searchType == '申请人查询'">{{scope.row.searchContents}}</span>
                  <span v-if="scope.row.searchType == '代理机构'">{{scope.row.searchContents}}</span>
                </template>
              </el-table-column>

              <el-table-column prop="createTime" label="查询时间" align="center"/>
              <el-table-column prop="patentSearchContents" label="查询条件" align="center">
                <template v-if="scope.row.patentSearchContents" slot-scope="scope">
                  <p style="font-size: 12px"><span style="color:#d01027;">国家</span>：{{ scope.row.countryName }}</p>
                  <p style="font-size: 12px" v-if="scope.row.searchType == '文字检索' || scope.row.searchType == '图片检索'">
                    <span style="color:#d01027;">and 类别</span>：{{scope.row.patentSearchContents.niceList ? scope.row.patentSearchContents.niceList : '1-45类'}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents && scope.row.patentSearchContents.imageCodeList">
                    <span style="color:#d01027;">and 图形要素编码</span>：{{scope.row.patentSearchContents.imageCodeList}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.applicant">
                    <span style="color:#d01027;">and 申请人名称</span>：{{scope.row.patentSearchContents.applicant}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.address">
                    <span style="color:#d01027;">and 申请人地址</span>：{{scope.row.patentSearchContents.address}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.appBeginDate">
                    <span style="color:#d01027;">and 申请日开始日期</span>：{{scope.row.patentSearchContents.appBeginDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.appEndDate">
                    <span style="color:#d01027;">and 申请日结束日期</span>：{{scope.row.patentSearchContents.appEndDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.trialBeginDate">
                    <span style="color:#d01027;">and 初审公告日开始日期</span>：{{scope.row.patentSearchContents.trialBeginDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.trialEndDate">
                    <span style="color:#d01027;">and 初审公告日结束日期</span>：{{scope.row.patentSearchContents.trialEndDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.ggBeginDate">
                    <span style="color:#d01027;">and 注册公告日开始日期</span>：{{scope.row.patentSearchContents.ggBeginDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.ggEndDate">
                    <span style="color:#d01027;">and 注册公告日结束日期</span>：{{scope.row.patentSearchContents.ggEndDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.regBeginDate">
                    <span style="color:#d01027;">and 注册日开始日期</span>：{{scope.row.patentSearchContents.regBeginDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.regEndDate">
                    <span style="color:#d01027;">and 注册日结束日期</span>：{{scope.row.patentSearchContents.regEndDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.priorityBeginDate">
                    <span style="color:#d01027;">and 优先权日期开始日期</span>：{{scope.row.patentSearchContents.priorityBeginDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.priorityEndDate">
                    <span style="color:#d01027;">and 优先权日结束日期</span>：{{scope.row.patentSearchContents.priorityEndDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.validBeginDate">
                    <span style="color:#d01027;">and 专用权日开始日期</span>：{{scope.row.patentSearchContents.validBeginDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.validEndDate">
                    <span style="color:#d01027;">and 专用权日结束日期</span>：{{scope.row.patentSearchContents.validEndDate}}
                  </p>
                  <p style="font-size: 12px" v-if="scope.row.patentSearchContents.istotal">
                    <span style="color:#d01027;">and 是否共有商标</span>：{{scope.row.patentSearchContents.istotal=='yes'?'是':'否'}}
                  </p>
                </template>
              </el-table-column>

              <el-table-column width="80px" fixed="right" prop="initiateType" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button @click="isBigDatas();historicalRetrieval(scope.row,true)" type="text" size="small">搜索
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div v-if="isCharts">
            <div @click="jumphistory" class="lishi">
              <img src="../../assets/images/query/lishi.png" alt="">
              <span>历史记录</span>
            </div>

            <span class="title">汇总查询结果信息</span>

            <div style="display: flex;height: 100%">
              <div style="width: 45%;height: 100%">
                <ul class="title_tab">
                  <li :class="[{ 'active': item.show }]" @click="switchTitle(item.id)" v-for="(item,index) in collectTitle" :key="index">
                    {{item.name}}
                  </li>
                </ul>
                <!-- 商标状态-->
                <pie style="width: 100%;height: 200px" :dataT="isShow ? pieDatas : pieData" id="pie1"></pie>
              </div>

              <div style="width: 55%;height: 80%">
                <span class="distribution">年份分布</span>
                <bar :trendsData="trendsData" id="pie4" style="width: 100%;height: 185px"></bar>
              </div>
            </div>
          </div>
        </transition>
      </el-card>

      <el-card
        shadow="always"
        :style="{'width': trademarkFlag && !invalidAnnouncement ? '55%' : ''}"
        :class="['sub_box-card',{'cardWidth': switchTypeId ==4 && announcement.data.length > 0}, {active: switchTypeId == 1}]"
      >
        <div v-if="(switchTypeId == 1 && value != 8) && switchTypeId != 4 && switchTypeId != 5" :class="['table-icon',{active: switchTypeId == 1}]">
          <div class="table-icon_item1" style="cursor: pointer" @click="tableSwitching">
            <img v-if="isModeTable" src="../../assets/images/query/table_active.png" alt="">
            <img v-else src="../../assets/images/query/table.png" alt="">
          </div>

          <div style="cursor: pointer" @click="tableSwitching">
            <img v-if="isModeTable" src="../../assets/images/query/picter.png" alt="">
            <img v-else src="../../assets/images/query/picter_active.png" alt="">
          </div>

          <div style="z-index: 1005" v-if="isHistoryAnalysist">
            <div v-if="noShow">
              <div  v-if="switchTypeId == 1 && (value == 2 || value == 4 ) && !(this.stateChange && this.stateChange.split(';').length >= 43) && !imageUrla && (this.stateChange.indexOf('CN') != -1 && this.stateChange.split(';').length == 1)" style="cursor: pointer;z-index: 9999;position: relative">
                <div style="z-index: 1005;position: relative" :class="['jumpReport',{active: isModeTable}]">
                  <img @click.stop="jumpReport" style="cursor: pointer" src="@/assets/images/query/report.png" class="" slot="reference"/>
                  <div :class="['icon']">
                    <div class="iconContent">
                      可注册分析报告
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="z-index: 1005" v-else>
              <div  v-if="switchTypeId == 1 && (value == 2 || value == 4 ) && !(this.stateChange && this.stateChange.split(';').length >= 43) && !imageUrla && (this.stateChange.indexOf('CN') != -1 && this.stateChange.split(';').length == 1)" style="cursor: pointer">
                <el-popover
                  style="margin-top: 4px;margin-left: 17px"
                  placement="bottom-start"
                  title=""
                  trigger="hover"
                  :content="$t(`PQ.accordion[11]`)"
                >
                  <img @click="jumpReport" style="cursor: pointer" src="@/assets/images/query/report.png" class="" slot="reference"/>
                </el-popover>
              </div>
            </div>
          </div>

          <div>
            <el-popover
              style="margin-top: 4px;margin-left: 17px"
              placement="bottom-start"
              title=""
              trigger="hover"
              content="word文档下载"
            >
              <img @click="Exexport(1)" style="cursor: pointer" src="../../assets/images/query/doc.png" slot="reference"/>
            </el-popover>
          </div>

          <div>
            <el-popover
              style="margin-top: 4px;margin-left: 15px"
              placement="bottom-start"
              title=""
              trigger="hover"
              content="xls文档下载"
            >
              <img @click="Exexport(0)" style="cursor: pointer" src="../../assets/images/query/xls.png" slot="reference"/>
            </el-popover>
          </div>

          <div class="defineSwitchs">
            <el-popover
              style="margin-top: 4px;margin-left: 5px"
              placement="bottom-start"
              title=""
              trigger="hover"
              content="仅看选中结果"
            >
              <el-switch slot="reference" @change="defineSwitchs" :disabled="getCheckboxRecords" v-model="screening" :width="54"></el-switch>
            </el-popover>
          </div>

          <div @click="trademarkCcomparison" class="comparison">
            商标对比
          </div>
          <!--一键添加商标监控-->
          <div>
            <el-popover
              placement="bottom-start"
              trigger="hover"
              content="默认添加商标名称/商标图样两个监控，消耗2个监控个数，支持修改监控条件"
            >
              <el-button @click="trademarkMonitorFn" class="addMonitor" slot="reference">商标监控</el-button>
            </el-popover>
          </div>
        </div>

        <ul v-if="switchTypeId == 6 && leadsRadio == 2" class="cluesSwitch">
          <li :class="[{'item': cluesType == 1}]"  @click="cluesTypeEvent(1)">
            <span></span>
            申请线索
          </li>

          <!--        <li :class="[{'item': cluesType == 2}]" @click="cluesTypeEvent(2)">-->
          <!--          <span></span>-->
          <!--          异议线索-->
          <!--        </li>-->

          <li :class="[{'item': cluesType == 3}]" @click="cluesTypeEvent(3)">
            <span></span>
            驳回线索
          </li>

          <li :class="[{'item': cluesType == 4}]" @click="cluesTypeEvent(4)">
            <span></span>
            无效线索
          </li>
        </ul>

        <div v-if="switchTypeId == 6 && leadsRadio == 3">
          <el-popover
            style="margin-top: 4px;margin-left: 15px"
            placement="bottom-start"
            title=""
            trigger="hover"
            content="xls文档下载"
          >
            <img @click="leadExexport(0)" style="cursor: pointer" src="../../assets/images/query/xls.png" slot="reference"/>
          </el-popover>
        </div>

        <!--业务线索-->
        <div class="businessLeads" v-if="switchTypeId == 6">
          <!--代理机构推荐-->
          <div v-if="leadsRadio == 1">
            <el-table
              class="active"
              :row-class-name="businessLeadsRowClassName"
              key="businessLeads_1"
              cell-class-name="mo-table__cell"
              :data="businessLeadsGridOption.data"
              :cell-class-name="businessLeadsClass"
              header-cell-class-name="_el_table_header"
              :border="false"
              :highlight-current-row="false"
              height="720px"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column
                align="left"
                prop="agentName"
                header-align="center"
                label="代理机构名称"
                width="220"
              />
              <el-table-column
                prop="lastYearCount"
                align="center"
                header-align="center"
                label="去年办案量"
              />
              <el-table-column
                prop="regRate"
                align="center"
                header-align="center"
                label="注册成功率"
                width="120"
              >
                <template slot="header" slot-scope="scope">

                  <div style="display: flex;align-items: center">
                    <p style="margin-right: 5px">注册成功率</p>
                    <el-tooltip class="item" effect="light" content="部分审查中的数据会影响注册成功率，该数据仅供参考" placement="right">
                      <i  style="font-size: 16px" class="el-tooltip el-icon-info item"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="rejectRate"
                align="center"
                header-align="center"
                label="申请驳回率"
              />
              <el-table-column
                prop="phone"
                align="center"
                header-align="center"
                label="联系电话"
              />
              <el-table-column
                prop="applicantName"
                align="center"
                header-align="center"
                label="TOP5申请人"
              >
                <template slot-scope="scope">
                  <p :style="{borderBottom: index == scope.row.applicantList.length - 1 || scope.row.applicantList.length == 1 ? '' : '1px solid #EBEEF5', marginBottom: index == scope.row.applicantList.length - 1   || scope.row.applicantList.length == 1 ? '' :  '6px'}" v-for="(item,index) in scope.row.applicantList">
                    {{item.applicantName}}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="appCount"
                header-align="center"
                align="center"
                label="代理此申请人商标量"
              >
                <template slot-scope="scope">
                  <p :style="{borderBottom: index == scope.row.applicantList.length - 1 || scope.row.applicantList.length == 1 ? '' : '1px solid #EBEEF5', marginBottom: index == scope.row.applicantList.length - 1   || scope.row.applicantList.length == 1 ? '' :  '6px'}" v-for="(item,index) in scope.row.applicantList">
                    {{item.appCount}}
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--申请人线索-->
          <div v-if="leadsRadio == 2">
            <!--申请线索-->
            <el-table
              v-if="cluesType == 1"
              :data="cluesTypeData"
              :row-class-name="businessLeadsRowClassName"
              :cell-class-name="businessLeadsClass2"
              header-cell-class-name="_el_table_header"
              :border="false"
              ref="cluesTypeData"
              key="businessLeads_2"
              :highlight-current-row="false"
              height="720px"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column
                prop="applicantZhName"
                label="申请人名称"
                header-align="center"
                align="left"
                width="400"
              >
                <template slot-scope="scope">
                <span  class="applicantZhName" style="color: #6483ed">
                  <span @click="applicantZhName(scope.row.applicantZhName)">{{ scope.row.applicantZhName }}</span>
                  <img class="copy" @click="qichacha(scope.row.applicantZhName)" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%"  :src="require('../../assets/images/query/qichacha.png')" title="企查查">
                </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="appRate"
                align="center"
                label="近3年商标申请增长率"
                width="160"
              />
              <el-table-column
                prop="appCount"
                align="center"
                label="总申请量"
              />
              <el-table-column
                prop="rejectRate"
                align="center"
                label="申请驳回率"
              />
              <el-table-column
                prop="regRate"
                align="center"
                label="注册成功率"
              >
                <template slot="header" slot-scope="scope">

                  <div style="display: flex;align-items: center;justify-content: center">
                    <p style="margin-right: 5px">注册成功率</p>
                    <el-tooltip class="item" effect="light" content="部分审查中的数据会影响注册成功率，该数据仅供参考" placement="right">
                      <i  style="font-size: 16px" class="el-tooltip el-icon-info item"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>

            </el-table>
            <!--异议线索-->
            <el-table
              class="active"
              v-if="cluesType == 2"
              :data="cluesTypeData"
              :cell-class-name="businessLeadsClass2"
              header-cell-class-name="_el_table_header"
              :border="false"
              ref="cluesTypeData"
              key="businessLeads_2"
              :highlight-current-row="false"
              height="720px"
              style="width: 100%; margin-top: 20px"
            >

              <el-table-column
                prop="applicantZhName"
                label="申请人名称"
                header-align="center"
                align="left"
                width="400"
              >
                <template slot-scope="scope">
                <span  class="applicantZhName" style="color: #6483ed">
                  <span @click="applicantZhName(scope.row.applicantZhName)">{{ scope.row.applicantZhName }}</span>
                  <img class="copy" @click="qichacha(scope.row.applicantZhName)" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%"  :src="require('../../assets/images/query/qichacha.png')" title="企查查">
                </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="140px"
                prop="lastYearOppositionCount"
                label="上年度商标异议量"
              />
              <el-table-column
                prop="appCount"
                align="center"
                width="140px"
                label="总申请量"
              />
              <el-table-column
                prop="applicantAddress"
                align="center"
                show-overflow-tooltip
                label="申请人地址"
              />
              <el-table-column
                prop="agentList"
                align="center"
                label="合作代理机构"
              >
                <template slot-scope="scope">
                  <p :style="{borderBottom: index == scope.row.agentList.length - 1 || scope.row.agentList.length == 1 ? '' : '1px solid #EBEEF5', marginBottom: index == scope.row.agentList.length - 1   || scope.row.agentList.length == 1 ? '' :  '6px'}" v-for="(item,index) in scope.row.agentList">
                    {{item}}
                  </p>
                </template>
              </el-table-column>
            </el-table>
            <!--驳回线索-->
            <el-table
              class="active"
              v-if="cluesType == 3"
              :data="cluesTypeData"
              :cell-class-name="businessLeadsClasss"
              :row-class-name="businessLeadsRowClassName"
              header-cell-class-name="_el_table_header"
              :border="false"
              ref="cluesTypeData"
              key="businessLeads_2"
              :highlight-current-row="false"
              height="720px"
              style="width: 100%; margin-top: 20px"
            >

              <el-table-column
                prop="applicantZhName"
                label="申请人名称"
                header-align="center"
                align="left"
                width="400"
              >
                <template slot-scope="scope">
                <span  class="applicantZhName" style="color: #6483ed">
                  <span @click="applicantZhName(scope.row.applicantZhName)">{{ scope.row.applicantZhName }}</span>
                  <img class="copy" @click="qichacha(scope.row.applicantZhName)" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%"  :src="require('../../assets/images/query/qichacha.png')" title="企查查">
                </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="140px"
                prop="lastYearRejectCount"
                label="上年度商标驳回量"
              />
              <el-table-column
                prop="appCount"
                align="center"
                width="140px"
                label="总申请量"
              />
              <el-table-column
                prop="applicantAddress"
                align="center"
                show-overflow-tooltip
                label="申请人地址"
              />
              <el-table-column
                prop="agentList"
                align="center"
                label="合作代理机构"
              >
                <template slot-scope="scope">
                  <p :style="{borderBottom: index == scope.row.agentList.length - 1 || scope.row.agentList.length == 1 ? '' : '1px solid #EBEEF5', marginBottom: index == scope.row.agentList.length - 1   || scope.row.agentList.length == 1 ? '' :  '6px'}" v-for="(item,index) in scope.row.agentList">
                    {{item}}
                  </p>
                </template>
              </el-table-column>
            </el-table>
            <!--无效线索-->
            <el-table
              class="active"
              v-if="cluesType == 4"
              :data="cluesTypeData"
              :row-class-name="businessLeadsRowClassName"
              :cell-class-name="businessLeadsClass2"
              header-cell-class-name="_el_table_header"
              :border="false"
              ref="cluesTypeData"
              key="businessLeads_2"
              :highlight-current-row="false"
              height="720px"
              style="width: 100%; margin-top: 20px"
            >

              <el-table-column
                prop="applicantZhName"
                label="申请人名称"
                header-align="center"
                align="left"
                width="400"
              >
                <template slot-scope="scope">
                <span class="applicantZhName" style="color: #6483ed">
                  <span  @click="applicantZhName(scope.row.applicantZhName)">{{ scope.row.applicantZhName }}</span>

                  <img class="copy" @click="qichacha(scope.row.applicantZhName)" style="width: 27px;cursor: pointer;margin-left: 5px;vertical-align: middle;border-radius: 50%"  :src="require('../../assets/images/query/qichacha.png')" title="企查查">
                </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="140px"
                prop="lastYearInvalidCount"
                label="上年度商标无效量"
              />
              <el-table-column
                prop="appCount"
                align="center"
                width="140px"
                label="总申请量"
              />
              <el-table-column
                prop="applicantAddress"
                align="center"
                show-overflow-tooltip
                label="申请人地址"
              />
              <el-table-column
                prop="agentList"
                align="center"
                label="合作代理机构"
              >
                <template slot-scope="scope">
                  <p :style="{borderBottom: index == scope.row.agentList.length - 1 || scope.row.agentList.length == 1 ? '' : '1px solid #EBEEF5', marginBottom: index == scope.row.agentList.length - 1   || scope.row.agentList.length == 1 ? '' :  '6px'}" v-for="(item,index) in scope.row.agentList">
                    {{item}}
                  </p>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <!--公告线索-->
          <div v-if="leadsRadio == 3">
            <vxe-grid
              v-bind="getQueryNotivceArrGridOption()"
              header-cell-class-name="_el_table_header"
              :row-class-name="businessLeadsRowClassName"
              key="businessLeads_3"
              :highlight-current-row="false"
              style="width: 100%; margin-top: 20px"
              border="inner"
              height="720px"
              :highlight-hover-row="false"
              :highlight-current-column="false"
              align="center"
              header-align="center"
              ref='queryNotivcexGrids'
              @clear-filter="queryNotivceFilterChange"
              @filter-change="queryNotivceFilterChange"
              @sort-change="queryNotivceFilterChange"
            >

              <template #appno="{ row }">
                <span @click="appno(row.appno)" style="color: rgb(100, 131, 237);cursor: pointer">{{row.appno}}</span>
              </template>

              <template #item="{ row }">
                <span @click="copys(row.item)" style="color: rgb(100, 131, 237);cursor: pointer">{{row.item}}</span>
              </template>

              <template #ElDatePicker="{ $panel, column }">
                <div style="display: flex;align-items: center;justify-content: center">
                  <el-form v-for="(option, index) in column.filters" :key="index" style="margin: 10px" :inline="true" class="demo-form-inline">
                    <el-form-item style="margin: 0" label="许可起始日期">
                      <el-date-picker
                        @change="deadlineEvent($event, option, $panel)"
                        v-model="deadline1"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item style="margin: 0"  label="许可截止日期">
                      <el-date-picker
                        @change="deadlineEvent($event, option, $panel)"
                        value-format="yyyy-MM-dd"
                        v-model="deadline2"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                </div>

              </template>

              <!--        分页-->
              <template #pager>
                <div>
                  <vxe-pager
                    border
                    background
                    row-config.keyField="id"
                    :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                    :current-page.sync="queryNotivcePageNos"
                    :page-size.sync="queryNotivceSizes"
                    :total="queryNotivcetotal"
                    @page-change="queryNotivcePageChange">
                  </vxe-pager>
                </div>
              </template>

              <!--自定义空数据模板-->
              <!--            <template #empty>-->
              <!--              <div v-if="(isRelute && switchTypeId == 1) || (isRelutes && value == 7)">-->
              <!--                <img style="width: 700px" src="../../assets/images/query/relute.png">-->
              <!--              </div>-->
              <!--            </template>-->
            </vxe-grid>
          </div>
          <!--续展线索-->
          <div v-if="leadsRadio == 4">
            <vxe-grid
              v-bind="continuationCluesOption"
              header-cell-class-name="_el_table_header"
              :row-class-name="businessLeadsRowClassName"
              :highlight-current-row="false"
              style="width: 100%; margin-top: 20px"
              border="inner"
              height="720px"
              :highlight-hover-row="false"
              :highlight-current-column="false"
              align="center"
              header-align="center"
              ref='continuationCluesxGrids'
              @clear-filter="queryNotivceFilterChanges"
              @filter-change="queryNotivceFilterChanges"
            >

              <template #toolbar_buttons_xlsx>
                <el-popover
                  style="float: right;margin-right: 50px"
                  placement="bottom-start"
                  title=""
                  trigger="hover"
                  content="xls文档下载"
                >
                  <img @click="extensionExexports()" style="cursor: pointer" src="../../assets/images/query/xls.png" slot="reference"/>
                </el-popover>
              </template>

              <template #appno="{ row }">
                <span @click="appno(row.appno)" style="color: rgb(100, 131, 237);cursor: pointer">{{row.appno}}</span>
              </template>

              <template #imageUrl="{ row }">
                <img style="height: 40px;max-width: 100%" :src="queryDataUrl(row.imageUrl)"/>
              </template>

              <!--分页-->
              <template #pager>
                <div>
                  <vxe-pager
                    border
                    background
                    row-config.keyField="id"
                    :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                    :current-page.sync="continuationCluesCurrent"
                    :page-size.sync="continuationCluesSize"
                    :total="continuationCluesTotal"
                    @page-change="handleContinuationCluesPageChange">
                  </vxe-pager>
                </div>
              </template>
            </vxe-grid>
          </div>
        </div>

        <fullscreen v-show="switchTypeId != 6 && !isModeTable" class="fullscreen_father"  :fullscreen.sync="fullscreen">
          <!--        <div  class="tool">-->
          <!--          <vxe-button title="刷新"  @click="query" circle  icon="vxe-icon-repeat"></vxe-button>-->
          <!--          <vxe-button title="全屏"  @click="toggle" circle  :icon="fullscreen ? 'vxe-icon-minimize' : 'vxe-icon-fullscreen' "></vxe-button>-->
          <!--        </div>-->
          <div v-if="!flagyang" class="fullscreen_title">
            <img src="../../assets/images/query/trips.png" alt="">
            请勾选需要在可注册性分析报告中分析的数据
          </div>

          <div v-if="!flagyang" :class="['icon']">
            <div @click="generateReport" class="iconContent">
              生成报告
            </div>
          </div>

          <div @mouseenter="mouseenter" @mouseleave="mouseleave" :class="[{'table-icon-nodata': !isModeTable},{'fullscreens': fullscreen}]">          <!--图标版-->
            <el-checkbox
              style="position: absolute;top: 86px;left: 50px;"
              v-if="gridOptions.data.length > 0"
              :indeterminate="isIndeterminateId"
              v-model="checkAlls"
              @change="IdCheckAllChanges"
            >
              全选
            </el-checkbox>

            <ul id="chatBox" v-if="gridOptions.data.length > 0" :class="['result-ico']">
              <el-checkbox-group v-if="gridOptions.data && gridOptions.data.length" v-model="checkedId" @change="CheckediDChanges">
                <el-popover
                  popper-class="query-result-ico" v-for="item in gridOptions.data"
                  v-if="gridOptions.data && gridOptions.data.length"
                  :key="'icos'+item.id"
                  placement="bottom"
                  width="400"
                  trigger="hover"
                >
                  <div :class="['container']">
                    <section class="top">
                      <div class="left">
                        <div class="tuyang">
                          <div  style="width: 100%;height: 100%;" v-if="item.imageUrl">
                            <img @load="loadImage($event)" :src="queryDataUrl(item.imageUrl)" alt="">
                            <div  @click="returnDataUrl(item.imageUrl)" style="cursor: pointer;font-size: 14px;color: #1e44b9;padding: 5px 0;bottom: -28px">查询该图形</div>
                          </div>
                          <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 18px" v-else>
                            {{item.sbmc}}
                          </div>
                        </div>
                      </div>

                      <ul class="right">
                        <li>
                          <p class="title">{{$t(`PQ.details_item[6]`)}}:</p>
                          <p class="text">
                            <span>{{item.showName}}</span>
                          </p>
                        </li>

                        <li>
                          <p class="title">{{$t(`PQ.details_item[3]`)}}:</p>
                          <p class="text">
                            <span>{{item.showAdress}}</span>
                          </p>
                        </li>

                        <li>
                          <p class="title">{{$t(`PQ.details_item[5]`)}}:</p>
                          <p class="text">{{item.regno}}</p>
                        </li>

                        <li>
                          <p class="title">{{$t(`PQ.accordion[3]`)}}:</p>
                          <p class="text">{{item.intcls}}</p>
                        </li>

                        <li>
                          <p class="title">{{$t(`PQ.details_item[0]`)}}:</p>
                          <p class="text">{{item.tmstatus}}</p>
                        </li>

                        <li>
                          <p class="title">{{$t(`PQ.accordion[4]`)}}: </p>
                          <p class="text">{{item.appdate}}</p>
                        </li>

                        <li>
                          <p class="title">{{$t(`PQ.accordion[5]`)}}:</p>
                          <p class="text">{{item.regdate}}</p>
                        </li>
                      </ul>
                    </section>

                    <section class="line" v-if="false&&!item.isDisabled"></section>

                    <section class="bottom" v-if="false&&!item.isDisabled">
                      <span class="btn" @click="confirmFn2(item)">去续展</span>
                    </section>
                  </div>

                  <li style="background: white" slot="reference">
                    <div class="trademark-patterns">
                      <span class='state' :style="{background:setColor(item.tmstatus), color: '#FFFFFF'}">{{item.alias}}</span>
                      <p class="small-item" v-if="isapplication">
                      <span class="text" :class="{'level-high':item.level=='高' || item.level=='High','level-middle':item.level=='中' || item.level=='Middle','level-low':item.level=='低' || item.level=='Low'}">
                          <i class="level-graphical"></i>
                          <i class="text-level">{{item.level}}</i>
                        </span>
                      </p>
                    </div>

                    <div class="trademark-pattern" @click="goDetail(item)">
                      <div class="trademark-pattern-img">
                        <img  @load="loadImage($event)" :src="queryDataUrl(item.imageUrl)" alt="" v-if="item.imageUrl">
                        <span :style="{fontSize: item.sbmc && item.sbmc.length > 20 ? '22px' : '35px',fontWeight: 'bold'}" v-else>{{item.sbmc}}</span>
                      </div>
                    </div>

                    <dl style="color:#000;font-size: 12px">
                      <dd>
                        <p>
                          {{$t(`PQ.accordion[12]`)}}
                          <span style="display: inline-block;max-width: 150px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;vertical-align: text-top;">{{item.intcls}}</span>
                          {{$t(`PQ.accordion[13]`)}}
                          <i v-if="item.miaoSu">-{{item.miaoSu}}</i>
                        </p>

                      <el-checkbox class="el_checkbox" :label="JSON.stringify({
            id: item.id,
            similarity: item.level
          })" :key="JSON.stringify({
            id: item.id,
            similarity: item.level
          })"></el-checkbox>
                    </dd>

                      <dd><span style="font-weight: 500;">{{$t(`PQ.details_item[5]`)}}:</span> {{item.regno}}</dd>
                      <dd><span style="font-weight: 500;">{{$t(`PQ.details_item[6]`)}}:</span> {{item.showName}}</dd>
                    </dl>

                    <img :src="queryDataUrl(item.imageUrl)" alt="" class="showbig" v-if="item.showBig">
                  </li>
                </el-popover>
              </el-checkbox-group>
            </ul>

<!--            <div>-->
<!--              <vxe-pager-->
<!--                border-->
<!--                background-->
<!--                :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"-->
<!--                :current-page.sync="pageNos"-->
<!--                :page-size.sync="pageSizes"-->
<!--                :total="total"-->
<!--                @page-change="handlePageChange"-->
<!--              />-->
<!--            </div>-->

            <div v-if="gridOptions.data.length == 0 && !isModeTable && isRelute" style="display: flex;justify-content: center;align-items: center;height: 100%">
              <div>
                <img src="../../assets/images/query/relute.png">
              </div>
            </div>
          </div>
        </fullscreen>

        <div style="margin-bottom: 15px;display: flex;justify-content: space-between;" v-if="switchTypeId == 5">
          <div v-if="!extensionFlag1">
            <el-button :key="Math.random()" @click="addRenewal" style="margin-left: 37px" round>添加到续展监控</el-button>
          </div>

          <div style="display: flex" v-else>
            <div style="position: relative">
              <el-tooltip style="position: absolute;position: absolute;right: -18px;" class="item" effect="light" content="仅支持计算当前年份的续展商标费用" placement="left">
                <i style="color: #FF2600" class="el-icon-warning"></i>
              </el-tooltip>

              <el-button class="cost" :key="Math.random()" @click="costCalculation" style="margin-left: 37px" round>
                <div>
                  <img src="../../assets/images/query/cost.png" alt="">
                  <img src="../../assets/images/query/cost1.png" alt="">
                  费用计算
                </div>
              </el-button>
            </div>

            <el-button class="printfs" :key="Math.random()" @click="paymentList" :disabled="isPaymentList" style="margin-left: 37px" round>
              <div>
                <img src="../../assets/images/query/printf.png" alt="">
                <img src="../../assets/images/query/printf1.png" alt="">
                生成缴费清单
              </div>
            </el-button>
          </div>

          <div style="position: relative;float:right;margin-right: 37px">
            <el-tooltip style="position: absolute;position: absolute;right: -18px;" class="item" effect="light" content="因缴费时间会影响续展费用，请以当天计算的费用为准。计算结果仅供参考。" placement="left">
              <i style="color: #FF2600" class="el-icon-warning"></i>
            </el-tooltip>
            <el-button :key="Math.random()" @click="myRenewal" style="" round><i class="el-icon-user-solid"></i>我的续展</el-button>
          </div>
        </div>
        <vxe-grid
          v-if="switchTypeId != 6"
          :class="[{'vxe_grid_toolbar': switchTypeId == 5}]"
          header-row-class-name="vxe_grid_header"
          border="inner"
          height="720px"
          :highlight-current-row="true"
          :highlight-hover-row="true"
          :highlight-current-column="true"
          align="center"
          header-align="center"
          v-show="isModeTable"
          ref='xGrid'
          @checkbox-change="isChecked"
          @checkbox-all="isCheckeds"
          :cell-class-name="cellClassName"
          :row-class-name="tableRowClassNames"
          v-bind="(value == 8 && switchTypeId == 1) ? gridOption : switchTypeId ==4 && invalidAnnouncement == false? announcement :  switchTypeId ==4 && invalidAnnouncement == true? announcements :  switchTypeId == 5 && extensionFlag ? extension  : gridOptions "
          @cell-click="cellClick"
          @clear-filter="filterChange"
          @filter-change="filterChange"
          @sort-change="filterChange"
        >
          <!--        导出-->
          <!--   工具栏     -->
          <template #toolbar_buttons>
            <vxe-pager
              border
              background
              row-config.keyField="id"
              :class="[{'active':gridOptions.data.length > 0}]"
              :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
              :current-page.sync="pageNos"
              :page-size.sync="pageSizes"
              :total="total"
              @page-change="handlePageChange">
            </vxe-pager>
          </template>

          <template #toolbar_buttons_xlsx>
            <el-popover
              style="float: right;margin-right: 50px"
              placement="bottom-start"
              title=""
              trigger="hover"
              content="xls文档下载"
            >
              <img @click="Exexports()" style="cursor: pointer" src="../../assets/images/query/xls.png" slot="reference"/>
            </el-popover>
          </template>

          <!--  @click="goDetail(row)      图片-->
          <template #trialImageUrl="{ row }">
            <img @click.stop="particulars" style="max-height: 48px;width: 100%" v-if="row.trialImageUrl" :src="row.trialImageUrl" alt=""/>
          </template>

          <!--  @click="goDetail(row)      商标续展-商标图样-->
          <template #trialImageUrls="{ row }">
            <img style="max-height: 48px;width: 100%" v-if="row.trademarkImageUrl" :src="row.trademarkImageUrl" alt=""/>
          </template>

          <!--  @click="goDetail(row)      提交方式-->
          <template #submitType="{ row }">
          <span v-if="!extensionFlag1">
            {{row.submitType == 0 ? '电子' : '纸质'}}
          </span>

            <el-select v-else size="mini"   v-model="row.submitType" placeholder="请选择">
              <el-option
                v-for="item in submitTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>

          <!--        图片详情-->
          <template #imageUrl="{ row }">
            <el-tooltip effect="light" v-if="row.imageUrl" placement="right">
              <div style="width: 300px;height: auto" slot="content">
                <img style="height: 100%;max-width: 100%"  :src="queryDataUrl(row.imageUrl)" alt=""/>
              </div>
              <img style="height: 100%;max-width: 100%"  :src="queryDataUrl(row.imageUrl)" alt=""/>
            </el-tooltip>

            <span v-else>{{row.sbmc}}</span>
          </template>

          <!--        商标详情页-->
          <template #trialDetail="{ row }">
            <img style="cursor: pointer" @click="trimlDetail(row)" src="../../assets/images/query/trimlDetail.png" alt="">
          </template>

          <!--        工具箱-->
          <template #degree="{ row }">
            <div class="text" :class="{'level-high':row.level=='高','level-middle':row.level=='中','level-low':row.level=='低'}">
              <i class="level-graphical"></i>
              <i style="margin-left: 5px">{{row.level}}</i>
            </div>
          </template>

          <!--        近似度-->
          <template #degree="{ row }">
            <div class="text" :class="{'level-high':row.level=='高','level-middle':row.level=='中','level-low':row.level=='低'}">
              <i class="level-graphical"></i>
              <i style="margin-left: 5px">{{row.level}}</i>
            </div>
          </template>

          <!--        详情-->
          <template #num1_default="{row}">
            <div style="display: flex;background: #F3F9FE;align-items: center" class="alert-message">
              <div style="height: 184px;width: 206px;background:#FFFFFF;border-radius: 10px; align-items: center;justify-content: center;display: flex;margin: 25px 10px 45px 2%;box-shadow: 0px 0px 7px 1px rgba(14, 36, 64, 0.12);position: relative">
                <div
                  class="imgBox"
                  style="width: 206px;max-height: 184px;display: flex;justify-content: center"
                  v-if="!imagecodeFlag && mineInfor.image_url"
                >
                  <img
                    :src="queryDataUrl(mineInfor.image_url)"
                    class="imghover"
                    style="height: auto;border-radius: 10px;max-width: 89%;max-height: 89%"
                  />

                  <div @click="returnDataUrl(mineInfor.image_url)" style="position: absolute;bottom: -34px;width: 100%;text-align: center;cursor: pointer;font-size: 14px;color: #1e44b9;padding: 5px 0">查询该图形</div>
                </div>

                <div
                  v-else-if="!imagecodeFlag && mineInfor.sbmc"
                  class="imgBox"
                  style="overflow: hidden;"
                >
                  <span>{{mineInfor.sbmc}}</span>
                </div>

                <div
                  v-else
                  class="imgBox"
                  style="overflow: hidden;cursor: pointer"
                  @click="copys(row.imagecodeList.join(';'))"
                >
                  <span style="display: block;font-size: 20px" v-for="(item,index) in row.imagecodeList" :key="index">{{item}}</span>
                </div>
              </div>

              <!--查看详情-->
              <ul class="translate" cellspacing="0"
                  style="margin-top: 15px;width: calc(100% - 300px - 206px - 55px - 2%)">
                <li style="background: #D9E2F1;height: 28px;border-radius: 8px 8px 0px 0px;">
                  <!--日期-->
                  <p class="sbmc" style="text-align: left">
                    <span>商标名称：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.sbmc)">{{mineInfor.sbmc}}</span>
                  </p>

                  <!--订单编号-->
                  <p style="text-align: left">
                    <span>商标状态：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.tmstatus)">{{mineInfor.tmstatus}}</span>
                  </p>

                  <!--翻译类型-->
                  <p style="text-align: left">
                    <span>是否共有商标：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.istotal)">{{mineInfor.istotal}}</span>
                  </p>

                  <p style="text-align: left">
                    <span>国际分类：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.intcls)">{{mineInfor.intcls}}</span>
                  </p>
                </li>

                <li style="height: 28px">
                  <!--日期-->
                  <p style="text-align: left">
                    <span>申请人：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.country == 'CN' ? mineInfor.tmapplicant : mineInfor.applicant_en)">{{mineInfor.country == 'CN' ? mineInfor.tmapplicant : mineInfor.applicant_en}}</span>
                  </p>

                  <!--订单编号-->
                  <p style="text-align: left">
                    <span>商标类型：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.tmtype)">{{mineInfor.tmtype}}</span>
                  </p>

                  <!--翻译类型-->
                  <p style="text-align: left">
                    <span>类似群组：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.similargroup)">{{mineInfor.similargroup}}</span>
                  </p>

                  <!--字数-->
                  <p style="text-align: left">
                    <span>专用权期限：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.validdate)">{{mineInfor.validdate}}</span>
                  </p>
                </li>

                <li style="background: #D9E2F1;height: 28px;border-radius: 8px 8px 0px 0px;">
                  <!--日期-->
                  <p style="text-align: left">
                    <span>申请号：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.appno)">{{mineInfor.appno}}</span>
                  </p>

                  <!--订单编号-->
                  <p style="text-align: left">
                    <span>注册公告期号：</span>
                    <span style="cursor: pointer;color: #6483ED" @click="copys(mineInfor.regnum,true);querymentDetail(mineInfor.regnum,mineInfor.appno)">{{mineInfor.regnum}}</span>
                  </p>

                  <!--翻译类型-->
                  <p style="text-align: left">
                    <span>初审公告期号：</span>
                    <span style="cursor: pointer;color: #6483ED" @click="copys(mineInfor.trialnum,true);querymentDetail(mineInfor.trialnum,mineInfor.appno)">{{mineInfor.trialnum}}</span>
                  </p>
                  <!--字数-->
                  <p style="text-align: left">
                    <span>优先权日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.prioritydate)">{{mineInfor.prioritydate}}</span>
                  </p>
                </li>

                <li style="height: 28px">
                  <!--日期-->
                  <p style="text-align: left">
                    <span>申请日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.appdate)">{{mineInfor.appdate}}</span>
                  </p>

                  <!--订单编号-->
                  <p style="text-align: left">
                    <span>注册公告日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.ggdate)">{{mineInfor.ggdate}}</span>
                  </p>

                  <!--翻译类型-->
                  <p style="text-align: left">
                    <span>初审公告日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.trialdate)">{{mineInfor.trialdate}}</span>
                  </p>

                  <!--字数-->
                  <p v-if="mineInfor.country == 'CN'" style="text-align: left">
                    <span>代理机构：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.tmagent)">{{mineInfor.tmagent}}</span>
                  </p>
                  <p v-else style="text-align: left">
                    <span>联系人名称：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.correspondence_name)">{{mineInfor.correspondence_name}}</span>
                  </p>
                </li>

                <li style="background: #D9E2F1;height: 28px;border-radius: 8px 8px 0px 0px;">
                  <!--日期 address_en -->
                  <p style="text-align: left">
                    <span>申请人地址：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.country == 'CN' ? mineInfor.tmaddress : mineInfor.address_en)">{{mineInfor.country == 'CN' ? mineInfor.tmaddress : mineInfor.address_en}}</span>
                  </p>

                  <!--订单编号-->
                  <p v-if="mineInfor.country == 'CN'" style="text-align: left">
                    <span>商标撤三日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.fromThree)"> {{mineInfor.fromThree}}</span>
                  </p>

                  <p v-else style="text-align: left">
                    <span>国际注册日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.interregdate)">{{mineInfor.interregdate}}</span>
                  </p>

                  <!--翻译类型-->
                  <p style="text-align: left">
                    <span>后期指定日期：</span>
                    <span style="cursor: pointer" @click="copys(mineInfor.latespecifieddate)">{{mineInfor.latespecifieddate}}</span>
                  </p>

                  <!--日期-->
                  <p style="text-align: left">
                    <span>商品服务：</span>
                    <span style="cursor: pointer" @click="copys(row.showTmdetail)">{{row.showTmdetail}}</span>
                  </p>
                </li>

                <li style="height: 29px"></li>
              </ul>

              <div class="progressd" style="width:300px;height: 225px;overflow-y: auto;border-radius: 8px 8px 0px 0px">
                <span class="progressTop_title">商标流程</span>

                <table v-if="row.country == 'CN'" cellspacing="0" class="progressTop">
                  <tr style="border-radius: 8px 8px 0px 0px!important;">
                    <th></th>
                    <th>{{$t('query_lang.dddate')}}</th>
                    <th class="yewu">{{$t(`tra_detail_cn.classTable[3]`)}}</th>
                    <th class="huanjie">{{$t(`tra_detail_cn.classTable[4]`)}}</th>
                  </tr>

                  <tr v-for="(valList,index2) in flowData" :key="index2" class="progressBottom">
                    <td class="inailw">
                      <img :src="require('../../assets/images/query/huilineLittle.png')" alt/>
                    </td>
                    <td>{{valList.flowdate}}</td>
                    <td>{{valList.shui}}</td>
                    <el-tooltip class="item" effect="dark" :content="valList.shuiOne" placement="top">
                      <td>{{valList.shuiOne}}</td>
                    </el-tooltip>
                  </tr>
                </table>

                <table v-else cellspacing="0" class="progressTop">
                  <tr style="border-radius: 8px 8px 0px 0px;">
                    <th></th>
                    <th style="width: 100px">{{$t('query_lang.dddate')}}</th>
                    <th style="width: 150px">流程</th>
                  </tr>

                  <tr class="progressBottom" v-for="(list, index) in flowData" :key="index">
                    <td class="inailw">
                      <img src="../../assets/images/query/huilineLittleOne.png" alt/>
                    </td>
                    <td style="width: 100px">{{list.flowdate}}</td>
                    <el-tooltip class="item" effect="light" :content="list.flowenname" placement="top">
                      <td style="width: 150px">{{list.flowenname}}</td>
                    </el-tooltip>
                  </tr>
                </table>
              </div>

              <div class="translate_imhCode">
                <div>
                  <el-button :style="{'padding': '0px!important','color': !mineInfor.sbmc || mineInfor.sbmc == '图形' ? '#c0c4cc' : '#1E44B9'}" @click="evidence(row)" type="text" :disabled="!mineInfor.sbmc || mineInfor.sbmc == '图形' || mineInfor.country != 'CN'" >网络证据</el-button>
                </div>
                <div @click="yaosuCopy(row.imagecode)">图形要素编码</div>
                <div @click="goDetail(row)"><img src="../../assets/images/query/details.png" alt="">
                  <span>详情页</span>
                </div>
              </div>
            </div>
          </template>

          <!--        分页-->
          <template #pager>
            <div v-if="(switchTypeId == 1 && value != 8 && switchTypeId != 4)|| value == 7 || (switchTypeId == 5 && !extensionFlag)">
              <vxe-pager
                border
                background
                :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                :current-page.sync="pageNos"
                :page-size="isPageSize ? gridOptions.data.length : pageSizes"
                :total="total"
                :page-sizes="isPageSize ? [gridOptions.data.length]  : [10, 15, 20, 50, 100]"
                @page-change="handlePageChange"
              />
            </div>

            <div v-if="(switchTypeId == 4 && invalidAnnouncement) || (switchTypeId == 5 && extensionFlag) || value == 8">
              <vxe-pager
                border
                background
                :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                :current-page.sync="pageNoss"
                :page-size.sync="pageSizess"
                :total="totals"
                @page-change="handlePageChanges"
              />
            </div>
          </template>

          <!--自定义空数据模板-->
          <template #empty>
            <div v-if="(isRelute && switchTypeId == 1) || (isRelutes && value == 7)">
              <img style="width: 700px" src="../../assets/images/query/relute.png">
            </div>
          </template>
        </vxe-grid>
      </el-card>

      <div v-if="trademarkFlag && !invalidAnnouncement" class="pdfImg">
        <img @click="pdfs(queryDataUrl('/'+pdfSrc))" class="item1" :src="require('../../assets/images/query/pdf_icon.png')" alt="">
        <img class="item2" style="width: 100%;height: 100%" :src="pdfSrc" alt="">
      </div>

      <!--    数据库-->
      <el-card :class="['boxCards']" v-if="boxShow">
        <!--    数据库-->
        <div class="index_1">
          <el-checkbox @change="database" v-model="checked">
            {{$t(`PQ.queryModes[6]`)}}
          </el-checkbox>
          <div>
            <el-button @click.stop="rotate" type="primary" size="mini">确定</el-button>
          </div>
        </div>

        <!--亚洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="asiaIsIndeterminate" v-model="asiaCheckAll" @change="handleCheckAllChange">
              {{$t(`PQ.country[0]`)}} ({{index1}}/14)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="asia" @change="handleCheckedCitiesChange">
              <el-checkbox class="checkbox" v-for="(item,index) in asiaCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--欧洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="europeIsIndeterminate" v-model="europeCheckAll" @change="handleCheckAllChanges">
              {{$t(`PQ.country[1]`)}} ({{index2}}/19)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="europe" @change="handleCheckedCitiesChanges">
              <el-checkbox class="checkbox" v-for="(item,index) in europeCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--北美洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="americaIsIndeterminate" v-model="americaCheckAll" @change="handleCheckAllChangess">
              {{$t(`PQ.country[2]`)}} ({{index3}}/3)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="america" @change="handleCheckedCitiesChangess">
              <el-checkbox class="checkbox" v-for="(item,index) in americaCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--南美洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="southIsIndeterminate" v-model="southCheckAll" @change="handleCheckAllChangesss">
              {{$t(`PQ.country[3]`)}} ({{index4}}/3)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="south" @change="handleCheckedCitiesChangesss">
              <el-checkbox class="checkbox" v-for="(item,index) in southCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--非洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="africaIsIndeterminate" v-model="africaCheckAll" @change="handleCheckAllChangessss">
              {{$t(`PQ.country[4]`)}} ({{index5}}/1)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="africa" @change="handleCheckedCitiesChangessss">
              <el-checkbox class="checkbox" v-for="(item,index) in africaCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--大洋洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="oceaniaIsIndeterminate" v-model="oceaniaCheckAll" @change="handleCheckAllChangesssss">
              {{$t(`PQ.country[5]`)}} ({{index6}}/2)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="oceania" @change="handleCheckedCitiesChangesssss">
              <el-checkbox class="checkbox" v-for="(item,index) in OceaniaCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--知识产权组织-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="propertyIndeterminate" v-model="propertyCheckAll" @change="handleCheckAllChangessssse">
              {{$t(`PQ.country[6]`)}} ({{index7}}/1)
            </el-checkbox>
          </div>

          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="property" @change="handleCheckedCitiesChangessssse">
              <el-checkbox class="checkbox" v-for="(item,index) in propertyOrganizationCity" :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-card>

      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog" :before-close="closeEvent">
        <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
      </el-dialog>

      <el-dialog
        title="系统错误"
        :visible.sync="dialogVisiblePrompt"
        width="30%"
      >
        <span>系统升级，暂不支持43国全类查询</span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePrompt = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisiblePrompt = false">确 定</el-button>
      </span>
      </el-dialog>

      <!--    商标对比-->
      <el-dialog title="" class="el_dialog"  :show-close="false" :close-on-click-modal="false" :visible.sync="dialogTableVisible">
        <div class="header_title">
          <p class="title">请选择申请商标:</p>
          <i @click="clase" class="el-icon-close icon"></i>
        </div>

        <div class="content">
          <el-radio-group v-model="radioValue">
            <el-radio v-for="(item,index) in selectData" :key="index" :label="item.appno">{{item.appno}}</el-radio>
          </el-radio-group>
        </div>

        <div @click="goCcomparison" class="btn">
          去对比
        </div>
      </el-dialog>

      <el-dialog  class="trademarkAdvanceds" :close-on-click-modal="false" title="" :visible.sync="dialogTableVisibles">
        <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
          <el-select v-model="selects" slot="prepend" placeholder="商标名称">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>

          <el-button @click="searchAdvancedFlag = true;searchAdvanced()" slot="append" >查询</el-button>
        </el-input>

        <div class="content">
          <div class="left">
            <span>公告类型</span>
            <span>公告期号</span>
            <span>{{isFlag ? '无效原因' : '审查决定'}}</span>
          </div>

          <div class="right">
            <el-radio-group v-model="radio1">
              <el-radio label="无效公告">无效公告</el-radio>
              <el-radio label="注册商标宣告无效公告">注册商标宣告无效公告</el-radio>
            </el-radio-group>

            <div style="display: flex;align-items: center">
              <el-radio-group style="margin-top: 31px" v-model="radio2">
                <el-radio label="3">最近3期</el-radio>
                <el-radio style="margin-left: 6px" label="2">最近2期</el-radio>
                <el-radio style="margin-left: 6px" label="1">最近1期</el-radio>
              </el-radio-group>

              <el-input placeholder="自定义期号" class="radio2_input" size="small" v-model="input4"/>
            </div>

            <img @click="arrows1" v-if="radio1 == '无效公告' && arrow1" class="arrow1" src="../../assets/images/query/arrow1.png" alt="">
            <img @click="arrows1" v-if="radio1 == '无效公告' && !arrow1" class="arrow1" src="../../assets/images/query/arrow2.png" alt="">

            <el-checkbox-group v-if="isFlag" style="margin-top: 31px" v-model="radio3">
              <el-checkbox label="因异议无效">因异议无效</el-checkbox>
              <el-checkbox label="评审形审首次形审受理财务通过">评审形审首次形审受理财务通过</el-checkbox>
              <el-checkbox style="margin-top: 25px" label="评审形审首次形审受理财务无款不予受理">评审形审首次形审受理财务无款不予受理</el-checkbox>
              <el-checkbox style="margin-top: 25px" label="评审形审不予受理处长审核">评审形审不予受理处长审核</el-checkbox>
              <el-checkbox style="margin-top: 25px" label="评审形审补正审查视为撤回结论">评审形审补正审查视为撤回结论</el-checkbox>
              <el-checkbox style="margin-top: 25px" label="该商标已进入司法应诉阶段，驳回复审裁定尚未生效">该商标已进入司法应诉阶段，驳回复审裁定尚未生效</el-checkbox>
              <template v-if="radio1 == '无效公告' && !arrow1">
                <el-checkbox style="margin-top: 25px" label="该商标已进入司法应诉阶段，不予注册复审裁定尚未生效">该商标已进入司法应诉阶段，不予注册复审裁定尚未生效</el-checkbox>
                <el-checkbox style="margin-top: 25px" label="该商标已进入司法应诉阶段，无效宣告裁定尚未生效">该商标已进入司法应诉阶段，无效宣告裁定尚未生效</el-checkbox>
                <el-checkbox style="margin-top: 25px" label="该商标已进入司法应诉阶段，撤销复审裁定尚未生效">该商标已进入司法应诉阶段，撤销复审裁定尚未生效</el-checkbox>
                <el-checkbox style="margin-top: 25px" label="其他原因">其他原因</el-checkbox>
              </template>
            </el-checkbox-group>

            <el-checkbox-group v-else style="margin-top: 31px" v-model="radio3">
              <el-checkbox label="无效宣告全部无效">全部无效</el-checkbox>
              <el-checkbox label="无效宣告部分成立">部分无效</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-dialog>

      <!--一键添加商标监控浮窗-->
      <el-dialog width="560px" :visible.sync="trademarkMonitorDialogShow" class="long-dialog">
        <div class="monitorDialog clearfix">
          <div class="mainBody">
            <div>
              <!--国家/地区-->
              <div class="check">
              <span class="title" style="vertical-align: middle">
                所属国家/地区
                <span class="requiredStar">*</span>
              </span>

                <el-select
                  v-model="trademarkMonitorData.countrySet"
                  multiple
                  clearable
                  ref="fuzzySearch"
                  placeholder="请选择"
                  @change="trademarkMonitorSelectCountryFn"
                  style="width:380px"
                >
                  <el-option
                    v-for="item in [{label: '', value: ''}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div @click.stop style="display: flex; justify-content: space-between; margin-bottom: 5px">
                      <el-input
                        clearable
                        style="margin-right: 5px"
                        size="small"
                        placeholder="输入关键字进行过滤"
                        v-model="trademarkMonitorCountryFilterText"
                      />

                      <el-button style="height: 29px;margin-top: 2px" type="primary" @click.stop="trademarkMonitorCountrySelectConfirm" size="mini">确认</el-button>
                    </div>

                    <el-tree
                      :default-expanded-keys="[0]"
                      class="filter-tree"
                      :filter-node-method="trademarkMonitorCountryFilterNode"
                      ref="tree"
                      :data="trademarkMonitorCountryList"
                      show-checkbox
                      node-key="code"
                      :props="trademarkMonitorCountryTreeProps"
                    />
                  </el-option>
                </el-select>
              </div>
              <!--商标图样-->
              <div class="check">
                <span class="title" style="vertical-align: middle">商标图样</span>

                <span class="right" style="vertical-align: middle;">
                <div style="display: inline-block; height: 120px; width: 100%; border: 1px #dddddd solid; border-radius: 5px">
                  <img :src="getMonitorImageUrl(trademarkMonitorData.iconImageUrl)" v-if="trademarkMonitorData.iconImageUrl" class="upload-img"/>

                  <p v-if="trademarkMonitorData.iconImageUrl" class="delete" style="cursor: pointer" @click.stop="trademarkMonitorDeleteImg">{{$t("update_lang.btn_delete")}}</p>
                </div>
              </span>
              </div>

              <!--商标名称-->
              <div style="display: flex;align-items: center" class="check">
                <span class="title">商标名称</span>

                <span style="display: flex;align-items: center" class="right">
                <el-input
                  class="el-input-long-officialt active"
                  v-model="trademarkMonitorData.trademarkName"
                  placeholder="请输入文字"
                  :maxlength=20
                />
                <el-tooltip class="item" effect="light" content="请注意输入的商标名称语种与选择的国家是否匹配。例如，监控商标【蓝灯鱼】，监控国家【美国】，监控结果为空。" placement="top-start">
                  <i style="margin-left: 10px;font-size: 18px" class="el-icon-info"/>
                </el-tooltip>
              </span>
              </div>

              <!--商标类别-->
              <div class="check">
              <span class="title">
                监控类别
                <span class="requiredStar">*</span>
              </span>

                <span class="right niceCode">
                <el-input
                  class="leftText el-input-long-officialt"
                  :disabled="true"
                  placeholder="请通过右侧选择按钮选定类别"
                  v-model="trademarkMonitorData.niceSet"
                />

                <span class="rightText" @click="trademarkMonitorCategorySelectOpen();trademarkMonitorCategoryType(true)" style="cursor: pointer">{{$t('query_lang.please_choose')}}</span>
              </span>
              </div>

              <!--监控类型-->
              <div class="check">
              <span class="title">
                监控类型
                <span class="requiredStar">*</span>
              </span>

                <span class="right">
                <el-input disabled v-model="trademarkMonitorData.monitorType == 1 ? '近似监控' : ''" placeholder="近似监控"/>
              </span>
              </div>

              <!--拓展监控(撤三监控/续展监控)-->
              <div class="check" v-if="this.trademarkMonitorContainCN">
                <el-checkbox v-model="trademarkMonitorData.revokeThreeFlag" :label=true>撤三监控</el-checkbox>
                <el-checkbox v-model="trademarkMonitorData.renewalFlag" :label=true>续展监控</el-checkbox>
              </div>

              <div v-if="(trademarkMonitorData.revokeThreeFlag || trademarkMonitorData.renewalFlag) && this.trademarkMonitorContainCN" class="check">
              <span class="title">
                商标申请号
                <span class="requiredStar">*</span>
              </span>

                <span class="right">
                <el-input placeholder="商标申请号" v-model="trademarkMonitorData.trademarkApplyNo"/>
              </span>
              </div>

              <div v-if="trademarkMonitorData.revokeThreeFlag && this.trademarkMonitorContainCN" class="check">
              <span>
                是否是本商标的专有权人
                <span class="requiredStar">*</span>
              </span>

                <span style="display: inline-block; margin-left: 20px">
                <el-select v-model="trademarkMonitorData.trademarkOwnFlag" style="width: 300px">
                  <el-option label="否" :value = false />
                  <el-option label="是" :value = true />
                </el-select>
              </span>
              </div>

              <div v-if="trademarkMonitorData.renewalFlag && this.trademarkMonitorContainCN" class="check">
              <span class="title">
                商标类别
                <span class="requiredStar">*</span>
              </span>

                <span class="right niceCode">
                <el-input
                  class="leftText el-input-long-officialt"
                  :disabled="true"
                  placeholder="商标类别仅支持选择 1 个"
                  v-model="trademarkMonitorData.renewalNiceCode"
                />

                <span class="rightText" @click="trademarkMonitorCategorySelectOpen();trademarkMonitorCategoryType(false)" style="cursor: pointer">{{$t('query_lang.please_choose')}}</span>
              </span>
              </div>

              <div style="margin-top: 20px;text-align: center" class="check">
                <el-button style="width: 100px" :disabled="trademarkMonitorIsDisabled" type="primary" @click="trademarkMonitorSave">保存</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!--尼斯分类-->
      <el-dialog :visible.sync="trademarkMonitorNiceVisible" class="long-dialog-old" style="margin-top: 23px">
        <div class="long-inner" style="width: 741px; height: 351px;">
          <classify @closeFn="trademarkMonitorClose" @classifyConfirm="trademarkMonitorClassifyType" class="high-classify" style="height: 532px; width: 741px"/>
        </div>
      </el-dialog>

      <!--    图片和图片上文字-->
      <el-dialog width="550px" center title="将图片和图片上文字同时进行查询，结果更准确" :visible.sync="UloaddialogTableVisible">
        <el-upload
          v-if="switchTypeId == 1"
          action=""
          ref="upload"
          drag
          class="left-upload camera2"
          :show-file-list="false"
          :on-change="uploadChangeFn"
          :on-remove="handleRemove"
          :auto-upload="false"
          :class="{vh:queryModes=='text'}"
        >
          <div style="display: flex;justify-content: center">
            <img v-if="imageUrla" :src="imageUrla" class="avatar"/>

            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传JPG/JEPG/PNG/GIF/WEBP/BMP文件格式，且不超过5MB</div>
            </div>
          </div>
        </el-upload>

        <div style="display: flex;justify-content: center;align-items: center;margin-top: 25px">
          <div style="width: 350px;display: flex">
            <span style="width: 125px;font-size: 18px;display: block;margin-top: 5px">图片上文字：</span>
            <el-input style="width: 250px;" size="medium" placeholder="请输入内容" v-model="imageText" clearable></el-input>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
        <el-button @click="clearChose" >重新选择</el-button>
        <el-button @click="render" type="primary">提交</el-button>
      </span>
      </el-dialog>

      <el-dialog class="categoryTypeVisible"  width="580px"  :visible.sync="categoryTypeVisible">
        <p slot="title" class="title">
          {{`您选择的第${niceCrossChecked}类，与以下类别的商品相似，是否要为您一起查询？`}}
        </p>

        <el-table :cell-style="{color: '#333333'}" header-cell-class-name="_el_table_header"  height="500" @selection-change="handleSelectionChanges" :data="categoryTypeTable">
          <el-table-column
            align="center"
            header-align="center"
            show-overflow-tooltip
            type="selection"
            width="30"
          />
          <el-table-column
            align="center"
            header-align="center"
            show-overflow-tooltip
            label="群组"
            width="55"
            prop="item3"
          />
          <el-table-column
            align="center"
            header-align="center"
            show-overflow-tooltip
            label="群组信息"
            prop="item2"
          />
          <el-table-column
            align="center"
            header-align="center"
            show-overflow-tooltip
            prop="item1"
            label="交叉群组"
            width="80"
          />
        </el-table>

        <div slot="footer" class="dialog-footer">
          <el-button @click="categoryTypeVisible = false">取 消</el-button>
          <el-button @click="que" type="primary" >确 定</el-button>
        </div>
      </el-dialog>

      <div v-if="ifShowAnalysisIcon && switchTypeId == 1" class="myAnalysisIcon" @click = "handleClickMyAnalysisIcon">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="&#230;&#138;&#165;&#229;&#145;&#138;&#232;&#175;&#166;&#230;&#131;&#133;-&#229;&#155;&#190;&#230;&#150;&#135;&#230;&#138;&#165;&#229;&#145;&#138; 1" clip-path="url(#clip0_541_5)">
            <path id="Vector" d="M0 14C0 17.713 1.475 21.274 4.10051 23.8995C6.72601 26.525 10.287 28 14 28C17.713 28 21.274 26.525 23.8995 23.8995C26.525 21.274 28 17.713 28 14C28 10.287 26.525 6.72601 23.8995 4.10051C21.274 1.475 17.713 0 14 0C10.287 0 6.72601 1.475 4.10051 4.10051C1.475 6.72601 0 10.287 0 14Z" fill="#4864E6"/>
            <path id="Vector_2" d="M19.895 19.5265C20.0101 19.5265 20.1205 19.5722 20.2019 19.6536C20.2833 19.735 20.329 19.8454 20.329 19.9605V20.829C20.329 20.886 20.3178 20.9425 20.2959 20.9952C20.2741 21.0479 20.2421 21.0958 20.2017 21.1361C20.1614 21.1764 20.1135 21.2083 20.0607 21.2301C20.008 21.2519 19.9515 21.2631 19.8945 21.263H8.6055C8.54846 21.2631 8.49197 21.2519 8.43926 21.2301C8.38655 21.2083 8.33865 21.1764 8.29829 21.1361C8.25794 21.0958 8.22593 21.0479 8.20408 20.9952C8.18224 20.9425 8.171 20.886 8.171 20.829V19.96C8.171 19.903 8.18224 19.8465 8.20408 19.7938C8.22593 19.7411 8.25794 19.6932 8.29829 19.6529C8.33865 19.6126 8.38655 19.5807 8.43926 19.5589C8.49197 19.5371 8.54846 19.5259 8.6055 19.526H19.8945L19.895 19.5265ZM21.631 6.5C22.111 6.5 22.4995 6.889 22.4995 7.3685V17.7895C22.4995 18.2695 22.1105 18.658 21.631 18.658H6.8685C6.3885 18.658 6 18.269 6 17.7895V7.3685C6 6.8885 6.389 6.5 6.8685 6.5H21.6315H21.631ZM17.6075 9.9735L15.3505 12.203L14.3255 11.1895L14.3275 11.1875L13.223 10.0955L13.2195 10.097L13.218 10.0955L12.143 11.1565L12.1445 11.158L9.9075 13.37L11.011 14.4625L13.251 12.2505L15.367 14.3405L15.383 14.327L15.396 14.341L18.7125 11.066L17.6075 9.9735Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_541_5">
              <rect width="28" height="28" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <el-drawer
        v-if="switchTypeId == 1"
        custom-class="queryDrawer"
        :visible.sync="ifShowAnalysisReport"
        :append-to-body="true"
        size="580px"
      >
        <analysis-report
          v-if="ifShowAnalysisReport"
          :reportType = reportType
          :queryKeyword = queryKeyword
          :queryData = myQueryData
        />
      </el-drawer>

      <el-dialog width="730px" custom-class="dialogAnalysist"  :visible.sync="dialogAnalysistVisible">
        <div slot="title">
          <div class="title">
            <img src="../../assets/images/query/trip.png" alt="">请您选择报告中要展示的商标信息及报告语种（最多选择9项）
          </div>
        </div>
        <div class="checkbox">
          <div class="allCheckbox">
            <el-checkbox :indeterminate="isIndeterminateAnalysist" v-model="checkAllAnalysist" @change="handleCheckAllAnalysistChange">全选</el-checkbox>
          </div>

          <el-checkbox-group v-model="checkedAnalysist" @change="handleCheckedAnalysistChange">
            <el-checkbox v-for="item in analysist" :label="item.value" :key="item.value">{{item.text}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="line"></div>
        <el-radio v-model="radioAnalysist" :label="1">中文</el-radio>
        <el-radio v-model="radioAnalysist" :label="2">英文</el-radio>
        <div slot="footer" class="dialog-footer">
          <el-button class="generateAnalysist" type="primary" @click="generateAnalysist">立即生成</el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
  //table拖动
  import Sortable from 'sortablejs'
  import XEUtils from 'xe-utils'
  import $ from 'jquery';
  //获取所有的国家
  import allCountry from './allCountry'
  import moment from "moment";
  import {mapGetters, mapMutations, mapState} from "vuex";
  import {createFn } from "./querys";
  import {classifyData} from './data';//尼斯分类
  import classify from "./public/classInTheNiceClassification";
  import viennaCodezu from "./public/viennaCodezu";
  import imgDefault from "../../assets/images/query/upload.png";
  import progressp from "./public/progress";
  import pie from '@/components/chart/query_pie'
  import bar from '@/components/chart/query_bar'
  import analysisReport from "@/components/query/analysisReport";
  import login from "@/components/common/login"

  const statusObj = {
    '1': '已注册',
    '2': '无效',
    '3': '公告中',
    '4': '驳回复审审查中',
    '5': '异议审查中',
    '6': '注册申请审查中',
    '7': '撤销/无效宣告申请审查中',
    '8': '其他情形',
    '9': '无效',
    '10': '已注册',
    '11': '公告中',
    '12': '审查中'
  }

  const subStatusObj = {
    '已注册': '已注册',
    '无效': '无 效',
    '公告中': '公告中',
    '驳回复审审查中': '审查中',
    '异议审查中': '审查中',
    '注册申请审查中': '审查中',
    '撤销/无效宣告申请审查中': '审查中',
    '其他情形': '其 他',
    '审查中': '审查中'
  }

  const statueColorObj = {
    '已注册': '#B3D465',
    '无效': '#EB6100',
    '公告中': '#5779EE',
    '驳回复审审查中': '#FFBD54',
    '异议审查中': '#FFBD54',
    '注册申请审查中': '#FFBD54',
    '撤销/无效宣告申请审查中': '#FFBD54',
    '其他情形': '#7B578C',
    '审查中': "#FFBD54"
  }

  const type = {
    "01": "化工",
    "02": "颜料",
    "03": "日化",
    "04": "燃料",
    "05": "医药",
    "06": "金属",
    "07": "机械",
    "08": "器具",
    "09": "电子",
    "10": "医用",
    "11": "灯具",
    "12": "车辆",
    "13": "火药",
    "14": "珠宝",
    "15": "乐器",
    "16": "文具",
    "17": "橡胶",
    "18": "皮具",
    "19": "建材",
    "20": "家具",
    "21": "厨具",
    "22": "绳网",
    "23": "纱线",
    "24": "布料",
    "25": "服装",
    "26": "纽扣",
    "27": "地毯",
    "28": "玩具",
    "29": "肉油",
    "30": "米面",
    "31": "果蔬",
    "32": "啤酒",
    "33": "白酒",
    "34": "烟草",
    "35": "广告",
    "36": "金融",
    "37": "建筑",
    "38": "通讯",
    "39": "运输",
    "40": "加工",
    "41": "文娱",
    "42": "科技",
    "43": "酒店",
    "44": "医疗",
    "45": "法律"
  }

  const queryParamsMap = {'country': 'countryData'}
  // 亚洲
  const cityOptions = [
    {
      city: '中国大陆',
      code: 'CN',
      ingSrc: '/images/national/zgdl.jpg'
    },
    {
      city: '中国香港',
      code: 'HK',
      ingSrc: '/images/national/zgxg.jpg'
    },
    {
      city: '印度尼西亚',
      code: 'ID',
      ingSrc: '/images/national/ydnxy.jpg'
    },
    {
      city: '印度',
      code: 'IN',
      ingSrc: '/images/national/yd.jpg'
    },
    {
      city: '日本',
      code: 'JP',
      ingSrc: '/images/national/rb.jpg'
    },
    {
      city: '土耳其',
      code: 'TR',
      ingSrc: '/images/national/trq.jpg'
    },
    {
      city: '韩国',
      code: 'KR',
      ingSrc: '/images/national/hg.jpg'
    },
    {
      city: '中国澳门',
      code: 'MO',
      ingSrc: '/images/national/am.jpg'
    },
    {
      city: '马来西亚',
      code: 'MY',
      ingSrc: '/images/national/mlxy.jpg'
    },
    {
      city: '菲律宾',
      code: 'PH',
      ingSrc: '/images/national/flb.jpg'
    },
    {
      city: '新加坡',
      code: 'SG',
      ingSrc: '/images/national/xjp.jpg'
    },
    {
      city: '中国台湾',
      code: 'TW',
      ingSrc: '/images/national/zgtw.png'
    },
    {
      city: '泰国',
      code: 'TH',
      ingSrc: '/images/national/tgs.jpg'
    },
    {
      city: '阿拉伯联合酋长国',
      code: 'AE',
      ingSrc: '/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city: '奥地利',
      code: 'AT',
      ingSrc: '/images/national/adl.JPG'
    },
    {
      city: '瑞士',
      code: 'CH',
      ingSrc: '/images/national/rs.jpg'
    },
    {
      city: '德国',
      code: 'DE',
      ingSrc: '/images/national/dg.jpg'
    },
    {
      city: '丹麦',
      code: 'DK',
      ingSrc: '/images/national/dm.jpg'
    },
    {
      city: '挪威',
      code: 'NO',
      ingSrc: '/images/national/nw.jpg'
    },
    {
      city: '西班牙',
      code: 'ES',
      ingSrc: '/images/national/xby.jpg'
    },
    {
      city: '欧盟',
      code: 'EU',
      ingSrc: '/images/national/om.jpg'
    },
    {
      city: '芬兰',
      code: 'FI',
      ingSrc: '/images/national/fl.jpg'
    },
    {
      city: '法国',
      code: 'FR',
      ingSrc: '/images/national/fg.jpg'
    },
    {
      city: '英国',
      code: 'GB',
      ingSrc: '/images/national/yg.jpg'
    },
    {
      city: '葡萄牙',
      code: 'PT',
      ingSrc: '/images/national/pty.jpg'
    },
    {
      city: '希腊',
      code: 'GR',
      ingSrc: '/images/national/xl.jpg'
    },
    {
      city: '匈牙利',
      code: 'HU',
      ingSrc: '/images/national/xyl.jpg'
    },
    {
      city: '爱尔兰',
      code: 'IE',
      ingSrc: '/images/national/ael.jpg'
    },
    {
      city: '冰岛',
      code: 'IS',
      ingSrc: '/images/national/bd.jpg'
    },
    {
      city: '意大利',
      code: 'IT',
      ingSrc: '/images/national/ydl.jpg'
    },
    {
      city: '瑞典',
      code: 'SE',
      ingSrc: '/images/national/rd.jpg'
    },
    {
      city: '俄罗斯',
      code: 'RU',
      ingSrc: '/images/national/elss.jpg'
    },
    {
      city: '比荷卢经济联盟',
      code: 'BX',
      ingSrc: '/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city: '加拿大',
      code: 'CA',
      ingSrc: '/images/national/jnd.jpg'
    },
    {
      city: '墨西哥',
      code: 'MX',
      ingSrc: '/images/national/mxg.jpg'
    },
    {
      city: '美国',
      code: 'US',
      ingSrc: '/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city: '阿根廷',
      code: 'AR',
      ingSrc: '/images/national/agt.jpg'
    },
    {
      city: '巴西',
      code: 'BR',
      ingSrc: '/images/national/bx.jpg'
    },
    {
      city: '智利',
      code: 'CL',
      ingSrc: '/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city: '南非',
      code: 'ZA',
      ingSrc: '/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city: '澳大利亚',
      code: 'AU',
      ingSrc: '/images/national/adly.jpg'
    },
    {
      city: '新西兰',
      code: 'NZ',
      ingSrc: '/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city: '世界知识产权组织国际局',
      code: 'WO',
      ingSrc: '/images/national/zscq.jpg'
    }
  ]

  export default {
    name: "query",
    metaInfo: {
      title: '商标查询', // set a title
      meta: [
        {                 // set meta
          name: 'keyWords',
          content: '蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索,图片识别,商标数据,近似商标,中国商标,商标名,商标分类,商标图片,商标文字,商标图片查询api'
        },
        {                 // set meta
          name: 'description',
          content: '基于全球商标图片，按照维也纳编码分类，对图片进行特征提取和机器学习，通过"以图搜图"AI智能方式查询，准确查找出近似图片。同时，支持商标文字查询,商标档案查看等'
        }
      ],
    },
    data() {
      return {
        flagyang: true,
        //是否展示
        isHistoryAnalysist: true,
        historicalSearchData:{},
        lastPage: 0,
        checkedIdTable: [],
        chatBox: null,
        intervalId: null,
        radioAnalysist: 1,
        checkAllAnalysist: false,
        checkedAnalysist: [],
        analysist: [
          {text:'商标名称', value: 'tmName'},
          {text:'商标图案', value: 'imageUrl'},
          {text:'类别', value: 'ncl'},
          {text:'申请人名称（中文）', value: 'applicantZh'},
          {text:'申请号', value: 'appno'},
          {text:'申请日', value: 'appdate'},
          {text:'近似度', value: 'similarity'},
          {text:'申请人地址（中文）', value: 'addressZh'},
          {text:'商标状态', value: 'status'},
          {text:'类似群组', value: 'similargroup'},
          {text:'代理机构', value: 'agent'},
          {text:'申请人名称（英文）', value: 'applicantEn'},
          {text:'注册公告日', value: 'ggdate'},
          {text:'商品服务', value: 'goods'},
          {text:'初审公告日', value: 'trialdate'},
          {text:'申请人地址（英文）', value: 'addressEn'},
        ],
        isIndeterminateAnalysist: true,
        dialogAnalysistVisible: false,
        //业务线索
        leadsRadio: 1,
        formInline: {
          city: '',
          region: '',
          num: '',
          type: ''
        },
        regionList: [
          {
            lable: '北京',
            disable: false,
          },
          {
            lable: '天津',
            disable: false,
          },
          {
            lable: '河北',
            disable: false,
          },          {
            lable: '山西',
            disable: false,
          },
          {
            lable: '内蒙古',
            disable: false,
          },
          {
            lable: '辽宁',
            disable: false,
          },
          {
            lable: '吉林',
            disable: false,
          },
          {
            lable: '黑龙江',
            disable: false,
          },
          {
            lable: '上海',
            disable: false,
          },
          {
            lable: '江苏',
            disable: false,
          },          {
            lable: '浙江',
            disable: false,
          },
          {
            lable: '安徽',
            disable: false,
          },
          {
            lable: '福建',
            disable: false,
          },
          {
            lable: '江西',
            disable: false,
          },
          {
            lable: '山东',
            disable: false,
          },
          {
            lable: '河南',
            disable: false,
          },
          {
            lable: '湖北',
            disable: false,
          },
          {
            lable: '湖南',
            disable: false,
          },
          {
            lable: '广东',
            disable: false,
          },
          {
            lable: '广西',
            disable: false,
          },
          {
            lable: '海南',
            disable: false,
          },          {
            lable: '重庆',
            disable: false,
          },
          {
            lable: '四川',
            disable: false,
          },
          {
            lable: '贵州',
            disable: false,
          },
          {
            lable: '云南',
            disable: false,
          },
          {
            lable: '西藏',
            disable: false,
          },          {
            lable: '陕西',
            disable: false,
          },          {
            lable: '甘肃',
            disable: false,
          },
          {
            lable: '青海',
            disable: false,
          },
          {
            lable: '宁夏',
            disable: false,
          },
          {
            lable: '新疆',
            disable: false,
          }
        ],
        regionArr: [],
        cluesType: 1,
        cluesTypeData: [],
        cluesTypeDataCopy: "",
        queryTmTrialNumArr:[],
        announcementTypeArr: [
          {
            lable: '无效公告'
          },
          {
            lable: '注册商标宣告无效公告'
          },
          {
            lable: '注册商标撤销公告'
          },
          {
            lable: '注册商标注销公告'
          },
          {
            lable: '商标使用许可备案公告'
          },
          {
            lable: '送达公告'
          },
          {
            lable: '转让公告'
          },
        ],
        queryNotivcePageNos: 1,
        queryNotivceSizes: 50,
        queryNotivcetotal: 0,
        // 记录当前高亮的行
        highlight_rows: [],
        crossNice: '',
        categoryTypeVisibleFlag: false,
        fullscreen:false,
        niceLists:'',
        categoryTypeVisible: false,
        categoryTypeTable: [],
        datam: [],
        //上传图片
        imageUrla:'',
        imageText:'',
        //图片上传
        UloaddialogTableVisible: false,
        searchAdvancedFlag: true,
        //商标大数据
        filterDate: [],
        //商标监控次数
        trademarkMonitorTotalTimes:0,
        trademarkMonitorUsageTime:0,
        traderarkMonitorRemainTime:0,
        //是否打开一键添加商标监控浮窗
        trademarkMonitorDialogShow:false,
        //一键添加商标监控浮窗数据
        trademarkMonitorData:{
          countrySet:[],
          niceSet:"",
          trademarkName:"",
          wordImageUrl:"",
          iconImageUrl:"",
          remarks:"",
          monitorType:1,
          sourceType:1,

          revokeThreeFlag:null,
          renewalFlag:null,
          trademarkApplyNo:null,
          trademarkOwnFlag:null,
          renewalNiceCode:null,
        },
        //一键添加商标监控浮窗国家过滤器
        trademarkMonitorCountryFilterText:"",
        //一键添加商标监控国家列表
        trademarkMonitorCountryList:[
          {
            code: 0,
            label: '全选',
            children: [
              {
                code: 1,
                label: '亚洲',
                children: [
                  {
                    label: '中国大陆',
                    code: 'CN',
                    ingSrc: '/images/national/zgdl.jpg'
                  },
                  {
                    label: '中国香港',
                    code: 'HK',
                    ingSrc: '/images/national/zgxg.jpg'
                  },
                  {
                    label: '印度尼西亚',
                    code: 'ID',
                    ingSrc: '/images/national/ydnxy.jpg'
                  },
                  {
                    label: '印度',
                    code: 'IN',
                    ingSrc: '/images/national/yd.jpg'
                  },
                  {
                    label: '日本',
                    code: 'JP',
                    ingSrc: '/images/national/rb.jpg'
                  },
                  {
                    label: '土耳其',
                    code: 'TR',
                    ingSrc: '/images/national/trq.jpg'
                  },
                  {
                    label: '韩国',
                    code: 'KR',
                    ingSrc: '/images/national/hg.jpg'
                  },
                  {
                    label: '中国澳门',
                    code: 'MO',
                    ingSrc: '/images/national/am.jpg'
                  },
                  {
                    label: '马来西亚',
                    code: 'MY',
                    ingSrc: '/images/national/mlxy.jpg'
                  },
                  {
                    label: '菲律宾',
                    code: 'PH',
                    ingSrc: '/images/national/flb.jpg'
                  },
                  {
                    label: '新加坡',
                    code: 'SG',
                    ingSrc: '/images/national/xjp.jpg'
                  },
                  {
                    label: '中国台湾',
                    code: 'TW',
                    ingSrc: '/images/national/zgtw.png'
                  },
                  {
                    label: '泰国',
                    code: 'TH',
                    ingSrc: '/images/national/tgs.jpg'
                  },
                  {
                    label: '阿拉伯联合酋长国',
                    code: 'AE',
                    ingSrc: '/images/national/alq.jpg'
                  }
                ]
              },
              {
                code: 2,
                label: '欧洲',
                children: [
                  {
                    label: '奥地利',
                    code: 'AT',
                    ingSrc: '/images/national/adl.JPG'
                  },
                  {
                    label: '瑞士',
                    code: 'CH',
                    ingSrc: '/images/national/rs.jpg'
                  },
                  {
                    label: '德国',
                    code: 'DE',
                    ingSrc: '/images/national/dg.jpg'
                  },
                  {
                    label: '丹麦',
                    code: 'DK',
                    ingSrc: '/images/national/dm.jpg'
                  },
                  {
                    label: '挪威',
                    code: 'NO',
                    ingSrc: '/images/national/nw.jpg'
                  },
                  {
                    label: '西班牙',
                    code: 'ES',
                    ingSrc: '/images/national/xby.jpg'
                  },
                  {
                    label: '欧盟',
                    code: 'EU',
                    ingSrc: '/images/national/om.jpg'
                  },
                  {
                    label: '芬兰',
                    code: 'FI',
                    ingSrc: '/images/national/fl.jpg'
                  },
                  {
                    label: '法国',
                    code: 'FR',
                    ingSrc: '/images/national/fg.jpg'
                  },
                  {
                    label: '英国',
                    code: 'GB',
                    ingSrc: '/images/national/yg.jpg'
                  },
                  {
                    label: '葡萄牙',
                    code: 'PT',
                    ingSrc: '/images/national/pty.jpg'
                  },
                  {
                    label: '希腊',
                    code: 'GR',
                    ingSrc: '/images/national/xl.jpg'
                  },
                  {
                    label: '匈牙利',
                    code: 'HU',
                    ingSrc: '/images/national/xyl.jpg'
                  },
                  {
                    label: '爱尔兰',
                    code: 'IE',
                    ingSrc: '/images/national/ael.jpg'
                  },
                  {
                    label: '冰岛',
                    code: 'IS',
                    ingSrc: '/images/national/bd.jpg'
                  },
                  {
                    label: '意大利',
                    code: 'IT',
                    ingSrc: '/images/national/ydl.jpg'
                  },
                  {
                    label: '瑞典',
                    code: 'SE',
                    ingSrc: '/images/national/rd.jpg'
                  },
                  {
                    label: '俄罗斯',
                    code: 'RU',
                    ingSrc: '/images/national/elss.jpg'
                  },
                  {
                    label: '比荷卢经济联盟',
                    code: 'BX',
                    ingSrc: '/images/national/bhl.png'
                  }
                ]
              },
              {
                code: 3,
                label: '北美洲',
                children: [
                  {
                    label: '加拿大',
                    code: 'CA',
                    ingSrc: '/images/national/jnd.jpg'
                  },
                  {
                    label: '墨西哥',
                    code: 'MX',
                    ingSrc: '/images/national/mxg.jpg'
                  },
                  {
                    label: '美国',
                    code: 'US',
                    ingSrc: '/images/national/mg.jpg'
                  },
                ]
              },
              {
                code: 4,
                label: '南美洲',
                children: [
                  {
                    label: '阿根廷',
                    code: 'AR',
                    ingSrc: '/images/national/agt.jpg'
                  },
                  {
                    label: '巴西',
                    code: 'BR',
                    ingSrc: '/images/national/bx.jpg'
                  },
                  {
                    label: '智利',
                    code: 'CL',
                    ingSrc: '/images/national/zl.jpg'
                  },
                ]
              },
              {
                code: 5,
                label: '非洲',
                children: [
                  {
                    label: '南非',
                    code: 'ZA',
                    ingSrc: '/images/national/nf.jpg'
                  }
                ]
              },
              {
                code: 6,
                label: '大洋洲',
                children: [
                  {
                    label: '澳大利亚',
                    code: 'AU',
                    ingSrc: '/images/national/adly.jpg'
                  },
                  {
                    label: '新西兰',
                    code: 'NZ',
                    ingSrc: '/images/national/xxl.jpg'
                  }
                ]
              },
              {
                code: 7,
                label: '知识产权组织',
                children: [
                  {
                    label: '世界知识产权组织国际局',
                    code: 'WO',
                    ingSrc: '/images/national/zscq.jpg'
                  }
                ]
              }
            ]
          },
        ],
        //一键添加商标监控树形数据配置
        trademarkMonitorCountryTreeProps:{
          children: 'children',
          label: 'label',
          id: 'code'
        },
        //一键添加商标监控上传图片头
        trademarkMonitorImageUploadHeaders:{
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        //一键添加商标监控所选国家是否包含中国
        trademarkMonitorContainCN:false,
        //一键添加商标监控是否禁止提交(防止重复提交)
        trademarkMonitorIsDisabled:false,
        //一键添加商标监控商标类别选择显示
        trademarkMonitorNiceVisible:false,
        //一键添加商标监控商标类别 0-商标类别，1-续展类别
        trademarkMonitorCategoryOrRenewalCategory:0,
        //筛选状态
        filterStatues: false,
        wSize: true,
        //无效原因的箭头
        arrow1: true,
        isPaymentList: true,
        //生成缴费
        extensionFlag: false,
        extensionFlag1: false,
        submitTypeData:[
          {
            value: 0,
            label: '电子'
          },
          {
            value: 1,
            label: '纸质'
          }
        ],
        //商标续展数据
        extensionData: [],
        //注册商标宣告无效公告
        invalidAnnouncement: true,
        options: [
          {
            value: 1,
            label: '商标名称'
          }, {
            value: 2,
            label: '申请人名称'
          }, {
            value: 3,
            label: '代理机构'
          }
        ],
        //
        isFlag: true,
        selects: 1,
        input3: undefined,
        input4: undefined,
        radio1: '无效公告',
        radio2: '3',
        radio3: ['因异议无效'],
        dialogTableVisibles: false,
        multipleSelection: [],
        //是否复合群组信息
        compoundArr: [],
        //是否VIP
        isVip: "COMMON",
        //长度限制错误
        errFlag: false,
        radioValue: 3,
        //商标对比显示
        dialogTableVisible: false,
        //是否大数据
        isBigData: true,
        //活动详情页
        activeVisible: true,
        //图片组
        imgSrcArr: [],
        //获取商标公告详情
        trademarkFlag: false,
        //PDF文档图片地址
        pdfSrc: '',
        //公告期号
        announcementFlag: 0,
        //历史搜索
        isHistory: false,
        historicalConditions: {},
        // 备份查询条件
        backups: {},
        //记录勾选中的数据
        selectData: [],
        isPageSize: false,
        //列配置
        isColumn: true,
        //是否筛选
        isScreen: false,
        whole: {
          // 近似分数
          startScore: 40,
          /** 近似结束分数 */
          endScore: undefined
        },
        orderConditions: {},
        wholeText: '准',
        //悬停
        hoverFlag: false,
        //代码上传
        dialogVisiblePrompt: false,
        //商标三撤
        fromThree: '',
        getCheckboxRecords: true,
        //结果数据备份
        backupData: [],
        //筛选
        screening: false,
        //是否显示无数据图
        isRelute: false,
        isRelutes: false,
        ids: [],
        //图形要素展示
        imagecodeFlag: false,
        checkAlls: false,
        checkedId: [],
        isIndeterminateId: true,
        //展示方式
        isModeTable: true,
        //是否是交叉检索
        searchCrossFlag: false,
        allCommodity: [],
        //是否显示大数据
        isCharts: false,
        //商品分类
        commodity: [],
        //查询类型选择
        switchTypeId: 1,
        //是否使用关联检索
        isRetrieval: false,
        actionUrl: '',
        //商标查询
        iStrademarkInquiry: 1,
        //图形要素查询
        //商品分类
        //获取商标日志
        loggingData: [],
        //获取数据详情
        //中英切换
        isSwtchEn: false,
        data: {},
        list: {},
        infor: {},
        mineInfor: {},
        flowData: {},
        lang: this.$i18n.locale == "zh-CN" ? "ch" : "en",
        moreBol: false,
        firstIndex: 0,
        leftDisabled: false,
        rightDisabled: false,
        trademarkIds: [],
        trademarkCountrys: [],
        tablse: "",
        //选中类似群组信息
        sameMessage: [],
        //关联类别
        //群组信息
        //汇总信息title
        collectTitle: [
          {
            id: 0,
            name: '商标状态分布',
            show: true
          },
          {
            id: 1,
            name: '类别分布',
            show: false
          }
        ],
        //汇总查询结果信息
        pieData: [],
        pieDatas: [],
        trendsData: {"legendYear": [], "applications": []},
        //表单提交
        formData3: {
          name: '',
          nickname: '',
          sex: '',
          age: 30,
          status: '1',
          weight: null,
          date: null,
          active: false,
          single: '1',
          flagList: []
        },
        deadline1: [],
        deadline2: [],
        //公告类型
        queryNotivceArrGridOption_1: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
              width: 180,
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantName', title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantAddress',
              title: '申请人地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'intcls',
              title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'reason',
              title: '无效原因',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'content',
              title: '无效内容',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        queryNotivceArrGridOption_2: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
              width: 180,
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantName', title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantAddress',
              title: '申请人地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'intcls',
              title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'docNo',
              title: '文号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'reason',
              title: '理由',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'item',
              title: '宣告无效的商品/服务项目',
              slots: {
                // 使用插槽模板渲染
                default: 'item',
              },
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        queryNotivceArrGridOption_3: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
              width: 180,
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantName', title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantAddress',
              filters: [{data: null}],
              title: '申请人地址',
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'intcls',
              title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'docNo',
              title: '文号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'reason',
              title: '撤销理由',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'item',
              title: '宣告无效的商品/服务项目',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'item',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        queryNotivceArrGridOption_4: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
              width: 180,
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantName', title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantAddress',
              title: '申请人地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'intcls',
              title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'deregisterdate',
              title: '注销申请日期',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'item',
              title: '注销的商品/服务项目',
              slots: {
                // 使用插槽模板渲染
                default: 'item',
              },
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        queryNotivceArrGridOption_5: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
              width: 180,
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantName', title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantAddress',
              title: '申请人地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {field: 'intcls', title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'docNo',
              title: '备案号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'deadline',
              title: '许可使用期限',
              filters: [{
                data: null
              }],
              slots: {
                // 使用插槽模板渲染
                filter: 'ElDatePicker',
              },
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'dates',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'licensor',
              title: '许可人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
            ,
            {
              field: 'licensee',
              title: '被许可人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
            ,
            {
              field: 'item',
              title: '许可使用的商品/服务项目',
              filters: [{data: null}],
              slots: {
                // 使用插槽模板渲染
                default: 'item',
              },
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        queryNotivceArrGridOption_6: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
            },
            {
              field: 'tmName',
              title: '商标名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              },
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
            },
            {
              field: 'applicantName',
              title: '申请人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              },
            },
            {
              field: 'tmAgent',
              title: '代理机构',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'docDate',
              title: '原发文日期',
              filters: [{
                data: null
              }],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'docNo',
              title: '原发文编号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'recipientName',
              title: '收件人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'doctype',
              title: '发文类型',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        queryNotivceArrGridOption_7: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'trialType',
              title: '公告类型',
            },
            {
              field: 'tmName',
              title: '商标名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'appno',
              title: '申请号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              },
              slots: {
                // 使用插槽模板渲染
                default: 'appno',
              },
            },
            {
              field: 'assignCount',
              title: '转让次数',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'applicantName',
              title: '申请人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'intcls',
              title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {
                  multiple: true,
                  filterable: true,
                  placeholder: '请选择'
                }
              }
            },
            {
              field: 'transferor',
              title: '转让人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'assign',
              title: '受让人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //业务线索
        businessLeadsGridOption: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {
              field: 'agentName',
              title: '代理机构名称',
              width: 160,
            },
            {
              field: 'lastYearCount',
              title: '去年办案量'
            },
            {
              field: 'regRate',
              title: '注册成功率'
            },
            {
              field: 'rejectRate',
              title: '申请驳回率',
            },
            {
              field: 'phone',
              title: '联系电话'
            },
            {
              field: 'applicantName',
              title: 'TOP5申请人',
            },
            {
              field: 'appCount',
              title: '代理此申请人商标量'
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //续展线索
        continuationCluesOption:{
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          filterConfig: {
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          toolbarConfig: {
            refresh: false,
            import: false,
            export: false,
            // print: true,
            zoom: true,
            custom: true,
            className: 'toolbarNames_xlsx',
            slots: {
              buttons: 'toolbar_buttons_xlsx'
            }
          },
          data: [],
          columns: [
            {
              field: 'sbmc',
              title: '商标名称',
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'imageUrl',
              title: '商标图样',
              slots: {
                default: 'imageUrl',
              },
            },
            {
              field: 'appno',
              title: '申请号',
              slots: {
                default: 'appno',
              },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'tmapplicant',
              title: '申请人名称',
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'tmaddress',
              title: '申请人地址',
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'tmagent',
              title: '代理机构名称',
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'ggdate',
              title: '注册公告日',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  startPlaceholder: '开始日期',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              field: 'validdate',
              title: '续展截止日期',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  startPlaceholder: '开始日期',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  endPlaceholder: '结束日期'
                }
              }
            }
          ]
        },
        ggDate_: [],
        validDate_: [],
        continuationCluesCurrent:1,
        continuationCluesSize:20,
        continuationCluesTotal:9999,
        announcement: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'trialType',
              title: '公告类型',
              width: 160,
            },
            {
              field: 'tmName',
              title: '商标名称'
            },
            {
              field: 'goodsName', title: '商标图片',
              slots: {
                // 使用插槽模板渲染
                default: 'trialImageUrl',
              }
            },
            // {
            //   field: 'goodsNameEn',
            //   title: '类别',
            // },
            {
              field: 'appno',
              title: '申请号',
            },
            {field: 'applicant', title: '申请人'},
            {
              field: 'trialNum',
              title: '公告期号',
            },
            {
              field: 'detail',
              title: '商标详情页',
              slots: {
                // 使用插槽模板渲染
                default: 'trialDetail',
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //商标续展
        extension: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'goodsName', title: '商标图样',
              slots: {
                // 使用插槽模板渲染
                default: 'trialImageUrls',
              }
            },
            {
              field: 'trademarkName',
              title: '商标名称',
            },
            {
              field: 'renewalNiceCode',
              title: '类别',
            },
            {
              field: 'trademarkApplyNo',
              title: '申请号',
            },
            {field: 'trademarkStatus', title: '商标状态'},
            {field: 'applyPersonName', title: '申请人名称'},
            {field: 'lastRenewalDate', title: '上次续展完成时间'},
            {
              field: 'submitType',
              title: '提交方式',
              slots: {
                // 使用插槽模板渲染
                default: 'submitType',
              }
            },
            {
              field: 'renewalAmount',
              title: '续展费',
            },
            {
              field: 'extendedAmount',
              title: '宽展费',
            },
            {
              field: 'renewalSummaryAmount',
              title: '续展官费合计',
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        announcements: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          toolbarConfig: {
            refresh: false,
            import: false,
            export: false,
            // print: true,
            zoom: true,
            custom: true,
            className: 'toolbarNames_xlsx',
            slots: {
              buttons: 'toolbar_buttons_xlsx'
            }
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'trialType',
              title: '公告类型',
              width: 160
            },
            {
              field: 'goodsName', title: '商标图样',
              slots: {
                // 使用插槽模板渲染
                default: 'trialImageUrl',
              },
            },
            {
              field: 'tmName',
              title: '商标名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'appno',
              title: '申请号'
            },
            {
              field: 'intcls',
              title: '类别',
              filters: [
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
                { label: '25', value: '25' },
                { label: '26', value: '26' },
                { label: '27', value: '27' },
                { label: '28', value: '28' },
                { label: '29', value: '29' },
                { label: '30', value: '30' },
                { label: '31', value: '31' },
                { label: '32', value: '32' },
                { label: '33', value: '33' },
                { label: '34', value: '34' },
                { label: '35', value: '35' },
                { label: '36', value: '36' },
                { label: '37', value: '37' },
                { label: '38', value: '38' },
                { label: '39', value: '39' },
                { label: '40', value: '40' },
                { label: '41', value: '41' },
                { label: '42', value: '42' },
                { label: '43', value: '43' },
                { label: '44', value: '44' },
                { label: '45', value: '45' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {field: 'applicant', title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {field: 'tmAgent', title: '代理机构',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {field: 'reason', title: '无效原因',
              filters: [
                { label: '因异议无效', value: '因异议无效' },
                { label: '评审形审首次形审受理财务通过', value: '评审形审首次形审受理财务通过' },
                { label: '评审形审首次形审受理财务无款不予受理', value: '评审形审首次形审受理财务无款不予受理' },
                { label: '该商标已进入司法应诉阶段，驳回复审裁定尚未生效', value: '该商标已进入司法应诉阶段，驳回复审裁定尚未生效' },
                { label: '评审形审不予受理处长审核', value: '评审形审不予受理处长审核' },
                { label: '该商标已进入司法应诉阶段，不予注册复审裁定尚未生效', value: '该商标已进入司法应诉阶段，不予注册复审裁定尚未生效' },
                { label: '评审形审补正审查视为撤回结论', value: '评审形审补正审查视为撤回结论' },
                { label: '该商标已进入司法应诉阶段，无效宣告裁定尚未生效', value: '该商标已进入司法应诉阶段，无效宣告裁定尚未生效' },
                { label: '该商标已进入司法应诉阶段，撤销复审裁定尚未生效', value: '该商标已进入司法应诉阶段，撤销复审裁定尚未生效' },
                { label: '无效宣告全部无效', value: '无效宣告全部无效' },
                { label: '无效宣告部分成立', value: '无效宣告部分成立' },
                { label: '其他原因', value: '其他原因' }
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {field: 'content', title: '无效理由',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {field: 'invalidItem', title: '商品/服务项目',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'trialNum',
              title: '公告期号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'appDate',
              title: '申请日期',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'trialDate',
              title: '公告日期',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        gridOption: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true
          },
          printConfig: {
            columns: [
              {field: 'name'},
              {field: 'email'},
              {field: 'nickname'},
              {field: 'age'},
              {field: 'amount'}
            ]
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          columns: [
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'nice',
              title: '类别',
              width: 160
            },
            {
              field: 'similargroup',
              title: '群组'
            },

            {field: 'goodsName', title: '项目中文'},
            {
              field: 'goodsNameEn',
              title: '项目英文',
            },
            {
              field: 'goodsNo',
              title: '商品服务编码',
            },
            {field: 'remark', title: '备注'},
            {
              field: 'itemType',
              title: '项目类型',
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          toolbarConfig: {
            refresh: {
              query: this.query
            },
            import: false,
            export: false,
            // print: true,
            zoom: true,
            custom: true,
            className: 'toolbarNames',
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          data: [],
          Props: {
            height: '720px',
            stripe: true
          },
          columns: [
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'imageUrl', width: 120, title: '商标图案', slots: {
                // 使用插槽模板渲染
                default: 'imageUrl',
              }
            },
            {
              field: 'sbmc',
              title: '商标名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'intcls',
              title: '类别',
              width: 160,
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'appno', title: '申请号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'tmapplicant',
              title: '申请人名称(中文)',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人名称'}
              }
            },
            {
              field: 'applicantEn',
              title: '申请人名称(英文)',
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人名称'}
              }
            },
            {
              field: 'tmaddress', title: '申请人地址(中文)',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'addressEn', title: '申请人地址(英文)', visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'appdate',
              title: '申请日',
              filters: [{data: null}],
              formatter: ({row}) => row.appdate,
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'ggdate',
              title: '注册公告日',
              width: 160,
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              field: 'showTmdetail',
              title: '商品服务',
              width: 160,
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'tmstatus',
              title: '商标状态',
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择', checked: ['已注册']}
              }
            },
            {
              field: 'level', title: '近似度',
              width: 100, visible: false, slots: {
                // 使用插槽模板渲染
                default: 'degree',
              }
            },
            {
              field: 'similargroup', title: '类似群组', visible: true,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入类似群组'}
              }
            },
            {
              field: 'tmagent', title: '代理机构', visible: true,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入代理机构'}
              }
            },
            {
              field: 'regno', title: '注册号', filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'countryZhName',
              title: '国家/地区',
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: false, filterable: true, placeholder: '请选择'}
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'trialdate', title: '初审公告日',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  startPlaceholder: '开始日期',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              type: 'expand', title: '', visible: false, width: 160, slots: {
                // 使用插槽模板渲染
                content: 'num1_default',
              }
            },
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        show: false,
        seqFixed: null,
        expandFixed: null,
        ageFixed: null,
        loading: false,
        tableData: [],
        sexList: [
          {label: '', value: ''},
          {label: '男', value: '1'},
          {label: '女', value: '0'}
        ],
        //查询类型选择
        tab: [
          {
            label: '商标查询',
            id: 1,
            show: true
          },
          {
            label: '商标公告',
            id: 4,
            show: false
          },
          {
            label: '商标续展',
            id: 5,
            show: false
          },
          {
            label: '业务线索',
            id: 6,
            show: false
          }
        ],
        //查询类型选择
        tabType: [
          {
            label: '驳通智写',
            icon: require('../../assets/images/query/znzx.png'),
            icon1: require('../../assets/images/query/znzx1.png'),
            id: 4,
            show: true
          },
          {
            label: '法律文书',
            icon: require('../../assets/images/query/sbgg.png'),
            icon1: require('../../assets/images/query/sbgg1.png'),
            id: 1,
            show: true
          },
          {
            label: '商标监控',
            icon: require('../../assets/images/query/sbjk.png'),
            icon1: require('../../assets/images/query/sbjk1.png'),
            id: 2,
            show: false
          },
          {
            label: '商标对比',
            icon: require('../../assets/images/query/lsjl.png'),
            icon1: require('../../assets/images/query/lsjl1.png'),
            id: 3,
            show: false
          }
        ],
        getServicePermissionFlag: true,
        isCN: true,
        dataType: this.$t(`PQ.dataType`),
        //申请号下不显示申请号近似
        isapplication: true,
        radio: 3,
        //是否选择类别
        isCategory: false,
        //part
        partResult: [],
        //获取全部结果
        allResult: [],
        //国家的选择
        choiceCountry: [],
        //监听页面滚动条
        isScroll: false,
        //已筛选国家
        selectedCountries: [],
        queryDirection: true,
        value: 2,
        //是否是条件筛选
        flag: 0,
        //组合查询的查询条件
        queryCriteria: {},
        //结果需要筛选的条件
        responseData: {
          applicant_list: []
        },
        //组合查询
        combinedquery: true,
        queryItemDefault: {
          tradeMarkText: {
            defaultText: "商标名称",
            keys: "SBMC",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          }, //
          tradeMarkPattern: {
            defaultText: "商标图形",
            keys: "SBTP",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          }, //value存储file
          patternElement: {
            defaultText: "图形要素编码",
            keys: "TXYSBM",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          classify: {
            defaultText: "分类",
            keys: "LB",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          applicationNumber: {
            defaultText: "申请号",
            keys: "SQH",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          registerNumber: {
            defaultText: "注册号",
            keys: "ZCH",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          tmApplicant: {
            defaultText: "申请人名称",
            keys: "SQRMC",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          tmAddress: {
            defaultText: "申请人地址",
            keys: "SQRDZ",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          status: {
            defaultText: "商标状态",
            keys: "SBZT",
            cnText: "",
            enText: "ewrwer",
            value: [],
            isMust: 2
          },
          istotal: {
            defaultText: "是否共有商标",
            keys: "SFGYSB",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          active: {
            defaultText: "是否有效",
            keys: "SFYX",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          trialDate: {
            defaultText: "初审公告日",
            keys: "CSGGR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          appDate: {
            defaultText: "申请日",
            keys: "SQR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          regDate: {
            defaultText: "注册日",
            keys: "ZCR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          ggDate: {
            defaultText: "注册公告日",
            keys: "ZCGGR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          priorityDate: {
            defaultText: "优先权日",
            keys: "YXQR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          privateDate: {
            defaultText: "专用权到期日",
            keys: "ZYQDQR",
            cnText: "",
            enText: "ewrwer",
            value: "",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          tmAgent: {
            defaultText: "代理机构",
            keys: "DLJG",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          }
        },
        queryItem: {},
        queryMode: {
          //查询模式,status,0精确查询,1近似查询,2包含查询
          // list: [
          //   {defaultText: "精确查询", cnText: "精确查询", enText: "exactQuery", val: "term"},
          //   {defaultText: "近似查询", cnText: "近似查询", enText: "approximateQuery", val: "ai"},
          //   {defaultText: "包含查询", cnText: "包含查询", enText: "includeQuery", val: "match"},
          // ],
          choosed: this.$store.state.query.queryData.queryWay
        },
        defaultText: {
          uploadText: "拖拽图片或点击上传",
          patternText: "与商标字段互斥，有商标内容则不显示图形要素",
          classifyText:
            "请输入1-45之间的阿拉伯数字,类别之间用;分隔，例如查询第九类输入：9;",
          plaseChoose: "请选择"
        },
        cnText: {
          uploadText: "拖拽图片或点击上传",
          patternText: "与商标字段互斥，有商标内容则不显示图形要素",
          classifyText: "请输入1-45之间的阿拉伯数字，例如查询第九类输入：9",
          plaseChoose: "请选择"
        },
        enText: {
          uploadText: "tuotuotuto",
          patternText: "tuotuotuto",
          classifyText: "aaaa",
          plaseChoose: "please choose"
        },
        /*维也纳分类,即图形像素*/
        ViennaVisible: false, //维也纳分类Vienna
        /*尼斯分类*/
        niceVisible: false,
        niceChoosedArr: [], //选中的尼斯分类数组
        niceAll: [], //接口获取到整个一二级的尼斯分类,
        //niceChoosedStr:"", // 选中的图形像素,见computed
        /*0124分类内容*/
        // classifyData: JSON.parse(JSON.stringify(classifyData)),//分类内容
        imgUplad: "", //上传的图片
        imgDefault: imgDefault, //默认的图片,即上传图片
        // 高级查询改版之后---初始化数据start
        val: "",
        isAddInput: false,
        query_Item1: [
          //固定查询下拉列表
          {
            tmParams: "",
            input: "",
            options: [
              {
                value: "SQRMC",
                label: "申请人名称",
                disabled: false
              },
              {
                value: "SQH",
                label: "申请号",
                disabled: false
              }
            ]
          }
        ],
        query_Items: this.$t(`PQ.query_Items`),
        // 高级查询改版之后---初始化数据end
        flagdata: "",
        selectList: [],
        onlyVipCanQuery: false,
        isSelectAll: false,
        //全部选择
        allChecked: false,
        //是否显示类别
        queryConditon: true,
        queryNotivceAsideForms: {},
        continuationAsideForms: {},
        asideForms: {
          // applicant: [],
          // niceList: [],
          // appYear: [],
          // regYear: [],
          // active: [],
          // tmstatuscode: [],
          // agent: []
        },
        //是否显示可注册性报告
        isReport: '',
        arr: [],
        newData: [],
        //全类数据
        allType: [],
        //出现结果的搜索条件
        condition: '',
        //没有类别数据提示
        noData: false,
        //----------------
        fileList: [],
        fileLists: [],
        //数据库
        boxShow: false,
        //是否旋转
        isRotate: true,
        //数据库全选
        checked: false,
        //亚洲
        asiaCheckAll: false,
        asiaIsIndeterminate: true,
        // 欧洲
        europeCheckAll: false,
        europeIsIndeterminate: false,
        // 北美洲
        americaCheckAll: false,
        americaIsIndeterminate: false,
        // 南美洲
        southCheckAll: false,
        southIsIndeterminate: false,
        // 非洲
        africaCheckAll: false,
        africaIsIndeterminate: false,
        // 大洋洲
        oceaniaCheckAll: false,
        oceaniaIsIndeterminate: false,
        //世界知识产权组织
        propertyCheckAll: false,
        propertyIndeterminate: false,
        //亚洲
        asia: ['CN'],
        asiaCity: this.$t(`PQ.cityOptions`),
        // 欧洲
        europe: [],
        europeCity: this.$t(`PQ.city`),
        // 北美洲
        america: [],
        americaCity: this.$t(`PQ.cityOptionsd`),
        // 南美洲
        south: [],
        southCity: this.$t(`PQ.cityOptionsds`),
        // 非洲
        africa: [],
        africaCity: this.$t(`PQ.cityOpts`),
        // 大洋洲
        oceania: [],
        OceaniaCity: this.$t(`PQ.cityds`),
        //知识产权组织
        property: [],
        propertyOrganizationCity: this.$t(`PQ.propertyOrganization`),
        dialogFormVisible: true,
        dialogClose: false,
        /*动画相关*/
        searchBoxAppear: true,
        inputFocus: false,
        btnHover: false,
        // riverHhorseAppear: false,
        /*查询方式*/
        queryModes: "",//image,text
        /*查询条件*/
        firstList: this.$t(`PQ.firstList`),//param,queryWay爲後臺需要,classControl爲類名控制需要;
        firstChoosed: "",
        /*筛选条件*/
        niceList: [],//尼斯分类
        tmStatusList: [
          {name: "商标已注册", isCheck: false, code: 1},
          {name: "商标申请中", isCheck: false, code: 2},
          {name: "初审公告中", isCheck: false, code: 3},
          {name: "驳回复审中", isCheck: false, code: 4},
          {name: "商标异议中", isCheck: false, code: 5},
          {name: "商标无效", isCheck: false, code: 6},
          {name: "其他情形", isCheck: false, code: 7}
        ],//法律状态
        appDateList: [],//申请日期
        moreObj: {
          category: false,
          status: false,
          year: false,
        },
        searchText: "",
        pageNo: 1,//当前页数
        //滚动条底部页数
        pageNos: 1,
        pageSizes: 50,
        pageNoss: 1,
        pageSizess: 50,
        // pageSize: 500,//每页查询多少条
        total: 0,
        totals: 0,
        totals_: 0,
        pagesTotal: 500,
        base64Url: "",//需要查询的文件
        imageCodeList: "",//图形编码
        param: "",//默认为空，  1为商标名称查询/2为申请人查询/3为申请号查询/4为代理公司查询
        selectedArea: "",//selectedArea
        /*结果*/
        // resultList: [2],
        resultList: [],
        switchStyle: false,
        // 用户选择的默认显示形式  表格或图文
        switchStyleDefault: false,
        dialogVisible: false,
        serviceNameType: "",
        resultBool: false,
        countryList: [
          {
            value: 'CN',
            label: '中国大陆'
          }, {
            value: 'EU',
            label: '欧盟'
          }, {
            value: 'US',
            label: '美国'
          }
        ],
        countryVal: 'CN',
        tag: '',
        tmtypeOne: {
          普通商标: "Ordinary Trademark",
          证明商标: "Certification Trademark",
          集体商标: "Collective Trademark",
          特殊商标: "Special Trademark",
          一般: "Ordinary Trademark",
          证明: "Certification Trademark",
          集体: "Collective Trademark",
          特殊: "Special Trademark"
        },
        flowDataCountry: [], // 流程中的国家
        CanadaWebService: [],
        activeNames: [],
        switchStyleList: [
          {text: "收折"},
          {text: "展开"},
        ],
        switchStyleChoosed: 1,
        urlParamIntcls: "",
        cotry: {},
        op: "",
        webClassService: [],
        webClassServiceOne: [],
        seniority: [],
        isClass: false,
        appliShow: true,
        appliOneShow: false,
        filterData: [],
        //节假日数据
        festivalData: [],
        //输入框是否是自动补全
        ifSearchInputAutoComplate:false,
        //是否展示查询报告
        ifShowAnalysisIcon:false,
        ifShowAnalysisReport:false,
        analysisLastQueryKeyword:"",
        reportType:0,
        queryKeyword:"",
        myQueryData:{},
        noShow: true
      }
    },
    components: {
      login,
      classify,
      viennaCodezu,
      progressp,
      pie,
      bar,
      analysisReport
    },
    created() {
      //创建类别 0-45
      for (let i = 1; i <= 45; i++) {
        this.niceList.push({
          name: i < 10 ? "0" + i : i,
          categoryNo: i < 10 ? "0" + i : i + '',
          isCheck: false,
          isSimilar: false,
          typeName: this.dataType[i - 1].name
        })
      }

      this.queryItem = JSON.parse(JSON.stringify(this.queryItemDefault));
      this.columnDrop2()
    },
    async mounted() {
      document.addEventListener('click', this.hideTooltipOnClickOutside);
      let that = this;
      this.queryDataFn({
        countryList: allCountry.countryMaxDates,
        country: ['CN']
      })
      this.asideFormFn({
        country: [],
        applicant: [],
        applicantEn: [],
        niceList: [],
        imageFactory: [],
        appYear: [],
        regYear: [],
        trailYear: [],
        active: [],
        ggBeginYear: [],
        priorityYear: [],
        rightPeriod: [],
        tmstatuscode: [],
        commonBrand: []
      })

      if (this.$i18n.locale === 'cn') {
        this.isCN = true
      }
      else {
        this.isCN = false
        this.$router.push('/nPQ')
      }

      if (this.$route.query.id) {
        this.switchType(Number(this.$route.query.id))
      }

      await this.queryAjax()

      if (this.$route.query.country) {
        if(this.asiaCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = [this.$route.query.country]
        }

        if(this.europeCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = []
          this.europe = [this.$route.query.country]
        }

        if(this.americaCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = []
          this.america = [this.$route.query.country]
        }

        if(this.southCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = []
          this.south = [this.$route.query.country]
        }

        if(this.africaCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = []
          this.africa = [this.$route.query.country]
        }

        if(this.OceaniaCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = []
          this.oceania = [this.$route.query.country]
        }

        if(this.propertyOrganizationCity.map(item => item.code).includes(this.$route.query.country)){
          this.asia = []
          this.property = [this.$route.query.country]
        }
      }

      if (this.$route.query.value) {
        this.value = Number(this.$route.query.value)
        this.searchText = this.$route.query.searchText
        if (this.$route.query.searchText && this.$route.query.auto) {
          await this.searchCross()
        }
      }

      $(function () {
        var x = -16;
        var y = 26;
        var newtitle = '';
        var arr1 = []
        var arr2 = []
        var arr3 = []
        $('.vxe-button.type--button.is--circle')
          .mouseover(function (e) {
            if (e.currentTarget.title) e.currentTarget.titles = e.currentTarget.title
            else ;
            e.currentTarget.title = ''
            newtitle = e.currentTarget.titles
            $('body').append('<div id="mytitle" >' + newtitle + '</div>')
            if (!that.hoverFlag) {
              that.hoverFlag = true
              $('#mytitle').css({
                'left': (e.clientX + x + document.documentElement.scrollLeft + document.body.scrollLeft + 'px'),
                'top': (e.clientY + document.documentElement.scrollTop + document.body.scrollTop + y + 'px')
              }).show();
            }
          })
          .mouseout(function () {
            that.hoverFlag = false
            this.title = newtitle;
            $('#mytitle').remove();
          })
      });
      //isInformation
      var w = document.documentElement.clientWidth;
      var h = document.documentElement.clientHeight;
      if(w > 1600){
        // this.wSize = true
      }
      else{
        this.isInformation()
        this.wSize = false
      }
      this.queryTmTrialNum()
      //实时监听当前浏览器大小
      window.addEventListener("resize", this.getWindowSize);
      this.isVips()
      this.extensionEvent()
      this.festival()
      //请求商标监控次数
      this.monitorRemaining()

    },
    beforeDestroy() {
      clearInterval(this.intervalId);
      // 卸载事件监听器
      window.removeEventListener('scroll', () => {
        const isAtTop = this.chatBox.scrollTop === 0;

        if (isAtTop) {
          console.log('div 已经滚动到顶部');
          // 可以在这里执行一些操作，比如显示提示、加载更多内容等
          this.automaticPagination(false)
        }
      });
      if (this.sortable2) {
        this.sortable2.destroy()
      }
    },
    methods: {
      ...mapMutations("query", [
        "setClassifyShowFn",
        "queryItemsShowFn",
        "queryDataFn",
        "ajaxResponseFn",
        "othersItemFn",
        "pageNum_Fn",
        "pageSize_Fn",
        "noResetFn",
        "queryBtnClickNumFn",
        "responseDataFn",
        "sendQueryDataFn",
        "isFirstQueryFn",
        "asideFormFn",
        'setMe',
        'setNoticeList'
      ]),
      hideTooltipOnClickOutside(){
        this.noShow = false;
      },
      generateAnalysist() {
        if(this.checkedAnalysist.length > 9) {
          this.$message({
            type: 'info',
            message: '添加报告中要展示的商标信息数量不能超过9个'
          });
          return
        }

        if(this.checkedId.length ==  0) {
          this.$message({
            type: 'info',
            message: '请选择需要在可注册性分析报告中分析的数据'
          });
          return
        }

        let arr = []
        let isCheckArr = []
        this.niceList.filter(item => item.isCheck).map(item => {
          isCheckArr.push(item.name)
        })
        // if(this.getIsAllChecked){
        this.multipleSelection.map(item => {
          if(!isCheckArr.includes(item.slice(0,2))){
            arr.push(item)
          }
        })

        let obj = {}
        if(!this.base64Url) {
          obj = {
            current: this.pageNos,
            similargroup:this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ?  ( this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') :  this.getIsAllChecked ? undefined  : this.multipleSelection.join(';')) : undefined,
            //this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : undefined,
            keywork: this.value == 7 && this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : this.value == 7 ? undefined : this.value == 2 || this.value == 4 ? this.searchText : undefined,
            //图形要素:
            imageCodeList: this.value == 7 ? this.searchText : undefined,
            size: this.pageSizes,
            niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';') : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
            tmStatus: this.tmStatusChecked || undefined,
            country: this.stateChange,
            lang: "zh",
            agent: this.value == 6 ? this.searchText : undefined,
            applicant: this.value == 0 || this.value == 3 ? this.searchText : this.queryItem.tmApplicant.value ? this.queryItem.tmApplicant.value : undefined,
            appNo: this.value == 1 || this.value == 5 ? this.searchText : undefined,
            queryWay: this.value == 7 && this.queryItem.tmApplicant.value ? 'match' : this.value !== "" && this.value !== 6 && this.value !== 3 ? this.firstList[this.value].queryWay : this.value === 3 || this.value === 6 ? 'term' : undefined,
            secondQuery: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.isobject({...this.conditions}) : this.flag ? this.isobject({...this.conditions}) : this.switchTypeId == 1 && this.value == 2 ? undefined : undefined,
            order: this.flag ? this.orderCond : undefined,
            startScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : undefined,
            endScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : undefined,
            ...this.queryCriteria
          }
        }else {
          obj = {
            current: this.pageNos,
            size: this.pageSizes,
            similargroup:this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ?   ( this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') :  this.getIsAllChecked ? undefined  : this.multipleSelection.join(';')) : undefined,
            base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
            imageCodeList: this.value == 7 ? this.searchText : undefined,
            niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';') : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
            selectedArea: this.selectedArea || undefined,
            tmStatus: this.tmStatusChecked || undefined,
            country: this.stateChange,
            lang: "zh",
            keywork: this.imageText ? this.imageText : undefined,
            ...this.queryCriteria,
            secondQuery: this.flag ? this.isobject({...this.conditions}) : undefined,
            order: this.flag ? this.orderCond : undefined,
          }
        }
        console.log(this.historicalSearchData,"this.historicalSearchData")
        this.$axios.post('/common/query/analyzeReportV2', {
          ...obj,
          crossSimilarygroup: arr.length == 0 ? undefined : arr.join(';'),
          headList: this.checkedAnalysist,
          idList: this.checkAlls ? undefined : this.checkedId.map(item => JSON.parse(item).id),
          lang: this.radioAnalysist == 1 ? 'zh' : 'en',
          keywork: this.isHistory ? this.historicalSearchData.patentSearchContents.keywork : this.searchText
        },{
          responseType: 'blob'
        }).then(({code, data, msg}) => {
          // this.$nuxt.$loading.finish()

          clearInterval(this.intervalId);
          // 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
          this.isModeTable = true
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '可注册分析报告.doc'
          this.dialogAnalysistVisible = false
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      generateReport(){
        this.dialogAnalysistVisible = true
      },
      handleCheckAllAnalysistChange(val) {
        this.checkedAnalysist = val ? this.analysist.map(item => item.value) : [];
        this.isIndeterminateAnalysist = false;
      },
      handleCheckedAnalysistChange(value) {
        let checkedCount = value.length;
        this.checkAllAnalysist = checkedCount === this.analysist.length;
        this.isIndeterminateAnalysist = checkedCount > 0 && checkedCount < this.analysist.length;
      },
      GenerateImmediately(){

      },
      getQueryNotivceArrGridOption(){
        let queryNotivceArrGridOption = {}
        switch (this.formInline.type){
          case "无效公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_1
            break
          case "注册商标宣告无效公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_2
            break
          case "注册商标撤销公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_3
            break
          case "注册商标注销公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_4
            break
          case "商标使用许可备案公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_5
            break
          case "送达公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_6
            break
          case "转让公告":
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_7
            break
          default:
            queryNotivceArrGridOption = this.queryNotivceArrGridOption_1
        }
        return queryNotivceArrGridOption
      },
      deadlineEvent(event, option, $panel){
        if(this.deadline1.length == 2 && this.deadline2.length == 2){
          option.data = [...this.deadline1,...this.deadline2]
          $panel.changeOption(event, true, option)
        }
        // let queryNotivceArrGridOption_5 = this.queryNotivceArrGridOption_5
        // if(this.deadline1.length == 2 && this.deadline2.length == 2){
        //   queryNotivceArrGridOption_5.columns[6].filters[0].checked = true
        //   this.queryNotivceArrGridOption_5 = JSON.parse(JSON.stringify(queryNotivceArrGridOption_5))
        //   this.$nextTick(() => {
        //     console.log(this.$refs.queryNotivcexGrids.updateData)
        //     this.$refs.queryNotivcexGrids.updateData()
        //     this.$refs.queryNotivcexGrids.updateStatus()
        //     this.$forceUpdate();
        //
        //   })
        //
        // }else {
        //   this.queryNotivceArrGridOption_5.columns[6].filters[0].checked = false
        //   this.$refs.queryNotivcexGrids.updateData()
        //   this.$forceUpdate();
        //
        // }
      },
      applicantZhName(val){
        //20240716改为新开一个页面
        window.open(window.location.origin+"/#/PQ?value=3&searchText="+val)
      },
      appno(val){
        //20240716改为新开一个页面
        window.open(window.location.origin+"/#/PQ?value=5&searchText="+val)
      },
      //企查查
      qichacha(item){
        // https://www.qcc.com/web/search?key=%E5%8D%8E%E4%B8%BA
        window.open(`https://www.qcc.com/web/search?key=${item}`);
      },
      businessLeadsClasss({row, column, rowIndex, columnIndex}){
        if(columnIndex == 4){
          return 'businessLeadsClass'
        }
      },
      businessLeadsClass({row, column, rowIndex, columnIndex}){
        // let arr = []
        // // 当列索引是2(第三列), 需要设置要合并的列/行
        // if ((0 <= rowIndex && rowIndex <= 4) && (columnIndex == 5 || columnIndex == 6)) {
        //   if(columnIndex == 5){
        //     return 'businessLeadsClass_back businessLeadsClass'
        //   }else {
        //     return 'businessLeadsClass_back'
        //   }
        // }else if ((10 <=  rowIndex && rowIndex <= 14) && (columnIndex == 5 || columnIndex == 6)) {
        //   if(columnIndex == 5){
        //     return 'businessLeadsClass_back businessLeadsClass'
        //   }else {
        //     return 'businessLeadsClass_back'
        //   }
        // }else if ((20 <=  rowIndex && rowIndex <= 24) && (columnIndex == 5 || columnIndex == 6)) {
        //   if(columnIndex == 5){
        //     return 'businessLeadsClass_back businessLeadsClass'
        //   }else {
        //     return 'businessLeadsClass_back'
        //   }
        // }else if ((30 <=  rowIndex && rowIndex <= 34) && (columnIndex == 5 || columnIndex == 6)) {
        //   if(columnIndex == 5){
        //     return 'businessLeadsClass_back businessLeadsClass'
        //   }else {
        //     return 'businessLeadsClass_back'
        //   }
        // }else if ((40 <=  rowIndex && rowIndex <= 44) && (columnIndex == 5 || columnIndex == 6)) {
        //   if(columnIndex == 5){
        //     return 'businessLeadsClass_back businessLeadsClass'
        //   }else {
        //     return 'businessLeadsClass_back'
        //   }
        // }else if ((50 <=  rowIndex && rowIndex <= 54) && (columnIndex == 5 || columnIndex == 6)) {
        //   if(columnIndex == 5){
        //     return 'businessLeadsClass_back businessLeadsClass'
        //   }else {
        //     return 'businessLeadsClass_back'
        //   }
        // }else {
        //   return 'businessLeadsClass_white'
        // }
        if(columnIndex == 5 || columnIndex == 6){
          return 'businessLeadsClass'
        }
      },
      businessLeadsClass2({row, column, rowIndex, columnIndex}){
        if(columnIndex == 1){
          return 'businessLeadsClass2'
        }
        if(column.label == '合作代理机构'){
          return 'businessLeadsClass3'
        }
        if(column.label == '申请人名称'){
          return 'businessLeadsClass4'
        }
      },
      queryNotivceArrEvent(){
        this.$refs.queryNotivcexGrids.clearFilter()
        this.queryNotivceAsideForms = {}
        this.deadline1 = []
        this.deadline2 = []
        this.queryNotivceArrGridOption_1.data = []
        this.queryNotivceArrGridOption_2.data = []
        this.queryNotivceArrGridOption_3.data = []
        this.queryNotivceArrGridOption_4.data = []
        this.queryNotivceArrGridOption_5.data = []
        this.queryNotivceArrGridOption_6.data = []
        this.queryNotivceArrGridOption_7.data = []
      },
      handleClickMyAnalysisIcon(){
        this.ifShowAnalysisReport = !this.ifShowAnalysisReport
      },
      querySearch(queryString, cb){
        let result = []
        if (this.value == 3){
          let queryParam = {
            country:"CN",
            applicant:queryString,
            size:10
          }
          this.$axios.post("/common/query/applicantHit2", queryParam).then(response=>{
            response.data.forEach(item=>{
              result.push({value:item})
            })
          })
        }
        if (this.value == 6){
          let queryParam = {
            agent:queryString,
            size:10
          }
          this.$axios.post("/common/query/agentHit", queryParam).then(response=>{
            response.data.forEach(item=>{
              result.push({value:item})
            })
          })
        }

        cb(result)
      },
      cluesTypeEvent(val){
        this.cluesType = val
        if(this.cluesTypeDataCopy){
          switch (val) {
            case 1 : this.cluesTypeData = this.cluesTypeDataCopy.appClueDtoList ; break;
            case 2 : this.cluesTypeData = this.cluesTypeDataCopy.oppositionClueDtoList;
              break;
            case 3 : this.cluesTypeData = this.cluesTypeDataCopy.rejectClueDtoList.map((item) => {
              if(item.agentList && item.agentList.length > 0){
                item.agentList = item.agentList.filter(items => items !== null && typeof items !== "undefined" && items !== "")
              }
              return item
            }); break;
            case 4 : this.cluesTypeData = this.cluesTypeDataCopy.invalidClueDtoList.map((item) => {
              if(item.agentList && item.agentList.length > 0){
                item.agentList = item.agentList.filter(items => items !== null && typeof items !== "undefined" && items !== "")
              }
              return item
            }) ; break;
          }
        }
        this.$nextTick(() => {
          this.$refs.cluesTypeData.doLayout();
        })
      },
      queryTmTrialNum(){
        this.$axios.post("/common/query/queryTmTrialNum")
          .then(({data}) => {
            this.queryTmTrialNumArr = data
          })
      },
      objectSpanMethod({ row, rowIndex, $rowIndex, _rowIndex, column, columnIndex, $columnIndex, _columnIndex, data }) {
        // 当列索引是2(第三列), 需要设置要合并的列/行
        if (!(columnIndex == 5 || columnIndex == 6)) {
          if (rowIndex % 5 == 0) {
            // 当行索引是0(第一行), 或者当行索引是3(第四行), 均向下合并1行(共两行), 合并0列(共一列)
            return {
              rowspan: 5, colspan: 1
            };
          }
          else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      },
      // 获取地区
      getArea(value){
        this.$axios.post("/common/query/queryArea",  {
          province: value,
          city: ''
        })
          .then(({data}) => {
            this.regionArr = data
          })
      },

      resetTableRowHighlight() {
        // 移除所有高亮行的高亮样式
        $('.mo-table__row').removeClass('mo-table__row--active');
      },

      setTableRowHighlight() {
        // 重新添加高亮行的高亮样式
        for (let item of this.highlight_rows) {
          // 通过预先定义的行号来选中要高亮的行
          let selector = `.mo-table__row:nth-child(${item.row_index + 1})`;
          $(selector).addClass('mo-table__row--active');
        }
      },
      // 获取地区
      getAreData(){
        this.$axios.post("/common/query/agent/clue",  {
          province:	this.formInline.city,
          city:	 (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  undefined : this.formInline.region,
          district:	  (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  this.formInline.region : undefined
        })
          .then(({data}) => {
            this.businessLeadsGridOption.data = data
          })
      },
      handleOnSubmitButtonClick(){
        this.queryNotivcePageNos = 1
        this.queryNotivceSizes = 50
        this.continuationCluesCurrent = 1
        this.continuationCluesSize = 20

        //若leadsRadio不为3执行以下方法会报undefined错误
        if (this.leadsRadio == 3){
          this.queryNotivceArrEvent()
        }

        this.onSubmit()
      },
      onSubmit(){
        //提交业务线索
        if(this.leadsRadio == 1){
          this.getAreData()
        }
        else if(this.leadsRadio == 2){
          this.$axios.post("/common/query/applicant/clue",  {
            province:	this.formInline.city,
            city:	 (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  undefined : this.formInline.region,
            district:	  (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  this.formInline.region : undefined
          }).then(({data}) => {
            this.cluesTypeDataCopy = data
            switch (this.cluesType) {
              case 1 : this.cluesTypeData = data.appClueDtoList ; break;
              case 2 : this.cluesTypeData = data.oppositionClueDtoList; break;
              case 3 : this.cluesTypeData = data.rejectClueDtoList ; break;
              case 4 : this.cluesTypeData = data.invalidClueDtoList ; break;
            }
          })
        }
        else if(this.leadsRadio == 3){
          this.$axios.post("/common/query/queryNotivce",  {
            trialNum:	this.formInline.num,
            trialType: this.formInline.type,
            current: this.queryNotivcePageNos,
            size: this.queryNotivceSizes,
            ...this.queryNotivceAsideForms
          })
            .then(({data}) => {
              if(data.code == 0){
                this.queryNotivceArrGridOption_1.data = data.data.records
                this.queryNotivceArrGridOption_2.data = data.data.records
                this.queryNotivceArrGridOption_3.data = data.data.records
                this.queryNotivceArrGridOption_4.data = data.data.records
                this.queryNotivceArrGridOption_5.data = data.data.records
                this.queryNotivceArrGridOption_6.data = data.data.records
                this.queryNotivceArrGridOption_7.data = data.data.records
                this.queryNotivcetotal = data.data.total
              }
            })
        }
        else if(this.leadsRadio == 4){
          this.$axios.post("/common/query/queryRenewData",  {
            current:this.continuationCluesCurrent,
            size:this.continuationCluesSize,
            province:	this.formInline.city,
            ggBeginDate: this.ggDate_ && this.ggDate_[0],
            ggEndDate: this.ggDate_ && this.ggDate_[1],
            validBeginDate: this.validDate_ && this.validDate_[0],
            validEndDate: this.validDate_ && this.validDate_[1],
            city:	 (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  undefined : this.formInline.region,
            district:	  (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  this.formInline.region : undefined,
            ...this.continuationAsideForms
          })
            .then(({data}) => {
              this.continuationCluesOption.data = data.records
              this.continuationCluesTotal = data.total
            })
        }
      },
      //续展线索页面变化
      handleContinuationCluesPageChange({currentPage, pageSize}){
        this.continuationCluesCurrent = currentPage
        this.continuationCluesSize = pageSize
        this.onSubmit()
      },
      radioGroupChange(value){
        this.continuationAsideForms = {}
        this.formInline.city = ''
        this.formInline.region = ''
        this.formInline.num = ''
        this.formInline.type = ''
        this.businessLeadsGridOption.data = []
        this.cluesTypeData = []
        this.queryNotivceArrGridOption_1.data = []
        this.queryNotivceArrGridOption_2.data = []
        this.queryNotivceArrGridOption_3.data = []
        this.queryNotivceArrGridOption_4.data = []
        this.queryNotivceArrGridOption_5.data = []
        this.queryNotivceArrGridOption_6.data = []
        this.queryNotivceArrGridOption_7.data = []
        this.continuationCluesOption.data = []
        this.ggDate_ = []
        this.validDate_ = []
      },
      selectChange_2(value){},
      selectChange_1(value){
        this.formInline.region = ''
        this.regionArr = []
        this.getArea(value);
      },
      que(){
        this.categoryTypeVisible = false
        this.categoryTypeVisibleFlag = true
        this.isBigDatas();
        this.searchCross_();
      },
      toggle () {
        this.fullscreen = !this.fullscreen
      },
      getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL
      },
      returnDataUrl(url){
        this.imageText = ''
        var img = document.createElement('img');
        img.src = this.queryDataUrl(url);  //此处自己替换本地图片的地址
        img.crossOrigin= 'anonymous'
        img.onload =() => {
          var data = this.getBase64Image(img);
          var img1 = document.createElement('img');
          img1.src = data;
          this.searchText = ''
          this.base64Url = data
        }
      },
      close(){
        this.imageText = ''
        this.imageUrla = ''
        this.base64Url = ''
      },
      //清除
      clearChose(){
        this.imageUrla = ''
      },
      //提交
      render(){
        if(!this.imageUrla){
          this.$message("请上传图片")
          return
        }
        this.UloaddialogTableVisible = false
        this.isBigDatas()
        this.isColumn = true
      },
      //退出登录
      async loginOut(){
        // const authData = await this.$axios.delete("/auth/token/logout");
        // if (authData.data.code == 0) {
        this.tool.clearCookie('auth');
        this.setMe("");
        this.setNoticeList("");
        // }
      },
      //生成缴费清单
      paymentXlsx() {
        this.$axios.post('/trademark/monitoringRenewal/export', this.$refs.xGrid.getCheckboxRecords().map(item => item.id), {responseType: 'blob'})
          .then(({code, data, msg}) => {
            const content = data
            const blob = new Blob([content], { type: "application/vnd.ms-excel;charset=utf-8" })
            const fileName = '缴费清单.xls'
            if ('download' in document.createElement('a')) { // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            }
            else { // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .catch((error) => {})
      },
      //剩余监控个数
      async monitorRemaining() {
        await this.$axios.get("/product/servicemanage/getMonitorTimes")
          .then(({data}) => {
            if (data.code == 0) {
              //总次数
              this.trademarkMonitorTotalTimes = data.data.totalTimes
              //使用次数
              this.trademarkMonitorUsageTime = data.data.usageTimes
              //剩余次数
              this.traderarkMonitorRemainTime = data.data.remainTimes
            }
          })
      },
      //点击一键添加商标监控触发
      trademarkMonitorFn(){
        if (this.selectData.length == 0){
          this.$message("请选择一条商标数据")
          return
        }
        if (this.selectData.length != 1){
          this.$message('商标监控暂不支持多选')
          return
        }
        //带入商标数据
        let row = this.selectData[0]
        this.trademarkMonitorData.niceSet = row.intcls.split(";")
        this.trademarkMonitorData.trademarkName = row.sbmc
        this.trademarkMonitorData.iconImageUrl = row.imageUrl

        this.trademarkMonitorData.countrySet = []
        this.trademarkMonitorData.countrySet.push(row.countryZhName)
        if(row.countryZhName == "中国大陆"){
          this.trademarkMonitorContainCN = true
        }
        let countryCodeSet = []
        countryCodeSet.push(this.searchCountryCodeByName(row.countryZhName))
        this.$nextTick(()=>{
          this.$refs.tree[0].setCheckedKeys(countryCodeSet)
        })

        this.trademarkMonitorData.sourceType = 1

        this.trademarkMonitorDialogShow = true
      },
      //一键添加监控点击国家触发
      trademarkMonitorSelectCountryFn(val){
        let arr = []
        if (val.length == 0) {
          this.$refs.tree[0].setCheckedKeys([])
          this.trademarkMonitorContainCN = false
        }
        else {
          this.allCountry.map(item => {
            val.map(items => {
              if (item.city == items) {
                arr.push([item.code])
              }
              return
            })
            return
          })
          this.$refs.tree[0].setCheckedKeys(arr)

          let CN = false
          val.map(item=>{
            if (item == '中国大陆'){
              CN = true
            }
          })
          this.trademarkMonitorContainCN = CN
          if (this.trademarkMonitorContainCN == false){
            this.trademarkMonitorData.revokeThreeFlag = null
            this.trademarkMonitorData.renewalFlag = null
            this.trademarkMonitorData.trademarkApplyNo = null
            this.trademarkMonitorData.trademarkOwnFlag = null
            this.trademarkMonitorData.renewalNiceCode = null
          }
        }
      },
      //一键添加监控确定选择国家触发
      trademarkMonitorCountrySelectConfirm(){
        this.trademarkMonitorData.countrySet = []
        //this.$refs.tree[0].getCheckedKeys() 内存储树形结构选中项
        // 0 1 2 3 4 5 6 7表示 全选 亚洲 欧洲 北美洲 南美洲 非洲 大洋洲 世界知识产权组织
        // 其余则为国家简写 如 中国大陆->CN
        if (this.$refs.tree[0].getCheckedKeys().includes(0)) {
          //若存在 0 则表示全选，将所有国家/地区插入this.addOrEditData.countrySet
          this.allCountry.map(item => {
            this.trademarkMonitorData.countrySet.push(item.city)
            return
          })
        }

        else {
          this.allCountry.map(item => {
            this.$refs.tree[0].getCheckedKeys().map(items => {
              if (item.code == items) {
                this.trademarkMonitorData.countrySet.push(item.city)
                return
              }
            })
            return
          })
        }

        if (this.$refs.tree[0].getCheckedKeys().includes("CN")){
          this.trademarkMonitorContainCN = true
        }
        else {
          this.trademarkMonitorContainCN = false
        }

        this.$refs.fuzzySearch.blur()
      },
      //一键添加监控国家选择过滤方法
      trademarkMonitorCountryFilterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      //一键添加监控商标图片上传前触发
      trademarkMonitorBeforeImageUpload(file){
        const isLt5M = file.size < 1024 * 1024 * 5
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          return false
        }
        return true
      },
      //一键添加监控商标图片上传成功触发
      trademarkMonitorImageUploadFn(response) {
        if (response.code == 0){
          this.trademarkMonitorData.iconImageUrl = response.data.url
          this.trademarkMonitorData.sourceType = 0
        }
        else this.$message('图片上传失败')
      },
      //新增商标监控删除图片
      trademarkMonitorDeleteImg() {
        this.trademarkMonitorData.iconImageUrl = ""
        this.trademarkMonitorData.sourceType = 0
      },
      //一键添加监控选择类别触发
      trademarkMonitorCategorySelectOpen(){
        this.trademarkMonitorNiceVisible = true
      },
      //一键添加监控类别种类，true为商标类别，false为续展商标类别
      trademarkMonitorCategoryType(type){
        if(type) this.trademarkMonitorCategoryOrRenewalCategory = 0
        else this.trademarkMonitorCategoryOrRenewalCategory = 1
      },
      //一键添加商标监控实际执行保存商标监控触发
      trademarkMonitorSave(){
        if (this.trademarkMonitorData.countrySet == []){
          this.$message("请选择监控国家")
          return
        }

        if (this.trademarkMonitorData.niceSet == []){
          this.$message("请选择监控类别")
          return
        }

        if (this.trademarkMonitorData.trademarkName == "" && this.trademarkMonitorData.iconImageUrl == ""){
          this.$message("请输入商标名称或上传商标图片")
          return
        }

        if (this.trademarkMonitorContainCN && this.trademarkMonitorData.revokeThreeFlag != null && this.trademarkMonitorData.revokeThreeFlag == true){
          if (this.trademarkMonitorData.trademarkApplyNo == null || this.trademarkMonitorData.trademarkApplyNo == ''){
            this.$message("请输入商标申请号")
            return
          }
          if (this.trademarkMonitorData.trademarkOwnFlag == true ? false : this.trademarkMonitorData.trademarkOwnFlag == false ? false : true){
            this.$message("请选择是否为商标专有人")
            return
          }
        }

        if (this.trademarkMonitorContainCN && this.trademarkMonitorData.renewalFlag != null && this.trademarkMonitorData.renewalFlag == true){
          if (this.trademarkMonitorData.trademarkApplyNo == null || this.trademarkMonitorData.trademarkApplyNo == ''){
            this.$message("请输入商标申请号")
            return
          }
          if (this.trademarkMonitorData.renewalNiceCode == null || this.trademarkMonitorData.renewalNiceCode == ''){
            this.$message("请选择商标续展所需的商标类别")
            return
          }
        }

        if (!this.trademarkMonitorContainCN){
          this.trademarkMonitorData.revokeThreeFlag = null
          this.trademarkMonitorData.renewalFlag = null
          this.trademarkMonitorData.trademarkApplyNo = null
          this.trademarkMonitorData.trademarkOwnFlag = null
          this.trademarkMonitorData.renewalNiceCode = null
        }

        this.trademarkMonitorIsDisabled = true

        //若存在商标名称，则创建文字图像并创建监控订单
        if (this.trademarkMonitorData.trademarkName != "" && this.trademarkMonitorData.trademarkName != null) {
          let jishu = this.trademarkMonitorGetBLen(this.trademarkMonitorData.trademarkName) * 0.6
          this.$axios.post("/trademark/trademark/generateUrl", {
            font: this.trademarkMonitorData.trademarkName,
            fontpx: 1000 / jishu
          })
            .then(({data}) => {
              if (data.code == 0) {
                this.trademarkMonitorData.wordImageUrl = data.data

                this.trademarkMonitorCreatedOrderFn()
              }
            })
            .catch(error => {
              this.trademarkMonitorIsDisabled = false
            })
        }
        //若不存在商标名称，则直接创建监控订单
        else {
          this.trademarkMonitorCreatedOrderFn()
        }
      },
      trademarkMonitorGetBLen(str){
        var len = 0
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94 || str.charCodeAt(i) == 87) {
            len += 2
          } else if ((str.charCodeAt(i) >= 65 && str.charCodeAt(i) <= 90)) {
            len += 1.3
          } else {
            len++
          }
        }
        if (len == 1) len = 1.8
        if (len == 1.3) len = 2
        return len
      },
      trademarkMonitorCreatedOrderFn(){
        let sourceType = this.trademarkMonitorData.iconImageUrl == '' ? 0 :this.trademarkMonitorData.iconImageUrl.startsWith('/group') ? 1 : 0

        let doAddOrEditData = {}
        doAddOrEditData.countrySet = []
        doAddOrEditData.niceSet = this.trademarkMonitorData.niceSet
        doAddOrEditData.trademarkName = this.trademarkMonitorData.trademarkName
        doAddOrEditData.wordImageUrl = this.trademarkMonitorData.wordImageUrl
        doAddOrEditData.iconImageUrl = this.trademarkMonitorData.iconImageUrl
        doAddOrEditData.remarks = this.trademarkMonitorData.remarks
        doAddOrEditData.monitorType = this.trademarkMonitorData.monitorType
        doAddOrEditData.sourceType = sourceType
        doAddOrEditData.revokeThreeFlag = this.trademarkMonitorData.revokeThreeFlag
        doAddOrEditData.renewalFlag = this.trademarkMonitorData.renewalFlag
        doAddOrEditData.trademarkApplyNo = this.trademarkMonitorData.trademarkApplyNo
        doAddOrEditData.trademarkOwnFlag = this.trademarkMonitorData.trademarkOwnFlag
        doAddOrEditData.renewalNiceCode = this.trademarkMonitorData.renewalNiceCode
        this.trademarkMonitorData.countrySet.forEach(item=>{
          doAddOrEditData.countrySet.push(this.searchCountryCodeByName(item))
        })

        this.$axios.post("/trademark/monitoring/createMonitoringImageOrWord", doAddOrEditData)
          .then(({data}) => {
            this.trademarkMonitorIsDisabled = false
            if (data.code == 0) {
              this.trademarkMonitorIsDisabled = false
              this.$message({
                message: '商标监控添加成功',
                type: 'success'
              })
              this.trademarkMonitorDialogShow = false
            }
            else if (data.code == 900) {
              this.$confirm(data.data.paymentMessage, '提示', {
                confirmButtonText: '去购买',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  this.$router.push({
                    path: "/services"
                  })
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.traderarkMonitorRemainTime}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.traderarkMonitorRemainTime}个` : `本次添加失败。剩余个数还是${this.traderarkMonitorRemainTime}个`
                  })
                })
            }
            else if (data.code == 901){
              this.$confirm(data.data.paymentMessage, '提示', {
                confirmButtonText: '去添加',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(()=>{
                  this.$router.push({
                    path: '/personalCenter/account'
                  })
                })
                .catch(()=>{
                  this.trademarkMonitorDialogShow = false
                })
            }
            else if (data.code == 10001) { this.$message('参数校验失败') }
            else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
            else if (data.code == 10003) { this.$message('没有商标监控权限') }
            else if (data.code == 10009) { this.$message('未知异常') }
            else { this.$message('添加失败') }
          })
          .catch(error => {
            this.isDisabled = false
            this.$message(error.message)
          })
      },
      searchCountryCodeByName(countryName){
        let s = ""
        this.allCountry.map(item=>{
          if (item.city == countryName){
            s = item.code
          }
        })
        return s
      },
      trademarkMonitorClose(){
        this.trademarkMonitorNiceVisible = false
        this.trademarkMonitorDialogShow = true
      },
      trademarkMonitorClassifyType(data){
        if (this.trademarkMonitorCategoryOrRenewalCategory == 0){
          this.trademarkMonitorData.niceSet = []
          data.forEach(item=>{
            this.trademarkMonitorData.niceSet.push(item.no)
          })
        }
        else {
          if (data.length > 1){
            this.$message("此处仅可以选择一个类别")
          }
          else {
            this.trademarkMonitorData.renewalNiceCode = data[0].no
          }
        }
      },
      getMonitorImageUrl(url){
        if(url.startsWith("/group")){
          return "https://tmimage.lanternfish.cn" + url
        }
        else {
          return url
        }
      },
      getWindowSize(){
        // 获取窗口的宽度和高度，不包括滚动条
        var w = document.documentElement.clientWidth;
        var h = document.documentElement.clientHeight;
        if(w > 1600){
          this.wSize = true
        }
        else{
          this.wSize = false
        }
      },
      arrows1(){
        this.arrow1 = !this.arrow1
      },
      //生成缴费清单
      paymentList(){
        this.paymentXlsx()
      },
      //获取节假日
      festival(){
        var date = new Date();
        this.$axios.get('/trademark/workHoliday/queryWorkHolidayListByYear',{
          params:{
            currentYear: date.getFullYear()
          }
        })
          .then(({data}) => {
            if(data.code == 0){
              this.festivalData = data.data
            }
          })
      },
      //当前日期加减一天
      dateFormat(dateTime,day){
        const d1 = new Date(dateTime);
        return new Date(d1.setDate(d1.getDate()-day));
      },
      //判断是否是节假日
      isFestivalData(value){
        if(this.festivalData.filter(item => item.currentDay == value).length > 0){
          return this.festivalData.filter(item => item.currentDay == value)[0].lastWorkDay
        }
        else {
          return value
        }
      },
      //判断是否为周末和节假日
      isWeekendAndFestivalData(date) {
        const d1 = new Date(date);
        if(this.isFestivalData(date) != date){
          return this.isFestivalData(date)
        }
        else if(d1.getDay() === 6){
          return this.isFestivalData(this.dateFormat(date,1))
        }
        else if(d1.getDay() === 0){
          return this.isFestivalData(this.dateFormat(date,2))
        }
        else {
          return date
        }
      },
      //日期比较
      compareDate(dateTime1,dateTime2){
        if((new Date(dateTime1).getTime() <= new Date().getTime()) && (new Date().getTime() <= new Date(dateTime2).getTime())){
          return true
        }
        else {
          return false
        }
      },
      //当前日期减少
      dateDecrease(item,nowDate){
        const d1 = new Date(nowDate);
        if(item.type == 'year'){
          var year_Mill = item.mold == '+' ? d1.setFullYear((d1.getFullYear() + item.value)) : d1.setFullYear((d1.getFullYear() - item.value));
          var year_Date = echarts.format.formatTime('yyyy-MM-dd', new Date(year_Mill));
          return year_Date
        }
        else if(item.type == 'mounth'){
          var month_Mill = item.mold == '+' ? d1.setMonth((d1.getMonth() + item.value)) : d1.setMonth((d1.getMonth() -  item.value))
          var month_Date = echarts.format.formatTime('yyyy-MM-dd', new Date(month_Mill));
          return month_Date
        }
      },
      //计算最后
      costCalcu(date){
        var dataVale1 = this.dateDecrease({
          type: 'year',
          value: 10,
          mold: '+'
        },date)
        if(this.compareDate(dataVale1,this.isWeekendAndFestivalData(this.dateDecrease({
          type: 'mounth',
          value: 6,
          mold: '+'
        },dataVale1)))){
          //续展宽限期
          return 1
        }
        else if(this.compareDate(this.dateDecrease({
          type: 'year',
          value: 1,
          mold: '-'
        },dataVale1),this.isWeekendAndFestivalData(dataVale1))){
          //判断商标续展期
          return 2
        }
        else {
          return 3
        }
      },
      //费用计算保存
      costCalculationSave(){
        let ids = this.$refs.xGrid.getCheckboxRecords().map(item => item.id)
        let arr = []
        this.extension.data.filter(item => ids.includes(item.id)).map(item => {
          arr.push({
            id:	item.id,
            submitType:	item.submitType,
            renewalAmount:	item.renewalAmount == '/' ? '0' : item.renewalAmount,
            extendedAmount:	item.extendedAmount == '/' ? '0' : item.extendedAmount,
            renewalSummaryAmount: item.renewalSummaryAmount == '/' ? '0' : item.renewalSummaryAmount
          })
          return item
        })

        this.$axios.post('/trademark/monitoringRenewal/batch/update/amount', arr).then(({data}) => {
          if(data.code == 0){
            this.$message({
              type: 'info',
              message: '计算成功'
            });
          }
        })
      },
      //费用计算
      costCalculation(){
        if(this.$refs.xGrid.getCheckboxRecords().length == 0){
          this.$message({
            type: 'info',
            message: '请选择要计算的商标'
          });
          return;
        }
        else if(this.$refs.xGrid.getCheckboxRecords().length > 10){
          this.$message({
            type: 'info',
            message: '计算的商标数量不能超过10个'
          });
          return;
        }
        this.$confirm('电子/纸质的提交方式会影响续展官费，请确认提交方式无误！', {
          customClass:'costCalculationClass',
          confirmButtonText: '确认无误',
          cancelButtonText: '返回修改',
          type: 'warning'
        })
          .then(() => {
            let arr = this.$refs.xGrid.getCheckboxRecords().map(item => item.id)
            this.extension.data = this.extension.data.map(item => {
              if(arr.includes(item.id)){
                if(item.registerDate && this.costCalcu(item.registerDate) == 1){
                  //续展费用
                  item.renewalAmount = item.submitType == 0 ? 450 : 500
                  //宽展费用
                  item.extendedAmount = item.submitType == 0 ? 225 : 250
                  //续展费和宽展费之和
                  item.renewalSummaryAmount = (item.submitType == 0 ? 675 : 750)
                  item.color = 8
                }
                else if(item.registerDate && this.costCalcu(item.registerDate) == 2){
                  //续展费用
                  item.renewalAmount = item.submitType == 0 ? 450 : 500
                  //宽展费用
                  item.extendedAmount = '/'
                  //续展费和宽展费之和
                  item.renewalSummaryAmount = item.submitType == 0 ? 450 : 500
                  item.color = 7
                }
                else {
                  //续展费用
                  item.renewalAmount = '/'
                  //宽展费用
                  item.extendedAmount = '/'
                  //续展费和宽展费之和
                  item.renewalSummaryAmount = '/'
                }
              }
              return item;
            })
            if (this.$refs.xGrid && this.$refs.xGrid.getCheckboxRecords().length > 0) this.getCheckboxRecords = false
            else this.getCheckboxRecords = true
            this.costCalculationSave()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
      },
      //我得续展
      myRenewal(){
        this.extensionFlag = true
        this.extensionFlag1 = true
        this.searchText = ''
        this.extensionEvent()
      },
      //添加续展
      addRenewal(){
        if(this.extensionFlag){
          return
        }
        if(this.$refs.xGrid.getCheckboxRecords().length == 0){
          this.$message({
            type: 'info',
            message: '请选择要续展的商标'
          })
          return
        }
        if(this.$refs.xGrid.getCheckboxRecords().length > 10){
          this.$message({
            type: 'info',
            message: '添加续展的商标数量不能超过10个'
          });
          return
        }
        var arr = []
        var obj = {}
        this.$axios.post('/common/query/idListQuery', {
          country: 'CN',
          idList: this.$refs.xGrid.getCheckboxRecords().map(item => item.id)
        })
          .then(({data}) => {
            if(data.code == 0){
              data.data.records.map(item => {
                obj = {
                  trademarkApplyNo: item.appno,	//string 必须 商标申请号
                  trademarkName: item.tmcn || item.tmen,	//string 非必须 商标名称
                  trademarkImageUrl: item.imageUrl,	//string 非必须 商标的图片地址
                  imageCode: item.imagecode,	//string 非必须 图形编码
                  trademarkStatus: item.tmstatus,	//string 非必须 商标状态
                  registerDate: item.ggdate,	//string 非必须 注册日期
                  registerNo: item.regno,	//string 非必须 注册号
                  applyPersonName: item.tmapplicant,	//string 非必须 申请人名称
                  lastRenewalDate: item.renewaldate,	//string 非必须 上次续展时间
                  submitType: 0,	//number 必须 提交方式 0电子，1纸质
                  renewalNiceCode: item.intcls	//string 必须 尼斯分类
                }
                arr.push(obj)
                return item;
              })
            }
            this.$axios.post('/trademark/monitoringRenewal/batch/save', arr).then(({data}) => {
              if(data.code == 0){
                this.$message('续展监控添加成功');
              }
            })
          })
      },
      //无效公告高级查询
      searchAdvanced(){
        this.announcement.data = []
        this.invalidAnnouncement = true
        this.isModeTable = true
        clearInterval(this.intervalId);
        // 卸载事件监听器
        window.removeEventListener('scroll', () => {
          const isAtTop = this.chatBox.scrollTop === 0;

          if (isAtTop) {
            console.log('div 已经滚动到顶部');
            // 可以在这里执行一些操作，比如显示提示、加载更多内容等
            this.automaticPagination(false)
          }
        });
        this.trademarkFlag = false
        if(this.conditions && this.conditions.hasOwnProperty('niceList')){
          if(!(this.conditions.niceList instanceof Array)) this.conditions.niceList = this.conditions.niceList.split(';')
        }
        if(this.conditions && this.conditions.hasOwnProperty('reason')){
          if(!(this.conditions.reason instanceof Array)) this.conditions.reason = this.conditions.reason.split(';')
        }
        this.dialogTableVisibles = false
        this.$axios({
          url: '/common/query/getInvalidNotice',
          method:'post',
          // data:{ID: '123'}, // body参数
          // query参数
          data:{
            current: this.pageNoss,
            size: this.pageSizess,
            tmName: this.selects == 1 ? this.input3 || undefined : undefined,
            applicant: this.selects == 2 ? this.input3 || undefined  : undefined,
            tmAgent: this.selects == 3 ? this.input3 || undefined  : undefined,
            trialType:	this.radio1 ? this.radio1 : undefined,
            reason:	this.radio3.length > 0 ? this.radio3 : undefined,
            trialNum: this.input4 ? this.input4  : this.radio2 ? this.radio2 : undefined,
            secondQuery: this.searchAdvancedFlag ? undefined : this.conditions
          }
        })
          .then(({data}) => {
            if(data.code == 0){
              if(data.data.records.length > 0){
                this.announcements.data = data.data.records.map(item => {
                  item.trialImageUrl = this.queryDataUrl(item.imageUrl)
                  return item;
                })
                this.totals = data.data.total
                this.imgSrcArr = this.announcements.data.map(item => item.trialImageUrl)
                this.pdfSrc = this.announcements.data[0].trialImageUrl
                this.dialogTableVisibles = false
                this.$message('查询成功');
              }
              else {
                this.dialogTableVisibles = false
                this.announcements.data = []
                this.$message('查询成功,暂无数据');
              }
            }
          })
      },
      gaoji(){
        if(this.tool.getCookie("auth")){
          this.pageNoss = 1
          this.totals = 0
          this.dialogTableVisibles = !this.dialogTableVisibles
        }
        else {
          this.dialogVisible = true
        }
      },
      noRepeat(arr){
        var newArr = [...new Set(arr)]; //利用了Set结构不能接收重复数据的特点
        return newArr
      },
      handleSelectionChanges(val) {
        this.crossNice = this.noRepeat(val.map(item => item.nice).concat(val.map(item => item.crossNice)))
        val = val.filter(item => item != undefined)
        if(val.length == 0){
          this.multipleSelection = [];
        }
        else if(this.sameMessage.length == val.length){
          this.multipleSelection = [];
        }
        this.multipleSelection = this.noRepeat(val.map(item => item.item3)).concat(this.sameMessage.map(item => item.item3))
      },
      handleSelectionChange(val) {
        val = val.filter(item => item != undefined)
        if(val.length == 0){
          this.multipleSelection = [];
        }
        else if(this.sameMessage.length == val.length){
          this.multipleSelection = [];
        }
        this.multipleSelection = this.noRepeat(val.map(item => item.item3))
      },
      businessLeadsRowClassName({row, column, rowIndex, columnIndex}){
        if (rowIndex % 2 == 0) {
          return 'background-row';
        } else if (rowIndex === 3) {
          // return 'success-row';
        }
        return '';
      },
      tableRowClassName({row, rowIndex}) {
        if (row.color == 1) {
          return 'color-row1';
        } else if (row.color == 2) {
          return 'color-row2';
        } else {
          return 'color-row3';
        }
      },
      tableRowClassNames({row, rowIndex}) {
        if (row.color == 1) {
          return 'color-row1-1';
        } else if(row.color == 7) {
          return 'color-row1-7';
        } else if(row.color == 8) {
          return 'color-row1-8';
        }
      },
      tableHeaderClassName(){
        return 'color-header';
      },
      //网络证据
      evidence(item){
        if (item.country == "CN") {
          window.open(window.location.origin + '/#/query/detail' + '?id=' + item.id + '&country=' + item.country + '&flag=1');
        }
        else {
          window.open(window.location.origin + '/#/query/otherTrademarkArchives' + '?id=' + item.id + '&country=' + item.country + '&flag=1');
        }
      },
      //是否商标vip
      isVips(){
        this.$axios.get("/product/servicemanage/getTmVipLevel").then(({data}) => {
          if(data.code == 0){
            this.isVip = data.data
          }
        })
      },
      //获取用户商标查询业务权限和剩余次数接口
      getServicePermission() {
        let contory = this.stateChange.split(";")
        this.$axios
          .get("/product/jurisdiction/getTmServicePermission", {
            headers: {
              'Authorization': "Bearer " + this.tool.getCookie("auth"),
              'TENANT-ID': '4'
            }
          })
          .then(({data}) => {
            if (data.code == 0) {
              if(contory.length == 1 && contory.includes('CN')){
                if(data.data.cnAuth){
                  if(data.data.cnCount > 0){
                    this.getServicePermissionFlag = true;
                  }
                  else {
                    this.$confirm('您的次数不足哦，请订购服务?', '提示', {
                      confirmButtonText: '去订购',
                      cancelButtonText: '取消',
                      type: 'warning'
                    })
                      .then(() => {
                        this.$router.push('/services')
                      })
                      .catch(() => {
                        this.$message({
                          type: 'info',
                          message: '已取消订购'
                        });
                      })
                    this.getServicePermissionFlag = false;
                  }
                }
                else if (data.data.globalAuth){
                  if(data.data.globalCount > 0){
                    this.getServicePermissionFlag = true;
                  }
                  else {
                    this.$confirm('您的次数不足哦，请订购服务?', '提示', {
                      confirmButtonText: '去订购',
                      cancelButtonText: '取消',
                      type: 'warning'
                    })
                      .then(() => {
                        this.$router.push('/services')
                      })
                      .catch(() => {
                        this.$message({
                          type: 'info',
                          message: '已取消订购'
                        });
                      })
                    this.getServicePermissionFlag = false;
                  }
                }
                else {
                  this.$confirm('您还没有订购所选国家的服务，请订购服务?', '提示', {
                    confirmButtonText: '去订购',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
                    .then(() => {
                      this.$router.push('/services')
                    })
                    .catch(() => {
                      this.$message({
                        type: 'info',
                        message: '已取消订购'
                      });
                    });
                  this.getServicePermissionFlag = false;
                }
              }
              else {
                if (data.data.globalAuth){
                  if(data.data.globalCount >= contory.length){
                    this.getServicePermissionFlag = true;
                  }
                  else {
                    this.$confirm('您的次数不足哦，请订购服务?', '提示', {
                      confirmButtonText: '去订购',
                      cancelButtonText: '取消',
                      type: 'warning'
                    })
                      .then(() => {
                        this.$router.push('/services')
                      })
                      .catch(() => {
                        this.$message({
                          type: 'info',
                          message: '已取消订购'
                        });
                      })
                    this.getServicePermissionFlag = false;
                  }
                }
                else {
                  this.$confirm('您还没有订购所选国家的服务，请订购服务?', '提示', {
                    confirmButtonText: '去订购',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
                    .then(() => {
                      this.$router.push('/services')
                    })
                    .catch(() => {
                      this.$message({
                        type: 'info',
                        message: '已取消订购'
                      })
                    })
                  this.getServicePermissionFlag = false
                }
              }
            }
          })
      },
      jumphistory(){
        this.show = false
        this.isCharts = false
        //获取查询日志记录
        this.queryAjax();
      },
      //去对比
      goCcomparison(){
        this.selectData.map((item,index) => {
          if(item.appno == this.radioValue){
            this.selectData.unshift(this.selectData.splice(index , 1)[0]);
          }
        })

        this.$router.push({
          path:'/contrast',
          query: {
            appno: this.selectData.map(item => item.appno).join(";")
          }
        })
      },
      //商标对比
      trademarkCcomparison(){
        if(this.selectData.length > 0){
          if(this.selectData.length > 10){
            this.$refs.xGrid?.clearCheckboxRow()
            this.$message('商标对比最多可选择10个商标');
            return;
          }
          this.dialogTableVisible = true
          this.radioValue = this.selectData[0].appno
          localStorage.setItem('trademarkCcomparison',JSON.stringify(this.selectData))
        }
        else {
          this.$message('请选择要对比的商标数据');
        }
      },
      //关闭商标对比弹窗
      clase(){
        this.dialogTableVisible = false
      },
      isBigDatas() {
        this.isBigData = true
      },
      //426活动
      activeicon() {
        this.activeVisible = !this.activeVisible
      },
      pdfs(item) {
        //pdf 打印置顶
        if (this.pdfSrc) {
          window.open(this.pdfSrc)
        }
      },
      particulars() {},
      //商标详情--页面跳转
      trimlDetail(item) {
        window.open(window.location.origin + '/#/query/detail' + '?id=' + item.tmId + '&country=' + 'CN');
      },
      //公告期号
      Announcement(value) {
        this.announcementFlag = value
      },
      query() {
        this.$refs.xGrid.clearFilter()
        this.$refs.xGrid.clearSort()
        this.$refs.xGrid?.clearCheckboxRow()
        this.$refs.xGrid?.clearCheckboxReserve()
        this.filterChange()
      },
      heroic(){
        this.$axios.post('/common/query/isChineseMartyrs', {
          "keywork": this.searchText
        })
          .then((data) => {
            if (data.status == 200 && data.data) {
              let str = `<P >您搜索的关键词中包含<span style="color: #409EFF">${this.searchText}</span>是<span style="color: #409EFF">烈士姓名</span>，申请注册时可能因违反<span style="color: #409EFF">《商标法》第十条第一款</span>被驳回，请谨慎注册。`
              this.$notify({
                title: '烈士姓名提示',
                dangerouslyUseHTMLString: true,
                message: str
              });
            }
          })
      },
      famous() {
        this.$axios.post('/common/query/queryBrand', {
          "keywork": this.searchText,
          "niceList": this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined
        })
          .then((data) => {
            if (data.status == 200 && data.data.length > 0) {
              let str = ''
              for (let i = 0; i < data.data.length; i++) {
                str += `<P >您搜索的关键词中包含<span style="color: #409EFF">${data.data[i].zhName || data.data[i].enName}</span>是<span style="color: #409EFF">${data.data[i].applicant}</span>在第<span style="color: #409EFF">${data.data[i].nice}</span>类中拥有的驰名商标</P>`
              }
              this.$notify({
                title: '驰名商标提示',
                dangerouslyUseHTMLString: true,
                message: str
              });
            }
          })
      },
      wholebtn(item) {
        if (item == '全') {
          this.wholeText = item
          this.whole.startScore = 0
        }
        else {
          this.wholeText = '准'
          this.whole.startScore = 40
        }
      },
      isChecked(value) {
        this.isPaymentList = false
        if(this.$refs.xGrid.getCheckboxRecords().length == 0){
          this.isPaymentList = true
        }
        else {
          this.$refs.xGrid.getCheckboxRecords().map(item => {
            if(!item.renewalSummaryAmount){
              this.isPaymentList = true
            }
            return item;
          })
        }
        if (this.$refs.xGrid && this.$refs.xGrid.getCheckboxRecords().length > 0) this.getCheckboxRecords = false
        else this.getCheckboxRecords = true
        this.selectData = [...value.records, ...value.reserves]
      },
      isCheckeds(value) {
        this.isPaymentList = false
        if(this.$refs.xGrid.getCheckboxRecords().length == 0){
          this.isPaymentList = true
        }else {
          this.$refs.xGrid.getCheckboxRecords().map(item => {
            if(!item.renewalSummaryAmount){
              this.isPaymentList = true
            }
            return item;
          })
        }
        if (this.$refs.xGrid && this.$refs.xGrid.getCheckboxRecords().length > 0) this.getCheckboxRecords = false
        else this.getCheckboxRecords = true
        this.selectData = [...value.records, ...value.reserves]
      },
      changeValue() {
        this.searchText = ''
        this.handleRemove()
        this.resetSizer(false)
        // if (this.value != 2) {
        //   this.isRetrieval = false
        // } else {
        //   this.isRetrieval = true
        // }
      },
      //是否展示图形要素
      yaosuCopy(item) {
        if (item) {
          this.imagecodeFlag = !this.imagecodeFlag
        } else {
          this.$message('暂无数据');
        }
      },
      //日期判断
      dateComparison(a, b, c) {
        //开始日期
        var arr1 = a.split("-");
        var starttime1 = new Date(arr1[0], arr1[1], arr1[2]);
        var starttimes1 = starttime1.getTime();
        //结束日期
        var arrs2 = b.split("-");
        var lktime2 = new Date(arrs2[0], arrs2[1], arrs2[2]);
        var lktimes = lktime2.getTime();
        //比较日期
        var arrss3 = c.split("-");
        var lktimesd3 = new Date(arrss3[0], arrss3[1], arrss3[2]);
        var lktimess3 = lktimesd3.getTime();
        if (starttimes1 <= lktimess3 && lktimess3 <= lktimes) {
          return true
        } else return false;
      }
      ,
      IdCheckAllChanges(val) {
        this.checkedId = val ? this.gridOptions.data.map((item) => {
          return JSON.stringify({
            id: item.id,
            similarity: item.level
          })
        }) : [];
        this.isIndeterminateId = false;
      }
      ,
      CheckediDChanges(value) {
        let checkedCount = value.length;
        this.checkAlls = checkedCount === this.gridOptions.data.map(item => item.id).length;
        this.isIndeterminateId = checkedCount > 0 && checkedCount < this.gridOptions.data.map(item => item.id).length;
      }
      ,
      cellClassName({row, column, $columnIndex}) {
        if (this.value == 8) {
          return;
        }
        if ($columnIndex == 1 && this.switchTypeId != 4) {
          return 'col-orange'
        }
      }
      ,
      // 生成订单
      async saveOrderAjax (param) {
        let data = await this.$axios.post("/order/order/saveLanternfishOrder", {
          productServiceId: param.id,
          productId: param.productId,
          currency: param.currency, //币种：CNY人民币,USD美元,EUR欧元,JPY日元
          source: "web",
          orderType: "lantern" //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
        });
        if (data.data.code == 0) {
          this.$message('申请试用成功');
        }else {
          this.$message('申请试用失败');
        }
      },
      // 申请
      applicaEvent () {
        this.$axios.get('/product/productservicemanage/getPrePublishServiceList').then(({data}) => {
          if(data.code == 0){
            let param =  data.data.trialProductServiceManages[0]
            this.saveOrderAjax(param);
          }
        }).catch(err => {

        })

      },
      //表格单元格点击
      cellClick({
                  row,
                  rowIndex,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  triggerRadio,
                  triggerCheckbox,
                  triggerTreeNode,
                  triggerExpandNode,
                  $event
                }) {
        if ($columnIndex != 0 || this.switchTypeId == 4) {
          this.pdfSrc = row.trialImageUrl
        }

        if ($columnIndex == 0 || this.switchTypeId == 4) return;

        if (this.value == 8) {
          this.copys(row[column.property])
          return;
        }
        this.imagecodeFlag = false
        this.$refs.xGrid.toggleRowExpand(row)
      }
      ,
      //输入框是否聚焦
      focus() {
        this.inputFocus = true
        if (this.switchTypeId == 1 || this.value == 7 || this.switchTypeId == 5) {
          this.show = true
        }
      }
      ,
      filterChanges() {
        this.isBigData = false
        let tableData = this.backupData
        let getCheckedFilters = this.$refs.xGrid?.getCheckedFilters()
        getCheckedFilters.map(item => {
          if (item.values.length) return item.datas = item.values
        })
        this.pageNos = 1
        for (let i = 0; i < getCheckedFilters.length; i++) {
          tableData = tableData.filter(item => {
            if (getCheckedFilters[i].property == "appdate" || getCheckedFilters[i].property == "regdate" || getCheckedFilters[i].property == "trialdate" || getCheckedFilters[i].property == "ggdate") {
              return this.dateComparison(getCheckedFilters[i].column.filters.data[0], getCheckedFilters[i].column.filters.data[1], item[getCheckedFilters[i].property])
            } else if (getCheckedFilters[i].property == "countryZhName" || getCheckedFilters[i].property == "level" || getCheckedFilters[i].property == "tmstatus" || getCheckedFilters[i].property == "intcls") {
              for (let k = 0; k < getCheckedFilters[i].datas.length; k++) {
                if (item[getCheckedFilters[i].property] && item[getCheckedFilters[i].property].indexOf(getCheckedFilters[i].datas[k]) != -1) {
                  return true
                  break;
                } else {
                  if (k == getCheckedFilters[i].datas.length) return false
                }
              }
            } else {
              if (item[getCheckedFilters[i].property] && item[getCheckedFilters[i].property].indexOf(getCheckedFilters[i].column.filters[0].data) != -1) {
                return true
              } else {
                return false
              }
            }

          })
        }
        this.filterData = tableData
        this.gridOptions.data = this.filterData.slice((this.pageNos - 1) * this.pageSizes, this.pageNos * this.pageSizes)
        this.$refs.xGrid.updateData()
      },
      defineSwitchs(value) {
        if (!value) {
          this.gridOptions.data = this.allType.records
          this.total = this.allType.total;
          this.isPageSize = false
        } else {
          this.isPageSize = true
          this.gridOptions.data = this.selectData
          this.total = this.selectData.length;
        }
        // this.filterChanges()
      }
      ,
      //根据国家名称获取国家数组简称
      countryArray(arr) {
        for (let i = 0; i < arr.length; i++) {
          this.allCountry.map(item => {
            if (item.city == arr[i]) {
              arr[i] = item.code
            }
            return item;
          })
        }
        return arr
      },

      //筛选获取商标状态
      tmstatusArray(arr) {
        for (let i = 0; i < arr.length; i++) {
          for (let key in statusObj) {
            if (arr[i] == statusObj[key]) {
              arr[i] = key
            }
          }
        }

        if (arr.includes('1')) {
          arr.push('10')
        } else if (arr.includes('2')) {
          arr.push('9')
        } else if (arr.includes('3')) {
          arr.push('11')
        } else if (arr.includes('4') || arr.includes('5') || arr.includes('6') || arr.includes('7')) {
          arr.push('12')
        }
        return arr
      },
      queryNotivceFilterChanges() {
        this.continuationCluesCurrent = 1
        let getCheckedFilters = this.$refs.continuationCluesxGrids.getCheckedFilters()
        let getSortColumns = this.$refs.continuationCluesxGrids.getSortColumns()
        this.continuationAsideForms = {}

        getCheckedFilters.map(item => {
          switch (item.property) {
            //截止日期
            case 'validdate':
              this.continuationAsideForms.validBeginDate = [item.datas[0][0]][0];
              this.continuationAsideForms.validEndDate = [item.datas[0][1]][0];
              break;
            //注册日期
            case 'ggdate':
              this.continuationAsideForms.ggBeginDate = [item.datas[0][0]][0];
              this.continuationAsideForms.ggEndDate = [item.datas[0][1]][0];
              break;
            //代理机构
            case 'tmagent':
              this.continuationAsideForms.agent = item.datas[0];
              break;
            //申请人地址
            case 'tmaddress':
              this.continuationAsideForms.address = item.datas[0];
              break;
            //申请人名称
            case 'tmapplicant':
              this.continuationAsideForms.applicant = item.datas[0];
              break;
            //申请号
            case 'appno':
              this.continuationAsideForms.appNo = item.datas[0];
              break;
            //商标名称
            case 'sbmc':
              this.continuationAsideForms.tmName = item.datas[0];
              break;
            default:
              break;
          }
        })
        this.onSubmit()
      },
      queryNotivceFilterChange() {
        this.queryNotivcePageNos = 1
        let getCheckedFilters = this.$refs.queryNotivcexGrids.getCheckedFilters()
        let getSortColumns = this.$refs.queryNotivcexGrids.getSortColumns()
        this.queryNotivceAsideForms = {}
        if(getCheckedFilters.filter(item => item.property == 'deadline').length == 0){
          this.deadline1 = []
          this.deadline2 = []
        }
        getCheckedFilters.map(item => {
          switch (item.property) {
            //申请日
            case 'deadline':
              this.queryNotivceAsideForms.deadline1BeginDate = item.datas[0][0]
              this.queryNotivceAsideForms.deadline1EndDate = item.datas[0][1]
              this.queryNotivceAsideForms.deadline2BeginDate = item.datas[0][2]
              this.queryNotivceAsideForms.deadline2EndDate = item.datas[0][3]
              break;
            //申请日
            case 'deregisterdate':
              this.queryNotivceAsideForms.cancelBeginDate = [item.datas[0][0]][0];
              this.queryNotivceAsideForms.cancelEndDate = [item.datas[0][1]][0];
              break;
            //商品/服务项目
            case 'applicantName':
              this.queryNotivceAsideForms.applicantName = item.datas[0];
              break;
            //商品/服务项目
            case 'applicantAddress':
              this.queryNotivceAsideForms.applicantAddress = item.datas[0];
              break;
            //无效理由
            case 'content':
              this.queryNotivceAsideForms.content = item.datas[0];
              break;
            //无效原因
            case 'reason':
              this.queryNotivceAsideForms.reason = item.datas[0];
              break;
            //类别
            case 'intcls':
              this.queryNotivceAsideForms.niceList = item.values.join(';');
              break;
            //申请号
            case 'appno':
              this.queryNotivceAsideForms.appno = item.datas[0];
              break;
            //申请人名称
            case 'applicant':
              this.queryNotivceAsideForms.applicant = item.datas;
              break;
            //发文编号
            case 'docNo':
              this.queryNotivceAsideForms.docNo = item.datas[0];
              break;
            //公告类型
            case 'item':
              this.queryNotivceAsideForms.item = item.datas[0];
              break;
            //申请人名称（中文）
            case 'licensor':
              this.queryNotivceAsideForms.licensor = item.datas[0];
              break;
            //申请人名称（英文）
            case 'licensee':
              this.queryNotivceAsideForms.licensee = item.datas[0];
              break;
            //商品服务
            case 'showTmdetail':
              this.queryNotivceAsideForms.tmDetail = item.datas;
              break;
            //商标状态
            case 'tmstatus':
              this.queryNotivceAsideForms.tmstatuscode = this.tmstatusArray(item.values, item);
              break;
            //类似群组
            case 'similargroup':
              this.queryNotivceAsideForms.similargroup = item.datas;
              break;
            //代理机构
            case 'tmagent':
              this.queryNotivceAsideForms.agent = item.datas;
              break;
            //注册号
            case 'regno':
              this.queryNotivceAsideForms.regno = item.datas;
              break;
            //国家/地区
            case 'countryZhName':
              this.queryNotivceAsideForms.country = this.countryArray(item.values);
              break;
            //商标名称
            case 'tmName':
              this.queryNotivceAsideForms.tmName = item.datas[0];
              break;
            //代理机构
            case 'tmAgent':
              this.queryNotivceAsideForms.tmAgent = item.datas[0];
              break;
            //原发文日
            case 'docDate':
              this.queryNotivceAsideForms.docBeginDate = item.datas[0][0];
              this.queryNotivceAsideForms.docEndDate = item.datas[0][1];
              break;
            //收件人名称
            case 'recipientName':
              this.queryNotivceAsideForms.recipientName = item.datas[0];
              break;
            //发文类型
            case 'doctype':
              this.queryNotivceAsideForms.docType = item.datas[0];
              break;
            //转让次数
            case 'assignCount':
              this.queryNotivceAsideForms.assignCount = item.datas[0];
              break;
            //转让人
            case 'transferor':
              this.queryNotivceAsideForms.transferor = item.datas[0];
              break;
            //受让人
            case 'assign':
              this.queryNotivceAsideForms.assign = item.datas[0];
              break;
            default:
              break;
          }
        })
        this.onSubmit()
      },
      filterChange() {
        this.isBigData = false
        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()
        let getSortColumns = this.$refs.xGrid.getSortColumns()
        this.asideForms = {}
        this.orderConditions = {}
        this.flag = 1
        if (getSortColumns.length > 0) {
          switch (getSortColumns[0].field) {
            /** 尼期分类 1：倒序  2: 正序*/
            case 'intcls':
              getSortColumns[0].order == 'desc' ? this.orderConditions.nice = 1 : this.orderConditions.nice = 2;
              break;
            /** 申请日期 1：倒序  2: 正序 */
            case 'appdate':
              getSortColumns[0].order == 'desc' ? this.orderConditions.appDate = 1 : this.orderConditions.appDate = 2;
              break;
            /** 注册日期 1：倒序  2: 正序*/
            case 'regdate':
              getSortColumns[0].order == 'desc' ? this.orderConditions.regDate = 1 : this.orderConditions.regDate = 2;
              break;
            //ggdate
            case 'ggdate':
              getSortColumns[0].order == 'desc' ? this.orderConditions.ggDate = 1 : this.orderConditions.ggDate = 2;
              break;
            /** 初审公告日期 1：倒序  2: 正序*/
            case 'trialdate':
              getSortColumns[0].order == 'desc' ? this.orderConditions.trialDate = 1 : this.orderConditions.trialDate = 2;
              break;
            default:
              break;
          }
        }
        else {
          this.orderConditions = {}
        }
        getCheckedFilters.map(item => {
          switch (item.property) {

            //公告日期
            case 'trialDate':
              this.asideForms.trialBeginDate = [item.datas[0][0]];
              this.asideForms.trialEndDate = [item.datas[0][1]];
              break;
            //申请日
            case 'appDate':
              this.asideForms.appBeginDate = [item.datas[0][0]];
              this.asideForms.appEndDate = [item.datas[0][1]];
              break;
            //公告期号
            case 'trialNum':
              this.asideForms.trialNum = item.datas;
              break;
            //商品/服务项目
            case 'invalidItem':
              this.asideForms.item = item.datas;
              break;
            //无效理由
            case 'content':
              this.asideForms.content = item.datas;
              break;
            //无效原因
            case 'reason':
              this.asideForms.reason = item.values;
              break;
            //代理机构
            case 'tmAgent':
              this.asideForms.tmAgent = item.datas;
              break;
            //申请人名称
            case 'applicant':
              this.asideForms.applicant = item.datas;
              break;
            //商标名称
            case 'tmName':
              this.asideForms.tmName = item.datas;
              break;
            case 'sbmc':
              this.asideForms.tmName = item.datas;
              break;
            //公告类型
            case 'trialType':
              this.asideForms.trialType = item.values;
              break;
            //类别
            case 'intcls':
              this.asideForms.niceList = item.values;
              break;
            //申请号
            case 'appno':
              this.asideForms.appno = item.datas;
              break;
            //申请人名称（中文）
            case 'tmapplicant':
              this.asideForms.tmApplicant = item.datas;
              break;
            //申请人名称（英文）
            case 'applicantEn':
              this.asideForms.applicant_en = item.datas;
              break;
            //申请人地址（中文）
            case 'tmaddress':
              this.asideForms.tmAddress = item.datas;
              break;
            //申请人地址（英文）
            case 'addressEn':
              this.asideForms.address_en = item.datas;
              break;
            //申请日
            case 'appdate':
              this.asideForms.appBeginDate = [item.datas[0][0]];
              this.asideForms.appEndDate = [item.datas[0][1]];
              break;
            //注册日
            case 'regdate':
              this.asideForms.regBeginDate = [item.datas[0][0]];
              this.asideForms.regEndDate = [item.datas[0][1]];
              break;
            //注册公告日
            case 'ggdate':
              this.asideForms.ggBeginDate = [item.datas[0][0]];
              this.asideForms.ggEndDate = [item.datas[0][1]];
              break;
            //商品服务
            case 'showTmdetail':
              this.asideForms.tmDetail = item.datas;
              break;
            //商标状态
            case 'tmstatus':
              this.asideForms.tmstatuscode = this.tmstatusArray(item.values, item);
              break;
            //类似群组
            case 'similargroup':
              this.asideForms.similargroup = item.datas;
              break;
            //代理机构
            case 'tmagent':
              this.asideForms.agent = item.datas;
              break;
            //注册号
            case 'regno':
              this.asideForms.regno = item.datas;
              break;
            //国家/地区
            case 'countryZhName':
              this.asideForms.country = this.countryArray(item.values);
              break;
            //初审公告日
            case 'trialdate':
              this.asideForms.trialBeginDate = [item.datas[0][0]];
              this.asideForms.trialEndDate = [item.datas[0][1]];
              break;
            default:
              break;
          }
        })

        if(this.switchTypeId == 4) {
          this.pageNoss = 1
          this.searchAdvancedFlag = false
          this.searchAdvanced()
          return;
        }
        if (this.isHistory) {
          this.historicalRetrieval(undefined, true)
          return;
        }
        else {
          this.searchCross(true)
        }
      },
      handlePageChanges({currentPage, pageSize}) {
        this.pageNoss = currentPage
        this.pageSizess = pageSize
        this.$refs.xGrid?.clearCheckboxRow()
        this.$refs.xGrid?.clearCheckboxReserve()
        if(this.value == 8) {
          this.classification()
        }else if(this.switchTypeId == 4) {
          this.searchAdvanced()
        }else if(this.switchTypeId == 5) {
          // this.extensionEvent()
        }
        setTimeout(() => {
          if (window.document.getElementsByClassName('vxe-table--body-wrapper') && window.document.getElementsByClassName('vxe-table--body-wrapper')[0]) {
            window.document.getElementsByClassName('vxe-table--body-wrapper')[0].scrollTop = 0
            $('.vxe-table--body-wrapper').scrollTop(0);
          }
        })
      },
      queryNotivcePageChange({currentPage, pageSize}){
        this.queryNotivcePageNos = currentPage
        this.queryNotivceSizes = pageSize
        this.onSubmit()
      },
      automaticPagination(val) {
        if(val) {
          if(this.lastPage == this.pageNos) {
            clearInterval(this.intervalId);
            // 卸载事件监听器
            window.removeEventListener('scroll', () => {
              const isAtTop = this.chatBox.scrollTop === 0;

              if (isAtTop) {
                console.log('div 已经滚动到顶部');
                // 可以在这里执行一些操作，比如显示提示、加载更多内容等
                this.automaticPagination(false)
              }
            });
            return;
          }
          this.pageNos++;
        }else {
          if(this.pageNos == 1){

            return;
          }
          this.pageNos--;
        }

        if (this.isHistory) {
          this.historicalRetrieval(undefined, true)
          return;
        }
        //筛选
        this.isScreen = true
        this.flag = 1
        //是交叉检索
        this.searchCrossFlag = true
        //检索
        if (this.value == 8) {
          //商品分类
          this.classification()
          return;
        }
        this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if (this.tool.getCookie("auth")) {
        } else {
          this.dialogVisible = true
          return;
        }
        if (this.switchTypeId == 1 && this.searchText.trim()) {
          this.queryModes = "text";
        } else if (this.switchTypeId == 1 && this.base64Url) {
          this.queryModes = 'image'
        } else if (this.value == 7 && this.searchText.trim()) {
          this.queryModes = 'image'
        }
        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }
        if (this.searchText.trim() && !this.base64Url) {
          this.boxShow = false
          this.isRotate = true
          // if (this.queryModes) {
          let regex = new RegExp("；", "g"),
            regexSemi = new RegExp("^[;]+|[;]+$", "g");
          let searchTextArr = this.searchText.replace(regex, ";").replace(regexSemi, "").split(";");
          let isAppNo = new RegExp(/^[gGaA]?[0-9]+[gGaA]?$/);
          if (searchTextArr && searchTextArr.length != 0) {
            for (var i = 0; i < searchTextArr.length; i++) {
              this.getJurisdictionAjax();
              return;
            }
          }
          this.highGradeQuery()
          this.getJurisdictionAjax();
        } else if ((this.switchTypeId == 1 && this.base64Url && !this.searchText.trim()) || (this.value == 7 && this.base64Url && this.searchText.trim())) {
          this.highGradeQuery()
          //图片查询
          this.getJurisdictionAjaxImage();
        } else if (this.value == 7 && this.searchText.trim()) {
          this.highGradeQuery()
          //图片查询
          this.getJurisdictionAjaxImage();
        } else {
          if (this.searchText.trim() && this.queryModes == "image") {
            this.$message(this.$t(`PQ.Tips[0]`));
          } else if (this.base64Url && this.queryModes == "text") {
            this.$message(this.$t(`PQ.Tips[1]`));
          } else {
            if (this.switchTypeId == 1) this.$message(this.$t(`PQ.Tips[2]`));
            else this.$message(this.$t(`PQ.Tips[1]`));
          }
        }
        setTimeout(() => {
          if (window.document.getElementsByClassName('vxe-table--body-wrapper') && window.document.getElementsByClassName('vxe-table--body-wrapper')[0]) {
            window.document.getElementsByClassName('vxe-table--body-wrapper')[0].scrollTop = 0
            $('.vxe-table--body-wrapper').scrollTop(0);
          }
        })
      },
      handlePageChange({currentPage, pageSize}) {
        this.pageNos = currentPage
        this.pageSizes = pageSize
        if (this.isHistory) {
          this.historicalRetrieval(undefined, true)
          return;
        }
        //筛选
        this.isScreen = true
        this.flag = 1
        //是交叉检索
        this.searchCrossFlag = true
        //检索
        if (this.value == 8) {
          //商品分类
          this.classification()
          return;
        }
        this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if (this.tool.getCookie("auth")) {
        } else {
          this.dialogVisible = true
          return;
        }
        if (this.switchTypeId == 1 && this.searchText.trim()) {
          this.queryModes = "text";
        } else if (this.switchTypeId == 1 && this.base64Url) {
          this.queryModes = 'image'
        } else if (this.value == 7 && this.searchText.trim()) {
          this.queryModes = 'image'
        }
        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }
        if (this.searchText.trim() && !this.base64Url) {
          this.boxShow = false
          this.isRotate = true
          // if (this.queryModes) {
          let regex = new RegExp("；", "g"),
            regexSemi = new RegExp("^[;]+|[;]+$", "g");
          let searchTextArr = this.searchText.replace(regex, ";").replace(regexSemi, "").split(";");
          let isAppNo = new RegExp(/^[gGaA]?[0-9]+[gGaA]?$/);
          if (searchTextArr && searchTextArr.length != 0) {
            for (var i = 0; i < searchTextArr.length; i++) {
              this.getJurisdictionAjax();
              return;
            }
          }
          this.highGradeQuery()
          this.getJurisdictionAjax();
        } else if ((this.switchTypeId == 1 && this.base64Url && !this.searchText.trim()) || (this.value == 7 && this.base64Url && this.searchText.trim())) {
          this.highGradeQuery()
          //图片查询
          this.getJurisdictionAjaxImage();
        } else if (this.value == 7 && this.searchText.trim()) {
          this.highGradeQuery()
          //图片查询
          this.getJurisdictionAjaxImage();
        } else {
          if (this.searchText.trim() && this.queryModes == "image") {
            this.$message(this.$t(`PQ.Tips[0]`));
          } else if (this.base64Url && this.queryModes == "text") {
            this.$message(this.$t(`PQ.Tips[1]`));
          } else {
            if (this.switchTypeId == 1) this.$message(this.$t(`PQ.Tips[2]`));
            else this.$message(this.$t(`PQ.Tips[1]`));
          }
        }
        setTimeout(() => {
          if (window.document.getElementsByClassName('vxe-table--body-wrapper') && window.document.getElementsByClassName('vxe-table--body-wrapper')[0]) {
            window.document.getElementsByClassName('vxe-table--body-wrapper')[0].scrollTop = 0
            $('.vxe-table--body-wrapper').scrollTop(0);
          }
        })
      },
      //统计数据
      statistical(obj) {
        //备份查询条件
        this.backups = obj
        if (!this.isBigData) return;

        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters().map((item) => {
          return {
            property: item.property,
            values: item.values
          }
        })
        let that = this
        this.$axios.post('/common/query/groupStatistics', {...obj})
          .then((res) => {
            if (res.status == 200) {
              let indexArr = this.gridOptions.columns.map(item => item.field)
              let {data} = res
              data.tmstatuscodeList = data.tmstatuscodeList?.map((item) => {
                item.value = Number(item.value)
                return item
              })
              let arr = data.tmstatuscodeList?.map(item => item.name)
              if (arr.indexOf('2') != -1 && arr.indexOf('9') != -1) {
                arr = data.tmstatuscodeList?.map(item => item.name)
                let a = data.tmstatuscodeList.filter(item => item.name == '9')[0]
                data.tmstatuscodeList = data.tmstatuscodeList?.map(item => {
                  if (item.name == '2') {
                    item.value += a.value
                  }
                  return item
                })

                data.tmstatuscodeList.splice(arr.indexOf('9'), 1)
              }
              if (arr.indexOf('3') != -1 && arr.indexOf('11') != -1) {
                arr = data.tmstatuscodeList?.map(item => item.name)
                let b = data.tmstatuscodeList.filter(item => item.name == '11')[0]
                data.tmstatuscodeList = data.tmstatuscodeList?.map(item => {
                  if (item.name == '3') {
                    item.value += b.value
                  }
                  return item
                })

                data.tmstatuscodeList.splice(arr.indexOf('11'), 1)
              }
              if (arr.indexOf('1') != -1 && arr.indexOf('10') != -1) {
                arr = data.tmstatuscodeList?.map(item => item.name)
                let c = data.tmstatuscodeList.filter(item => item.name == '10')[0]
                data.tmstatuscodeList = data.tmstatuscodeList?.map(item => {
                  if (item.name == '1') {
                    item.value += c.value
                  }
                  return item
                })

                data.tmstatuscodeList.splice(arr.indexOf('10'), 1)
              }
              const $table = this.$refs.xGrid || undefined
              data.intclsList.map(function (item) {
                return {
                  "label": `${item.name}(${item.value})`,
                  "value": item.name
                }
              })
              this.filterDate = data

              if (!this.isScreen) {
                if ($table?.getColumnByField('intcls')) {
                  if ($table) {
                    $table.setFilter($table?.getColumnByField('intcls'), this.gridOptions.columns[indexArr.indexOf('intcls')].filterRender.options = data.intclsList ? data.intclsList.map(function (item) {
                      return {
                        "label": `${item.name}(${item.value})`,
                        "value": item.name,
                        checked: getCheckedFilters.filter(item => item.property == 'intcls').length > 0 && getCheckedFilters.filter(item => item.property == 'intcls')[0].values.includes(item.name)
                      }
                    }) : [])
                  }
                  // if(getCheckedFilters.includes('intcls'))
                  // else this.gridOptions.columns[3].filters[0].checked = false
                }
                if (this.allType.records && this.allType.records.length > 0 && this.allType.records.filter(item => item.tmstatus == '空').length > 0) {
                  if ($table?.getColumnByField('tmstatus')) {
                    if ($table) {
                      this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options = data.tmstatuscodeList ? data.tmstatuscodeList.map((item) => {
                        return {
                          "label": statusObj[item.name],
                          "value": statusObj[item.name],
                          checked: getCheckedFilters.filter(item => item.property == 'tmstatus').length > 0 && getCheckedFilters.filter(item => item.property == 'tmstatus')[0].values.includes(item.name)
                        }
                      }) : []
                      this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options.push({
                        "label": '空',
                        "value": '空'
                      })

                      $table.setFilter($table?.getColumnByField('tmstatus'), this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options)
                    }
                  }
                  // if(getCheckedFilters.includes('tmstatus'))  this.gridOptions.columns[10].filters[0].checked = true
                  // else this.gridOptions.columns[10].filters[0].checked = false
                } else {
                  if ($table?.getColumnByField('tmstatus')) {
                    if ($table) {
                      $table.setFilter($table?.getColumnByField('tmstatus'), this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options = data.tmstatuscodeList ? data.tmstatuscodeList.map((item) => {
                        return {
                          "label": statusObj[item.name],
                          "value": statusObj[item.name],
                          checked: getCheckedFilters.filter(item => item.property == 'tmstatus').length > 0 && getCheckedFilters.filter(item => item.property == 'tmstatus')[0].values.includes(item.name)
                        }
                      }) : [])
                    }
                  }
                }
                if ($table) {
                  if(this.switchTypeId == 5){
                  }else {
                    $table.setFilter($table?.getColumnByField('countryZhName'), this.gridOptions.columns[indexArr.indexOf('countryZhName')].filterRender.options = this.filterDate.countryList ? this.filterDate.countryList.map(function (item) {
                      return {
                        "label": `${that.getCountryName(item.name)}(${item.value})`,
                        "value": that.getCountryName(item.name),
                        checked: getCheckedFilters.filter(item => item.property == 'countryZhName').length > 0 && getCheckedFilters.filter(item => item.property == 'countryZhName')[0].values.includes(item.name)
                      }
                    }) : [])
                  }
                }
              }

              //商标大数据
              if (data.tmstatuscodeList && data.tmstatuscodeList.length) {
                data.tmstatuscodeList?.map(item => {

                })
                data.tmstatuscodeList = data.tmstatuscodeList && data.tmstatuscodeList?.map(item => {
                  return {
                    ...item,
                    statusName: statusObj[item.name]
                  }
                })
              }
              //商标状态分布
              let tmstatuscodeList = [
                {
                  name: '无效',
                  value: 0
                },
                {
                  name: '审查中',
                  value: 0
                },
                {
                  name: '已注册',
                  value: 0
                },
                {
                  name: '其他',
                  value: 0
                }
              ]

              data.tmstatuscodeList && data.tmstatuscodeList?.map(item => {
                if (item.statusName && item.statusName.indexOf('审查中') != -1) {
                  tmstatuscodeList[1].value += Number(item.value)
                }
                else if (item.statusName && item.statusName == '无效') {
                  tmstatuscodeList[0].value += Number(item.value)
                }
                else if (item.statusName && item.statusName == '已注册') {
                  tmstatuscodeList[2].value += Number(item.value)
                }
                else {
                  if (item.statusName) tmstatuscodeList[3].value += Number(item.value)
                }
                return item;
              })
              this.pieDatas = tmstatuscodeList

              //类别分类
              let intclsList = [
                {
                  name: '无效',
                  value: 0
                },
                {
                  name: '审查中',
                  value: 0
                },
                {
                  name: '已注册',
                  value: 0
                },
                {
                  name: '其他',
                  value: 0
                }
              ]
              data.intclsList.sort((a, b) => {
                return Number(b.value) - Number(a.value)
              })
                .map((item, index) => {
                  if (index <= 2) {
                    intclsList[index].name = item.name + '类'
                    intclsList[index].value = item.value
                  }
                  else {
                    intclsList[3].value += Number(item.value)
                  }
                })

              this.pieData = intclsList

              //申请日柱图 asideForms.appYear
              this.trendsData.legendYear = data.appDateList.map(item => item.name).slice(-11)
              this.trendsData.applications = data.appDateList.map(item => item.value).slice(-11)

              if (this.ifSearchInputAutoComplate) {
                if (this.value == 3) {
                  this.reportType = 0
                  this.queryKeyword = this.searchText

                  this.$axios.post('/common/query/applicant/analysis', {applicant: this.queryKeyword}).then(response => {
                    if (response.data.appCount != null && response.data.appCount != 0) {
                      this.ifShowAnalysisIcon = true
                      if (this.queryKeyword != this.analysisLastQueryKeyword) {
                        this.ifShowAnalysisReport = true
                        this.analysisLastQueryKeyword = this.queryKeyword
                      } else {
                        this.ifShowAnalysisReport = false
                        this.analysisLastQueryKeyword = this.queryKeyword
                      }
                      this.myQueryData = response.data
                    }

                    // 根据情况判断 是因为无数据导致无分析报告 还是因为数据过新尚未统计导致无分析报告
                    this.handleAnalysisMessage(response.data, data)
                  })
                }
                else if (this.value == 6) {
                  this.reportType = 1
                  this.queryKeyword = this.searchText

                  this.$axios.post('/common/query/agent/analysis', {agent: this.queryKeyword}).then(response => {
                    if (response.data.appCount != null && response.data.appCount != 0) {

                      this.ifShowAnalysisIcon = true
                      if (this.queryKeyword != this.analysisLastQueryKeyword) {
                        this.ifShowAnalysisReport = true
                        this.analysisLastQueryKeyword = this.queryKeyword
                      } else {
                        this.ifShowAnalysisReport = false
                        this.analysisLastQueryKeyword = this.queryKeyword
                      }
                      this.myQueryData = response.data
                    }
                    // 根据情况判断 是因为无数据导致无分析报告 还是因为数据过新尚未统计导致无分析报告
                    this.handleAnalysisMessage(response.data, data)
                  })
                }
              }
            }
          })
      },
      handleAnalysisMessage(analysisData, statisticData){
        if(analysisData.appCount == null){
          let nowYear = new Date().getFullYear()
          let nowYearHasData = false
          let otherYearHasData = false

          statisticData.appDateList.forEach(item=>{
            if(item.name == nowYear && item.value != 0){
              nowYearHasData = true
            }
            else{
              if (item.value != 0){
                otherYearHasData = true
              }
            }
          })

          if(nowYearHasData && !otherYearHasData){
            //今年有数据而往年没数据 说明数据尚未更新 进行提示
            this.$message("数据更新中，暂无分析报告")
          }
        }
      },
      //历史搜索
      historicalRetrieval(row, value) {
        //是否是历史搜索
        this.isHistory = value;
        if (row && this.isHistory) {
          this.historicalSearchData = row
          this.pageNos = 1;
          //Historical conditions
          this.historicalConditions = row
          this.$refs.xGrid.clearFilter()
          this.$refs.xGrid.clearSort()
          this.$refs.xGrid?.clearCheckboxRow()
          this.$refs.xGrid?.clearCheckboxReserve()
        }
        else {
          this.historicalConditions.patentSearchContents.secondQuery = {...this.historicalConditions.patentSearchContents.secondQuery, ...this.isobject({...this.conditions})}
          this.historicalConditions.patentSearchContents = {
            ...this.historicalConditions.patentSearchContents,
            current: this.pageNos,
            size: this.pageSizes,
            order: this.flag ? this.orderCond : undefined,
          }
        }

        let arr = this.historicalConditions.patentSearchContents.country ? this.historicalConditions.patentSearchContents.country.split(';') : []
        if (arr.length == 1 && arr.includes('CN')) {
          this.$refs.xGrid.hideColumn('applicantEn')
          this.$refs.xGrid.hideColumn('addressEn')
          this.$refs.xGrid.showColumn('tmapplicant')
          this.$refs.xGrid.showColumn('tmaddress')
        }
        else if (arr.length == 1 && !arr.includes('CN')) {
          this.$refs.xGrid.hideColumn('tmapplicant')
          this.$refs.xGrid.hideColumn('tmaddress')
          this.$refs.xGrid.showColumn('applicantEn')
          this.$refs.xGrid.showColumn('addressEn')
        }
        else if (arr.length > 1 && arr.includes('CN')) {
          this.$refs.xGrid.showColumn('tmapplicant')
          this.$refs.xGrid.showColumn('tmaddress')
          this.$refs.xGrid.showColumn('applicantEn')
          this.$refs.xGrid.showColumn('addressEn')
        }
        else if (arr.length > 1 && !arr.includes('CN')) {
          this.$refs.xGrid.hideColumn('tmapplicant')
          this.$refs.xGrid.hideColumn('tmaddress')
          this.$refs.xGrid.showColumn('applicantEn')
          this.$refs.xGrid.showColumn('addressEn')
        }

        if (!this.historicalConditions.patentSearchContents.base64File) {
          this.queryModes = "text";
          this.selectedCountries = this.stateChange.split(';').map(item => {
            return {
              name: item,
              countryName: this.code_country(item),
              value: 0
            }
          })
          if (this.historicalConditions.patentSearchContents.appNo) {
            delete this.historicalConditions.patentSearchContents.keywork
          }
          this.selectData = []
          this.$axios.post('/common/query/search/v2', {
            ...this.historicalConditions.patentSearchContents
          }).then(({data}) => {
            // this.$nuxt.$loading.finish()
            if (data.code != 0 && data.code != 4) {
              //VIP用户
              if(this.isVip != 'COMMON'){
                this.$confirm('您的使用次数不足，为完成本次查询，请购买次数或更换查询条件。', this.$t(`patentSearch.tips`), {
                  confirmButtonText: '直接购买',
                  cancelButtonText: '暂不购买',
                  center: true,
                  customClass: "time_confirm",
                  closeOnClickModal: false
                }).then(() => {
                  this.$router.push({
                    path: "/services",
                    query: {
                      serverId: 27
                    }
                  })
                })
              }else {
                //普通用户
                this.$axios
                  .get("/product/servicemanage/getTrialCount").then(({data}) => {
                  if(data.code == 0){
                    this.$confirm(`您的第${data.data.trialCount}次试用服务已到期`, this.$t(`patentSearch.tips`), {
                      confirmButtonText: '直接购买',
                      cancelButtonText: `继续试用${data.data.totalTrialCount - data.data.trialCount <= 0 ? 0 : data.data.totalTrialCount - data.data.trialCount}`,
                      center: true,
                      customClass: "time_confirm",
                      closeOnClickModal: false,
                      distinguishCancelAndClose: true
                    }).then(() => {
                      this.$router.push({
                        path: "/services",
                        query: {
                          serverId: 27
                        }
                      })
                    }).catch((action) => {

                      if(action === 'cancel'){
                        if(data.data.totalTrialCount - data.data.trialCount <= 0){
                          this.$message({
                            message: '您的试用服务已结束',
                            type: 'warning'
                          });
                        }else {
                          this.applicaEvent()
                        }
                      }
                    });
                  }
                })
              }

            } else if (data.code == 4) {
              // this.dialogVisiblePrompt = true
              this.$message.error('系统错误');
            } else {
              this.condition = this.searchText
              this.allResult = data.data
              this.statistical({...this.historicalConditions.patentSearchContents})
              this.searchHandle(this.getClone(data.data));
            }
          })
        }
        else {
          this.historicalConditions.patentSearchContents.base64File = this.historicalConditions.searchContents
          this.selectedCountries = this.stateChange && this.stateChange.split(';').map(item => {
            return {
              name: item,
              countryName: this.code_country(item),
              value: 0
            }
          })
          this.highGradeQuery()
          //   this.$nuxt.$loading.start()
          this.queryModes = "image";
          this.value = 4;
          this.$axios.post('/common/graph/search/v2', {
            ...this.historicalConditions.patentSearchContents
          }).then(({code, data, msg}) => {
            // this.$nuxt.$loading.finish()
            if (data.code != 0 && data.code != 4) {
              //VIP用户
              if(this.isVip != 'COMMON'){
                this.$confirm('您的使用次数不足，为完成本次查询，请购买次数或更换查询条件。', this.$t(`patentSearch.tips`), {
                  confirmButtonText: '直接购买',
                  cancelButtonText: '暂不购买',
                  center: true,
                  customClass: "time_confirm",
                  closeOnClickModal: false
                }).then(() => {
                  this.$router.push({
                    path: "/services",
                    query: {
                      serverId: 27
                    }
                  })
                })
              }else {
                //普通用户
                this.$axios
                  .get("/product/servicemanage/getTrialCount").then(({data}) => {
                  if(data.code == 0){
                    this.$confirm(`您的第${data.data.trialCount}次试用服务已到期`, this.$t(`patentSearch.tips`), {
                      confirmButtonText: '直接购买',
                      cancelButtonText: `继续试用${data.data.totalTrialCount - data.data.trialCount <= 0 ? 0 : data.data.totalTrialCount - data.data.trialCount}`,
                      center: true,
                      customClass: "time_confirm",
                      closeOnClickModal: false,
                      distinguishCancelAndClose: true
                    }).then(() => {
                      this.$router.push({
                        path: "/services",
                        query: {
                          serverId: 27
                        }
                      })
                    }).catch((action) => {

                      if(action === 'cancel'){
                        if(data.data.totalTrialCount - data.data.trialCount <= 0){
                          this.$message({
                            message: '您的试用服务已结束',
                            type: 'warning'
                          });
                        }else {
                          this.applicaEvent()
                        }
                      }
                    });
                  }
                })
              }

            } else if (data.code == 4) {
              // this.dialogVisiblePrompt = true
              this.$message.error('系统错误');
            } else {
              this.condition = this.base64Url
              this.allResult = data.data
              this.statistical({...this.historicalConditions.patentSearchContents})
              this.searchHandle(this.getClone(data.data));
            }
          })
        }
      }
      ,
      getExportId() {
        let arr = {}
        let arrs = []
        let item = []
        //获取国家
        let countryArr = this.unique(this.selectData)
        if (this.selectData.map(item => item.id).length > 0) {
          // for (let i = 0; i < countryArr.length; i++) {
          //   arr = {
          //     "country": countryArr[i],
          //     "similarityList": []
          //   }
          //   for (let j = 0; j < this.selectData.length; j++) {
          //     if (this.selectData[j].country == countryArr[i])
          //       item.push(
          //         {
          //           id: (this.selectData[j].id).toString(),
          //           similarity: this.selectData[j].level
          //         }
          //       )
          //   }
          //   arr.similarityList = item
          //   item = []
          //   arrs.push(arr)
          // }
          return countryArr;
        } else {
          return []
        }
      },
      //数组去重
      //数组去重
      unique(arr) {
        let newArr = []
        let obj = {}
        arr.forEach((item, index) => {
          if(item.country != (arr[index-1] && arr[index-1].country)) {
            if(index > 0){
              newArr.push(obj)
            }
            obj = {
              "country": item.country,
              "similarityList": [
                {
                  id: item.id.toString(),
                  similarity: item.level
                }
              ]
            }
            if(index ==( arr.length - 1)) newArr.push(obj)
          }else {
            obj.similarityList.push(
              {
                id: item.id.toString(),
                similarity: item.level
              })
            if(index ==( arr.length - 1)) newArr.push(obj)
          }
        });
        return newArr
      },
      extensionExexports(item) {
        let reportParam = {
          current:this.continuationCluesCurrent,
          size:this.continuationCluesSize,
          province:	this.formInline.city,
          ggBeginDate: this.ggDate_[0],
          ggEndDate: this.ggDate_[1],
          validBeginDate: this.validDate_[0],
          validEndDate: this.validDate_[1],
          city:	 (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  undefined : this.formInline.region,
          district:	  (this.formInline.city == '北京' || this.formInline.city == '天津' || this.formInline.city == '上海' || this.formInline.city == '重庆' ) ?  this.formInline.region : undefined,
          ...this.continuationAsideForms
        }

        const myDate = new Date();
        const Y = myDate.getFullYear();
        const M = (myDate.getMonth() < 9) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
        const D = (myDate.getDate() < 10) ? "0" + myDate.getDate() : myDate.getDate();
        let date = Y + '-' + M + '-' + D;

        let url = '/common/query/renewExcelDownload'
        this.$axios.post(url, reportParam, {responseType: "arraybuffer"}, {timeout: 60 * 1000 * 25}).then((res) => { // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content]);
          let fileName = ''
          fileName = '续展线索结果-' + date + '.xls';

          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      //xlse导出
      Exexports(item) {
        let reportParam = {}
        if (this.$refs.xGrid.getCheckboxRecords().length > 0) {
          reportParam = {
            exportAll: 0,
            idList: this.$refs.xGrid.getCheckboxRecords().map(item => item.id)
          }
        } else {
          reportParam = {
            tmName: this.selects == 1 ? this.input3 : undefined,
            applicant: this.selects == 2 ? this.input3 : undefined,
            tmAgent: this.selects == 3 ? this.input3 : undefined,
            trialType:	this.radio1 ? this.radio1 : undefined,
            reason:	this.radio3.length > 0 ? this.radio3 : undefined,
            trialNum: this.input4 ? this.input4 + '期' : this.radio2 ? this.radio2 : undefined,
            exportAll: 1
          }
        }

        const myDate = new Date();
        const Y = myDate.getFullYear();
        const M = (myDate.getMonth() < 9) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
        const D = (myDate.getDate() < 10) ? "0" + myDate.getDate() : myDate.getDate();
        let date = Y + '-' + M + '-' + D;

        let url = '/common/query/invalidNoticeExcelDownload'
        this.$axios.post(url, reportParam, {responseType: "arraybuffer"}, {timeout: 60 * 1000 * 25}).then((res) => { // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content]);
          let fileName = ''
          fileName = '商标公告结果-' + date + '.xls';

          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      leadExexport(){
        const myDate = new Date();
        const Y = myDate.getFullYear();
        const M = (myDate.getMonth() < 9) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
        const D = (myDate.getDate() < 10) ? "0" + myDate.getDate() : myDate.getDate();
        let date = Y + '-' + M + '-' + D;

        let url =  '/common/query/noticeExcelDownload'
        this.$axios.post(url, {
          trialNum:	this.formInline.num,
          trialType: this.formInline.type,
          ...this.queryNotivceAsideForms
        }, {responseType: "arraybuffer"}, {timeout: 60 * 1000 * 25}).then((res) => { // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content]);
          let fileName = ''
          fileName = '公告查询结果-' + date + '.xls';

          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      //表格导出
      Exexport(item) {
        let reportParam = {}
        if (this.getExportId().length > 0) {
          reportParam = {
            idsList: this.getExportId()
          }
        } else {
          if (this.queryModes == "text") {
            reportParam = {
              allTextExceport: 1,
              ...this.backups
            }
          } else {
            reportParam = {
              allImageExceport: 1,
              ...this.backups
            }
          }
          if (this.total > 2000) {
            this.$message({
              message: '您的导出数量已超过2000条，请分批导出',
              type: 'warning'
            });
          }
        }

        const myDate = new Date();
        const Y = myDate.getFullYear();
        const M = (myDate.getMonth() < 9) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
        const D = (myDate.getDate() < 10) ? "0" + myDate.getDate() : myDate.getDate();
        let date = Y + '-' + M + '-' + D;

        let url = item ? '/common/query/wordExportForLantern' : '/common/query/excelExport'
        this.$axios.post(url, reportParam, {  responseType: "arraybuffer",
          timeout: 60 * 1000 * 2500 }).then((res) => { // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content]);
          let fileName = ''
          if (item == 1) {
            fileName = '商标查询结果-' + date + '.doc';
          } else {
            fileName = '商标查询结果-' + date + '.xls';
          }

          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      //查询方式选择
      tabTypeSwitch(id) {
        if(this.tool.getCookie("auth")){
          this.iStrademarkInquiry = id
          if (id == 1) {
            this.$router.push('/instrument')
          } else if (id == 2) {
            this.$router.push('/TM')
          } else if (id == 3){
            this.$router.push('/contrast')
          } else if (id == 4){
            this.$router.push('/trademarkRejectionAI')
          }

        }else {
          this.dialogVisible = true
        }

      }
      ,
      //类别选择
      async categoryType(item) {
        if(this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN'){
          if(!this.isRetrieval && !this.niceCrossChecked ){
            if(this.searchText || this.base64Url){

              this.categoryTypeTable = []
              this.categoryTypeCrosss(item.name)
            }
          }
          //交叉检索关闭
          if (!this.isRetrieval) {
            if (item.isSimilar) {
              item.isSimilar = !item.isSimilar
              return;
            }
            item.isCheck = !item.isCheck
            if(item.isCheck){
              this.compoundArr.push(item.name)
              this.categoryCross(item.name)
            }else {
              this.compoundArr.splice(this.compoundArr.indexOf(item.name), 1)
              for (let i = 0; i < this.sameMessage.length; i++) {
                if (this.sameMessage[i].nice == item.name) {
                  this.sameMessage.splice(i, 1);
                  i--;
                }
              }
            }
          } else {
            //交叉检索开启
            if (item.isCheck) {
              item.isCheck = !item.isCheck
              this.compoundArr.splice(this.compoundArr.indexOf(item.name), 1)
              for (let i = 0; i < this.sameMessage.length; i++) {
                if (this.sameMessage[i].nice == item.name) {
                  this.sameMessage.splice(i, 1);
                  i--;
                }
              }
            } else if (item.isSimilar) {
              item.isSimilar = !item.isSimilar
              for (let i = 0; i < this.sameMessage.length; i++) {
                if (this.sameMessage[i].crossNice == item.name) {
                  this.sameMessage.splice(i, 1);
                  i--;
                }
              }
            } else {
              item.isCheck = !item.isCheck
              this.categoryCross(item.name)
            }
          }
        }else{
          if (!this.isRetrieval) {
            if (item.isSimilar) {
              item.isSimilar = !item.isSimilar
              return;
            }
            item.isCheck = !item.isCheck
          } else {
            if (item.isCheck) {
              item.isCheck = !item.isCheck
              for (let i = 0; i < this.sameMessage.length; i++) {
                if (this.sameMessage[i].nice == item.name) {
                  this.sameMessage.splice(i, 1);
                  i--;
                }
              }
            } else if (item.isSimilar) {
              item.isSimilar = !item.isSimilar
              for (let i = 0; i < this.sameMessage.length; i++) {
                if (this.sameMessage[i].crossNice == item.name) {
                  this.sameMessage.splice(i, 1);
                  i--;
                }
              }
            } else {
              item.isCheck = !item.isCheck
              this.categoryCrosss(item.name)
            }
          }
        }

      }
      ,
      //点击跳转历史记录
      historyJump() {
        this.$router.push('/personalCenter/trademarkLog')
      }
      ,
      //根据国家code获取国家名称
      getCountryName(code) {
        let arr = []
        let codes = code.split(';')
        for (let i = 0; i < codes.length; i++) {
          arr.push(this.allCountry.filter(item => item.code == codes[i])[0].city)
        }
        return arr.join(';')
      }
      ,
      //获取查询日志记录
      async queryAjax() {
        if (this.tool.getCookie("auth")) {
        } else {
          return;
        }
        let data = await this.$axios.get("/lantern/lanlog/page", {
          params: {
            productType: "商标",
            current: 1,
            size: 10,
            startTime: '',
            endTime: '',
            productName: ''
          }
        });
        if (data.data.code == 0) {
          this.loggingData = data.data.data.records;
          this.loggingData = this.loggingData.map(item => {
            // item.patentSearchContents = item.patentSearchContents.replace(/\"\"/g,"\",\"")
            item.patentSearchContents = JSON.parse(item.patentSearchContents)
            item.countryName = this.getCountryName(item.patentSearchContents.country)
            if (item.patentSearchContents.agent) {
              item.searchType == '代理机构'
              item.searchContents = item.patentSearchContents.agent
            }
            delete item.patentSearchContents.mchId
            delete item.patentSearchContents.sign
            return item
          })
        }
      }
      ,
      toggleMethod({expanded, column, columnIndex, row, rowIndex}) {
        if (expanded == true) this.$refs.xGrid.clearRowExpandLoaded(row)
        return true
      }
      ,
      loadMethod({row}) {
        // 模拟后台接口
        return new Promise(resolve => {
          this.$axios.post("/common/query/searchByIds", {
            country: row.country,
            keywork: row.id,
            lang: "zh"
          }).then((res) => {
            if (row.country == 'CN') {
              this.mineInfor = res.data.trademark_list[0];
              //处理商标三撤
              {
                if (this.mineInfor.ggdate) {
                  let arr = this.mineInfor.ggdate.split('-')
                  arr[0] = Number(arr[0]) + 3
                  this.mineInfor.fromThree = arr.join('-')
                }
              }
              //处理类别
              {
                if (this.mineInfor.country == 'CN' && this.mineInfor.tmdetail_en) {
                  let str = "";
                  let arr = this.mineInfor.tmdetail_en ? this.mineInfor.tmdetail_en.split(";") : [];
                  arr.map((item) => {
                    if (item) str += item + ";&nbsp;&nbsp;&nbsp;&nbsp";
                  });
                  this.mineInfor.dandan = str;
                } else {
                  let str = "";
                  let arr = this.mineInfor.tmdetail_en ? JSON.parse(this.mineInfor.tmdetail_en) : [];
                  arr.map((item) => {
                    if (item) str += item + ";&nbsp;&nbsp;&nbsp;&nbsp";
                  });
                  this.mineInfor.dandan = str;
                }
              }
              {
                if (this.mineInfor.tmdetail) {
                  let str = "";
                  let arr = this.mineInfor.tmdetail.split(";");
                  arr.map(item => {
                    if (item) str += item;
                  });
                  this.mineInfor.dandan_cn = str;
                }
              }
              {
                if (this.mineInfor.tmdetail_en) {
                  let str = "";
                  let arr = this.mineInfor.tmdetail_en.split(";");
                  arr.map(item => {
                    if (item) str += item + ";";
                  });
                  this.mineInfor.dandan_en = str;
                }
              }
              if (this.mineInfor.tmFlowInfos.length != 0) {
                this.flowData = this.mineInfor.tmFlowInfos;
                this.flowData.map(item => {
                  let arr = item.flowname.replace(
                    /[\-\_\,\———\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g,
                    "&"
                  );
                  let arrLength = arr.split("&").length;
                  item.shui = arr.split("&")[0];
                  item.shuiOne = arr.split("&")[arrLength - 1];
                });
              }

              if (this.$route.query.toprogress == 1) {
                this.$nextTick(() => {
                  $(document).scrollTop($(".tradeMarkProgre").offset().top);
                });
              }
              resolve()
            } else {
              this.mineInfor = res.data.trademark_list[0];
              this.webClassService = this.mineInfor.tmdetail || this.mineInfor.tmdetail_en || [];
              if (this.webClassService.length) {
                this.webClassService = JSON.parse(this.webClassService);
                for (var i = 0; i < this.webClassService.length; i++) {
                  for (var key in this.webClassService[i]) {
                    if (parseInt(key)) {
                      this.webClassService[i].niceCode = key;
                      this.webClassService[i].value = this.webClassService[i][key];
                    } else {
                      this.webClassService[i].niceCode = this.webClassService[i].niceCode;
                      this.webClassService[i].value = this.webClassService[i].value;
                    }
                    break;
                  }
                }
              }
              this.webClassService.map((item, index) => {
                if (item.niceCode === "00") {
                  this.webClassService.splice(index, 1)
                }
              })
              let CanadaWebServiceObj = {};
              CanadaWebServiceObj.niceCode = this.webClassService.map(item => item.niceCode).join(';');
              CanadaWebServiceObj.value = this.webClassService.map(item => item.value).join(';');
              this.CanadaWebService.push(CanadaWebServiceObj);
              this.flowData = res.data.trademark_list[0].tmFlowInfos;
              if (this.infor.country == 'WO') {
                let tmFlowInfos_filter = this.flowData.reduce((r, c) => {
                  let buff = r.find(i => i.authorityCode === c.authorityCode);
                  if (buff) {
                    return r.map(j => {
                      if (j.authorityCode === buff.authorityCode) {
                        j.progress.push({
                          progressDate: c.flowdate,
                          progressEvent: c.flowenname
                        })
                        j.progress = j.progress.sort((a, b) => b.progressDate - a.progressDate)
                      }
                      return j
                    })
                  } else {
                    c.progress = [{
                      progressDate: c.flowdate,
                      progressEvent: c.flowenname
                    }]
                    r.push(c)
                    return r
                  }
                }, [])
                this.flowData = tmFlowInfos_filter.splice(tmFlowInfos_filter.findIndex(i => i.authorityZhName == '中国'), 1).concat(tmFlowInfos_filter.sort((a, b) => {
                  return a.authorityZhName.localeCompare(b.authorityZhName, 'zh-CN')
                }))
              }
              resolve()
            }
          })
        })
      },
      //类别交叉检索
      categoryTypeCrosss(name) {
        this.$axios.post('/common/query/tmCrossSearch', {
          "nice": name,
          "current": 1,
          "size": '9999'
        }).then(({data}) => {
          if (data.code == 0) {
            //类似群组

            let newData = JSON.parse(JSON.stringify(data.data.records))
            let items = {}
            newData = newData.sort((a,b) => {
              return  a.similargroup - b.similargroup
            })
            //交叉数据1--------------------------------------------
            newData.map(item => {
              item.id = (Math.random() * 9 + 1) * 10000000
              items =  JSON.parse(JSON.stringify(item))
              items.color = 1
              items.item1 = items.crossSimilargroup
              items.item2 = items.similargroupName
              // items.item2 = res.data.filter(item => item.similargroup == items.similargroup)[0].similargroupName
              items.item3 = items.similargroup
              items.item4 = items.crossSimilargroupName
              // this.categoryTypeTable.push(items)
            })

            data.data.records = data.data.records.sort((a,b) => {
              return  a.crossSimilargroup - b.crossSimilargroup
            })
            //交叉数据2--------------------------------------------
            data.data.records.map(item => {
              item.id = (Math.random() * 9 + 1) * 10000000
              items=  JSON.parse(JSON.stringify(item))
              items.color = 2
              items.item1 = items.similargroup
              items.item2 = items.crossSimilargroupName
              items.item3 = items.crossSimilargroup
              items.item4 = items.similargroupName
              if(items.item4){
                this.categoryTypeTable.push(items)
              }
            })
            if(this.categoryTypeTable.length > 0){
              this.categoryTypeVisible = true
            }
          }
        })
          .catch(error => {
          });
      },
      //类别交叉检索
      categoryCrosss(name) {
        this.$axios.post('/common/query/tmCrossSearch', {
          "nice": name,
          "current": 1,
          "size": '9999'
        }).then(({data}) => {
          if (data.code == 0) {
            //类似群组
            let crossNice = data.data.records.map(item => item.crossNice)
            this.niceList.map(item => {
              if (crossNice.includes(item.categoryNo) && !item.isCheck && !item.isSimilar) {
                item.isSimilar = true
              }
              return item;
            })
            data.data.records.map(item => {
              this.sameMessage.push(item)
            })
            this.$nextTick(() => {
              data.data.records.map(item => {
                this.$refs.multipleTable.toggleRowSelection(this.sameMessage.find(items => items.id == item.id),true);
              })
            })

          }
        })
          .catch(error => {
          });
      },
      //类别交叉检索---------------------------交叉检索关闭
      categoryCross(name) {
        let index = this.sameMessage.map((item) => item.color).indexOf(2);
        this.$axios.post('/common/query/similargroupDD', {
          "nice": name,
          "current": 1,
          "size": '9999'
        }).then(({data}) => {
          let res = data
          res.data = res.data.map((item) => {
            item.id = (Math.random() * 9 + 1) * 10000000
            item.nice = name
            item.similargroup = item.similargroupCode
            item.goodsName = item.similargroupName
            return  item
          })
          let similargroupArr = res.data.map(item => item.similargroup)
          if (res.code == 0) {
            if(!this.isRetrieval){
              //类似群组
              let crossNice = res.data.map(item => item.crossNice)
              let items = {}
              res.data = res.data.sort((a,b) => {
                return  a.similargroup - b.similargroup
              })
              //如果交叉检索开启
              res.data.map(item => {
                items =  JSON.parse(JSON.stringify(item))
                items.color = 3
                items.item1 = ''
                items.item2 = items.goodsName
                items.item3 = items.similargroup
                items.item4 = ''
                if(index == -1){
                  this.sameMessage.push(items)
                }else {
                  this.sameMessage.splice(index++,0,items);
                }
              })
              this.$nextTick(() => {
                res.data.map(item => {
                  this.$refs.multipleTable.toggleRowSelection(this.sameMessage.find(items => items.id == item.id),true);
                })
              })
            }else{
              this.$axios.post('/common/query/tmCrossSearch', {
                "nice": name,
                "current": 1,
                "size": '9999'
              }).then(({data}) => {
                if (data.code == 0) {
                  let similargroupArr1 = data.data.records.map(item => item.similargroup)
                  let newData = JSON.parse(JSON.stringify(data.data.records))
                  res.data.map(item => {
                    if(!similargroupArr1.includes(item.similargroup)){
                      item.crossSimilargroupName = item.goodsName
                      newData.push(item)
                    }
                  })

                  //类似群组
                  let crossNice = data.data.records.map(item => item.crossNice)
                  let items = {}
                  this.niceList.map(item => {
                    if (crossNice.includes(item.categoryNo) && !item.isCheck && !item.isSimilar) {
                      item.isSimilar = true
                    }
                    return item;
                  })
                  newData = newData.sort((a,b) => {
                    return  a.similargroup - b.similargroup
                  })
                  //交叉数据1--------------------------------------------
                  newData.map(item => {
                    item.id = (Math.random() * 9 + 1) * 10000000
                    items =  JSON.parse(JSON.stringify(item))
                    items.color = 1
                    items.item1 = items.crossSimilargroup
                    // items.item2 = items.similargroupName
                    items.item2 = res.data.filter(item => item.similargroup == items.similargroup)[0].goodsName
                    items.item3 = items.similargroup
                    items.item4 = items.crossSimilargroupName
                    if(index == -1){
                      this.sameMessage.push(items)
                    }else {
                      this.sameMessage.splice(index++,0,items);
                    }
                  })
                  this.$nextTick(() => {
                    newData.map(item => {
                      this.$refs.multipleTable.toggleRowSelection(this.sameMessage.find(items => items.id == item.id),true);
                    })
                  })
                  data.data.records = data.data.records.sort((a,b) => {
                    return  a.crossSimilargroup - b.crossSimilargroup
                  })
                  //交叉数据2--------------------------------------------
                  data.data.records.map(item => {
                    item.id = (Math.random() * 9 + 1) * 10000000
                    items=  JSON.parse(JSON.stringify(item))
                    items.color = 2
                    items.item1 = items.similargroup
                    items.item2 = items.crossSimilargroupName
                    items.item3 = items.crossSimilargroup
                    items.item4 = items.similargroupName
                    if(items.item4){
                      this.sameMessage.push(items)
                    }
                  })
                  this.$nextTick(() => {
                    data.data.records.map(item => {
                      this.$refs.multipleTable.toggleRowSelection(this.sameMessage.find(items => items.id == item.id),true);
                    })
                  })
                }
              })
            }
          }
        })
      }
      ,
      //切换总汇信息
      switchTitle(id) {
        this.collectTitle.map(item => {
          if (item.id == id) item.show = true
          else item.show = false
          return item
        })
      },
      checkColumnMethod({column}) {
        if (['nickname', 'role'].includes(column.property)) {
          return false
        }
        return true
      }
      ,
      isInformation() {
        this.show = !this.show
      },
      //表格拖拽
      meanNum(list, field) {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        return count / list.length
      }
      ,
      sumNum(list, field) {
        let count = 0
        list.forEach(item => {
          count += Number(item[field])
        })
        return count
      }
      ,
      footerMethod({columns, data}) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '平均'
            }
            if (['age', 'sex'].includes(column.property)) {
              return this.$store.state.meanNum(data, column.property)
            }
            return null
          }),
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '和值'
            }
            if (['age', 'sex'].includes(column.property)) {
              return this.sumNum(data, column.property)
            }
            return null
          })
        ]
      }
      ,
      columnDrop2() {
        this.$nextTick(() => {
          const $table = this.$refs.xGrid
          this.sortable2 = Sortable.create($table?.$el.querySelector('.body--wrapper>.vxe-table--header .vxe-header--row'), {
            handle: '.vxe-header--column:not(.col--fixed)',
            onEnd: ({item, newIndex, oldIndex}) => {
              const {fullColumn, tableColumn} = $table.getTableColumn()
              const targetThElem = item
              const wrapperElem = targetThElem.parentNode
              const newColumn = fullColumn[newIndex]
              if (newColumn.fixed) {
                // 错误的移动
                if (newIndex > oldIndex) {
                  wrapperElem.insertBefore(targetThElem, wrapperElem.children[oldIndex])
                } else {
                  wrapperElem.insertBefore(wrapperElem.children[oldIndex], targetThElem)
                }
                return;
              }
              // 转换真实索引
              const oldColumnIndex = $table.getColumnIndex(tableColumn[oldIndex])
              const newColumnIndex = $table.getColumnIndex(tableColumn[newIndex])
              // 移动到目标列
              const currRow = fullColumn.splice(oldColumnIndex, 1)[0]
              fullColumn.splice(newColumnIndex, 0, currRow)
              $table.loadColumn(fullColumn)
            }
          })
        })
      }
      ,
      //
      switchType(id) {
        this.ifShowAnalysisReport = false
        this.ifShowAnalysisIcon = false
        this.myQueryData = {}
        //当前得查询方式
        this.pageNos = 1
        this.switchTypeId = id
        this.tab.map(item => {
          if (item.id === id) return item.show = true
          else return item.show = false
        })
        if (id == 1) {
          this.combinedquery = true
          this.query_Items = this.$t(`PQ.query_Items`)
          this.total = this.allType.total;
        } else if (id == 2) {
          this.show = false
          this.combinedquery = true
          this.query_Items = this.$t(`PQ.query_Itemsd`)
          this.total = this.allType.total;
        } else {
          this.show = false
          this.combinedquery = false
          this.total = this.allType.total;
        }
      }
      ,
      //login
      login() {
        this.$axios
          .get("/auth/oauth/token", {
            params: {
              checkCode: 'n',
              username: this.ruleForm.phone,
              password: this.ruleForm.password,
              code: '',
              token: this.token,
              grant_type: 'password',
              scope: 'server',
              authType: 'image'
            },
            headers: {
              Authorization: "Basic " + this.userSource.token
            }
          }).then((data) => {
          if (data.status == 200) {
            if (data.data.access_token) {
              this.setToken(data.data.access_token)
            }

            this.$emit("loginDialog", false);
            this.getUserInfo();
            this.pageAjax();
            this.$refs["ruleForm"].resetFields();
            this.codeMsg = this.$t("logins.form.sendCodeText");
            this.countdown = 60;
            clearInterval(this.timer);
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.wechatLogin = false;
            this.wechatAuth = false;
            this.unClick = false;
          } else {
            this.$message({
              message: err.response.data.msg
            });
          }
        })
          .catch(err => {
            clearInterval(this.timer);
            this.codeMsg = this.$t("logins.form.resendText");
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.$message({
              message: err.response.data.message
            })
          });
      }
      ,
      //浏览器判断
      getliu() {
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;
        //以下进行测试
        if (Sys.safari) return '36%'
        else return '55%'
      }
      ,
      getqi() {
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;
        //以下进行测试
        if (Sys.safari) return '100%'
        else return '100%'
      }
      ,
      //跳转支持国家页面
      supportcountry() {
        this.$router.push('/supportCountry')
      }
      ,
      //筛选国家
      countrySelect(code, val) {
        if (val == 0) return;
        if (this.choiceCountry && this.choiceCountry.length == 0) {
          this.choiceCountry = [code]
        } else if (this.choiceCountry.includes(code)) {
          this.choiceCountry = []
        } else {
          this.choiceCountry = [code]
        }
      }
      ,
      //获取图片的大小设置
      loadImage(dom) {
        // this.style.height = '10px!important' explicitOriginalTarget
        if ((dom.path && dom.path[0].clientWidth > 166) || dom.explicitOriginalTarget && dom.explicitOriginalTarget.clientWidth > 166) {
          if (dom.explicitOriginalTarget) {
            dom.explicitOriginalTarget.width = '60'
            // dom.explicitOriginalTarget.height = 'auto'
          } else if ((dom.path && dom.path[0].clientHeight > 166) || dom.explicitOriginalTarget && dom.explicitOriginalTarget.clientHeight > 166) {
            if (dom.explicitOriginalTarget) {
              dom.explicitOriginalTarget.height = '30'
              // dom.explicitOriginalTarget.width = 'auto'
            }
          } else {
            if (dom.explicitOriginalTarget) {
              dom.explicitOriginalTarget.width = '60%'
            } else {
              dom.target.style.width = '60%'
            }
          }
        }
      }
      ,
      //根据状态返回对应颜色
      setColor(color) {
        return statueColorObj[color]
      }
      ,
      //删除选中国家
      delCountry(val) {
        let codes = []
        if (this.choiceCountry.includes(val)) this.choiceCountry = []
        if (this.asia.indexOf(val) > -1) {
          this.asia.splice(this.asia.indexOf(val), 1);
        } else if (this.europe.indexOf(val) > -1) {
          this.europe.splice(this.europe.indexOf(val), 1);
        } else if (this.america.indexOf(val) > -1) {
          this.america.splice(this.america.indexOf(val), 1);
        } else if (this.south.indexOf(val) > -1) {
          this.south.splice(this.south.indexOf(val), 1);
        } else if (this.africa.indexOf(val) > -1) {
          this.africa.splice(this.africa.indexOf(val), 1);
        } else if (this.oceania.indexOf(val) > -1) {
          this.oceania.splice(this.oceania.indexOf(val), 1);
        } else if (this.property.indexOf(val) > -1) {
          this.property.splice(this.property.indexOf(val), 1);
        }
        codes = this.selectedCountries.map(item => item.name)
        if (codes.indexOf(val) > -1) {
          this.selectedCountries.splice(codes.indexOf(val), 1);
        }
      }
      ,
      changeInpText(v) {
        if (!v) return
        this.queryDataFn({
          tradeMarkText: v
        })
      }
      ,
      //条件筛选
      conditionalQuery() {
        this.flag = 1;
        // this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if (this.tool.getCookie("auth")) {

        } else {
          this.dialogVisible = true
          return;
        }

        if (this.searchText.trim()) {
          this.queryModes = "text";
        }

        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }

        this.searchFn();
      }
      ,
      changeVal(val, index) {
        this.val = val;
        this.$set(
          this.query_Items,
          this.query_Items[this.query_Items.length - 1]["tmParams"],
          val
        );
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function (item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function (item, i) {
            item["disabled"] = false;
          });
        });
        query_Item1.forEach(function (item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function (item, i) {
            item["disabled"] = false;
          });
        });
        query_Items.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            list.forEach(function (ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        query_Item1.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            list.forEach(function (ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });

        //切换输入框，要清空被切换的存值
        for (var key in this.queryItem) {
          if (list.indexOf(this.queryItem[key].keys) == -1) {
            if ("beginValue" in this.queryItem[key]) {
              this.queryItem[key].beginValue = '';
              this.queryItem[key].endValue = '';
            } else {
              this.queryItem[key].value = "";
            }
          }
        }
        //是否商标状态要默认否
        if (val == 'SFGYSB') {
          this.queryItem.istotal.value = "no";
        }
        //是否商有效要默认有效
        if (val == 'SFYX') {
          this.queryItem.active.value = "1";
        }
        //图片清空
        if (list.indexOf('SBTP') == -1) {
          this.imgUplad = "";
          this.base64Url = ''
        }
      }
      ,
      //固定输入框切换时触发
      changeVal1(val) {
        this.val = val;
        if (this.val != 'SBMC') {
          this.queryMode.choosed = "term";
        }
        this.$set(
          this.query_Item1,
          this.query_Item1[this.query_Item1.length - 1]["tmParams"],
          val
        );
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function (item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function (item, i) {
            item["disabled"] = false;
          });
        });
        query_Item1.forEach(function (item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function (item, i) {
            item["disabled"] = false;
          });
        });
        query_Items.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            list.forEach(function (ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        query_Item1.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            list.forEach(function (ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });

        //切换输入框，要清空被切换的存值
        for (var key in this.queryItem) {
          if (list.indexOf(this.queryItem[key].keys) == -1) {
            this.queryItem[key].value = "";
          }
        }
        //图片清空
        if (list.indexOf('SBTP') == -1) {
          this.imgUplad = "";
          this.base64Url = ''
          this.queryDataFn({
            imgLineUrl: ""
          })
        }
      }
      ,
      addInput() {
        this.query_Items.push({
          tmParams: "",
          input: "",
          options: [
            // {
            //   value: "SBMC",
            //   label: "商标名称",
            //   disabled: false,
            // },
            // {
            //   value: "SBTP",
            //   label: "商标图片",
            //   disabled: false
            // },
            {
              value: "SQRMC",
              label: "申请人名称",
              disabled: false
            },
            {
              value: "SQRDZ",
              label: "申请人地址",
              disabled: false
            },
            {
              value: "SQH",
              label: "申请号",
              disabled: false
            },
            // {
            //   value: "LB",
            //   label: "类别",
            //   disabled: false,
            // },
            {
              value: "SQR",
              label: "申请日",
              disabled: false
            },
            {
              value: "TXYSBM",
              label: "图形要素编码",
              disabled: false
            },
            {
              value: "CSGGR",
              label: "初审公告日",
              disabled: false
            },
            {
              value: "ZCR",
              label: "注册日",
              disabled: false
            },
            {
              value: "ZYQDQR",
              label: "专用权到期日",
              disabled: false
            },
            {
              value: "DLJG",
              label: "代理机构",
              disabled: false
            },
            {
              value: 'ZCH',
              label: '注册号',
              disabled: false
            },
            {
              value: "SBZT",
              label: "商标状态",
              disabled: false
            },
            {
              value: "SFGYSB",
              label: "是否共有商标",
              disabled: false
            },
            {
              value: "SFYX",
              label: "是否有效",
              disabled: false
            },
            //{
            // value: "ZCGGR",
            // label: "注册公告日",
            //  disabled: false
            //},
            {
              value: "YXQR",
              label: "优先权日",
              disabled: false
            }
          ]
        });
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function (item, index) {
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
        });
        query_Item1.forEach(function (item, index) {
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
        });

        var query_Items2 = query_Items.slice(-1);
        query_Items2.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            list.forEach(function (ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        // this.isDisabledSBZT();
        if (query_Items.length > 14) {
          this.isAddInput = true;
        }
      }
      ,
      delInput(val, index) {
        this.query_Items.splice(index, 1);
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        query_Items.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            if (item.value == val && val != "") {
              item["disabled"] = false;
            }
          });
        });
        query_Item1.forEach(function (items, index) {
          let options = items.options;
          options.forEach(function (item, i) {
            if (item.value == val && val != "") {
              item["disabled"] = false;
            }
          });
        });
        //删除输入框，要清空存值
        for (var key in this.queryItem) {
          if (this.queryItem[key].keys == val && val != "") {
            this.queryItem[key].value = "";
            this.queryItem[key].beginValue = "";
            this.queryItem[key].endValue = "";
          }
        }
        //商标图片删除，
        if (val == 'SBTP') {
          this.imgUplad = '';
          this.base64Url = ''
        }
        //是否共有商标清空
        if (val == 'SFGYSB') {
          this.queryItem.istotal.value = "";
        }
        //是否有效清空
        if (val == 'SFYX') {
          this.queryItem.active.value = "";
        }
        if (query_Items.length <= 14) {
          this.isAddInput = false;
        }
        this.queryDataFn({
          classify: JSON.parse(JSON.stringify(classifyData))
        })
      }
      ,
      createFn(word) {
        createFn.bind(this)(word);
      }
      ,
      openVienna() {
        this.$nextTick(() => {
          this.$refs.viennaCodezuRef.updateVienna();
        });
      }
      ,
      //获取用户支持查询的国家
      supportquery(file) {
        this.$axios.get('/lantern/jurisdiction/getTrademarkServiceAuthority').then(({data}) => {
          if (data.code == 0) {
            //亚洲
            this.asiaCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 欧洲
            this.europeCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 北美洲
            this.americaCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 南美洲
            this.southCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 非洲
            this.africaCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 大洋洲
            this.OceaniaCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            //知识产权组织
            this.propertyOrganizationCity.map(item => {
              if (data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
          }
        })
          .catch(error => {
          });
      }
      ,
      deleteImg() {
        this.imgUplad = "";
        this.base64Url = ''
        this.queryDataFn({
          imgFile: null,
          imgLocalUrl: imgDefault,
          imgLineUrl: ""
        });
      }
      ,
      viennaInput() {
        /*末尾去;号*/
        let str = this.queryItem.patternElement.value;
        if (!str.length) {
          resetVienna.bind(this)();
          return;
        }
        str = str.substr(-1) == ";" ? str.substr(0, str.length - 1) : str;
        let arr = str.split(";");
        /*判断是否合法*/
        let bol = true;
        arr.map(item => {
          bol = this.noReset.viennaSecondText.includes(item);
        });
        if (!bol) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetVienna.bind(this)();
          return;
        }
        /*set*/
        this.queryDataFn({
          viennaChoosedArr: arr
        });

        //开启类别选择
        function resetVienna() {
          this.noReset.vienna.map(item => {
            item.isOpen = false;
            item.children.map(item2 => {
              item2.isOpen = false;
              item2.children &&
              item2.children.map(item3 => {
                item3.isOpen = false;
                // this.queryData.viennaChoosedArr = [];
                this.queryDataFn({
                  viennaChoosedArr: []
                });
              });
            });
          });
        }
      }
      ,
      classifyInput() {
        /*末尾补;号*/
        let str = this.queryItem.classify.value;
        if (!str.length) {
          resetClassify.bind(this)();
          return;
        }
        str = str.substr(-1) == ";" ? str : str + ";";
        let arr = str.match(/\d{1,2}/g);
        if (arr == null) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetClassify.bind(this)();
          return;
        }
        let strNew = arr.join(";") + ";";
        /*判断是否合法*/
        let bol = false;
        if (strNew == str) bol = true;
        let middleArr = arr.filter(item => item == 0 || item > 45 || item < 1);
        if (middleArr.length) bol = false;
        if (!bol) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetClassify.bind(this)();
          return;
        }
        /*set*/
        arr.map((item, index) => {
          if (item < 10) arr[index] = "0" + item * 1;
        });
        arr = [...new Set(arr)];
        this.queryData.classify.map(item => {
          let ddd = arr.includes(item.no);
          if (ddd) {
            item.isCheck = true;
            item.isQuery = true;
          } else {
            item.isCheck = false;
            item.isQuery = false;
          }
        });
        this.queryDataFn();

        function resetClassify() {
          this.queryData.classify.map(item => {
            item.isCheck = false;
            item.isQuery = false;
          });
          this.queryDataFn();
        }
      }
      ,
      queryWayFn() {
        this.queryDataFn({
          queryWay: this.queryMode.choosed
        });
      }
      ,
      isOpenFn(item) {
        item.isOpen = !item.isOpen;
      }
      ,
      viennaChange() {
      },
      reset_Fn() {
        //     //删除输入框，要清空存值
        let val = ''
        for (var key in this.queryItem) {
          if (this.queryItem[key].keys) {
            this.queryItem[key].value = "";
            this.queryItem[key].beginValue = "";
            this.queryItem[key].endValue = "";
          }
        }
      }
      ,
      //商标状态是否可选
      isDisabledSBZT() {
        //判断是否已选
        let isSelected = false;
        this.query_Items.forEach(function (items, index) {
          if (items.tmParams == 'SBZT') {
            isSelected = true;
          }
        });
        //中欧美以外其他国家商标状态未定，所以不可选
        if (this.queryData.country == "CN" || this.queryData.country == "EU" || this.queryData.country == "US") {
          this.query_Items.forEach(function (item, index) {
            let options = item.options;
            options.forEach(function (item2, i) {
              if (item2["value"] == 'SBZT') {
                item2["disabled"] = isSelected;
              }
            });
          });
        } else {
          this.query_Items.forEach((item, index) => {
            let options = item.options;
            //已选则删除
            if (item.tmParams == 'SBZT') {
              this.delInput('SBZT', index);
            }
          });
          //上下两个循环合一起时 第二个修改不生效，原因未知
          this.query_Items.forEach((item, index) => {
            let options = item.options;
            options.forEach((item2, i) => {
              //未选不可选
              if (item2["value"] == 'SBZT') {
                item2["disabled"] = true;
              }
            });
          });
        }
      }
      ,
      whichLandFn(v) {
        if (this.isSelectAll) {
          this.isSelectAll = false;
          this.queryDataFn({
            tabCountryName: '',
            country: this.queryData.countryList.filter(i => !i.disabled).map(i => i.countryKey).includes('CN') ? ['CN'] : []
          })
          this.asideFormFn({
            ...this.$store.state.query.asideForm,
            country: this.queryData.country,
          })
        } else if (v.includes('all') && !this.isSelectAll) {
          this.isSelectAll = true;
          this.queryDataFn({
            tabCountryName: '',
            country: [...this.queryData.countryList.filter(i => !i.disabled).map(i => i.countryKey)]
          })
          this.asideFormFn({
            ...this.$store.state.query.asideForm,
            country: [this.queryData.countryList[0].countryKey],
          })
        } else {
          this.queryDataFn({
            tabCountryName: ''
          })
        }
        //回复默认，不可图片查询
        this.queryData.isCanUploadImg = true;
        let tmpThis = this;
        this.queryData.countryList.map((countryItem) => {
          if (tmpThis.queryData.country.join(";").includes(countryItem.countryKey)) {
            tmpThis.queryData.countryName = countryItem.chName;
            //部分国家暂不支持图片查询
            if (!countryItem.isGraphQuerySelect) {
              tmpThis.queryData.isCanUploadImg = false;
            }
          }
        });
        //商标状态是否可选
        // this.isDisabledSBZT();
        let TM5CountyList = ['CN', 'EU', 'US', 'JP', 'KR'],
          OtherCountryList = ["AR", "AU", "AT", "BX", "BR", "CA", "CL", "DK", "FI", "FR", "DE", "GB", "GR", "HK", "HU", "IS", "IN", "ID", "IE", "IT", "MO", "MY", "MX", "NZ", "NO", "PH", "PT", "RU", "SG", "ZA", "ES", "SE", "CH", "TW", "TH", "TR", "AE", "WO"]
        let tm5Flag = this.queryData.country.every(i => TM5CountyList.includes(i)),
          otherFlag = this.queryData.country.every(i => OtherCountryList.includes(i));
        if (tm5Flag && !otherFlag) {
          this.queryDataFn({
            countryStatusVuex: 0
          })
        } else if (!tm5Flag && otherFlag) {
          this.queryDataFn({
            countryStatusVuex: 1
          })
        } else {
          this.queryDataFn({
            countryStatusVuex: 2
          })
        }
        this.queryItem.status.value = [];
      }
      ,
      highGradeQuery() {
        /*isOk验证至少有一个必填项*/
        /*isOdd验证成对出现*/
        {
          let isOk = 0;
          for (let key in this.queryItem) {
            if ("beginValue" in this.queryItem[key]) {
              /*时间成对选择*/ //暂时不用成对
              if (
                this.queryItem[key].beginValue &&
                this.queryItem[key].endValue &&
                this.queryItem[key].beginValue > this.queryItem[key].endValue
              ) {
                this.$message(this.$t("reg_query_lang.right_range_of_time")); //请选择正确的时间段
                return;
              }
              let valueStr = "";
              if (this.queryItem[key].beginValue)
                valueStr +=
                  "从" +
                  moment(this.queryItem[key].beginValue).format("YYYY-MM-DD") +
                  "起 ";
              if (this.queryItem[key].endValue)
                valueStr +=
                  "到" +
                  moment(this.queryItem[key].endValue).format("YYYY-MM-DD") +
                  "止";
              this.queryItem[key].valueStr = valueStr;
            }
            if (this.queryItem[key].isMust == 1 && this.queryItem[key].value)
              isOk++;
          }
        }
        this.asideFormFn({
          ...this.$store.state.query.asideForm,
          country: [this.queryData.tabCountryName || this.queryData.country[0]],
          // country: [this.queryData.country[0]],
        })
        this.setClassifyShowFn();
        let sendData = {
          active: this.queryItem.active.value ? this.queryItem.active.value : undefined,
          // applicant: this.queryItem.tmApplicant.value ? this.queryItem.tmApplicant.value : undefined,
          istotal: this.queryItem.istotal.value ? this.queryItem.istotal.value : undefined, //是否共有商标
          niceChoosedStr: this.niceChoosedStr ? this.niceChoosedStr : undefined, //选择的尼斯分类
          regNo: this.queryItem.registerNumber.value ? this.queryItem.registerNumber.value.replace(/[.]+/g, '').replace(/[，,；;]+/g, ';') : undefined, //注册号
          address: this.queryItem.tmAddress.value ? this.queryItem.tmAddress.value : undefined, //申请人地址
          trialBeginDate: this.queryItem.trialDate.beginValue ? moment(this.queryItem.trialDate.beginValue).format("YYYY-MM-DD") : undefined,
          trialEndDate: this.queryItem.trialDate.endValue ? moment(this.queryItem.trialDate.endValue).format("YYYY-MM-DD") : undefined,
          priorityBeginDate: this.queryItem.priorityDate.beginValue ? moment(this.queryItem.priorityDate.beginValue).format("YYYY-MM-DD") : undefined,
          priorityEndDate: this.queryItem.priorityDate.endValue ? moment(this.queryItem.priorityDate.endValue).format("YYYY-MM-DD") : undefined,
          appBeginDate: this.queryItem.appDate.beginValue ? moment(this.queryItem.appDate.beginValue).format("YYYY-MM-DD") : undefined,
          appEndDate: this.queryItem.appDate.endValue ? moment(this.queryItem.appDate.endValue).format("YYYY-MM-DD") : undefined,
          regBeginDate: this.queryItem.regDate.beginValue ? moment(this.queryItem.regDate.beginValue).format("YYYY-MM-DD") : undefined,
          regEndDate: this.queryItem.regDate.endValue ? moment(this.queryItem.regDate.endValue).format("YYYY-MM-DD") : undefined,
          validBeginDate: this.queryItem.privateDate.beginValue ? moment(this.queryItem.privateDate.beginValue).format("YYYY-MM-DD") : undefined,
          validEndDate: this.queryItem.privateDate.endValue ? moment(this.queryItem.privateDate.endValue).format("YYYY-MM-DD") : undefined,
          tmstatuscode: this.queryItem.status.value.length > 0 ? this.queryItem.status.value.join(';') : undefined, //申请人地址
        };
        let optionalCondition = []
        this.query_Items.forEach(item => {
          let obj = {}
          for (let k in this.queryItem) {
            if (this.queryItem[k]["keys"] === item.tmParams) {
              obj.key = item.tmParams
              obj.beginValue = this.queryItem[k]['beginValue']
              obj.endValue = this.queryItem[k]['endValue']
              obj.value = this.queryItem[k]['value']
              obj.imgUplad = this.queryData.imgLineUrl
              optionalCondition.push(obj)
            }
          }
        })

        let solidCondition = {}
        for (let k in this.queryItem) {
          if (this.queryItem[k]["keys"] === this.query_Item1[0].tmParams) {
            solidCondition.key = this.query_Item1[0].tmParams
            solidCondition.value = this.queryItem[k]['value']
            solidCondition.imgUplad = this.queryData.imgLineUrl
          }
        }
        this.queryCriteria = sendData
      }
      ,
      niceConfirm() {
        this.niceVisible = false;
      }
      ,
      niceClose() {
        this.niceVisible = false;
      }
      ,
      /*0124尼斯分类*/
      classifyChange(item) {
        item.isCheck = !item.isCheck;
        item.isQuery = item.isCheck;
        if (
          this.queryData.classify.filter(item2 => item2.isCheck == false).length
        )
          this.othersItemFn({
            checkAll: false
          });
        else
          this.othersItemFn({
            checkAll: true
          });
        this.queryDataFn(); //刷新vuex工具,以便可视及时更新;
      }
      ,
      checkedAllFn() {
        this.queryData.classify.map(item => {
          item.isCheck = this.othersItem.checkAll;
          item.isQuery = this.othersItem.checkAll;
        });
        this.queryDataFn();
      }
      ,
      classifyCancel() {
        this.queryDataFn({
          classify: JSON.parse(JSON.stringify(this.queryData.classifyDefault))
        });
      }
      ,
      classifyConfirm() {
        //高级查询中确定不能让condition为true
        this.queryItemsShowFn({
          condition: false
        });
      }
      ,
      //类别选择
      category() {
        this.queryConditon = !this.queryConditon
      }
      ,
      //组合查询
      combinedQueryEven() {
        this.combinedquery = !this.combinedquery;
        // return;
        // this.$router.push({path: '/combinedQuery'})
      }
      ,
      clossOpen() {
        this.isRotate = true
        this.boxShow = false
      }
      ,
      mouseenter(){
        clearInterval(this.intervalId); // 停止滚动
      },
      mouseleave(){
        this.chatBox = document.getElementById('chatBox'); // 假设聊天框的 ID 是 chatBox
        this.intervalId = setInterval(() => {
          const isAtBottom = this.chatBox.scrollTop + this.chatBox.offsetHeight >= this.chatBox.scrollHeight;
          if (!isAtBottom ) {
            this.chatBox.scrollTop += 50;
          } else {
            // 如果已经到底部，可以清除定时器，或进行其他操作
            this.automaticPagination(true)
          }
        }, 1000);
        this.chatBox.addEventListener('scroll', () => {
          const isAtTop = this.chatBox.scrollTop === 0;

          if (isAtTop) {
            console.log('div 已经滚动到顶部');
            // 可以在这里执行一些操作，比如显示提示、加载更多内容等
            this.automaticPagination(false)
          }
        });
      },
      tableSwitching() {
        console.log(123456)
        this.flagyang = true
        this.isModeTable = !this.isModeTable
        if(this.isModeTable) {
          this.chatBox = document.getElementById('chatBox'); // 假设聊天框的 ID 是 chatBox
          clearInterval(this.intervalId);
// 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
        }
      },
      //展示可注册分析报告
      jumpReport() {
        if(this.gridOptions.data.length == 0){
          return
        }
        if(this.flagyang) {
          this.flagyang = false
          this.isModeTable = true
        }

        if(this.isModeTable) {
          this.isModeTable = false
          this.$nextTick(() => {
            this.chatBox = document.getElementById('chatBox'); // 假设聊天框的 ID 是 chatBox
            clearInterval(this.intervalId);
            // 卸载事件监听器
            window.removeEventListener('scroll', () => {
              const isAtTop = this.chatBox.scrollTop === 0;

              if (isAtTop) {
                console.log('div 已经滚动到顶部');
                // 可以在这里执行一些操作，比如显示提示、加载更多内容等
                this.automaticPagination(false)
              }
            });
            this.intervalId = setInterval(() => {
              const isAtBottom = this.chatBox.scrollTop + this.chatBox.offsetHeight >= this.chatBox.scrollHeight;
              if (!isAtBottom ) {
                this.chatBox.scrollTop += 50;
              } else {
                // 如果已经到底部，可以清除定时器，或进行其他操作
                this.automaticPagination(true)
              }
            }, 1000);

            this.chatBox.addEventListener('scroll', () => {
              const isAtTop = this.chatBox.scrollTop === 0;

              if (isAtTop) {
                console.log('div 已经滚动到顶部');
                // 可以在这里执行一些操作，比如显示提示、加载更多内容等
                this.automaticPagination(false)
              }
            });
          })
        }else {
          clearInterval(this.intervalId);
          // 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
          this.isModeTable = true
        }
      }
      ,
      //商标支付
      apply() {
        if (this.tool.getCookie("auth")) {
          this.$router.push({path: '/trademarkPayment'})
        } else {
          this.dialogVisible = true
        }
      }
      ,
      //数据库
      database(value) {
        if (value) {
          this.selectAll()
        } else {
          this.cancelAll()
        }
      }
      ,
      //点击显示数据库
      rotate() {
        this.isRotate = !this.isRotate
        this.boxShow = !this.isRotate
      }
      ,

      //全部选中
      selectAll() {
        //数据库
        this.checked = true
        //亚洲
        this.asiaCheckAll = true
        this.handleCheckAllChange(true)
        // 欧洲
        this.europeCheckAll = true
        this.handleCheckAllChanges(true)
        // 北美洲
        this.americaCheckAll = true
        this.handleCheckAllChangess(true)
        // 南美洲
        this.southCheckAll = true
        this.handleCheckAllChangesss(true)
        // 非洲
        this.africaCheckAll = true
        this.handleCheckAllChangessss(true)
        // 大洋洲
        this.oceaniaCheckAll = true
        this.handleCheckAllChangesssss(true)
        //世界知识产权
        this.propertyCheckAll = true
        this.handleCheckAllChangessssse(true)
      }
      ,
      //全部取消
      cancelAll() {
        //数据库
        this.checked = false
        //亚洲
        this.asiaCheckAll = false
        this.handleCheckAllChange(false)
        // 欧洲
        this.europeCheckAll = false
        this.handleCheckAllChanges(false)
        // 北美洲
        this.americaCheckAll = false
        this.handleCheckAllChangess(false)
        // 南美洲
        this.southCheckAll = false
        this.handleCheckAllChangesss(false)
        // 非洲
        this.africaCheckAll = false
        this.handleCheckAllChangessss(false)
        // 大洋洲
        this.oceaniaCheckAll = false
        this.handleCheckAllChangesssss(false)
        // 世界知识产权
        this.propertyCheckAll = false
        this.handleCheckAllChangessssse(false)
      }
      ,
      //-------------------------------------------------选中------------------------------------------
      handleCheckAllChange(val) {
        this.asia = val ? cityOptions.map(item => item.code) : []
        this.asiaIsIndeterminate = false;
      }
      ,
      handleCheckAllChanges(val) {
        this.europe = val ? city.map(item => item.code) : [];
        this.europeIsIndeterminate = false;
      }
      ,
      handleCheckAllChangess(val) {
        this.america = val ? cityOptionsd.map(item => item.code) : [];
        this.americaIsIndeterminate = false;
      }
      ,
      handleCheckAllChangesss(val) {
        this.south = val ? cityOptionsds.map(item => item.code) : [];
        this.southIsIndeterminate = false;
      }
      ,
      handleCheckAllChangessss(val) {
        this.africa = val ? cityOpts.map(item => item.code) : [];
        this.africaIsIndeterminate = false;
      }
      ,
      handleCheckAllChangesssss(val) {
        this.oceania = val ? cityds.map(item => item.code) : [];
        this.oceaniaIsIndeterminate = false;
      }
      ,
      //世界知识产权组织
      handleCheckAllChangessssse(val) {
        this.property = val ? propertyOrganization.map(item => item.code) : [];
        this.propertyIndeterminate = false;
      }
      ,
      //亚洲
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.asiaCheckAll = checkedCount === this.asiaCity.length;
        this.asiaIsIndeterminate = checkedCount > 0 && checkedCount < this.asiaCity.length;
      }
      ,
      // 欧洲
      handleCheckedCitiesChanges(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.europeCheckAll = checkedCount === this.europeCity.length;
        this.europeIsIndeterminate = checkedCount > 0 && checkedCount < this.europeCity.length;
      }
      ,
      // 北美洲
      handleCheckedCitiesChangess(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.americaCheckAll = checkedCount === this.americaCity.length;
        this.americaIsIndeterminate = checkedCount > 0 && checkedCount < this.americaCity.length;
      }
      ,
      //南美洲
      handleCheckedCitiesChangesss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.southCheckAll = checkedCount === this.southCity.length;
        this.southIsIndeterminate = checkedCount > 0 && checkedCount < this.southCity.length;
      }
      ,
      //非洲
      handleCheckedCitiesChangessss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.africaCheckAll = checkedCount === this.africaCity.length;
        this.africaIsIndeterminate = checkedCount > 0 && checkedCount < this.africaCity.length;
      }
      ,
      //大洋洲
      handleCheckedCitiesChangesssss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.oceaniaCheckAll = checkedCount === this.OceaniaCity.length;
        this.oceaniaIsIndeterminate = checkedCount > 0 && checkedCount < this.OceaniaCity.length;
      }
      ,
      //世界知识产权组织
      handleCheckedCitiesChangessssse(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.propertyCheckAll = checkedCount === this.propertyOrganizationCity.length;
        this.propertyIndeterminate = checkedCount > 0 && checkedCount < this.propertyOrganizationCity.length;
      }
      ,
      //  查询蓝灯鱼当前登录用户的服务列表
      async searchServiceAjax() {
        let data = await this.$axios.get("/lantern/serviceLan/searchService");
        if (data.data.msg == "success") {
          if (data.data.data) {
            this.setTrialList(data.data.data)
            this.init();
          }
        }
      }
      ,
      // 去申请
      toApplyEvent() {
        if (!this.tool.getCookie("auth")) {
          this.dialogVisible = true;
        } else {
          this.$router.push({
            path: "/account/trialInfo",
            query: {
              serverType: 3
            }
          })
        }
      }
      ,
      closeEvent(done) {
        this.dialogClose = true;
        done();
      }
      ,
      dialogCloseBool(data) {
        this.dialogClose = data;
      }
      ,
      init() {
        if (this.trialList) {
          for (var i = 0; i < this.trialList.length; i++) {
            if (this.trialList[i].serviceName == 0) {
              this.serviceNameType = this.trialList[i].serviceType
            }
          }
        }
      }
      ,
      // 登录弹层隐藏
      loginDialog(data) {
        this.dialogVisible = data;
      }
      ,
      blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
          callback(e.target.result);
        };
        a.readAsDataURL(blob);
      }
      ,
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      }
      ,
      //商标续展
      extensionEvent(){
        //   this.$nuxt.$loading.start()
        this.queryModes = "text";
        this.pageNos = 1;
        this.$refs.xGrid?.clearCheckboxRow()
        this.$refs.xGrid?.clearCheckboxReserve()
        this.$axios.get('/trademark/monitoringRenewal/page',{
          params: {
            size: this.pageSizess,
            current:this.pageNoss
          }
        }).then(({data}) => {
          if(data.code == 0){
            this.extension.data = data.data.records.map(item => {
              // if(item.registerDate && (Number(item.registerDate.split('-')[0]) + 10) == new Date().getFullYear()){
              //   item.color = 1
              // }else {
              //   item.color = 0
              // }
              if(item.registerDate && this.costCalcu(item.registerDate) == 1){
                item.color = 8
              }else if(item.registerDate && this.costCalcu(item.registerDate) == 2){
                item.color = 7
              }

              item.trademarkImageUrl = this.queryDataUrl(item.trademarkImageUrl)
              //续展费用
              item.renewalAmount = item.renewalAmount == '1' ? '/' : item.renewalAmount
              //宽展费用
              item.extendedAmount = item.extendedAmount == '1' ? '/' : item.extendedAmount
              //续展费和宽展费之和
              item.renewalSummaryAmount = item.renewalSummaryAmount == '1' ? '/' : item.renewalSummaryAmount
              return item
            })
            this.totals_ = data.data.total
          }
        })
      },
      //交叉检索
      searchCross_(value) {
        if(this.tool.getCookie("auth")){
        }else {
          this.dialogVisible = true
          return;
        }
        if(this.errFlag){
          return;
        }
        this.selectData = []
        this.isHistory = false
        //是交叉检索
        this.searchCrossFlag = true
        this.invalidAnnouncement = false
        this.extensionFlag = false
        this.extensionFlag1 = false
        this.pageNoss = 1
        this.totals = 0
        //检索
        if (this.value == 8) {
          //商品分类
          this.classification()
          return;
        }
        //商标公告
        if (this.switchTypeId == 4) {
          //商标公告
          this.trademarkGazette()
          return;
        }
        if (value) {
          this.isScreen = false
        } else {
          this.flag = 0;
          this.isScreen = false;
          if (this.$refs.xGrid) {
            this.$refs.xGrid.clearFilter()
            this.$refs.xGrid.clearSort()
            this.$refs.xGrid?.clearCheckboxRow()
            this.$refs.xGrid?.clearCheckboxReserve()
          }
        }
        this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if (this.tool.getCookie("auth")) {

        } else {
          this.dialogVisible = true
          return;
        }

        if (this.switchTypeId == 1 && this.searchText.trim()) {
          this.queryModes = "text";
        } else if (this.switchTypeId == 1 && this.base64Url) {
          this.queryModes = 'image'
        } else if (this.value == 7 && this.searchText.trim()) {
          this.queryModes = 'image'
        }

        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }

        this.searchFn();
      },
      //交叉检索
      searchCross(value) {
        if(this.tool.getCookie("auth")){}
        else {
          this.dialogVisible = true
          return;
        }
        if(this.errFlag){
          return;
        }

        this.categoryTypeVisibleFlag = false
        this.selectData = []
        this.isHistory = false
        //是交叉检索
        this.searchCrossFlag = true
        this.invalidAnnouncement = false
        this.extensionFlag = false
        this.extensionFlag1 = false
        this.pageNoss = 1
        this.totals = 0
        //检索
        if (this.value == 8) {
          //商品分类
          this.classification()
          return;
        }
        //商标公告
        if (this.switchTypeId == 4) {
          //商标公告
          this.trademarkGazette()
          return;
        }
        if (value) {
          this.isScreen = false
        }
        else {
          this.ifShowAnalysisIcon = false
          this.flag = 0;
          this.isScreen = false;
          if (this.$refs.xGrid) {
            this.$refs.xGrid.clearFilter()
            this.$refs.xGrid.clearSort()
            this.$refs.xGrid?.clearCheckboxRow()
            this.$refs.xGrid?.clearCheckboxReserve()
          }
        }
        this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if (this.tool.getCookie("auth")) {}
        else {
          this.dialogVisible = true
          return;
        }

        if (this.switchTypeId == 1 && this.searchText.trim()) {
          this.queryModes = "text";
        }
        else if (this.switchTypeId == 1 && this.base64Url) {
          this.queryModes = 'image'
        }
        else if (this.value == 7 && this.searchText.trim()) {
          this.queryModes = 'image'
        }

        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        }
        else {
          this.switchStyleDefault = true;
        }

        this.searchFn();
      },
      searchFirstFn() {
        //不是交叉检索
        this.Flag = false
        if (this.value == 8) {
          //商品分类
          this.classification()
          return;
        }

        this.flag = 0;
        this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if (this.tool.getCookie("auth")) {

        } else {
          this.dialogVisible = true
          return;
        }

        if (this.switchTypeId == 1 && this.searchText.trim()) {
          this.queryModes = "text";
        } else if (this.switchTypeId == 1 && this.base64Url) {
          this.queryModes = 'image'
        } else if (this.value == 7 && this.searchText.trim()) {
          this.queryModes = 'image'
        }

        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }

        this.searchFn();
      }
      ,
      confirmSizer() {
        this.resultList = []
        // this.switchStyleDefault = true;
        if (this.searchText.trim()) {
          this.queryModes = "text";
        }

        this.searchFn();
      }
      ,
      //类别重置
      resetSizer(item) {
        if (item == true) {
          this.niceList && this.niceList.map(item => {
            return item.isCheck = true
          })
          this.niceList && this.niceList.map(item => {
            return item.isSimilar = false
          })
          this.sameMessage = []
        } else {
          this.compoundArr = []
          this.multipleSelection = []
          this.niceList && this.niceList.map((item) => {
            item.isCheck = false;
          });
          this.niceList && this.niceList.map(item => {
            return item.isSimilar = false
          })
          this.sameMessage = []
          this.tmStatusList.map((item) => {
            item.isCheck = false;
          });
        }
      }
      ,
      moreFn(who) {
        this.moreObj[who] = !this.moreObj[who];
      }
      ,
      searchFn() {
        // if(this.switchTypeId == 1){
        //   this.getServicePermission()
        //   if(!this.getServicePermissionFlag) return;
        // }

        this.boxShow = false
        this.isRotate = true
        if (this.searchText.trim() && !this.base64Url) {
          // if (this.queryModes) {
          let regex = new RegExp("；", "g"),
            regexSemi = new RegExp("^[;]+|[;]+$", "g");
          let searchTextArr = this.searchText.replace(regex, ";").replace(regexSemi, "").split(";");
          let isAppNo = new RegExp(/^[gGaA]?[0-9]+[gGaA]?$/);
          if (searchTextArr && searchTextArr.length != 0) {
            for (var i = 0; i < searchTextArr.length; i++) {
              //不是申请号判断
              // if (!isAppNo.test(searchTextArr[i])) {
              // this.value = 2;
              this.pageNo = 1;
              this.pageNos = 1;
              // this.queryModes = "text";
              // this.querySearch_FromDB();
              this.getJurisdictionAjax();
              return;
              // } else {
              //   //申请号近似
              //   this.value = 5;
              // }
            }
          }
          // else {
          //   if (isAppNo.test(this.searchText)) {
          //     this.value = 5;
          //   }
          // }
          // }
          this.highGradeQuery()
          this.pageNo = 1;
          this.pageNos = 1;
          // this.queryModes = "text";
          // this.querySearch_FromDB();
          this.getJurisdictionAjax();
        }
        else if ((this.switchTypeId == 1 && this.base64Url && !this.searchText.trim()) || (this.value == 7 && this.base64Url && this.searchText.trim())) {
          this.highGradeQuery()
          this.pageNo = 1;
          this.pageNos = 1;
          // this.queryModes = "image";
          // this.graphSearch_FromDB()
          //图片查询
          this.getJurisdictionAjaxImage();
        }
        else if (this.value == 7 && this.searchText.trim()) {
          this.highGradeQuery()
          this.pageNo = 1;
          this.pageNos = 1;
          // this.queryModes = "image";
          // this.graphSearch_FromDB()
          //图片查询
          this.getJurisdictionAjaxImage();
        }
        else {
          if (this.searchText.trim() && this.queryModes == "image") {
            this.$message(this.$t(`PQ.Tips[0]`));
          } else if (this.base64Url && this.queryModes == "text") {
            this.$message(this.$t(`PQ.Tips[1]`));
          } else {
            if (this.switchTypeId == 1) this.$message(this.$t(`PQ.Tips[2]`));
            else if (this.value == 7) this.$message(this.$t(`PQ.Tips[3]`));
            else this.$message(this.$t(`PQ.Tips[1]`));
          }
        }
      }
      ,
      copys(text, value) {
        if (text) {
          //获取要赋值的input的元素
          var inputElement = document.getElementById("copy_content");
          //给input框赋值
          inputElement.value = text;
          //选中input框的内容
          inputElement.select();
          // 执行浏览器复制命令
          document.execCommand("Copy");
          if (value) {
            this.$axios.post('/common/query/getNoticePages', {
              trialNum: text.toString(),
              "beginPage": 1,
              "endPage": 100
            }).then((data) => {
              return;
            })
          }
          //提示已复制
          this.$message('已复制');
        } else {
          //提示已复制
          this.$message('暂无数据');
        }
      }
      ,
      copy(text) {
        if (text) {
          text = JSON.parse(text).join(';')
          //获取要赋值的input的元素
          var inputElement = document.getElementById("copy_content");
          //给input框赋值
          inputElement.value = text;
          //选中input框的内容
          inputElement.select();
          // 执行浏览器复制命令
          document.execCommand("Copy");
          //提示已复制
          this.$message('已复制');
        } else {
          //提示已复制
          this.$message('暂无数据');
        }
      }
      ,
      goDetail(item) {
        // $(window).unbind('scroll', this.scrollFn);
        if (item.country == "CN") {
          window.open(window.location.origin + '/#/query/detail' + '?id=' + item.id + '&country=' + item.country + '&flag=0');
        } else {
          window.open(window.location.origin + '/#/query/otherTrademarkArchives' + '?id=' + item.id + '&country=' + item.country + '&flag=0');
        }
      }
      ,
      aLargerPreview(item) {
        window.open(this.queryDataUrl(item.imageUrl));
        this.resultList.map(item => item.showBig = false);
        item.showBig = true;
      },
      handleCurrentChange() {
        this.highGradeQuery()
        // this.switchStyleDefault = true;
        this.searchHandle()
        return;
        switch (this.queryModes) {
          case "image": {
            //   this.queryModes = "image";
            //   this.graphSearch_FromDB()
            // this.getJurisdictionAjaxImage();
            this.gridOptions.data = []
            this.searchHandle()
          }
            break;
          case "text": {

            //   this.querySearch_FromDB();
            // this.getJurisdictionAjax();
            this.gridOptions.data = []
            this.searchHandle()
          }
            break;
        }
      }
      ,
      // 更新是否是第一次使用服务的状态
      async updateIsFirstClickAjax() {
        let data = await this.$axios.put("/lantern/serviceLan/updateIsFirstClick", {
          params: {
            serviceName: 0
          }
        })
      }
      ,
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjax() {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryModes = "text";
          this.querySearch_FromDB();
          this.$axios.put("/lantern/trademarkSearchLan/updateCount")
        }
        else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          }
          else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            }
            else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              }
              else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      //查找code 对应 国家名字
      code_country(code) {
        return this.allCountry.filter(item => item.code == code)[0].city
      }
      ,
      //商品分类
      classification() {
        if (!this.searchText.trim()) {
          this.$message(this.$t(`PQ.Tips[1]`));
          return;
        }
        //   this.$nuxt.$loading.start()
        this.queryModes = "text";
        this.pageNos = 1;
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        this.$axios.post('/common/query/tmNice', {
          current: this.pageNoss,
          goodsName: this.searchText,
          size: this.pageSizess,
        }).then(({data}) => {

          if (data.code != 0) {
            this.$confirm('您的使用次数不足，为完成本次查询，请购买次数或更换查询条件。', this.$t(`patentSearch.tips`), {
              confirmButtonText: '直接购买',
              cancelButtonText: '暂不购买',
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/services",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            })
          } else {
            this.allCommodity = data.data.records
            this.isRelutes = true
            this.gridOption.data = this.allCommodity.slice((this.pageNos - 1) * this.pageSizes, this.pageNos * this.pageSizes)
            this.totals = data.data.total;
            // this.searchHandle(this.getClone(data.data));
          }
        })
      },
      //商标公告
      trademarkGazette() {
        if (!this.searchText.trim()) {
          this.$message(this.$t(`PQ.Tips[1]`));
          return;
        }
        this.queryModes = "text";
        this.pageNos = 1;
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        if (this.announcementFlag == 1) {
          this.$axios.post('/common/query/getNoticeInfo', {
            appno: this.searchText.toString(),
          }).then(({data}) => {
            if (data && data.length > 0) {
              this.trademarkFlag = true
              this.announcement.data = data
              this.datam = data
              this.imgSrcArr = this.announcement.data.map(item => item.trialImageUrl)
              this.pdfSrc = this.announcement.data[0].trialImageUrl
            }
          })
            .catch((error) => {
              this.$message.error('查询失败');
            })
        } else {
          if(this.tool.getCookie("auth")){
            this.$router.push({path: '/query/announcementDetail', query: {searchText: this.searchText}})
          }else {
            this.dialogVisible = true
          }
        }
      }
      ,
      //商标公告期号
      querymentDetail(item, appno) {
        // this.$router.push({path: '/query/announcementDetail', query: {searchText: item}})
        // await this.getNoticOverView()
        this.$axios.post('/common/query/getNoticeInfo', {
          trialNum: item,
          appno: appno.toString()
        }).then(({data}) => {
          if (data.length > 0) {
            if (!data[0].trialImageUrl) {
              this.$message.error('暂无商标公告数据');
              return;
            }
            window.open(data[0].trialImageUrl, '_blank')
          } else {
            this.$message.error('暂无数据');
          }
        })
          .catch((error) => {
            this.$message.error('查询失败');
          })
      },
      //商标公告
      detailPdf() {

      },
      //判断对象是否为空
      isobject(obj) {
        var arr = Object.keys(obj);
        if (arr.length == 0) {
          return undefined
        } else {
          return obj
        }
      },
      /*文字查詢*/
      querySearch_FromDB(flag) {
        if (!this.searchText.trim()) return;
        this.highGradeQuery()
        this.queryModes = "text";
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        this.isScreen ? '' : this.value == 2 ? this.famous()  : ''
        this.isScreen ? '' : this.value == 2 ? this.heroic()  : ''
        this.$axios.post('/common/query/search/v2', {
          current: this.pageNos,
          similargroup:this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ?  ( this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') :  this.getIsAllChecked ? undefined  : this.multipleSelection.join(';')) : undefined,
          //this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : undefined,
          keywork: this.value == 7 && this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : this.value == 7 ? undefined : this.value == 2 || this.value == 4 ? this.searchText : undefined,
          //图形要素:
          imageCodeList: this.value == 7 ? this.searchText : undefined,
          size: this.pageSizes,
          niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';') : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          agent: this.value == 6 ? this.searchText : undefined,
          applicant: this.value == 0 || this.value == 3 ? this.searchText : this.queryItem.tmApplicant.value ? this.queryItem.tmApplicant.value : undefined,
          appNo: this.value == 1 || this.value == 5 ? this.searchText : undefined,
          queryWay: this.value == 7 && this.queryItem.tmApplicant.value ? 'match' : this.value !== "" && this.value !== 6 && this.value !== 3 ? this.firstList[this.value].queryWay : this.value === 3 || this.value === 6 ? 'term' : undefined,
          secondQuery: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.isobject({...this.conditions}) : this.flag ? this.isobject({...this.conditions}) : this.switchTypeId == 1 && this.value == 2 ? undefined : undefined,
          order: this.flag ? this.orderCond : undefined,
          startScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : undefined,
          endScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : undefined,
          ...this.queryCriteria
        })
          .then(async ({data}) => {
            if (data.code != 0 && data.code != 4) {
              //VIP用户
              if (this.isVip != 'COMMON') {
                this.$confirm('您的使用次数不足，为完成本次查询，请购买次数或更换查询条件。', this.$t(`patentSearch.tips`), {
                  confirmButtonText: '直接购买',
                  cancelButtonText: '暂不购买',
                  center: true,
                  customClass: "time_confirm",
                  closeOnClickModal: false
                }).then(() => {
                  this.$router.push({
                    path: "/services",
                    query: {
                      serverId: 27
                    }
                  })
                })
              }
              else {
                //普通用户
                this.$axios
                  .get("/product/servicemanage/getTrialCount").then(({data}) => {
                  if (data.code == 0) {
                    this.$confirm(`您的第${data.data.trialCount}次试用服务已到期`, this.$t(`patentSearch.tips`), {
                      confirmButtonText: '直接购买',
                      cancelButtonText: `继续试用${data.data.totalTrialCount - data.data.trialCount <= 0 ? 0 : data.data.totalTrialCount - data.data.trialCount}`,
                      center: true,
                      customClass: "time_confirm",
                      closeOnClickModal: false,
                      distinguishCancelAndClose: true
                    }).then(() => {
                      this.$router.push({
                        path: "/services",
                        query: {
                          serverId: 27
                        }
                      })
                    }).catch((action) => {

                      if (action === 'cancel') {
                        if (data.data.totalTrialCount - data.data.trialCount <= 0) {
                          this.$message({
                            message: '您的试用服务已结束',
                            type: 'warning'
                          });
                        } else {
                          this.applicaEvent()
                        }
                      }
                    });
                  }
                })
              }
            }
            else if (data.code == 4) {
              this.$message.error('系统错误');
            }
            else {
              this.condition = this.searchText
              this.allResult = data.data
              this.gridOptions.data = []

              this.searchHandle(this.getClone(data.data));

              this.statistical(
                {
                  current: this.pageNos,
                  similargroup: this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ? (this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') : this.getIsAllChecked ? undefined : this.multipleSelection.join(';')) : undefined,
                  //this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : undefined,
                  keywork: this.value == 7 && this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : this.value == 7 ? undefined : this.value == 2 || this.value == 4 ? this.searchText : undefined,
                  //图形要素:
                  imageCodeList: this.value == 7 ? this.searchText : undefined,
                  size: this.pageSizes,
                  niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';') : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
                  tmStatus: this.tmStatusChecked || undefined,
                  country: this.stateChange,
                  lang: "zh",
                  agent: this.value == 6 ? this.searchText : undefined,
                  applicant: this.value == 0 || this.value == 3 ? this.searchText : this.queryItem.tmApplicant.value ? this.queryItem.tmApplicant.value : undefined,
                  appNo: this.value == 1 || this.value == 5 ? this.searchText : undefined,
                  queryWay: this.value == 7 && this.queryItem.tmApplicant.value ? 'match' : this.value !== "" && this.value !== 6 ? this.firstList[this.value].queryWay : undefined,
                  secondQuery: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.isobject({...this.conditions}) : this.flag ? this.isobject({...this.conditions}) : this.switchTypeId == 1 && this.value == 2 ? undefined : undefined,
                  order: this.flag ? this.orderCond : undefined,
                  startScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : undefined,
                  endScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : undefined,
                  ...this.queryCriteria
                }
              )
            }
          })
      },
      getClone(data) {
        return JSON.parse(JSON.stringify(data))
      },
      /*文字查詢----分析报告*/
      querySearch_FromDBs() {
        if (!this.searchText.trim()) return;
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0,
          }
        })

        let arr = []
        let isCheckArr = []
        this.niceList.filter(item => item.isCheck).map(item => {
          isCheckArr.push(item.name)
        })
        // if(this.getIsAllChecked){
        this.multipleSelection.map(item => {
          if(!isCheckArr.includes(item.slice(0,2))){
            arr.push(item)
          }
        })
        // }
        //   this.$nuxt.$loading.start()
        this.queryModes = "text";
        this.$axios.post('/common/query/analyzeReport', {
          current: this.pageNos,
          crossSimilarygroup: arr.length == 0 ? undefined : arr.join(';'),
          similargroup:this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ?  ( this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') :  this.getIsAllChecked ? undefined  : this.multipleSelection.join(';')) : undefined,
          //this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : undefined,
          keywork: this.value == 7 && this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : this.value == 7 ? undefined : this.value == 2 || this.value == 4 ? this.searchText : undefined,
          //图形要素:
          imageCodeList: this.value == 7 ? this.searchText : undefined,
          size: this.pageSizes,
          niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';') : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          agent: this.value == 6 ? this.searchText : undefined,
          applicant: this.value == 0 || this.value == 3 ? this.searchText : this.queryItem.tmApplicant.value ? this.queryItem.tmApplicant.value : undefined,
          appNo: this.value == 1 || this.value == 5 ? this.searchText : undefined,
          queryWay: this.value == 7 && this.queryItem.tmApplicant.value ? 'match' : this.value !== "" && this.value !== 6 ? this.firstList[this.value].queryWay : undefined,
          secondQuery: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.isobject({...this.conditions}) : this.flag ? this.isobject({...this.conditions}) : this.switchTypeId == 1 && this.value == 2 ? undefined : undefined,
          order: this.flag ? this.orderCond : undefined,
          startScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.startScore : undefined,
          endScore: this.flag && this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : this.flag ? '' : this.switchTypeId == 1 && this.value == 2 ? this.whole.endScore : undefined,
          ...this.queryCriteria
        },{
          responseType: 'blob'
        }).then(({code, data, msg}) => {
          // this.$nuxt.$loading.finish()
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '可注册分析报告.doc'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      /*图片查询-------分析报告*/
      graphSearch_FromDBs() {
        if (!this.base64Url) return;
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        //   this.$nuxt.$loading.start()
        this.queryModes = "image";
        this.value = 4;
        this.$axios.post('/common/query/analyzeReport', {
          current: this.pageNos,
          size: this.pageSizes,
          similargroup:this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ?  ( this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') :  this.getIsAllChecked ? undefined  : this.multipleSelection.join(';')) : undefined,
          base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
          imageCodeList: this.value == 7 ? this.searchText : undefined,
          niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';')  : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
          selectedArea: this.selectedArea || undefined,
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          ...this.queryCriteria,
          secondQuery: this.flag ? this.isobject({...this.conditions}) : undefined,
          order: this.flag ? this.orderCond : undefined,
        },{
          responseType: 'blob'
        }).then(({code, data, msg}) => {
          // this.$nuxt.$loading.finish()
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '可注册分析报告.doc'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjaxImage(params) {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryModes = "image";
          this.graphSearch_FromDB()
          this.$axios.put("/lantern/trademarkSearchLan/updateCount");
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      }
      ,
      /*图片查询*/
      graphSearch_FromDB() {
        // if (!this.base64Url) return;
        this.selectedCountries = this.stateChange && this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        this.highGradeQuery()
        //   this.$nuxt.$loading.start()
        this.queryModes = "image";
        this.value = 4;
        this.$axios.post('/common/graph/search/v2', {
          current: this.pageNos,
          size: this.pageSizes,
          similargroup:this.switchTypeId == 1 && this.value == 2 && this.stateChange == 'CN' ?   ( this.categoryTypeVisibleFlag ? this.multipleSelection.join(';') :  this.getIsAllChecked ? undefined  : this.multipleSelection.join(';')) : undefined,
          base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
          imageCodeList: this.value == 7 ? this.searchText : undefined,
          niceList: this.categoryTypeVisibleFlag ? this.crossNice.join(';') : (this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined),
          selectedArea: this.selectedArea || undefined,
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          keywork: this.imageText ? this.imageText : undefined,
          ...this.queryCriteria,
          secondQuery: this.flag ? this.isobject({...this.conditions}) : undefined,
          order: this.flag ? this.orderCond : undefined,
        }).then(({code, data, msg}) => {
          // this.$nuxt.$loading.finish()
          if (data.code != 0 && data.code != 4) {
            //VIP用户
            if(this.isVip != 'COMMON'){
              this.$confirm('您的使用次数不足，为完成本次查询，请购买次数或更换查询条件。', this.$t(`patentSearch.tips`), {
                confirmButtonText: '直接购买',
                cancelButtonText: '暂不购买',
                center: true,
                customClass: "time_confirm",
                closeOnClickModal: false
              }).then(() => {
                this.$router.push({
                  path: "/services",
                  query: {
                    serverId: 27
                  }
                })
              })
            }else {
              //普通用户
              this.$axios
                .get("/product/servicemanage/getTrialCount").then(({data}) => {
                if(data.code == 0){
                  this.$confirm(`您的第${data.data.trialCount}次试用服务已到期`, this.$t(`patentSearch.tips`), {
                    confirmButtonText: '直接购买',
                    cancelButtonText: `继续试用${data.data.totalTrialCount - data.data.trialCount <= 0 ? 0 : data.data.totalTrialCount - data.data.trialCount}`,
                    center: true,
                    customClass: "time_confirm",
                    closeOnClickModal: false,
                    distinguishCancelAndClose: true
                  }).then(() => {
                    this.$router.push({
                      path: "/services",
                      query: {
                        serverId: 27
                      }
                    })
                  }).catch((action) => {

                    if(action === 'cancel'){
                      if(data.data.totalTrialCount - data.data.trialCount <= 0){
                        this.$message({
                          message: '您的试用服务已结束',
                          type: 'warning'
                        });
                      }else {
                        this.applicaEvent()
                      }
                    }
                  });
                }
              })
            }

          } else if (data.code == 4) {
            // this.dialogVisiblePrompt = true
            this.$message.error('系统错误');
          } else {
            this.condition = this.base64Url
            this.allResult = data.data
            this.gridOptions.data = []
            this.statistical({
              keywork: this.imageText ? this.imageText : undefined,
              current: this.pageNos,
              size: this.pageSizes,
              base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
              imageCodeList: this.value == 7 ? this.searchText : undefined,
              niceList: this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined,
              selectedArea: this.selectedArea || undefined,
              country: this.stateChange,
              lang: "zh",
              ...this.queryCriteria,
              secondQuery: this.flag ? this.isobject({...this.conditions}) : undefined,
              order: this.flag ? this.orderCond : undefined,
            })
            this.searchHandle(this.getClone(data.data));
          }
        })
      },
      jsonStringConvert(str) {
        let n = str.length;
        for (let i = 1; i < n; i++) {
          if (str[i - 1] == '"' && str[i] == ':' && str[i + 1] == '"') {
            for (let j = i + 2; j < n - 1; j++) {
              if (str[j] == '"') {
                if ((str[j + 1] != ',' && str[j + 1] != '}') || (str[j + 1] == ',' && str[j + 2] != '"')) {
                  const strArr = str.split('');
                  strArr[j] = "'";
                  str = strArr.join('');
                } else if (str[j + 1] == '}' || str[j + 1] == ',' || str[j + 1] == '"' || str[j - 1] == '{') {
                  break;
                }
              }
            }
          }
        }
        if (str.charAt(str.length - 1) != "]") {
          str += '"}]'
        }
        return str;
      },
      /*文字或图片查询的后续处理*/
      searchHandle(data, datas) {
        if(this.chatBox) {
          this.chatBox.scrollTop = 10;
        }
        if(this.isHistory) {
          if(this.historicalSearchData.searchType == '文字检索') {
            this.isHistoryAnalysist = true
          }else {
            this.isHistoryAnalysist = false
          }
        }else {
          this.isHistoryAnalysist = true
        }
        if (this.stateChange && this.stateChange.split(";").length >= 43) {
          this.$message('温馨提示：如选择43国一并查询，可能会因数据过多而导致查询时间过长或查询不出。如出现类似情况，建议您分批查询。');
        }
        this.isColumn = false
        let that = this;
        this.isRelute = true
        const $table = this.$refs.xGrid || undefined
        this.value == 5 || 3 || 6 ? this.isapplication = true : this.isapplication = false
        this.choiceCountry && this.choiceCountry.length != 0 ? this.partResult = this.getClone(datas) : this.partResult = this.getClone(data)
        // window.localStorage.setItem('datatable',JSON.stringify(this.partResult))
        // return;
        // this.partResult = JSON.parse(window.localStorage.getItem('datatable'))
        this.partResult && this.partResult.records && this.partResult.records.map(item => {
          this.dataType.forEach(items => {
            if (item.intcls == items.type) item.miaoSu = items.name
          })
          if (!item.tmstatus) item.tmstatus = '空'
          item.alias = subStatusObj[item.tmstatus]
          return item
        })
        this.isReport = this.stateChange
        this.responseData = this.partResult
        if (this.partResult && this.partResult.tmstatuscodeList && this.partResult.tmstatuscodeList.length) {
          this.partResult.tmstatuscodeList = this.partResult && this.partResult.tmstatuscodeList && this.partResult.tmstatuscodeList.map(item => {
            return {
              ...item,
              statusName: statusObj[item.name]
            }
          })
        }
        this.allType = this.partResult

        // $(window).unbind('scroll', this.scrollFn);
        // $(window).scroll(this.scrollFn);
        this.allType && this.allType.records && this.allType.records.map(item => {
          item.showName = item.tmapplicant || item.tmapplicant_en || item.applicant_en || item.applicantEn;
          item.showAdress = item.tmaddress || item.tmaddress_en || item.address_en || item.addressEn;
          // item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
          item.showTrademarkName = (item.tmcn && item.tmen ? item.tmcn + "--" + item.tmen : item.tmcn || item.tmen);

          // 服务平台图片近似查询结果评分标准：
          // score<=85 高，
          // score>85 and score<=250 中，
          // score>250 低，

          // 服务平台文字近似查询结果评分标准：
          // score>=80 高，
          // score>=20 and score<80 中，
          // score<20 低

          // let level = "";
          // if (this.queryModes == 'image') {
          //   if (parseInt(item.score) <= 85) level = '高';
          //   else if (parseInt(item.score) > 85 && parseInt(item.score) <= 250) level = '中';
          //   else if (parseInt(item.score) > 250) level = !this.isCN ? 'Low' : '低';
          // } else if (this.queryModes == 'text') {
          //   if (parseInt(item.score) >= 80) level = '高';
          //   else if (parseInt(item.score) >= 20 && parseInt(item.score) < 80) level = '中';
          //   else if (parseInt(item.score) < 20) level = '低';
          // }
          item.level = item.similarity;
          //如果选择的国家是中国大陆
          if (item.country == 'CN') {
            if (item.tmdetail) {
              item.showTmdetail = item.tmdetail
            } else item.showTmdetail = ''
          } else {
            if (item.tmdetailEn) {
              let str = ''
              item.showTmdetail = item.tmdetailEn || [];
              if (item.showTmdetail.length) {
                item.showTmdetail = item.showTmdetail.replace(/\\./g, '.')
                item.showTmdetail = item.showTmdetail.replace(/	/g, '.')
                item.showTmdetail = JSON.parse(this.jsonStringConvert(item.showTmdetail));
                for (var i = 0; i < item.showTmdetail.length; i++) {
                  for (var key in item.showTmdetail[i]) {
                    if (parseInt(key)) {
                      item.showTmdetail[i].niceCode = key;
                      item.showTmdetail[i].value = item.showTmdetail[i][key];
                    } else {
                      item.showTmdetail[i].niceCode = item.showTmdetail[i].niceCode;
                      item.showTmdetail[i].value = item.showTmdetail[i].value;
                    }
                    break;
                  }
                }
              }
              item.showTmdetail.map(item => {
                str += item.value
              })
              item.showTmdetail = str
            } else item.showTmdetail = ''
          }
          // item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
        });

        this.noData = false
        localStorage.setItem("douxing", JSON.stringify(this.arr.concat(this.allType.records).map(item => item.id)));
        localStorage.setItem("douguo", JSON.stringify(this.arr.concat(this.allType.records).map(item => item.country)));
        this.$set(this.resultList, 'checked', false)
        this.total = this.allType.total;



        this.allType.records = this.allType.records.map(item => {
          if (item.imagecode && item.imagecode != '') {
            try {
              item.imagecodeList = JSON.parse(item.imagecode)
            } catch (e) {
              item.imagecodeList = item.imagecode
            }
          }
          return item
        })


        // this.tableData = imageCodeList


        this.resultBool = true;
        this.gridOptions.data = this.allType.records
        this.lastPage = this.allType.pages
        //备注数据
        this.backupData = this.allType.records
        if (this.gridOptions.data.length > 0) {
          this.show = false
          this.isCharts = true
        } else {
          this.show = false
          this.isCharts = false
        }


      }
      ,
      inputFn() {
        if (this.value == 4) this.value = 2;
        if (this.value === "") this.value = 2;
      }
      ,
      handleRemove() {
        this.base64Url = "";
        this.imageUrla = "";
        this.$refs.upload?.clearFiles();
      },
      uploadChangeFn(file, fileList) {
        //JPG/JEPG/PNG/GIF/WEBP/BMP
        const isImage =
          file.raw.type == "image/png" ||
          file.raw.type == "image/jpg" ||
          file.raw.type == "image/jpeg" ||
          file.raw.type == "image/gif" ||
          file.raw.type == "image/bmp" ||
          file.raw.type == "image/webp";
        if (fileList && fileList.length > 1) {
          fileList.splice(0, 1);
        }
        if (fileList[0].name.length > 15) {
          fileList[0].name = fileList[0].name.slice(0, 15) + '...'
        }
        let imgSize = Number(file.size / 1024 / 1024);

        if (imgSize > 5) {
          this.$message('文件大小不能超过5MB，请重新上传');
          this.materialList[scope.$index].fileList.splice(scope.index, 1);
          return;
        }

        if (!isImage) {
          this.$message.error("目前只支持上传JPG/JEPG/PNG/GIF/WEBP/BMP格式");
          return;
        }
        this.imageUrla = URL.createObjectURL(file.raw);
        this.blobToDataURL(file.raw, (base64) => {
          this.base64Url = base64;
          this.pageNo = 1;
          this.pageNos = 1;
          this.value = 4;
          this.show = true
          //图形要素查询不清空
          this.switchTypeId == 1 ? this.searchText = "" : '';
          // this.queryModes = "image";
          // this.graphSearch_FromDB()

          // this.getJurisdictionAjaxImage();
        });
      }
      ,
      uploadChangeFns(file, fileLists) {
        this.imgUplad = file.url;
        this.blobToDataURL(file.raw, (base64) => {
          this.base64Url = base64;
          this.pageNo = 1;
          this.pageNos = 1;
          this.value = 4;
          //图形要素查询不清空
          this.switchTypeId == 1 ? this.searchText = "" : '';
          // this.queryModes = "image";
          // this.graphSearch_FromDB()

          // this.getJurisdictionAjaxImage();
        });
      }
      ,
      // 下载
      downEvent() {
        //   this.$nuxt.$loading.start()
        this.$axios.post('common/query/exportExcel', {
          keywork: this.searchText,
          queryWay: this.value !== "" ? this.firstList[this.value].queryWay : undefined,
          niceList: this.searchCrossFlag ? this.niceCrossChecked || undefined : this.niceChecked || undefined,
          param: this.value !== "" ? this.firstList[this.value].param : undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange
        }, {
          responseType: 'blob'
        }).then(({code, data, msg}) => {
          // this.$nuxt.$loading.finish()
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '查询结果.xls'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
      },
      //切换查询类型 保存查询条件
      saveQueryCriteria(newValue, oldValue) {
        let that = this
        let getCheckedFilters = this.$refs.xGrid?.getCheckedFilters().map(item => item.property)
        let indexArr = this.gridOptions.columns.map(item => item.field)
        this.$nextTick(() => {
          const $table = this.$refs.xGrid
          if (this.tableValue.length > 0) {
            this.$nextTick(() => {
              document.getElementsByClassName("vxe-tools--operate")[0] && document.getElementsByClassName("vxe-tools--operate")[0].classList.add('active');
            })

          } else {
            this.$nextTick(() => {
              document.getElementsByClassName("vxe-tools--operate")[0] && document.getElementsByClassName("vxe-tools--operate")[0].classList.remove("active")
            })
          }
          if ($table?.getColumnByField('intcls')) {
            if ($table) {
              $table.setFilter($table?.getColumnByField('intcls'), this.gridOptions.columns[indexArr.indexOf('intcls')].filterRender.options = this.filterDate.intclsList ? this.filterDate.intclsList.map(function (item) {
                return {
                  "label": `${item.name}(${item.value})`,
                  "value": item.name,
                  checked: getCheckedFilters.filter(item => item.property == 'intcls').length > 0 && getCheckedFilters.filter(item => item.property == 'intcls')[0].values.includes(item.name)
                }
              }) : [])
            }
            // if(getCheckedFilters.includes('intcls'))
            // else this.gridOptions.columns[3].filters[0].checked = false
          }
          if (this.allType.records && this.allType.records.length > 0 && this.allType.records.filter(item => item.tmstatus == '空').length > 0) {
            if ($table?.getColumnByField('tmstatus')) {
              if ($table) {
                this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options = this.filterDate.tmstatuscodeList ? this.filterDate.tmstatuscodeList.map((item) => {
                  return {
                    "label": statusObj[item.name],
                    "value": statusObj[item.name],
                    checked: getCheckedFilters.filter(item => item.property == 'tmstatus').length > 0 && getCheckedFilters.filter(item => item.property == 'tmstatus')[0].values.includes(item.name)
                  }
                }) : []
                this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options.push({
                  "label": '空',
                  "value": '空'
                })

                $table.setFilter($table?.getColumnByField('tmstatus'), this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options)
              }
            }
            // if(getCheckedFilters.includes('tmstatus'))  this.gridOptions.columns[10].filters[0].checked = true
            // else this.gridOptions.columns[10].filters[0].checked = false
          } else {
            if ($table?.getColumnByField('tmstatus')) {
              if ($table) {
                $table.setFilter($table?.getColumnByField('tmstatus'), this.gridOptions.columns[indexArr.indexOf('tmstatus')].filterRender.options = this.filterDate.tmstatuscodeList ? this.filterDate.tmstatuscodeList.map((item) => {
                  return {
                    "label": statusObj[item.name],
                    "value": statusObj[item.name],
                    checked: getCheckedFilters.filter(item => item.property == 'tmstatus').length > 0 && getCheckedFilters.filter(item => item.property == 'tmstatus')[0].values.includes(item.name)
                  }
                }) : [])
              }
            }
          }
          if ($table) {
            if(this.switchTypeId == 5){
            }else {
              $table.setFilter($table?.getColumnByField('countryZhName'), this.gridOptions.columns[indexArr.indexOf('countryZhName')].filterRender.options = this.filterDate.countryList ? this.filterDate.countryList.map(function (item) {
                return {
                  "label": that.getCountryName(item.name),
                  "value": that.getCountryName(item.name),
                  checked: getCheckedFilters.filter(item => item.property == 'countryZhName').length > 0 && getCheckedFilters.filter(item => item.property == 'countryZhName')[0].values.includes(item.name)
                }
              }) : [])
            }
          }
          this.$refs.xGrid && this.$refs.xGrid.updateData()
        })
        // }
      }
    },
    computed: {
      ...
        mapState("query", [
          "queryItemsShow",
          "queryData",
          "ajaxResponse",
          "othersItem",
          "pageNum_",
          "pageSize_",
          "noReset",
          "queryBtnClickNum",
          "statusList",
          "countryStatusVuex",
          'me',
          'trialList'
        ]),
      ...
        mapGetters("query", ["classifyChoosed", "classifyStatus"]),
      checkedIds(){
        return this.checkedId.map(item => item.id)
      },
      sameMessages(){
        return this.sameMessage.filter(item => item.color == 1 || item.color == 2)
      },
      getIsAllChecked() {
        //下面这行为获取table组件中的全选checkbox的勾选状态
        return this.$refs.multipleTable.store.states.isAllSelected
      },
      //是否是复合群组
      isCompound(){
        if(this.niceList.filter(item => item.isSimilar).length == 0 && this.compoundArr.length > 0){
          return 1;
        }else if(this.niceList.filter(item => item.isSimilar).length > 0 && this.compoundArr.length == 0){
          return 2;
        }else if(this.niceList.filter(item => item.isSimilar).length > 0 && this.compoundArr.length > 0){
          return 3;
        }else {
          return 4;
        }

      },
      //返回表格数据
      tableValue() {
        return this.gridOptions.data
      },
      //控制
      //输入类型提示
      returnTip() {
        if (this.base64Url && this.switchTypeId == 1) {
          return ''
        } else if (!this.base64Url && this.switchTypeId == 1 && !this.searchText.trim()) {
          switch (this.value) {
            case 2:
              return '在此处输入商标名称';
              break;
            case 5:
              return '在此处输入申请号';
              break;
            case 3:
              return '在此处输入申请人';
              break;
            case 6:
              return '在此处输入代理机构';
              break;
            case 7:
              return '在此处输入图形要素，例如3.2.12a，以“；”为间隔'
              break;
            case 8:
              return '在此处输入要查询的商品服务名称'
              break;
            default:
              return '在此处输入商标名称';
              break;
          }
        }  else if (this.switchTypeId == 4) {
          if (this.announcementFlag == 0) return '      搜索申请号查询公告/搜索公告期号，直达公告详情'
          else return '      搜索申请号查询公告/搜索公告期号，直达公告详情'
        }
      }
      ,
      //返回id
      returnId() {
        return this.gridOptions.data[0].id
      }
      ,
      //是否显示
      isShow() {
        let show = this.collectTitle[0].show
        return show
      }
      ,
      //筛选条件
      conditions() {
        let asideForms = {}
        if (Object.keys(this.asideForms).length == 0) {
          return undefined
        }
        for (let k in this.asideForms) {
          asideForms[k] = this.asideForms[k].join(';')
        }
        return asideForms;
      }
      ,
      //排序筛选条件
      orderCond() {
        if (Object.keys(this.orderConditions).length == 0) return undefined
        else return this.orderConditions
      },
      viennaChoosedStr() {
        let imageType = "";
        if (this.queryData.viennaChoosedArr) {
          this.queryData.viennaChoosedArr.map((item, index) => {
            imageType +=
              item +
              (index == this.queryData.viennaChoosedArr.length - 1 ? "" : ";");
          });
          this.queryItem.patternElement.value = imageType;
        }
        return imageType;
      }
      ,
      niceChoosedStr() {
        /*0124临时代码*/
        let imageType = "";
        this.queryData.classify.map((item, index) => {
          if (item.isCheck)
            imageType += item.no + (index == this.niceChoosedArr.length - 1 ? "" : ";");
        });
        this.queryItem.classify.value = imageType;
        return imageType;
      }
      ,
      ...
        ['country'].reduce((obj, cur) => {
          obj[queryParamsMap[cur]] = {
            get() {
              return this.$store.state.query.queryData[cur]
            },
            set(v) {
              // 使用vuex中的mutations中定义好的方法来改变
              this.queryDataFn({...this.$store.state.query.queryData, [cur]: v})
            }
          }
          return obj;
        }, {}),
      //----------
      files() {
        return this.base64Url
      }
      ,
      index1() {
        return this.asia.length
      }
      ,
      index2() {
        return this.europe.length
      }
      ,
      index3() {
        return this.america.length
      }
      ,
      index4() {
        return this.south.length
      }
      ,
      index5() {
        return this.africa.length
      }
      ,
      index6() {
        return this.oceania.length
      }
      ,
      index7() {
        return this.property.length
      }
      ,
      //不关联查询交叉类型
      niceChecked() {
        return this.niceList && this.niceList.filter(item => item.isCheck).map(item2 => item2.categoryNo).join(";");
      }
      ,
      //关联查询 交叉类型
      niceCrossChecked() {
        return this.niceList && this.niceList.filter(item => item.isCheck || item.isSimilar).map(item2 => item2.categoryNo).join(";");
      }
      ,
      tmStatusChecked() {
        return this.tmStatusList.filter(item => item.isCheck).map(item2 => item2.code).join(";");
      }
      ,
      //所有国家
      allCountry() {
        return this.$t(`PQ.cityOptions`).concat(this.$t(`PQ.city`), this.$t(`PQ.cityOptionsd`), this.$t(`PQ.cityOptionsds`), this.$t(`PQ.cityOpts`), this.$t(`PQ.cityds`), this.$t(`PQ.propertyOrganization`))
      }
      ,
      //国家国旗
      nationalFlag() {
        let str = ''
        str = this.asia.concat(this.europe, this.america, this.south, this.africa, this.oceania, this.property) && this.asia.concat(this.europe, this.america, this.south, this.africa, this.oceania, this.property).length > 1 ? '/images/national/colorGol.png' : this.allCountry.map(item => {
          if (item.code == this.asia.concat(this.europe, this.america, this.south, this.africa, this.oceania, this.property)[0])
            return item.ingSrc
        }).join('')
        return str
      }
      ,
      //国家个数发生改变
      stateChange() {
        return this.asia.concat(this.europe, this.america, this.south, this.africa, this.oceania, this.property).join(';')
      }
    },
    watch: {
      dialogAnalysistVisible(newVal,oldVal){
        this.chatBox = document.getElementById('chatBox'); // 假设聊天框的 ID 是 chatBox
        if(newVal) {
          clearInterval(this.intervalId);
          // 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
        }else {
          // this.intervalId = setInterval(() => {
          //   const isAtBottom = this.chatBox.scrollTop + this.chatBox.offsetHeight >= this.chatBox.scrollHeight;
          //   if (!isAtBottom ) {
          //     this.chatBox.scrollTop += 50;
          //   } else {
          //     // 如果已经到底部，可以清除定时器，或进行其他操作
          //     this.automaticPagination(true)
          //   }
          // }, 1000);
          // this.chatBox.addEventListener('scroll', () => {
          //   const isAtTop = this.chatBox.scrollTop === 0;
          //
          //   if (isAtTop) {
          //     console.log('div 已经滚动到顶部');
          //     // 可以在这里执行一些操作，比如显示提示、加载更多内容等
          //     this.automaticPagination(false)
          //   }
          // });
        }
      },
      isModeTable(newVal) {
        if(newVal) {
          this.chatBox = document.getElementById('chatBox'); // 假设聊天框的 ID 是 chatBox
          clearInterval(this.intervalId);
          // 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
        }
      },
      UloaddialogTableVisible(newVal,oldVal){
        if(newVal) {
          this.imageText = ''
          this.imageUrla = ''
          this.base64Url = ''
        }
      },
      input4(newVal,oldVal){
        if(newVal){
          this.radio2 = undefined
        }else {
          if(!this.radio2) this.radio2 = '3'
        }
      },
      radio2(newVal,oldVal){
        if(newVal){
          this.input4 = undefined
        }
      },
      dialogTableVisibles(newVal,oldVal){
        if(newVal){
          this.input3 = ''
          this.input4 =  ''
          this.radio1 = '无效公告'
          this.radio2 = '3'
          this.radio3 = ['因异议无效']
          //无效原因的箭头
          this.arrow1 = true,
            this.isFlag = true

        }

      },
      radio1(newVal,oldVal){
        if(newVal == '无效公告'){
          this.isFlag = true
          this.radio3 = ['因异议无效']
        }else if(newVal == '注册商标宣告无效公告'){
          this.isFlag = false
          this.radio3 = []
        }
      },
      isCompound(){
        this.$nextTick(() => {
          this.$refs.multipleTable && this.$refs.multipleTable.doLayout();
        })
      },
      searchText(newValue, oldValue){
        // this.searchText = this.searchText.trim()
        if(newValue) {
          this.queryModes = "text"
          this.base64Url = ''
        }
        if(newValue < oldValue) return;
        if(this.switchTypeId == 1 || this.value == 7 || this.value == 8){
          if(this.searchText.trim().length > 50){
            this.$message('您查询内容的长度已超过限制，请确认是否输入正确，如无误，请分批查询');
            this.errFlag = true;
            return;
          }
        }else if( this.switchTypeId == 4 && this.announcementFlag == 0){
          //公告期号
          if(this.searchText.trim().length >4){
            this.$message('您查询内容的长度已超过限制，请确认是否输入正确，如无误，请分批查询');
            this.errFlag = true;
            return;
          }
        }else if( this.switchTypeId == 4 && this.announcementFlag == 1){
          //申请号
          if(this.searchText.trim().length > 50){
            this.$message('您查询内容的长度已超过限制，请确认是否输入正确，如无误，请分批查询');
            this.errFlag = true;
            return;
          }
        }
        this.errFlag = false;
      },
      stateChange(newValue, oldValue) {
        if(newValue.split(';').length > oldValue.split(';').length){
          this.getServicePermission()
        }
        if (this.$refs.xGrid) {
          let arr = newValue ? newValue.split(';') : []
          if (arr.length == 1 && arr.includes('CN')) {
            this.$refs.xGrid.hideColumn('applicantEn')
            this.$refs.xGrid.hideColumn('addressEn')
            this.$refs.xGrid.showColumn('tmapplicant')
            this.$refs.xGrid.showColumn('tmaddress')
          } else if (arr.length == 1 && !arr.includes('CN')) {
            this.$refs.xGrid.hideColumn('tmapplicant')
            this.$refs.xGrid.hideColumn('tmaddress')
            this.$refs.xGrid.showColumn('applicantEn')
            this.$refs.xGrid.showColumn('addressEn')
          } else if (arr.length > 1 && arr.includes('CN')) {
            this.$refs.xGrid.showColumn('tmapplicant')
            this.$refs.xGrid.showColumn('tmaddress')
            this.$refs.xGrid.showColumn('applicantEn')
            this.$refs.xGrid.showColumn('addressEn')
          } else if (arr.length > 1 && !arr.includes('CN')) {
            this.$refs.xGrid.hideColumn('tmapplicant')
            this.$refs.xGrid.hideColumn('tmaddress')
            this.$refs.xGrid.showColumn('applicantEn')
            this.$refs.xGrid.showColumn('addressEn')
          }
        }
        if (newValue && newValue.split(";").length >= 43) {
          this.$message('温馨提示：如选择43国一并查询，可能会因数据过多而导致查询时间过长或查询不出。如出现类似情况，建议您分批查询。');
        }
      },
      tableValue(newValue, oldValue) {
        if (newValue.length) {
          this.isModeTable ? document.getElementsByClassName("vxe-tools--operate")[0] && document.getElementsByClassName("vxe-tools--operate")[0].classList.add('active') : '';
        } else {
          this.isModeTable ? document.getElementsByClassName("vxe-tools--operate")[0] && document.getElementsByClassName("vxe-tools--operate")[0].classList.remove("active") : ''
        }
      },
      value(newValue,old) {
        this.ifShowAnalysisIcon = false
        if (newValue == 7) {
          this.show = false
          this.combinedquery = true
          this.query_Items = this.$t(`PQ.query_Itemsd`)
          this.total = this.allType.total;
          return
        }
        else if(newValue == 8){
          this.show = false
          this.combinedquery = false
          this.total = this.allType.total;
          return;
        }

        if (newValue == 5 || newValue == 3 || newValue == 6) {
          this.$refs.xGrid?.hideColumn('level')
        }
        else {
          this.$refs.xGrid?.showColumn('level')
        }
        if(newValue != 2){
          this.sameMessage = []
        }

        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if((newValue == 3 || newValue == 6) && this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      asia(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      europe(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      america(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      south(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      africa(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      oceania(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      property(newValue, old){
        let countryCount =
          this.asia.length +
          this.europe.length +
          this.america.length +
          this.south.length +
          this.africa.length +
          this.oceania.length +
          this.property.length
        //控制输入框是否具有自动补全功能
        if(this.asia[0] == 'CN' && countryCount == 1){
          this.ifSearchInputAutoComplate = true
        }
        else{
          this.ifSearchInputAutoComplate = false
        }
      },
      wholeText(newValue) {
        if (newValue == '准') {
          this.$refs.xGrid?.hideColumn('level')
        } else {
          this.$refs.xGrid?.showColumn('level')
        }
      },
      isModeTable(newValue, oldValue) {
        this.saveQueryCriteria(newValue, oldValue)
      },
      switchTypeId(newValue, oldValue) {
        this.ifShowAnalysisIcon = false
        if(newValue == 5 || newValue == 4){
          this.trademarkFlag = false
          this.invalidAnnouncement = false
          this.announcement.data = []
        }
        this.sameMessage = []
        if (newValue == 5){
          this.searchText = ''
          // this.extensionFlag = true
          this.value = 3
          this.isModeTable = true
          clearInterval(this.intervalId);
          // 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
          this.cancelAll();
          this.asia = ['CN']
          this.extensionFlag = false
          this.extensionFlag1 = false,
            this.handleCheckedCitiesChange(['CN'])
          this.gridOptions.columns =[
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'imageUrl', width: 120, title: '商标图案', slots: {
                // 使用插槽模板渲染
                default: 'imageUrl',
              }
            },
            {
              field: 'sbmc',
              title: '商标名称'
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'intcls',
              title: '类别',
              visible: false,
              width: 160,
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'appno', title: '申请号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'tmapplicant',
              title: '申请人名称',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人名称'}
              }
            },
            {
              field: 'applicantEn',
              title: '申请人名称(英文)',
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人名称'}
              }
            },
            {
              field: 'tmaddress', title: '申请人地址',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'addressEn', title: '申请人地址(英文)', visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              sortable: true,
              visible: false,
              sortType: 'string',
              field: 'appdate',
              title: '申请日',
              filters: [{data: null}],
              formatter: ({row}) => row.appdate,
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'showTmdetail',
              title: '商品服务',
              width: 160,
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'level', title: '近似度',
              width: 100, visible: false, slots: {
                // 使用插槽模板渲染
                default: 'degree',
              }
            },
            {
              field: 'similargroup', title: '类似群组', visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入类似群组'}
              }
            },
            {
              field: 'tmagent', title: '代理机构', visible: true,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入代理机构'}
              }
            },
            {
              field: 'tmstatus',
              title: '商标状态',
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择', checked: ['已注册']}
              }
            },
            {
              field: 'regno', title: '注册号', filters: [{data: null}],
              visible: false,
              filterRender: {
                name: 'ElInput',
                options: [],
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'ggdate',
              title: '注册公告日',
              width: 160,
              visible: true,
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'trialdate', title: '初审公告日', filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  startPlaceholder: '开始日期',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              field: 'countryZhName', title: '国家/地区'
            },
            {
              type: 'expand', title: '', visible: false, width: 160, slots: {
                // 使用插槽模板渲染
                content: 'num1_default',
              }
            },
          ]
          this.pageNoss = 1
          this.totals = 0
          this.extensionEvent()
          setTimeout(() => {
            this.saveQueryCriteria(newValue, oldValue)
          })
          return;
        }
        else {
          // this.extensionFlag = false
          this.gridOptions.columns = [
            {type: 'checkbox', width: '50px', fixed: 'left'},
            {
              field: 'imageUrl', width: 120, title: '商标图案', slots: {
                // 使用插槽模板渲染
                default: 'imageUrl',
              }
            },
            {
              field: 'sbmc',
              title: '商标名称'
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'intcls',
              title: '类别',
              width: 160,
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'appno', title: '申请号',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'tmapplicant',
              title: '申请人名称(中文)',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人名称'}
              }
            },
            {
              field: 'applicantEn',
              title: '申请人名称(英文)',
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人名称'}
              }
            },
            {
              field: 'tmaddress', title: '申请人地址(中文)',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'addressEn', title: '申请人地址(英文)', visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'appdate',
              title: '申请日',
              filters: [{data: null}],
              formatter: ({row}) => row.appdate,
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'ggdate',
              title: '注册公告日',
              width: 160,
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              field: 'showTmdetail',
              title: '商品服务',
              width: 160,
              visible: false,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'tmstatus',
              title: '商标状态',
              filters: [{data: null}],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择', checked: ['已注册']}
              }
            },
            {
              field: 'level', title: '近似度',
              width: 100, visible: false, slots: {
                // 使用插槽模板渲染
                default: 'degree',
              }
            },
            {
              field: 'similargroup', title: '类似群组', visible: true,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入类似群组'}
              }
            },
            {
              field: 'tmagent', title: '代理机构', visible: true,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                props: {placeholder: '请输入代理机构'}
              }
            },
            {
              field: 'regno', title: '注册号', filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                props: {placeholder: '请输入申请人地址'}
              }
            },
            {
              field: 'countryZhName', title: '国家/地区', filters: [{data: null}], filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: false, filterable: true, placeholder: '请选择'}
              }
            },
            {
              sortable: true,
              sortType: 'string',
              field: 'trialdate', title: '初审公告日', filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  startPlaceholder: '开始日期',
                  unlinkPanels: true,
                  valueFormat: "yyyy-MM-dd",
                  endPlaceholder: '结束日期'
                }
              }
            },
            {
              type: 'expand', title: '', visible: false, width: 160, slots: {
                // 使用插槽模板渲染
                content: 'num1_default',
              }
            },
          ]
          setTimeout(() => {
            this.saveQueryCriteria(newValue, oldValue)
          })
        }
        if (newValue == 4 && this.announcement.data.length > 0) {
          this.trademarkFlag = true
          this.invalidAnnouncement = true
        }
        else {
          this.trademarkFlag = false
          this.invalidAnnouncement = false
        }
        if (newValue == 4 ) {
          this.pageNoss = 1
          this.totals = 0
          this.announcements.data = []
        }
        this.base64Url = '';
        this.searchText = ''
        this.imageUrla = ''
        this.imgUplad = ''
        this.fileList = []
        this.value = 2
        // this.trademarkFlag = false
        this.resetSizer(false)
        if (newValue == 1) {
          setTimeout(() => {
            this.saveQueryCriteria(newValue, oldValue)
          })
        }
        if (newValue == 2) {
          this.$refs.xGrid?.hideColumn('level')
        }
        // if (newValue != 1) {
        //   this.isRetrieval = false
        // } else {
        //   this.isRetrieval = true
        // }
        if (newValue == 1 || newValue == 2) {
          if (this.tableValue.length) {
            this.$nextTick(() => {
              document.getElementsByClassName("vxe-tools--operate")[0].classList.add('active');
            })
          } else {
            this.$nextTick(() => {
              document.getElementsByClassName("vxe-tools--operate")[0].classList.remove("active")
            })
          }
        }
        if (newValue == 3) {
          this.isModeTable = true
          clearInterval(this.intervalId);
          // 卸载事件监听器
          window.removeEventListener('scroll', () => {
            const isAtTop = this.chatBox.scrollTop === 0;

            if (isAtTop) {
              console.log('div 已经滚动到顶部');
              // 可以在这里执行一些操作，比如显示提示、加载更多内容等
              this.automaticPagination(false)
            }
          });
          this.cancelAll();
          this.asia = ['CN']
          this.handleCheckedCitiesChange(['CN'])
          this.pageNoss = 1
          this.totals = 0
          this.gridOption.data = []
        }
      },
      //choiceCountry
      choiceCountry: {
        handler(newValue, oldValue) {

          if (this.allResult && this.allResult.records && newValue && newValue.length != 0) {
            if (this.allResult.records && this.allResult.records.length != 0) {
              let arr = JSON.parse(JSON.stringify(this.allResult))
              arr.records = this.allResult.records.filter(item => newValue.includes(item.country))
              this.searchHandle([], arr)
            }
          } else {
            if (this.allResult && this.allResult.records && this.allResult.records.length) this.searchHandle(this.allResult, [])
          }
        }
        ,
        deep: true
      },
      $route() {
        this.tag = this.$route.query.tag
      },
      tag(val) {
        this.ifShowAnalysisIcon = false
        if (val == 1 || val == undefined) {
          //浏览器刷新页面
          this.$router.go(0);
        }
      },
      queryModes(val) {
        this.ifShowAnalysisIcon = false
        if (val != "") {
          this.tag = 2;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  ::-webkit-scrollbar {
    width: 4px
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px
  }

  ::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, .4)
  }

  ::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, .1)
  }

  .activeVisible {
    /deep/ .el-dialog {
      background: transparent;
      -webkit-box-shadow: none;
    }
  }

  // 滚动条的宽度
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }

  ///deep/.vxe-grid--toolbar-wrapper{
  //  height: 65px;
  //}

  // 滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, .1);
    border-radius: 10px
  }

  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, .1)
  }

  // 滚动条的宽度
  /deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }

  // 滚动条的滑块
  /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, .1);
    border-radius: 10px
  }

  /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, .1)
  }

  /deep/ .vxe-table--filter-wrapper {
    min-width: 165px
  }

  //result-ico
  // 滚动条的宽度
  .result-ico::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }

  // 滚动条的滑块
  .result-ico::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, .1);
    border-radius: 10px
  }

  .result-ico::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, .1)
  }

  .scroll_bar /deep/ .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

  .dialogAnalysist {
    .title {
      display: flex;
      align-items: center;
      width: 452px;
      height: 45px;
      background: #D9E3FF;
      border-radius: 5px 5px 5px 5px;
      img {
        margin-left: 10px;
        width: 22px;
        height: auto;
        margin-right: 5px;
      }
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }

    .checkbox {
      display: flex;
      .allCheckbox {
        display: flex;
        align-items: start;
        position: relative;
        &:before {
          position: absolute;
          right: 20px;
          display: block;
          content: '';
          width: 1px;
          height: 22px;
          background: #D9E3FF;
        }
        .el-checkbox {
          width: 95px;
          margin-bottom: 10px;
        }
      }
      .el-checkbox {
        width: 95px;
        margin-bottom: 10px;
      }
    }

    .line {
      width: 639px;
      height: 2px;
      background: #9A9FCE;
      margin-top: 24px;
      margin-bottom: 27px;
    }

    .el-radio {
      .el-radio__label {
        font-weight: 400;
        font-size: 12px;
        color: #000000;
      }
    }

    .dialog-footer {
      text-align: center;
      .generateAnalysist {
        width: 126px;
        height: 45px;
        background: #2E54D9;
        border-radius: 8px 8px 8px 8px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
      }
    }
  }

  .high-grade-query {
    margin: 0 !important;
    width: auto !important;

    &.high-grade-query-en {
      .query-item {
        /*margin-bottom: 0;*/

        & > * {
          display: block;
        }

        .title {
          width: 100%;
          height: 32px;
          line-height: 32px;

        }

        .container {
          width: 100%;

          .choose {
            width: 100%;

            .leftText {
              width: 200px;
              height: 100%;
              font-size: 14px;
            }

            .rightText {
              width: 130px;
              text-align: center;
            }
          }

          .el-date-editor-long-officialt.el-date-editor {
            width: 300px;

            &.is-disabled {
              background: #eeeeee;
            }
          }
        }
      }
    }

    /*width: 460px;*/
    margin: 0 auto;
    padding-bottom: 30px !important;
    /*margin-top: 50px;*/
    box-sizing: border-box;

    .toHigh, .toFast {
      margin: 0 auto;
      /*width: 134px;*/
      cursor: pointer;

      & > * {
        display: inline-block;
      }

      &.mt320 {
        margin-top: 327px;
      }

      .el-icon-arrow-right, .el-icon-arrow-left {
        width: 24px;
        height: 24px;
        background: #cfcfcf;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        vertical-align: middle;
      }

      i:hover {
        background-color: #3a6ecf;
      }

      span {
        font-size: 16px;
        letter-spacing: 1px;
        color: #333333;
        vertical-align: middle;
      }
    }

    .toFast {
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 2;
    }

    .toFast:hover {
      i {
        background-color: #3a6ecf;
      }

      span {
        color: #3a6ecf
      }
    }

    .query-item {
      font-size: 0;
      margin-bottom: 15px;
      position: relative;

      .el-icon-delete {
        &:hover {
          color: red !important;
        }
      }

      & > * {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }

      .title {
        width: 125px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333333;

        /deep/ .el-input__inner {
          background: rgba(224, 228, 244, 0.6);
          height: 40px;
          font-size: 14px;
          border: 1px solid #AFBFF0;
          font-weight: 400;
          color: #626674;
          border-radius: 15px 0 0 15px !important;
        }
      }

      .container {
        width: 362px;

        /deep/ .el-input__inner {
          border-radius: 0 15px 15px 0 !important;
          background: rgba(224, 228, 244, 0.6);
          height: 40px;
          border: 1px solid #AFBFF0;
          font-size: 14px;
          font-weight: 400;
          color: #C8C7C7;
        }

        .date_customized {
          /deep/ .el-input__inner {
            border-radius: 15px 15px 15px 15px !important;
          }
        }

        .el-radio-group {
          display: flex;
          align-items: center;
          height: 40px;
          margin-left: 15px;
        }

        .el-select {
          .el-input--suffix {
            width: 300px;
          }
        }

        .queryModes {
          margin-top: 12px;
        }

        .upload-drag {
          /deep/ .el-upload-dragger {

            height: 73px;
          }

          border: 1px solid #d8dce5;
          height: 73px;

          &.uploadDisabled {
            position: relative;
            border: 0;

            .el-upload {
              background: #eeeeee;

              /deep/ .el-upload-dragger {
                p {
                  color: #c0c4cc;
                }

                height: 73px;

                .el-upload-disabled-shade {
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  /*background: rgba(255, 238, 245, 0.6);*/
                  top: 0;
                  right: 0;
                  cursor: not-allowed;
                }
              }
            }
          }

          .el-upload {
            width: 100%;
            height: 100%;
            background: transparent;
            display: flex;
            /deep/ .el-upload-dragger {
              border: none !important;
              width: 100%;
              height: 100%;
              height: 73px;
              background: transparent;
              position: relative;
              /*padding: 10px;*/

              .upload-btn {
                width: 38px;
                height: 36px;
                margin-top: 10px;
              }

              .upload-img {
                height: 96%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }

              p.delete {
                position: absolute;
                z-index: 5;
                right: 10px;
              }

              p {
                margin-top: 5px;
                font-size: 12px;
                letter-spacing: 1px;
                color: #999999;

                &.delete:hover {
                  color: #0b6ce8;
                }
              }

              .el-upload-disabled-shade {
                display: none;
              }
            }
          }
        }

        /*图形要素呢内*/

        .choose {
          /*background: #eeeeee;*/
          /*border: 1px solid #d8dce5;*/
          width: 500px;
          height: 36px;
          border-radius: 5px;
          font-size: 0;
          /*padding: 5px 0px;*/
          line-height: 36px;
          font-size: 0;

          & > * {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            letter-spacing: 1px;
          }

          span.leftText {
            width: 436px;
            background: transparent;
            font-size: 12px;
            height: 36px;
            display: inline-block;
            padding: 0 15px;
            box-sizing: border-box;
            line-height: 36px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .rightText {
            font-size: 14px;
            color: #666666;
            /* height: 100%; */
            width: 65px;
            margin-left: 5px;
            line-height: 34px;
            border-radius: 3px;
            margin-top: 2px;
          }
        }

        .date {
          font-size: 0;

          & > * {
            font-size: 12px;
            vertical-align: middle;
          }

          .to {
            width: 40px;
            display: inline-block;
            text-align: center;
            font-size: 14px;
            letter-spacing: 1px;
            color: #333333;
          }
        }
      }
    }

    .buttons {
      overflow: hidden;
      text-align: left;

      .el-checkbox {
        margin-left: 40px;
      }

      .el-button {
        float: right;

        &.return-btn {
          margin-right: 20px;
        }
      }
    }

    .long-dialog-old.el-dialog__wrapper {
      text-align: center;

      .el-dialog {
        width: auto;
        display: inline-block;
      }

      .el-dialog__header {
        padding: 0;
        height: 0px;
        display: inline-block;

        .el-icon-close {
          top: 0;
          right: 0;
          font-size: 20px;
        }
      }

      .el-dialog__body {
        padding: 0;
        display: inline-block;
      }

      .long-inner {
        width: 740px;
        border-radius: 30px;
        text-align: left;

        &.long-inner-viena {
          width: 950px;
        }

        .top {
          height: 53px;
          line-height: 53px;
          font-size: 14px;
          letter-spacing: 1px;
          color: #666666;
          padding: 0px 50px;
          box-sizing: border-box;
          border-bottom: 1px solid #cecece;
          background-color: #efefef;
          border-radius: 3px;

          i {
            color: #e96364;
          }

          span {
            margin-left: 12px;
          }
        }

        /*尼斯分类*/

        .middle.nice-style {
          height: 530px;
          overflow: auto;

          .first-ul {
            font-size: 13px;

            .first-li {
              padding: 0;
              text-indent: 50px;

              .first-p {
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                letter-spacing: 0px;
                color: #333333;

                &:hover {
                  background: #f7f7f7;
                }

                .el-checkbox {
                  text-indent: 0;
                  //复选框样式见long.scss
                }
              }

              .second-ul {
                .second-li {
                  padding: 0;
                  text-indent: 50+18px;

                  &:nth-last-of-type(1) {
                    padding-bottom: 0;
                  }

                  .second-p {
                    cursor: pointer;
                    height: 34px;
                    line-height: 34px;

                    &:hover {
                      background: #f7f7f7;
                    }

                    .el-checkbox {
                      text-indent: 0;
                      //复选框样式见long.scss
                    }
                  }
                }
              }
            }
          }
        }

        .bottom {
          height: 94px;
          padding: 30px 54px;
          text-align: right;
          box-sizing: border-box;

          .el-button {
            height: 30px;
            width: 100px;
          }

          .viennaConfirm {
          }

          .viennaClose {
            margin-left: 22px;
          }
        }
      }
    }

    .el-input-long-officialt {
      width: 500px;
      height: 40px;
      border-radius: 0 4px 4px 0;

      &.is-disabled {
        input.el-input__inner {
          background-color: #eeeeee;
          border-color: #eeeeee;
          color: #c0c4cc;
          cursor: not-allowed;
        }
      }
    }

    .el-date-editor-long-officialt.el-date-editor {
      width: 145px;

      &.is-disabled {
        input.el-input__inner {
          background-color: #eeeeee;
          color: #c0c4cc;
          border: 0;
        }
      }
    }


    .el-select-long-officialt {
      width: 500px;
    }

    .el-long-white-button {
      &:hover {
        span {
          color: #ffffff;
        }
      }

      &.is-disabled {
        span {
          color: #c0c4cc;
        }
      }
    }

    .el-input {
      &.leftText {
        width: 430px;

        input {
          width: inherit;
          vertical-align: top;
          height: 40px;
          line-height: 40px;
          border: 0;
          background: transparent;
          border: 1px solid #d8dce5;
        }
      }

      /*.leftText .el-input__inner:focus{
                  border:1px solid #409eff!important;
              }*/
    }

    /*0124尼斯分类*/
    /*    section.classify {
            width: 100%;
            & > div {
              margin-left: 5px;
              width: 740px;
              height: 440px;
              padding: 35px 0 57px 0px;
              background-color: #ffffff;
              box-shadow: 0px 2px 18px 0px rgba(47, 47, 47, 0.18);
              border-radius: 5px;
              ul {
                text-align: left;
                li {
                  display: inline-block;
                  height: 35px;
                  width: 98px;
                  margin-left: 42px;
                  line-height: 35px;
                  font-size: 13px;
                  cursor: pointer;
                  color: #333333;
                  &:hover {
                    color: #eb4142;
                  }
                  &.checked {
                    color: #e25665;
                  }
                }
              }
              .buttons {
                margin-top: 25px;
                padding-right: 68px;
                overflow: hidden;
                .el-button {
                  float: right;
                  width: 90px;
                  height: 34px;
                  padding: 0;
                  font-size: 16px;
                  border-radius: 5px;
                  !*确定按钮*!
                  &:nth-of-type(1) {
                    border: solid 1px transparent;
                    background-color: #d01027;
                    color: #ffffff;
                    &:hover {
                      background-color: #c6293a;
                    }
                  }
                  !*取消按钮*!
                  &:nth-of-type(2) {
                    border: solid 1px #aaaaaa;
                    margin-right: 20px;
                    &:hover {
                      color: #ffffff;
                      background-color: #434554;
                    }
                  }
                }

              }
            }
          }*/

    .class-in-the-Nice-classification.high-classify {
      /*有序号有内容的时候*/
      /*      &.en {
                & > div {
                  padding: 35px 0 20px 0px;
                  ul {
                    height: 450px;
                  }
                }
              }*/
    }
  }
</style>
<style lang="less" scoped>
  .fullscreen_father {
    position: relative!important;
    .tool {
      position: absolute;
      top: 20px;
      right: 100px;
      z-index: 100;
    }
    .icon {
      display: block;
      position: absolute;
      top: -41px;
      right: 50px;
      cursor: pointer;
      z-index: 1002;
      .iconContent {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 126px;
        height: 45px;
        background: #2E54D9;
        border-radius: 8px 8px 8px 8px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-align: left;
        position: relative;
        &::before {
          top: 24px;
          right: 126px;
          display: block;
          position: absolute;
          content: '';
          width: 34px;
          height: 2px;
          background: #9AB0FF;
        }
        &::after {
          top: 19px;
          right: 158px;
          display: block;
          position: absolute;
          content: '';
          width: 9px;
          height: 9px;
          background: #FFFFFF;
          border-radius: 50%;
          border: 2px solid rgba(55,99,255,0.55);;
        }
      }
    }
    .fullscreen_title {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: -20px;
      z-index: 1002;
      justify-content: center;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      width: 384px;
      height: 51px;
      background: #D9E3FF;
      border-radius: 5px 5px 5px 5px;
      img {
        width: 22px;
        height: auto;
        margin-right: 6px;
      }
    }
  }
  /deep/.vxe-pager--jump {
    display: inline-block!important;
    align-items: center!important;
  }
  /deep/.vxe-table-custom-wrapper {
    top: -10px !important;
    right: 329px !important;
  }
  /deep/.applicantZhName {
    cursor: pointer;
    display: flex;
    img {
      display: none;
    }
    &:hover {
      img {
        display: block;
      }
    }
  }
  .categoryTypeVisible {
    .title {
      padding-left: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        top: 3px;
        content: '';
        width: 3px;
        height: 14px;
        background: #2F56DE;
      }
    }
    /deep/.el-table {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    /deep/._el_table_header {
      background: #e2efff;
      color: #606266;
      font-size: 14px;
      font-weight: 600;

    }
  }
  .table-icon-nodata {
    background: #EDF2FD!important;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 20px 10px 150px;
    height: 600px;
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
    &.fullscreens {
      background: white;
      .result-ico {
        height: 890px;
        /*/deep/.el-checkbox-group {*/
        /*  /deep/.el-popover__reference-wrapper {*/
        /*    padding: 0 30px;*/
        /*    box-sizing: border-box;*/
        /*  }*/
        /*}*/
      }
    }
  }

  .cluesSwitch {
    width: 100%;
    height: 48px;
    background: #DDDFEC;
    display: flex;
    border-radius: 8px 6px 0px 0px;
    li {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      width: 106px;
      font-weight: 400;
      font-size: 14px;
      color: #5F6264;
      position: relative;

      &.item {
        font-weight: 600;
        font-size: 14px;
        color: #101A75;
        border-radius: 6px 6px 0px 0px;
        background: #ffffff;
        overflow: hidden;
        span {
          display: block;
          position: absolute;
          width: 100%;
          height: 5px;
          background: #212BB0;
          top: 0;
        }
        &::before {
          content: none;
        }
      }
      &::before {
        position: absolute;
        right: 0;
        top: 20px;
        content: '';
        width: 2px;
        height: 11px;
        background: #939BB5;
      }
      &:nth-last-of-type(1)::before {
        position: absolute;
        right: 0;
        content: none;
        top: 20px;
        width: 2px;
        height: 11px;
      }
    }
  }

  .businessLeads {
    /deep/ .el-table {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;

      &.active .el-table__cell {
        padding: 5px 0 !important;
      }
    }

    /deep/ .background-row {
      background: rgb(243, 249, 254) !important;
    }

    /deep/ .el-table__empty-block {
      height: 700px !important;
    }

    /deep/ ._el_table_header {
      background: #e2efff;
      color: #606266;
      font-size: 14px;
      font-weight: 600;
      height: 50px;
    }

    /deep/ .businessLeadsClass {
      border-left: 1px solid #EBEEF5;
      .cell {
        padding: 0;
      }
    }

    /deep/.businessLeadsClass_back {
      background: rgb(243, 249, 254)!important;
    }
    /deep/.businessLeadsClass_white{
      background: #fff!important;
    }
    /deep/.businessLeadsClass2 {
      color: rgb(100, 131, 237);
    }
    /deep/.businessLeadsClass3 {
      border-left: 1px solid #EBEEF5;
      .cell {
        padding: 0;
      }
    }
    /deep/.businessLeadsClass4 {
      .cell {
        padding-left: 80px;
      }
    }
  }
  .table-icon {
    position: absolute;
    //z-index: 10;
    display: flex;
    top: 355px;
    &.active {
      top: 15px;
    }
    align-items: center;
    & > div {
      position: relative;
      z-index: 2;
    }

    .jumpReport {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -4px;
      margin-left: 17px;
      width: 38px;
      height: 34px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.18);
      border-radius: 2px 2px 2px 2px;
      position: relative;
      z-index: 2001;
      .icon {
        display: none;
      }
      &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -4px;
        margin-left: 17px;
        width: 38px;
        height: 34px;
        background: #D8DDEE;
        border-radius: 0px 0px 0px 0px;
        position: relative;
        .icon {
          display: block;
          position: absolute;
          top: -41px;
          .iconContent {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 24px;
            background: #292A2D;
            border-radius: 5px 5px 5px 5px;
            font-size: 10px;
            color: #FFFFFF;
            line-height: 12px;
            text-align: left;
            position: relative;
            &::before {
              top: 24px;
              display: block;
              position: absolute;
              content: '';
              width: 2px;
              height: 16px;
              background: #7C85A7;
            }
            &::after {
              top: 36px;
              display: block;
              position: absolute;
              content: '';
              width: 6px;
              height: 6px;
              background: #0636C6;
              border-radius: 50%;
            }
          }
        }
      }

    }

    div.table-icon_item1:nth-of-type(1) {
      position: relative;
      padding-right: 15px;

      &:after {
        position: absolute;
        right: 0;
        top: 3px;
        content: '';
        width: 2px;
        height: 18px;
        background: #193dbe;
      }
    }

    div:nth-of-type(2) {
      margin-left: 15px;
    }
  }

  .result-ico {
    font-size: 0;
    /*margin: 0 auto;*/
    box-sizing: border-box;
    margin-top: 20px;
    height: 580px;
    overflow-y: auto;
    cursor: pointer;
    div:nth-of-type(1) {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      border-radius: 8px;
      transition: all 1s ease-out;
      box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.05);
      width: 210px;
      height: 248px;
      /*border: solid 1px #c7c7c7;*/
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 40px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      box-sizing: border-box;

      dl {
        padding: 0 13px;

        dd:nth-of-type(1) {
          display: inline-block;
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          /*padding: 7px 11px;*/
          color: #0D419D;
          font-size: 12px;
          box-sizing: border-box;

          p:nth-of-type(1) {
            width: 115px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        dd:nth-of-type(2) {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          margin-top: 5px;
        }

        dd:nth-of-type(3) {
          width: 187px;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap
        }
      }

      &:hover {
        .see-big-img {
          display: block;
        }
      }

      .trademark-patterns {
        margin-top: 12px;
        padding: 0 15px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /*padding: 27px 33px 16px 33px;*/

        .trademark-pattern-img {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 198px;
          height: 112px;
          background: #FFFFFF;
          border-radius: 5px;

          .el-checkbox__label {
            display: none;
          }
        }

        .state {
          font-size: 14px;
          font-weight: 400;
          /*display: inline-block;*/
          padding: 4px 9px;
          border-radius: 5px !important;
        }

        .small-item {
          .text {
            width: calc(100% + 3px);
            font-size: 0;

            .text-level,
            .level-graphical {
              display: inline-block;
              vertical-align: top;
            }

            &.level-high {
              .text-level {
                color: #ff5a5a;
              }

              .level-graphical {
                /*background: #eb5657;*/
                background-image: url("@{imgUrl}query/levels.png");
                background-repeat: no-repeat;
                background-position: -120px 0;
              }
            }

            &.level-middle {
              .text-level {
                color: #fcbc41;
              }

              .level-graphical {
                /*background: #fcbc41;*/
                background-image: url("@{imgUrl}query/levels.png");
                background-repeat: no-repeat;
                background-position: -60px 0;
              }
            }

            &.level-low {
              .text-level {
                color: #23c994;
              }

              .level-graphical {
                /*background: #23c994;*/
                background-image: url("@{imgUrl}query/levels.png");
                background-repeat: no-repeat;
                background-position: 0 0;
              }
            }

            .text-level {
              margin-top: 3px;
              font-style: normal;
              font-size: 14px;
              letter-spacing: 1px;
              margin-left: 10px;
            }

            .level-graphical {
              width: 60px;
              height: 8px;
              display: inline-block;
              margin-top: 4px;
            }
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
        }
      }

      .trademark-pattern {
        margin-top: 4px;
        /*padding: 27px 33px 16px 33px;*/

        .trademark-pattern-img {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 198px;
          height: 112px;
          /*background: #FFFFFF;*/
          border-radius: 5px;
        }

        /*padding: 15px;*/
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        border-bottom: 1px solid #D0E4FD;;

        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
        }
      }

      .trademark-reg,
      .see-big-img {
        height: 30px;
        background-color: #616060;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #ffffff;
        text-align: center;
      }

      .trademark-reg {
        cursor: default;
      }

      .see-big-img {
        position: absolute;
        width: 100%;
        top: 0;
        display: none;
        z-index: 2;
        cursor: pointer;
      }

      .showbig {
        position: fixed;
        width: 800px;
        background: red;
        z-index: 999;
      }

      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.22);
        transform: scale(1.01);
      }
    }

    .el_checkbox {
      /deep/ .el-checkbox__label {
        display: none;
      }
    }

    &.pq_active {
      li {
        .trademark-pattern {
          padding: 27px 33px 16px 33px;

          .trademark-pattern-img {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 234px;
            height: auto;
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            border: 1px solid #92A5EE;
            box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.08);
            border-radius: 5px;

            .state {
              position: absolute;
              bottom: -1px;
              left: -1px;
              font-size: 14px;
              font-weight: 400;
              display: inline-block;
              padding: 8px 10px;
              border-radius: 5px !important;
            }

            .small-item {
              position: absolute;
              top: 10px;
              right: 15px;

              .text {
                width: calc(100% - 50px);
                font-size: 0;

                .text-level,
                .level-graphical {
                  display: inline-block;
                  vertical-align: top;
                }

                &.level-high {
                  .text-level {
                    color: #ff5a5a;
                  }

                  .level-graphical {
                    /*background: #eb5657;*/
                    background-image: url("@{imgUrl}query/levels.png");
                    background-repeat: no-repeat;
                    background-position: -120px 0;
                  }
                }

                &.level-middle {
                  .text-level {
                    color: #fcbc41;
                  }

                  .level-graphical {
                    /*background: #fcbc41;*/
                    background-image: url("@{imgUrl}query/levels.png");
                    background-repeat: no-repeat;
                    background-position: -60px 0;
                  }
                }

                &.level-low {
                  .text-level {
                    color: #23c994;
                  }

                  .level-graphical {
                    /*background: #23c994;*/
                    background-image: url("@{imgUrl}query/levels.png");
                    background-repeat: no-repeat;
                    background-position: 0 0;
                  }
                }

                .text-level {
                  font-style: normal;
                  font-size: 14px;
                  letter-spacing: 1px;
                  margin-left: 10px;
                }

                .level-graphical {
                  width: 60px;
                  height: 8px;
                  display: inline-block;
                  margin-top: 4px;
                }
              }
            }
          }

          /*padding: 15px;*/
          height: 220px;
          text-align: center;
          position: relative;

          img {
            max-width: 100%;
            max-height: 100%;
            display: block;
          }
        }

        .trademark-reg,
        .see-big-img {
          height: 30px;
          background-color: #616060;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 1px;
          color: #ffffff;
          text-align: center;
        }

        .trademark-reg {
          cursor: default;
        }

        .see-big-img {
          position: absolute;
          width: 100%;
          top: 0;
          display: none;
          z-index: 2;
          cursor: pointer;
        }

        .showbig {
          position: fixed;
          width: 800px;
          background: red;
          z-index: 999;
        }

        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.22);
          transform: scale(1.01);
        }
      }
    }
  }

  .translate {
    margin-right: 45px;

    li {
      display: flex;
      align-items: center;

      p {
        width: 25% !important;
        display: flex;
        align-items: center;

        span:nth-of-type(2) {
          width: 60%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:nth-of-type(1) {
          padding-left: 20px;
          box-sizing: border-box;
        }

        &.sbmc {
          position: relative;

          &:before {
            width: 2px;
            height: 12px;
            content: '';
            left: 12px;
            top: 4px;
            display: block;
            position: absolute;
            background: #2B68D4;
          }
        }
      }
    }
  }

  .translate_imhCode {
    position: absolute;
    display: flex;
    top: 13px;
    right: 348px;

    div {
      cursor: pointer;
      font-size: 14px;
      color: #1E44B9;
      display: flex;
      align-items: center;
      position: relative;

      img {
        margin-right: 9px;
      }

      padding-bottom: 7px;

      /*&:nth-of-type(1) {*/
      /*  &:before {*/
      /*    width: 84px;*/
      /*    height: 1px;*/
      /*    position: absolute;*/

      /*    bottom: 0;*/
      /*    content: '';*/
      /*    background: #0b6ce8;*/
      /*  }*/
      /*}*/
      /deep/.is-disabled{
        color: #C0C4CC!important;
      }
      &:nth-of-type(2) {
        padding-left: 24px;
        margin: 0 24px;

        &:before {
          width: 2px;
          height: 12px;
          position: absolute;
          padding-bottom: 7px;
          left: 0;
          top: 4px;
          content: '';
          background: #0b6ce8;
        }
      }
    }
  }

  .progressTop_title {
    position: relative;
    background: #F3F9FE;
    border-radius: 10px;
    padding-left: 13px;;

    box-sizing: border-box;

    &:before {
      width: 2px;
      height: 12px;
      content: '';
      top: 4px;
      left: 0;
      position: absolute;
      background: #2B68D4;
    }
  }

  .progressd {
    tr td {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: 80px;
      text-align: center
    }
  }

  .progressTop {
    margin-top: 16px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;

    span {
      display: inline-block;
    }

    tr:nth-of-type(1) {
      height: 28px;
      line-height: 28px;
      background: #D9E2F1;
      border-radius: 8px 8px 0px 0px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;

      th {
        display: inline-block;
      }

      th:nth-of-type(1) {
        width: 12%;
      }

      th:nth-of-type(2) {
        width: 80px;
      }

      th:nth-of-type(3) {
        width: 80px;
      }

      th:nth-of-type(4) {
        width: 83px;
      }
    }
  }

  .progressBottom:nth-child(2) {
    margin-top: 30px;

    .inailw {
      background: url("../../assets/images/query/redjiaotpu.png") no-repeat 2px;
      position: relative;

      img {
        position: absolute;
        left: 17px;
        top: 27px;
      }
    }
  }

  .progressBottom {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    width: 100%;
    height: 32px;
    line-height: 32px;

    span {
      display: inline-block;
      text-align: center;

    }

    .inailw {
      background: url("../../assets/images/query/huijiantou.png") no-repeat 2px;
      height: 26px;
      width: 12%;
      position: relative;

      img {
        position: absolute;
        left: 17px;
        top: 27px;
      }
    }

    span:nth-child(2) {
      width: 20%;
    }

    span:nth-child(3) {
      width: 30%;
    }

    span:nth-child(4) {
      width: 30%;
    }

    td {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }
  }

  .progressBottom:last-child {
    .inailw {
      img {
        display: none;
      }
    }
  }

  .boxCards {
    /deep/ .is-checked {
      visibility: visible !important;
    }

    /deep/ .el-checkbox__input {
      /*visibility: hidden;*/
    }

    /deep/ .is-indeterminate {
      visibility: visible !important;
    }

    /deep/ .el-checkbox__label {
      font-size: 12px;
    }

    /deep/ .el-card__body {
      padding: 28px 37px 28px 37px;
      box-sizing: border-box;
    }

    /deep/ .el-card__body {
      padding: 28px 37px 28px 37px;
      box-sizing: border-box;
    }

    position: absolute;
    top: 256px;
    left: 112px;

    z-index: 1600;
    width: 1000px;

    .index_1 {
      display: flex;
      justify-content: space-between;
    }

    .checkbox_group {
      display: flex;
      /*justify-content: space-between;*/
      flex-wrap: wrap;
      flex: 1;

      .checkbox {
        width: 125px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        display: flex;

        .checkbox_img {
          width: 30px;
          height: 20px;
          display: block;
        }

        /deep/ .el-checkbox__label {
          display: flex;
        }
      }
    }

    .query-btns {
      width: 98px;
      height: 36px;
      background: @blue url("@{imgUrl}common/search_icon.png") no-repeat center;
      background-size: 20px auto;
      margin-right: 10px;

      &:hover {
        background: @blueHover url("@{imgUrl}common/search_icon.png") no-repeat center;
        background-size: 20px auto;
      }
    }
  }

  .query {
    .camera2 {
      padding: 0 10px 0 11px;
      box-sizing: border-box;
      font-size: 0;
      background: #fff;
      display: flex;
      justify-content: center;
      .upload {
        width: 38px;
      }
      /deep/.el-upload-dragger{
        display: flex;
        justify-content: center;
        width: 100%!important;
      }

    }
    /deep/.el-upload{
      width: 100%!important;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    padding: 122px 40px;
    box-sizing: border-box;
    .alert{
      position: relative;
      top: -30px;
    }
    .box-card /deep/ .el-card__body {
      padding: 0;
      /*height: 250px;*/
      overflow: hidden;

      .tab {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: #F2F8FD;
        display: flex;
        border-radius: 8px;
        z-index: 2;

        li {
          /*cursor: pointer;*/
          position: relative;
          padding: 0px 11px;
          box-sizing: border-box;
          letter-spacing: 0.5px;
          font-size: 14px;
          border-radius: 8px;
          line-height: 36px;
          text-align: center;

          &.active {
            background: #587BFA;
            color: #FEFEFE;
          }

          &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 13px;
            background: #1182ba;
            width: 2px;
            height: 12px;
          }

          &:last-child:after {
            display: none;
          }
        }
      }


      .content {
        display: flex;
        height: 280px;

        .arrow {
          color: #617BD5;
          cursor: pointer;
          position: absolute;
          top: 11px;
          right: 20px;
          z-index: 999;
        }

        .content_item_1 {
          box-sizing: border-box;
          padding: 66px 67px 0 70px;
          position: relative;
          .tabType {
            width: 470px;
            height: 36px;
            top: 0px;
            right: 0;
            position: absolute;
            text-align: center;
            display: flex;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            color: #12256B;
            z-index: 1;
            background: #ECF0FD;
            box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12);
            border-radius: 6px;
            &.active {
              right: 45px;
              left: auto;
            }

            li {
              cursor: pointer;
              padding: 0 13px;
              box-sizing: border-box;
              display: flex;
              position: relative;
              align-items: center;
              img{
                height: 22px;
                width: auto;
              }
              img:nth-of-type(1){
                display: block;
              }
              img:nth-of-type(2){
                display: none;
              }
              &:hover{
                img:nth-of-type(1){
                  display: none;
                }
                img:nth-of-type(2){
                  display: block;
                }
              }
              span {
                margin-left: 11px;
              }

              &:after {
                position: absolute;
                content: '';
                right: 0;
                top: 8.5px;
                background: #D5DAED;
                width: 1px;
                height: 21px;
              }

              &:last-child:after {
                display: none;
              }
            }
          }
          .tabTypes {
            /*width: 220px;*/
            height: 36px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            top: 0px;
            right: 0;
            position: absolute;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #12256B;
            z-index: 1;
            background: #ECF0FD;
            box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12);
            border-radius: 6px;
            &.active {
              right: 45px;
              left: auto;
            }
            li {
              cursor: pointer;
              padding: 0px;
              box-sizing: border-box;
              display: flex;
              position: relative;
              align-items: center;
              img{
                height: 22px;
                width: auto;
                padding: 0 13px;
              }
              img:nth-of-type(1){
                display: block;
              }
              img:nth-of-type(2){
                display: none;
              }
              &:hover{
                img:nth-of-type(1){
                  display: none;
                }
                img:nth-of-type(2){
                  display: block;
                }
              }
              span {
                margin-left: 11px;
              }

              &:after {
                position: absolute;
                content: '';
                right: 0px;
                top: 0px;
                background: #D5DAED;
                width: 1px;
                height: 21px;
              }

              &:last-child:after {
                display: none;
              }
            }
          }
          .btn {
            cursor: pointer;
            position: absolute;
            bottom: 40px;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 78px;
            height: 35px;
            background: #4751DA;
            border-radius: 8px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
          }

          @media screen and (max-width: 1500px) {
            padding: 66px 57px 0 70px;
          }
          @media screen and (max-width: 1350px) {
            padding: 66px 27px 0 50px;
          }
          @media screen and (max-width: 1230px) {
            padding: 66px 27px 0 20px;
          }

          &.active {
            padding-right: 35px;
            box-sizing: border-box;
          }

          .query-box {
            height: 100px;
            /*background: #ffffff;*/
            position: relative;

            &.vertical-horizon-center {
              height: 85px;
              margin-bottom: auto;

              .top {
                height: 61px;
              }
            }

            .query-inner {
              width: 832px;
              margin: 0 auto;
            }

            .top {
              height: 44px;
              text-align: center;
              position: relative;

              .announcement {
                position: absolute;
                bottom: 0;
                left: 0;

                .icon-warning-circle {
                  color: @blue;
                  font-size: 16px;
                  margin-right: 7px;
                }

                span {
                  font-size: 12px;
                  letter-spacing: 2px;
                  color: #b7b6b6;
                }
              }

              .river-horse-courtyard {
                width: 93px;
                height: 86px;
                margin: 0 auto;
                position: relative;
                padding-bottom: 20px;
                box-sizing: border-box;

                .river-horse-home {
                  width: 100%;
                  height: 100%;
                  opacity: 0;

                  img {
                    width: 100%;
                    height: 100%;
                  }

                  animation: riverHorse_query 1s 0.7s linear forwards;
                }
              }
            }
            .input_search_item {
              .line {
                width: 100%;
                height: 1px;
                margin-top: 12px;
                background: #D3DCE7;
                margin-bottom: 18px;
              }
              .el-form-item {
                margin-bottom: 10px;
              }
            }

            .input_search {
              height: 36px;
              margin: 0px auto 0;

              /*width: 808px;*/
              .screen_btn {
                margin-left: 20px;
                width: 84px;
                /*height: 38px;*/
                background: #EDEFF9;
                border: 1px solid #B9C7F1;
                border-radius: 15px;
                display: flex;
                align-items: center;

                span {
                  cursor: pointer;
                  text-align: center;
                  display: block;
                  width: 44px;
                  height: 34px;
                  line-height: 34px;
                  border-radius: 15px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #1A3DBF;

                  &:nth-of-type(1).active {
                    background: #1A3DBF;
                    color: #FAFBFE;
                    margin: 2px 0 2px 3px;
                  }

                  &:nth-of-type(2).active {
                    background: #1A3DBF;
                    color: #FAFBFE;
                    margin: 2px 3px 2px 0px;
                  }

                  &.Advanced{
                    width: 373px;
                    background: #1A3DBF;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                  }
                }
              }

              .notice_btn {
                /*width: 150px;*/
                height: 38px;
                background: #EDEFF9;
                /*border: 1px solid #B9C7F1;*/
                border-radius: 15px;
                display: flex;
                align-items: center;
                position: absolute;
                top: -2px;
                left: -21px;

                span {
                  cursor: pointer;
                  text-align: center;
                  display: block;
                  width: 63px;
                  height: 34px;
                  line-height: 34px;
                  border-radius: 15px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #1A3DBF;

                  &:nth-of-type(1).active {
                    background: #1A3DBF;
                    color: #FAFBFE;
                    margin: 2px 0 2px 3px;
                  }

                  &:nth-of-type(2).active {
                    background: #1A3DBF;
                    color: #FAFBFE;
                    margin: 2px 3px 2px 0px;
                  }
                }
              }

              .input-single {
                height: 36px;
                border-radius: 15px;
                border: solid 1px #c2c6d0;
                display: flex;
                position: relative;

                .category {
                  width: 101px;
                  text-align: right;
                  font-size: 14px;
                  letter-spacing: 1px;
                  color: #333333;
                  cursor: pointer;
                }

                .input_box {
                  flex: 1;
                  position: relative;
                  /*border-left: 1px solid #c2c6d0;*/

                  .input-con {
                    width: 100%;
                    height: 100%;
                    border: 0; // 去除未选中状态边框
                    outline: none; // 去除选中状态边框
                    background-color: rgba(0, 0, 0, 0); // 透明背景
                    input {
                      height: 100%;
                      border: 0; // 去除未选中状态边框
                    }

                    /deep/ .el-input__inner {
                      line-height: 36px !important;
                    }

                    .el-input__inner {
                      line-height: 36px !important;
                    }

                    .el-input-group__prepend {
                      /*display: flex;*/
                      /*align-items: center;*/
                      width: 100px;
                      position: relative;
                      background: #193dbe;
                      box-sizing: border-box;
                      border-radius: 15px 0px 0px 15px;
                    }

                    .el-icon-caret-bottom {
                      -webkit-transition: -webkit-transform 0.3s ease;
                      -moz-transition: -moz-transform 0.3s ease;
                      transition: transform 0.3s ease;
                      -webkit-transform: rotate(180deg);
                      -moz-transform: rotate(180deg);
                      transform: rotate(180deg);

                      &.rotate {
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        transform: rotate(0deg);
                      }
                    }
                  }
                }

                .el-input__inner{
                  height: 36px!important;
                }

                .camera-box {
                  width: 38px;
                  line-height: 36px;
                  overflow: hidden;

                  &:hover {
                    .camera-btn1 {
                      display: none;
                    }

                    .camera-btn2 {
                      display: inline;
                    }
                  }

                  .camera-btn {
                    width: 26px;
                    vertical-align: middle;
                  }

                  .camera-btn1 {
                    display: inline;
                  }

                  .camera-btn2 {
                    display: none;
                  }
                }

                .query-btn {
                  cursor: pointer;
                  width: 68px;
                  height: 37px;
                  border-radius: 0 15px 15px 0;
                  background: @blue url("@{imgUrl}common/search_icon.png") no-repeat center;
                  margin-top: -1px;
                  margin-right: -1px;
                  background-size: 32%;

                  &:hover {
                    background: #1A3DBF url("@{imgUrl}common/search_icon.png") no-repeat center;
                  }
                }

                .whole {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 12px;
                  width: 26px;
                  height: 23px;
                  margin: 6px 10px 0 0;
                }

                .query-btn_cross {
                  cursor: pointer;
                  width: 75px;
                  height: 37px;
                  color: white;
                  font-size: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 0 15px 15px 0;
                  background: @blue no-repeat center;
                  margin-top: -1px;
                  margin-right: -1px;
                  background-size: 32%;

                  &:hover {
                    background: #1A3DBF no-repeat center;
                  }
                }

                &.borderBlue {
                  border-color: @blue;

                  .input_box {
                    border-color: @blue;
                  }
                }
              }

              .search_btn {
                background: #1A3DBF;
                border-radius: 15px;
                font-size: 14px;
                font-weight: 600;
                color: #FEFEFE;

                &:hover {
                  background: #1A3DBF;
                  color: #FFFFFF;
                  box-shadow: 0px 0px 7px 1px rgba(14, 36, 64, 0.12);
                }
              }

              .el-upload-list {
                position: absolute;
                left: 110px;
                top: 0;
                background: #fff;

                &:focus {
                  outline: none;
                  border: none;
                }
              }

              .el-upload-list--picture .el-upload-list__item {
                border: none;
                border-radius: 0;
                padding: 0 0 0 90px;
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                margin-top: 7px;
                transition: none !important;

                &:focus {
                  outline: none;
                  border: none;
                }
              }

              .el-upload-list--picture .el-upload-list__item-thumbnail {
                width: auto;
                height: 26px;
              }

              .el-upload-list--picture .el-upload-list__item-name {
                margin-top: 0;
                margin-left: -15px;
              }

              .el-upload-list--picture .el-upload-list__item-name i {
                font-size: 0;
              }

              .el-upload-list__item .el-icon-close {
                top: 10px;
              }
            }
          }

          .combinedQuery {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: #1A3DBF;

            span {
              margin-left: 10px;
            }
          }
        }

        .content_item_2 {
          box-sizing: border-box;
          /*width: 500px;*/
          width: calc(50% - 350px);
          position: relative;
          padding: 35px 35px 1px 35px;

          .notes {
            width: 254px;
            height: 12px;
            font-size: 12px;
            font-weight: 500;
            color: #555555;
            line-height: 24px;
            margin-left: 10px;
            margin-top: 3%;
          }


          .defineSwitcher {
            margin-left: 15px;

            .el-switch__core::before {
              content: "交叉检索";
              position: absolute;
              top: -1px;
              right: 9px;
              height: 12px;
              font-size: 12px;
              font-weight: 500;
              color: #FFFFFF;
            }

            .is-checked .el-switch__core::before {
              content: "交叉检索";
              position: absolute;
              top: -1px;
              left: 8px;
              height: 12px;
              font-size: 12px;
              font-weight: 500;
            }
          }


          @media screen and (max-width: 1702px) {
            .notes {
              margin-top: 1%;
            }
          }
          @media screen and (max-width: 1500px) {
            padding: 35px 25px 1px 25px;
            width: calc(50% - 260px);
            .notes {
              margin-top: 1%;
            }
          }
          @media screen and (max-width: 1261px) {
            padding: 35px 15px 1px 15px;
            .notes {
              margin-top: 1%;
            }
          }


          .title {
            height: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .sub_title {
              box-sizing: border-box;
              padding-left: 10px;
              position: relative;
              font-size: 14px;
              font-weight: 400;
              color: #1A3DBF;
              display: inline-block;

              &:before {
                content: '';
                width: 2px;
                height: 12px;
                top: 4px;
                left: 0;
                position: absolute;
                background: #2B68D4
              }
            }

            .el_button {
              padding: 0;
              width: 48px;
              height: 24px;
              color: #666666;
              border: 1px solid #AAAAAA;
              border-radius: 8px;

              &:focus, &:hover {
                background: #587BFA;
                color: #FFFFFF;
              }
            }
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            /*margin-top: 16px;*/
            @media screen and (max-width: 1580px) {
              height: 210px;
            }
            overflow-y: auto;

            .li-category {
              width: 24px;
              height: 24px;
              color: #333333;
              font-size: 12px;
              font-weight: 400;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #F3F5F5;
              margin-bottom: 11px;

              &.isCheck {
                background: #6283FE !important;
              }

              &.isSimilar {
                background: #FFEDBC !important;
              }
            }
          }

          &:before {
            position: absolute;
            display: block;
            content: '';
            left: 0;
            top: 108px;
            width: 2px;
            height: 64px;
            background: #1A3DC0;
            border-radius: 1px;
          }
        }

        .content_item_3 {
          .multipleTable{
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            background: #F2F8FE;
            .color-row1 {
              background: #F2F8FE!important;
            }

            .color-row2 {
              background: #FCF4DE!important;
            }

            .color-row3 {
              background: #F2F8FE!important;
            }

            .color-header{
              background: #E2EFFF!important;
              border-radius: 8px 8px 0px 0px;
              overflow: hidden;
            }

            th.el-table__cell {
              padding: 3px 0!important;
              background: #E2EFFF!important;
            }

            td.el-table__cell{
              border-bottom: none;
              padding: 3px 0!important;
            }
            border-radius: 8px 8px 0px 0px;
            overflow: hidden;
          }
          box-sizing: border-box;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          width: 350px;
          @media screen and (max-width: 1500px) {
            width: 260px;
          }
          /*width: 266px;*/
          background: #f2f8fd;
          height: 100%;
          padding: 10px 16px 17px 16px;
          box-sizing: border-box;

          .title_1 {
            padding-left: 9px;
            position: relative;
            box-sizing: border-box;
            height: 20px;
            /*&:before {*/
            /*  width: 2px;*/
            /*  height: 12px;*/
            /*  content: '';*/
            /*  top: 3px;*/
            /*  left: 0;*/
            /*  position: absolute;*/
            /*  background: #2B68D4;*/
            /*}*/
          }

          .el_button {
            border: 1px solid #AAAAAA;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            width: 100px;
            height: 26px;
            padding: 7px 7px;
            box-sizing: border-box;

            &.active {
              color: #ffffff;
              background: #587BFA;
            }
          }

          .groupInformation {
            li {
              display: flex;

              span {
                text-align: left;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

            }

            &.active li {
              width: 96% !important;
              height: 26px;
              line-height: 26px;
              background: #E2EFFF;
              border-radius: 5px 5px 0px 0px;
            }
          }

          /*.Retrieval {*/
          /*  position: absolute;*/
          /*  bottom: 6px;*/
          /*  right: 20px;*/
          /*  height: 20px;*/
          /*  display: flex;*/
          /*  align-items: center;*/

          /*  .cross {*/
          /*    display: block;*/
          /*    margin-right: 5px*/
          /*  }*/
          /*}*/

          /*@media screen and (max-width: 1500px) {*/
          /*  .Retrieval {*/
          /*    position: absolute;*/
          /*    bottom: 6px;*/
          /*    right: 20px;*/
          /*    height: 20px;*/
          /*    display: flex;*/
          /*    align-items: center;*/

          /*    .cross {*/
          /*      display: block;*/
          /*      margin-right: 5px*/
          /*    }*/
          /*  }*/
          /*}*/

        }
      }
    }

    .pdfImg {
      position: absolute;
      right: 40px;
      width: 42%;
      top: 123px;
      height: 1059px;
      box-shadow: 0px 0px 7px 1px rgba(14, 36, 64, 0.12);
      border-radius: 8px;
      overflow: auto;

      .item1 {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 340px;
      }
    }

    .activeicon {
      cursor: pointer;
      width: 136px;
      height: 119px;
      position: fixed;
      top: 360px;
      right: 0px;
      z-index: 1200;
    }

    .chart_box-card {
      height: 280px;
      position: absolute;
      /*height: 250px;*/
      overflow: hidden;
      top: 123px;
      right: 40px;
      z-index: 1;
      width: 42%;

      /deep/ .el-card__body {
        padding: 10px 25px;
        box-sizing: border-box;
        height: 100%;
      }

      .title_tab {
        font-size: 12px;
        font-weight: 400;
        color: #FBFCFE;
        display: flex;

        li {
          cursor: pointer;
          border-radius: 3px;
          padding: 7px 10px;
          box-sizing: border-box;
          background: #D7DFFE;
          color: #666666;

          &.active {
            background: #1F46D7;
            color: #FBFCFE;
          }
        }
      }

      .loggingTitle {
        display: flex;
        justify-content: space-between;

        span:nth-of-type(1) {
          font-size: 14px;
          font-weight: 400;
          display: inline-block;
          color: #1A3DBF;
          position: relative;
          padding-left: 10px;
          box-sizing: border-box;

          &:before {
            position: absolute;
            left: 0;
            top: 4px;
            width: 3px;
            height: 12px;
            content: '';
            background: #2B68D4;
          }
        }

        span:nth-of-type(2) {
          font-size: 14px;
          font-weight: 400;
          color: #1A3DBF;
          display: inline-block;
          position: relative;
          padding-bottom: 5px;
          box-sizing: border-box;

          &:before {
            position: absolute;
            bottom: 0;
            width: 58px;
            height: 1px;
            content: '';
            left: 0;
            background: #748FFB;
          }
        }
      }

      .title {
        font-size: 14px;
        font-weight: 400;
        color: #1A3DBF;
        position: relative;
        padding-left: 10px;
        box-sizing: border-box;

        &:before {
          position: absolute;
          left: 0;
          top: 2px;
          width: 2px;
          height: 12px;
          content: '';
          background: #2B68D4;
        }
      }

      .lishi{
        cursor: pointer;
        display: flex;
        align-items: center;
        position: absolute;
        font-size: 14px;
        font-weight: 400;
        color: #1A3DBF;
        top: 12px;
        right: 25px;
        img{
          width: 14px;
          height: 14px;
        }
        span{
          margin-left: 3px;
        }
      }

      .distribution {
        cursor: pointer;
        font-size: 12px;
        border-radius: 3px;
        display: inline-block;
        padding: 7px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background: #D7DFFE;
        color: #666666;
      }
    }

    .sub_box-card {
      position: relative;
      margin-top: 32px;
      min-height: 720px;
      overflow: visible;
      &.active {
        /deep/.el-card__body {
          padding-top: 0!important;
        }
      }

      .cost{
        div{
          display: flex;
        }
        background: white;
        color: #979797;
        img:nth-of-type(1){
          display: block;
          margin-right: 5px;
        }
        img:nth-of-type(2){
          display: none;
          margin-right: 5px;
        }
        &:hover{
          background: #597BFA!important;
          color: #FFFFFF!important;
          img:nth-of-type(1){
            display: none!important;
            margin-right: 5px;
          }
          img:nth-of-type(2){
            display: block!important;
            margin-right: 5px;
          }
        }
      }

      .printfs{
        div{
          display: flex;
        }
        background: #EBF1FE;
        color: #979797;
        img:nth-of-type(1){
          display: block;
          margin-right: 5px;
        }
        img:nth-of-type(2){
          display: none;
          margin-right: 5px;
        }
        &:hover{
          background: #FFF7E9!important;
          color: #C29546!important;
          img:nth-of-type(1){
            display: none!important;
            margin-right: 5px;
          }
          img:nth-of-type(2){
            display: block!important;
            margin-right: 5px;
          }
        }
      }

      &.cardWidth {
        width: 55% !important;
      }

      .text {
        /*width: calc(100% - 50px);*/
        display: flex;
        align-items: center;

        .text-level,
        .level-graphical {
          display: inline-block;
          vertical-align: top;
        }

        &.level-high {
          .text-level {
            color: #ff5a5a;
          }

          .level-graphical {
            /*background: #eb5657;*/
            background-image: url("@{imgUrl}query/levels.png");
            background-repeat: no-repeat;
            background-position: -120px 0;
          }
        }

        &.level-middle {
          .text-level {
            color: #fcbc41;
          }

          .level-graphical {
            /*background: #fcbc41;*/
            background-image: url("@{imgUrl}query/levels.png");
            background-repeat: no-repeat;
            background-position: -60px 0;
          }
        }

        &.level-low {
          .text-level {
            color: #23c994;
          }

          .level-graphical {
            /*background: #23c994;*/
            background-image: url("@{imgUrl}query/levels.png");
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }

        .level-graphical {
          width: 60px;
          height: 8px;
          display: inline-block;
        }
      }

      .defineSwitchs {
        margin-left: 10px;

        /deep/ .el-switch__core::before {
          content: "选中";
          position: absolute;
          top: -1px;
          right: 5px;
          color: #fff;
        }

        .is-checked /deep/ .el-switch__core::before {
          content: "选中";
          position: absolute;
          top: -1px;
          left: 5px;
          color: #fff;
        }
      }

      .comparison{
        font-size: 12px;
        font-weight: 400;
        color: #888888;
        width: 66px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        border-radius: 11px;
        border: 1px solid #888888;
        margin-left: 20px;
        margin-top: 1px;
        cursor: pointer;
        &:hover {
          background: #193DBE;
          border-radius: 11px;
          border: 1px solid #193DBE;
          color: #FFFFFF;
        }
      }
    }
  }

  .trademarkAdvanceds{
    /deep/.el-dialog__body{
      padding: 30px 43px;
    }
    /deep/.el-dialog{
      width: 816px;
      min-height: 380px;
      background: #FFFFFF;
      border-radius: 6px;
      box-sizing: border-box;
    }
    /deep/.el-input--suffix{
      width: 118px;
      background: #2F57DE;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }
    /deep/.input-with-select{
      overflow: hidden;
      border-radius: 12px 12px 12px 12px;
    }
    /deep/.el-input-group__append{
      text-align: center;
      width: 50px;
      background: #2F57DE;
      border-radius: 0 12px 12px 0;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }
    .content{
      display: flex;
      width: 728px;
      min-height: 254px;
      background: #F6F8FE;
      .left{
        width: 119px;
        min-height: 254px;
        background: #E6EBF7;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 500;
        color: #555555;
        span{
          padding-left: 21px;
          box-sizing: border-box;
          margin-top: 31px;
        }
      }
      .right{
        width: 610px;
        box-sizing: border-box;
        padding: 31px 0 30px 16px;
        .radio2_input{
          width: 169px;
          height: 26px;
          background: #FFFFFF;
          border-radius: 4px;
          margin-top: 20px;
          margin-left: 20px;
        }
        .arrow1{
          cursor: pointer;
          width: 16px;
          height: auto;
          position: absolute;
          top: 272px;
          right: 85px;
        }
      }
    }
  }

  .el_dialog{
    /*position: relative;*/
    /deep/.el-dialog__header{
      display: none;
    }
    /deep/.el-dialog{
      width: 408px;
      height: 249px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.1700);
      border-radius: 7px;
    }
    /deep/.el-dialog__body{
      padding: 0;
    }
    /deep/.header_title{
      display: flex;
      width: 100%;
      height: 40px;
      background: #E2EFFF;
      box-sizing: border-box;
      padding: 0 30px 0 20px;
      line-height: 40px;
      align-items: center;
      justify-content: space-between;
    }
    /deep/.content{
      margin-top: 21px;
      margin-left: 35px;
      height: 125px;
      overflow-y: auto;
    }
    /deep/.title{
      font-size: 12px;
      font-weight: 400;
      color: #444444;
      position: relative;
      padding-left: 12px;
      &:before {
        width: 3px;
        height: 12px;
        position: absolute;
        content: '';
        left: 0;
        top: 14px;
        background: #193DBE;
      }
    }
    /deep/.icon{
      font-size: 14px;
      color: #555555;
      cursor: pointer;
    }
    /deep/.btn{
      width: 72px;
      height: 28px;
      position: absolute;
      background: #193DBE;
      border-radius: 7px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 23px;
      right: 30px;
    }
    /deep/.el-radio-group{
      display: flex;
      flex-direction: column;
    }
    /deep/.el-radio{
      margin-bottom: 10px;
    }
  }
</style>
<style lang="less">
  .base64Url {
    width: 70px;
    min-height: 25px;
    position: absolute;
    top: 1px;
    left: 115px;
    text-align: center;
    img {
      margin-top: 2px;
      max-width: 65%;
      height: 90%;
    }
    i{
      margin-top: -39px;
      margin-left: -10px;
    }
    .el-icon-close {
      top: 3px!important;
      right: -17px!important;
    }
  }
  .costCalculationClass{
    z-index: 2140;
    .el-button--primary {
      color: #fff;
      background-color: #587BFA!important;
      border-color: #587BFA!important;
    }
    /*.el-button--primary:focus, .el-button--primary:hover{*/
    /*  background: #66b1ff;*/
    /*  border-color: #66b1ff;*/
    /*  color: #fff;*/
    /*}*/
  }

  .history_table {
    .el-table__fixed-right {
      &:before {
        content: none;
      }
    }
  }

  .Retrieval {
    /*margin-left: 10px;*/
    /*.cross {*/
    /*  font-size: 14px;*/
    /*}*/
    margin-top: -3px;
    .defineSwitchs {
      margin-left: 15px;
      margin-top: -4px;
      /deep/ .el-switch__core::before {
        content: "交叉检索";
        position: absolute;
        top: -1px;
        right: 5px;
        color: #fff;
      }

      .is-checked /deep/ .el-switch__core::before {
        content: "选中";
        position: absolute;
        top: -1px;
        left: 5px;
        color: #fff;
      }
    }

    /*.el-switch.is-checked .el-switch__core::after {*/
    /*  margin-left: -14px;*/
    /*}*/

    /*.el-switch__core {*/
    /*  height: 16px; // 重置*/
    /*  &:after {*/
    /*    width: 14px;*/
    /*    height: 14px;*/
    /*    top: 0;*/
    /*  }*/
    /*}*/
  }

  .input-con {
    .el-input__inner {
      line-height: 36px;
    }
  }

  .vxe-tools--operate {
    position: absolute;
    right: 400px;

    &.active {
      right: 580px;
    }
  }

  .row--current, .row--hover {
    background: #ffffff !important;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    -webkit-transform: translate3d(0, 0px, -2px);
    transform: translate3d(0, 1px, -2px);
  }

  .vxe-body--row {
    transition: all 0.2s ease-in;
  }


  .query .vxe-table--header {
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
  }

  .vxe_grid_header {
    background: #E2EFFF;
  }

  .query-result-ico {
    width: 650px !important;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(63, 63, 63, 0.32);
    border-radius: 3px;
    border: solid 1px #d7d7d7;

    .container {
      padding: 12px 20px;
      font-size: 0;

      .top {
        display: flex;
        & > * {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
        }

        .left {
          .tuyang {
            width: 200px;
            height: 200px;
            border: solid 1px #d0d0d0;
            position: relative;

            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              max-width: 95%;
              max-height: 80%;
            }
            div {
              text-align: center;
              position: absolute;
              bottom: 0px;
              width: 100%;
            }
          }

          .degree,
          .zan {
            margin-top: 6px;
            font-size: 0;

            & > * {
              vertical-align: middle;
              font-size: 13px;
              display: inline-block;
            }

            .title {
              width: 71px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #454545;
              display: inline-block;
              word-break: normal;
              margin-right: 15px;
            }

            .text {
              font-size: 14px;
              letter-spacing: 1px;
              color: #ef6c6d;
            }

            .feedbackImgLaughone {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -50px 0;
            }

            .feedbackImgCryone {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: 0 0;
            }

            .feedbackImgLaughtwo {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -75px 0;
            }

            .feedbackImgCrytwo {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -25px 0;
            }
          }
        }

        .right {
          margin-left: 10px;

          li {
            margin-bottom: 10px;
            display: flex;
            & > * {
              display: inline-block;
              vertical-align: top;
              font-size: 12px;
            }

            .title {
              width: 94px;
              text-align: right;
              font-size: 14px;
              letter-spacing: 1px;
              color: #666666;
              word-break: normal;
              margin-right: 15px;
              /*background: red;*/
            }

            .text {
              font-size: 13px;
              letter-spacing: 1px;
              color: #333333;
              /*background: green;*/
              width: 310px;
              overflow: hidden;
              text-overflow: ellipsis;
              /*word-break:normal;*/
            }
          }
        }
      }

      .line {
        height: 1px;
        border: solid 1px #d3d3d3;
      }

      .bottom {
        text-align: center;

        .btn {
          display: inline-block;
          width: 155px;
          height: 30px;
          line-height: 30px;
          border-radius: 3px;
          border: solid 1px #eb5657;
          margin-top: 15px;
          font-size: 13px;
          letter-spacing: 1px;
          color: #eb5657;
          cursor: pointer;

          &:hover {
            background: #eb5657;
            color: #ffffff;
          }
        }
      }

      &.pq_active {
        .top {
          & > * {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
          }

          .left {
            .tuyang {
              width: 112px;
              height: 117px;
              border: solid 1px #d0d0d0;
              position: relative;

              img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 95%;
                max-height: 95%;
              }
            }

            .degree,
            .zan {
              margin-top: 6px;
              font-size: 0;

              & > * {
                vertical-align: middle;
                font-size: 13px;
                display: inline-block;
              }

              .title {
                width: 71px;
                font-size: 12px;
                letter-spacing: 1px;
                color: #454545;
                display: inline-block;
                word-break: normal;
                margin-right: 15px;
              }

              .text {
                font-size: 14px;
                letter-spacing: 1px;
                color: #ef6c6d;
              }

              .feedbackImgLaughone {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: -50px 0;
              }

              .feedbackImgCryone {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: 0 0;
              }

              .feedbackImgLaughtwo {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: -75px 0;
              }

              .feedbackImgCrytwo {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: -25px 0;
              }
            }
          }

          .right {
            margin-left: 10px;

            li {
              margin-bottom: 10px;

              & > * {
                display: inline-block;
                vertical-align: top;
                font-size: 12px;
              }

              .title {
                width: 150px !important;
                font-size: 14px;
                letter-spacing: 1px;
                color: #666666;
                word-break: normal;
                margin-right: 15px;
                /*background: red;*/
              }

              .text {
                font-size: 13px;
                letter-spacing: 1px;
                color: #333333;
                /*background: green;*/
                width: 310px;
                overflow: hidden;
                text-overflow: ellipsis;
                /*word-break:normal;*/
              }
            }
          }
        }
      }
    }
  }

  .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
  .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
    background-color: #dfecfb;
  }

  .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
    cursor: no-drop;
  }

  /*滚动条整体部分*/
  .mytable-scrollbar ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /*滚动条的轨道*/
  .mytable-scrollbar ::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  }

  /*滚动条里面的小方块，能向上向下移动*/
  .mytable-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }

  .mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
    background-color: #A8A8A8;
  }

  .mytable-scrollbar ::-webkit-scrollbar-thumb:active {
    background-color: #787878;
  }

  /*边角，即两个滚动条的交汇处*/
  .mytable-scrollbar ::-webkit-scrollbar-corner {
    background-color: #FFFFFF;
  }

  .expand-wrapper {
    padding: 20px;
    box-sizing: border-box;
  }

  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .col-orange div {
    width: 118px !important;
    display: flex !important;
    height: 48px !important;
    padding: 2px 0 !important;
    justify-content: center !important;
  }
  .color-row1-1{
    background: #fff3e0;
  }
  .color-row1-7{
    background: #E9D7C4;
  }
  .color-row1-8{
    background: #FFEFDF;
  }
</style>
<style lang="less">
  #mytitle {
    position: absolute;
    transform-origin: center top;
    z-index: 2010;
    width: 192px;
    height: 57px;
    color: #606266;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #ebeef5;

    &:after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent #ebeef5 transparent;
      left: 12px;
      top: -9px;
    }
  }

  .popperClass {
    position: absolute;
    /*display: none;*/
  }

  .vxe_grid_toolbar{
    .toolbarNames {
      display: none;
    }

    .toolbarNames_xlsx{
      display: none;
    }
  }

  .toolbarNames_xlsx {
    display: flex;

    /*div:nth-of-type(1) {*/
    /*  flex-direction: row-reverse;*/
    /*}*/

    .vxe-tools--operate{
      right: 0px;
      margin-top: -8px;
    }
  }

  .toolbarNames {
    display: flex;

    div:nth-of-type(1) {
      flex-direction: row-reverse;
    }
  }
</style>
<style lang="less">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明的黑色背景 */
  z-index: 1002; /* 置于其他内容之上 */
}
  .query{
    .myAnalysisIcon{
      position: fixed;
      top: 500px;
      right: 0;
      background-image: linear-gradient(to bottom left, rgba(74, 110, 240, .8), rgb(255, 255, 255) 51%);
      padding: 10px 30px 4px 30px;
      box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.18);
      border-bottom-left-radius: 24px;
      border-top-left-radius: 24px;
      cursor:pointer;
    }
  }

  .queryDrawer #el-drawer__title{
    display:none;
  }
  //此style都是商标监控相关的样式
  .addMonitor{
    font-size: 12px;
    font-weight: 400;
    color: #888888;
    margin-top: 1px;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #888888;
    &:hover {
      background: #193DBE;
      border-radius: 11px;
      border: 1px solid #193DBE;
      color: #FFFFFF;
    }
  }

  .monitorDialog {
    .prompt {
      height: 58px;
      line-height: 58px;
      border-bottom: 1px solid #e4e4e4;
      margin: 0 32px;
      text-align: center;

      .text {
        font-size: 14px;
        color: #222222;
        vertical-align: middle;
        margin-left: 10px;
      }
    }

    .mainBody {
      width: 495px;
      margin: 0px auto 0px;

      .check {
        margin-bottom: 20px;

        .requiredStar {
          color: #d01027;
          font-weight: bold;
          font-size: 16px;
        }

        .title:nth-child(1) {
          width: 105px;
          font-size: 14px;
          color: #222222;
          text-align: left;
          vertical-align: middle;
        }

        .title {
          display: inline-block;
        }

        .right {
          width: 380px;
          text-align: left;
          vertical-align: middle;
          position: relative;
          display: inline-block;

          .upload-drag {
            height: 82px !important;

            &.uploadDisabled {
              position: relative;

              .el-upload {
                .el-upload-dragger {
                  .el-upload-disabled-shade {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    cursor: not-allowed;
                  }
                }
              }
            }
          }

          .el-upload-dragger {
            border: 1px solid #e4e4e4;
            height: 82px;
            width: 380px;
            cursor: pointer;
          }

          .upload-img {
            height: 94%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          p.delete {
            position: absolute;
            z-index: 5;
            right: 10px;
          }

          p {
            margin-top: 12px;
            font-size: 12px;
            letter-spacing: 1px;
            color: #999999;

            &.delete:hover {
              color: #0b6ce8;
            }
          }

          .leftText {
            height: 36px;
            border-color: #e4e4e4;
          }

          .el-input-long-officialt {
            width: 380px;
            background-color: #fff;

            &.active {
              width: 360px;
            }
          }

          .rightText {
            float: right;
            border-left: 1px solid #ccc;
            width: 40px;
            text-align: center;
          }

          .el-select-long-officialt {
            width: 380px;
            background-color: #fff;

            .el-input__suffix-inner {
              margin-top: 9px;
            }
          }

          .result {
            margin: 10px 0 0;

            .resultImg {
              width: 82px;
              height: 82px;
              border: 1px solid #e4e4e4;
              text-align: center;
              display: table-cell;
              vertical-align: middle;
              float: left;
            }

            .resultText {
              float: left;
              margin-left: 16px;

              span {
                color: #222;
                font-size: 14px;
              }
            }
          }
        }

        .niceCode {
          width: 380px;
          border: 1px solid #e4e4e4;
          height: 38px;
          border-radius: 3px;

          .leftText {
            border: transparent;
            width: 310px;

            input.el-input__inner {
              border: none !important;
            }
          }

          .rightText {
            width: 60px;
            height: 25px;
            margin-top: 9px;
          }
        }

        .el-select {
          .el-select__tags {
            .el-select__input {
              border: none !important;
            }
          }
        }
      }
    }

    .dialog-footer {
      float: right;
      margin-right: 113px;

      .submit {
        width: 90px;
        height: 36px;
        display: inline-block;
        background-color: #d01027;
        color: #fff;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        margin-top: 22px;
        margin-left: 32px;
        cursor: pointer;
        border-radius: 3px;
      }

      .submit:hover {
        background-color: #d73e3f;
      }
    }
  }
  .el-select-dropdown__item {
    height: auto !important;
  }

  .el-tree {
    display: flex;
    flex-direction: column;
  }

  section.search-option {
    min-height: 100px;
    background-color: #ffffff;
    border-radius: 3px;
    box-sizing: border-box;
    border: solid 1px #e4e4e4;
    margin-bottom: 20px;
    padding-top: 47px;
    padding-left: 50px;
    height: 135px;

    .search_title {
      font-size: 14px;
      color: #474747;
      margin-right: 14px;
    }

    div {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .el-select-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;

      .el-input {
        input.el-input__inner {
          border: 1px solid #cccccc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .one, .el-input {
      width: 180px;

      input {
        height: 34px !important;
        border-color: #ccc;
        color: inherit;
        padding: 0 4px;
      }
    }

    ._one /deep/ .el-input__inner {
      height: 34px;
    }

    .el-date-editor-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;
      line-height: 32px;

      input.el-input__inner {
        border: 1px solid #cccccc;
        text-indent: 42px;
      }
    }
  }

  .trademarkMonitoring {
    .danTitle {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: relative;
      margin-left: 17px;

      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 16px;
        top: 17px;
        background: #1a3dbf;
      }

      span {
        margin: 0 9px;

        &:nth-of-type(1) {
          margin-left: 18px;
        }

        &:nth-of-type(3) {
          color: #506bce;
        }
      }

      span:hover {
        cursor: pointer;
        color: #506bce;
      }
    }

    padding: 0 1%;
    box-sizing: border-box;

    .monitoring_top {
      height: 131px;
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 15px auto;

      .condition {
        padding: 45px 0 0 45px;

        .el-input__inner {
          height: 36px;
        }

        .el-input {
          width: 220px;
          margin-right: 20px;
          padding: 0px;
          margin-left: 10px;
        }
      }
    }

    .monitoring_two {
      width: 100%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      justify-content: space-between;

      .totalMonitoring {
        background: #f4f7ff;
        font-size: 14px;
        color: #383838;
        width: 354px;
        height: 44px;
        border-radius: 20px;
        margin-right: 32px;
        display: flex;
        align-items: center;
        padding: 0 37px 0 33px;
        justify-content: space-between;
        box-sizing: border-box;

        i {
          font-size: 16px;
        }

        .active1 {
          color: #3057e3;
        }

        .active2 {
          color: #ff8d1a;
        }
      }

      .jg {
        width: 14px;
        cursor: pointer;
        position: absolute;
        margin-left: 0px;
        margin-right: 14px;
      }

      .recommend-tips {
        vertical-align: bottom;
        width: 425px;
        padding: 7px 12px;
        line-height: 17px;
        background: #fbedec;
        border: 1px solid #f6d4d4;
        font-size: 12px;
        text-align: left;

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          border-bottom: 1px solid #f6d4d4;
          border-left: 1px solid #f6d4d4;
          top: 50%;
          left: -7px;
          transform: translateY(-50%) rotate(45deg);
          background: #fbedec;
        }
      }

      .addMsg {
        position: absolute;
        left: 110px;
        top: -18px;
      }

      .add {
        width: 152px;
        height: 32px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
        color: #2c3e50;
        cursor: pointer;
        margin-top: 30px;
      }

      .jum {
        width: 120px;
        margin-left: 15px;
      }

      .add:hover {
        background: #193dbe;
        color: #fff;
        border: 1px solid #193dbe;
      }

      .batchImport {
        float: right;
        margin-top: 35px;

        span {
          margin-right: 15px;
          vertical-align: middle;

          img {
            vertical-align: middle;
          }

          .upload-demo {
            .el-upload-dragger {
              width: 100px;
              height: auto;
              background: none;
              border: none;
              margin-top: 3px;
            }

            .el-upload-list {
              display: none;
            }
          }
        }

        .icondaoru-tianchong {
          font-weight: bold;
          vertical-align: middle;
          color: #de5455;
          margin-right: 3px;
          font-size: 18px;
        }
      }
    }

    .monitoring_table {
      padding: 10px;
      background: white;
      border: 1px solid #ddddde;
    }


    .seeResults:hover {
      text-decoration: underline;
    }

    .primary {
      background: #d01027;
      border: #d01027;
    }

    .primary:hover {
      background: #d73e3f;
    }

    .can:hover {
      color: white;
      background-color: #d01027;
      border: 1px solid #d01027;
    }

    .fyBlock {
      padding-bottom: 1%;
      padding-top: 2%;
      text-align: center;
    }

    .monitor {

      .prompt {
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #e4e4e4;
        margin: 0 32px;
        text-align: center;

        .text {
          font-size: 14px;
          color: #222222;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
      .mainBody {
        width: 495px;
        margin: 0px auto 0px;

        .check {
          margin-bottom: 20px;

          .requiredStar {
            color: #d01027;
            font-weight: bold;
            font-size: 16px;
          }

          .title:nth-child(1) {
            width: 105px;
            font-size: 14px;
            color: #222222;
            text-align: left;
            vertical-align: middle;
          }

          .title {
            display: inline-block;
          }

          .right {
            width: 380px;
            text-align: left;
            vertical-align: middle;
            position: relative;
            display: inline-block;

            .upload-drag {
              height: 82px !important;

              &.uploadDisabled {
                position: relative;

                .el-upload {
                  .el-upload-dragger {
                    .el-upload-disabled-shade {
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      right: 0;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }

            .el-upload-dragger {
              border: 1px solid #e4e4e4;
              height: 82px;
              width: 380px;
              cursor: pointer;
            }

            .upload-img {
              height: 96%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            p.delete {
              position: absolute;
              z-index: 5;
              right: 10px;
            }

            p {
              margin-top: 12px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #999999;

              &.delete:hover {
                color: #0b6ce8;
              }
            }

            .leftText {
              height: 36px;
              border-color: #e4e4e4;
            }

            .el-input-long-officialt {
              width: 380px;
              background-color: #fff;

              &.active {
                width: 360px;
              }
            }

            .rightText {
              float: right;
              border-left: 1px solid #ccc;
              width: 40px;
              text-align: center;
            }

            .el-select-long-officialt {
              width: 380px;
              background-color: #fff;

              .el-input__suffix-inner {
                margin-top: 9px;
              }
            }

            .result {
              margin: 10px 0 0;

              .resultImg {
                width: 82px;
                height: 82px;
                border: 1px solid #e4e4e4;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                float: left;
              }

              .resultText {
                float: left;
                margin-left: 16px;

                span {
                  color: #222;
                  font-size: 14px;
                }
              }
            }
          }

          .niceCode {
            width: 380px;
            border: 1px solid #e4e4e4;
            height: 38px;
            border-radius: 3px;

            .leftText {
              border: transparent;
              width: 310px;

              input.el-input__inner {
                border: none !important;
              }
            }

            .rightText {
              width: 60px;
              height: 25px;
              margin-top: 9px;
            }
          }

          .el-select {
            .el-select__tags {
              .el-select__input {
                border: none !important;
              }
            }
          }
        }
      }

      .dialog-footer {
        float: right;
        margin-right: 113px;

        .submit {
          width: 90px;
          height: 36px;
          display: inline-block;
          background-color: #d01027;
          color: #fff;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          margin-top: 22px;
          margin-left: 32px;
          cursor: pointer;
          border-radius: 3px;
        }

        .submit:hover {
          background-color: #d73e3f;
        }
      }
    }
  }

  .long-dialog-old.el-dialog__wrapper {
    text-align: center;

    .el-dialog {
      width: auto;
      display: inline-block;
    }

    .el-dialog__header {
      padding: 0;
      height: 0px;
      display: inline-block;

      .el-icon-close {
        top: 0;
        right: 0;
        font-size: 20px;
      }
    }

    .el-dialog__body {
      padding: 0;
      display: inline-block;
    }

    .long-inner {
      width: 740px;
      border-radius: 30px;
      text-align: left;

      &.long-inner-viena {
        width: 950px;
      }

      .top {
        height: 53px;
        line-height: 53px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #666666;
        padding: 0px 50px;
        border-bottom: 1px solid #cecece;
        background-color: #efefef;
        border-radius: 3px;

        i {
          color: #e96364;
        }

        span {
          margin-left: 12px;
        }
      }

      /*尼斯分类*/
      .middle.nice-style {
        height: 530px;
        overflow: auto;

        .first-ul {
          font-size: 13px;

          .first-li {
            padding: 0;
            text-indent: 50px;

            .first-p {
              cursor: pointer;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              letter-spacing: 0px;
              color: #333333;

              &:hover {
                background: #f7f7f7;
              }

              .el-checkbox {
                text-indent: 0;
                //复选框样式见long.less
              }
            }

            .second-ul {
              .second-li {
                padding: 0;
                text-indent: 50+18px;

                &:nth-last-of-type(1) {
                  padding-bottom: 0;
                }

                .second-p {
                  cursor: pointer;
                  height: 34px;
                  line-height: 34px;

                  &:hover {
                    background: #f7f7f7;
                  }

                  .el-checkbox {
                    text-indent: 0;
                    //复选框样式见long.less
                  }
                }
              }
            }
          }
        }
      }

      .bottom {
        height: 94px;
        padding: 30px 54px;
        text-align: right;

        .el-button {
          height: 30px;
          width: 100px;
        }

        .viennaConfirm {
        }

        .viennaClose {
          margin-left: 22px;
        }
      }
    }
  }
</style>
<style lang="less">
  // 行的样式
  .mo-table__row {
  }

  // 单元格的样式
  .mo-table__cell {
  }

  // 去除element-ui 默认的鼠标移入样式
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: transparent;
  }

  // 自定义的高亮样式
  .el-table--enable-row-hover .el-table__body .mo-table__row--active {
    background-color: #f5f7fa;
  }
</style>

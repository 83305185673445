<template>
  <div>
    <div @mouseover="mouseover" :class="['nav_wrap nav_headers',{'active': active},'no-print']">
      <div :class="['nav_con',{'active':!isCN}]">
        <div class="nav">
          <div class="logo" @click="toHome">
            <img src="@/assets/images/header/yu.png" alt v-if="!headerBgBool || navBarFixed" />
            <img src="@/assets/images/header/logo_white.png" alt v-else />
          </div>
        </div>

        <div style="display: flex;align-items: center">
          <div class="nav" >
            <ul>
              <li :class="{'active': item.text == 'AI引擎'}"  :style="{color: item.text == '订购服务' ? '#EDCF8A' : ''}" @click="jump(item)" @mouseleave="liLive" @mouseenter="enter(item)" v-for="(item,index) in nav">
                <span v-if="item.text != 'AI引擎'">{{item.text}}</span>
                <img style="width: 52px" v-else src="../../assets/images/common/wo.png" alt="">
              </li>

            </ul>
          </div>
          <ul style="margin-top: -2px" :style="{marginLeft: '15px'}" class="login">

            <!-- <li @click="changeLanguage('zh-cn')">中</li>
            <li> / </li>
            <li @click="changeLanguage('en')" class="en_btn">EN</li>-->
            <!--
                1.headerBgBool:背景色是否透明：true
                2.navBarFixed:scroll 是否>64
                3.me：是否登录
            -->

            <li v-if="!me" :style="{marginLeft: '45px',fontSize: '14px'}"
                class="login_btn" @click="loginEvent()">{{me ? getIphone(me.phone) : $t(`headers.login`)}}
            </li>


            <!--          <li  v-if="!me &&  !navBarFixed" class="login_btn" @click="loginEvent()">{{me ? me.phone : $t(`headers.login`)}}</li>-->
            <!--            <li v-else class="login_btn" @click="loginEvent()">{{me ? me.phone : $t(`headers.login`)}}</li>-->

            <li style="position: relative" v-if="me">
              <el-dropdown @command="handleCommand">
              <span :style="{fontSize: '16px'}" :class="['el-dropdown-link',{active: active}]">
                <img v-if="vipInfo && vipInfo != ''" style="position: absolute;left: -30px;top: 5px;" :src="require(`../../assets/images/header/${vipInfo}.png`)" alt="">   {{getIphone(me.phone)}}
                <i style="font-size: 14px" class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item  v-for="(item,index) in $t(`headers.dropDownMenu`)" :command="item" :key="index">
                    {{item.text}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>

            <li v-if="me" class="notice" @mouseenter="noticeBool = true" @mouseleave="mouseleaveEvent">
              <el-badge :is-dot="dotBool">
                <i :style="{color: active ?  '#fff' : ''} " class="el-icon-bell"></i>
              </el-badge>
              <div class="notice_po">
                <ul class="notice_con" :class="{noDefault:noticeList== undefined || noticeList.length == 0}"
                    v-show="noticeBool">
                  <li v-if="noticeList== undefined || noticeList.length == 0" class="no_notice">{{$t(`headers.noData`)}}
                  </li>
                  <li v-else v-for="(item,index) in noticeList" :key="index">
                    <p>
                    <span>
                      <b>{{item.messageTitle}}</b>
                      {{item.messageContent}}
                    </span>
                      <i>{{item.createTime}}</i>
                    </p>
                  </li>
                </ul>
              </div>
            </li>

<!--            <li  :style="{marginLeft: '25px',display: active ? 'none' : ''}">-->
<!--              <el-select  class="en_cn_Switch" style="width: 118px;height: 30px" @change="changeLanguage"  v-model="value" placeholder="请选择">-->
<!--                <el-option-->

<!--                  v-for="item in options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <div v-show="isFlag" @mouseleave="isFlag = false;isFlags = false" @mouseenter="isFlag = true;isFlags = true"
             class="menu_cen">
          <div class="menu_cen_body">
            <ul>
              <li :key="index" v-for="(item,index) in subMenu">
                <span class="subMenu_title">{{item.text}}</span>
                <span @click="jump(items)" class="subMenu" :key="indexs"
                      v-for="(items,indexs) in subMenu[index].children">{{items.text}}</span>
              </li>
            </ul>

            <dl>
              <dt>
                <img style="border-radius: 10px" src="../../assets/images/about/sy.png" alt="">
              </dt>
              <dd>
                微信小程序
              </dd>
            </dl>

          </div>

        </div>
      </transition>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login v-if="dialogVisible" @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>

  </div>
</template>
<script>
  import { mapState, mapMutations, mapGetters } from "vuex";
  import login from "@/components/common/login";
  export default {
    name: "headers",
    data () {
      return {

        applicant: [],
        subMenu: [],
        isFlag: false,
        isFlags: false,
        nav: [
          {
            "text": "产品/服务",
            "position": 15,
            'showBool': true,
            children: [
              {
                "text": "在线应用",
                "subTilte": true,
                'showBool': true,
                children: [
                  {
                    "text": "商标查询",
                    "subTilte": false,
                    "path": "/PQ",
                    'showBool': true
                  }, {
                    "text": "商标监控",
                    "subTilte": false,
                    "path": "/TM",
                    'showBool': true
                  }, {
                    "text": "业务线索",
                    "subTilte": false,
                    "path": "/PQ?id=6",
                    'showBool': true
                  }, {
                    "text": "法律文书",
                    "subTilte": false,
                    "path": "/instrument",
                    'showBool': true
                  },  {
                    "text": "专利翻译",
                    "subTilte": false,
                    "path": "/PT",
                    'showBool': true
                  }
                  , {
                    "text": "中小微企业智产托管平台",
                    "subTilte": false,
                    "path": "/hostingPlatform",
                    'showBool': true
                  }
                  , {
                    "text": "专利检索",
                    "subTilte": false,
                    "path": "/PS",
                    'showBool': true
                  }
                ]
              },
              {
                "text": "效率工具",
                "subTilte": true,
                'showBool': true,
                children: [
                  {
                    "text": "效率工具",
                    "subTilte": false,
                    "path": "/fficiencyTools",
                    'showBool': true
                  }
                ]
              },
              {
                "text": "管理平台",
                "subTilte": true,
                'showBool': true,
                children: [
                  {
                    "text": "商标管理系统",
                    "subTilte": false,
                    "path": "/TMS",
                    'showBool': true
                  }, {
                    "text": "专利管理系统",
                    "subTilte": false,
                    "path": "/PMS?point=0",
                    'showBool': true
                  }, {
                    "text": "专利年费管理系统",
                    "subTilte": false,
                    "path": "/PMS?point=1",
                    'showBool': true
                  }
                ]
              },
              {
                "text": "数智平台",
                "subTilte": true,
                'showBool': true,
                children: [
                  {
                    "text": "商标大数据",
                    "subTilte": false,
                    "path": "/brand",
                    'showBool': true
                  }, {
                    "text": "智慧园区",
                    "subTilte": false,
                    "path": "/smartPark",
                    'showBool': true
                  }
                ]
              }
            ]
          },
           {
             "text": "AI引擎",
             "path": "/aiLanding",
             "position": 10,
             'showBool': true
          },
          {
            "text": "资源/支持",
            "path": "/downloadCenter",
            "position": 15,
            'showBool': true
          },
          {
            "position": 15,
            "text": "新闻/动态",
            "path": "/TD",
            "showBool": true
          },
          {
            "position": 15,
            "text": "关于我们",
            "path": "/AU",
            "showBool": true
          },
          {
            "position": 15,
            "text": "订购服务",
            "path": "/services",
            "showBool": true
          }
        ],
        active: false,
        isCN: true,
        options: [{
          value: 'cn',
          label: '简体中文'
        }, {
          value: 'en',
          label: 'English'
        }],
        value: 'cn',
        show: true,
        //子菜单是否显示
        isShow: -1,
        dialogVisible: false,
        noticeBool: false,
        dialogClose: false,
        dotBool: false,
        unreadList: [],
        p: 0,
        navBarFixed: false,
        vipInfo: ''
      };
    },
    created () {
      var that = this;
      setTimeout(() => {
        if (that.$route.query.dialogVisible && that.$route.query.wechatLogin && that.$route.query.wechatAuth && !that.me) {
          that.dialogVisible = true;
        } else {
          // that.dialogVisible = false;
        }
      }, 1500);

      this.value = localStorage.getItem('locale') || 'cn'
    },
    mounted () {
      var that = this;
      // that.initHeader();
      that.$nextTick(function () {
        window.addEventListener("scroll", that.init);
      });

      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
      this.editeApplicantName()
      this.getVipInfo()
      this.$EventBus.$on('applicant',() => {
        this.editeApplicantName()
      })
    },
    components: {
      login
    },
    computed: {
      ...mapState(["me", "noticeList", "headerBgBool"]),
    },
    methods: {
      getVipInfo(){
        this.$axios
          .get("/admin/member/user/get").then(({data}) => {
          if(data.code == 0){
            if(data.data) {
              switch (data.data.card.cardLevel) {
                case '10': this.vipInfo = 'VIP_A'; break;
                case '20': this.vipInfo = 'VIP_B'; break;
                case '30': this.vipInfo = 'VIP_C'; break;
                case '40': this.vipInfo = 'VIP_D'; break;
              }
            }else {
              this.vipInfo = ''
            }
          }
        })
      },
      startTimer() {
        if (isPaused) return; // 如果已经暂停，不再开始计时
        timer = setInterval(() => {
          seconds++;
          console.log(`计时: ${seconds} 秒`);
        }, 1000);
      },
      pauseTimer() {
        clearInterval(timer); // 清除计时
        isPaused = true;
      },
      resumeTimer() {
        isPaused = false;
        startTimer(); // 恢复计时
      },
      //手机号正则
      getIphone(val){
        if(val.indexOf("86") == 0){
          var matchReg = /^86/;
          return val.replace(matchReg, "");
        }else if(val.indexOf("852") == 0){
          var matchReg = /^852/;
          return val.replace(matchReg, "");
        }else if(val.indexOf("853") == 0){
          var matchReg = /^853/;
          return val.replace(matchReg, "");
        }else {
          return val
        }
      },
      editeApplicantName(){
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicant = data.data.filter(item => item.storeFlag)
          }
        })
      },
      //页面跳转
      jump(item){
        if(item.path && item.path.indexOf('://') != -1){
          window.open(item.path)
          this.isFlag = false
          this.isFlags = false
        }else if(item.path) {
          if(item.path == '/services'){
            if (this.tool.getCookie("auth")) {
            } else {
              this.dialogVisible = true
              return;
            }
          }

          if(item.path == '/hostingPlatform'){

            if(this.applicant.length > 0){
              this.$router.push({
                path: '/hostingPlatforms'
              });
              this.isFlag = false
              this.isFlags = false
              return;
            }
          }

          this.$router.push({
            path: item.path
            // query: {
            //   tag: 1
            // }
          });
          this.isFlag = false
          this.isFlags = false
        }
      },
      liLive() {
        if (this.isFlag) {
          setTimeout(() => {
            if (this.isFlags == false) {
              this.isFlag = false;
              this.isFlags = false;
            }
          }, 50)
        }
      },
      //鼠标移入
      enter(item){
        if(item.children && item.children.length > 0) this.isFlag = true
        else this.isFlag = false
        this.subMenu = item.children
      },
      mouseover(){
        this.$store.commit('setPatentTranslation',true)
        this.$store.commit('setTrademark',true)
        this.$store.commit('setPatentSearch',true)
      },
      //切换语言
      changeLanguage(){
        // i18n
        this.$router.push("/index");
        localStorage.setItem("locale", this.value);
        this.$i18n.locale = this.value || 'cn'
        this.$nextTick(()=>{
          location.reload();//强制刷新页面
        })
      },
      //鼠标移入
      mouseenter(){
        this.show = true
      },

      //菜单鼠标移出
      mouseleave(index) {
        this.isShow = -1
      },

      ...mapMutations(["setMe", "setNoticeList"]),
      // initHeader () {
      //   this.$t(`headers.navList`).forEach((item, index) => {
      //     if (item.path == '/PR' && process.env.API_ROOT != 'https://test.lanternfish.cn') {
      //       this.$set(item, 'showBool', false)
      //     }
      //   })
      // },
      init() {
        var _this = this;
        _this.p = document.documentElement.scrollTop || document.body.scrollTop;
        if (_this.p > 64) {
          _this.navBarFixed = true
        } else {
          _this.navBarFixed = false
        }
      },
      // 回到首页
      toHome () {
        if(this.$route.path == '/entrance'){
          window.scrollTo(0, 0)
        }
        this.$router.push({
          path: "/entrance?a=1"
        });
      },
      // 登录
      loginEvent () {
        this.dialogVisible = true;
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        this.noticeEvent();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      // 个人中心、退出登录事件
      async handleCommand (item) {
        if (item.path) this.$router.push({ path: item.path });
        else if (item.text == 'Login out' || '退出登录') {
          const authData = await this.$axios.delete("/auth/token/logout");
          if (authData.data.code == 0) {
            this.tool.clearCookie('auth');
            this.applicant = []
            this.setMe("");
            this.setNoticeList("");
            this.$router.push({
              path: "/index"
            });
          }
        }
      },
      // 更新通知消息
      noticeEvent () {
        this.unreadList = [];
        for (var i = 0; i < this.noticeList.length; i++) {
          if (this.noticeList[i].isLook == 0) {
            this.dotBool = true;
            this.unreadList.push(this.noticeList[i]);
          }
        }
      },
      mouseleaveEvent () {
        this.noticeBool = false;
        if (this.dotBool) {
          this.updateMessageAjax();
        }
      },
      // 更新蓝灯鱼用户通知消息状态
      async updateMessageAjax () {
        let params = [];
        for (var i = 0; i < this.unreadList.length; i++) {
          params.push(this.unreadList[i].id);
        }
        let data = await this.$axios.put(
          "/lantern/messageLan/updateMessage",
          {
            longList: JSON.stringify(params)
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8"
            }
          }
        );
        if (data.data.code == 0) {
          this.dotBool = false;
        }
      }
    },
    watch: {
      $route:{
        handler(val,oldval){
          if(this.$router.history.current.fullPath.indexOf('/entrance') != -1){
            this.active = true
          }else {
            this.active = false
          }
        },
        // 深度观察监听
        deep: true
      }
    }
  };
</script>
<style lang="less" scoped>
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 14px;
    &.active{
      color: #FEFCFC!important;
    }
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .nav_wrap {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1008;
    background: #08061E;
    height: 90px;
    /*border-bottom: 1px solid rgba(97, 115, 216, 0.23);*/

    &.active {
      background: #08061E !important;
      color: white;
    }

    .logo {
      width: 173px;
      height: 50px;
      /*position: absolute;*/
      /*top: 15px;*/
      /*margin-top: -20px;*/
      /*left: 306px;*/
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .menu_cen{
      position: absolute;
      top: 85px;
      z-index: 9999 !important;
      width: 100%;
      height: 230px;
      padding-top: 33px;
      background: #0A0829;

      li {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
      .subMenu_title{
        position: relative;
        margin-right: 35px;
        &:before {
          position: absolute;
          right: -20px;
          top: 4px;
          content: '';
          width: 1px;
          height: 16px;
          background:  #979797;
        }
      }

      .subMenu{
        font-size: 16px;
        font-weight: 400;
        color: #C5C5C5;
        cursor: pointer;
        margin-right: 32px;
        &:hover{
          color: #2E54D9;
        }
      }
      .menu_cen_body{
        width: 1300px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        dl{
          width: 97px;
          margin-top: 22px;
          text-align: center;
          position: relative;
          img{
            width: 97px;
            height: 96px;
          }
          dd{
            width: 97px;
            text-align: center;
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }
          &:before{
            position: absolute;
            content: '';
            width: 1px;
            height: 58px;
            left: -50px;
            top: 28px;
            background: rgba(151,151,151,0.68);
          }
        }
        li{
          margin-bottom: 18px;
        }
      }
    }
    .nav_con {
      max-width: 1400px;
      height: 90px;
      /*&.active{*/
      /*  width: 1320px;*/
      /*}*/
      /*margin: 0 18% 0 26%;*/
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      border: none;
      .nav {
        /*line-height: 90px;*/
        display: contents;
        color: #fff;
        ul{
          display: flex;
          li{
            text-align: center;
            cursor: pointer;
            margin: 0 20px;
            position: relative;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            width: 86px;
            &.active {
              width: 65px;
            }
            height: 90px;
            display: flex;
            align-items: center;
            &.active:hover:before {
              position: absolute;
              bottom: 21px;
              left: -9px;
              content: '';
              width: 65px;
              height: 6px;
              background: #2E54D9;
              border-radius: 3px;
            }
            &:hover:before {
              position: absolute;
              bottom: 21px;
              left: -9px;
              content: '';
              width: 86px;
              height: 6px;
              background: #2E54D9;
              border-radius: 3px;
            }
          }
        }
        li.nav_li {
          cursor: pointer;
          display: inline-block;
          margin: 0 25px;
          position: relative;
          transition: color ease-in-out 0.2s;
          &.active {
            i {
              display: block;
              width: 100%;
              height: 3px;
              background: @blue;
              position: absolute;
              left: 0;
              bottom: 14px;
            }
          }
          &::after {
            content: "";
            width: 100%;
            height: 3px;
            background: @blue;
            position: absolute;
            left: 0;
            bottom: -12px;
            -webkit-transform-origin: right;
            -ms-transform-origin: right;
            transform-origin: right;
            -webkit-transform: scaleX(0);
            -ms-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: -webkit-transform 0.75s
            cubic-bezier(0.19, 1, 0.22, 1);
            transition: -webkit-transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
            -o-transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
            transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
            transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1),
            -webkit-transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
          }
          .nav_li:hover {
            cursor: pointer;
            &::after {
              -webkit-transform-origin: left;
              -ms-transform-origin: left;
              transform-origin: left;
              -webkit-transform: scaleX(1);
              -ms-transform: scaleX(1);
              transform: scaleX(1);
            }
          }

          /*.navChildren:hover{*/
          /*  display: block;*/
          /*}*/

          .navChildren{
            /*width: 101px;*/
            /*display: none;*/
            padding: 10px 0;
            color: rgb( 9, 9, 9 );;
            background: #FFFFFF;
            border: 1px solid #ebeef5;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            position: absolute;
            z-index: 9999;
            margin-top: -10px;
            left: -26px;
            li{
              text-align: center;
              cursor: pointer;
              white-space: nowrap;
              line-height: 33px!important;
            }
            li:hover{
              color: #3d61df!important;
            }
            &::before{
              content: '';
              display: inline-block;
              width: 0;
              height: 0;
              border: 8px solid transparent;
              border-bottom: 8px solid #AFABAB;
              position: relative;
              top: 2px;
              left: 10px;
            }
          }
        }

        .el-dropdown{
          margin: 0 23px;
          /*&.active{*/
          /*  margin: 0 12px;*/
          /*}*/
          border: none;
          position: relative;
          .el-dropdown-link{
            color: #090909;
            border: none;
            font-size: 14px
          }

        }
      }
      .login {

        /deep/.en_cn_Switch{
          .el-input__inner{
            height: 30px;
            line-height: 30px;
          }
        }
        li {
          display: inline-block;
          cursor: pointer;
          color: #3d3d3d;
          &.en_btn {
            margin-right: 40px;
          }
          &.login_btn {
            font-size: 16px;
            /*width: 88px;*/
            height: 30px;
            padding: 0 10px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #ffffff;
            border-radius: 8px;
            color: #fff;

            &:hover {
              box-shadow: @boxShadow;
            }
          }
          &.notice {
            color: #fff;
            font-size: 22px;
            margin-left: 20px;
            position: relative;
            margin-top: -4px;
            .el-icon-bell {
              &:hover {
                color: @blue;
              }
            }
            .notice_po {
              width: 30px;
              height: 27px;
              position: absolute;
              left: 50%;
              margin-left: -15px;
              .notice_con {
                position: absolute;
                top: 24px;
                right: -210px;
                z-index: 1006;
                width: 510px;
                max-height: 490px;
                overflow: auto;
                background-color: #ffffff;
                box-shadow: 0px 6px 40px 0px rgba(110, 119, 123, 0.14);
                border: solid 1px #ededed;
                padding: 32px 0 50px;
                box-sizing: border-box;
                li {
                  &:last-child {
                    margin-left: 0;
                  }
                  &:hover {
                    cursor: pointer;
                    background: #f8f8f8;
                  }
                  p {
                    width: 440px;
                    margin: 0 34px;
                    padding: 20px 0;
                    font-size: 14px;
                    border-bottom: 1px solid#e7e6e6;
                    span {
                      display: block;
                      color: #4c4c4c;
                      margin-bottom: 9px;
                      line-height: 22px;
                      b {
                        color: @blue;
                        margin-right: 10px;
                      }
                    }
                    i {
                      color: #888888;
                    }
                  }
                  &.no_notice {
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    color: #888888;
                    &:hover {
                      background: #fff;
                      cursor: default;
                    }
                  }
                }
                &.noDefault {
                  cursor: default;
                }
              }
            }
            .en_cn_Switch{

            }
          }
        }
      }
    }
    &.navwhite {
      background: #fff;
      .nav_con {
        .nav {
          color: #090909;
        }
      }
      .login {

        li {
          &.notice {
            color: #010101;
          }
        }
      }
    }
  }
  .fade-enter-active {
    margin-top: 0;
    transition: margin-top 0.3s linear;
  }

  .fade-enter {
    margin-top: -90px;
  }

  .fade-leave-active {
    margin-top: -90px;
    transition: margin-top 0.3s linear;
  }

  .fade-leave {
    margin-top: 0;
  }
</style>
<style lang="less" >
  .el-dropdown-link:focus {
    outline: 0;
  }
  .sub_tilte{
    font-weight: bold;
    font-size: 15px!important;
    color: rgb( 51, 51, 51 )!important;
    /*border-bottom: 1px solid #f1f1f1!important;*/
    /*padding: 0;*/
    background: url("@{imgUrl}cancat/label.jpg") no-repeat ;
    background-color: transparent!important;
    background-size: 100% 2px;
    background-position: 42% 100%;
  }

  .sub_tiltes{

  }

  .login_dialog {
    width: 683px;
    height: 470px;
    //   height: 500px;
    border-radius: 5px;
    /*padding: 10px 5px!important;*/
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }
  .nav_headers {
    .el-dropdown {
      color: #fff;
    }
    &.navwhite {
      .el-dropdown {
        color: #000;
      }
    }
  }
</style>

<template>
  <div>
    <div class="order_wrap">
      <div style="height: 74px"></div>
      <div class="order_con">

        <div class="order_con_cen">
          <p>{{$route.query.name?$route.query.name:''}}</p>

          <div class="step1">
            <div class="step1_left">
              <p class="step1_left_title1">对公支付</p>
              <p class="step1_left_title2">订单编号: <span>{{orderId?orderId:''}}</span></p>
              <p class="step1_left_title3">请于24小时内完成转账并上传凭证，我们会尽快确认到账并开通服务。如您有任何疑问，请通过右侧方式联系我们</p>
            </div>

            <div>
              <img style="height: 150px" src="/images/index/mpian.jpg" alt="">
            </div>
          </div>

          <div class="step2">
            <div class="step1_left">
              <p class="step1_left_title1">对公转账流程</p>
              <p style="margin-top: 15px" class="step1_left_title2">第一步: <span>对公汇款</span></p>
              <p class="step1_left_title2">公司名称: <span>北京蓝灯鱼智能科技有限公司</span></p>
              <p class="step1_left_title2">纳税人识别号: <span>9111 0108 MA01 7NAY 0R</span></p>
              <p class="step1_left_title2">地址、电话: <span>北京市海淀区知春路甲48号3号楼3单元14层17C（010-56571525）</span></p>
              <p class="step1_left_title2">开户行及帐号: <span>招商银行北京世纪城支行 1109 3011 1210 201</span></p>
              <p v-if="this.$route.query.vip" class="step1_left_title2">汇款金额: <span>{{$route.query.price?($route.query.price/100):''}}元</span></p>
              <p v-else class="step1_left_title2">汇款金额: <span>{{$route.query.price?$route.query.price:''}}元</span></p>
              <p class="step1_left_title2">汇款备注: <span>{{msgIphone}}</span></p>
            </div>

            <div>
              <p style="margin-top: 34px;" class="step1_left_title2">第二步: <span>上传凭证并开通服务</span></p>
              <p class="step1_left_title2"> <span style="font-size: 14px">(1)</span>&nbsp;&nbsp;<span>打开个人中心-我的订单，上传凭证</span></p>
              <p class="step1_left_title2"><span style="font-size: 14px">(2)</span>&nbsp;&nbsp;<span>蓝灯鱼审核通过后，会尽快为您开通服务</span></p>
            </div>
          </div>

          <div style="margin-left: 378px;margin-top: 30px;padding-bottom: 48px">
            <el-button type="primary" @click="voucher">上传凭证</el-button>
            <el-button @click="returnOrder" style="margin-left: 14px;background: #ffffff">返回我的订单</el-button>
          </div>
        </div>

      </div>
    </div>
    <xfooter></xfooter>
    <!--    上传凭证-->
    <el-dialog class="el_dialog" title="" :visible.sync="dialogTableVisible">
      <div>
        <el-upload
          class="upload"
          ref="upload"
          :headers="headers"
          :on-remove="onRemove"
          :on-change="beforeUpload"
          :on-error="errorUpload"
          action="/admin/sys-file/upload"
          :on-success="changeUpload"
          list-type="picture-card"
          :auto-upload="false">
          <div id="isShow" slot="file" slot-scope="{file}">
            <img
              style="height: 100%;width: auto"
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
            >
          </div>
          <i id="isShows" slot="default" style="font-size: 60px;color: #e6e6e6" class="el-icon-plus">
          </i>
        </el-upload>
      </div>

      <div class="dialog" style="text-align: center">
        <p style="margin-top: 12px;margin-bottom: 50px">{{imgName}}</p>
        <el-button class="el_button" style="margin-right: 44px;font-size: 12px;width: 86px;height: 34px" size="mini" @click="submit" type="primary">提交</el-button>
        <el-button class="el_button"  style="font-size: 12px;width: 86px;height: 34px" size="mini" @click="empty">重新上传</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  import { voucherPay, voucherPayTradmark, voucherVipPayTradmark } from "@/request/trademarkPayment";
  import zfb from '@/assets/images/common/zfb.jpg'
  import gong from '@/assets/images/common/gong.jpg'
  import wx from '@/assets/images/common/wxs.jpg'
  import xfooter from '@/components/common/xfooter'
  import info from "@/components/account/info"
  export default {
    name: "orderInfo",
    data () {
      return {
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        //msg
        msgIphone:'',
        //点击上传的钩子
        upload:true,
        //记录最后一张图片的地址
        recordImg:'',
        //是否重新上传
        uploadAgain: true,
        //是否显示
        isShow:false,
        //上传图片得名字
        imgName:'文件名称.jpg',
        //上传图片得地址
        imgSrc:'',
        actionUrl: '',
        dialogTableVisible: false,
        //照片上传
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        detailData: {},
        disabledBool: false,
        dialogClose: false,
        //订单编号
        orderId: ''
      }
    },
    mounted () {
      // this.actionUrl = process.env.API_ROOT;
      // this.init();
      this.getContactLanAjax();
      if(this.$route.query.id){
        if(this.$route.query.vip) {
          this.orderId = this.$route.query.orderId
        }else {
          this.payAjaxProductStatus()
        }
      }else {

        this.orderId = this.$route.query.orderId
      }
    },
    components: { info, xfooter },
    methods: {
      async payAjaxProductStatus(){
        let {data} = await this.$axios.get(`/order/order/getLanternOrderDetail/${this.$route.query.id}`);

        if (data.code == 0) {
          this.orderId = data.data.orderNum
        }
      },
      // 文件删除的钩子
      onRemove(){
      },
      //返回我的订单
      returnOrder(){
        this.$router.push({
          path: '/personalCenter/myOrder'
        })
      },
      //文件上传前的清空
      emptys(){
        // if(document.getElementsByClassName('el-upload--picture-card')[0])  document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'block'
        // if(document.getElementsByClassName('el-upload-list__item')[0]) document.getElementsByClassName('el-upload-list__item')[0].style.display = 'none'
        // this.imgName = '文件名称.jpg'
        this.$refs.upload.clearFiles();
      },
      //重新上传
      empty(){
        // this.emptys()
        this.upload = true
        this.$refs['upload'].$refs['upload-inner'].handleClick()
      },

      // 上传图片
      beforeUpload (file) { //上传前的函数
        if(!this.upload) return
        // this.emptys();
        if(file.name){
          if(document.getElementsByClassName('el-upload--picture-card')[0]) document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'none'
          if(document.getElementsByClassName('el-upload-list__item')[0]) document.getElementsByClassName('el-upload-list__item')[0].style.display = 'block'
          this.isShow = true
          this.imgName = file.name
          // this.recordImg = file.name
        } else{
          if(document.getElementsByClassName('el-upload--picture-card')[0]) document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'block'
          if(document.getElementsByClassName('el-upload-list__item')[0]) document.getElementsByClassName('el-upload-list__item')[0].style.display = 'none'
          this.imgName = '文件名称.jpg'
        }
      },
      //上传失败的钩子
      errorUpload(err){
        this.$message.error('上传凭证失败');
        this.dialogTableVisible = false
      },

      async changeUpload (response, file, fileList) {
        this.upload = false
        if(file.response.code == 0) {
          if(file.name == this.imgName){
            this.imgSrc = file.response.data.url
            if(!this.imgSrc){
              this.$message({
                message: '请上传图片',
                type: 'warning'
              });
              return;
            }
            if(this.$route.query.vip){
              voucherVipPayTradmark({
                orderId: this.$route.query.payOrderId,
                paymentVoucher: this.imgSrc
              }).then(({data, code}) => {
                if(code == 0){
                  if(data){
                    this.$message({
                      message: '上传凭证成功',
                      type: 'success'
                    });
                    this.dialogTableVisible = false

                    if(this.$route.query.name == '续展订单'){
                      this.$router.push({
                        path: '/hostingPlatforms/informationupload'
                      })
                    }if(this.$route.query.name == '专利订单'){
                      this.$router.push({
                        path: "/hostingPlatforms/myAnnualFee"
                      })
                    }else {
                      this.$router.push({
                        path: '/uploadSuccee',
                        query: {
                          tradmark: this.$route.query.tradmark ? this.$route.query.tradmark : undefined
                        }
                      })
                    }

                  }else {
                    this.$message.error('上传凭证失败');
                  }
                  this.dialogTableVisible = false
                }
              })
            }else if(this.$route.query.tradmark == 'tradmark'){
              voucherPayTradmark({
                renewalOrderNumber: this.orderId,
                "orderImageUrl": this.imgSrc
              }).then(({data, code}) => {
                if(code == 0){
                  if(data){
                    this.$message({
                      message: '上传凭证成功',
                      type: 'success'
                    });
                    this.dialogTableVisible = false

                    if(this.$route.query.name == '续展订单'){
                      this.$router.push({
                        path: '/hostingPlatforms/informationupload'
                      })
                    }if(this.$route.query.name == '专利订单'){
                      this.$router.push({
                        path: "/hostingPlatforms/myAnnualFee"
                      })
                    }else {
                      this.$router.push({
                        path: '/uploadSuccee',
                        query: {
                          tradmark: this.$route.query.tradmark ? this.$route.query.tradmark : undefined
                        }
                      })
                    }

                  }else {
                    this.$message.error('上传凭证失败');
                  }
                  this.dialogTableVisible = false
                }
              })
            }
            else if(this.$route.query.tradmark == 'patent'){
              let {data} = await this.$axios.post("/custody/annuity/order/corporatePayment", {
                platformOrderNumber: this.$route.query.number,
                corporatePaymentUrl: this.imgSrc
              });
              if(data.code == 0){
                this.$message({
                  message: '上传凭证成功',
                  type: 'success'
                });
                this.dialogTableVisible = false
                this.$router.push({
                  path: '/uploadSuccee',
                  query: {
                    tradmark: this.$route.query.tradmark ? this.$route.query.tradmark : undefined
                  }
                })
                this.dialogTableVisible = false
              }else {
                this.$message.error('上传凭证失败');
              }
              // voucherPayTradmark({
              //   renewalOrderNumber: this.$route.query.orderId,
              //   "orderImageUrl": this.imgSrc
              // }).then(({data, code}) => {
              //   console.log(data, code,"data, code")
              //
              // })
            }else {
              voucherPay({
                "orderNum": this.orderId,
                "paymentVoucher": this.imgSrc,
                "paymentMethod": 'PAY_VOUCHER'
              }).then(res => {
                if(res.code == 0){
                  if(res.data){
                    this.$message({
                      message: '上传凭证成功',
                      type: 'success'
                    });
                    this.dialogTableVisible = false
                    if(this.$route.query.name == '续展订单'){
                      this.$router.push({
                        path: '/hostingPlatforms/informationupload'
                      })
                    }else {
                      this.$router.push({
                        path: '/uploadSuccee'
                      })
                    }
                  }else {
                    this.$message.error('上传凭证失败');
                  }
                  this.dialogTableVisible = false
                }
              })
            }
          }
        }else {
          this.$message.error('图片上传失败')
          this.dialogTableVisible = false
        }

      },
      //提交
      submit(){

        this.$refs.upload.submit();
      },
      //图片上传
      handleRemove(file) {
        // console.log(file);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleDownload(file) {
        // console.log(file);
      },
      //上传凭证
      voucher(){
        this.dialogTableVisible = true
      },
      // 选择
      pays(items){
        let data = this.payMethod.filter(item => {
          return item.name == '对公支付'
        })
        if(data[0].chooseBool){
          this.$router.push()
          return ;
        }
      },
      //   初始化
      async init () {
        let data = await this.$axios.get(`/product/productservicemanage/${this.$route.query.id}`);
        if (data.data.code == 0) {
          this.detailData = data.data.data;
        }
      },
      // payMethodEvent (data) {
      //   if (data.chooseBool) {
      //     return;
      //   }
      //   this.payMethod.forEach((item, index) => {
      //     item.chooseBool = false;
      //   })
      //   data.chooseBool = !data.chooseBool;
      // },

      infoEvent () {
        this.dialogVisible = true;
      },
      getDialog (param) {
        this.dialogVisible = param;
        this.getContactLanAjax();
      },
      //  获取联系人信息
      async getContactLanAjax () {
        let data = await this.$axios.get("/admin/contact/getContact");
        if (data.data.msg == "success") {

          if (data.data.data != null) {
            this.msgIphone = data.data.data.phone
            this.disabledBool = false;
          } else {
            this.disabledBool = true;
          }
        }
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
    }
  }
</script>
<style lang="less" scoped>
  .upload{
    text-align: center;
    /deep/.el-upload-list li{
      display: none!important;
      &:last-of-type{
        display: block!important;
      }
      #isShow{
        height: 100%;
      }
    }
    /deep/.el-upload-list__item{
      height: 200px;
    }
  }
  .order_wrap {
    /deep/.el_dialog{
      width: 492px!important;
      height: 374px!important;
      .dialog{
        .el_button{
          width: 86px;
          height: 34px;
          font-size: 12px;
        }
      }

    }
    /*min-height: calc(100vh - 85px);*/
    /*padding: 89px 100px 9px;*/
    box-sizing: border-box;
    background: #f2f2f2;
    .order_con {
      .order_con_cen{
        width: 1210px;
        margin: 0 auto;
        .step1{
          padding: 38px 36px 0px 36px;
          display: flex;
          justify-content: space-between;
          box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
          width: 856px;
          height: 214px;
          margin: 63px 0 0 115px;
          box-sizing: border-box;
          .step1_left{
            position: relative;
            padding-left: 7px;
            &:before{
              position: absolute;
              left: 0;
              top: 2px;
              content: '';
              width: 4px;
              height: 16px;
              background: #2d5cc8;
            }
          }
          .step1_left_title1{
            font-weight: bold;
            font-size: 14px;
            color: red;
            color: rgb( 51, 51, 51 );
          }

          .step1_left_title2{
            font-weight: 500;
            font-size: 14px;
            margin-top: 19px;
            margin-bottom: 22px;
            font-size: 14px;
            color: red;
            color: rgb( 51, 51, 51 );
          }

          .step1_left_title3{
            width: 338px;
            font-size: 12px;
            color: rgb( 51, 51, 51 );
          }
        }
        .step2{
          padding: 38px 36px 0px 36px;
          display: flex;
          justify-content: space-between;
          box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
          width: 856px;
          height: 280px;
          margin: 63px 0 0 115px;
          box-sizing: border-box;
          .step1_left{
            position: relative;
            padding-left: 7px;
            &:before{
              position: absolute;
              left: 0;
              top: 2px;
              content: '';
              width: 4px;
              height: 16px;
              background: #2d5cc8;
            }
          }
          .step1_left_title1{
            font-weight: bold;
            font-size: 14px;
            color: red;
            color: rgb( 51, 51, 51 );
          }

          .step1_left_title2{
            line-height: 27px;
            font-weight: 500;
            font-size: 13.414px;
            color: rgb( 51, 51, 51 );
          }


          .step1_left_title3{
            width: 338px;
            font-size: 12px;
            color: rgb( 51, 51, 51 );
          }
        }
        .payment{
          margin-bottom: 22px;
          display: flex;
          justify-content: space-between;
          padding: 39px 76px 0 58px;
          box-sizing: border-box;
          box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
          width: 845px;
          height: 203px;
          .payment_title{
            font-size: 14px;
            color: rgb( 51, 51, 51 );
            position: relative;
            &:before{
              content: '';
              position: absolute;
              left: -6px;
              top: 1px;
              width: 4px;
              height: 16px;
              background: @blue;
            }

          }
          .payment_sub_title{
            font-size: 14px;
            color: rgb( 51, 51, 51 );
          }
          .payment_content{
            width: 350px;;
            font-size: 11.498px;
            padding-left: 8px;
            box-sizing: border-box;
            margin-top: 22px;
            font-family: "PingFang";
            color: rgb( 51, 51, 51 );
            -moz-transform: matrix( 1.04369915311199,0,0,1.04369915311199,0,0);
            -webkit-transform: matrix( 1.04369915311199,0,0,1.04369915311199,0,0);
            -ms-transform: matrix( 1.04369915311199,0,0,1.04369915311199,0,0);
          }
        }
        .payments{
          margin-bottom: 22px;
          display: flex;
          justify-content: space-between;
          padding: 39px 76px 0 58px;
          box-sizing: border-box;
          box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
          width: 845px;
          height: 226px;
          .payment_title{
            font-size: 14px;
            color: rgb( 51, 51, 51 );
            position: relative;
            &:before{
              content: '';
              position: absolute;
              left: -6px;
              top: 1px;
              width: 4px;
              height: 16px;
              background: @blue;
            }

          }
          .payment_sub_titles{
            .开户银行：北京蓝灯鱼智能科技有限公司 {
              font-size: 11.498px;
              color: rgb( 51, 51, 51 );
            }

          }
          .payment_content{
            width: 350px;;
            font-size: 11.498px;
            padding-left: 8px;
            box-sizing: border-box;
            margin-top: 22px;
            color: rgb( 51, 51, 51 );
          }
        }
      }
      width: 100%;
      min-height: calc(100vh - 190px);
      /*@media screen and (max-width: 300px) {*/
      /*  body {*/
      /*    background-color:lightblue;*/
      /*  }*/
      /*}*/
      background: #fff;
      padding: 30px 0 0px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
      .order_info {
        width: 900px;
        margin: 0 auto;
        h6 {
          font-size: 16px;
          color: rgb( 51, 51, 51 );
          font-weight: bold;
        }
        .order_list {
          padding-left: 15px;
          box-sizing: border-box;
          .order_list_item {
            // 产品详情表格
            /deep/.tableRowClassName {
              height: 30px;
              /deep/ .cell {
                font-size: 14px;
                color: rgb(51, 51, 51);
              }

              td {
                border-bottom: 1px solid #dce4fe;
                line-height: 82px;
              }
            }
            /deep/.tableRowHeader{
              height: 30px;
              font-size: 14px;
              color: rgb( 51, 51, 51 );
            }
            line-height: 55px;
            color: #595a5e;
            font-size: 14px;
            label {
              margin-top: 35.5px;
              margin-right: 40px;
              font-size: 16px;
              display: block;
              color: rgb( 51, 51, 51 );
              font-weight: bold;
            }
            .red {
              color: #eb5657;
            }
            p {
              display: inline-block;
              height: 44px;
              line-height: 44px;
              padding: 0 15px;
              box-sizing: border-box;
              border: solid 1px #cccccc;
              position: relative;
              cursor: pointer;
              border-radius: 6px;
              margin-right: 30px;
              b {
                display: inline-block;
                width: 20px;
                vertical-align: -7px;
                img {
                  display: block;
                  height: 25px;
                }
              }
              span {
                margin-left: 6px;
                font-size: 16px;
                color: rgb( 51, 51, 51 );
              }
              &.active {
                border: solid 1px @blue;
                i {
                  width: 16px;
                  height: 16px;
                  background: url("@{imgUrl}common/choose.png") no-repeat center;
                  background-size: 100%;
                  position: absolute;
                  top: -5px;
                  right: -4px;
                }
              }
            }
            .order_detail_con {
              display: inline-block;
              margin-bottom: 25px;
              .order_detail {
                display: flex;
                width: 420px;
                border-radius: 3px;
                border: solid 1px #ecf0f4;
                dl {
                  display: inline-block;
                  text-align: center;
                  flex: 1;
                  dt {
                    height: 37px;
                    background-color: #f8f9fb;
                    border-bottom: 1px solid #eff2f5;
                    line-height: 37px;
                    color: #999999;
                  }
                  dd {
                    line-height: normal;
                    padding: 10px 0;
                    span {
                      color: @blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .order_hint {
        width: 900px;
        font-size: 14px;
        color: rgb( 51, 51, 51 );
        padding-left: 15px;
        box-sizing: border-box;
        span {
          color: @blue;
          cursor: pointer;
        }
      }
      .amount{
        width: 900px;
        margin: 40px auto 0px;
        font-size: 18px;
        color: rgb( 51, 51, 51 );
        font-weight: bold;
        padding-left: 15px;
        span{
          font-size: 34px;
          color: rgb( 255, 106, 55 );
        }
      }
      .order_btn {
        width: 900px;
        margin: 26px auto 0;
        position: relative;
        display: flex;
        align-items: flex-end;
        .el-order-btn {
          margin-left: 10px;
          width: 144px;
          height: 52px;
          background: @blue;
          font-size: 20px;
          color: rgb( 255, 255, 255 );
          line-height: 57px;
          border-radius: 3px;
          padding: 0;
          border: none;

          &:hover {
            box-shadow: @boxShadow;
          }
          &.disabled {
            background-color: #d9d9dc;
            cursor: default;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">

  .wxCode_dialog {
    .el-dialog__header {
      display: none;
    }
    #qrcode {
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }
  }
</style>
<style lang="less">
  .account_dialog {
    width: 600px;
    height: 740px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
    border-radius: 5px;
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .info_wrap {
      .info_con {
        padding: 45px 80px 80px;
      }
    }
  }

  .el_dialog{
    .el-upload--picture-card{
      padding-top: 20px;
      width: 100%;
      border: none;
      background: #f2f2f2;

    }

    .el-upload-list__item{
      width: 100%;
      border: none!important;
      img{
        border: none!important;
      }
    }
    .el-dialog{
      width: 500px;
    }

    .el-upload--picture-card{
      padding-top: 20px;
      width: 100%;
      border: none;
      background: #f2f2f2;

    }

    .el-upload-list__item{
      width: 100%;
      border: none!important;
      img{
        border: none!important;
      }
    }
    .el-dialog{
      width: 500px;
    }
  }
</style>

<template>
  <div class="index_wrap">
    <h1 class="h_none">商标管理系统</h1>
    <h2 class="h_none">蓝灯鱼商标管理、商标、流程化管理</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div class="index_banner">
      <i class="index_banner_light"></i>
      <i class="index_banner_lightT"></i>
      <div class="index_banner_title"></div>
    </div>

    <section class="top">
      <span>
        商标管理系统
      </span>
    </section>
    <section class="subject">
      <!--      inquiry.png-->
      <div class="subject_left">
        <img src="../../assets/images/patent/inquiry.png" alt="">
        <div class="subject_left_rud">
          <p>
            产品优势：智能化、专业化、标准化、<br/>
            案件流程全生命周期管理&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
      </div>

      <div class="subject_right">
        <div class="subject_right_title">
          面向商标代理机构，案件流程全生命周期管理
        </div>
        <ul>
          <li>
            产品是一款面向「商标代理机构」提供「全类型」商标业务的流程管理系统。
          </li>
          <li>
            支持商标内内、内外、外内和外外所涉及的所有商标业务类型；灵活安全的商标案卷及流程数据管理；任务办案时限自动提醒；自动生成报告、信函模板，自动官申提交；便捷的信函模板和文件模板配置，灵活的权限配置；CS架构，系统处理和交互速度更快，不受外部网络影响。
          </li>
        </ul>
        <el-popover
          class="contact"
          placement="top"
          width="350"
          trigger="hover">
          <img v-if="isCN" style="width: 100%;height: auto" src="/images/index/mpian.jpg" alt="">
          <img v-else style="width: 100%;height: auto" src="/images/index/mpian_en.png" alt="">
          <el-button type="primary" slot="reference">联系我们</el-button>
        </el-popover>
      </div>

    </section>
    <footers></footers>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import login from "@/components/common/login"

  export default {
    name: 'index',
    metaInfo: {
      title: '商标管理系统', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标管理系统、商标、管理系统、商标数据、商标信息、流程化管理'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼商标管理系统是一款面向「商标代理机构」提供「全类型」商标业务的流程管理系统。可灵活安全的商标案卷及流程数据管理，任务办案时限自动提醒，自动生成报告、信函模板，自动官申提交，便捷的信函模板和文件模板配置，灵活的权限配置。'
      }],
    },
    data() {
      return {
        isCN: true,
        dialogClose: false,
        dialogVisible: false,
        listData: [
          {
            categoryCode: "shangbiaoyewu",
            categoryName: "商标业务",
            currency: "CNY",
            existApply: false,
            imageUrl: "patent_mgr_system.png",
            modeType: "4",
            price: '全球数据 周期自选',
            productName: "专利翻译",
            publishType: "4",
            serviceDesc: "流程化管理",
            button: true,
            btnName: "购买",
            serviceName: "商标管理系统"
          }
        ],
        //走马灯图片名
        name: '',
        lun: [{
          imgSrc: '/images/index/query_index1.png',
          name: '动漫检索'
        }]
      }
    },
    mounted() {
      this.name = this.lun[0].name
      if (this.$i18n.locale === 'cn') {
        this.isCN = true
      } else {
        this.isCN = false
      }
    },
    components: {
      footers,
      login
    },
    methods: {
      //产品定价
      btn(item) {
        if (this.tool.getCookie("auth")) {
          if (item.btnName == "购买") {
            this.$router.push({
              path: '/services',
              query: {
                typeId: 27
              }
            })
          } else {

            // this.applicaEvent()
          }
        } else {
          this.dialogVisible = true
        }
      },
      //走马灯
      select(item) {
        this.name = this.lun[item].name
      },
      immediate() {
        this.$router.push({
          path: '/PT'
        })
      },
      // 登录弹层隐藏
      loginDialog(data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F1F3FA;

    .index_banner {
      width: 100%;
      height: 560px;
      background: url("@{imgUrl}patent/tradmarks.png") no-repeat center
        center/auto 100%;
      position: relative;

      .index_banner_light {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/tradmarks.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }

      .index_banner_lightT {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/tradmarks.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }

      .index_banner_title {
        position: absolute;
        left: 110px;
        top: 193px;
        z-index: 3;

        i {
          display: block;
          width: 343px;
          margin-bottom: 30px;

          img {
            display: block;
            width: 100%;
          }
        }

        p:nth-child(1) {
          font-size: 40px;
          color: rgb(255, 255, 255);
        }

        p:nth-child(2) {
          margin-top: 29px;
          font-size: 20px;
          color: rgb(255, 255, 255);
        }

        .el_button {
          margin-top: 30px;
          width: 123px;
          height: 41px;
          font-size: 18px;
          color: rgb(27, 48, 191);
          font-weight: bold;
        }
      }
    }

    .top {
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 238px;
      padding-top: 46px;
      margin: 0 auto;
      margin-bottom: 51px;

      span {
        position: relative;
        z-index: 999;
      }

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 238px;
        height: 12px;
        background: rgba(38, 77, 216, 0.58);
        border-radius: 5px;
      }

      &.patent:before {
        left: 11px;
      }
    }


    .subject {
      width: 1202px;
      margin: 0 auto;
      position: relative;

      .subject_left {
        img {
          position: relative;
          z-index: 5;
          width: 512px;
          height: 330px;
        }

        .subject_left_rud {
          top: -201px;
          left: 268px;
          position: relative;
          z-index: 4;
          width: 410px;
          height: 410px;
          background: #CCD8FF;
          font-size: 20px;
          font-weight: 600;
          color: #091567;
          border-radius: 50%;
          line-height: 42px;

          p {
            position: absolute;
            text-align: right;
            bottom: 93px;
            left: 40px;
          }
        }
      }

      .subject_right {
        width: 550px;
        position: absolute;
        top: 34px;
        right: 0;

        .subject_right_title {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
        }

        .contact {
          position: absolute;
          top: 437px;
          right: 0px;
        }

        ul {
          width: 470px;
          top: 65px;
          right: 0;
          height: 156px;
          text-align: left;
          position: absolute;

          li {
            font-size: 18px;
            font-weight: 400;
            color: #555555;
            line-height: 36px;

            &:nth-of-type(1) {
              position: relative;

              &:after {
                content: '';
                width: 463px;
                height: 2px;
                bottom: -32px;
                position: absolute;
                left: 0;
                background: rgba(151, 151, 151, 0.47);
              }
            }

            &:nth-of-type(2) {
              margin-top: 68px !important;
            }

            .characteristics {
              color: #3c3d41;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .index_wrap {
      .index_banner {
        height: 460px;

        .index_banner_title {
          /*top: 150px;*/
          /*left: 133px;*/
        }
      }

      .learnMore {
        margin-top: 110px !important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .index_wrap {
      .index_banner {
        height: 602px;

        .index_banner_title {
          /*top: 210px;*/
          /*left: 34px;*/
        }
      }
    }
  }

  .arrow-enter {
    transform: translateX(-30px);
  }

  .arrow-enter-active {
    transition: all 0.8s ease;
  }
</style>

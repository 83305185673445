<template>
  <div class="index_wrap">
    <h1 class="h_none">商标管理系统</h1>
    <h2 class="h_none">蓝灯鱼商标管理、商标、流程化管理</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div class="index_banner">
      <i class="index_banner_light"></i>
      <i class="index_banner_lightT"></i>
      <div class="index_banner_title"></div>
      <div class="weilai">
        智见蔚来
      </div>
    </div>

    <section class="top">效率工具</section>
    <section class="subject">
      <!--      inquiry.png-->
      <ul>
        <li :key="index" v-for="(item,index) in dataTool">
          <img class="horn" src='../../assets/images/patent/horn.png' alt="">
          <div class="incon">
            <img :src='"../../assets/images/patent/"+ item.icon +".png"' alt="">
          </div>
          <p class="title">{{item.title}}</p>
          <div class="detail">{{item.detail}}</div>
          <span class="line"></span>
          <p class="advantages">{{item.advantages}}</p>
          <el-button @click="load(item.id)" class="btn" type="primary">去下载</el-button>
        </li>
      </ul>

    </section>
    <footers></footers>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import login from "@/components/common/login"
  export default {
    name: 'index',
    metaInfo: {
      title: '商标管理系统', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标管理系统、商标、管理系统、商标数据、商标信息、流程化管理'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼商标管理系统是一款面向「商标代理机构」提供「全类型」商标业务的流程管理系统。可灵活安全的商标案卷及流程数据管理，任务办案时限自动提醒，自动生成报告、信函模板，自动官申提交，便捷的信函模板和文件模板配置，灵活的权限配置。'
      }],
    },
    data () {
      return {
        isCN: true,
        dialogClose: false,
        dialogVisible: false,
        listData:[
          {categoryCode: "shangbiaoyewu",
            categoryName: "商标业务",
            currency: "CNY",
            existApply: false,
            imageUrl: "patent_mgr_system.png",
            modeType: "4",
            price: '全球数据 周期自选',
            productName: "专利翻译",
            publishType: "4",
            serviceDesc: "流程化管理",
            button:true,
            btnName:"购买",
            serviceName: "商标管理系统"}
        ],
        //走马灯图片名
        name:'',
        lun:[{
          imgSrc: '/images/index/query_index1.png',
          name:'动漫检索'
        } ],
        dataTool:[
          {
            id: 1,
            title: '「机翻Trados（2019版）插件」',
            detail: '是一款基于SDL Trados Studio的机器翻译插件，能够使得Trados软件直接调取「蓝灯鱼机器翻译引擎」可以在翻译过程中低成本快速获取机器翻译结果，实现多语言的批量翻译',
            advantages: '产品优势：翻译效率提升、兼容性强、降低翻译成本、免费赠送10万字',
            icon: 'icon'
          },
          {
            id: 2,
            title: '「蓝灯鱼机翻Word插件」',
            detail: '是一款基于Microsoft Office Word的机器翻译插件，能够使得word软件直接调取「蓝灯鱼机器翻译引擎」可以在翻译过程中低成本快速获取机器翻译结果，实现多语言的批量翻译',
            advantages: '产品优势：翻译效率提升、兼容性强、降低翻译成本、免费赠送10万字',
            icon: 'word'
          },
          {
            id: 3,
            title: '「蓝灯鱼机翻Excel插件」',
            detail: '是一款基于Microsoft Office Excel的机器翻译插件，能够使得Excel软件直接调取「蓝灯鱼机器翻译引擎」可以在翻译过程中低成本快速获取机器翻译结果，实现多语言的批量翻译',
            advantages: '产品优势：翻译效率提升、兼容性强、降低翻译成本、免费赠送10万字',
            icon: 'excel'
          },
          {
            id: 4,
            title: '「蓝灯鱼专利五书拆分」',
            detail: '是一款支持word版专利申请文件自动拆分为符合CPC提交要求的PDF格式“五书”的效率工具。可应用于专利代理机构以及有专利申请业务并自行提交申请的企事业单位及科研院所等；',
            advantages: '产品优势：一件拆分、自动命名、转换PDF格式、高效便捷',
            icon: 'chafen'
          },
          {
            id: 5,
            title: '「专利CPC附图一键压缩」',
            detail: '是一款针对专利申请中附图不符合CPC提交要求时，一键压缩生成符合合格图片的效率工具。可应用于专利代理机构、企事业单位、高校、科研院所处理专利申请附图尺寸时使用；',
            advantages: '产品优势：按照专利类型选择压缩要求、像素可按尺寸和比例调整、一键转换并转存PDF、简便易用',
            icon: 'cpcicon'
          },
          {
            id: 6,
            title: '「蓝灯鱼CPC官文提取工具」',
            detail: '是一款基于CPC系统文件结构，能够自动将CPC官文ZIP包中的官文PDF文本提取出并生成excel列表，其遵循专利业务实用性原则，自动对部分官文进行组合（如受理通知书与缴费通知书）处理，方便用户管理和使用。',
            advantages: '产品优势：CPC官文ZIP包自动解压提取、自动官文组合、自动生成官文列表',
            icon: 'cpcicon'
          },
          {
            id: 7,
            title: '「专利撰写形式核查助手」',
            detail: '是一款针对撰写发明或实用新型专利申请文件过程中经常出现的问题，如标点问题、权项引用问题、附图问题、缺所述问题、附图与实施例不对应等问题而开发的一款效率工具。',
            advantages: '产品优势：核查多种形式问题，并给出提示和位置；内嵌到word当中，方便易用；提高核查效率',
            icon: 'cpcicon'
          }
        ]
      }
    },
    mounted() {
      this.name = this.lun[0].name
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
    },
    components: {
      footers,
      login
    },
    methods: {
      //去下载
      load(id){
        if (this.tool.getCookie("auth")) {
            this.$router.push({
              path: '/downloadCenter',
              query: {
                id
              }
            })
        } else {
          this.dialogVisible = true
        }
      },
      //产品定价
      btn(item){
        if (this.tool.getCookie("auth")) {
          if(item.btnName == "购买"){
            this.$router.push({
              path: '/services',
              query: {
                typeId: 27
              }
            })
          }else {

            // this.applicaEvent()
          }
        } else {
          this.dialogVisible = true
        }
      },
      //走马灯
      select(item){
        this.name = this.lun[item].name
      },
      immediate(){
        this.$router.push({
          path: '/PT'
        })
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F1F3FA;
    .index_banner {
      .weilai {
        width: 123px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #000000;
        font-weight: bold;
        font-style: normal;
        position: absolute;
        bottom: 50px;
        right: 122px;
        z-index: 2;
      }
      width: 100%;
      height: 560px;
      background: url("@{imgUrl}patent/fficiencyTools.png") no-repeat center
        center/auto 100%;
      position: relative;
      .index_banner_light {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/fficiencyTools.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }
      .index_banner_lightT {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/fficiencyTools.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }
      .index_banner_title {
        position: absolute;
        left: 110px;
        top: 193px;
        z-index: 3;
        i {
          display: block;
          width: 343px;
          margin-bottom: 30px;
          img {
            display: block;
            width: 100%;
          }
        }
        p:nth-child(1){
          font-size: 40px;
          color: rgb( 255, 255, 255 );
        }
        p:nth-child(2){
          margin-top: 29px;
          font-size: 20px;
          color: rgb( 255, 255, 255 );
        }
        .el_button{
          margin-top: 30px;
          width: 123px;
          height: 41px;
          font-size: 18px;
          color: rgb( 27, 48, 191 );
          font-weight: bold;
        }
      }
    }

    .top{
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 164px;
      margin: 21px auto 0;
      margin-bottom: 51px;
      &:before{
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 164px;
        height: 12px;
        background: rgba(38,77,216,0.58);
        border-radius: 5px;
      }
    }

    .subject{
      width: 1200px;
      margin: 0 auto;
      position: relative;
      ul{
        li{
          padding: 20px 0 0 27px;
          box-sizing: border-box;
          position: relative;
          width: 1200px;
          height: 288px;
          margin-bottom: 78px;
          background: #FFFFFF;
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
          border-radius: 15px;
          .horn{
            position: absolute;
            top: 0;
            left: 0;
            width: 61px;
            height: 55px;
          }
          .incon{
            position: absolute;
            right: 73px;
            top: -64px;
            width: 126px;
            height: 126px;
            border-radius: 50%;
            background: #F1F3FA;
            border: 1px solid #DFE3EC;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 61px;
              height: 71px;
            }
          }
          .title {
            font-size: 26px;
            font-weight: 600;
            color: #666666;
          }
          .detail{
            text-align:justify;
            text-justify:inter-ideograph;
            margin-top: 35px;
            padding-left: 10px;
            width: 820px;
            font-size: 20px;
            font-weight: 400;
            color: #888888;
          }
          .line{
            display: block;
            width: 649px;
            height: 1px;
            background: rgba(151,151,151,0.47);
            margin: 38px 0 30px 10px;
          }
          .advantages{
            padding-left: 10px;
            font-size: 20px;
            font-weight: 500;
            color: #666666;
          }
          .btn{
            position: absolute;
            right: 65px;
            bottom: 53px;
            width: 140px;
            height: 50px;
            background: #2E54D9;
            border-radius: 8px;
            font-size: 22px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .index_wrap {
      .index_banner {
        height: 460px;
        .index_banner_title {
          /*top: 150px;*/
          /*left: 133px;*/
        }
      }

      .learnMore{
        margin-top: 110px!important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .index_wrap {
      .index_banner {
        height: 602px;
        .index_banner_title {
          /*top: 210px;*/
          /*left: 34px;*/
        }
      }
    }
  }

</style>

<template>
  <div class="myRenewal">
<!--    <section class="search-option">-->
<!--      <span class="search_title active">订单编号</span>-->
<!--      <el-input v-model="searchObj.renewalOrderNumber" placeholder="请输入内容" class="_one"/>-->

<!--      <span style="margin-left: 20px" class="search_title">订单状态</span>-->
<!--      <el-select class="_one" v-model="searchObj.renewalOrderStatus" placeholder="请选择">-->
<!--        <el-option-->
<!--          v-for="item in options"-->
<!--          :key="item.value"-->
<!--          :label="item.label"-->
<!--          :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->

<!--      <span style="margin-left: 20px" class="search_title">创建时间</span>-->
<!--      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="searchObj.orderCreateStartDateStr" type="date" placeholder="选择时间"/>-->
<!--      <i style="margin-right: 5px" class="el-icon-minus"/>-->
<!--      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="searchObj.orderCreateEndDateStr" type="date" placeholder="选择时间"/>-->
<!--      <div class="function">-->
<!--        <el-button style="width: 85px;border-radius: 10px;text-align: center" @click="claer">清空</el-button>-->
<!--        <el-button type="primary" style="margin-right: 59px;width: 85px;border-radius: 10px;text-align: center;margin-left: 20px" @click="queryEvent">筛选</el-button>-->
<!--      </div>-->
<!--    </section>-->
    <div class="my_order_main">
      <div class="search_input">
        <el-input  size="small"  v-model="searchObj.searchKeyWord" placeholder="请输入关键词搜索..."></el-input>
        <i  @click="queryEvent" style="color: #2D3748;font-size: 18px;margin-left: 5px" class="el-icon-search"></i>
      </div>
      <vxe-grid
        stripe
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="697"
        empty-text="暂无订单"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="left"
        header-align="left"
        ref='xGrid'
        v-bind="gridOptions"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >
        <template #orderPaymentType="{ row }">
          {{row.orderPaymentType == 0 ? '未知' : row.orderPaymentType == 1 ? '支付宝' : row.orderPaymentType == 2 ? '微信' : '对公支付'}}
        </template>
        <template #renewalOrderStatus="{ row }">
          {{getState(row.renewalOrderStatus)}}
        </template>
        <template #operation="{ row }">
          <div class="operation" >
            <div v-if="row.renewalOrderStatus == 0" @click="payEvent(row.renewalOrderNumber)">支付</div>
            <div v-if="row.renewalOrderStatus == 50" @click="viewReceipts(row)">查看收据</div>
            <div v-if="row.renewalOrderStatus == 50" @click="downloadReceipt(row)">下载收据</div>
            <div v-if="row.renewalOrderStatus != 0 && row.renewalOrderStatus < 50" @click="viewOrder(row.renewalOrderNumber)">查看订单</div>
            <div v-if="row.renewalOrderStatus == 60" @click="viewOrder(row.renewalOrderNumber)">查看订单</div>
            <div v-if="row.applicantFileFlag == 0 && row.renewalOrderStatus != 0 && row.renewalOrderStatus != 50" @click="uploadFile(row.renewalOrderNumber)">上传文件</div>
          </div>
        </template>
        <template #pager>
          <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                         :current-page="searchObj.current" :page-size="searchObj.size"  :page-sizes="[10, 15, 20, 50, 100]"
                         layout="total, sizes, prev, pager, next" :total="total">
          </el-pagination>
        </template>
      </vxe-grid>
    </div>

    <el-dialog :visible.sync="dialogTableVisible" width="860px" custom-class="detail_dialog"
               :close-on-click-modal="false">
      <div class="report_con">
        <div class="report_con_item">
          <h6 style="margin-bottom: 30px">订单状态</h6>
          <el-steps v-if="detailData.renewalOrderStatus != 60" :space="170" :active="detailData.renewalOrderStatus/10" finish-status="success">
            <el-step title="支付成功"></el-step>
            <el-step title="确认信息中"></el-step>
            <el-step title="提交官费中"></el-step>
            <el-step title="等待官方收据"></el-step>
            <el-step title="订单完成"></el-step>
          </el-steps>

          <el-steps v-else :space="170" :active="3" finish-status="success">
            <el-step title="支付成功"></el-step>
            <el-step title="确认信息中"></el-step>
            <el-step title="退款成功"></el-step>
          </el-steps>
        </div>
        <div class="report_con_item">
          <h6>订单信息</h6>
          <div class="report_list_item">
            <span>订单编号:</span>
            <p>{{detailData.renewalOrderNumber}}</p>
          </div>
          <div class="report_list_item">
            <span>创建时间:</span>
            <p>{{detailData.orderCreateTime}}</p>
          </div>
          <div class="report_list_item">
            <span>订单金额:</span>
            <p>{{detailData.orderSummaryAmount+"元"}}</p>
          </div>
        </div>
        <div class="report_con_item">
          <h6>商标信息</h6>
          <el-table :data="detailTableData" style="width: 100%">
            <el-table-column style="width: 60px"  prop="serviceName" label="商标名称" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.trademarkName}}</span>
              </template>
            </el-table-column>
            <el-table-column  prop="trademarkImageUrl" label="商标图样" align="center">
              <template slot-scope="scope">
                <img style="width: 60px" :src="queryDataUrl(scope.row.trademarkImageUrl)" alt="">
              </template>
            </el-table-column>
            <el-table-column style="width: 60px" prop="serviceName" label="申请号" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.trademarkApplyNo}}</span>
              </template>
            </el-table-column>
            <el-table-column width="50" prop="serviceName" label="类别" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.renewalNiceCode}}</span>
              </template>
            </el-table-column>
            <el-table-column width="80" prop="serviceName" label="提交方式" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.submitType == 0 ? '电子' : '纸质'}}</span>
              </template>
            </el-table-column>
            <el-table-column width="80" prop="serviceName" label="续展官费" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.renewalAmount}}</span>
              </template>
            </el-table-column>
            <el-table-column width="65" prop="serviceName" label="宽展费" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.extendedAmount}}</span>
              </template>
            </el-table-column>
            <el-table-column width="65" prop="serviceName" label="代理费" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.agencyAmount}}</span>
              </template>
            </el-table-column>
            <el-table-column width="110" prop="serviceName" label="续展官费总计" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.renewalSummaryAmount}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="report_con_item">
          <h6>续展信息</h6>
          <div class="report_list_item">
            <span>申请人名称(中文):</span>
            <p>{{businessCustomer.applicantName}}</p>
          </div>
          <div class="report_list_item">
            <span>申请人地址:</span>
            <p>{{businessCustomer.applicantAddress}}</p>
          </div>
          <div class="report_list_item">
            <span>联系人名称:</span>
            <p>{{businessCustomer.contactPersonName}}</p>
          </div>
          <div class="report_list_item">
            <span>联系人邮编:</span>
            <p>{{businessCustomer.contactPostCode}}</p>
          </div>
          <div class="report_list_item">
            <span>联系人电话:</span>
            <p>{{businessCustomer.contactPersonPhone}}</p>
          </div>
          <div class="report_list_item">
            <span>{{idTypeEent(businessCustomer.idType)}}:</span>
            <p>{{businessCustomer.idCode}}</p>
          </div>
        </div>
        <div class="report_con_item">
          <h6>支付信息</h6>
          <div class="report_list_item">
            <span>支付时间:</span>
            <p>{{detailData.orderPaymentTime == null ? '--' : detailData.orderPaymentTime}}</p>
          </div>
          <div class="report_list_item">
            <span>支付方式:</span>
            <span>{{detailData.orderPaymentType == 0 ? '未知' : detailData.orderPaymentType == 1 ? '支付宝' : detailData.orderPaymentType == 2 ? '微信' : '对公支付'}}</span>
          </div>
          <div class="report_list_item">
            <span>支付金额:</span>
            <p>
              {{(detailData.isPaid == 'n' && detailData.orderSummaryAmount == 0) || detailData.orderSummaryAmount == null  ? '--' : detailData.orderSummaryAmount+"元"}}
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="800px" custom-class="_softwareUrl" style="align-items: center" title="查看收据" :visible.sync="dialogsoftwareUrlVisible">
      <pdf
        :src="softwareUrl"
      ></pdf>
    </el-dialog>
  </div>
</template>
<script>
  import pdf from 'vue-pdf'
  import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory";
  export default {
    data () {
      return {
        //表格配置项
        gridOptions:{
          id: 'annualFeeGrid',
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          height: 600,
          rowId: 'id',
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            stripe: true,
          },
          columns:[
            {
              align: 'left',
              field: 'renewalOrderNumber',
              title: '订单编号'
            },
            {
              align: 'left',
              field: 'orderCreateTime',
              title: '创建时间',
              sortable: true,
              sortType: 'string'
            },
            {
              align: 'left',
              field: 'orderSummaryAmount',
              title: '订单金额',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
              },
            },
            {
              align: 'left',
              field: 'orderPaymentType',
              title: '支付方式',
              slots: {
                default: 'orderPaymentType'
              }
            },
            {
              align: 'left',
              field: 'renewalOrderStatus',
              title: '订单状态',
              slots: {
                default: 'renewalOrderStatus'
              }
            },
            {
              align: 'left',
              field: 'patentOperate',
              fixed: "right",
              title: '操作',
              visible: true,
              width: "200px",
              slots: {
                default: 'operation'
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
        },
        businessCustomer: {},
        softwareUrl: '',
        dialogsoftwareUrlVisible: false,
        searchObj:{
          current: 1,
          size: 20,
          searchKeyWord: '',
          orderFiled: 'orderCreateTime',
          orderType: '',
          orderSummaryAmount: '',
          // 续展官费订单编号
          renewalOrderNumber: '',
          // 续展订单状态
          renewalOrderStatus: '',
          // 订单创建开始时间
          orderCreateStartDateStr: '',
          orderCreateEndDateStr: ''
        },
        options: [{
          value: 0,
          label: '待支付'
        }, {
          value: 10,
          label: '支付成功'
        }, {
          value: 20,
          label: '信息确认中'
        }, {
          value: 30,
          label: '提交官费中'
        }, {
          value: 40,
          label: '等待官方收据,'
        }, {
          value: 50,
          label: '订单完成,'
        }],
        value: '',
        quantity:1,
        publishType:'4',
        //总和数据库
        allDatabase:[],
        beginTime: "",
        endTime: "",
        tableData: [],
        detailTableData: [],
        dialogTableVisible: false,
        current: 1,
        size: 10,
        total: 0,
        detailData: {},
        modeType: '4',
        servicePeriod:['','一周','一个月','三个月','半年','一年'],
        type: 0
      }
    },
    mounted () {
      this.queryAjax();
    },
    computed:{

    },
    components: {
      pdf
    },
    methods: {
      //表格过滤变化触发
      filterChange() {
        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()
        let getSortColumns = this.$refs.xGrid.getSortColumns()
        this.searchObj.current = 1
        this.searchObj.orderType = undefined
        this.searchObj.orderSummaryAmount = ''

        if (getSortColumns.length > 0) {
          this.searchObj.orderType = getSortColumns[0].order
        } else {
          this.searchObj.orderType = undefined
        }
        if(getCheckedFilters.length > 0){
          getCheckedFilters.map(item => {
            switch (item.property) {
              //申请人名称
              case "orderSummaryAmount":
                this.searchObj.orderSummaryAmount = item.datas[0];
                break;
            }
          })
        }else {
          this.searchObj.orderSummaryAmount = ''
        }
        this.queryAjax();
      },
      idTypeEent(val) {
        let name = ''
        switch (val) {
          case '1':
            name = "统一信用代码";
            break;
          case '2':
            name = "身份证";
            break;
          case '3':
            name = "护照";
            break;
          case '4':
            name = "其它";
            break;
        }
        return name
      },
      //上传文件
      uploadFile(fileNumber){
        localStorage.setItem('ruleForm',JSON.stringify({
          orderNumber: fileNumber
        }))
        this.$router.push({
          path: "/hostingPlatforms/informationupload"
        })
      },
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      //查看收据
      viewReceipts(row){
        this.dialogsoftwareUrlVisible = true
        this.softwareUrl = pdf.createLoadingTask({ url: row.officialReceiptUrl, cMapUrl: 'https://unpkg.com/pdfjs-dist@2.9.359/cmaps/',
          cMapPacked: true });
      },
      //下载收据
      downloadReceipt(row){
        window.open(row.officialReceiptUrl)
      },
      //清空
      claer(){
        this.searchObj = {
          searchKeyWord:'',
          current: 1,
          size: 50,
          // 续展官费订单编号
          renewalOrderNumber: '',
          // 续展订单状态
          renewalOrderStatus: '',
          // 订单创建开始时间
          orderCreateStartDateStr: '',
          orderCreateEndDateStr: ''
        }
      },
      //获取支付状态
      getState(val){
        switch (val) {
          case 0: return '待支付'; break;
          case 10: return '支付成功'; break;
          case 20: return '信息确认中'; break;
          case 30: return '提交官费中'; break;
          case 40: return '等待官方收据'; break;
          case 50: return '订单完成'; break;
          case 60: return '退款成功'; break;
        }
      },
      //   购买
      buyEvent () {
        this.$router.push({
          path: "/services"
        })
      },
      // 查询
      queryEvent () {
        this.searchObj.current = 1;
        this.queryAjax();
      },
      async queryAjax () {
        let data = await this.$axios.post("/custody/renewal/order/page", this.searchObj);
        if (data.data.code == 0) {
          this.gridOptions.data = data.data.data.records;
          this.total = data.data.data.total;
        }
      },
      viewOrder (renewalOrderNumber) {
        this.$router.push({
          path: '/hostingPlatforms/myRenewalOrder',
          query: {
            id: renewalOrderNumber
          }
        })
        // this.detailTableData = [];
        // this.detailAjax(renewalOrderNumber)
        // this.dialogTableVisible = true;
      },
      // 详情
      async detailAjax (renewalOrderNumber) {
        let data = await this.$axios.get(`/custody/renewal/order/detail?renewalOrderNumber=` + renewalOrderNumber);
        if (data.data.code == 0) {
          this.detailData = data.data.data.renewalOrderList[0];
          this.detailTableData = data.data.data.renewalOrderList;
          this.businessCustomer = data.data.data.businessCustomer
        }
      },
      // 支付
      payEvent (renewalOrderNumber) {
        this.$router.push({
          path: "/hostingPlatforms/platformPay",
          query: {
            order: 'order',
            renewalOrderNumber: renewalOrderNumber,
            type: 'tradmark'
          }
        })
      },
      // 删除订单
      delEvent () {

      },
      toTop () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      },
      handleCurrentChange (val) {
        this.searchObj.current = val;
        this.toTop();
        this.queryAjax();
      },
      handleSizeChange (val) {
        this.searchObj.size = val;
        this.toTop();
        this.queryAjax();
      },
    }
  }
</script>
<style scoped lang="less">
  .myRenewal {
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }

    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    /deep/.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    /deep/.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    /deep/.sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    /deep/.vxe-grid--pager-wrapper {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(244,247,252,0.75);
      border-radius: 8px 8px 8px 8px;
      margin-top: 10px;
      text-align: right;
      ul {
        background: rgba(244,247,252,0.75);
      }
      button {
        background: rgba(244,247,252,0.75);
      }
      .el-input__inner {
        background: rgba(244,247,252,0.75);
        border: none;
      }
    }

    /deep/.vxe-cell--checkbox {
      .vxe-checkbox--icon {
        font-size: 16px;
        width: 17px;
        height: 16px;
        color: #ffffff;
        background: #ffffff;
        border-radius: 4px; /* 圆角 */
        border: none!important;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) ; /* 阴影效果 */
      }
    }
    /* 自定义 VXE-Table 复选框的阴影效果 */
    /deep/.vxe-cell--checkbox.is--checked {
      .vxe-checkbox--icon {
        /*border: 1px solid #dcdfe6!important; !* 边框颜色 *!*/
        &.vxe-icon-checkbox-checked {
          width: 18px;
          height: 17px;
          box-shadow: none !important; /* 阴影效果 */
          color: #2264E5!important;
        }
      }
    }

    /deep/.vxe-body--row {
      height: 64px;
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
    }

    /deep/.vxe-table--header {
      height: 70px;
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    /deep/.color-row1-1{
      background: #F5F7FA;
    }
    /deep/tbody {
      .vxe-cell {
        font-weight: 500;
        font-size: 12px;
        color: #464F60;
        .trademarkImageUrl {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
          border-radius: 8px!important;
          padding: 8px 31px!important;
          box-sizing: border-box;
        }

        .trademarkStatus {
          width: 52px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #EAECFF;
          border-radius: 20px;
          font-weight: 400;
          font-size: 12px;
          color: #4F5AED;
        }
      }
    }

    /deep/.vxe_grid_header {
      font-weight: 500;
      font-size: 14px;
      color: #464F60;
      border-radius: 5px 5px 0px 0px !important;
      overflow: hidden !important;
      background: rgba(244,247,252,0.75) !important;
    }

    /deep/.row--checked,/deep/.row--hover {
      background: #EBF0FA !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
      td:nth-of-type(1) {
        position: relative;
        &:before {
          width: 2px;
          height: 64px;
          background: #2264E5;
          border-radius: 0px 0px 0px 0px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .trademarkStatus {
        width: 52px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff!important;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #4F5AED;
      }
    }

    /deep/.vxe-body--row {
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
      .operation {
        display: flex;
        justify-content: space-between;
        div {
          width: 78px;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:nth-of-type(1) {
            background: #F2F6FF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #3B5789;
            font-weight: 400;
            font-size: 12px;
            color: #2555A2;
          }
          &:nth-of-type(2) {
            background: #2264E5;
            border-radius: 15px 15px 15px 15px;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
          }
        }
      }
    }

    /deep/.col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
    section.search-option {
      min-height: 100px;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      /*border: solid 1px #e4e4e4;*/
      margin-bottom: 20px;
      padding-top: 23px;
      padding-left: 19px;
      /*height: 135px;*/
      padding-bottom: 20px;
      .function{
        display: flex;
        justify-content: end;
      }
      .search_title {
        font-size: 14px;
        color: #474747;
        margin-right: 14px;
        &.active{
          position: relative;
          padding-left: 16px;
          &::before {
            position: absolute;
            content: '';
            width: 4px;
            height: 17px;
            background: #465CFC;
            left: 0;
            top: 2px;
          }
        }
      }

      div {
        margin-bottom: 10px;
        margin-right: 5px;
      }

      .el-select-long-officialt {
        width: 180px;
        height: 34px;
        border-radius: 3px;
        background: #ffffff;

        .el-input {
          input.el-input__inner {
            border: 1px solid #cccccc;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .one, .el-input {
        width: 180px;

        input {
          height: 34px !important;
          border-color: #ccc;
          color: inherit;
          padding: 0 4px;
        }
      }

      ._one /deep/ .el-input__inner {
        height: 34px;
      }

      .el-date-editor-long-officialt {
        width: 180px;
        height: 34px;
        border-radius: 3px;
        background: #ffffff;
        line-height: 32px;

        input.el-input__inner {
          border: 1px solid #cccccc;
          text-indent: 42px;
        }
      }
    }
    .my_order_main {

      .search_input {
        margin-top: 8px;
        margin-bottom: 20px;
        display: flex;
        width: 296px;
        height: 45px;
        align-items: center;
        padding: 0 10px;
        background: #F7F7F8;
        border-radius: 15px 15px 15px 15px;
        border: 1px solid #E2E8F0;
        i {
          cursor: pointer;
        }
        /deep/input {
          background: #F7F7F8;
          border: none;
        }
      }
      padding: 13px 19px;
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
      .el_table {
        overflow: hidden !important;
        /deep/.el-table__row:hover {
          background: #EBF0FA !important;
          -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
          -webkit-transform: translate3d(0, 0px, -2px);
          transform: translate3d(0, 1px, -2px);
          td:nth-of-type(1) {
            position: relative;
            &:before {
              width: 2px;
              height: 54px;
              background: #2264E5;
              border-radius: 0px 0px 0px 0px;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        /deep/.el-table__row:hover {
          td.el-table__cell {
            background: #EBF0FA!important;
          }
        }
        /deep/.el-table__row:hover {
          td.el-table__cell {
            background: #EBF0FA!important;
          }
        }
        /deep/.vxe_grid_header th{
          font-weight: 600;
          font-size: 14px;
          color: #464F60;
          height: 70px;
          background: rgba(244,247,252,0.75)!important;
        }
        /deep/tbody {
          .el-table__cell {
            font-weight: 500;
            font-size: 12px;
            color: #464F60;
            .trademarkImageUrl {
              display: flex;
              align-items: center;
              background: #FFFFFF;
              box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
              border-radius: 8px!important;
              padding: 8px 31px!important;
              box-sizing: border-box;
            }

            .trademarkStatus {
              width: 52px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #EAECFF;
              border-radius: 20px;
              font-weight: 400;
              font-size: 12px;
              color: #4F5AED;
            }
          }
        }
        .el_table {
          overflow: hidden !important;
          /deep/.el-table__row:hover {
            background: #EBF0FA !important;
            -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
            -webkit-transform: translate3d(0, 0px, -2px);
            transform: translate3d(0, 1px, -2px);
            td:nth-of-type(1) {
              position: relative;
              &:before {
                width: 2px;
                height: 54px;
                background: #2264E5;
                border-radius: 0px 0px 0px 0px;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
          /deep/.el-table__row:hover {
            td.el-table__cell {
              background: #EBF0FA!important;
            }
          }
          /deep/.vxe_grid_header th{
            font-weight: 600;
            font-size: 14px;
            color: #464F60;
            height: 70px;
            background: rgba(244,247,252,0.75)!important;
          }
          /deep/tbody {
            .el-table__cell {
              font-weight: 500;
              font-size: 12px;
              color: #464F60;
              .trademarkImageUrl {
                display: flex;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
                border-radius: 8px!important;
                padding: 8px 31px!important;
                box-sizing: border-box;
              }

              .trademarkStatus {
                width: 52px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #EAECFF;
                border-radius: 20px;
                font-weight: 400;
                font-size: 12px;
                color: #4F5AED;
              }
            }
          }
          .operation {
            display: flex;
            justify-content: space-between;
            div {
              width: 78px;
              height: 29px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:nth-of-type(1) {
                background: #F2F6FF;
                border-radius: 12px 12px 12px 12px;
                border: 1px solid #3B5789;
                font-weight: 400;
                font-size: 12px;
                color: #2555A2;
              }
              &:nth-of-type(2) {
                background: #2264E5;
                border-radius: 15px 15px 15px 15px;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
              }
            }
          }
        }
        .operation {
          display: flex;
          justify-content: space-between;
          div {
            width: 78px;
            height: 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:nth-of-type(1) {
              background: #F2F6FF;
              border-radius: 12px 12px 12px 12px;
              border: 1px solid #3B5789;
              font-weight: 400;
              font-size: 12px;
              color: #2555A2;
            }
            &:nth-of-type(2) {
              background: #2264E5;
              border-radius: 15px 15px 15px 15px;
              font-weight: 400;
              font-size: 12px;
              color: #FFFFFF;
            }
          }
        }
      }
      .order_upload,
      .order_detail {
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        cursor: pointer;
        img {
          width: 100%;
          display: block;
        }
      }
      .order_detail {
        width: 20px;
        margin-left: 16px;
        vertical-align: -4px;
      }
      .el_pagination_wrap {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: rgba(244,247,252,0.75);
        border-radius: 8px 8px 8px 8px;
        margin-top: 10px;
        text-align: right;
        /deep/ul {
          background: rgba(244,247,252,0.75);
        }
        /deep/button {
          background: rgba(244,247,252,0.75);
        }
        /deep/.el-input__inner {
          background: rgba(244,247,252,0.75);
          border: none;
        }
      }
      .no_table_data {
        margin-top: 60px;
        text-align: center;
        i {
          display: block;
          width: 80px;
          height: 80px;
          margin: 0 auto 40px;
          img {
            display: block;
            width: 100%;
          }
        }
        p {
          font-size: 15px;
          color: #949aa9;
          span {
            cursor: pointer;
            color: @blue;
          }
        }
      }
      .btn {
        width: 76px;
        height: 32px;
        color: #ffffff;
        background-color: #dec397;
        border-radius: 5px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        padding: 0;
        border: none;
        margin-right: 5px;
        &.pay {
          background-color: #dec397;
          border: solid 1px #dec397;
          color: #53dee3;
        }
        &:hover {
          box-shadow: none;
        }
      }
    }
    .detail_dialog .el-dialog__body {
      .report_con {
        padding: 0 60px 30px;
        color: #454545;
        .report_con_item {
          margin-bottom: 50px;
          h6 {
            color: #595a5e;
            font-size: 16px;
            position: relative;
            margin-bottom: 12px;
            &::before {
              content: "";
              width: 4px;
              height: 18px;
              background-color: @blue;
              position: absolute;
              top: 50%;
              margin-top: -9px;
              left: -11px;
            }
          }
          .report_list_item {
            line-height: 28px;
            display: flex;
            span {
              display: inline-block;
              /*width: 74px;*/
              font-size: 14px;
              color: #888888;
              margin-right: 10px;
              white-space: nowrap;
            }
            p {
              display: inline-block;
              width: 600px;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .myRenewal {
    .my_order_date.el-input {
      width: 160px;
    }
    /*.vxe_grid_header {*/
    /*  th{*/
    /*    background: #E2EFFF !important;*/
    /*  }*/
    /*}*/
    .my_order_date .el-input__inner {
      width: 160px;
      height: 34px;
      line-height: 34px;
      border: 1px solid #c2c6d0;
      border-radius: 5px;
      color: #666666;
    }
    .my_order_query_btn {
      width: 78px;
      height: 34px;
      line-height: 34px;
      background-color: @blue;
      border-radius: 5px;
      color: #fff;
      text-align: center;
      padding: 0;
      margin-left: 20px;
      border: none;
      &:focus,
      &:hover {
        border: none;
        color: #fff;
        background-color: @blue;
      }
      &:hover {
        box-shadow: @boxShadow;
      }
    }
  }

</style>

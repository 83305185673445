<template>
  <div>
    <div class="vxPay009">
      <section class="vx-pay-container">
        <div class="top">
          <div v-if="this.$route.query.vip" class="right">应付金额：<span  class="amount">{{ (price /100.0).toFixed(2) }}</span>元</div>
          <div v-else class="right">应付金额：<span  class="amount">{{ Number(price).toFixed(2)}}</span>元</div>
        </div>
        <div class="middle">
          <p class="text1">微信扫码支付</p>
          <p class="text2" v-if="lastTime">付款二维码将在 <span class="last-time">{{lastTime}}秒</span> 后失效,失效后请点击页面重获二维码</p>
          <p class="text2" v-if="!lastTime">二维码已过期，刷新页面重新获取二维码。</p>
          <div class="qrcode-container">
            <div id="qrcode"></div>
          </div>
          <div class="reminder">
            <img src="@/assets/images/common/saomiao.png" height="30" width="34" />
            <div class="reminder-text">
              <p>请使用微信扫一扫</p>
              <p>扫描二维码支付</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/common/xfooter'
import QRCode from 'qrcodejs2'
export default {
  name: "pay",
  data () {
    return {
      lastTime: "",
      timer: "",
      beginTime: '',
      price: 0,
    }
  },
  created () {
  },
  components: { footers },
  mounted () {
    this.price = this.$route.query.price;
    if(this.$route.query.tradmark == 'tradmark'){
      this.payAjaxTradmark();
    }else if(this.$route.query.tradmark == 'patent'){
      this.payAjax();
    }else {
      //产品服务支付
      this.payAjaxProduct();
    }
  },
  destroyed () {
    clearInterval(this.timer);
  },
  methods: {
    qrCode (url) {
      let qrcode = new QRCode('qrcode', {
        width: 220, //图像宽度
        height: 220, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      })
      qrcode.clear() //清除二维码
      qrcode.makeCode(url) //生成另一个新的二维码
    },
    async payAjaxTradmark () {
      let data = await this.$axios.post("/pay/order/submit", {
        channelCode: "wx_native",
        id: this.$route.query.orderId,
        returnUrl: window.location.origin
      });
      if (data.data.code == 0) {
        // data="{\"codeUrl\":\"weixin://wxpay/bizpayurl?pr=4bcNdVR\",\"payOrderId\":\"P0020190226032543000005\",\"sign\":\"59AD04863E65A0BADEE711CE74CEA93C\",\"resCode\":\"SUCCESS\",\"prepayId\":\"wx261125080991761b1bad093d1493353742\",\"retCode\":\"SUCCESS\",\"retMsg\":\"\"}";
        this.lastTime = 90;
        $("#qrcode").empty();
        const tradePage = data.data.data.displayContent
        this.qrCode(tradePage)
        this.beginTime = new Date();
        /*查看是否支付成功*/
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.lastTime--;
          if (this.lastTime == 0) {
            clearInterval(this.timer);
          }
          this.payStatusTradmark();
        }, 1000);
      }
    },
    async payAjax () {
      let data = await this.$axios.post("/pay/order/submit", {
        channelCode: "wx_native",
        id: this.$route.query.orderId,
        returnUrl: window.location.origin
      });
      if (data.data.code == 0) {
        // data="{\"codeUrl\":\"weixin://wxpay/bizpayurl?pr=4bcNdVR\",\"payOrderId\":\"P0020190226032543000005\",\"sign\":\"59AD04863E65A0BADEE711CE74CEA93C\",\"resCode\":\"SUCCESS\",\"prepayId\":\"wx261125080991761b1bad093d1493353742\",\"retCode\":\"SUCCESS\",\"retMsg\":\"\"}";
        this.lastTime = 90;
        $("#qrcode").empty();
        const tradePage = data.data.data.displayContent
        this.qrCode(tradePage)
        this.beginTime = new Date();
        /*查看是否支付成功*/
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.lastTime--;
          if (this.lastTime == 0) {
            clearInterval(this.timer);
          }
          this.payStatus();
        }, 1000);
      }
    },
    async payAjaxProduct(){
      let data = await this.$axios.post("/pay/order/submit", {
        channelCode: "wx_native",
        id: this.$route.query.orderId,
        returnUrl: window.location.origin
      });
      if (data.data.code == 0) {
        // data="{\"codeUrl\":\"weixin://wxpay/bizpayurl?pr=4bcNdVR\",\"payOrderId\":\"P0020190226032543000005\",\"sign\":\"59AD04863E65A0BADEE711CE74CEA93C\",\"resCode\":\"SUCCESS\",\"prepayId\":\"wx261125080991761b1bad093d1493353742\",\"retCode\":\"SUCCESS\",\"retMsg\":\"\"}";
        this.lastTime = 90;
        $("#qrcode").empty();
        const tradePage = data.data.data.displayContent
        this.qrCode(tradePage)
        this.beginTime = new Date();
        /*查看是否支付成功*/
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.lastTime--;
          if (this.lastTime == 0) {
            clearInterval(this.timer);
          }
          if(this.$route.query.vip && this.$route.query.vip == '1') {
            console.log(123456)
            this.payAjaxProductVipStatus();
          }else {
            this.payAjaxProductStatus();
          }
        }, 1000);
      }
    },
    /*查看是否支付成功*/
    async payStatusTradmark () {
      let data = await this.$axios.post(`/custody/renewal/order/detail?renewalOrderNumber=` + this.$route.query.renewalOrderNumber,{
        renewalOrderNumber: this.$route.query.renewalOrderNumber
      });
      if (data.data.code == 0) {
        if (data.data.data.renewalOrderList[0].renewalOrderStatus == 10) {
          clearInterval(this.timer);
          if(this.$route.query.tradmark == 'tradmark'){
            this.$router.push({
              path: "/hostingPlatforms/informationupload",
              query: {
                tradmark: 'tradmark',
                payType: 1,
                td: -11,
                renewalOrderNumber: this.$route.query.renewalOrderNumber
              }
            })
          }

        } else {
          if (new Date() - this.beginTime > 1000 * 60 * 60) {//1小时
            clearInterval(this.timer);
          }
        }
      }
    },
    /*查看是否支付成功*/
    async payStatus () {
      let {data} = await this.$axios.get(`/custody/annuity/order/detail/${this.$route.query.orderId}`);
      if (data.code == 0) {
        if (data.data.platformOrderStatus == 10) {
          clearInterval(this.timer);
          this.$router.push({
            path: "/hostingPlatforms/myAnnualFee",
            query: {
              type: 'pay'
            }
          })
        } else {
          if (new Date() - this.beginTime > 1000 * 60 * 60) {//1小时
            clearInterval(this.timer);
          }
        }
      }
    },
    //payAjaxProductVipStatus
    async payAjaxProductVipStatus(){
      let {data} = await this.$axios.get(`/order/member/order/query/${this.$route.query.orderId}`);
      if (data.code == 0) {
        if (data.data.status != 0) {
          clearInterval(this.timer);
          this.$router.push({
            path: "/personalCenter/myServer",
            query: {
              payType: 1,
              td: -11
            }
          })
        } else {
          if (new Date() - this.beginTime > 1000 * 60 * 60) {//1小时
            clearInterval(this.timer);
          }
        }
      }
    },
    async payAjaxProductStatus(){
      let {data} = await this.$axios.get(`/order/order/getLanternOrderDetail/${this.$route.query.id}`);
      if (data.code == 0) {
        if (data.data.orderStatus != 10) {
          clearInterval(this.timer);
          this.$router.push({
            path: "/personalCenter/myServer",
            query: {
              payType: 1,
              td: -11
            }
          })
        } else {
          if (new Date() - this.beginTime > 1000 * 60 * 60) {//1小时
            clearInterval(this.timer);
          }
        }
      }
    }
  },
  computed: {},
}
</script>

<style lang="less" scoped>
.vxPay009 {
  padding: 89px 100px 9px;
  min-height: calc(100vh - 99px);
  box-sizing: border-box;
  background: #f2f2f2;
  box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
  .vx-pay-container {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    min-height: calc(100vh - 203px);
  }
  .top {
    font-size: 0;
    overflow: hidden;
    height: 72px;
    line-height: 72px;
    border-bottom: 1px solid #e5e5e5;
    & > * {
      font-size: 14px;
      letter-spacing: 0px;
      color: #222222;
      display: inline-block;
    }
    .left {
      float: left;
      padding-left: 28px;
    }
    .right {
      float: right;
      padding-right: 68px;
      .amount {
        font-size: 18px;
        letter-spacing: 0px;
        color: #e13536;
      }
    }
  }
  .middle {
    text-align: center;
    padding-top: 43px;
    height: 417px;
    .text1 {
      font-size: 18px;
      letter-spacing: 0px;
      color: #222222;
      font-weight: bold;
    }
    .text2 {
      font-size: 12px;
      line-height: 20px;
      color: #777777;
      margin-top: 15px;
      .last-time {
        font-size: 12px;
        letter-spacing: 0px;
        color: #cc1212;
      }
    }
    .qrcode-container {
      width: 250px;
      height: 250px;
      border: solid 1px #cccccc;
      margin: 0 auto;
      margin-top: 10px;
      #qrcode,
      .qrcode {
        padding: 15px;
        img {
          width: 220px;
        }
      }
    }
    .reminder {
      width: 250px;
      height: 46px;
      line-height: 46px;
      background-color: #2c86f8;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 0;
      & > * {
        display: inline-block;
        font-size: 12px;
        vertical-align: middle;
      }
      .reminder-text {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 23px;
        text-align: left;
      }
    }
    .choose-other {
      width: 250px;
      height: 12px;
      margin: 0 auto;
      margin-top: 23px;
      font-size: 13px;
      letter-spacing: 0px;
      color: #608bf6;
      cursor: pointer;
      span {
        width: 23px;
        display: inline-block;
      }
    }
  }
}
</style>

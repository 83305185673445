<template>
  <div class="index_wrap">
    <h1 class="h_none">商标管理系统</h1>
    <h2 class="h_none">蓝灯鱼商标管理、商标、流程化管理</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div class="index_banner">
      <i class="index_banner_light"></i>
      <i class="index_banner_lightT"></i>
      <div class="index_banner_title">
        <p>数智平台</p>
        <p><span>智慧知产、数据互连</span></p>
      </div>
    </div>

    <section class="top">
      <span>
        智慧园区
      </span>
    </section>
    <section class="subject">
      <!--      inquiry.png-->
      <div class="subject_left">
        <img style="top: -25px" src="../../assets/images/smartPark/smartPark.png" alt="">
        <div class="subject_left_rud">
          <p class="text_item1">
            产品优势：
          </p>
          <div class="text_item2">
            基于大数据和AI技术<br/>
            平台交互、方便快捷<br/>
            专注知识产权管理和服务<br/>
            全面整合供需方业务需求
          </div>
        </div>
      </div>

      <div class="subject_right">
        <div class="subject_right_title">
          实时监控园区企业知识产权动态
        </div>
        <ul>
          <li>
            蓝灯鱼智慧园区管理平台是一款实时监控园区企业知识产权动态，园区知识产权保护态势，可用于通过分析园区企业知识产权情况，
            为园区知识产权服务提供管理工具。
          </li>
        </ul>
      </div>

      <el-popover
        class="contact item1"
        placement="top"
        width="350"
        trigger="hover">
        <img v-if="isCN" style="width: 100%;height: auto" src="/images/index/mpian.jpg" alt="">
        <img v-else style="width: 100%;height: auto" src="/images/index/mpian_en.png" alt="">
        <el-button type="primary" slot="reference">联系我们</el-button>
      </el-popover>
    </section>

    <section class="sub_part">
      <div class="top patent">
        <span>
          企业网盘
        </span>
      </div>
      <div class="subject patent">
        <!--      inquiry.png-->
        <div class="subject_left">
          <img src="../../assets/images/smartPark/part2.png" alt="">
          <div class="subject_left_rud">
            <p class="text_item1">
              产品优势：
            </p>
            <div class="text_item2">
              共享协作，权限灵活<br/>
              便捷搜索，快速定位<br/>
              加密存储，数据安全<br/>
              个性定制，实时追溯
            </div>
          </div>
        </div>

        <div class="subject_right">
          <div class="subject_right_title">
            面向IP代理机构和企业，文件云管理系统
          </div>
          <ul>
            <li>
              蓝灯鱼「企业网盘」是一款文件云管理可定制的产品，具有安全便捷、
              共享协作、灵活接入、实时同步的特点。
            </li>
            <li>
              <span class="characteristics">自身特点：</span>整合「文件」能力赋能给机构或企业现有信息系统，如邮件、OA、ERP等，成为企业文件入口沉淀各系统内文件；实现现有业务系统与网盘文件自动对接、存储及查询；
            </li>
          </ul>
        </div>
        <el-popover
          class="contact item2"
          placement="top"
          width="350"
          trigger="hover">
          <img v-if="isCN" style="width: 100%;height: auto" src="/images/index/mpian.jpg" alt="">
          <img v-else style="width: 100%;height: auto" src="/images/index/mpian_en.png" alt="">
          <el-button type="primary" slot="reference">联系我们</el-button>
        </el-popover>
      </div>
    </section>

    <footers></footers>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import login from "@/components/common/login"
  export default {
    name: 'index',
    metaInfo: {
      title: '商标管理系统', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标管理系统、商标、管理系统、商标数据、商标信息、流程化管理'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼商标管理系统是一款面向「商标代理机构」提供「全类型」商标业务的流程管理系统。可灵活安全的商标案卷及流程数据管理，任务办案时限自动提醒，自动生成报告、信函模板，自动官申提交，便捷的信函模板和文件模板配置，灵活的权限配置。'
      }],
    },
    data () {
      return {
        isCN: true,
        dialogClose: false,
        dialogVisible: false,
        listData:[
          {categoryCode: "shangbiaoyewu",
            categoryName: "商标业务",
            currency: "CNY",
            existApply: false,
            imageUrl: "patent_mgr_system.png",
            modeType: "4",
            price: '全球数据 周期自选',
            productName: "专利翻译",
            publishType: "4",
            serviceDesc: "流程化管理",
            button:true,
            btnName:"购买",
            serviceName: "商标管理系统"}
        ],
        //走马灯图片名
        name:'',
        lun:[{
          imgSrc: '/images/index/query_index1.png',
          name:'动漫检索'
        } ]
      }
    },
    mounted() {
      this.name = this.lun[0].name
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
    },
    components: {
      footers,
      login
    },
    methods: {
      //产品定价
      btn(item){
        if (this.tool.getCookie("auth")) {
          if(item.btnName == "购买"){
            this.$router.push({
              path: '/services',
              query: {
                typeId: 27
              }
            })
          }else {

            // this.applicaEvent()
          }
        } else {
          this.dialogVisible = true
        }
      },
      //走马灯
      select(item){
        this.name = this.lun[item].name
      },
      immediate(){
        this.$router.push({
          path: '/PT'
        })
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F1F3FA;
    .index_banner {
      width: 100%;
      height: 560px;
      background: url("@{imgUrl}smartPark/banner.png") no-repeat center
        center/auto 100%;
      position: relative;
      .index_banner_light {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}smartPark/banner.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }
      .index_banner_lightT {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}smartPark/banner.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }
      .index_banner_title {
        position: absolute;
        left: 18%;
        top: 282px;
        z-index: 3;
        i {
          display: block;
          width: 343px;
          margin-bottom: 30px;
          img {
            display: block;
            width: 100%;
          }
        }
        p:nth-child(1){
          font-size: 56px;
          line-height: 56px;
          font-weight: 600;
          color: #131313;
          letter-spacing: 2px;
        }
        p:nth-child(2){
          font-size: 30px;
          font-weight: 500;
          color: #091567;
          line-height: 30px;
          letter-spacing: 1px;
          margin-top: 48px;
          span{
            color: rgba(34, 77, 232, 1)
          }
        }
        .el_button{
          margin-top: 30px;
          width: 123px;
          height: 41px;
          font-size: 18px;
          color: rgb( 27, 48, 191 );
          font-weight: bold;
        }
      }
    }
    .top{
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 168px;
      margin: 0px auto 0;
      padding-top: 46px;
      margin-bottom: 51px;
      span {
        position: relative;
        z-index: 999;
      }

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 168px;
        height: 12px;
        background: rgba(38,77,216,0.58);
        border-radius: 5px;
      }
    }
    .subject{
      width: 1202px;
      margin: 0 auto;
      position: relative;
      .subject_left {
        height: 630px;
        img{
          position: relative;
          z-index: 5;
          width: 512px;
          height: 330px;
        }
        .subject_left_rud{
          display: flex;
          top: -201px;
          left: 268px;
          position: relative;
          z-index: 4;
          width: 410px;
          height: 410px;
          background: #CCD8FF;
          font-size: 20px;
          font-weight: 600;
          color: #091567;
          border-radius: 50%;
          line-height: 42px;
          .text_item1 {
            position: absolute;
            text-align: right;
            bottom: 184px;
            left: 35px;
          }
          .text_item2 {
            position: absolute;
            text-align: center;
            width: 410px;
            bottom: 57px;
            right: -20px;
          }
        }
      }
      .subject_right {
        width: 550px;
        position: absolute;
        top: 34px;
        right: 0;
        .subject_right_title{
          font-size: 24px;
          font-weight: 500;
          color: #333333;
        }
        ul{
          width: 470px;
          top: 65px;
          right: 0;
          height: 156px;
          text-align: left;
          position: absolute;
          li{
            font-size: 18px;
            font-weight: 400;
            color: #555555;
            line-height: 36px;
            &:nth-of-type(1){
              position: relative;
              &:after{
                content: '';
                width: 463px;
                height: 2px;
                bottom: -32px;
                position: absolute;
                left: 0;
                background: rgba(151,151,151,0.47);
              }
            }
            &:nth-of-type(2){
              margin-top: 68px!important;
            }
            .characteristics{
              color: #3c3d41;
              font-weight: bold;
            }
          }
        }
      }
      .contact{
        position: absolute;
        right: 0;
        &.item1{
          top: 355px;
        }
        &.item2{
          bottom: 100px;
          left: 0;
        }
      }
    }

    .sub_part{
      top{
        margin: 0 auto;
        padding-top: 46px;
      }
      background: white;
      .subject.patent{
        width: 1202px;
        height: 600px;
        margin: 0 auto;
        position: relative;
        .subject_left {
          position: absolute;
          right: 0;
          img{
            position: relative;
            z-index: 5;
            width: 512px;
            height: 330px;
          }
          .subject_left_rud{
            top: -190px;
            left: -190px;
            position: relative;
            z-index: 4;
            width: 410px;
            height: 410px;
            background: #CCD8FF;
            font-size: 20px;
            font-weight: 600;
            color: #091567;
            border-radius: 50%;
            line-height: 42px;
            display: flex;
            .text_item1 {
              position: absolute;
              text-align: right;
              bottom: 184px;
              left: 35px;
            }
            .text_item2 {
              position: absolute;
              text-align: center;
              width: 410px;
              bottom: 57px;
              right: -20px;
            }
          }
        }
        .subject_right {
          width: 550px;
          position: absolute;
          top: 60px;
          left: 0;
          .subject_right_title{
            padding-left: 69px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
          }
          ul{
            width: 470px;
            top: 65px;
            left: 0;
            height: 156px;
            text-align: left;
            position: absolute;
            li{
              font-size: 18px;
              font-weight: 400;
              color: #555555;
              line-height: 36px;
              &:nth-of-type(1){
                position: relative;
                &:after{
                  content: '';
                  width: 463px;
                  height: 2px;
                  bottom: -32px;
                  position: absolute;
                  left: 0;
                  background: rgba(151,151,151,0.47);
                }
              }
              &:nth-of-type(2){
                margin-top: 68px!important;
              }
              .characteristics{
                color: #3c3d41;
                font-weight: bold;
              }
            }
          }
        }
        .contact{
          position: absolute;
          bottom: 100px!important;
          right: 0;
        }
      }
    }

  }
  @media screen and (max-width: 1600px) {
    .index_wrap {
      .index_banner {
        height: 460px;
        .index_banner_title {
          top: 200px;
          left: 12%;
        }
      }
      .learnMore{
        margin-top: 110px!important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .index_wrap {
      .index_banner {
        /*height: 602px;*/
        .index_banner_title {
          top: 200px;
          left: 8%;
        }
      }
    }
  }
  .arrow-enter {
    transform: translateX(-30px);
  }
  .arrow-enter-active {
    transition: all 0.8s ease;
  }
</style>

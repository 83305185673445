<template>
  <div class="account_wrap">
    <section class="account_header">
      <dl class="account_info">
        <dt>
          <img v-if="me && me.avatar" :src="imgUrl" alt=""/>

          <img v-else src="@/assets/images/common/default_headimg.png" alt=""/>

          <el-upload
            class="upload-demo"
            :headers="headers"
            action="/admin/sys-file/upload"
            :before-upload="beforeUpload"
            :on-success="changeUpload"
            :show-file-list="false" accept="image/png,image/jpg,image/jpeg"
          >
            <el-button class="upload_btn" size="small" type="primary">
              {{$t('personalCenter.account.headImgBtn')}}
            </el-button>
          </el-upload>

          <span></span>
        </dt>

        <dd>
          <div class="account_info_part1">
            <p style="margin-top: 14px;width: 310px">
              <i style="width: 15px;height: 21px">
                <img src="@/assets/images/common/iphoneNumber.png" alt=""/>
              </i>

              <span @click="modifyEvent">
                Hi! {{me && me.phone ? getIphone(me.phone) : ''}}
              </span>

            </p>

            <p style="margin-bottom: 8px">
              <i style="width: 17px;height: 19px">
                <img src="@/assets/images/common/password.png" alt=""/>
              </i>

              <span @click="setPassword()" class="auth">
                设置密码
              </span>
            </p>

          </div>

<!--          <div>-->
<!--            <p style="margin-top: 14px">-->
<!--              <i style="width: 19px;height: 16px">-->
<!--                <img src="@/assets/images/common/weixin.png" alt=""/>-->
<!--              </i>-->

<!--              <span @click="authEvent(me)" class="auth">-->
<!--                {{me && me.wxOpenid != null && me.wxOpenid != "" ? me.nickname  : "微信授权登录 "}}-->
<!--              </span>-->
<!--            </p>-->
<!--          </div>-->

          <div class="account_btn" v-if="keyBtnBool && MchInfo == ''">
            <p>
              <i style="width: 14px;height: 18px">
                <img src="@/assets/images/common/secretKey.png" alt="">
              </i>

              <span class="btn" @click="getKeyEvent">
                获取API密钥
              </span>
            </p>
          </div>
        </dd>
      </dl>

      <dl class="account_info account_key" v-if="MchInfo != ''">
        <dd>
          <p>商户ID：{{MchInfo.mchId}}</p>
          <p>请求密钥：{{MchInfo.reqKey}}</p>
        </dd>
      </dl>
    </section>

    <section class="account_con">
      <section class="account_left">
        <div class="account_left_con">

          <div class="account_title">
            <h5>
              {{$t('personalCenter.account.contactTitle')}}
            </h5>

            <span @click="editEvent">
              <i></i>
              {{editType == 1 ? $t(`personalCenter.account.contactAddBtn`) : $t(`personalCenter.account.contactEditBtn`)}}
            </span>
          </div>

          <ul class="account_main" v-if="contactInfo">
            <li>
              <strong>{{$t('personalCenter.account.form.userName.label')}}</strong>
              <span>{{contactInfo.contactName}}</span>
            </li>

            <li>
              <strong>{{$t('personalCenter.account.form.phone.label')}}</strong>
              <span>{{contactInfo.contactPhone}}</span>
            </li>
          </ul>

          <ul class="account_main" v-else>
            <li>
              <strong>{{$t('personalCenter.account.form.userName.label')}}</strong>
              <span>--</span>
            </li>

            <li>
              <strong>{{$t('personalCenter.account.form.phone.label')}}</strong>
              <span>--</span>
            </li>
          </ul>

          <ul class="account_main" v-if="contactInfo">
            <li>
              <strong>{{$t('personalCenter.account.form.email.label')}}</strong>
              <span>{{contactInfo.contactEmail}}</span>
            </li>

            <li>
              <strong>{{$t('personalCenter.account.form.companyName.label')}}</strong>
              <span>{{contactInfo.companyName}}</span>
            </li>
          </ul>

          <ul class="account_main" v-else>

            <li>
              <strong>{{$t('personalCenter.account.form.email.label')}}</strong>
              <span>--</span>
            </li>

            <li>
              <strong>{{$t('personalCenter.account.form.companyName.label')}}</strong>
              <span>--</span>
            </li>
          </ul>

          <div style="font-size: 14px; margin-top: 10px">
            <strong style="margin-right: 20px; color: #888888">监控邮箱地址</strong>
            <div style="display:inline-block; font-size:11px; margin: 0 10px 10px 0; padding: 5px 10px; background-color: rgb(234,241,255)">
              <span v-if="monitorEmail.length != 0" style="display:inline-block; margin-right:10px; padding: 8px 0">{{monitorEmail.join("；")}}</span>

              <el-input
                v-if="addMonitorEmailShow"
                v-model="tempAddMonitorEmail"
                size="mini"
                style="display: inline-block; width: 110px; margin: 0 10px"
                @blur="doAddMonitorEmail"
              />

              <i
                @click="addMonitorEmail"
                v-if="monitorEmail.length < 5"
                class="el-icon-plus"
                style="padding: 2px; font-weight: bolder; border-radius: 50%; border: 1px solid #2f56de; color: #2f56de; cursor: pointer"
              />

              <span v-if="monitorEmail.length == 0 && addMonitorEmailShow == false" style="display:inline-block; margin-left: 10px; padding: 5px 0; color: #888888">添加邮箱</span>
            </div>

          </div>
        </div>
      </section>
    </section>

    <!--用户信息dialog-->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="account_dialog"
      :before-close="closeEvent"
    >
      <info
        @dialog="getDialog"
        :dialogVisible='dialogVisible'
        :server-type="0"
        :dialog-close="dialogClose"
        @dialogCloseBool="dialogCloseBool"
      />
    </el-dialog>

    <el-dialog
      :visible.sync="dialogCodeVisible"
      :close-on-click-modal="false"
      custom-class="login_dialog"
      :before-close="closeEvent"
    >
      <div class="login_wrap">
        <div class="login_logo">
          <img src="@/assets/images/login/login_logo.png" alt="">
        </div>

        <dl class="login_wechat">
          <dt id="qrcode"></dt>
          <dd>请使用微信扫描二维码登录</dd>
        </dl>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogSmsVisible"
      :close-on-click-modal="false"
      custom-class="sms_dialog"
      :before-close="closeEvent"
    >
      <div class="key_wrap">
        <div class="login_logo">
          <img src="@/assets/images/login/new_logo.png" alt=""/>
        </div>

        <div class="login_phone">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login_form" @submit="submitForm('ruleForm')">

            <el-form-item prop="phone">
              <el-input v-model="ruleForm.phone" readonly auto-complete="off"></el-input>
            </el-form-item>

            <el-form-item prop="code" class="login_code">
              <el-input
                v-model="ruleForm.code"
                auto-complete="off"
                :placeholder="$t(`logins.form.code.placeholder`)"
                maxlength="6"
                @keyup.enter.native="submitForm('ruleForm')"
              />

              <el-button class="login_code_btn" :disabled="codeDisabled" @click="getCode">{{codeMsg}}</el-button>
            </el-form-item>

            <el-form-item>
              <el-button class="login_btn" @click="submitForm('ruleForm')" :loading="loading>0" :disabled="disabledBtn">
                确定
              </el-button>
            </el-form-item>

          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import info from "@/components/account/info"
import { mapState, mapMutations } from 'vuex'
import { USER } from "@/request/common";
export default {
  name: "account",
  data () {
    var codeReg = /^\d{6}$/;
    // 校验验证码
    var validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('logins.form.code.placeholder')))
      }
      setTimeout(() => {
        if (!codeReg.test(value)) {
          callback(new Error(this.$t('logins.form.code.errorMessage')))
        } else {
          callback()
        }
      }, 1000)
    };
    return {
      headers: {
        'Authorization': "Bearer " + this.tool.getCookie("auth"),
        'TENANT-ID': '4'
      },
      //产品服务
      content:[
        [
          {
            operation:'立即了解',
            subTitle:'商标查询',
            path:'/trademarkSearch'
          },
          {
            operation:'立即了解',
            subTitle:'商标监控',
            path:'/SC'
          },
          {
            operation:'立即了解',
            subTitle:'品牌保护',
            path:'/BP'
          },
          {
            operation:'立即了解',
            subTitle:'商标查询API',
            path:'/TI'
          },
          {
            operation:'立即了解',
            subTitle:'商标管理系统',
            path:'/TMS'
          }
        ],
        [
          {
            operation:'立即了解',
            subTitle:'专利翻译',
            path: '/patentTranslation'
          },
          {
            operation:'立即了解',
            subTitle:'专利检索',
            path: '/searchTntroduction'
          },
          {
            operation:'立即了解',
            subTitle:'专利翻译API',
            path: '/psApi'
          },
          {
            operation:'立即了解',
            subTitle:'专利管理系统',
            path: '/PMS'
          },
          {
            operation:'立即了解',
            subTitle:'专利年费管理系统',
            path: '/PAMS'
          },
          {
            operation:'立即了解',
          subTitle:'机翻Trados插件',
          path: "/Trados"
        },
          {
            operation:'立即了解',
            subTitle:'蓝灯鱼机翻word插件',
            path: "/TradosWord"
          },
          {
            operation:'立即了解',
            subTitle:'蓝灯鱼机翻Excel插件',
            path: "/Excel"
          },
          {
            operation:'立即了解',
            subTitle:'专利五书拆分',
            path: "/SPB"
          },
          {
            operation:'立即了解',
            subTitle:'专利CPC附图一键压缩',
            path: "/patentCPC"
          }
        ],
        [
          {
            operation:'立即了解',
            subTitle:'智慧园区',
            path: '/smartPark'
          },
          {
            operation:'立即了解',
            subTitle:'企业网盘订制',
            path: '/enterpriseNetwork'
          }
        ]
      ],
      actionUrl: '',
      dialogVisible: false,
      contactInfo: "",
      editType: 1, // 1 添加  2  编辑
      headImg: '',
      dialogClose: false,
      dialogCodeVisible: false,
      trialList: [
          {
          "serviceName": 3,
          "serviceType": 2,
        },
        {
          "serviceName": 2,
          "serviceType": 0,
        },
        {
          "serviceName": 1,
          "serviceType": 1,
        },
      ],
      MchInfo: '',
      keyBtnBool: false,
      // 随机码（自动生成一串随机码）  后台传的参
      token: Math.random(),
      //   key
      dialogSmsVisible: false,
      // 是否禁用按钮
      codeDisabled: false,
      disabledBtn: false,
      // 倒计时秒数
      countdown: 60,
      // 按钮上的文字
      codeMsg: this.$t('logins.form.sendCodeText'),
      // 定时器
      timer: null,
      // 表单参数
      ruleForm: {
        phone: "",
        code: ""
      },
      // 表单验证
      rules: {
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: "blur"
          }
        ]
      },
      loading: 0,

      //监控邮箱列表
      monitorEmail:[],
      addMonitorEmailShow:false,
      tempAddMonitorEmail:"",
      //获取申请人名称
      applicant: []
    }
  },
  components: { info },
  computed: {
    ...mapState(['me']),
    imgUrl: function () {
      if (this.me.avatar.startsWith("http")){
        return this.me.avatar;
      }
      return this.me.avatar
    }

  },
  mounted () {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
    document.body.appendChild(s)
    // 获取用户信息
    this.getUserInfo()
    // 获取联系人信息
    this.getContactLanAjax()
    this.existAjax()
    // 获取监控邮箱数据
    this.getMonitoringEmail()
    //获取申请人企业
    this.editeApplicantName()
  },
  methods: {
    getIphone(val){
      if(val.indexOf("86") == 0){
        var matchReg = /^86/;
        return val.replace(matchReg, "");
      }else if(val.indexOf("852") == 0){
        var matchReg = /^852/;
        return val.replace(matchReg, "");
      }else if(val.indexOf("853") == 0){
        var matchReg = /^853/;
        return val.replace(matchReg, "");
      }else {
        return val
      }
    },
    //修改托管平台
    goApplicant(){
      this.$router.push({
        path: "/hostingPlatform?tpye=edit"
      })
    },
    editeApplicantName(){
      this.$axios.get(
        '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicant = data.data.filter(item => item.storeFlag)
         }
      })
    },
    //设置密码
    setPassword(){
      this.$router.push({
        path: "/changePassword",
        query: {
          type: 1
        }
      })
    },
    //跳转
    jump(item){
      if(item.path){
        this.$router.push({
          path: item.path
        })
      }
    },
    ...mapMutations(["setMe"]),
    // 获取用户信息
    async getUserInfo () {
      let user = await USER()
      if (user.code == 0) {
        this.setMe(user.data)
      }
    },
    wxLogins () {
      var appid = ""
      var uriAll = window.location.href
      //获取指定字符串在原字符串条中的位置
      var getindex = uriAll.indexOf(".cn")
      var getstr = uriAll.substring(0, getindex)
      if (getstr.indexOf("test.lanternfish") != -1) {
        appid = 'wx855ff947adfb6ef2'
      } else {
        appid = 'wx6a278bb6f3d73649'
      }
      this.tool.setCookie("loginUrl", this.$route.path);
      var obj = new WxLogin({
        self_redirect: false,
        id: 'qrcode',
        appid: appid,
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(`http://${window.location.host}/callback`),
        state: '/',
        style: 'black',
        href: 'data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsNCi5pbXBvd2VyQm94e3dpZHRoOmF1dG87fQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIyN3B4O2hlaWdodDogMjI3cHg7bWFyZ2luLXRvcDowO2JvcmRlcjpub25lO30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAud3JwX2NvZGV7d2lkdGg6MjI3cHg7fQ==',
      })
    },
    //  获取联系人信息
    async getContactLanAjax () {
      let data = await this.$axios.get("/admin/contact/getContact")
      if (data.data.code == 0) {
        if (data.data.data != null) {
          this.contactInfo = data.data.data
          this.editType = 2
        }
        else {
          this.editType = 1
        }
      }
    },

    // 上传图片
    beforeUpload (file) {
      //上传前的函数
      //上传前对图片类型和大小进行判断
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message('上传头像图片只能是 JPG 或 PNG 格式!')
      }
      if (!isLt2M) {
        this.$message('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M;
    },
    async changeUpload (response, file, fileList) {
      if(response.code == 0){
          this.editAvatarAjax(response.data.url)
      }
    },
    // 更换头像
    async editAvatarAjax (param) {
      const data = await this.$axios.post(
        '/admin/user/editAvatar',
        {
          avatar: param
        },
      )
      if (data.data.code == 0) {
        // 获取用户信息
        this.getUserInfo()
      }
    },
    // 编辑联系人
    editEvent () {
      this.dialogVisible = true
    },
    // 编辑联系人成功
    getDialog (param) {
      this.dialogVisible = param
      this.getContactLanAjax()
      this.getMonitoringEmail()
    },
    dialogCloseBool (data) {
      this.dialogClose = data
    },
    closeEvent (done) {
      this.dialogClose = true
      done()
    },
    // 修改手机号
    modifyEvent () {
      this.$router.push({
        path: "/modidyOrAuth",
        query: {
          type: 1
        }
      })
    },
    // 授权 or 解除授权
    authEvent (param) {
      //解除授权
      if (param && param.wxOpenid != null && param.wxOpenid != "") {
        this.$router.push({
          path: "/modidyOrAuth",
          query: {
            type: 2
          }
        })
      }
      else {
        //授权
        this.dialogCodeVisible = true
        this.$nextTick(function () {
          this.wxLogins()
        })
      }
    },
    //  查询蓝灯鱼当前登录用户的服务列表
    async searchServiceAjax () {
      let data = await this.$axios.get("/lantern/serviceLan/searchService")
      if (data.data.msg == "success") {
        if (data.data.data) {
          this.trialList = data.data.data
        }
      }
    },
    toServerEvent () {
      this.$router.push({
        path: "/services"
      })
    },
    // 判断用户是否有商户信息
    async existAjax () {
      let data = await this.$axios.get("/admin/merchantinfo/exist")
      if (data.data.code == 0) {
        this.keyBtnBool = data.data.data   //false代表不存在 true代表存在
      }
    },
    // 获取验证码
    getCode () {
      this.codeDisabled = true
      setTimeout(() => {
        this.codeDisabled = false
      }, 1000)
      // 验证码60秒倒计时
      if (!this.timer) {
        this.timerEvent()
      }
    },
    // 倒计时
    timerEvent () {
      this.codeDisabled = true
      this.timer = setInterval(() => {
        if (this.countdown > 0 && this.countdown <= 60) {
          this.countdown--
          if (this.countdown !== 0) {
            this.codeMsg = this.countdown + " s"
          }
          else {
            clearInterval(this.timer)
            this.codeMsg = this.$t('logins.form.resendText')
            this.countdown = 60
            this.timer = null
            this.codeDisabled = false
          }
        }
      }, 1000)
      this.smsAjax()
    },
    // 验证码接口
    async smsAjax () {
      const codeData = await this.$axios.get(
        '/common/code/sms', {
        params: {
          subject: this.ruleForm.phone,
          token: this.token,
          locale: 'lantern'
        }
      }
      )
      if (codeData.data.code == 0) {
        // this.$message({
        //   type: "success",
        //   message: codeData.data.msg
        // });
      }
      else {
        this.$message({
          message: codeData.data.msg
        })
        clearInterval(this.timer)
        this.codeMsg = this.$t('logins.form.resendText')
        this.countdown = 60
        this.timer = null
        this.codeDisabled = false
      }
    },
    // 表单提交
    submitForm (formName) {
      this.disabledBtn = true
      setTimeout(() => {
        this.disabledBtn = false
      }, 1000)
      this.$refs[formName].validate(result => {
        if (result) {
          this.disabledBtn = true
          this.loading++
          this.getMchInfoAjax()
        }
      });
    },
    // 获取商户信息
    async getMchInfoAjax () {
      const data = await this.$axios({
        url: '/admin/merchantinfo/getMchInfo',
        method: 'post',
        params: {
          username: this.ruleForm.phone,
          code: this.ruleForm.code,
          token: this.token,
          authType: 'sms'
        }
      })
      if (data.data.code == 0) {
        clearInterval(this.timer)
        this.disabledBtn = false
        this.loading--
        this.dialogSmsVisible = false
        this.MchInfo = data.data.data
        // 商户ID  mchId    请求密钥 reqKey    返回密钥 resKey
      }
      else {
        clearInterval(this.timer)
        this.codeMsg = this.$t('logins.form.resendText')
        this.countdown = 60
        this.timer = null
        this.codeDisabled = false
        this.disabledBtn = false
        this.loading = 0
        this.$message({
          message: data.data.msg
        })
      }
    },
    // 获取Key
    getKeyEvent () {
      if (this.$store.state && this.$store.state.me) {
        this.ruleForm.phone = this.$store.state.me.phone
      }
      this.dialogSmsVisible = true
    },

    //获取监控邮箱
    async getMonitoringEmail() {
      this.monitorEmail = []

      let data = await this.$axios.get("/trademark/monitoring/email/list")
      data.data.data.forEach(item=>{
        this.monitorEmail.push(item.userEmail)
      })
    },
    addMonitorEmail(){
      this.tempAddMonitorEmail = ""
      this.addMonitorEmailShow = !this.addMonitorEmailShow
    },
    doAddMonitorEmail(){
      let emailRed = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


      if (this.tempAddMonitorEmail != "" && emailRed.test(this.tempAddMonitorEmail)){

        this.monitorEmail.push(this.tempAddMonitorEmail.trim())

        this.$axios.post('/trademark/monitoring/email/batchAddOrUpdate', { userEmailSet:this.monitorEmail})
          .then( ()=>{this.getMonitoringEmail() })

        this.addMonitorEmailShow = false
      }
      else if (this.tempAddMonitorEmail == ""){
        this.addMonitorEmailShow = false
      }
      else if (!emailRed.test(this.tempAddMonitorEmail)){
        this.$message("请检查您的邮箱格式")
        return
      }
    },
  }
}
</script>

<style scoped lang="less">
.account_header {
  height: 141px;
  background: #ffffff;
  box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
  padding: 0 50px;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  position: relative;
  .account_info {
    display: flex;
    align-items: center;
    dt {
      width: 80px;
      margin-right: 15px;
      position: relative;
      color: #fff;
      &:hover {
        .upload-demo,
        .upload_btn {
          display: block;
          cursor: pointer;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .upload_btn,
      .upload-demo {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 27px;
        background-color: #757e83;
        opacity: 0.7;
        border-radius: 0 0 5px 5px;
        line-height: 13px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        border: none;
      }
    }
    dd {
      display: flex;
      height: 80px;
      .account_info_part1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      p {
        font-size: 14px;
        color: #262626;
        position: relative;
        display: flex;
        width: 194px;
        height: 22px;
        align-items: flex-end;
        span{
          cursor: pointer;
          padding: 0 5px 1px 5px;
          &:hover{
            color: @blue;
            border-bottom: 2px solid @blue;
          }
        }
        i {
          display: inline-block;
          width: 16px;
          height: 22px;
          vertical-align: middle;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
        b {
          display: inline-block;
          width: 18px;
          height: 18px;
          background: url("@{imgUrl}common/edit_black.png") no-repeat center;
          background-size: contain;
          position: absolute;
          top: 50%;
          margin-top: -9px;
          right: 0;
          cursor: pointer;
          &:hover {
            background: url("@{imgUrl}common/edit_blue.png") no-repeat center;
            background-size: contain;
          }
        }
        a {
          font-size: 14px;
          color: @blue;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .account_key {
    margin-left: 20px;
    dd {
      p {
        width: 100%;
        font-size: 12px;
      }
    }
  }
  .account_btn {
    cursor: pointer;
    color: #595a5e;
    position: absolute;
    right: 0;
    top: 45px;
  }
}
.account_con {
  .account_left {
    flex: 1;
    background: #ffffff;
    height: 240px;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    padding: 20px 50px;
    box-sizing: border-box;
    .account_left_con {
      //   &:last-child {
      //     margin-top: 40px;
      //   }
      .account_title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        h5 {
          font-size: 16px;
          color: #595a5e;
          position: relative;
          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 200px;
            position: absolute;
            left: -21px;
            top: 50%;
            margin-top: -3px;
            border: solid 1px @blue;
          }
        }
        span {
          font-size: 14px;
          color: #888888;
          cursor: pointer;
          i {
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            margin-right: 8px;
            background: url("@{imgUrl}common/edit_black.png") no-repeat center;
            background-size: contain;
          }
          &:hover {
            color: @blue;
            i {
              background: url("@{imgUrl}common/edit_blue.png") no-repeat center;
              background-size: contain;
            }
          }
        }
      }
      .account_main {
        display: flex;
        /*margin-top: 24px;*/
        li {
          justify-content: space-between;
          width: 388px;
          font-size: 14px;
          line-height: 44px;
          strong {
            color: #888888;
            display: inline-block;
            width: 150px;
          }
          span {
            color: #333333;
          }
          b {
            color: #999;
          }
        }
      }
    }
  }
  .account_right {
    width: 310px;
    padding: 20px 50px;
    height: 551px;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    box-sizing: border-box;
    margin-left: 10px;
    background: #fff;
    .account_title {
      margin-bottom: 16px;
      h5 {
        font-size: 16px;
        color: #595a5e;
        position: relative;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 200px;
          position: absolute;
          left: -21px;
          top: 50%;
          margin-top: -3px;
          border: solid 1px @blue;
        }
      }
    }
    .trial_list {
      li {
        width: 132px;
        height: 100px;
        background-color: #f9f9f9;
        padding: 12px 14px 0;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto 34px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        dl {
          height: 70px;
          background: #fff;
          border-radius: 3px;
          border: solid 1px #f2f2f2;
          dt {
            height: 16px;
            line-height: 16px;
            border: 1px solid #f2f2f2;
            border-radius: 3px;
            padding: 0 5px 0 36px;
            position: relative;
            i {
              position: absolute;
              left: 8px;
              &.green {
                color: #70c7b3;
              }
              &.red {
                color: #eea1a1;
              }
              &.blue {
                color: #699bd2;
              }
            }
            b {
              display: inline-block;
              width: 100%;
              height: 6px;
              background-color: #f6f6f6;
              border-radius: 1px;
              vertical-align: middle;
            }
          }
          dd {
            padding: 6px 10px 0;
            text-align: center;
            p {
              width: 100%;
              height: 8px;
              background-color: #f6f6f6;
              border-radius: 1px;
              margin-bottom: 6px;
            }
            h5 {
              font-size: 12px;
              color: #717171;
              &.translate {
                height: 27px;
                line-height: 27px;
                margin-top: 6px;
                background-color: #f6f6f6;
                border-radius: 1px;
              }
            }
          }
        }
      }
    }
  }
}

.account_cons {
  .account_lefts {
    margin-top: 11px;
    flex: 1;
    background: #ffffff;
    height: 500px;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    padding: 20px 50px;
    box-sizing: border-box;
    .account_left_con {
      //   &:last-child {
      //     margin-top: 40px;
      //   }
      .account_title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        h5 {
          font-size: 16px;
          color: #595a5e;
          position: relative;
          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 200px;
            position: absolute;
            left: -21px;
            top: 50%;
            margin-top: -3px;
            border: solid 1px @blue;
          }
        }
        span {
          font-size: 14px;
          color: #888888;
          cursor: pointer;
          i {
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            margin-right: 8px;
            background: url("@{imgUrl}common/edit_black.png") no-repeat center;
            background-size: contain;
          }
          &:hover {
            color: @blue;
            i {
              background: url("@{imgUrl}common/edit_blue.png") no-repeat center;
              background-size: contain;
            }
          }
        }
      }
      .account_main {
        /*display: flex;*/
        /*margin-top: 24px;*/

        li {
          width: 256px;
          font-size: 14px;
          line-height: 44px;
          display: flex;
          justify-content: space-between;
          strong {
            color: #888888;
            display: inline-block;
            width: 150px;
          }
          span {
            color: #3d61e0;
            cursor: pointer;
          }
          b {
            color: #999;
          }
        }
        li:last-child{
          margin-right: 0px!important;
        }
      }
    }
  }
  .account_right {
    width: 310px;
    padding: 20px 50px;
    height: 551px;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    box-sizing: border-box;
    margin-left: 10px;
    background: #fff;
    .account_title {
      margin-bottom: 16px;
      h5 {
        font-size: 16px;
        color: #595a5e;
        position: relative;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 200px;
          position: absolute;
          left: -21px;
          top: 50%;
          margin-top: -3px;
          border: solid 1px @blue;
        }
      }
    }
    .trial_list {
      li {
        width: 132px;
        height: 100px;
        background-color: #f9f9f9;
        padding: 12px 14px 0;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto 34px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        dl {
          height: 70px;
          background: #fff;
          border-radius: 3px;
          border: solid 1px #f2f2f2;
          dt {
            height: 16px;
            line-height: 16px;
            border: 1px solid #f2f2f2;
            border-radius: 3px;
            padding: 0 5px 0 36px;
            position: relative;
            i {
              position: absolute;
              left: 8px;
              &.green {
                color: #70c7b3;
              }
              &.red {
                color: #eea1a1;
              }
              &.blue {
                color: #699bd2;
              }
            }
            b {
              display: inline-block;
              width: 100%;
              height: 6px;
              background-color: #f6f6f6;
              border-radius: 1px;
              vertical-align: middle;
            }
          }
          dd {
            padding: 6px 10px 0;
            text-align: center;
            p {
              width: 100%;
              height: 8px;
              background-color: #f6f6f6;
              border-radius: 1px;
              margin-bottom: 6px;
            }
            h5 {
              font-size: 12px;
              color: #717171;
              &.translate {
                height: 27px;
                line-height: 27px;
                margin-top: 6px;
                background-color: #f6f6f6;
                border-radius: 1px;
              }
            }
          }
        }
      }
    }
  }
}
.login_wrap {
  width: 450px;
  height: 500px;
  border-radius: 5px;
  padding-top: 160px;
  box-sizing: border-box;
  background: #fff url("@{imgUrl}login/login_banner.png") no-repeat -48px top;
  position: relative;
  .login_logo {
    width: 110px;
    position: absolute;
    left: 50%;
    margin-left: -55px;
    top: 12px;
    img {
      width: 100%;
    }
  }
  .login_wechat {
    dt {
      width: 230px;
      height: 230px;
      margin: 0 auto;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      overflow: hidden;
    }
    dd {
      font-size: 14px;
      color: #c2c2c2;
      margin-top: 18px;
      text-align: center;
    }
  }
}
.sms_dialog {
  .key_wrap {
    width: 450px;
    height: 540px;
    background: #fff url("@{imgUrl}login/login_banner.png") no-repeat -48px top;
    border-radius: 5px;
    padding-top: 160px;
    box-sizing: border-box;
    position: relative;
    .login_logo {
      width: 110px;
      position: absolute;
      left: 50%;
      margin-left: -55px;
      top: 27px;
      img {
        width: 100%;
      }
    }
    .login_form {
      padding: 0 55px;
      .login_code {
        position: relative;
        .login_code_btn {
          position: absolute;
          top: 0;
          right: 18px;
          background: #fff;
          color: #888;
          border: none;
          padding: 12px 0;
          &:hover {
            color: @blue;
          }
          &.is-disabled {
            color: #dce8fb;
          }
        }
      }
      .login_btn {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        background: @blue;
        color: #fff;
        border: none;
        margin: 20px 0 10px;
        &:hover {
          box-shadow: 0px 2px 20px 0px rgba(38, 112, 230, 0.4);
        }
      }
      .treaty {
        line-height: 0;
        margin-top: -20px;
        a,
        p {
          color: #c2c2c2;
          font-size: 12px;
        }
        a {
          margin-left: -20px;
          &:hover {
            color: #888;
          }
        }
        p {
          line-height: 1.8;
        }
      }
    }
    .login_wechat_btn {
      margin-top: 30px;
      cursor: pointer;
      .weChat_icon {
        margin: 0 auto;
        width: 38px;
      }
      .phone_icon {
        margin: 0 auto;
        width: 30px;
      }
      &.unclick {
        cursor: default;
      }
    }
    .login_wechat {
      dt {
        width: 230px;
        height: 230px;
        margin: 0 auto;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        overflow: hidden;
      }
      dd {
        font-size: 14px;
        color: #c2c2c2;
        margin-top: 18px;
        text-align: center;
      }
    }
  }
}
</style>
<style lang="less">
.account_dialog {
  width: 600px;
  height: 740px;
  background-color: #ffffff;
  box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
  border-radius: 5px;
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }
  .info_wrap {
    .info_con {
      padding: 45px 80px 80px;
    }
  }
}
.sms_dialog {
  width: 450px;
  height: 540px;
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }
  .key_wrap {
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dbdcdd;
      font-size: 14px;
      color: #888;
      // margin-bottom: 40px;
    }
    .el-form-item__error {
      padding-left: 15px;
    }
    .el-form-item {
      margin-bottom: 30px;
    }
    .el-checkbox {
      margin-right: 30px;
    }
    #qrcode {
      img {
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="order_wrap">
      <div class="order_con">
        <div class="order_info">
          <h6>订单信息</h6>
          <div class="order_list">
            <div class="order_list_item">
              <div class="order_detail_con">
                <div v-if="this.$route.query.type && this.$route.query.type == 'tradmark'">
                  <div style="width: 900px" class="order_detail active">
                    <dl>
                      <dt>{{detailData.paymentOrderList[0].trademarkName ? '商标名称' : '商标图样'}}</dt>
                      <dd v-if="detailData.paymentOrderList[0].trademarkName">{{detailData.paymentOrderList[0].trademarkName}}</dd>
                      <dd v-else>
                        <img style="width: 60px" :src="queryDataUrl(detailData.paymentOrderList[0].trademarkImageUrl)" alt="">
                      </dd>
                    </dl>
                    <dl>
                      <dt>申请号</dt>
                      <dd>{{detailData.paymentOrderList[0].trademarkApplyNo}}</dd>
                    </dl>
                    <dl >
                      <dt>类别</dt>
                      <dd>{{detailData.paymentOrderList[0].renewalNiceCode}}</dd>
                    </dl>
                    <dl>
                      <dt>提交方式</dt>
                      <dd>{{detailData.paymentOrderList[0].submitType == 0 ? '电子' : '纸质'}}</dd>
                    </dl>
                    <dl>
                      <dt>续展官费</dt>
                      <dd>{{detailData.paymentOrderList[0].renewalAmount}}</dd>
                    </dl>
                    <dl>
                      <dt>宽展费</dt>
                      <dd>{{detailData.paymentOrderList[0].extendedAmount}}</dd>
                    </dl>
                    <dl>
                      <dt>代理费</dt>
                      <dd>{{detailData.paymentOrderList[0].agencyAmount}}</dd>
                    </dl>
                    <dl>
                      <dt>续展官费总计</dt>
                      <dd>{{detailData.paymentOrderList[0].renewalSummaryAmount}}</dd>
                    </dl>
                  </div>
                </div>
                <div v-else>
                  <div  class="order_detail">
                    <dl>
                      <dt >产品名称</dt>
                    </dl>
                    <dl>
                      <dt >总数</dt>
                    </dl>
                    <dl >
                      <dt >每日限制</dt>
                    </dl>
                    <dl >
                      <dt >时间限制</dt>
                    </dl>
                  </div>
                  <div v-for="(item, index) in orderServiceManageList" class="order_detail">
                    <dl>
                      <dd>{{item.serviceName}}</dd>
                    </dl>
                    <dl>
                      <dd>
                        <span>{{item.totalLimit == 0 ? '--' : (item.totalLimit)*item.quantity}}</span>{{item.type == 1 ? '个' : item.modeType == '4' && item.totalLimit != 0 ? '次' :item.modeType == '3' && item.totalLimit != 0 ? '字': item.modeType == '1' && item.totalLimit != 0 ? '个' : '' }}
                      </dd>
                    </dl>
                    <dl v-if="item.dailyLimit != 0 && $route.query.modeType != 1 ">
                    <dd>
                      <span>{{item.dailyLimit}}</span>{{item.modeType == '4' ? '次' :item.modeType == '3' ? '字':'' }}
                    </dd>
                  </dl>
                    <dl v-else>
                      <dd>
                        <span>--</span>
                      </dd>
                    </dl>
                    <dl v-if="item.timeLimit">
                      <dd><span>{{item.timeLimit}}</span>天</dd>
                    </dl>
                    <dl v-else>
                      <dd><span>--</span></dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div style="display: flex;flex-direction: column" class="order_list_item">
              <label>支付方式:</label>
              <div>
                <p v-for="(item,index) in payMethod" :key="index" :class="{'active':item.chooseBool}"
                   @click="payMethodEvent(item)">
                  <i></i>
                  <b><img :src="item.url" alt=""></b>
                  <span>{{item.name}}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="paymentAmount" style="margin-top: 60px;width: 1200px;display: flex" >
          <p style="padding-left: 15px" class="amount">应付金额: <span style="margin-left: 15px">{{totalPric}}</span>&nbsp;元</p>
          <!--            <img v-if="$route.query.discountFlag == 1"  style="height: 18px" src="/images/index/active.png" alt="">-->
          <span v-if="$route.query.discountFlag == 1"   class="zhe">5折</span>
        </div>

        <div class="order_btn">
          <el-button :class="['el-order-btn',{'disabled':disabledBool}]" @click="payEvent">立即支付</el-button>
          <h6 class="order_hint">提示：确认支付前，请先<span @click="infoEvent">完善账户信息</span></h6>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="account_dialog"
                 :before-close="closeEvent">
        <info @dialog="getDialog" :dialogVisible='dialogVisible' :server-type="0" :dialog-close="dialogClose"
              @dialogCloseBool="dialogCloseBool"></info>
      </el-dialog>
    </div>
    <xfooter></xfooter>
  </div>
</template>
<script>
  import { payZfb, renewalPayZfb } from "@/request/trademarkPayment";
  import zfb from '@/assets/images/common/zfb.jpg'
  import gong from '@/assets/images/common/gong.jpg'
  import wx from '@/assets/images/common/wxs.jpg'
  import xfooter from '@/components/common/xfooter'
  import info from "@/components/account/info"
  export default {
    name: "orderInfo",
    data () {
      return {
        id: '',
        number: '',
        //总价格
        totalPric: '',
        //是否有订单
        orderFlag: false,
        //支付类型
        payType: 'WX',
        payMethod: [
          {
            name: "微信支付",
            url: wx,
            chooseBool: true,
            type: "WX"
          },
          {
            name: "支付宝",
            url: zfb,
            chooseBool: false,
            type: "ZFB"
          },
          {
            name: "对公支付",
            url: gong,
            chooseBool: false,
            type: "gong"
          },
        ],
        detailData: {},
        detailDatas: {},
        detailDatass: {},
        detailDatasss: {},
        disabledBool: false,
        dialogVisible: false,
        dialogClose: false,
        ids:[],
        orderServiceManageList: []
      }
    },

    mounted () {
      //----------------------------------------------------------
      this.detailAjax();
      this.getContactLanAjax();
    },
    components: { info, xfooter },
    methods: {
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      payLater(data) {
        if (data.displayMode == 'url') {
          window.location.href = data.displayContent;
        } else {
          const tradePage = data;
          const div = document.createElement('div'); // 创建div
          div.innerHTML = tradePage.displayContent; // 将返回的form 放入div
          document.body.appendChild(div);
          document.forms[0].submit();
          document.body.removeChild(div);
        }
      },
      // 详情
      async detailAjax (id) {
        let data = await this.$axios.get(`/order/lantern-order/detail/${this.$route.query.id}`);
        if (data.data.code == 0) {
          this.totalPric = data.data.data.amount
          this.id = data.data.data.id
          this.orderId = data.data.data.payOrderId
          this.orderServiceManageList = data.data.data.orderServiceManageList
          // this.init()
        }
      },
      //支付宝支付
      payWay3() {
        // if(window.location.origin=='http://www.youxuanzifu.com'||window.location.origin=='http://www.premiword.com.cn'){
        payZfb({
          channelCode: "alipay_pc",
          id: this.orderId,
          returnUrl: window.location.origin + '/#/personalCenter/myServer'
        })
          .then(({data}) => {
            if (data.status == 0) {
              this.payLater(data);
            }
          })
          .catch((error) => {
            //   this.$message("系统未知错误,请反馈给管理员");
            console.log(error);
          })
      },

      //   初始化
      async init (value) {
        this.ids = value
        switch (this.ids.length) {
          case 1:            this.detailData = value[0]
            if(this.detailData.serviceName.indexOf('商标监控') != -1){
              this.detailData.type = 1
        }else if(this.detailData.serviceName.indexOf('驳通智写') != -1){
          this.detailData.type = 0
        }else {
          this.detailData.type = 0
        }
        break;
            case 2:
              this.detailData = value[0]
              if(this.detailData.serviceName.indexOf('商标监控') != -1){
                this.detailData.type = 1
              }else if(this.detailData.serviceName.indexOf('驳通智写') != -1){
                this.detailData.type = 0
              }else {
                this.detailData.type = 0
              }

            this.detailDatas = value[1]
            if(this.detailDatas.serviceName.indexOf('商标监控') != -1){
              this.detailDatas.type = 1
            }else if(this.detailDatas.serviceName.indexOf('驳通智写') != -1){
              this.detailDatas.type = 0
            }else {
              this.detailDatas.type = 0
            }
            break;
          case 3:
            this.detailData = value[0]
            if(this.detailData.serviceName.indexOf('商标监控') != -1){
              this.detailData.type = 1
            }else if(this.detailData.serviceName.indexOf('驳通智写') != -1){
              this.detailData.type = 0
            }else {
              this.detailData.type = 0
            }

            this.detailDatas = value[1]
            if(this.detailDatas.serviceName.indexOf('商标监控') != -1){
              this.detailDatas.type = 1
            }else if(this.detailDatas.serviceName.indexOf('驳通智写') != -1){
              this.detailDatas.type = 0
            }else {
              this.detailDatas.type = 0
            }
            this.detailDatass = value[2]
            if(this.detailDatass.serviceName.indexOf('商标监控') != -1){
              this.detailDatass.type = 1
            }else if(this.detailDatass.serviceName.indexOf('驳通智写') != -1){
              this.detailDatass.type = 0
            }else {
              this.detailDatass.type = 0
            }
            break;
          case 4:
            this.detailData = value[0]
            if(this.detailData.serviceName.indexOf('商标监控') != -1){
              this.detailData.type = 1
            }else if(this.detailData.serviceName.indexOf('驳通智写') != -1){
              this.detailData.type = 0
            }else {
              this.detailData.type = 0
            }

            this.detailDatas = value[1]
            if(this.detailDatas.serviceName.indexOf('商标监控') != -1){
              this.detailDatas.type = 1
            }else if(this.detailDatas.serviceName.indexOf('驳通智写') != -1){
              this.detailDatas.type = 0
            }else {
              this.detailDatas.type = 0
            }
            this.detailDatass = value[2]
            if(this.detailDatass.serviceName.indexOf('商标监控') != -1){
              this.detailDatass.type = 1
            }else if(this.detailDatass.serviceName.indexOf('驳通智写') != -1){
              this.detailDatass.type = 0
            }else {
              this.detailDatass.type = 0
            }
            this.detailDatasss = value[3]
            if(this.detailDatasss.serviceName.indexOf('商标监控') != -1){
              this.detailDatasss.type = 1
            }else if(this.detailDatasss.serviceName.indexOf('驳通智写') != -1){
              this.detailDatasss.type = 0
            }else {
              this.detailDatasss.type = 0
            }
            break;
        }
      },
      payMethodEvent (data) {

        let datas = []
        if (data.chooseBool) {
          return;
        }
        this.payMethod.forEach((item, index) => {
          item.chooseBool = false;
        })
        data.chooseBool = !data.chooseBool;
        this.payType = data.type
        datas = this.payMethod.filter(item => {
          return item.name == '对公支付'
        })
        if(datas[0].chooseBool == true){
          this.corporateFlag = true
        }else {
          this.corporateFlag = false
        }
      },
      payEvent () {
        //对公支付
        if(this.corporateFlag){
          this.$router.push({
            path: '/payment',
            query: {
              orderId: this.$route.query.orderNum,
              name: this.detailData.serviceName,
              price: this.totalPric
            }
          })
          return ;
        }

        //支付宝
        if(this.payType == 'ZFB'){
          this.payWay3(this.orderId)
          return;
        }

        this.$router.push({
          path: "/pay/pay",
          query: {
            id: this.id,
            orderId: this.orderId,
            price: this.totalPric,
            flag: this.$route.query.flag ? this.$route.query.flag : undefined
          }
        })
      },
      // 生成订单
      async saveOrderAjax () {
        let obj = []
        if(this.ids.length == 2){
          obj = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            },{
              productServiceId: this.detailDatas.id,
              productId: this.detailDatas.productId,
              price: this.detailDatas.price,
              currency: this.detailDatas.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            }
          ]
        }else if(this.ids.length == 3){
          obj = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            },{
              productServiceId: this.detailDatas.id,
              productId: this.detailDatas.productId,
              price: this.detailDatas.price,
              currency: this.detailDatas.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            },{
              productServiceId: this.detailDatass.id,
              productId: this.detailDatass.productId,
              price: this.detailDatass.price,
              currency: this.detailDatass.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            }
          ]
        }else if(this.ids.length == 4){
          obj = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            },{
              productServiceId: this.detailDatas.id,
              productId: this.detailDatas.productId,
              price: this.detailDatas.price,
              currency: this.detailDatas.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            },{
              productServiceId: this.detailDatass.id,
              productId: this.detailDatass.productId,
              price: this.detailDatass.price,
              currency: this.detailDatass.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            },{
              productServiceId: this.detailDatasss.id,
              productId: this.detailDatasss.productId,
              price: this.detailDatasss.price,
              currency: this.detailDatasss.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern' //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
            }
          ]
        } else {
          obj  = [
            {
              productServiceId: this.detailData.id,
              productId: this.detailData.productId,
              price: this.detailData.price,
              currency: this.detailData.currency,  //币种：CNY人民币,USD美元,EUR欧元,JPY日元
              source: 'web',
              orderType: 'lantern', //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
              quantity:this.$route.query.flag ? this.$route.query.monitorFlag : (this.$route.query.monitorFlag ? this.$route.query.monitorFlag/10 : undefined)
            }
          ]
        }

        if(this.$route.query.flag && this.$route.query.flag == 2){
          localStorage.setItem('orderId',this.$route.query.ids)
          localStorage.setItem('MT',1)
          //对公支付
          if(this.corporateFlag){
            this.$router.push({
              path: '/payment',
              query:{
                orderId:this.$route.query.orderNum,
                name: this.detailData.serviceName,
                price: this.totalPric
              }
            })
            return ;
          }

          if(this.payType == 'ZFB'){
            this.payWay3(this.$route.query.ids)
            return;
          }
          this.$router.push({
            path: "/pay/pay",
            query: {
              orderId: this.$route.query.ids,
              price: this.totalPric,
              flag: this.$route.query.flag ? this.$route.query.flag : undefined
            }
          })
          return;
        }
        let data = await this.$axios.post("/order/lantern-order/createBatch", obj);
        if (data.data.code == 0) {
          localStorage.setItem('orderId',data.data.data.payOrderId)
          if(this.$route.query.flag){
            localStorage.setItem('MT',1)
          }else {
            localStorage.removeItem('MT')
          }
          //对公支付
          if(this.corporateFlag){
            this.$router.push({
              path: '/payment',
              query:{
                id: data.data.data.id,
                orderId:data.data.data.orderNum,
                name: this.detailData.serviceName,
                price: this.totalPric
              }
            })
            return ;
          }

          if(this.payType == 'ZFB'){
            this.payWay3(data.data.data.payOrderId)
            return;
          }
          this.$router.push({
            path: "/pay/pay",
            query: {
              id: data.data.data.id,
              orderId: data.data.data.payOrderId,
              price: this.totalPric,
              flag: this.$route.query.flag ? this.$route.query.flag : undefined
            }
          })
        }
      },
      infoEvent () {
        this.dialogVisible = true;
      },
      getDialog (param) {
        this.dialogVisible = param;
        this.getContactLanAjax();
      },
      //  获取联系人信息
      async getContactLanAjax () {
        let data = await this.$axios.get("/admin/contact/getContact");
        if (data.data.msg == "success") {
          if (data.data.data != null) {
            this.disabledBool = false;
          } else {
            this.disabledBool = true;
          }
        }
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
    }
  }
</script>
<style lang="less" scoped>
  .order_wrap {
    /*min-height: calc(100vh - 85px);*/
    padding: 74px 0px 0px;
    box-sizing: border-box;
    background: #f2f2f2;
    .order_con {
      width: 100%;
      min-height: calc(100vh - 183px);
      background: #fff;
      padding: 40px 0 80px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
      .order_info {
        width: 1200px;
        margin: 0 auto;
        h6 {
          color: #595a5e;
          font-size: 16px;
          position: relative;
          padding-left: 15px;
          margin-bottom: 30px;
          &::before {
            content: "";
            width: 4px;
            height: 22px;
            background-color: @blue;
            position: absolute;
            left: 0;
          }
        }
        .order_list {
          padding-left: 15px;
          .order_list_item {
            line-height: 55px;
            color: #595a5e;
            font-size: 14px;
            label {
              margin-right: 40px;
            }
            .red {
              color: #eb5657;
            }
            p {
              display: inline-block;
              height: 33px;
              line-height: 33px;
              padding: 0 15px;
              border: solid 1px #cccccc;
              position: relative;
              cursor: pointer;
              margin-right: 20px;
              b {
                display: inline-block;
                width: 20px;
                vertical-align: -6px;
                img {
                  display: block;
                  width: 100%;
                }
              }
              span {
                color: #333;
                font-size: 14px;
                margin-left: 6px;
              }
              &.active {
                border: solid 1px @blue;
                i {
                  width: 16px;
                  height: 16px;
                  background: url("@{imgUrl}common/choose.png") no-repeat center;
                  background-size: 100%;
                  position: absolute;
                  top: -5px;
                  right: -4px;
                }
              }
            }
            .order_detail_con {
              display: inline-block;
              margin-bottom: 25px;
              .order_detail {
                display: flex;
                width: 800px;
                border-radius: 3px;
                /*border: solid 1px #ecf0f4;*/
                dl {
                  display: inline-block;
                  text-align: center;
                  flex: 1;
                  dt {
                    height: 37px;
                    /*background-color: #f8f9fb;*/
                    border-bottom: 1px solid #eff2f5;
                    line-height: 37px;
                    color: #999999;
                  }
                  dd {
                    /*width: 150px;*/
                    width: 100%;
                    line-height: normal;
                    padding: 10px 0;
                    span {
                      color: @blue;
                    }
                  }
                }
                &.active{
                  dd {
                    display: flex;
                    padding: 0;
                    align-items: center;
                    justify-content: center;
                    height: 80px;
                  }
                }
              }
            }
          }
        }
      }
      .order_hint {
        width: 1200px;
        margin: 40px auto 24px;
        color: #999999;
        font-size: 14px;
        padding-left: 15px;
        box-sizing: border-box;
        span {
          color: @blue;
          cursor: pointer;
        }
      }
      .paymentAmount{
        margin: 50px auto 10px ;
        display: flex;
        .amount{
          font-size: 18px;
          color: #595a5e;
          font-weight: bold;
          span{
            font-weight: 600;
            font-size: 34px;
            color: rgb( 255, 106, 55 );
          }
        }
        .zhe{
          margin-right: 3px;
          display: table-cell;
          width: 40px;
          height: 20px;
          background: #ff6a37;
          color: white;
          border-radius: 5px;
          font-size: 14px;
          vertical-align:middle;
          text-align:center;
          margin-left: 8px;
        }
      }
      .order_btn {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        height: 40px;
        align-items: center;
        .el-order-btn {
          margin-left: 15px;
          width: 100px;
          height: 34px;
          background: @blue;
          font-size: 14px;
          line-height: 34px;
          border-radius: 3px;
          color: #fff;
          padding: 0;
          border: none;
          &:hover {
            box-shadow: @boxShadow;
          }
          &.disabled {
            background-color: #d9d9dc;
            cursor: default;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .wxCode_dialog {
    .el-dialog__header {
      display: none;
    }
    #qrcode {
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }
  }
</style>
<style lang="less">
  .account_dialog {
    width: 600px;
    height: 740px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
    border-radius: 5px;
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .info_wrap {
      .info_con {
        padding: 45px 80px 80px;
      }
    }
  }
</style>
